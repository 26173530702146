import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Data, Helper, User } from '~/utils';
import { SelectOptions } from './options';
import { MasterService } from '~/services/master-service';
import { ROWS } from '~/components/in-year-budget-requests/constants/rows';
import { MtpStatus } from '~/utils/interfaces';
import { MINIFD_TYPES_INFO, REQUEST_TYPES_INFO } from '~/components/in-year-budget-requests/add-in-year-budget-requests-modal/add-in-year-budget-requests-modal-utils';
import { BudgetRequestSearchResultEntry, OutageType } from '~/models/services/budget-request';
import { Permission } from '~/utils/permission';

export enum RequestOptions {
    NotReleased = '1',
    NewProject = '2',
    BudgetOverrun = '3',
    AdvanceRelease = '4',
}

export type IYBRModalInputs = {
    requestType: string;
    plantSid?: string;
    globalIds?: string[];
    mtp?: string;
    miniFdId?: string;
    miniFdYear?: string;
    outageType?: string;
    outageId?: string;
    projectName?: string;
    miniFdType?: string;
};

type MtpOption = {
    label: string;
    uniqueId?: string;
    miniFdYear?: number;
    mtpYear: number;
};

enum MtpType {
    NORMAL_MTP = 'NORMAL',
    CHANGE_REQUEST_MTP = 'CHANGE REQUEST'
}

type CommonOutageInformation = {
    outageType: OutageType,
    outageId?: string,
    outageName?: string
};

@Component({})
export default class AddInYearBudgetRequestsModalComponent extends Vue {
    private userPermissions: Permission[] = [];
    private readonly RequestOptions = RequestOptions;
    private readonly NEW_PROJECT = RequestOptions.NewProject.toString();
    private readonly pageLayout = ROWS;
    private readonly REQUEST_TYPE_POPOVER_CONFIG = { headline: 'Request Types Info', position: 'bottom' };
    private readonly REQUEST_TYPES_POPOVER_INFO = REQUEST_TYPES_INFO;
    private readonly MINIFD_TYPE_POPOVER_CONFIG = { headline: 'MiniFD Types Info', position: 'bottom' };
    private readonly MINIFD_TYPES_POPOVER_INFO = MINIFD_TYPES_INFO;

    @Prop({ default: false })
    private showModal: boolean;

    @Prop()
    private selectedYear: number;

    private data = Data.Instance;
    private isFormValid: boolean = false;

    private queriedGlobalIdEntries: BudgetRequestSearchResultEntry[] = [];

    private formData = {
        requestType: {
            value: '',
            options: Object.keys(Data.Instance.constants.requestTypes).map((key) => ({
                label: Data.Instance.constants.requestTypes[key],
                value: key,
            })),
            error: '',
        },
        outageType: {
            value: '',
            options: Object.keys(Data.Instance.constants.outageType).map((key) => ({
                label: Data.Instance.constants.outageType[key],
                value: key,
            })),
            error: '',
        },
        outageId: {
            value: '',
            error: '',
        },
        outageName: {
            value: ''
        },
        globalIds: {
            value: [],
            options: [],
            loading: false,
            error: '',
        },
        mtp: {
            value: undefined,
            disabled: false,
            error: '',
        },
        newProjectMtp: {
            value: undefined,
            error: '',
        },
        plantSid: {
            value: '',
            error: '',
        },
        miniFdType: {
            value: '',
            options: Object.entries(this.data.constants.miniFdType).map(([key, value]) => ({
                label: value,
                value: key,
            })),
            disabled: false,
            error: '',
        }
    };

    private errorMessages = {
        NOT_FOUND: `Global ID doesn't exist`,
        ONLY_APPROVED_FLAGGED_REVIEWED_ALLOWED: 'The selected Budget Requests are not in a Flagged/Reviewed/Approved state: ',
        ONLY_APPROVED_FLAGGED_ALLOWED: 'The selected Budget Requests are not in a Flagged/Approved state: ',
        ONLY_FLAGGED_ALLOWED: 'The selected Budget Requests are not in a Flagged state: ',
        REVIEWED_GLOBAL_IDS_HAVE_BUDGET_IN_MTP: 'The selected Reviewed Budget Requests have incompatible Budgets in MTP: ',
        OUTAGE_TYPE_NOT_SELECTED: 'Please select an Outage Type.',
        OUTAGE_ID_NOT_PROVIDED: 'Please provide an Outage ID.',
        MTP_NOT_SELECTED: 'Please select an MTP Year from the list.',
        NO_COMMON_MTPS_NORMAL_MTP: 'The following Global IDs don\'t have data or already have a Change Request for MTP ',
        NO_COMMON_MTPS_CR_MTP: 'The following Global IDs don\'t have a Change Request for MTP ',
        PLANT_SIDS_NOT_COMMON: `The following Global IDs don't have the same Plant Sid: `,
        MTP_NOT_CORRESPONDING_TO_CR_PERIOD: 'The selected Global IDs don\'t have any mtp for the selected change request.',
        REQUEST_TYPE_NOT_SELECTED: 'Please select a request type.',
        GLOBAL_IDS_NOT_PROVIDED: 'Please enter one or more Global Ids.',
        PLANT_SID_NOT_SELECTED: 'Please select a Plant from the list.',
        GLOBAL_IDS_HAVE_EXISTING_CHANGE_REQUEST: 'Another Change Request cannot be created for the following Global IDs: ',
        GLOBAL_IDS_HAVE_DIFFERENT_OUTAGE_TYPES: 'The selected Global IDs have different Outage Types.',
        GLOBAL_IDS_HAVE_DIFFERENT_OUTAGE_IDS: 'The selected Global IDs have different Outage IDs.',
        MINIFD_TYPE_NOT_SELECTED: 'Please select a MiniFD Type.',
    } as const;

    private get globalIdOptions(): SelectOptions<BudgetRequestSearchResultEntry>[] {
        return this.queriedGlobalIdEntries.map(this.mapSearchResultToOptions);
    }

    private get mtpOptions(): SelectOptions<MtpOption>[] {
        if (this.formData.globalIds.value.length === 0) {
            this.formData.mtp.value = undefined;
            this.formData.mtp.disabled = true;
            return [];
        }

        const mtpOptions: MtpOption[] = [];

        this.formData.globalIds.value.forEach((entry: { value: BudgetRequestSearchResultEntry }) => {
            const miniFdEntry = entry.value.miniFdList.find(e => e.mtpYear === this.selectedYear);
            const mtpListEntry = entry.value.mtpList.find(e => e.year === this.selectedYear);

            if (miniFdEntry && !mtpOptions.some(e => e.mtpYear === this.selectedYear)) {
                mtpOptions.push({
                    label: `Change Request ${Helper.getYearsForLabel(miniFdEntry.miniFdYear)}`,
                    mtpYear: miniFdEntry.mtpYear,
                    miniFdYear: miniFdEntry.miniFdYear,
                    uniqueId: miniFdEntry.uniqueId
                });
            } else if (mtpListEntry && !mtpOptions.some(e => e.mtpYear === this.selectedYear)) {
                mtpOptions.push({
                    label: `MTP ${Helper.getYearsForLabel(mtpListEntry.year)}`,
                    mtpYear: mtpListEntry.year
                });
            }
        });

        this.formData.mtp.disabled = false;

        return mtpOptions.map(option => ({
            label: option.label,
            value: option
        }));
    }

    private get newProjectMtpOptions(): SelectOptions<number>[] {
        return Data.Instance.constants.newProjectMtpYears.map(year => ({
            label: `MTP ${Helper.getYearsForLabel(year)}`,
            value: year,
        }));
    }

    private get plantOptions(): SelectOptions[] {
        return this.data.loadedPlants.map((plants) => ({
            label: plants.plantName,
            value: plants.plantSid.toString(),
        }));
    }

    private get isAdmin(): boolean {
        return this.userPermissions.includes(Permission.SD_FD_EDIT)
    }

    async created(): Promise<void> {
        this.userPermissions = await User.getPerm();
    }

    private closeModal(): void {
        let openModal = this.showModal;
        this.$emit('close-modal', openModal);
        this.clearForm();
    }

    private handleRequestType(event: string): void {
        this.clearForm();

        this.isFormValid = false;
        this.formData.requestType.value = event;
    }

    private handlePlantSelect(event: string): void {
        if (event) {
            this.formData.plantSid.value = event;
        }
    }

    private async handleOutageTypeBlur(): Promise<void> {
        const outageId = this.formData.outageId.value;

        if (!outageId || this.formData.requestType.value === RequestOptions.NewProject.toString()) {
            return;
        }

        this.formData.globalIds.loading = true;
        const budgetRequestsFromOutageId = await MasterService.instance.budgetRequestsService.searchBudgetRequestsByOutageId(outageId, this.selectedYear);
        this.formData.globalIds.loading = false;

        this.formData.outageName.value = budgetRequestsFromOutageId.outageName;
        this.formData.globalIds.value = budgetRequestsFromOutageId.budgetRequests.map(this.mapSearchResultToOptions);
    }

    private mapSearchResultToOptions(searchResult: BudgetRequestSearchResultEntry): SelectOptions<BudgetRequestSearchResultEntry> {
        return {
            label: searchResult.globalId,
            secondaryLabel: searchResult.name,
            value: searchResult,
        };
    }

    private validateForm(): boolean {
        this.isFormValid = true;
        this.clearAllErrors();

        // Check if Request Type is selected
        if (this.formData.requestType.value === '') {
            this.formData.requestType.error = this.errorMessages.REQUEST_TYPE_NOT_SELECTED;
            this.isFormValid = false;
        } else if (this.formData.requestType.value === RequestOptions.NewProject.toString()) {
            // Check if Plant is selected
            if (this.formData.plantSid.value === '') {
                this.formData.plantSid.error = this.errorMessages.PLANT_SID_NOT_SELECTED;
                this.isFormValid = false;
            }

            // Check if New Project MTP is selected
            if (!this.formData.newProjectMtp.value) {
                this.formData.newProjectMtp.error = this.errorMessages.MTP_NOT_SELECTED;
                this.isFormValid = false;
            }
        } else {
            // Check if Global Ids & MTPs are provided
            if (this.formData.globalIds.value.length === 0) {
                this.formData.globalIds.error = this.errorMessages.GLOBAL_IDS_NOT_PROVIDED;
                this.isFormValid = false;
            } else if (!this.formData.mtp.value) {
                this.formData.mtp.error = this.errorMessages.MTP_NOT_SELECTED;
                this.isFormValid = false;
            }

            // Check if Outage Type is selected
            if (this.formData.outageType.value === '') {
                this.formData.outageType.error = this.errorMessages.OUTAGE_TYPE_NOT_SELECTED;
                this.isFormValid = false;
            }

            // Check if Outage Id is provided
            if (this.formData.outageType.value === '1' && this.formData.outageId.value === '') {
                this.formData.outageId.error = this.errorMessages.OUTAGE_ID_NOT_PROVIDED;
                this.isFormValid = false;
            }

            // Validations for Global Ids
            if (this.formData.globalIds.value.length > 0) {
                this.validateGlobalIds();
            }

            if (this.isAdmin && this.formData.miniFdType.value === '') {
                this.formData.miniFdType.error = this.errorMessages.MINIFD_TYPE_NOT_SELECTED;
                this.isFormValid = false;
            }
        }

        return this.isFormValid;
    }

    private clearAllErrors(): void {
        Object.keys(this.formData).forEach((key) => {
            this.formData[key].error = '';
        });
    }

    private async handleGlobalIdSearch(globalIdQuery: string): Promise<void> {
        if (globalIdQuery) {
            this.formData.globalIds.loading = true;
            this.queriedGlobalIdEntries = await MasterService.instance.budgetRequestsService.searchBudgetRequestsByGlobalId(globalIdQuery, this.selectedYear);
            this.formData.globalIds.loading = false;
        }
    }

    private clearForm(): void {
        this.clearAllErrors();

        this.formData.requestType.value = '';
        this.formData.outageType.value = '';
        this.formData.outageId.value = '';
        this.formData.plantSid.value = '';
        this.formData.globalIds.value = [];
        this.formData.globalIds.loading = false;
        this.formData.globalIds.options = [];
        this.formData.mtp.value = undefined;
        this.formData.mtp.disabled = false;
        this.formData.newProjectMtp.value = undefined;
        if (this.formData.miniFdType.value === '') {
            this.formData.miniFdType.value = '1';
        }
    }

    private onSubmit(): void {
        if (this.validateForm()) {
            let inputs: IYBRModalInputs = {
                requestType: this.formData.requestType.value
            };

            if (this.formData.requestType.value === RequestOptions.NewProject) {
                inputs.plantSid = this.formData.plantSid.value;
                inputs.mtp = this.formData.newProjectMtp.value;
            } else {
                inputs.globalIds = this.formData.globalIds.value.map((entry) => entry.value.globalId);
                inputs.outageType = this.formData.outageType.value;

                inputs.mtp = this.formData.mtp.value.mtpYear;
                inputs.miniFdYear = this.formData.mtp.value.miniFdYear;
                inputs.miniFdId = this.formData.mtp.value.uniqueId?.trim();

                if (inputs.outageType === OutageType.OUTAGE.toString()) {
                    inputs.projectName = this.formData.outageName.value;
                    inputs.outageId = this.formData.outageId.value;
                }
            }

            if (this.isAdmin) {
                inputs.miniFdType = this.formData.miniFdType.value;
            }

            this.$root.$emit('addMiniFd', inputs);
        }
    }

    private validateGlobalIds(): void {
        // Check if all Global Ids have same Plant Sid
        if (this.formData.outageType.value === '1' && this.formData.outageId.value) {
            const firstPlantSid = this.formData.globalIds.value[0].value.plantSid;
            const entriesWithDifferentPlantSid = this.formData.globalIds.value.map(entry => entry.value as BudgetRequestSearchResultEntry)
                .filter(entry => entry.plantSid !== firstPlantSid);

            if (entriesWithDifferentPlantSid.length > 0) {
                this.formData.globalIds.error = this.errorMessages.PLANT_SIDS_NOT_COMMON
                    + entriesWithDifferentPlantSid.map(entry => entry.globalId).join(', ');
                this.isFormValid = false;
                return;
            }
        }

        // Check if Global Ids selected don't have a Change Request
        const entriesWithChangeRequest = this.formData.globalIds.value.map(entry => entry.value as BudgetRequestSearchResultEntry)
            .filter(entry => entry.hasChangeRequest);

        if (entriesWithChangeRequest.length > 0) {
            this.formData.globalIds.error = this.errorMessages.GLOBAL_IDS_HAVE_EXISTING_CHANGE_REQUEST
                + entriesWithChangeRequest.map(entry => entry.globalId).join(', ');
            this.isFormValid = false;
            return;
        }

        // Check if Global Ids have correct status for Request Type
        if (this.formData.mtp.value) {
            const mtpType = this.formData.mtp.value.miniFdYear ? MtpType.CHANGE_REQUEST_MTP : MtpType.NORMAL_MTP;

            // Check if all Global Ids have the selected MTP
            let entriesWithoutSelectedMtp;

            switch (mtpType) {
                case MtpType.CHANGE_REQUEST_MTP:
                    entriesWithoutSelectedMtp = this.formData.globalIds.value.map(entry => entry.value as BudgetRequestSearchResultEntry)
                        .filter(entry => !entry.miniFdList.map(mtpEntry => mtpEntry.miniFdYear).includes(this.formData.mtp.value.miniFdYear));
                    break;
                case MtpType.NORMAL_MTP:
                    entriesWithoutSelectedMtp = this.formData.globalIds.value.map(entry => entry.value as BudgetRequestSearchResultEntry)
                        .filter(entry => !entry.mtpList.map(mtpEntry => mtpEntry.year).includes(this.formData.mtp.value.mtpYear));
                    break;
            }

            if (entriesWithoutSelectedMtp.length > 0) {
                switch (mtpType) {
                    case MtpType.CHANGE_REQUEST_MTP:
                        this.formData.globalIds.error = this.errorMessages.NO_COMMON_MTPS_CR_MTP;
                        break;
                    case MtpType.NORMAL_MTP:
                        this.formData.globalIds.error = this.errorMessages.NO_COMMON_MTPS_NORMAL_MTP;
                        break;
                }

                this.formData.globalIds.error += ` ${this.formData.mtp.value.mtpYear}: ${entriesWithoutSelectedMtp.map(entry => entry.globalId).join(', ')}`;
                this.isFormValid = false;
                return;
            }

            const mappedGlobalIdsMtp = this.formData.globalIds.value
                .map(entry => {
                    const searchResultEntry = entry.value as BudgetRequestSearchResultEntry;

                    let filteredMtpLists;
                    let hasDuplicates = false;
 
                    switch (mtpType) {
                        case MtpType.CHANGE_REQUEST_MTP:
                            filteredMtpLists = searchResultEntry.miniFdList.filter(mtpEntry => mtpEntry.miniFdYear === this.formData.mtp.value.miniFdYear);
                            hasDuplicates = new Set(filteredMtpLists.map(mtpEntry => mtpEntry.mtpYear)).size !== filteredMtpLists.length;
                            break;
                        case MtpType.NORMAL_MTP:
                            filteredMtpLists = searchResultEntry.mtpList.filter(mtpEntry => mtpEntry.year === this.formData.mtp.value.mtpYear);
                            hasDuplicates = new Set(filteredMtpLists.map(mtpEntry => mtpEntry.miniFdYear)).size !== filteredMtpLists.length;
                            break;
                    }
                    
                    if (filteredMtpLists.length !== 1 && hasDuplicates) {
                        this.formData.mtp.error = this.errorMessages.MTP_NOT_SELECTED;
                        this.isFormValid = false;
                    }

                    return {
                        globalId: searchResultEntry.globalId,
                        statusType: filteredMtpLists[0].status,
                        outageType: filteredMtpLists[0].outageType,
                        outageId: filteredMtpLists[0].outageId,
                        canCreateFromReviewed: filteredMtpLists[0].canCreateFromReviewed
                    };
                });

            let invalidEntries;
            let errorMessage;

            switch (this.formData.requestType.value) {
                case RequestOptions.NotReleased: // If NOT_RELEASED, only allow Global Ids with Flagged Status
                    invalidEntries = mappedGlobalIdsMtp.filter(e => e.statusType !== MtpStatus.FLAGGED);
                    errorMessage = this.errorMessages.ONLY_FLAGGED_ALLOWED;
                    break;
                case RequestOptions.AdvanceRelease: // If ADVANCE_RELEASE, only allow Global Ids with Flagged/Reviewed/Approved Status
                    invalidEntries = mappedGlobalIdsMtp.filter(e => e.statusType !== MtpStatus.APPROVED && e.statusType !== MtpStatus.FLAGGED && e.statusType !== MtpStatus.REVIEWED);
                    errorMessage = this.errorMessages.ONLY_APPROVED_FLAGGED_REVIEWED_ALLOWED;
                    break;
                default: // Else, only allow Global Ids with Flagged or Approved Status
                    invalidEntries = mappedGlobalIdsMtp.filter(e => e.statusType !== MtpStatus.APPROVED && e.statusType !== MtpStatus.FLAGGED);
                    errorMessage = this.errorMessages.ONLY_APPROVED_FLAGGED_ALLOWED;
                    break;
            }

            if (invalidEntries.length > 0) {
                this.formData.globalIds.error = errorMessage + invalidEntries.map(e => e.globalId).join(', ');
                this.isFormValid = false;
                return;
            }

            // Check if Reviewed Global Ids are in a state to create a Change Request
            if (mappedGlobalIdsMtp.some(e => e.statusType === MtpStatus.REVIEWED && !e.canCreateFromReviewed)) {
                const invalidReviewedEntries = mappedGlobalIdsMtp.filter(e => e.statusType === MtpStatus.REVIEWED && !e.canCreateFromReviewed);
                this.formData.globalIds.error = this.errorMessages.REVIEWED_GLOBAL_IDS_HAVE_BUDGET_IN_MTP + invalidReviewedEntries.map(e => e.globalId).join(', ');
                this.isFormValid = false
                return;
            }

            // Check if Global Ids MTPs have same Outage Type
            const firstOutageType = mappedGlobalIdsMtp[0].outageType;
            if (mappedGlobalIdsMtp.map(e => e.outageType).filter(e => e !== firstOutageType).length > 0) {
                this.formData.globalIds.error = this.errorMessages.GLOBAL_IDS_HAVE_DIFFERENT_OUTAGE_TYPES;
                this.isFormValid = false;
                return;
            }

            // If Outage Type -> Outage, check if all have same Outage Id
            if (firstOutageType === OutageType.OUTAGE) {
                const firstOutageId = mappedGlobalIdsMtp[0].outageId;

                if (mappedGlobalIdsMtp.map(e => e.outageId).filter(e => e !== firstOutageId).length > 0) {
                    this.formData.globalIds.error = this.errorMessages.GLOBAL_IDS_HAVE_DIFFERENT_OUTAGE_IDS;
                    this.isFormValid = false;
                    return;
                }
            }
        } else if (!this.mtpOptions.length) {
            if (this.formData.globalIds.value.length > 0) {
                this.formData.globalIds.error = this.errorMessages.MTP_NOT_CORRESPONDING_TO_CR_PERIOD;
                this.isFormValid = false;
                return;
            }
        }
    }

    private getCommonOutageInformation(): CommonOutageInformation | undefined {
        let outageId: string | undefined;
        let outageName: string | undefined;

        if (!this.formData.mtp.value) {
            return;
        }

        const mappedGlobalIdsMtp = this.formData.globalIds.value
            .map(entry => {
                const searchResultEntry = entry.value as BudgetRequestSearchResultEntry;

                let filteredMtpLists;

                if (this.formData.mtp.value.miniFdYear) {
                    filteredMtpLists = searchResultEntry.miniFdList.filter(mtpEntry => mtpEntry.miniFdYear === this.formData.mtp.value.miniFdYear);
                } else if (this.formData.mtp.value.mtpYear) {
                    filteredMtpLists = searchResultEntry.mtpList.filter(mtpEntry => mtpEntry.year === this.formData.mtp.value.mtpYear);
                }

                if (filteredMtpLists.length !== 1) {
                    return;
                }

                return {
                    outageType: filteredMtpLists[0].outageType,
                    outageId: filteredMtpLists[0].outageId,
                    outageName: filteredMtpLists[0].outageName
                };
            });

        if (mappedGlobalIdsMtp.includes(undefined) || mappedGlobalIdsMtp.length === 0) {
            return;
        }

        // Check if Global Ids MTPs have same Outage Type
        const outageType = mappedGlobalIdsMtp[0].outageType;
        if (mappedGlobalIdsMtp.map(e => e.outageType).filter(e => e !== outageType).length > 0) {
            return;
        }

        // If Outage Type -> Outage, check if all have same Outage Id
        if (outageType === OutageType.OUTAGE) {
            const firstOutageId = mappedGlobalIdsMtp[0].outageId;

            if (mappedGlobalIdsMtp.map(e => e.outageId).filter(e => e !== firstOutageId).length > 0) {
                return;
            }

            outageId = firstOutageId;
            outageName = mappedGlobalIdsMtp[0].outageName;
        }

        return {
            outageType,
            outageId,
            outageName
        };
    }

    private validateGlobalIdsAfterChange(): void {
        this.isFormValid = true;
        this.clearAllErrors();
        this.validateGlobalIds();

        const commonOutageInformation = this.getCommonOutageInformation();
        if (commonOutageInformation) {
            const { outageType, outageId, outageName } = commonOutageInformation;

            this.formData.outageType.value = outageType.toString();
            this.formData.outageName.value = outageName ? outageName : '';
            this.formData.outageId.value = outageId ? outageId : '';
        }
    }

    private clearGlobalIds(): void {
        this.formData.globalIds.value = [];
    }
}
