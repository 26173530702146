var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.budgetRequestHasPlanning ? _c('pui-collapsible', {
    staticClass: "mb-3",
    attrs: {
      "headline": "Global IDs",
      "open": ""
    }
  }, [_vm.linkedBudgetRequests.length > 0 ? _c('pui-tabs', {
    staticClass: "px-3 pt-3",
    on: {
      "changed": _vm.handleTabChange
    }
  }, _vm._l(_vm.linkedBudgetRequests, function (budgetRequest) {
    return _c('pui-tab', {
      key: budgetRequest.budgetRequest.globalId,
      attrs: {
        "title": budgetRequest.budgetRequest.globalId
      }
    });
  }), 1) : _vm._e(), _vm._l(_vm.linkedBudgetRequests, function (budgetRequest) {
    var _budgetRequest$budget, _budgetRequest$budget2, _budgetRequest$budget3, _budgetRequest$budget4, _budgetRequest$budget5, _budgetRequest$budget6, _budgetRequest$budget7, _budgetRequest$budget8, _budgetRequest$budget9, _budgetRequest$budget10, _budgetRequest$budget11, _budgetRequest$budget12, _budgetRequest$budget13, _budgetRequest$budget14, _budgetRequest$budget15, _budgetRequest$budget16, _budgetRequest$budget17, _budgetRequest$budget18;

    return [_c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.linkedBudgetRequests[_vm.currentTab] === budgetRequest,
        expression: "linkedBudgetRequests[currentTab] === budgetRequest"
      }],
      key: budgetRequest.budgetRequest.globalId
    }, [_c('div', {
      directives: [{
        name: "pui-form-grid-row",
        rawName: "v-pui-form-grid-row"
      }]
    }, [_c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: _vm.formLayout.FULL_ROW,
        expression: "formLayout.FULL_ROW"
      }],
      attrs: {
        "label": "Project Name"
      }
    }, [_c('pui-form-input-field', {
      attrs: {
        "value": budgetRequest === null || budgetRequest === void 0 ? void 0 : (_budgetRequest$budget = budgetRequest.budgetRequest) === null || _budgetRequest$budget === void 0 ? void 0 : _budgetRequest$budget.name,
        "is-disabled": "",
        "label": "Project Name",
        "type": "text"
      }
    })], 1), _c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: _vm.formLayout.HALF_ROW,
        expression: "formLayout.HALF_ROW"
      }],
      attrs: {
        "label": "Global ID"
      }
    }, [_c('pui-form-input-field', {
      attrs: {
        "value": (_budgetRequest$budget2 = budgetRequest.budgetRequest) === null || _budgetRequest$budget2 === void 0 ? void 0 : _budgetRequest$budget2.globalId,
        "is-disabled": "",
        "label": "Global ID",
        "type": "text"
      }
    })], 1), _c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: _vm.formLayout.HALF_ROW,
        expression: "formLayout.HALF_ROW"
      }],
      attrs: {
        "label": "WBS (PSP) Element"
      }
    }, [_c('pui-form-input-field', {
      attrs: {
        "value": (_budgetRequest$budget3 = budgetRequest.budgetRequest) === null || _budgetRequest$budget3 === void 0 ? void 0 : (_budgetRequest$budget4 = _budgetRequest$budget3.planning) === null || _budgetRequest$budget4 === void 0 ? void 0 : _budgetRequest$budget4.wbsElement,
        "is-disabled": "",
        "label": "WBS (PSP) Element",
        "type": "text"
      }
    })], 1), _c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: _vm.formLayout.HALF_ROW,
        expression: "formLayout.HALF_ROW"
      }],
      attrs: {
        "label": "AERO Classification"
      }
    }, [_c('pui-form-input-field', {
      attrs: {
        "value": _vm.getValueForConstant('aeroClassification', (_budgetRequest$budget5 = budgetRequest.budgetRequest) === null || _budgetRequest$budget5 === void 0 ? void 0 : (_budgetRequest$budget6 = _budgetRequest$budget5.planning) === null || _budgetRequest$budget6 === void 0 ? void 0 : _budgetRequest$budget6.aeroClassification),
        "is-disabled": "",
        "label": "AERO Classification",
        "type": "text"
      }
    })], 1)], 1), _c('div', {
      directives: [{
        name: "pui-form-grid-row",
        rawName: "v-pui-form-grid-row"
      }]
    }, [_c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: _vm.formLayout.HALF_ROW,
        expression: "formLayout.HALF_ROW"
      }],
      attrs: {
        "label": "Risk / Maintenance IDs"
      }
    }, [_c('pui-form-input-field', {
      attrs: {
        "value": (_budgetRequest$budget7 = budgetRequest.budgetRequest) === null || _budgetRequest$budget7 === void 0 ? void 0 : (_budgetRequest$budget8 = _budgetRequest$budget7.planning) === null || _budgetRequest$budget8 === void 0 ? void 0 : _budgetRequest$budget8.riskOpportunityId,
        "is-disabled": "",
        "label": "Risk / Maintenance IDs",
        "type": "text"
      }
    })], 1), _c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: _vm.formLayout.QUARTER_ROW,
        expression: "formLayout.QUARTER_ROW"
      }],
      attrs: {
        "label": "Project Start Date"
      }
    }, [_c('pui-form-input-field', {
      attrs: {
        "value": _vm.formatDate((_budgetRequest$budget9 = budgetRequest.budgetRequest) === null || _budgetRequest$budget9 === void 0 ? void 0 : (_budgetRequest$budget10 = _budgetRequest$budget9.planning) === null || _budgetRequest$budget10 === void 0 ? void 0 : _budgetRequest$budget10.startDate),
        "is-disabled": "",
        "label": "Project Start Date",
        "type": "date"
      }
    })], 1), _c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: _vm.formLayout.QUARTER_ROW,
        expression: "formLayout.QUARTER_ROW"
      }],
      attrs: {
        "label": "Project End Date"
      }
    }, [_c('pui-form-input-field', {
      attrs: {
        "value": _vm.formatDate((_budgetRequest$budget11 = budgetRequest.budgetRequest) === null || _budgetRequest$budget11 === void 0 ? void 0 : (_budgetRequest$budget12 = _budgetRequest$budget11.planning) === null || _budgetRequest$budget12 === void 0 ? void 0 : _budgetRequest$budget12.endDate),
        "is-disabled": "",
        "label": "Project End Date",
        "type": "date"
      }
    })], 1)], 1), _c('div', {
      directives: [{
        name: "pui-form-grid-row",
        rawName: "v-pui-form-grid-row"
      }]
    }, [_c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: _vm.formLayout.HALF_ROW,
        expression: "formLayout.HALF_ROW"
      }],
      attrs: {
        "label": "Planning & Controlling Classification"
      }
    }, [_c('pui-form-input-field', {
      attrs: {
        "value": _vm.getValueForConstant('allPlanningAndControllingClassification', (_budgetRequest$budget13 = budgetRequest.budgetRequest) === null || _budgetRequest$budget13 === void 0 ? void 0 : (_budgetRequest$budget14 = _budgetRequest$budget13.planning) === null || _budgetRequest$budget14 === void 0 ? void 0 : _budgetRequest$budget14.planningAndControllingClassification),
        "is-disabled": "",
        "label": "Planning & Controlling Classification",
        "type": "text"
      }
    })], 1), _c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: _vm.formLayout.QUARTER_ROW,
        expression: "formLayout.QUARTER_ROW"
      }],
      attrs: {
        "label": "Uniper Share of Budget"
      }
    }, [_c('pui-form-input-field', {
      attrs: {
        "value": ((_budgetRequest$budget15 = budgetRequest.budgetRequest) === null || _budgetRequest$budget15 === void 0 ? void 0 : (_budgetRequest$budget16 = _budgetRequest$budget15.planning) === null || _budgetRequest$budget16 === void 0 ? void 0 : _budgetRequest$budget16.uniperShareOfBudget) + '%',
        "is-disabled": "",
        "label": "Uniper Share of Budget",
        "type": "text"
      }
    })], 1), _c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: _vm.formLayout.QUARTER_ROW,
        expression: "formLayout.QUARTER_ROW"
      }],
      attrs: {
        "label": "Status"
      }
    }, [_c('pui-form-input-field', {
      attrs: {
        "value": _vm.getValueForConstant('mtpStatus', (_budgetRequest$budget17 = budgetRequest.budgetRequest) === null || _budgetRequest$budget17 === void 0 ? void 0 : (_budgetRequest$budget18 = _budgetRequest$budget17.planning) === null || _budgetRequest$budget18 === void 0 ? void 0 : _budgetRequest$budget18.mtpStatus).name,
        "is-disabled": "",
        "label": "Status",
        "type": "text"
      }
    })], 1)], 1), _c('pui-table', {
      staticClass: "pt-4",
      attrs: {
        "vendor-options": _vm.tableOptions,
        "columns-are-center-aligned": "",
        "first-column-is-left-aligned": ""
      }
    })], 1)];
  })], 2) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }