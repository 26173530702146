import { Connection } from '~/utils';
import { ApiPaginatedResponse, ApiResponse } from '~/models/services/common';
import { Notification } from '~/models/services/notification';
import Vue from "vue"

export class NotificationService {
    private readonly api = new Connection(Vue.prototype.$sdk.$http.$core.metaData.request.api);
    private readonly notificationsEndpoint = 'notifications';
    private readonly workflowsEndpoint = 'workflows';

    public async getAllNotifications(): Promise<ApiResponse<ApiPaginatedResponse<Notification>>> {
        const notificationFilter = {
            ListNotifications: true,
            ListTasks: true,
            GetRead: false,
            page: 1,
            size: 300,
            term: ''
        };

        let queryString = '?';
        for (const field in notificationFilter) {
            if (notificationFilter[field]) {
                queryString = queryString.concat(`${field}=${notificationFilter[field]}&`);
            }
        }

        if (queryString.endsWith('&') || queryString.endsWith('?')) {
            queryString = queryString.slice(0, -1);
        }

        return (await this.api.get<ApiResponse<ApiPaginatedResponse<Notification>>>(`/${this.notificationsEndpoint}${queryString}`)).data;
    }

    public async doAction(workflowInstanceId: string, workflowActionId: number, content: Record<string, unknown> = {}): Promise<number> {
        return (await this.api.post(`/${this.workflowsEndpoint}/${workflowInstanceId}`, {
            workflowActionId, content
        })).status;
    }

    public async clearAllNotifications(): Promise<number> {
        return (await this.api.post(`/${this.notificationsEndpoint}/clear-all`)).status;
    }

    public async markNotificationsAsRead(...notifications: number[]): Promise<number> {
        return (await this.api.post(`/${this.notificationsEndpoint}/mark-as-read`, notifications)).status;
    }
}
