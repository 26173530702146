import { Connection } from '~/utils/';
import { DeleteFileResponse, GetFilesResponse, UploadFileResponse } from '~/models/services/file';
import { ApiResponse } from '~/models/services/common';

export class GlobalDocumentService {
    private readonly api: Connection = new Connection();

    public async addFile(addFile: FormData): Promise<UploadFileResponse> {
        return (await this.api.post<ApiResponse<UploadFileResponse>>('global-file', addFile, { headers: { 'Content-Type' : 'multipart/form-data; boundary=${data._boundary}' } })).data.result;
    }

    public async getFiles(year: string | number, pageNo: string | number): Promise<GetFilesResponse> {
        return (await this.api.get<ApiResponse<GetFilesResponse>>('global-file/list/' + year + '/' + pageNo)).data.result;
    }

    public async deleteFile(fileId: string): Promise<DeleteFileResponse> {
        return (await this.api.delete('global-file/' + fileId)).data.result;
    }

    public async downloadFile(fileId: string): Promise<Blob> {
        return (await this.api.get<Blob>('global-file/download/' + fileId, { responseType: 'blob'})).data;
    }
}
