var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal fade",
    attrs: {
      "id": "changeReviewModal",
      "data-backdrop": "static",
      "role": "dialog",
      "tabindex": "-1"
    }
  }, [_c('b-toast', {
    attrs: {
      "id": "toast-error",
      "auto-hide-delay": "20000",
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center"
    }
  }, [_vm.succesChangeReview.items ? _c('div', {
    staticClass: "toast-align"
  }, [_vm._l(_vm.succesChangeReview.items, function (items, index) {
    return _c('span', {
      key: index
    }, [_vm._v(" " + _vm._s(items.error) + " ")]);
  }), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideToast();
      }
    }
  }, [_vm._v(" Dismiss ")])], 2) : _vm._e()]), _c('div', {
    staticClass: "modal-dialog",
    attrs: {
      "role": "document"
    }
  }, [_c('div', {
    staticClass: "modal-content"
  }, [_vm._m(0), _c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_vm.data && _vm.keyIdFromChangeMtp === '4' ? _c('p', [_vm._v(" To update the budget request to \"" + _vm._s(_vm.data.constants.mtpStatus[_vm.keyIdFromChangeMtp].name) + "\", the request must be reviewed with all flags being removed. ")]) : _vm._e()]), _c('button', {
    staticClass: "btn",
    staticStyle: {
      "color": "#0078dc",
      "padding-bottom": "10px"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.goToDetails();
      }
    }
  }, [_vm._v(" View details ")])]), _c('div', {
    staticClass: "row"
  }, [_vm.data && _vm.keyIdFromChangeMtp !== null ? _c('div', {
    staticClass: "col-sm-6 pb-4"
  }, [_c('label', [_vm._v("Main Flag")]), _c('multiselect', {
    attrs: {
      "allow-empty": true,
      "max": 1,
      "multiple": true,
      "options": _vm.flagsArray,
      "searchable": false,
      "show-labels": false,
      "label": "title",
      "placeholder": "No flag selected",
      "track-by": "title"
    },
    on: {
      "input": function ($event) {
        return _vm.disableFromOtherFlags();
      }
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function (props) {
        return [_vm._v(" " + _vm._s(props.option.title) + " ")];
      }
    }, {
      key: "option",
      fn: function (props) {
        return [_vm._v(" " + _vm._s(props.option.title) + " ")];
      }
    }], null, false, 3473667830),
    model: {
      value: _vm.selectedMainFlag,
      callback: function ($$v) {
        _vm.selectedMainFlag = $$v;
      },
      expression: "selectedMainFlag"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "col-sm-6 pb-4"
  }, [_c('label', [_vm._v("Other Flags")]), _c('multiselect', {
    attrs: {
      "close-on-select": false,
      "disabled": _vm.validateMainFlag,
      "multiple": true,
      "options": _vm.otherFlagsArray,
      "searchable": false,
      "show-labels": false,
      "label": "title",
      "placeholder": "No flag(s) selected",
      "track-by": "title"
    },
    on: {
      "input": function ($event) {
        return _vm.disableFromFlags();
      }
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function (props) {
        return [_vm._v(" " + _vm._s(props.option.title) + " ")];
      }
    }, {
      key: "option",
      fn: function (props) {
        return [_vm._v(" " + _vm._s(props.option.title) + " ")];
      }
    }]),
    model: {
      value: _vm.selectedOtherFlags,
      callback: function ($$v) {
        _vm.selectedOtherFlags = $$v;
      },
      expression: "selectedOtherFlags"
    }
  })], 1), _c('div', {
    staticClass: "col-12 pb-4"
  }, [_c('label', [_vm._v("Comment")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.reviewBudgetRequest.comment.text,
      expression: "reviewBudgetRequest.comment.text"
    }],
    staticClass: "form-control",
    attrs: {
      "input": _vm.keyIdFromChangeMtp === '3' ? _vm.checkemptyToFlagged() : _vm.checkempty(),
      "placeholder": "Please enter a comment"
    },
    domProps: {
      "value": _vm.reviewBudgetRequest.comment.text
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.reviewBudgetRequest.comment, "text", $event.target.value);
      }
    }
  })])])])]), _c('div', {
    staticClass: "modal-footer"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary",
    attrs: {
      "data-dismiss": "modal",
      "type": "button"
    }
  }, [_vm._v(" Cancel ")]), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "disabled": _vm.validateReviewComment,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.update();
      }
    }
  }, [_vm._v(" Update ")])])])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal-header"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('h5', {
    staticClass: "modal-title",
    attrs: {
      "id": "exampleModalLabel"
    }
  }, [_vm._v(" Update MTP status ")])])])]), _c('button', {
    staticClass: "close",
    attrs: {
      "aria-label": "Close",
      "data-dismiss": "modal",
      "type": "button"
    }
  }, [_c('span', {
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("×")])])]);
}]

export { render, staticRenderFns }