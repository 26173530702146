var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.showModal ? _c('div', {
    attrs: {
      "id": "inYearBudgetRequestsModal"
    }
  }, [_c('style', {
    tag: "component"
  }, [_vm._v(" body { overflow: hidden; } ")]), _c('div', {
    staticClass: "modal-mask"
  }, [_c('div', {
    staticClass: "modal-wrapper"
  }, [_c('div', {
    staticClass: "modal-dialog modal-dialog-centered modal-lg",
    attrs: {
      "role": "document"
    }
  }, [_c('div', {
    staticClass: "modal-content"
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('pui-grid-container', [_c('pui-grid-row', [_c('pui-grid-column', {
    staticClass: "my-4",
    attrs: {
      "cols": _vm.pageLayout.FULL_ROW
    }
  }, [_c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('div', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.pageLayout.FULL_ROW,
      expression: "pageLayout.FULL_ROW"
    }]
  }, [_c('pui-headline', {
    attrs: {
      "looks-like": "form-sheet-title",
      "type": "h1"
    }
  }, [_vm._v(" Add Change Request "), _c('button', {
    staticClass: "close",
    attrs: {
      "id": "inYearBudReqModalCloseBtn",
      "aria-label": "Close",
      "data-dismiss": "modal",
      "type": "button"
    }
  }, [_c('span', {
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": function ($event) {
        return _vm.closeModal();
      }
    }
  }, [_vm._v("× ")])])])], 1)]), _c('pui-form', {
    attrs: {
      "aria-label": "Form"
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.onSubmit();
      }
    }
  }, [_c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('p', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.pageLayout.FULL_ROW,
      expression: "pageLayout.FULL_ROW"
    }],
    attrs: {
      "id": "in-year-bud-req-modal-p"
    }
  }, [_vm._v(" Tell us more about your Change Request. ")])]), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.pageLayout.HALF_ROW,
      expression: "pageLayout.HALF_ROW"
    }],
    attrs: {
      "pui-popover-config": _vm.REQUEST_TYPE_POPOVER_CONFIG,
      "has-info-layer": "",
      "label": "Select Request Type",
      "label-for": "requestSelect"
    }
  }, [_c('div', {
    attrs: {
      "slot": "pui-popover-content"
    },
    slot: "pui-popover-content"
  }, [_c('ul', {
    staticClass: "popover-info-list"
  }, _vm._l(_vm.REQUEST_TYPES_POPOVER_INFO, function (requestTypeInfo) {
    return _c('li', {
      key: requestTypeInfo.requestType,
      staticClass: "popover-info-list-item"
    }, [_c('p', {
      staticClass: "popover-info-request-type"
    }, [_vm._v(" " + _vm._s(requestTypeInfo.requestType) + ": ")]), _c('p', {
      staticClass: "popover-info-request-info"
    }, [_vm._v(" " + _vm._s(requestTypeInfo.requestInfo) + " ")])]);
  }), 0)]), _c('pui-form-select', {
    attrs: {
      "options": _vm.formData.requestType.options,
      "label": "requestSelect",
      "search-input-id": "requestSelect",
      "search-input-placeholder": "Please select"
    },
    on: {
      "change": _vm.handleRequestType
    }
  })], 1), _vm.formData.requestType.value !== '' && _vm.formData.requestType.value !== _vm.NEW_PROJECT ? _c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.pageLayout.HALF_ROW,
      expression: "pageLayout.HALF_ROW"
    }],
    attrs: {
      "is-valid": !_vm.formData.outageType.error,
      "label": "Outage Type",
      "label-for": "outageType"
    }
  }, [_c('pui-form-input-selector', {
    attrs: {
      "options": _vm.formData.outageType.options,
      "selected-value": _vm.formData.outageType.value,
      "name": "outageType"
    },
    on: {
      "update:selectedValue": function ($event) {
        return _vm.$set(_vm.formData.outageType, "value", $event);
      },
      "update:selected-value": function ($event) {
        return _vm.$set(_vm.formData.outageType, "value", $event);
      }
    }
  }), _c('span', {
    attrs: {
      "slot": "error-message"
    },
    slot: "error-message"
  }, [_vm._v(" " + _vm._s(_vm.formData.outageType.error) + " ")])], 1) : _vm._e()], 1), _vm.formData.outageType.value === '1' ? _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.pageLayout.HALF_ROW,
      expression: "pageLayout.HALF_ROW"
    }],
    attrs: {
      "is-valid": !_vm.formData.outageId.error,
      "label": "Outage ID",
      "label-for": "outageId"
    }
  }, [_c('pui-form-input-field', {
    attrs: {
      "is-valid": !_vm.formData.outageId.error,
      "placeholder-text": "Enter Outage ID number",
      "search-input-id": "outageId"
    },
    on: {
      "blur": _vm.handleOutageTypeBlur
    },
    model: {
      value: _vm.formData.outageId.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.outageId, "value", $$v);
      },
      expression: "formData.outageId.value"
    }
  }), _c('span', {
    attrs: {
      "slot": "error-message"
    },
    slot: "error-message"
  }, [_vm._v(" " + _vm._s(_vm.formData.outageId.error) + " ")])], 1)], 1) : _vm._e(), _vm.formData.requestType.value === _vm.NEW_PROJECT ? _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.pageLayout.HALF_ROW,
      expression: "pageLayout.HALF_ROW"
    }],
    attrs: {
      "is-valid": !_vm.formData.plantSid.error,
      "label": "Select Plant",
      "label-for": "plantSelect"
    }
  }, [_c('pui-form-select', {
    attrs: {
      "options": _vm.plantOptions,
      "label": "plantSelect",
      "search-input-id": "plantSelect",
      "search-input-placeholder": "Please select"
    },
    on: {
      "change": _vm.handlePlantSelect
    }
  }), _c('span', {
    attrs: {
      "slot": "error-message"
    },
    slot: "error-message"
  }, [_vm._v(" " + _vm._s(_vm.formData.plantSid.error) + " ")])], 1), _c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.pageLayout.HALF_ROW,
      expression: "pageLayout.HALF_ROW"
    }],
    attrs: {
      "label": "MTP",
      "label-for": "newProjectMtpSelect"
    }
  }, [_c('pui-form-select', {
    attrs: {
      "options": _vm.newProjectMtpOptions,
      "label": "newProjectMtpSelect",
      "search-input-id": "newProjectMtpSelect",
      "search-input-placeholder": "Select year"
    },
    model: {
      value: _vm.formData.newProjectMtp.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.newProjectMtp, "value", $$v);
      },
      expression: "formData.newProjectMtp.value"
    }
  }), _c('span', {
    attrs: {
      "slot": "error-message"
    },
    slot: "error-message"
  }, [_vm._v(" " + _vm._s(_vm.formData.newProjectMtp.error) + " ")])], 1)], 1) : _vm.formData.requestType.value !== '' ? _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.pageLayout.HALF_ROW,
      expression: "pageLayout.HALF_ROW"
    }],
    attrs: {
      "cta-callback": _vm.clearGlobalIds,
      "is-valid": !_vm.formData.globalIds.error,
      "cta-label": "Clear all",
      "has-cta": "",
      "label": "Global IDs",
      "label-for": "globalIdInput"
    }
  }, [_c('pui-form-type-ahead', {
    staticClass: "global-id-type-ahead",
    attrs: {
      "options": _vm.globalIdOptions,
      "use-local-cache": false,
      "label": "globalIdInput",
      "multiple": ""
    },
    on: {
      "change": _vm.validateGlobalIdsAfterChange,
      "search": _vm.handleGlobalIdSearch
    },
    model: {
      value: _vm.formData.globalIds.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.globalIds, "value", $$v);
      },
      expression: "formData.globalIds.value"
    }
  }), _c('span', {
    attrs: {
      "slot": "error-message"
    },
    slot: "error-message"
  }, [_vm._v(" " + _vm._s(_vm.formData.globalIds.error) + " ")])], 1), _c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.pageLayout.HALF_ROW,
      expression: "pageLayout.HALF_ROW"
    }],
    attrs: {
      "label": "MTP",
      "label-for": "mtpSelect"
    }
  }, [_c('pui-form-select', {
    attrs: {
      "is-disabled": _vm.formData.mtp.disabled,
      "options": _vm.mtpOptions,
      "label": "mtpSelect",
      "search-input-id": "mtpSelect",
      "search-input-placeholder": "Select year"
    },
    on: {
      "change": _vm.validateGlobalIdsAfterChange
    },
    model: {
      value: _vm.formData.mtp.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.mtp, "value", $$v);
      },
      expression: "formData.mtp.value"
    }
  })], 1)], 1) : _vm._e(), _vm.isAdmin && _vm.formData.requestType.value !== '' ? _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.pageLayout.HALF_ROW,
      expression: "pageLayout.HALF_ROW"
    }],
    attrs: {
      "is-valid": !_vm.formData.miniFdType.error,
      "pui-popover-config": _vm.MINIFD_TYPE_POPOVER_CONFIG,
      "has-info-layer": "",
      "label": "MiniFD Type",
      "label-for": "miniFdType"
    }
  }, [_c('div', {
    attrs: {
      "slot": "pui-popover-content"
    },
    slot: "pui-popover-content"
  }, [_c('ul', {
    staticClass: "popover-info-list"
  }, _vm._l(_vm.MINIFD_TYPES_POPOVER_INFO, function (miniFdTypeInfo) {
    return _c('li', {
      key: miniFdTypeInfo.miniFdType,
      staticClass: "popover-info-list-item"
    }, [_c('p', {
      staticClass: "popover-info-request-type"
    }, [_vm._v(" " + _vm._s(miniFdTypeInfo.miniFdType) + ": ")]), _c('p', {
      staticClass: "popover-info-request-info"
    }, [_vm._v(" " + _vm._s(miniFdTypeInfo.miniFdInfo) + " ")])]);
  }), 0)]), _c('pui-form-select', {
    attrs: {
      "is-disabled": _vm.formData.miniFdType.disabled,
      "options": _vm.formData.miniFdType.options,
      "label": "MiniFD Type",
      "search-input-id": "miniFdType",
      "search-input-placeholder": "Select MiniFD Type"
    },
    model: {
      value: _vm.formData.miniFdType.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.miniFdType, "value", $$v);
      },
      expression: "formData.miniFdType.value"
    }
  }), _c('span', {
    attrs: {
      "slot": "error-message"
    },
    slot: "error-message"
  }, [_vm._v(" " + _vm._s(_vm.formData.miniFdType.error) + " ")])], 1)], 1) : _vm._e()])], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "modal-footer"
  }, [_c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('div', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.pageLayout.FULL_ROW,
      expression: "pageLayout.FULL_ROW"
    }],
    staticClass: "pui-comment-form__footer"
  }, [_c('pui-button', {
    attrs: {
      "state": "secondary",
      "type": "button"
    },
    on: {
      "click": _vm.closeModal
    }
  }, [_vm._v(" Cancel ")]), _c('pui-button', {
    on: {
      "click": function ($event) {
        $event.preventDefault();
        return _vm.onSubmit();
      }
    }
  }, [_vm._v(" Create ")])], 1)])])])])])])], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }