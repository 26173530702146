

















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';
import { Data } from '~/utils';

@Component({})
export default class BRLinkCellComponent extends Vue {
    private data = Data.Instance;
    private params?: ICellRendererParams;

    private get globalIds(): string[] {
        return this.params?.data.globalIds;
    }

    private get year(): string {
        return this.params?.data?.mtpYear?.toString();
    }

    private get requestType(): string {
        return this.data.constants.requestTypes[this.params?.data?.requestType];
    }

    private get miniFdStatus(): string {
        return this.data.constants.miniFdStatusesTypes[this.params?.data?.status];
    }

    private get isDisabled(): boolean {
        return this.requestType === 'New project' && this.miniFdStatus !== 'Approved'
    }

    private linkDetails(globalId: string): { path: string, query: Record<string, string> } {
        const isMiniFd = this.requestType === 'New project' && this.miniFdStatus === 'Approved' ? 'true' : undefined;
        const miniFdYear = isMiniFd && this.year ? this.year : '0';
        return {
            path: '/details-page',
            query: {
                globalId,
                year: this.year,
                miniFdYear,
            },
        }
    }
}
