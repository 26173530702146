import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { GetMiniFdByGlobalIdResponse, MiniFdStatus } from '~/models/services/minifd';
import { MasterService } from '~/services/master-service';
import { PuiLightbox } from '~/models/libraries/pebble-ui';
import { Data, User } from '~/utils';
import ConfirmIybrDeleteModalComponentTs
    from '~/components/in-year-budget-requests/view-in-year-budget-request/confirm-iybr-delete-modal/confirm-iybr-delete-modal';
import ConfirmIybrDeleteModalComponent
    from '~/components/in-year-budget-requests/view-in-year-budget-request/confirm-iybr-delete-modal/confirm-iybr-delete-modal.vue';
import { Permission } from '~/utils/permission';
import EditBudgetCompensationModalComponentTs
    from '~/components/in-year-budget-requests/view-in-year-budget-request/edit-budget-compensation-modal/edit-budget-compensation-modal';
import EditBudgetCompensationModalComponent
    from '~/components/in-year-budget-requests/view-in-year-budget-request/edit-budget-compensation-modal/edit-budget-compensation-modal.vue';
import { PlantAttributesItem } from '~/models/services/plants';
import EditApprovalModalComponentTs
    from '~/components/in-year-budget-requests/view-in-year-budget-request/edit-approval-modal/edit-approval-modal';
import EditApprovalModalComponent
    from '~/components/in-year-budget-requests/view-in-year-budget-request/edit-approval-modal/edit-approval-modal.vue';

type ActionButton = {
    text: string;
    iconName: string;
    onClick: () => void;
    shouldDisplay: boolean;
};

const REF_CONSTANTS = {
    SUBMIT_LOADING_MODAL: 'submitLoadingModal',
    CONFIRM_IYBR_DELETE_MODAL: 'confirmIybrDeleteModal',
    EDIT_BUDGET_COMPENSATION_MODAL: 'editBudgetCompensationModal',
    EDIT_APPROVAL_MODAL: 'editApprovalModal',
} as const;

enum MINI_FD_TYPE {
    MFD = 1,
    FD = 2,
    SFD = 3,
}

@Component({
    components: {
        confirmIybrDeleteModal: ConfirmIybrDeleteModalComponent,
        editBudgetCompensationModal: EditBudgetCompensationModalComponent,
        editApprovalModal: EditApprovalModalComponent,
    }
})
export default class ViewIybrActionButtonsComponent extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;
    private readonly MINI_FD_STATUS = Data.Instance.constants.miniFdStatusesTypes;
    private readonly MINI_FD_YEARS = Data.Instance.constants.miniFdYearFilters;

    $refs!: {
        [REF_CONSTANTS.SUBMIT_LOADING_MODAL]: PuiLightbox,
        [REF_CONSTANTS.CONFIRM_IYBR_DELETE_MODAL]: ConfirmIybrDeleteModalComponentTs,
        [REF_CONSTANTS.EDIT_BUDGET_COMPENSATION_MODAL]: EditBudgetCompensationModalComponentTs,
        [REF_CONSTANTS.EDIT_APPROVAL_MODAL]: EditApprovalModalComponentTs,
    };

    @Prop()
    private iybrData!: GetMiniFdByGlobalIdResponse;

    @Prop()
    private plantData!: PlantAttributesItem;

    @Prop()
    private currentUserKid?: string;

    @Prop({ default: () => [] })
    private contributorKids!: string[];

    private get actionButtons(): ActionButton[] {
        return [
            {
                text: 'Export to PDF',
                iconName: 'exported',
                onClick: this.onExportToPdfClick,
                shouldDisplay: this.shouldDisplayExportToPdf,
            },
            {
                text: 'Remove from MTP',
                iconName: 'delete',
                onClick: this.onRemoveFromMtpClick,
                shouldDisplay: this.shouldDisplayRemoveFromMtp,
            },
            {
                text: 'Edit Approval Workflow',
                iconName: 'edit',
                onClick: this.onEditApprovalClick,
                shouldDisplay: this.shouldDisplayEditApproval,
            },
            {
                text: 'Edit Budget Compensation Plan',
                iconName: 'edit',
                onClick: this.onEditBudgetCompensationClick,
                shouldDisplay: this.shouldDisplayEditBudgetCompensation,
            },
            {
                text: 'Edit Change Request',
                iconName: 'edit',
                onClick: this.onEditButtonClick,
                shouldDisplay: this.shouldDisplayEditButton,
            },
            {
                text: 'Submit Change Request',
                iconName: 'submit',
                onClick: this.onSubmitButtonClick,
                shouldDisplay: this.shouldDisplaySubmitButton,
            },
        ];
    }

    private get displayedActionButtons(): ActionButton[] {
        return this.actionButtons.filter(e => e.shouldDisplay).reverse();
    }

    private get contextMenuActionButtons(): ActionButton[] {
        return this.displayedActionButtons.slice(2);
    }

    private get largeActionButtons(): ActionButton[] {
        return this.displayedActionButtons.slice(0, 2).reverse();
    }

    private get isMiniFdApproved(): boolean {
        return this.iybrData.status === MiniFdStatus.APPROVED;
    }

    private get isMiniFdActive(): boolean {
        return !!this.iybrData.isActive;
    }

    private get isMiniFdCreator(): boolean {
        return this.currentUserKid === this.iybrData.createdBy;
    }

    private get isMiniFdContributor(): boolean {
        return this.contributorKids.includes(this.currentUserKid);
    }

    private get isActiveYear(): boolean {
        const selectedYear = this.iybrData.budgetRequestsMiniFinancialDecisions[0].year ?? 0;
        return !!this.MINI_FD_YEARS.find(year => year.year === selectedYear)?.isActive;
    }

    private get isSdFd(): boolean {
        return this.iybrData?.miniFdType === MINI_FD_TYPE.FD
            || this.iybrData?.miniFdType === MINI_FD_TYPE.SFD
    }

    private get shouldDisplayExportToPdf(): boolean {
        return this.isMiniFdApproved;
    }

    private get shouldDisplayRemoveFromMtp(): boolean {
        return User.hasPermission(Permission.DELETE_INACTIVE_CHANGE_REQUEST)
            && (this.iybrData.status === MiniFdStatus.DRAFT || this.iybrData.status === MiniFdStatus.REJECTED);
    }

    private get shouldDisplayEditApproval(): boolean {
        return User.hasPermission(Permission.BUDGET_COMPENSATION_SUPER_WRITE)
            && this.iybrData.status === MiniFdStatus.IN_REVIEW
            && this.isMiniFdActive
            && this.isActiveYear;
    }

    public get shouldDisplayEditBudgetCompensation(): boolean {
        return User.hasPermission(Permission.BUDGET_COMPENSATION_SUPER_WRITE) && this.isMiniFdActive && this.isActiveYear;
    }

    private get shouldDisplayEditButton(): boolean {
        const draftStatus = this.MINI_FD_STATUS[1];
        const approvedStatus = this.MINI_FD_STATUS[4];
        const currentStatus = this.getValueForConstant('miniFdStatusesTypes', this.iybrData.status);

        const isEveryoneAllowedToEdit = currentStatus === draftStatus;
        const isCurrentUserAllowedToEdit = (currentStatus !== approvedStatus && (this.isMiniFdCreator || this.isMiniFdContributor));
        const isAdminAllowedToEdit = currentStatus !== approvedStatus && User.hasPermission(Permission.BUDGET_COMPENSATION_SUPER_WRITE);
        const isMiniFdTypeAndAdminAllowedToEdit = this.isSdFd && User.hasPermission(Permission.SD_FD_EDIT);

        return (isEveryoneAllowedToEdit || isCurrentUserAllowedToEdit || isAdminAllowedToEdit || isMiniFdTypeAndAdminAllowedToEdit) && this.isMiniFdActive && this.isActiveYear;
    }

    private get shouldDisplaySubmitButton(): boolean {
        const draftStatus = this.MINI_FD_STATUS[1];

        return this.getValueForConstant('miniFdStatusesTypes', this.iybrData.status) === draftStatus
            && this.shouldDisplayEditButton
            && this.isMiniFdActive;
    }

    private async onExportToPdfClick(): Promise<void> {
        const content = await MasterService.instance.miniFdService.exportToPdf(this.iybrData.uniqueId.trim());

        if (content) {
            this.forceFileDownload(content.name, content.content);
        }
    }

    private onRemoveFromMtpClick(): void {
        this.$refs[REF_CONSTANTS.CONFIRM_IYBR_DELETE_MODAL].openLightbox();
    }

    private onEditApprovalClick(): void {
        this.$refs[REF_CONSTANTS.EDIT_APPROVAL_MODAL].openLightbox()
    }

    private onEditBudgetCompensationClick(): void {
        this.$refs[REF_CONSTANTS.EDIT_BUDGET_COMPENSATION_MODAL].openLightbox();
    }

    private onEditButtonClick(): void {
        this.$router.push({
            path: '/edit-in-year-budget-request',
            query: {
                uniqueId: this.iybrData?.uniqueId.trim()
            }
        }).then();
    }

    private async onSubmitButtonClick(): Promise<void> {
        try {
            this.$refs[REF_CONSTANTS.SUBMIT_LOADING_MODAL].open();
            await MasterService.instance.miniFdService.changeStatus(this.iybrData.uniqueId, MiniFdStatus.SUBMITTED);
            this.$bvToast.show('toast-successful-submit-draft-change-request');
            this.emitReloadData();
            this.$refs[REF_CONSTANTS.SUBMIT_LOADING_MODAL].close();
        } catch {
            this.$refs[REF_CONSTANTS.SUBMIT_LOADING_MODAL].close();
            this.$bvToast.show('toast-error-submit-draft-change-request');
        }
    }

    private emitReloadData(): void {
        this.$emit('reload:iybr-data');
    }

    private forceFileDownload(name: string, data: BlobPart): void {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', decodeURIComponent(name));
        document.body.appendChild(link);
        link.click();
    }

    private getValueForConstant(constantType: string, index?: string | number): string {
        if (!index) {
            return 'N/A';
        }

        const constantValue = Data.Instance.constants[constantType][index];

        if (!constantValue) {
            return 'Unknown';
        }

        return constantValue;
    }
}
