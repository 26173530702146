import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class TableAttachmentComponent extends Vue {
    @Prop()
    attachmentPosition!: 'top' | 'bottom';

    private get attachmentClasses(): string[] {
        let classes = ['table-attachment'];

        if (this.attachmentPosition === 'top') {
            classes.push('top-attached');
        } else if (this.attachmentPosition === 'bottom') {
            classes.push('bottom-attached');
        }

        return classes;
    }
}
