import { Connection } from '~/utils/';
import {
    Country,
    GetBudgetDistributionPayload, GetBudgetDistributionResponse, GetCountriesResponse,
    GetMtpStatusDistributionPayload,
    GetMtpStatusDistributionResponse, GetOverviewBudgetDistributionResponse
} from '~/models/services/chart';
import { ApiResponse } from '~/models/services/common';

export class ChartService {
    private readonly api: Connection = new Connection();

    public async getMtpStatusDistribution(mtpStatusDistribution: GetMtpStatusDistributionPayload): Promise<GetMtpStatusDistributionResponse> {
        return (await this.api.post<ApiResponse<GetMtpStatusDistributionResponse>>('chart/get-mtp-status-distribution/', mtpStatusDistribution)).data.result;
    }

    public async getBudgetDistribution(budgetDistributionRequest: GetBudgetDistributionPayload): Promise<GetBudgetDistributionResponse> {
        return (await this.api.post<ApiResponse<GetBudgetDistributionResponse>>('chart/get-budget-distribution/', budgetDistributionRequest)).data.result;
    }

    public async getCountries(): Promise<Country[]> {
        return (await this.api.get<ApiResponse<GetCountriesResponse>>('chart/get-countries')).data.result.countries;
    }

    public async getOverviewBudgetDistribution(countryCode: string, mtpYear: string | number, miniFdYear: string | number): Promise<GetOverviewBudgetDistributionResponse> {
        let queryStringParameters: string[] = [];

        if (countryCode) {
            queryStringParameters.push(`countryCode=${countryCode}`);
        }

        if (mtpYear !== undefined && mtpYear !== null) {
            queryStringParameters.push(`mtpYear=${mtpYear}`);
        }

        if (miniFdYear !== undefined && miniFdYear !== null) {
            queryStringParameters.push(`miniFdYear=${miniFdYear}`);
        }

        let queryString = '?' + queryStringParameters.join('&');

        return (await this.api.get<ApiResponse<GetOverviewBudgetDistributionResponse>>('chart/get-budget-distribution-overview' + queryString)).data.result;
    }
}
