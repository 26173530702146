import { Connection } from '~/utils/';
import { MtpPeriod, GetMtpDataResponse, GetAllMtpPeriodsResponse, GetMtpYearsResponse, GetMtpTagsResponse, AddMtpTagsPayload, DeleteMtpTagsPayload } from '~/models/services/mtp';
import { ApiResponse } from '~/models/services/common';
import { Tag } from '~/models/services/tags';

export class MtpService {
    private readonly api = new Connection();

    public async getMtpData(budgetRequestId: string, mtpYear: number | string, miniFdYear: number | string): Promise<GetMtpDataResponse> {
        let queryStringParameters: string[] = [];

        if (mtpYear !== undefined && mtpYear !== null) {
            queryStringParameters.push(`mtpYear=${mtpYear}`);
        }

        if (miniFdYear !== undefined && miniFdYear !== null) {
            queryStringParameters.push(`miniFdYear=${miniFdYear}`);
        }

        const queryString = '?' + queryStringParameters.join('&');

        return (await this.api.get<ApiResponse<GetMtpDataResponse>>('mtp/' + budgetRequestId + queryString)).data.result;
    }

    public async addMtpPeriod(newMtpPeriod: MtpPeriod): Promise<MtpPeriod> {
        return (await this.api.post<ApiResponse<MtpPeriod>>('mtp-period', newMtpPeriod)).data.result;
    }

    public async editMtpPeriod(newMtpPeriod: MtpPeriod): Promise<MtpPeriod> {
        return (await this.api.put<ApiResponse<MtpPeriod>>('mtp-period', newMtpPeriod)).data.result;
    }

    public async getMtpPeriods(): Promise<MtpPeriod[]> {
        return (await this.api.get<ApiResponse<GetAllMtpPeriodsResponse>>('mtp-period/list')).data.result.mtpPeriods;
    }

    public async GetMtpPeriod(mtpYear: number | string): Promise<MtpPeriod> {
        return (await this.api.get<ApiResponse<MtpPeriod>>('mtp-period/' + mtpYear)).data.result;
    }

    public async getMtpYears(): Promise<number[]> {
        return (await this.api.get<ApiResponse<GetMtpYearsResponse>>('mtp/mtp-years')).data.result.mtpList;
    }

    public async getMtpTags(mtpId): Promise<Tag[]> {
        return (await this.api.get<ApiResponse<GetMtpTagsResponse>>(`mtp/list-tags?mtpId=${mtpId}`)).data.result.tags;
    }

    public async addMtpTags(payload: AddMtpTagsPayload): Promise<number> {
        return (await this.api.post<ApiResponse<{ tagsAdded: number }>>('mtp/add-tags', payload)).data.result.tagsAdded;
    }

    public async deleteMtpTags(payload: DeleteMtpTagsPayload): Promise<number> {
        return (await this.api.delete('mtp/delete-tags', { data: payload })).data.result.tagsDeleted;
    }

    public async deleteInactiveMtp(ids: string[]): Promise<any> {
        const params = new URLSearchParams();
        ids.forEach(id => params.append('BudgetIds', id));

        return (await this.api.get<ApiResponse<any>>(`mtp/delete-inactive-mtp?${params.toString()}`)).data.result;
    }
}
