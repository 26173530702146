import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class HomeComponent extends Vue {

    created() {
        if (sessionStorage.redirectUrl && sessionStorage.redirectUrl.length > 0) {
            this.$router.push(sessionStorage.redirectUrl);
        } else {
            this.$router.push('/budget-requests');
        }

    }
}
