var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.globalIds, function (globalId, index) {
    return _c('span', {
      key: globalId
    }, [_c('router-link', {
      class: {
        disabled: _vm.isDisabled
      },
      attrs: {
        "to": _vm.linkDetails(globalId)
      }
    }, [_vm._v(" " + _vm._s(globalId) + " ")]), index != _vm.globalIds.length - 1 ? _c('span', [_vm._v(", ")]) : _vm._e()], 1);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }