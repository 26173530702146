import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import '~/components/home/home.scss';
import { Data, Helper, User } from '~/utils';
import { PlantsService } from '~/services/plants-service';
import { History, HistorySearchModel } from '~/utils/interfaces';
import moment from 'moment-timezone';
import { Permission } from "~/utils/permission"
import { PlantItem } from "~/models/services/plants"
import { nullIfNaN } from '~/utils/math-helper';
import { MasterService } from '~/services/master-service'

@Component({})
export default class ActivityTrackingComponent extends Vue {
    public history: History = {
        plantActivityTrackings: [],
        total: ''
    };
    public PlantsService = MasterService.instance.plantsService;
    public elementsPerPage: number = 10;
    public firstElementTable: number = 1;
    public numberOfElementTable: number = this.elementsPerPage;
    public currentPage: number = 1;
    public yearBr = null;
    private data: Data = Data.Instance;
    private userPermissions: Permission[] = [];
    private isAccessDeniedModalActive: boolean = false;
    private loading: boolean = false;
    private searchModel: HistorySearchModel = <HistorySearchModel>{};
    private selectedPlants: string[] = [];
    private selectedActivityTypes: string[] = [];
    private selectedDate: string = null;
    private activeButtonApplyFilter: boolean = false;
    private globalIdSearch = '';
    private startDate: Date = null;
    private endDate: Date = null;
    private showDropdown = false;
    private disabledDates = false;
    private showTimePanel = false;
    private openTimePanelStartDate = false;
    private openTimePanelEndDate = false;
    private selectedMtp = new Date(Date.now());

    constructor() {
        super();
    }

    async created(): Promise<void> {
        if (localStorage.selectedMtp) {
            this.selectedMtp = localStorage.selectedMtp;
        }
        this.userPermissions = await User.getPerm();
        if (!this.userPermissions.includes(Permission.SINGLE_SITE_PAGE_READ)) {
            $('#accessDeniedModal').modal('show');
            this.isAccessDeniedModalActive = true;
        }
    }

    async mounted(): Promise<void> {
        if (this.data.constants) {
            await this.load();
        }
    }

    @Watch('data.selectedPlant')
    async onSelectedPlantChanged(val: PlantItem[]): Promise<void> {
        if (val !== null) {
            // Update url query strings
            const plantIds = val.map(p => p.plantSid).join(',');
            this.$router.push({
                path: '/activity-tracking',
                query: {
                    plantids: this.$route.query.selectedPlantIds ? this.$route.query.selectedPlantIds : plantIds,
                    activitytypes: this.$route.query.activitytypes,
                    date: this.$route.query.date,
                    startDate: this.$route.query.startDate,
                    endDate: this.$route.query.endDate,
                    selectedPlantIds: this.$route.query.selectedPlantIds,
                }
            }).catch(err => {
                if (err.name !== 'NavigationDuplicated') {
                    console.error(err);
                }
            });

            await this.load();
        }
    }

    @Watch('selectedDate')
    async onValueChanged(val: string): Promise<void> {
        if (val != null) {
            this.disabledDates = true;
            this.startDate = null;
            this.endDate = null;
        } else {
            this.disabledDates = false;
        }
    }

    beforeRouteLeave(to, from, next): void {
        this.$bvToast.hide('toast-error');
        sessionStorage.removeItem('redirectUrl');
        if (this.isAccessDeniedModalActive) {
            next(false);
        } else {
            next(true);
        }
    }

    public async changeNavPageBack(): Promise<void> {
        this.loading = true;
        if (this.currentPage !== 1) {
            this.currentPage = this.currentPage - 1;
            this.searchModel.pageNo = this.currentPage;
            this.numberOfElementTable = this.numberOfElementTable - this.history.plantActivityTrackings.length;
            await this.getDataActivityTracking();
            this.firstElementTable = this.firstElementTable - this.history.plantActivityTrackings.length;
        }
        this.loading = false;
    }

    public async changeNavPageNext(): Promise<void> {
        this.loading = true;
        if (Math.ceil(parseInt(this.history.total) / this.elementsPerPage) > this.currentPage) {
            this.currentPage = this.currentPage + 1;
            this.searchModel.pageNo = this.currentPage;
            this.firstElementTable = this.firstElementTable + this.history.plantActivityTrackings.length;
            await this.getDataActivityTracking();
            this.numberOfElementTable = this.numberOfElementTable + this.history.plantActivityTrackings.length;
        }
        this.loading = false;
    }

    private async load(): Promise<void> {
        this.loading = true;
        this.data.navBarRefresh = true;
        if (this.$route.query && this.$route.query.plantids) {
            this.selectedPlants = this.$route.query.plantids.toString().split(',');
            console.log(this.$route.query.plantids, 'selectedPlants');

        } else {
            this.selectedPlants = [];
            if (this.data.selectedPlant) {
                this.data.selectedPlant.forEach(plant => {
                    this.selectedPlants.push(plant.plantSid.toString());
                });
            }
        }

        if (this.$route.query.activitytypes) {
            this.selectedActivityTypes = this.$route.query.activitytypes.toString().split(',');
        }

        if (this.$route.query.date !== undefined) {
            this.selectedDate = this.$route.query.date as string;
        }
        if (this.selectedPlants.length > 0) {
            this.searchModel = <HistorySearchModel>{
                globalId: '',
                plantIds: this.selectedPlants,
                itemsPerPage: this.elementsPerPage,
                pageNo: this.currentPage,
                filters: {
                    activityTypes: this.selectedActivityTypes.map(e => parseInt(e)),
                    date: nullIfNaN(parseInt(this.selectedDate)),
                    range: null
                }
            };
            if (this.$route.query.startDate) {
                this.startDate = new Date((decodeURIComponent(this.$route.query.startDate.toString())));
                if (!this.$route.query.endDate) {
                    this.endDate = new Date();
                    this.$route.query.endDate = moment(new Date(this.endDate)).toISOString();
                }
                this.endDate = new Date((decodeURIComponent(this.$route.query.endDate.toString())));
                if (this.startDate || this.endDate) {
                    this.searchModel.filters.range = {
                        startDate: moment(new Date(this.startDate)).toISOString(),
                        endDate: moment(new Date(this.endDate)).toISOString(),
                    };
                }
            }
            await this.getDataActivityTracking();
            if (this.history.plantActivityTrackings) {
                this.numberOfElementTable = this.history.plantActivityTrackings.length;
            }
        }
        this.loading = false;
        this.data.navBarRefresh = false;
    }

    private async getDataActivityTracking(): Promise<void> {
        this.loading = true;
        try {
            this.history = await this.PlantsService.GetPlantHistory(this.searchModel);
        } catch (error) {
            if (error) {
                this.$router.push('budget-requests');
            }
        }

        if (this.history.plantActivityTrackings) {
            this.formatHistoryDates();
        }

        this.loading = false;
    }

    private formatHistoryDates(): void {
        this.history.plantActivityTrackings.forEach((a) => {
            a['year'] = moment(Helper.UtcToLocalTime(a.date)).format('YYYY');
            a.date = moment(Helper.UtcToLocalTime(a.date)).format('DD/MM/YYYY HH:mm:ss');
        });
    }

    private getHistorySearchModelClean(): void {
        this.searchModel = <HistorySearchModel>{
            globalId: '',
            plantIds: this.selectedPlants,
            itemsPerPage: this.elementsPerPage,
            pageNo: this.currentPage,
            filters: {
                activityTypes: [],
                date: null,
                range: null
            }
        };
    }

    private async reloadTableChangeNumberElement(): Promise<void> {
        this.loading = true;
        this.currentPage = 1;
        this.searchModel.pageNo = this.currentPage;
        this.firstElementTable = 1;
        this.searchModel.itemsPerPage = this.elementsPerPage;
        await this.getDataActivityTracking();
        if (this.history.plantActivityTrackings) {
            this.numberOfElementTable = this.history.plantActivityTrackings.length;
        }
        this.loading = false;
    }

    private onClickCancel(value: boolean): void {
        this.isAccessDeniedModalActive = value;
    }

    private clearFilterSelected(selected: string, filters: string, range?: string): void {

        if (range) {
            this.showDropdown = true;
        } else {
            this.showDropdown = false;
        }
        this.activeButtonApplyFilter = false;
        this[selected] = this.searchModel.filters[filters];
    }

    private nameOfFilters(nameFilters: string, nameConstant: string, isChild: string): string[] {
        let localName = [''];

        this.searchModel.filters[nameFilters].forEach((e: number) => {
            if (isChild !== null) {
                localName.push(this.data.constants[nameConstant][e][isChild] + ', ');
            } else {
                localName.push(this.data.constants[nameConstant][e] + ', ');
            }
        });

        if (localName)
            localName[localName.length - 1] = localName[localName.length - 1].split(',')[0];
        return localName;

    }

    private nameOfDateFilter(nameFilters: string, nameConstant: string): string[]  {
        let localName = null;

        if (this.searchModel.filters[nameFilters] !== null) {
            localName = this.data.constants[nameConstant][this.searchModel.filters[nameFilters]];
        }

        return localName;
    }

    private async applyActivityFilter(): Promise<void> {
        this.searchModel.filters.activityTypes = this.selectedActivityTypes.map(e => parseInt(e));
        this.resetInitialTable();
        await this.getDataActivityTracking();
        if (this.history.plantActivityTrackings) {
            this.numberOfElementTable = this.history.plantActivityTrackings.length;
        }

        // Update url query strings
        let activityTypes = this.selectedActivityTypes.join(',');
        let queryActivityFilter = {};
        if (this.$route.query.plantids && !this.$route.query.selectedPlantIds) {
            queryActivityFilter['plantids'] = this.$route.query.plantids;
        }
        if (this.$route.query.selectedPlantIds) {
            queryActivityFilter['plantids'] = this.$route.query.selectedPlantIds;
            queryActivityFilter['selectedPlantIds'] = this.$route.query.selectedPlantIds;
        }
        if (this.$route.query.date) {
            queryActivityFilter['date'] = this.$route.query.date;
        }
        if (this.$route.query.startDate) {
            queryActivityFilter['startDate'] = this.$route.query.startDate;
        }
        if (this.$route.query.endDate) {
            queryActivityFilter['endDate'] = this.$route.query.startDate;
        }
        if (activityTypes) {
            queryActivityFilter['activitytypes'] = activityTypes;
        }
        this.$router.push({ path: '/activity-tracking', query: queryActivityFilter }).catch(err => {
            if (err.name !== 'NavigationDuplicated') {
                console.error(err);
            }
        });

    }

    private async applyDateFilter(): Promise<void> {
        this.searchModel.filters.date = nullIfNaN(parseInt(this.selectedDate));
        this.showDropdown = false;
        if (this.startDate) {
            this.searchModel.filters.range = {
                startDate: moment.utc(new Date(this.startDate)).toISOString(),
                endDate: moment(new Date()).toISOString(),
            };
        } else {
            this.searchModel.filters.range = null;
        }
        if (this.startDate && this.endDate) {
            this.searchModel.filters.range = {
                startDate: moment.utc(new Date(this.startDate)).toISOString(),
                endDate: this.endDate === null ? moment(new Date()).toISOString() : moment(new Date(this.endDate)).toISOString(),
            };
        }
        this.resetInitialTable();
        await this.getDataActivityTracking();
        if (this.history.plantActivityTrackings) {
            this.numberOfElementTable = this.history.plantActivityTrackings.length;
        }
        let queryDataFilter = {};
        if (this.$route.query.plantids && !this.$route.query.selectedPlantIds) {
            queryDataFilter['plantids'] = this.$route.query.plantids;
        }
        if (this.$route.query.selectedPlantIds) {
            queryDataFilter['plantids'] = this.$route.query.selectedPlantIds;
            queryDataFilter['selectedPlantIds'] = this.$route.query.selectedPlantIds;
        }
        if (this.$route.query.activitytypes) {
            queryDataFilter['activitytypes'] = this.$route.query.activitytypes;
        }
        if (this.startDate) {
            queryDataFilter['startDate'] = encodeURIComponent(moment(Helper.UtcToLocalTime(this.startDate)).format('MM/DD/YYYY HH:mm:ss'));
        }
        if (this.endDate) {
            queryDataFilter['endDate'] = encodeURIComponent(moment(Helper.UtcToLocalTime(this.endDate)).format('MM/DD/YYYY HH:mm:ss'));
        }
        if (this.selectedDate) {
            queryDataFilter['date'] = this.selectedDate;
        }
        this.$router.push({ path: '/activity-tracking', query: queryDataFilter }).catch(err => {
            if (err.name !== 'NavigationDuplicated') {
                console.error(err);
            }
        });

    }

    private notAfterEndDate(date: Date): boolean {
        if (this.endDate) {
            return date >= this.endDate;
        } else {
            return false;
        }
    }

    private notBeforeStartDate(date: Date): boolean {
        return date <= this.startDate;
    }

    private resetInitialTable(): void {
        this.currentPage = 1;
        this.searchModel.pageNo = this.currentPage;
        this.firstElementTable = 1;
    }

    private clearFilter(filter: string): void {
        this[filter] = [];
        this.activeButtonApplyFilter = true;
    }

    private clearDateFilter(filter: string): void {
        this.selectedDate = null;
        this.startDate = null;
        this.endDate = null;
        this.activeButtonApplyFilter = true;
    }

    private activeButtonApply(): void {
        this.activeButtonApplyFilter = true;
    }

    private async resetFilters(): Promise<void> {
        this.resetInitialTable();
        this.getHistorySearchModelClean();
        this.selectedActivityTypes = [];
        this.selectedDate = null;
        this.globalIdSearch = '';

        await this.getDataActivityTracking();
        if (this.history.plantActivityTrackings) {
            this.numberOfElementTable = this.history.plantActivityTrackings.length;
        }

        // Update url query strings
        this.$router.push({
            path: '/activity-tracking',
            query: {
                plantids: this.$route.query.plantids,
                selectedPlantIds: this.$route.query.selectedPlantIds,
            }
        }).catch(err => {
            // Ignore the vuex err regarding  navigating to the page they are already on.
            if (err.name !== 'NavigationDuplicated') {
                // But print any other errors to the console
                console.error(err);
            }
        });
    }

    private goToBrMtpYear(year: number): void {
        localStorage.setItem('selectedMtp', year.toString());
        this.$router.push('/budget-requests');
    }

    private async searchByGlobalId(): Promise<void> {
        this.searchModel.globalId = this.globalIdSearch;
        this.loading = true;
        this.resetInitialTable();
        await this.getDataActivityTracking();
        this.loading = false;
    }

    private handleCloseDate(): void {
        this.showTimePanel = false;
        this.activeButtonApplyFilter = false;
        if (this.startDate) {
            this.activeButtonApply();
        }
        if (!this.startDate && !this.endDate) {
            this.activeButtonApply();
        }
    }

    private hide(): void {
        this.showDropdown = true;
        let x = document.getElementsByClassName('mx-datepicker-popup');
        if (x.length === 0) {
            this.showDropdown = false;
        }
    }

    private toggleTimePanel(): void {
        this.showTimePanel = !this.showTimePanel;
    }

    private displayStartDate(): string {
        return moment(Helper.UtcToLocalTime(this.searchModel.filters.range.startDate)).format('DD/MM/YYYY HH:mm:ss');
    }

    private displayEndDate(): string {
        return moment(Helper.UtcToLocalTime(this.searchModel.filters.range.endDate)).format('DD/MM/YYYY HH:mm:ss');
    }

    private toggleDatePanelStartDate(): void {
        this.openTimePanelStartDate = !this.openTimePanelStartDate;
        this.openTimePanelEndDate = false;
    }

    private toggleDatePanelEndDate(): void {
        this.openTimePanelEndDate = !this.openTimePanelEndDate;
        this.openTimePanelStartDate = false;
    }
}
