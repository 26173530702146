import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { SelectOptions } from '~/components/in-year-budget-requests/add-in-year-budget-requests-modal/options';
import { ROWS } from '~/components/in-year-budget-requests/constants/rows';
import { MasterService } from '~/services/master-service';
import { Department, SelectedStakeholder, Stakeholder } from '~/models/services/minifd';
import { UAMUser } from '~/models/services/uam';

@Component({})
export default class SearchApproverComponent extends Vue {
    @Prop()
    label: string;

    @Prop()
    department: Department;

    private readonly formLayout = ROWS;
    private readonly uamService = MasterService.instance.uamService;
    private readonly MAX_NUMBER_OF_RESULTS = 5;

    private searchResults: SelectOptions[] = [];
    private selectedUser: SelectedStakeholder = null;
    private isFormLoading = false;
    private isDepartmentSet = false;
    private isUserInputValid = true;
    private userInput = null;
    private existingId = undefined;

    public getSelectedUser(): SelectedStakeholder {
        if (!this.selectedUser) {
            return;
        }

        return {
            id: this.existingId,
            ...this.selectedUser
        };
    }

    public highlightInvalidUserInput(): void {
        this.isUserInputValid = false;
    }

    public populateStakeholderData(stakeholder: Stakeholder): void {
        if (stakeholder.id) {
            this.existingId = stakeholder.id;
        }

        this.selectedUser = {
            name: stakeholder.name,
            kId: stakeholder.kId,
            departmentId: stakeholder.departmentId,
            isReviewer: true
        };

        this.userInput = {
            label: stakeholder.name,
            value: stakeholder.kId
        };

        this.isUserInputValid = true;
    }

    private async listUsers(input: string): Promise<void> {
        this.clearSearchResults();
        if (input) {
            this.isFormLoading = true;
            const users = await this.fetchUsers(input);
            this.isFormLoading = false;
            if (users) {
                if (users.length >= this.MAX_NUMBER_OF_RESULTS) {
                    for (let i = 0; i < this.MAX_NUMBER_OF_RESULTS; i++) {
                        const result = {
                            label: users[i].name,
                            value: users[i].kid,
                        };
                        this.searchResults.push(result);
                    }
                } else {
                    users.forEach((user) => {
                        const result = {
                            label: user.name,
                            value: user.kid,
                        };
                        this.searchResults.push(result);
                    });
                }
            }
        }
    }

    private async fetchUsers(input: string): Promise<UAMUser[]> {
        return await this.uamService.SearchUsers(input);
    }

    private hasSelectedUser(): void {
        if (this.userInput && this.department) {
            this.selectedUser = {
                name: this.userInput[0].label,
                kId: this.userInput[0].value,
                departmentId: this.department.id,
                isReviewer: true
            };
            this.isUserInputValid = true;
        }
    }

    private clearSearchResults(): void {
        this.searchResults.length = 0;
    }

    private checkIfDepartmentIsSet(): void {
        if (this.department) {
            this.isDepartmentSet = true;
        }
    }

    private checkEmptyInput(): void {
        if (this.userInput && this.userInput.length === 0) {
            this.selectedUser = null;
        }
    }
}
