var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.userPermissions.includes('OverviewPageView') ? _c('div', {
    staticClass: "container-fluid overview"
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "can-cancel": true
    },
    on: {
      "update:active": function ($event) {
        _vm.loading = $event;
      }
    }
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-xl-4 pt-4"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedCountry,
      expression: "selectedCountry"
    }],
    staticClass: "form-control",
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.selectedCountry = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, function ($event) {
        return _vm.changeCountry();
      }]
    }
  }, [_c('option', {
    attrs: {
      "selected": ""
    },
    domProps: {
      "value": null
    }
  }, [_vm._v(" All countries ")]), _vm._l(_vm.countries, function (country, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": country.code
      }
    }, [_vm._v(" " + _vm._s(country.name) + " ")]);
  })], 2)]), _vm.years ? _c('div', {
    staticClass: "col-12 col-xl-4 pt-4"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedMtpFilter,
      expression: "selectedMtpFilter"
    }],
    staticClass: "form-control",
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.selectedMtpFilter = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, _vm.changeMtpYear]
    }
  }, _vm._l(_vm.years, function (mtpFilter, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": ((mtpFilter.year) + "-" + (mtpFilter.miniFdYear))
      }
    }, [_vm._v(" " + _vm._s(mtpFilter.description) + " ")]);
  }), 0)]) : _vm._e()]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "wrapper_box mt-5"
  }, [_c('div', {
    staticClass: "row"
  }, _vm._l(_vm.chartSeries, function (data, index) {
    return _c('div', {
      key: index,
      staticClass: "col-lg-6 col-xl-3"
    }, [_c('h5', [_vm._v(_vm._s(data.title))]), _c('apexchart', {
      attrs: {
        "options": _vm.chartOptions,
        "series": data.series,
        "type": "donut",
        "width": "340"
      }
    }), index !== _vm.chartSeries.length - 1 ? _c('div', {
      staticClass: "vertical-bar"
    }) : _vm._e()], 1);
  }), 0)])]), _c('files-budget-request', {
    attrs: {
      "is-active": true,
      "is-overview-page": true,
      "selected-mtp-filter": _vm.mtpYear
    }
  })], 1) : _c('div', [_c('div', {
    staticClass: "container-fluid pt-3"
  }, [_c('access-denied-modal', {
    on: {
      "clickedcanceleditstate": _vm.onClickCancel
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }