var render = function () {
  var _vm$iybrData, _vm$iybrData2, _vm$iybrData3, _vm$iybrData4, _vm$iybrData5, _vm$iybrData6, _vm$iybrData7, _vm$iybrData8, _vm$iybrData9, _vm$iybrData10, _vm$iybrData11, _vm$iybrData12, _vm$iybrData13, _vm$plantData, _vm$iybrData14, _vm$iybrData15, _vm$iybrData16, _vm$iybrData16$extern, _vm$iybrData17, _vm$iybrData18, _vm$iybrData19, _vm$iybrData20, _vm$iybrData21, _vm$iybrData22, _vm$iybrData23, _vm$iybrData24, _vm$iybrData25, _vm$iybrData26, _vm$iybrData27, _vm$iybrData28, _vm$iybrData29, _vm$iybrData29$outage, _vm$iybrData30, _vm$iybrData31, _vm$iybrData32, _vm$iybrData33, _vm$iybrData34, _vm$iybrData35, _vm$iybrData36, _this$plantData, _vm$iybrData37, _vm$iybrData38, _vm$iybrData39, _vm$iybrData40, _vm$iybrData41, _vm$iybrData42, _vm$iybrData43, _vm$iybrData43$budget, _vm$iybrData44, _vm$iybrData45, _vm$iybrData46, _vm$iybrData47, _vm$iybrData48, _vm$iybrData49, _vm$iybrData$tags, _vm$miniFdId;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "view-iybr-wrapper"
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "can-cancel": true
    },
    on: {
      "update:active": function ($event) {
        _vm.loading = $event;
      }
    }
  }), _c('pui-grid-container', [_c('pui-grid-row', [_c('pui-grid-column', [_c('pui-breadcrumb', {
    attrs: {
      "links": _vm.breadcrumbLinks
    },
    on: {
      "changed:active-route": _vm.onBreadcrumbLinkClick
    }
  })], 1)], 1), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.HALF_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "iybr-headline",
    attrs: {
      "type": "h3"
    }
  }, [_vm._v(" " + _vm._s((_vm$iybrData = _vm.iybrData) === null || _vm$iybrData === void 0 ? void 0 : _vm$iybrData.projectName) + " ")])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.HALF_ROW
    }
  }, [_vm.iybrData && _vm.plantData ? _c('view-iybr-action-buttons', {
    ref: _vm.REF_CONSTANTS.ACTION_BUTTONS,
    attrs: {
      "iybr-data": _vm.iybrData,
      "plant-data": _vm.plantData,
      "current-user-kid": _vm.currentUserKid,
      "contributor-kids": _vm.contributorsKids
    },
    on: {
      "reload:iybr-data": _vm.loadData
    }
  }) : _vm._e()], 1)], 1), _c('pui-grid-row', {
    staticClass: "menu-details"
  }, [_c('pui-grid-column', [_c('a', {
    staticClass: "menu-details",
    class: _vm.isHistoryTabActive ? 'pr-3 history' : 'tab-active',
    on: {
      "click": function ($event) {
        return _vm.goToDetails();
      }
    }
  }, [_vm._v("Change request ")]), _c('a', {
    staticClass: "menu-details",
    class: _vm.isHistoryTabActive ? 'tab-active' : 'pl-3 history',
    on: {
      "click": function ($event) {
        return _vm.goToHistory();
      }
    }
  }, [_vm._v("History ")])])], 1), !_vm.isHistoryTabActive ? _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.TWO_THIRDS_ROW
    }
  }, [_c('pui-collapsible', {
    staticClass: "mb-4",
    attrs: {
      "headline": "DETAILS"
    }
  }, [_c('pui-grid-row', {
    staticClass: "p-4"
  }, [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.FULL_ROW
    }
  }, [_c('div', {
    staticClass: "heading-flex"
  }, [_c('pui-headline', {
    staticClass: "text-bolded",
    attrs: {
      "type": "h4"
    }
  }, [_vm._v(" " + _vm._s((_vm$iybrData2 = _vm.iybrData) === null || _vm$iybrData2 === void 0 ? void 0 : _vm$iybrData2.projectName) + " ")]), _c('div', {
    staticClass: "subheading-flex"
  }, [!((_vm$iybrData3 = _vm.iybrData) !== null && _vm$iybrData3 !== void 0 && _vm$iybrData3.isActive) ? [_c('pui-headline', {
    staticClass: "text-bolded",
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" Inactive ")]), _c('div', {
    staticClass: "vertical-separator"
  })] : _vm._e(), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" Unique ID: " + _vm._s((_vm$iybrData4 = _vm.iybrData) === null || _vm$iybrData4 === void 0 ? void 0 : _vm$iybrData4.uniqueId) + " ")]), _c('div', {
    staticClass: "vertical-separator"
  }), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.getValueForConstant('miniFdStatusesTypes', (_vm$iybrData5 = _vm.iybrData) === null || _vm$iybrData5 === void 0 ? void 0 : _vm$iybrData5.status)) + " ")]), _c('div', {
    staticClass: "vertical-separator"
  }), _c('pui-headline', {
    staticClass: "text-bolded",
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s((_vm$iybrData6 = _vm.iybrData) === null || _vm$iybrData6 === void 0 ? void 0 : _vm$iybrData6.createdBy) + " ")])], 2)], 1)]), _vm.stakeholders.length !== 0 ? _c('pui-grid-column', {
    staticClass: "mt-5",
    attrs: {
      "cols": _vm.pageLayout.FULL_ROW
    }
  }, [_c('div', {
    staticClass: "heading-flex"
  }, _vm._l(_vm.stakeholders, function (stakeholder, index) {
    return _c('div', {
      key: index,
      staticClass: "approver-item"
    }, [_c('pui-headline', {
      staticClass: "text-bolded",
      attrs: {
        "type": "h5"
      }
    }, [_vm._v(" " + _vm._s(stakeholder.departmentGroupName) + " ")]), _c('pui-headline', {
      class: stakeholder.approvalDate ? 'text-bolded' : 'text-muted',
      attrs: {
        "type": "h6"
      }
    }, [stakeholder.approvalDate ? _c('pui-icon', {
      attrs: {
        "icon-color": "green",
        "icon-name": "check"
      }
    }) : stakeholder.rejectionDate ? _c('pui-icon', {
      attrs: {
        "icon-color": "red",
        "icon-name": "close"
      }
    }) : _vm._e(), _vm._v(" " + _vm._s(stakeholder.name) + " ")], 1)], 1);
  }), 0)]) : _vm._e()], 1), _c('div', {
    staticClass: "separator"
  }), _c('pui-grid-row', {
    staticClass: "p-4"
  }, [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Linked Budget Request Global IDs ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s((_vm$iybrData7 = _vm.iybrData) === null || _vm$iybrData7 === void 0 ? void 0 : _vm$iybrData7.budgetRequests.map(function (entry) {
    return entry.globalId;
  }).join(', ')) + " ")])], 1), ((_vm$iybrData8 = _vm.iybrData) === null || _vm$iybrData8 === void 0 ? void 0 : _vm$iybrData8.miniFinancialDecisions.length) > 0 ? _c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Linked Change Request Unique IDs ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s((_vm$iybrData9 = _vm.iybrData) === null || _vm$iybrData9 === void 0 ? void 0 : _vm$iybrData9.miniFinancialDecisions.map(function (entry) {
    return entry.uniqueId;
  }).join(', ')) + " ")])], 1) : _vm._e(), (_vm$iybrData10 = _vm.iybrData) !== null && _vm$iybrData10 !== void 0 && _vm$iybrData10.miniFdType ? _c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" MiniFD Type ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.getValueForConstant('miniFdType', (_vm$iybrData11 = _vm.iybrData) === null || _vm$iybrData11 === void 0 ? void 0 : _vm$iybrData11.miniFdType)) + " ")])], 1) : _vm._e()], 1), _c('div', {
    staticClass: "separator"
  }), !_vm.isSdFd ? _c('pui-grid-row', {
    staticClass: "p-4"
  }, [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.FULL_ROW
    }
  }, [_c('div', {
    staticClass: "heading-flex"
  }, [_c('pui-headline', {
    staticClass: "text-bolded",
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" Notifiable Users ")])], 1)]), _vm.nonReviewerStakeholders.length !== 0 ? _c('pui-grid-column', {
    staticClass: "mt-2",
    attrs: {
      "cols": _vm.pageLayout.FULL_ROW
    }
  }, [_c('div', {
    staticClass: "non-reviewers-list"
  }, _vm._l(_vm.nonReviewerStakeholders, function (nonReviewerStakeholder, index) {
    return _c('div', {
      key: index,
      staticClass: "non-reviewer"
    }, [_c('pui-headline', {
      attrs: {
        "type": "h6"
      }
    }, [_vm._v(" " + _vm._s(nonReviewerStakeholder.name) + " ")])], 1);
  }), 0)]) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "separator"
  }), _c('pui-grid-row', {
    staticClass: "p-4"
  }, [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Country ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s((_vm$iybrData12 = _vm.iybrData) === null || _vm$iybrData12 === void 0 ? void 0 : _vm$iybrData12.budgetRequests[0].countryName) + " ")])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Technology ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s((_vm$iybrData13 = _vm.iybrData) === null || _vm$iybrData13 === void 0 ? void 0 : _vm$iybrData13.budgetRequests[0].technologyName) + " ")])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Plant / River Group ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s((_vm$plantData = _vm.plantData) === null || _vm$plantData === void 0 ? void 0 : _vm$plantData.plantGroupName) + " ")])], 1)], 1), _c('pui-grid-row', {
    staticClass: "p-4"
  }, [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Plant ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s((_vm$iybrData14 = _vm.iybrData) === null || _vm$iybrData14 === void 0 ? void 0 : _vm$iybrData14.budgetRequests[0].plantName) + " ")])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Unit ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s((_vm$iybrData15 = _vm.iybrData) === null || _vm$iybrData15 === void 0 ? void 0 : _vm$iybrData15.budgetRequests[0].unitName) + " ")])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Risk / Maintenance ID ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s((_vm$iybrData16 = _vm.iybrData) === null || _vm$iybrData16 === void 0 ? void 0 : (_vm$iybrData16$extern = _vm$iybrData16.externalIds) === null || _vm$iybrData16$extern === void 0 ? void 0 : _vm$iybrData16$extern.join(', ')) + " ")])], 1)], 1), _c('pui-grid-row', {
    staticClass: "p-4"
  }, [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Plant Sid ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s((_vm$iybrData17 = _vm.iybrData) === null || _vm$iybrData17 === void 0 ? void 0 : _vm$iybrData17.budgetRequests[0].plantSid) + " ")])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Unit Sid ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s((_vm$iybrData18 = _vm.iybrData) === null || _vm$iybrData18 === void 0 ? void 0 : _vm$iybrData18.budgetRequests[0].unitSid) + " ")])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Technology Sid ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s((_vm$iybrData19 = _vm.iybrData) === null || _vm$iybrData19 === void 0 ? void 0 : _vm$iybrData19.budgetRequests[0].technologySid) + " ")])], 1)], 1), _c('div', {
    staticClass: "separator"
  }), _c('pui-grid-row', {
    staticClass: "p-4"
  }, [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Name (English) ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s((_vm$iybrData20 = _vm.iybrData) === null || _vm$iybrData20 === void 0 ? void 0 : _vm$iybrData20.projectName) + " ")])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Name (Local Language) ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s((_vm$iybrData21 = _vm.iybrData) === null || _vm$iybrData21 === void 0 ? void 0 : _vm$iybrData21.budgetRequests[0].localLanguageName) + " ")])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Request Type ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.getValueForConstant('requestTypes', (_vm$iybrData22 = _vm.iybrData) === null || _vm$iybrData22 === void 0 ? void 0 : _vm$iybrData22.requestType)) + " ")])], 1)], 1), _c('pui-grid-row', {
    staticClass: "p-4"
  }, [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Start Date ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDate((_vm$iybrData23 = _vm.iybrData) === null || _vm$iybrData23 === void 0 ? void 0 : _vm$iybrData23.startDate)) + " ")])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" End Date ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDate((_vm$iybrData24 = _vm.iybrData) === null || _vm$iybrData24 === void 0 ? void 0 : _vm$iybrData24.endDate)) + " ")])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Latest Date for Decision ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDate((_vm$iybrData25 = _vm.iybrData) === null || _vm$iybrData25 === void 0 ? void 0 : _vm$iybrData25.latestDateForDecision)) + " ")])], 1)], 1), _c('div', {
    staticClass: "separator"
  }), _c('pui-grid-row', {
    staticClass: "p-4"
  }, [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" AERO Classification ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.getValueForConstant('aeroClassification', (_vm$iybrData26 = _vm.iybrData) === null || _vm$iybrData26 === void 0 ? void 0 : _vm$iybrData26.category)) + " ")])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Maintenance Type ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.getValueForConstant('maintenanceType', (_vm$iybrData27 = _vm.iybrData) === null || _vm$iybrData27 === void 0 ? void 0 : _vm$iybrData27.maintenanceType)) + " ")])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Outage Type ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.getValueForConstant('outageType', (_vm$iybrData28 = _vm.iybrData) === null || _vm$iybrData28 === void 0 ? void 0 : _vm$iybrData28.outageType)) + " ")])], 1)], 1), _c('pui-grid-row', {
    staticClass: "p-4"
  }, [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Outage ID(s) ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatTextForDisplay((_vm$iybrData29 = _vm.iybrData) === null || _vm$iybrData29 === void 0 ? void 0 : (_vm$iybrData29$outage = _vm$iybrData29.outageIds) === null || _vm$iybrData29$outage === void 0 ? void 0 : _vm$iybrData29$outage.join(', '))) + " ")])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Procurement Category ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.getValueForConstant('procurementCategory', (_vm$iybrData30 = _vm.iybrData) === null || _vm$iybrData30 === void 0 ? void 0 : _vm$iybrData30.procurementCategory)) + " ")])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Project Type ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.getValueForConstant('projectTypes', (_vm$iybrData31 = _vm.iybrData) === null || _vm$iybrData31 === void 0 ? void 0 : _vm$iybrData31.projectType)) + " ")])], 1)], 1), _c('pui-grid-row', {
    staticClass: "p-4"
  }, [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" WBS (PSP) Element ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s((_vm$iybrData32 = _vm.iybrData) === null || _vm$iybrData32 === void 0 ? void 0 : _vm$iybrData32.projectSapId) + " ")])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Budget Request Summary ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s((_vm$iybrData33 = _vm.iybrData) === null || _vm$iybrData33 === void 0 ? void 0 : _vm$iybrData33.miniFdSummary) + " ")])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Primary Department ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.getValueForConstant('primaryDepartment', (_vm$iybrData34 = _vm.iybrData) === null || _vm$iybrData34 === void 0 ? void 0 : _vm$iybrData34.primaryDepartment)) + " ")])], 1)], 1), _vm.isFlaggedProject ? _c('pui-grid-row', {
    staticClass: "p-4"
  }, [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Main Flag ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.getValueForConstant('flags', (_vm$iybrData35 = _vm.iybrData) === null || _vm$iybrData35 === void 0 ? void 0 : _vm$iybrData35.budgetRequests[0].planning.mainFlag)) + " ")])], 1)], 1) : _vm._e()], 1), ((_vm$iybrData36 = _vm.iybrData) === null || _vm$iybrData36 === void 0 ? void 0 : _vm$iybrData36.budgetRequestsMiniFinancialDecisions.length) > 1 ? _c('view-global-ids-collapsible-component', {
    attrs: {
      "currency-name": (_this$plantData = this.plantData) === null || _this$plantData === void 0 ? void 0 : _this$plantData.country.currency.code,
      "linked-budget-requests": (_vm$iybrData37 = _vm.iybrData) === null || _vm$iybrData37 === void 0 ? void 0 : _vm$iybrData37.budgetRequestsMiniFinancialDecisions
    }
  }) : _vm._e(), _c('files-change-request', {
    ref: _vm.FILES_COMPONENT_REF,
    attrs: {
      "is-view-page": true,
      "mini-fd-id": (_vm$iybrData38 = _vm.iybrData) === null || _vm$iybrData38 === void 0 ? void 0 : _vm$iybrData38.id,
      "year": (_vm$iybrData39 = _vm.iybrData) === null || _vm$iybrData39 === void 0 ? void 0 : _vm$iybrData39.year
    }
  }), _c('pui-tabs', {
    staticClass: "pt-3 pl-2 pb-3",
    on: {
      "changed": _vm.handleTabChange
    }
  }, [_c('pui-tab', {
    attrs: {
      "title": "Budget Request"
    }
  }), _c('pui-tab', {
    attrs: {
      "title": "Additional information"
    }
  })], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedTab === 0,
      expression: "selectedTab === 0"
    }]
  }, [_c('pui-collapsible', {
    attrs: {
      "headline": "FINANCE & PLANNING"
    }
  }, [_c('pui-grid-row', {
    staticClass: "p-4"
  }, [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Budget Compensation ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.getValueForConstant('localBudgetCompensationsTypes', (_vm$iybrData40 = _vm.iybrData) === null || _vm$iybrData40 === void 0 ? void 0 : _vm$iybrData40.budgetCompensationType)) + " ")])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Uniper Share of Budget ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s((((_vm$iybrData41 = _vm.iybrData) === null || _vm$iybrData41 === void 0 ? void 0 : _vm$iybrData41.uniperShareOfBudget) + "%")) + " ")])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Planning & Controlling Classification ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.getValueForConstant('allPlanningAndControllingClassification', (_vm$iybrData42 = _vm.iybrData) === null || _vm$iybrData42 === void 0 ? void 0 : _vm$iybrData42.planningAndControllingClassification)) + " ")])], 1)], 1), _c('view-cost-table', {
    attrs: {
      "iybr-data": _vm.iybrData,
      "plant-data": _vm.plantData
    }
  })], 1), ((_vm$iybrData43 = _vm.iybrData) === null || _vm$iybrData43 === void 0 ? void 0 : (_vm$iybrData43$budget = _vm$iybrData43.budgetCompensations) === null || _vm$iybrData43$budget === void 0 ? void 0 : _vm$iybrData43$budget.length) !== 0 ? _c('pui-collapsible', {
    attrs: {
      "headline": "BUDGET COMPENSATION"
    }
  }, [_c('view-compensation-table', {
    attrs: {
      "iybr-data": _vm.iybrData
    }
  })], 1) : _vm._e()], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedTab === 1,
      expression: "selectedTab === 1"
    }]
  }, [_c('pui-collapsible', {
    attrs: {
      "headline": "ADDITIONAL INFORMATION"
    }
  }, [_c('pui-grid-row', {
    staticClass: "p-4"
  }, [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.FULL_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Project Setup ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatTextForDisplay((_vm$iybrData44 = _vm.iybrData) === null || _vm$iybrData44 === void 0 ? void 0 : _vm$iybrData44.projectSetup)) + " ")])], 1)], 1), _c('pui-grid-row', {
    staticClass: "p-4"
  }, [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.FULL_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Project Anchoring ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatTextForDisplay((_vm$iybrData45 = _vm.iybrData) === null || _vm$iybrData45 === void 0 ? void 0 : _vm$iybrData45.projectAnchoring)) + " ")])], 1)], 1), _c('pui-grid-row', {
    staticClass: "p-4"
  }, [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.FULL_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Further Financial Parameters ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatTextForDisplay((_vm$iybrData46 = _vm.iybrData) === null || _vm$iybrData46 === void 0 ? void 0 : _vm$iybrData46.furtherFinancialParameters)) + " ")])], 1)], 1), _c('pui-grid-row', {
    staticClass: "p-4"
  }, [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.FULL_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Procurement Involvement ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatTextForDisplay((_vm$iybrData47 = _vm.iybrData) === null || _vm$iybrData47 === void 0 ? void 0 : _vm$iybrData47.procurementInvolvment)) + " ")])], 1)], 1), _c('pui-grid-row', {
    staticClass: "p-4"
  }, [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.FULL_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "text-muted",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Risk and Opportunities ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatTextForDisplay((_vm$iybrData48 = _vm.iybrData) === null || _vm$iybrData48 === void 0 ? void 0 : _vm$iybrData48.riskAndOpportunities)) + " ")])], 1)], 1)], 1)], 1)], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.THIRD_ROW
    }
  }, [_vm.showEstimatedPrioritization ? [_c('pui-collapsible', {
    attrs: {
      "headline": "ESTIMATED PRIORITIZATION"
    }
  }, [!_vm.loading && _vm.iybrData ? [_c('pui-grid-row', {
    staticClass: "p-4"
  }, [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.FULL_ROW
    }
  }, [_c('estimation', {
    attrs: {
      "unique-id": (_vm$iybrData49 = _vm.iybrData) === null || _vm$iybrData49 === void 0 ? void 0 : _vm$iybrData49.uniqueId
    }
  })], 1)], 1)] : _vm._e()], 2)] : _vm._e(), _c('pui-collapsible', {
    attrs: {
      "headline": "FOCUS TOPICS"
    }
  }, [!_vm.loading && _vm.iybrData ? [_c('pui-grid-row', {
    staticClass: "p-4"
  }, [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.FULL_ROW
    }
  }, [_c('tags', {
    attrs: {
      "view-only": _vm.isTagsViewOnly,
      "current-mode": _vm.currentTagsMode,
      "request-id": _vm.miniFdId,
      "initial-tags": (_vm$iybrData$tags = _vm.iybrData.tags) !== null && _vm$iybrData$tags !== void 0 ? _vm$iybrData$tags : []
    }
  })], 1)], 1)] : _vm._e()], 2), _vm.hasToReview ? _c('approval-collapsible', {
    key: (_vm$miniFdId = _vm.miniFdId) === null || _vm$miniFdId === void 0 ? void 0 : _vm$miniFdId.id,
    attrs: {
      "view-iybr-id": _vm.miniFdId,
      "workflow-instance-id": _vm.workflowId
    },
    on: {
      "has-reviewed-mini-fd": _vm.hasReviewedMiniFd
    }
  }) : _vm._e(), _c('comments-collapsible', {
    key: _vm.miniFdId,
    attrs: {
      "comments-data": _vm.miniFdComments,
      "view-iybr-id": _vm.miniFdId
    }
  }), _c('linked-external-ids-collapsible', {
    staticClass: "mt-5",
    attrs: {
      "external-ids": _vm.externalIds
    }
  })], 2)], 1) : _c('pui-grid-row', [_c('pui-grid-column', [_c('view-history', {
    key: _vm.miniFdId,
    attrs: {
      "change-request-id": _vm.miniFdId
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }