import { LivePmlFilterEntry, MiniFdYearFilterEntry, MtpFilterEntry } from '~/utils/interfaces';
import {
    CurrencyRate
} from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/iybr-new-project-collapsible/create-iybr-form-utils';
import { DepartmentGroups } from '~/models/services/minifd';

export type MtpStatusEntry = {
    name: string,
    color: string,
    description: string
};

export enum BudgetCompensationType {
    LocalCompensation = 1,
    FleetCompensation = 2,
    AssetOperationsCompensation = 3,
    NotApplicable = 4
}

export type ConstantsList = {
    externalApplicationTypes: Record<number, string>,
    activityTrackingDateFilterTypes: Record<number, string>,
    activityTypes: Record<number, string>,
    mtpFilters: MtpFilterEntry[],
    mtpReportingFilters: MtpFilterEntry[],
    miniFdYearFilters: MiniFdYearFilterEntry[],
    projectTypes: Record<number, string>,
    aeroClassification: Record<number, string>,
    primaryDepartment: Record<number, string>,
    fundingStatus: Record<number, string>,
    maintenanceType: Record<number, string>,
    miniFdType: Record<number, string>,
    outageType: Record<number, string>,
    procurementCategory: Record<number, string>,
    focusTopics: Record<string, string>,
    livePmlFilters: LivePmlFilterEntry[];
    planningAndControllingClassification: Record<number, string>,
    newPlanningAndControllingClassification: Record<number, string>,
    allPlanningAndControllingClassification: Record<number, string>,
    prioritizationCategory: Record<number, string>,
    mtpStatus: Record<number, MtpStatusEntry>,
    flags: Record<number, string>,
    filesPerPage: number,
    departments: Record<number, DepartmentGroups>,
    requestTypes: Record<number, string>,
    latestStatusTypes: Record<number, string>,
    miniFdStatusesTypes: Record<number, string>,
    localBudgetCompensationsTypes: Record<BudgetCompensationType, string>,
    latestDateCategories: Record<number, string>,
    justificationTypes: Record<number, string>,
    currencyRates: CurrencyRate[],
    newProjectMtpYears: number[],
    miniFdStatusColors: Record<number, {
        color: string,
    }>,
    projectStatusType: Record<number, string>,
};
