var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('pui-comment-list', {
    attrs: {
      "total-items-available": _vm.commentsList.length,
      "title": "COMMENTS"
    }
  }, _vm._l(_vm.commentsList, function (comment, index) {
    return _c('pui-comment', {
      key: 'comment' + index,
      attrs: {
        "author-username": comment.name,
        "comment": comment.text,
        "creation-date": comment.createdAt
      }
    });
  }), 1), _c('pui-comment-form', {
    ref: "commentText",
    attrs: {
      "is-expanded-on-mount": true,
      "is-valid-comment": _vm.isCommentValid
    },
    on: {
      "submit": function ($event) {
        return _vm.handleCommentSubmit();
      }
    },
    scopedSlots: _vm._u([{
      key: "comment-error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.invalidCommentMessage) + " ")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "comment-helper-text"
  }, [_vm._v(" In case there is any remark from the functional approvers, please use this comment section. ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }