import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ProjectPortfolioService } from '~/services/project-portfolio';
import { Data } from '~/utils';
import { addYears, format } from 'date-fns';
import DeleteConfirmation from '~/components/project-portfolio/linked-budget-request-view/linked-budget-request-view-content/delete-confirmation/delete-confirmation.vue';
import { MtpPeriod } from '~/utils/interfaces';
import { MtpService } from '~/services/mtp-service';

const ROWS = {
    FULL_ROW: { s: 12, l: 12 },
    TWO_THIRDS_ROW: { s: 12, l: 8 },
    HALF_ROW: { s: 12, l: 6 },
    ONE_THIRD_ROW: { s: 12, l: 4 },
    QUARTER_ROW: { s: 6, l: 3 },
    THREE_QUARTER_ROW: { s: 12, l: 9 },
    ONE_SIXTH_ROW: { s: 4, l: 2 },
} as const;


@Component({
    components: {
        DeleteConfirmation,
    }
})
export default class LinkedBudgetRequestViewContentComponent extends Vue {
    private projectPortfolioService: ProjectPortfolioService = new ProjectPortfolioService();
    private MtpService: MtpService = new MtpService();

    private data: Data = Data.Instance;
    private ROWS = ROWS;

    private combinedBudget: Record<string, any> = {};

    private loading = false;
    private initialError = {
        show: false,
        title: '',
    }

    private isDeleteLightboxOpen = false;
    private isDeleteInProgress = false;

    private currentYear = new Date(Date.now()).getFullYear();
    private mtpPeriod: MtpPeriod = <MtpPeriod>{
        year: 0,
        mtpStartDate: null,
        mtpEndDate: null
    };

    @Prop()
    private id: string;

    private created(): void {
        this.loadCombinedBudget();
    }

    private mounted(): void {
        this.checkMtpPeriod();
    }

    private get year(): number {
        return this.combinedBudget.year;
    }

    private onActiveRouteChanged(value): void {
        if (value.href === '') {
            return;
        }
        
        this.$router.push({ path: value.href });
    }

    private get breadcrumbLinks(): { label: string, href: string }[] {
        return [
            {
                label: 'Project Portfolio',
                href: '/project-portfolio'
            },
            {
                label: this.combinedBudget.combinedId ?? 'Combined Budget Request',
                href: ''
            }
        ]
    }    
    
    private async loadCombinedBudget(): Promise<void> {
        this.loading = true;
        try {
            const result = await this.projectPortfolioService.getCombinedBudget(this.id);
            if (result) {
                this.combinedBudget = result;
            } else {
                this.initialError = {
                    show: true,
                    title: 'Combined Budget not found!',
                }
            }
        } catch (error) {
            this.initialError = {
                show: true,
                title: 'Combined Budget not found!',
            }
        } finally {
            this.loading = false;
        }
    }

    private formatDate(date: string): string {
        return format(new Date(date), 'yyyy-MM-dd');
    }

    private sendToEdit(): void {
        const path = `/project-portfolio/${this.id}/edit`;
        this.$router.push({ path });
    }

    private handleOpenDeleteLightbox(): void {
        this.isDeleteLightboxOpen = true;
    }

    private handleCloseDeleteLightbox(): void {
        this.isDeleteLightboxOpen = false;
    }

    private async handleDelete(): Promise<void> {
        this.isDeleteInProgress = true;
        try {
            await this.projectPortfolioService.deleteCombinedBudget(this.id);
            this.$pui.toast({
                type: 'success',
                title: 'Success',
                copy: 'Combined Budget deleted.'
            });
            this.$router.push({ path: '/project-portfolio' });
        } catch (e) {
            this.$pui.toast({
                type: 'error',
                title: 'Delete Failed',
                copy: 'An error occurred when attempting to delete Combined Budget.'
            });
        } finally {
            this.isDeleteInProgress = false;
        }
    }

    private get isButtonDisabled(): boolean {
        const currentDate = new Date(Date.now());
        const startDate = new Date(this.mtpPeriod.mtpStartDate);
        const endDate = addYears(new Date(this.year, 11, 31), 1);

        if (this.year === this.currentYear) {
            return !(startDate <= currentDate && currentDate <= endDate);
        }

        return !(currentDate <= endDate);
    }

    private async checkMtpPeriod(): Promise<void> {
        if (localStorage.mtpPeriod) {
            this.mtpPeriod = JSON.parse(localStorage.mtpPeriod);
        } else {
            try {
                this.mtpPeriod = await this.MtpService.GetMtpPeriod(this.currentYear);
                localStorage.setItem('mtpPeriod', JSON.stringify(this.mtpPeriod));
            } catch (error) {
                if (error.response !== undefined && error.response.status === 400) {
                    localStorage.removeItem('mtpPeriod');
                }
            }
        }
    }
}
