import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import 'vue-awesome/icons/plug';
import { Helper } from '~/utils';
import moment from 'moment-timezone';
import { ActivateFocusTopicRequest, DeleteFocusTopicRequest, FocusTopic } from '~/models/services/focus-topics';
import { FocusTopicsService } from '~/services/focus-topics-service';

@Component({})

export default class ViewFocusTopicsComponent extends Vue {
    public FocusTopicService: FocusTopicsService = new FocusTopicsService();
    private DATE_FORMAT: string = 'DD/MM/YYYY';
    private isRequestPending = false;

    @Prop()
    private hasPermissionToEdit: boolean;

    @Prop()
    private loading: boolean;

    @Prop()
    private focusTopics: FocusTopic[];

    @Watch('focusTopics')
    async onFocusTopicsChanged() {
        if (this.focusTopics !== null) {
            this.formatDates();
        }
    }

    private formatDates() {
        this.focusTopics.forEach((focusTopic) => {
            focusTopic.createdAt = moment(Helper.UtcToLocalTime(focusTopic.createdAt)).format(this.DATE_FORMAT);
            if (focusTopic.updatedAt) {
                focusTopic.updatedAt = moment(Helper.UtcToLocalTime(focusTopic.updatedAt)).format(this.DATE_FORMAT);
            }
        });
    }

    private async editFocusTopic(clickedFocusTopic: FocusTopic) {
        if (!clickedFocusTopic.canBeDeleted) {
            return;
        }

        this.$emit('editFTChanged', clickedFocusTopic);
    }

    private async deleteFocusTopic(clickedFocusTopic: FocusTopic) {
        if (!clickedFocusTopic.canBeDeleted) {
            return;
        }

        this.isRequestPending = true;
        
        const deleteFocusTopic: DeleteFocusTopicRequest = {
            focusTopicIds: [clickedFocusTopic.id],
        };

        try {
            await this.FocusTopicService.deleteFocusTopic(deleteFocusTopic);
            this.$emit('reloadData');
            this.$bvToast.show('toast-focus-topic-success');
        } catch (error) {
            if (error?.response?.data?.errors.length > 0) {
                this.$emit('errorChanged', error.response.data.errors[0]);
            } else {
                this.$emit('errorChanged', 'Focus Topic could not be saved.');
            }
        } finally {
            this.isRequestPending = false;
        }
    }

    private async activateFocusTopic(clickedFocusTopic: FocusTopic) {
        this.isRequestPending = true;
        const activateFocusTopic: ActivateFocusTopicRequest = {
            focusTopics: [{
                id: clickedFocusTopic.id,
                isActive: !clickedFocusTopic.isActive,
            }],
        };

        try {
            await this.FocusTopicService.activateFocusTopic(activateFocusTopic);
            this.$emit('reloadData');
            this.$bvToast.show('toast-focus-topic-success');
        } catch (error) {
            if (error?.response?.data?.errors.length > 0) {
                this.$emit('errorChanged', error.response.data.errors[0]);
            } else {
                this.$emit('errorChanged', 'Focus Topic could not be saved.');
            }
        } finally {
            this.isRequestPending = false;
        }
    }
}
