import { AdminService } from '~/services/admin-service';
import { AppService } from '~/services/app-service';
import { ApproveService } from '~/services/approve-service';
import { AssetGroupsService } from '~/services/asset-groups-service';
import { BudgetRequestsService } from '~/services/budget-request-service';
import { ChartService } from '~/services/chart-service';
import { ConstantsListService } from '~/services/constants-list-service';
import { CrossAppLinkingService } from '~/services/cross-app-linking-service';
import { FileService } from '~/services/file-service';
import { FleetService } from '~/services/fleet-service';
import { FocusTopicsService } from '~/services/focus-topics-service';
import { GlobalDocumentService } from '~/services/global-document-service';
import { MtpService } from '~/services/mtp-service';
import { PlantsService } from '~/services/plants-service';
import { ReportingService } from '~/services/reporting-service';
import { MiniFdService } from '~/services/minifd-service';
import { NotificationService } from '~/services/notification-service';
import { UAMService } from './uam-service';

export class MasterService {
    private static singletonInstance: MasterService;

    public readonly adminService = new AdminService();
    public readonly appService = new AppService();
    public readonly approveService = new ApproveService();
    public readonly assetGroupsService = new AssetGroupsService();
    public readonly budgetRequestsService = new BudgetRequestsService();
    public readonly chartService = new ChartService();
    public readonly constantsListService = new ConstantsListService();
    public readonly crossAppLinkingService = new CrossAppLinkingService();
    public readonly fileService = new FileService();
    public readonly fleetService = new FleetService();
    public readonly focusTopicsService = new FocusTopicsService();
    public readonly globalDocumentService = new GlobalDocumentService();
    public readonly mtpService = new MtpService();
    public readonly plantsService = new PlantsService();
    public readonly reportingService = new ReportingService();
    public readonly miniFdService = new MiniFdService();
    public readonly notificationService = new NotificationService();
    public readonly uamService = new UAMService();

    private constructor() {
    }

    public static get instance(): MasterService {
        return this.singletonInstance || (this.singletonInstance = new this());
    }
}
