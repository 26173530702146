var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('toast-error-message'), _c('b-toast', {
    attrs: {
      "id": "toast-data-error",
      "auto-hide-delay": _vm.autoHideDelayValue,
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center toast-error-import"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [[_c('div', {
    staticClass: "toaster-icon-c-pr"
  }, [_c('icon', {
    staticClass: "icon-toast text-center pml-red",
    attrs: {
      "name": "times"
    }
  })], 1)], _c('div', [_c('span', [_vm._v("An error has occurred while loading data, please try again!")])]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideAllToasts();
      }
    }
  }, [_vm._v(" Dismiss ")])], 2)]), _c('b-toast', {
    attrs: {
      "id": "toast-validation-error",
      "auto-hide-delay": _vm.autoHideDelayValue,
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center toast-error-import"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [[_c('div', {
    staticClass: "toaster-icon-c-pr"
  }, [_c('icon', {
    staticClass: "icon-toast text-center pml-red",
    attrs: {
      "name": "times"
    }
  })], 1)], _c('div', [_c('span', [_vm._v("An error has occurred while validating this form, please try again!")])]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideAllToasts();
      }
    }
  }, [_vm._v(" Dismiss ")])], 2)]), [_c('b-toast', {
    attrs: {
      "id": "example-toast",
      "auto-hide-delay": _vm.autoHideDelayValue,
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [_c('div', [_c('icon', {
    staticClass: "icon-toast",
    attrs: {
      "name": "trash-alt"
    }
  })], 1), _c('div', [_c('span', [_vm._v("1 budget request was successfully deleted")])]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideAllToasts();
      }
    }
  }, [_vm._v(" Dismiss ")])])])], [_c('b-toast', {
    attrs: {
      "id": "inactive",
      "auto-hide-delay": _vm.autoHideDelayValue,
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [_c('div', [_c('icon', {
    staticClass: "icon-toast",
    attrs: {
      "name": "trash-alt"
    }
  })], 1), _c('span', [_vm._v("1 budget request was set to inactive")]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideAllToasts();
      }
    }
  }, [_vm._v(" Dismiss ")])])])], [_c('b-toast', {
    attrs: {
      "id": "add-to-current-mtp",
      "auto-hide-delay": _vm.autoHideDelayValue,
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [_c('div', [_c('icon', {
    staticClass: "icon-toast",
    attrs: {
      "name": "trash-alt"
    }
  })], 1), _c('div', [_c('span', [_vm._v("1 budget request was added to the current Mtp period")]), _c('h6', {
    staticClass: "pointerLink",
    staticStyle: {
      "color": "white"
    },
    on: {
      "click": function ($event) {
        return _vm.goToDetailsPage();
      }
    }
  }, [_vm._v(" View budget request ")])]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideAllToasts();
      }
    }
  }, [_vm._v(" Dismiss ")])])])], [_c('b-toast', {
    attrs: {
      "id": "toast-duplicate",
      "auto-hide-delay": _vm.autoHideDelayValue,
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center duplicate-toast"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [_c('div', [_c('icon', {
    staticClass: "icon-toast toaster-icon-w text-center",
    attrs: {
      "name": "check"
    }
  })], 1), _c('div', [_c('span', [_vm._v("Data successfully copied from Budget Request with Global ID '" + _vm._s(_vm.$route.query.globalId) + "'. ")]), _c('span', [_vm._v("You can now Edit, Save or Cancel this Budget Request.")])]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideAllToasts();
      }
    }
  }, [_vm._v(" Dismiss ")])])])], [_c('b-toast', {
    attrs: {
      "id": "toast-approved-minifd",
      "auto-hide-delay": _vm.autoHideDelayValue,
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [[_c('div', {
    staticClass: "toaster-icon-c-pr"
  }, [_c('icon', {
    staticClass: "icon-toast text-center",
    attrs: {
      "name": "check"
    }
  })], 1)], _c('div', [_c('span', [_vm._v("You have approved this Change request.")])]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideAllToasts();
      }
    }
  }, [_vm._v(" Dismiss ")])], 2)])], [_c('b-toast', {
    attrs: {
      "id": "toast-rejected-minifd",
      "auto-hide-delay": _vm.autoHideDelayValue,
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [[_c('div', {
    staticClass: "toaster-icon-c-pr"
  }, [_c('icon', {
    staticClass: "icon-toast text-center",
    attrs: {
      "name": "check"
    }
  })], 1)], _c('div', [_c('span', [_vm._v("You have rejected this Change Request.")])]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideAllToasts();
      }
    }
  }, [_vm._v(" Dismiss ")])], 2)])], [_c('b-toast', {
    attrs: {
      "id": "toast-error-submit-approval-workflow-minifd",
      "auto-hide-delay": _vm.autoHideDelayValue,
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [[_c('div', {
    staticClass: "toaster-icon-c-pr"
  }, [_c('icon', {
    staticClass: "icon-toast text-center",
    attrs: {
      "name": "times"
    }
  })], 1)], _c('div', [_c('span', [_vm._v("An error has occurred while submitting this review, please try again!")])]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideAllToasts();
      }
    }
  }, [_vm._v(" Dismiss ")])], 2)])], [_c('b-toast', {
    attrs: {
      "id": "toast-successful-submit-draft-change-request",
      "auto-hide-delay": _vm.autoHideDelayValue,
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [[_c('div', {
    staticClass: "toaster-icon-c-pr"
  }, [_c('icon', {
    staticClass: "icon-toast text-center",
    attrs: {
      "name": "check"
    }
  })], 1)], _c('div', [_c('span', [_vm._v("You have successfully submitted this change request.")])]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideAllToasts();
      }
    }
  }, [_vm._v(" Dismiss ")])], 2)])], [_c('b-toast', {
    attrs: {
      "id": "toast-error-submit-draft-change-request",
      "auto-hide-delay": _vm.autoHideDelayValue,
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [[_c('div', {
    staticClass: "toaster-icon-c-pr"
  }, [_c('icon', {
    staticClass: "icon-toast text-center",
    attrs: {
      "name": "times"
    }
  })], 1)], _c('div', [_c('span', [_vm._v("An error has occurred while submitting change request, please try again!")])]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideAllToasts();
      }
    }
  }, [_vm._v(" Dismiss ")])], 2)])], [_c('b-toast', {
    attrs: {
      "id": "toast-error-file-upload",
      "auto-hide-delay": _vm.autoHideDelayValue,
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [[_c('div', {
    staticClass: "toaster-icon-c-pr"
  }, [_c('icon', {
    staticClass: "icon-toast text-center",
    attrs: {
      "name": "times"
    }
  })], 1)], _c('div', [_c('span', [_vm._v("There was en error uploading this file. Please try again.")])]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideAllToasts();
      }
    }
  }, [_vm._v(" Dismiss ")])], 2)])], [_c('b-toast', {
    attrs: {
      "id": "toast-successful-delete-change-request-file",
      "auto-hide-delay": _vm.autoHideDelayValue,
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [[_c('div', {
    staticClass: "toaster-icon-c-pr"
  }, [_c('icon', {
    staticClass: "icon-toast text-center",
    attrs: {
      "name": "check"
    }
  })], 1)], _c('div', [_c('span', [_vm._v("File was successfully deleted!")])]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideAllToasts();
      }
    }
  }, [_vm._v(" Dismiss ")])], 2)])], [_c('b-toast', {
    attrs: {
      "id": "toast-error-delete-change-request-file",
      "auto-hide-delay": _vm.autoHideDelayValue,
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [[_c('div', {
    staticClass: "toaster-icon-c-pr"
  }, [_c('icon', {
    staticClass: "icon-toast text-center",
    attrs: {
      "name": "times"
    }
  })], 1)], _c('div', [_c('span', [_vm._v("There was an error while deleting this file, please try again.")])]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideAllToasts();
      }
    }
  }, [_vm._v(" Dismiss ")])], 2)])], [_c('b-toast', {
    attrs: {
      "id": "toast-error-download-change-request-file",
      "auto-hide-delay": _vm.autoHideDelayValue,
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [[_c('div', {
    staticClass: "toaster-icon-c-pr"
  }, [_c('icon', {
    staticClass: "icon-toast text-center",
    attrs: {
      "name": "times"
    }
  })], 1)], _c('div', [_c('span', [_vm._v("There was an error while downloading this file, please try again.")])]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideAllToasts();
      }
    }
  }, [_vm._v(" Dismiss ")])], 2)])], [_c('b-toast', {
    attrs: {
      "id": "toast-error-change-request-file-name-already-exists",
      "auto-hide-delay": _vm.autoHideDelayValue,
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [[_c('div', {
    staticClass: "toaster-icon-c-pr"
  }, [_c('icon', {
    staticClass: "icon-toast text-center",
    attrs: {
      "name": "times"
    }
  })], 1)], _c('div', [_c('span', [_vm._v("There is already an existing file with this name. Change the name and try again.")])]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideAllToasts();
      }
    }
  }, [_vm._v(" Dismiss ")])], 2)])], [_c('b-toast', {
    attrs: {
      "id": "toast-invalid-file-extension",
      "auto-hide-delay": _vm.autoHideDelayValue,
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [[_c('div', {
    staticClass: "toaster-icon-c-pr"
  }, [_c('icon', {
    staticClass: "icon-toast text-center",
    attrs: {
      "name": "times"
    }
  })], 1)], _c('div', [_c('span', [_vm._v("File type is not valid. Please chose another file.")])]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideAllToasts();
      }
    }
  }, [_vm._v(" Dismiss ")])], 2)])], [_c('b-toast', {
    attrs: {
      "id": "toast-error-project-prioritization-no-data",
      "auto-hide-delay": _vm.autoHideDelayValue,
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [[_c('div', {
    staticClass: "toaster-icon-c-pr"
  }, [_c('icon', {
    staticClass: "icon-toast text-center",
    attrs: {
      "name": "times"
    }
  })], 1)], _c('div', [_c('span', [_vm._v("There is no data to be exported from this Project Prioritization request.")])]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideAllToasts();
      }
    }
  }, [_vm._v(" Dismiss ")])], 2)])], [_c('b-toast', {
    attrs: {
      "id": "toast-error-submit-change-request",
      "auto-hide-delay": _vm.autoHideDelayValue,
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [[_c('div', {
    staticClass: "toaster-icon-c-pr"
  }, [_c('icon', {
    staticClass: "icon-toast text-center",
    attrs: {
      "name": "times"
    }
  })], 1)], _c('div', [_c('span', [_vm._v("There was an error submitting this change request. Please try again.")])]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideAllToasts();
      }
    }
  }, [_vm._v(" Dismiss ")])], 2)])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }