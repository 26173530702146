import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { MasterService } from '~/services/master-service';
import { AddMiniFdComment, MiniFdComment } from '~/models/services/minifd';
import { PuiCommentForm } from '~/models/libraries/pebble-ui';

@Component({})
export default class CommentsCollapsibleComponent extends Vue {
    @Prop()
    private viewIybrId: string;
    @Prop()
    private commentsData: MiniFdComment[];

    private isCommentValid = true;
    private invalidCommentMessage = '';

    private readonly miniFdService = MasterService.instance.miniFdService;
    private readonly EMPTY_COMMENT_ERROR = 'Comment text cannot be empty.';

    private get miniFdID(): string {
        return this.viewIybrId;
    }

    private get commentsList(): MiniFdComment[] {
        this.commentsData.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
        return this.commentsData;
    }

    private async handleCommentSubmit(): Promise<void> {
        const commentText = (this.$refs.commentText as PuiCommentForm).commentDescriptionText;
        const newCommentData: AddMiniFdComment = {
            miniFdId: this.miniFdID,
            text: commentText,
        };

        if (!this.isEmptyComment(commentText)) {
            await this.addComment(newCommentData)
                .then((miniFdComment) => {
                    this.commentsList.push(miniFdComment);
                    this.isCommentValid = true;
                    (this.$refs.commentText as PuiCommentForm).commentDescriptionText = '';
                })
                .catch((error) => {
                    if (error.response.status === 400) {
                        this.isCommentValid = false;
                    }
                });
        } else {
            this.isCommentValid = false;
            this.invalidCommentMessage = this.EMPTY_COMMENT_ERROR;
        }
    }

    private isEmptyComment(comment: string): boolean {
        if (comment) {
            return false;
        }
        return true;
    }

    private async addComment(addCommentData: AddMiniFdComment): Promise<MiniFdComment> {
        return await this.miniFdService.addMiniFdComment(addCommentData);
    }
}
