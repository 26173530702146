var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return (_vm.userPermissions.includes('BudgetRequestDetailsEdit') && _vm.isMtpPeriod || _vm.userPermissions.includes('BudgetRequestDetailsEditAnytime')) && _vm.existPlantId ? _c('div', {
    staticClass: "budget-request"
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "can-cancel": true
    },
    on: {
      "update:active": function ($event) {
        _vm.loading = $event;
      }
    }
  }), _vm.data.switchingEditMode === true || _vm.data.addToCurrentMtp ? _c('loading-spinner') : _vm._e(), _c('b-toast', {
    attrs: {
      "id": "toast-exception",
      "auto-hide-delay": "20000",
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center max-width-toast-update-mtp"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [_c('div', [_c('icon', {
    staticClass: "icon-toast text-center",
    staticStyle: {
      "color": "#F21100"
    },
    attrs: {
      "name": "times"
    }
  })], 1), _c('div', [_c('span', [_vm._v("Something bad happened.")])]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideToast();
      }
    }
  }, [_vm._v(" Dismiss ")])])]), _c('b-toast', {
    attrs: {
      "id": "external-data-copy-toast",
      "auto-hide-delay": "20000",
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center external-copy-toast"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [_c('div', [_c('icon', {
    staticClass: "icon-toast text-center",
    staticStyle: {
      "min-width": "40px"
    },
    attrs: {
      "name": "check"
    }
  })], 1), _c('div', [_vm.currentExternalApplicationType === 'M' && _vm.hasPlanningItems ? _c('span', [_vm._v("The following data from MSP was successfully copied over to the budget request: Name, Name(local language), Project type, Maintenance type, Aero clasiffication, Justification on classification, Lump sum, Planning and controlling classification, Outage type, Outage Ids, Forecast MTP " + _vm._s(_vm.newBudgetRequest.planning.year) + ", Spent until end of " + _vm._s(_vm.newBudgetRequest.planning.year - 1) + ", New MTP " + _vm._s(_vm.newBudgetRequest.planning.year + 1) + ", New MTP " + _vm._s(_vm.newBudgetRequest.planning.year + 2) + ", New MTP " + _vm._s(_vm.newBudgetRequest.planning.year + 3) + ", After " + _vm._s(_vm.newBudgetRequest.planning.year + 3) + ", Start date, End date. UEL was also copied from MSPT. ")]) : _vm._e(), _vm.currentExternalApplicationType === 'M' && !_vm.hasPlanningItems ? _c('span', [_vm._v("The following data from MSP was successfully copied over to the budget request: Name, Name(local language), Project type, Maintenance type, Aero clasiffication, Justification on classification, Lump sum, Planning and controlling classification, Start date, End date. UEL was also copied from MSPT. ")]) : _vm._e(), _vm.currentExternalApplicationType === 'R' ? _c('span', [_vm._v("The following data from PT Risk was successfully copied over to the budget request: Name, Name (local language), Forecast MTP " + _vm._s(_vm.newBudgetRequest.planning.year) + ", UEL. ")]) : _vm._e(), _vm.currentExternalApplicationType === 'O' ? _c('span', [_vm._v("The following data from PT Risk was successfully copied over to the budget request: Name, Name (local language), Forecast MTP " + _vm._s(_vm.newBudgetRequest.planning.year) + ", UEL. ")]) : _vm._e()]), _c('button', {
    staticClass: "btn btn-outline-primary ml-3",
    on: {
      "click": function ($event) {
        return _vm.hideToast();
      }
    }
  }, [_vm._v(" Dismiss ")])])]), _c('confirmation-modal'), _c('div', {
    staticClass: "container-fluid pt-3"
  }, [_c('div', {
    staticClass: "row justify-content-center mb-sm-4 mb-3 pt-3"
  }, [_c('div', {
    staticClass: "col-xl-5 col-lg-6 mb-sm-0 mb-2"
  }, [_vm.editPage && _vm.selectedMtp === _vm.currentYear && !_vm.data.switchingEditMode ? _c('h1', [_vm._v(" Edit Budget Request ")]) : _vm._e(), !_vm.editPage && _vm.newBudgetRequest.isActive && !_vm.data.switchingEditMode ? _c('h1', [_vm._v(" New Budget Request ")]) : _vm._e(), _vm.selectedMtp !== _vm.currentYear && !_vm.data.switchingEditMode && !_vm.duplicatePage ? _c('h1', [_vm._v(" Add to current MTP ")]) : _vm._e(), _vm.showWarning ? _c('h5', {
    staticClass: "warning-label"
  }, [_vm._v(" Following inputs have different values in MSP or PTRisk: " + _vm._s(_vm.mappingWarning) + ". Please see highlighted inputs below. ")]) : _vm._e(), _vm.noValidationWarning ? _c('h5', {
    staticClass: "warning-label"
  }, [_vm._v(" External data validation is not available when multiple extrenal items are linked to the same budget request! ")]) : _vm._e(), _vm.numberOfError > 0 ? _c('h5', {
    staticClass: "text-danger"
  }, [_vm._v(" " + _vm._s(_vm.numberOfError) + " errors were found in the form. Please update the highlighted inputs below. ")]) : _vm._e()]), _c('div', {
    staticClass: "col-xl-5 col-lg-6 menu-save"
  }, [_c('a', {
    staticClass: "pointerLink ml-0",
    on: {
      "click": function ($event) {
        _vm.preventLeavePage.checkPreventLeavePage === true ? _vm.cancel() : _vm.$router.go(-1);
      }
    }
  }, [_c('icon', {
    staticClass: "icon-cancel",
    attrs: {
      "name": "times"
    }
  }), _vm._v(" Cancel ")], 1), _vm.newBudgetRequest.isActive && (_vm.selectedMtp === _vm.currentYear || _vm.duplicatePage) ? _c('a', {
    staticClass: "pointerLink",
    class: _vm.preventLeavePage.checkPreventLeavePage === true ? '' : 'disabled-link',
    on: {
      "click": function ($event) {
        _vm.preventLeavePage.checkPreventLeavePage === true ? _vm.saveChanges() : '';
      }
    }
  }, [_c('icon', {
    staticClass: "icon-cancel",
    attrs: {
      "name": "save"
    }
  }), _vm._v(" Save changes ")], 1) : _vm._e(), !_vm.editPage && _vm.newBudgetRequest.isActive && (_vm.selectedMtp === _vm.currentYear || _vm.duplicatePage) ? _c('a', {
    staticClass: "pointerLink link-color",
    class: _vm.preventLeavePage.checkPreventLeavePage === true ? '' : 'disabled-link',
    on: {
      "click": function ($event) {
        _vm.preventLeavePage.checkPreventLeavePage === true ? _vm.saveAndSubmit() : '';
      }
    }
  }, [_c('icon', {
    staticClass: "icon-cancel",
    attrs: {
      "name": "check"
    }
  }), _vm._v(" Save and submit ")], 1) : _vm._e(), _vm.editPage && _vm.newBudgetRequest.isActive && _vm.selectedMtp === _vm.currentYear ? _c('a', {
    staticClass: "pointerLink link-color",
    on: {
      "click": function ($event) {
        return _vm.saveAndSubmit();
      }
    }
  }, [_c('icon', {
    staticClass: "icon-cancel",
    attrs: {
      "name": "check"
    }
  }), _vm._v(" Save and submit ")], 1) : _vm._e(), _vm.selectedMtp !== _vm.currentYear && !_vm.duplicatePage ? _c('a', {
    staticClass: "pointerLink link-color",
    on: {
      "click": function ($event) {
        return _vm.addToCurrentMtp();
      }
    }
  }, [_c('icon', {
    staticClass: "icon-cancel",
    attrs: {
      "name": "check"
    }
  }), _vm._v(" Save and add to current MTP ")], 1) : _vm._e(), _vm.data.loadingSaveBudgetRequest === true ? _c('loading-spinner') : _vm._e()], 1)]), _c('div', {
    staticClass: "row justify-content-center mb-3"
  }, [_c('div', {
    staticClass: "col-xl-6 col-lg-7"
  }, [_c('div', {
    staticClass: "accordion-budget-details",
    attrs: {
      "id": "accordion"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "collapse show budget-request-wrapper",
    attrs: {
      "id": "collapseOne",
      "aria-labelledby": "heading_accordion_budget",
      "data-parent": "#accordion"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-row"
  }, [_vm.plant.country ? _c('div', {
    staticClass: "form-group col-sm-6"
  }, [_c('label', {
    attrs: {
      "for": "inputCountry"
    }
  }, [_vm._v("Country")]), _c('select', {
    staticClass: "form-control",
    attrs: {
      "id": "inputCountry",
      "disabled": "",
      "readonly": ""
    }
  }, [_c('option', [_vm._v(" " + _vm._s(_vm.plant.country.name) + " ")])])]) : _vm._e(), _c('div', {
    staticClass: "form-group col-sm-6"
  }, [_c('label', {
    attrs: {
      "for": "inputTechnology"
    }
  }, [_vm._v("Technology")]), _c('select', {
    staticClass: "form-control",
    attrs: {
      "id": "inputTechnology",
      "disabled": "",
      "readonly": ""
    }
  }, [_c('option', [_vm._v(" " + _vm._s(_vm.plant.technology) + " ")])])])]), _c('div', {
    staticClass: "form-row border-row pb-3 align-items-baseline"
  }, [_c('div', {
    staticClass: "form-group col-xl-4 col-sm-6"
  }, [_c('label', {
    attrs: {
      "for": "inputPlNameRg"
    }
  }, [_vm._v("Plant Name / River Group")]), _c('select', {
    staticClass: "form-control",
    attrs: {
      "id": "inputPlNameRg",
      "disabled": "",
      "readonly": ""
    }
  }, [_c('option', {
    attrs: {
      "selected": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.plant.plantGroupName) + " ")])])]), _c('div', {
    staticClass: "form-group col-xl-4 col-sm-6"
  }, [_c('label', {
    attrs: {
      "for": "inputPlName"
    }
  }, [_vm._v("Plant")]), _c('select', {
    staticClass: "form-control",
    attrs: {
      "id": "inputPlName",
      "disabled": "",
      "readonly": ""
    }
  }, [_c('option', {
    attrs: {
      "selected": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.plant.name) + " ")])])]), _c('div', {
    staticClass: "form-group col-xl-4 col-sm-6"
  }, [_c('label', {
    attrs: {
      "for": "inputUnitName"
    }
  }, [_vm._v("Unit")]), _c('multiselect', {
    class: [_vm.validateBudgetRequests.unit ? 'is-invalid form-control' : ''],
    attrs: {
      "allow-empty": false,
      "options": _vm.plant.units,
      "searchable": false,
      "show-labels": false,
      "placeholder": "Please select"
    },
    on: {
      "input": function ($event) {
        return _vm.clearError('unit');
      }
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function (props) {
        return [_vm._v(" " + _vm._s(props.option.name) + " ")];
      }
    }, {
      key: "option",
      fn: function (props) {
        return [_vm._v(" " + _vm._s(props.option.name) + " ")];
      }
    }], null, false, 1493010454),
    model: {
      value: _vm.selectedUnitId,
      callback: function ($$v) {
        _vm.selectedUnitId = $$v;
      },
      expression: "selectedUnitId"
    }
  }), _c('small', {
    staticClass: "form-text text-muted"
  }), _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" You must select an option. ")])], 1)]), _c('div', {
    staticClass: "form-row pt-md-4 pt-1 external"
  }, [_vm._m(1), _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    attrs: {
      "for": "externalId"
    }
  }, [_vm._v("Risk / Maintenance ID")]), _vm._l(_vm.externalInputs, function (input, index) {
    return _c('div', {
      key: index,
      staticClass: "row mr-0 ml-0",
      class: index === 0 ? '' : 'mt-3'
    }, [_c('div', {
      staticClass: "col-md-10 col-sm-6 col-8 pl-0 pr-xl-3 pr-lg-2 pr-md-3"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: input.id,
        expression: "input.id"
      }],
      staticClass: "form-control",
      class: [input.isInvalid ? 'is-invalid' : _vm.newBudgetRequest.planning.attachedExternalId && input.id.toUpperCase() === _vm.newBudgetRequest.planning.attachedExternalId.toUpperCase() ? 'font-weight-bold' : ''],
      attrs: {
        "id": 'externalId' + index,
        "autocomplete": "none",
        "placeholder": "Start typing to search",
        "type": "text"
      },
      domProps: {
        "value": input.id
      },
      on: {
        "focusout": function ($event) {
          return _vm.getDataByExternalId(input.id, index);
        },
        "input": function ($event) {
          if ($event.target.composing) { return; }

          _vm.$set(input, "id", $event.target.value);
        }
      }
    }), index === 0 ? _c('small', {
      staticClass: "form-text text-muted"
    }, [_vm._v("e.g. \"RAT01234-R for PT Risk connection or RAT01234-M for MSP connection\" ")]) : _vm._e(), _c('div', {
      staticClass: "invalid-feedback"
    }, [_vm._v(" " + _vm._s(input.errorMessage) + " ")])]), index > 0 || _vm.externalInputs.length > 1 || input.id.length > 0 ? _c('a', {
      staticClass: "col-2 p-0",
      on: {
        "click": function ($event) {
          return _vm.removeExternalInput(index);
        }
      }
    }, [_vm._v("Remove ")]) : _vm._e()]);
  }), _c('a', {
    on: {
      "click": function ($event) {
        return _vm.addExternalInput();
      }
    }
  }, [_vm._v("Add another external ID")])], 2)]), _c('div', {
    staticClass: "form-row border-top-row pt-4"
  }, [_c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    class: [_vm.validateMappings.namesAreEqual ? '' : 'warning-label'],
    attrs: {
      "for": "inputName"
    }
  }, [_vm._v("Name (English) ")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newBudgetRequest.name,
      expression: "newBudgetRequest.name"
    }],
    staticClass: "form-control",
    class: [_vm.validateBudgetRequests.name ? 'is-invalid' : '', _vm.validateMappings.namesAreEqual ? '' : 'warning'],
    attrs: {
      "id": "inputName",
      "autocomplete": "none",
      "placeholder": "Please enter text",
      "type": "text"
    },
    domProps: {
      "value": _vm.newBudgetRequest.name
    },
    on: {
      "change": function ($event) {
        return _vm.clearError('name');
      },
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newBudgetRequest, "name", $event.target.value);
      }
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Please provide a name. ")])]), _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    class: [_vm.validateMappings.nameLocalLanguagesAreEqual ? '' : 'warning-label'],
    attrs: {
      "for": "inputNameLocal"
    }
  }, [_vm._v("Name (local language) "), _c('small', {
    staticClass: "text-muted"
  }, [_vm._v(" [optional]")])]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newBudgetRequest.localLanguageName,
      expression: "newBudgetRequest.localLanguageName"
    }],
    staticClass: "form-control",
    class: [_vm.validateMappings.nameLocalLanguagesAreEqual ? '' : 'warning'],
    attrs: {
      "id": "inputNameLocal",
      "autocomplete": "none",
      "placeholder": "Please enter text",
      "type": "text"
    },
    domProps: {
      "value": _vm.newBudgetRequest.localLanguageName
    },
    on: {
      "change": function ($event) {
        return _vm.clearError('localLanguageName', 'prevent');
      },
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newBudgetRequest, "localLanguageName", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "form-group col-xl-6 col-lg-12 col-md-6"
  }, [_c('label', {
    staticClass: "d-block",
    class: [_vm.validateMappings.projectTypesAreEqual ? '' : 'warning-label']
  }, [_vm._v(" Project Type ")]), _vm._l(_vm.data.constants.projectTypes, function (projectType, index) {
    return [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.newBudgetRequest.projectType,
        expression: "newBudgetRequest.projectType"
      }],
      key: index,
      staticClass: "vis-hidden form-control",
      class: [_vm.validateBudgetRequests.projectType ? 'is-invalid' : ''],
      attrs: {
        "id": projectType,
        "name": "project",
        "type": "radio"
      },
      domProps: {
        "value": index,
        "checked": _vm._q(_vm.newBudgetRequest.projectType, index)
      },
      on: {
        "change": [function ($event) {
          return _vm.$set(_vm.newBudgetRequest, "projectType", index);
        }, function ($event) {
          return _vm.clearError('projectType');
        }]
      }
    }), _c('label', {
      key: projectType,
      staticClass: "sameSize",
      attrs: {
        "for": projectType
      }
    }, [_vm._v(" " + _vm._s(projectType) + " ")])];
  }), _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Please select an option. ")]), _c('small', {
    staticClass: "form-text text-muted"
  }, [_vm._v(" A \"Project\" has a defined start and end date \"Non-Project\" items are recurring e.g. daily maintenance. ")])], 2), _c('div', {
    staticClass: "form-group col-xl-6 col-lg-12 col-md-6"
  }, [_c('label', {
    staticClass: "d-block",
    class: [_vm.validateMappings.lumpSumsAreEqual ? '' : 'warning-label']
  }, [_vm._v("Lump Sum ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newBudgetRequest.planning.lumpSum,
      expression: "newBudgetRequest.planning.lumpSum"
    }],
    staticClass: "vis-hidden form-control",
    class: [_vm.validateBudgetRequests.lumpSum ? 'is-invalid' : ''],
    attrs: {
      "id": "lump_yes",
      "name": "lump",
      "type": "radio"
    },
    domProps: {
      "value": true,
      "checked": _vm._q(_vm.newBudgetRequest.planning.lumpSum, true)
    },
    on: {
      "change": [function ($event) {
        return _vm.$set(_vm.newBudgetRequest.planning, "lumpSum", true);
      }, function ($event) {
        return _vm.clearError('lumpSum');
      }]
    }
  }), _c('label', {
    staticClass: "sameSize",
    attrs: {
      "for": "lump_yes"
    }
  }, [_vm._v("Yes ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newBudgetRequest.planning.lumpSum,
      expression: "newBudgetRequest.planning.lumpSum"
    }],
    staticClass: "vis-hidden form-control",
    class: [_vm.validateBudgetRequests.lumpSum ? 'is-invalid' : ''],
    attrs: {
      "id": "lump_no",
      "name": "lump",
      "type": "radio"
    },
    domProps: {
      "value": false,
      "checked": _vm._q(_vm.newBudgetRequest.planning.lumpSum, false)
    },
    on: {
      "change": [function ($event) {
        return _vm.$set(_vm.newBudgetRequest.planning, "lumpSum", false);
      }, function ($event) {
        return _vm.clearError('lumpSum');
      }]
    }
  }), _c('label', {
    staticClass: "sameSize",
    attrs: {
      "for": "lump_no"
    }
  }, [_vm._v("No ")]), _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Please select an option. ")]), _c('small', {
    staticClass: "form-text text-muted"
  }, [_vm._v("A lump sum is defined as a set of projects/activities whose individual total costs are below 200k€ each. ")])])]), _c('div', {
    staticClass: "form-row border-row pb-3"
  }, [_c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    class: [_vm.validateMappings.startDatesAreEqual ? '' : 'warning-label'],
    attrs: {
      "for": "start_date"
    }
  }, [_vm._v("Start Date "), _c('span', {
    staticClass: "wrapper-icon",
    attrs: {
      "id": "tooltip-target-startdate"
    }
  }, [_c('icon', {
    staticClass: "icon-info",
    attrs: {
      "name": "info"
    }
  })], 1), _c('b-tooltip', {
    attrs: {
      "custom-class": "my-tooltip-class",
      "target": "tooltip-target-startdate",
      "triggers": "hover"
    }
  }, [_c('div', [_vm._v("For project, it is mandatory to provide a start date, which will be the expected date or the actual date by which the financial commitment of the project is or was legally processed, such as the award of a purchase order, but excluding \"planning cost\".")]), _c('div', [_vm._v("For non-project activities, a rough estimation is sufficient.")])])], 1), _c('datepicker', {
    attrs: {
      "default-value": _vm.newBudgetRequest.planning.endDate ? new Date(_vm.newBudgetRequest.planning.endDate) : _vm.currentDate,
      "disabled-date": _vm.notAfterEndDate,
      "format": "DD/MM/YYYY",
      "input-class": "form-control",
      "placeholder": "dd/mm/yyyy"
    },
    on: {
      "change": function ($event) {
        return _vm.clearError('startDate');
      }
    },
    model: {
      value: _vm.newBudgetRequest.planning.startDate,
      callback: function ($$v) {
        _vm.$set(_vm.newBudgetRequest.planning, "startDate", $$v);
      },
      expression: "newBudgetRequest.planning.startDate"
    }
  }), _c('div', {
    class: [_vm.validateBudgetRequests.startDate ? 'is-invalid form-control' : ''],
    staticStyle: {
      "display": "none"
    }
  }), _c('div', {
    staticClass: "invalid-feedback",
    staticStyle: {
      "position": "absolute"
    },
    attrs: {
      "slot": "afterDateInput"
    },
    slot: "afterDateInput"
  }, [_vm._v(" Please select a date. "), _vm.validateGreatherStartDate ? _c('span', [_vm._v("Start Date needs to be earlier than End Date. ")]) : _vm._e()])], 1), _c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    class: [_vm.validateMappings.endDatesAreEqual ? '' : 'warning-label'],
    attrs: {
      "for": "end_date"
    }
  }, [_vm._v("End Date "), _c('span', {
    staticClass: "wrapper-icon",
    attrs: {
      "id": "tooltip-target-enddate"
    }
  }, [_c('icon', {
    staticClass: "icon-info",
    attrs: {
      "name": "info"
    }
  })], 1), _c('b-tooltip', {
    attrs: {
      "custom-class": "my-tooltip-class",
      "target": "tooltip-target-enddate",
      "triggers": "hover"
    }
  }, [_c('div', [_vm._v("For project, please indicate the date when all financial commitments between Uniper and the contractor have been completed and further control measures have been implemented and successfully tested.")])])], 1), _c('datepicker', {
    attrs: {
      "default-value": _vm.newBudgetRequest.planning.startDate ? new Date(_vm.newBudgetRequest.planning.startDate) : _vm.currentDate,
      "disabled-date": _vm.notBeforeStartDate,
      "format": "DD/MM/YYYY",
      "input-class": "form-control",
      "placeholder": "dd/mm/yyyy"
    },
    on: {
      "change": function ($event) {
        return _vm.clearError('startDate');
      }
    },
    model: {
      value: _vm.newBudgetRequest.planning.endDate,
      callback: function ($$v) {
        _vm.$set(_vm.newBudgetRequest.planning, "endDate", $$v);
      },
      expression: "newBudgetRequest.planning.endDate"
    }
  })], 1)]), _c('div', {
    staticClass: "form-row pt-4"
  }, [!_vm.loading ? _c('div', {
    staticClass: "form-group col-md-12"
  }, [_c('label', {
    staticClass: "d-block",
    class: [_vm.validateMappings.aeroClassificationsAreEqual ? '' : 'warning-label']
  }, [_vm._v("AERO Classification "), _c('span', {
    staticClass: "wrapper-icon",
    attrs: {
      "id": "tooltip-target-1"
    }
  }, [_c('icon', {
    staticClass: "icon-info",
    attrs: {
      "name": "info"
    }
  })], 1), _c('b-tooltip', {
    attrs: {
      "custom-class": "my-tooltip-class",
      "target": "tooltip-target-1",
      "triggers": "hover"
    }
  }, [_c('div', [_vm._v("The asset management classification:")]), _c('div', [_c('b', {
    staticClass: "tooltip-white-text"
  }, [_vm._v(" Maintenance ")]), _vm._v(" – Baseline maintenance that is required according to engineering standards, incl. day-to-day maintenance. ")]), _c('div', [_c('b', {
    staticClass: "tooltip-white-text"
  }, [_vm._v(" Risk ")]), _vm._v(" – A one-off project that aims to correct negative deviation from the normal declared performance parameters. ")]), _c('div', [_c('b', {
    staticClass: "tooltip-white-text"
  }, [_vm._v(" Opportunity ")]), _vm._v(" – A project that would lead to an improvement over and above the declared performance parameters. ")]), _c('div', [_c('b', {
    staticClass: "tooltip-white-text"
  }, [_vm._v(" Statutory ")]), _vm._v(" – Required by law to be addressed without any flexibility. ")]), _c('div', [_c('b', {
    staticClass: "tooltip-white-text"
  }, [_vm._v(" Not available ")]), _vm._v(" – Non-maintenance expenditure (e.g. Asset Development Projects, Decommissioning projects, etc.). ")])])], 1), _vm._l(_vm.aeroClassificationSortedOptions, function (index) {
    return [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.newBudgetRequest.planning.aeroClassification,
        expression: "newBudgetRequest.planning.aeroClassification"
      }],
      key: index,
      staticClass: "vis-hidden form-control",
      class: [_vm.validateBudgetRequests.aero ? 'is-invalid' : ''],
      attrs: {
        "id": _vm.data.constants.aeroClassification[index],
        "name": "aero",
        "type": "radio"
      },
      domProps: {
        "value": index,
        "checked": _vm._q(_vm.newBudgetRequest.planning.aeroClassification, index)
      },
      on: {
        "change": [function ($event) {
          return _vm.$set(_vm.newBudgetRequest.planning, "aeroClassification", index);
        }, function ($event) {
          return _vm.fillPrimaryDepartment(index);
        }]
      }
    }), _c('label', {
      key: _vm.data.constants.aeroClassification[index],
      staticClass: "aero-button",
      attrs: {
        "for": _vm.data.constants.aeroClassification[index]
      }
    }, [_vm._v(" " + _vm._s(_vm.data.constants.aeroClassification[index]) + " ")])];
  }), _vm.newBudgetRequest.planning.aeroClassification === 0 ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Please select an option. ")]) : _vm._e(), _vm.newBudgetRequest.planning.aeroClassification > 0 ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Please check Risk/Maintenace id. ")]) : _vm._e()], 2) : _vm._e()]), _c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    class: [_vm.validateMappings.justificationOnClassificationsAreEqual ? '' : 'warning-label'],
    attrs: {
      "for": "justification"
    }
  }, [_vm._v("Justification on Classification ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newBudgetRequest.planning.justificationOnClassification,
      expression: "newBudgetRequest.planning.justificationOnClassification"
    }],
    staticClass: "form-control",
    class: [_vm.validateBudgetRequests.justification ? 'is-invalid' : '', _vm.validateMappings.justificationOnClassificationsAreEqual ? '' : 'warning'],
    attrs: {
      "id": "justification",
      "autocomplete": "none",
      "placeholder": "Please enter a justification",
      "type": "text"
    },
    domProps: {
      "value": _vm.newBudgetRequest.planning.justificationOnClassification
    },
    on: {
      "change": function ($event) {
        return _vm.clearError('justification');
      },
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newBudgetRequest.planning, "justificationOnClassification", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Please provide a justification. ")])])]), _c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    attrs: {
      "for": "pr_depart"
    }
  }, [_vm._v("Primary Department")]), _c('multiselect', {
    class: [_vm.validateBudgetRequests.primaryDepartment ? 'is-invalid' : ''],
    attrs: {
      "id": "pr_depart",
      "options": Object.keys(_vm.data.constants.primaryDepartment),
      "allow-empty": false,
      "searchable": false,
      "show-labels": false,
      "placeholder": "Please select"
    },
    on: {
      "input": function ($event) {
        return _vm.clearError('primaryDepartment');
      }
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function (props) {
        return [_vm._v(" " + _vm._s(_vm.data.constants.primaryDepartment[props.option]) + " ")];
      }
    }, {
      key: "option",
      fn: function (props) {
        return [_vm._v(" " + _vm._s(_vm.data.constants.primaryDepartment[props.option]) + " ")];
      }
    }], null, false, 3456986774),
    model: {
      value: _vm.selectedPrimaryDepartment,
      callback: function ($$v) {
        _vm.selectedPrimaryDepartment = $$v;
      },
      expression: "selectedPrimaryDepartment"
    }
  }), _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Please provide a primary department. ")]), _vm.isPrimaryDepartmentDefinedbyAero ? _c('small', {
    staticClass: "form-text text-muted"
  }, [_vm._v(" Defined by \"AERO Classification\". ")]) : _vm._e()], 1)]), _c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    class: [_vm.validateMappings.maintenanceTypesAreEqual ? '' : 'warning-label'],
    attrs: {
      "for": "maintenance_type"
    }
  }, [_vm._v("Maintenance Type "), _c('span', {
    staticClass: "wrapper-icon",
    attrs: {
      "id": "maintenance_type_tooltip"
    }
  }, [_c('icon', {
    staticClass: "icon-info",
    attrs: {
      "name": "info"
    }
  })], 1), _c('b-tooltip', {
    attrs: {
      "custom-class": "my-tooltip-class",
      "target": "maintenance_type_tooltip",
      "triggers": "hover"
    }
  }, [_c('div', [_vm._v(" Indicates the maintenance type for the respective line item (not mandatory for hydro plants): ")]), _c('div', [_c('b', {
    staticClass: "tooltip-white-text"
  }, [_vm._v(" Corrective ")]), _vm._v(" – Maintenance carried out after fault recognition and intended to put an item into a state in which it can perform a required function. ")]), _c('div', [_c('b', {
    staticClass: "tooltip-white-text"
  }, [_vm._v(" Mandatory Preventive ")]), _vm._v(" – All preventive maintenance measures, which are required by law or which are of a legal type. They can also be part of an approval for the operation of an asset or based on existing legally binding contracts. ")]), _c('div', [_c('b', {
    staticClass: "tooltip-white-text"
  }, [_vm._v(" Non-mandatory Preventive ")]), _vm._v(" – All preventive maintenance measures, which are not required by law or of a legal type. These can result from recommendations of the vendor/provider, from risk analyses/evaluations, from experience, from warranties, etc. ")]), _c('div', [_c('b', {
    staticClass: "tooltip-white-text"
  }, [_vm._v(" Not available ")]), _vm._v(" – Non-maintenance expenditure. ")])])], 1), _c('multiselect', {
    class: [_vm.validateBudgetRequests.maintenanceType ? 'is-invalid form-control' : ''],
    attrs: {
      "allow-empty": false,
      "options": Object.keys(_vm.data.constants.maintenanceType),
      "searchable": false,
      "show-labels": false,
      "placeholder": "Please select"
    },
    on: {
      "input": function ($event) {
        return _vm.clearError('maintenanceType');
      }
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function (props) {
        return [_vm._v(" " + _vm._s(_vm.data.constants.maintenanceType[props.option]) + " ")];
      }
    }, {
      key: "option",
      fn: function (props) {
        return [_vm._v(" " + _vm._s(_vm.data.constants.maintenanceType[props.option]) + " ")];
      }
    }], null, false, 1979294070),
    model: {
      value: _vm.selectedMaintenanceType,
      callback: function ($$v) {
        _vm.selectedMaintenanceType = $$v;
      },
      expression: "selectedMaintenanceType"
    }
  }), _c('small', {
    staticClass: "form-text text-muted"
  }), _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" You must select an option. ")])], 1)]), _c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "form-group col-xl-6 col-lg-12 col-md-6"
  }, [_c('label', {
    staticClass: "d-block",
    class: [_vm.validateMappings.outageTypesAreEqual ? '' : 'warning-label']
  }, [_vm._v(" Outage Type ")]), _vm._l(_vm.data.constants.outageType, function (outageType, index) {
    return [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model.number",
        value: _vm.newBudgetRequest.planning.outageType,
        expression: "newBudgetRequest.planning.outageType",
        modifiers: {
          "number": true
        }
      }],
      key: index,
      staticClass: "vis-hidden form-control",
      class: [_vm.validateBudgetRequests.outageType ? 'is-invalid' : ''],
      attrs: {
        "id": outageType,
        "name": "outage",
        "type": "radio"
      },
      domProps: {
        "value": index,
        "checked": _vm._q(_vm.newBudgetRequest.planning.outageType, _vm._n(index))
      },
      on: {
        "change": [function ($event) {
          _vm.$set(_vm.newBudgetRequest.planning, "outageType", _vm._n(index));
        }, function ($event) {
          return _vm.clearError('outageType');
        }]
      }
    }), _c('label', {
      key: outageType,
      staticClass: "sameSize",
      attrs: {
        "for": outageType
      }
    }, [_vm._v(" " + _vm._s(outageType) + " ")])];
  }), _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Please select an option. For Hydro plants please choose „Non-Outage“. ")]), _c('small', {
    staticClass: "form-text text-muted"
  }, [_vm._v("For Fossil plants, please indicate whether the works are part of an \"Outage\" or not. For Hydro plants please choose \"Non-Outage\"\" ")])], 2)]), _c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    staticClass: "d-block",
    class: [_vm.validateMappings.outageIdsAreEqual ? '' : 'warning-label'],
    attrs: {
      "for": "outage_id"
    }
  }, [_vm._v("Outage ID ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newBudgetRequest.planning.outageId,
      expression: "newBudgetRequest.planning.outageId"
    }],
    staticClass: "form-control",
    class: [_vm.validateBudgetRequests.outageId ? 'is-invalid' : '', _vm.validateMappings.outageIdsAreEqual ? '' : 'warning'],
    attrs: {
      "id": "outage_id",
      "disabled": _vm.newBudgetRequest.planning.outageType !== 1,
      "autocomplete": "none",
      "name": "outage_id",
      "placeholder": "Start typing to search",
      "type": "text"
    },
    domProps: {
      "value": _vm.newBudgetRequest.planning.outageId
    },
    on: {
      "change": function ($event) {
        return _vm.clearError('outageId');
      },
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newBudgetRequest.planning, "outageId", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Please provide an outage ID. ")]), _c('small', {
    staticClass: "form-text text-muted"
  }, [_vm._v("e.g. \"OUT/WHV/1/21.01\". For small outages without outage IDs, please enter the standard term \"N/A small(<2.5m€) outage.\" ")])])]), _c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    attrs: {
      "for": "procurement_cat"
    }
  }, [_vm._v("Procurement Category "), _c('span', {
    staticClass: "wrapper-icon",
    attrs: {
      "id": "tooltip-target-2"
    }
  }, [_c('icon', {
    staticClass: "icon-info",
    attrs: {
      "name": "info"
    }
  })], 1), _c('b-tooltip', {
    attrs: {
      "custom-class": "my-tooltip-class",
      "target": "tooltip-target-2",
      "triggers": "hover"
    }
  }, [_c('div', [_vm._v("Please choose the dominant procurement categories (Single selections possible). If it is a lump sum spend, please choose \"Others\": ")]), _c('div', [_c('b', {
    staticClass: "tooltip-white-text"
  }, [_vm._v(" Boiler ")]), _vm._v(" – Ultra-critical boiler, auxiliary boiler, mills / grinding systems, burner, firing systems, high-pressure piping. ")]), _c('div', [_c('b', {
    staticClass: "tooltip-white-text"
  }, [_vm._v(" Mechanical ")]), _vm._v(" – Small pipes, industrial cooling systems, conveying and handling services of primary fuel, pumps, compressors and systems, water treatment, valves, ventilator, etc. ")]), _c('div', [_c('b', {
    staticClass: "tooltip-white-text"
  }, [_vm._v(" Turboset ")]), _vm._v(" – Steam / Gas turbine, electrical generator, CHP Turbine, turbine control & protection system. ")]), _c('div', [_c('b', {
    staticClass: "tooltip-white-text"
  }, [_vm._v(" Pollution Control Equipment ")]), _vm._v(" – Scrubbers (wet or dry), heat exchanger, precipitators, NOx reduction systems, Catalysts, rubber lining works (acid protection), fly ash removal to ash bin, vacuum belt filter. ")]), _c('div', [_c('b', {
    staticClass: "tooltip-white-text"
  }, [_vm._v(" Electrical ")]), _vm._v(" – Transformers, switchgears, power supply units, Diesel generators, electrical components and installations. ")]), _c('div', [_c('b', {
    staticClass: "tooltip-white-text"
  }, [_vm._v(" Instrumentation & Controls (I&C) ")]), _vm._v(" – I&C components, Safety systems (e.g. fire alarm, access control, etc.), CHP specific controls. ")]), _c('div', [_c('b', {
    staticClass: "tooltip-white-text"
  }, [_vm._v(" Hydro Technology (excluding Electrical I&C, and Civil) ")]), _vm._v(" – Hydro turbine, hydro generator, hydro gates and trash racks. ")]), _c('div', [_c('b', {
    staticClass: "tooltip-white-text"
  }, [_vm._v(" Civil, infrastructure & general services ")]), _vm._v(" – Underground and civil works, Hydro dams / fish passes / canals, building construction works, dismantling and disposal services. ")]), _c('div', [_c('b', {
    staticClass: "tooltip-white-text"
  }, [_vm._v(" Engineering services ")]), _vm._v(" – Engineering studies and tests, technical consultancy on plant operation and projects, handling of technical documents incl. quality issues. ")]), _c('div', [_c('b', {
    staticClass: "tooltip-white-text"
  }, [_vm._v(" Others ")]), _vm._v(" – Maintenance consumables, commodities, professional services, facility management, etc. ")])])], 1), _c('multiselect', {
    class: [_vm.validateBudgetRequests.procurementCategory ? 'is-invalid form-control' : ''],
    attrs: {
      "allow-empty": false,
      "options": Object.keys(_vm.data.constants.procurementCategory),
      "searchable": false,
      "show-labels": false,
      "placeholder": "Please select"
    },
    on: {
      "input": function ($event) {
        return _vm.clearError('procurementCategory');
      }
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function (props) {
        return [_vm._v(" " + _vm._s(_vm.data.constants.procurementCategory[props.option]) + " ")];
      }
    }, {
      key: "option",
      fn: function (props) {
        return [_vm._v(" " + _vm._s(_vm.data.constants.procurementCategory[props.option]) + " ")];
      }
    }], null, false, 1593887958),
    model: {
      value: _vm.selectedProcurementCategory,
      callback: function ($$v) {
        _vm.selectedProcurementCategory = $$v;
      },
      expression: "selectedProcurementCategory"
    }
  }), _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Please select an option. ")]), _c('small', {
    staticClass: "form-text text-muted"
  }, [_vm._v("If a lump sum spend, please select \"Others\" ")])], 1)]), _c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    attrs: {
      "for": "inputComment"
    }
  }, [_vm._v("Comment")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newBudgetRequest.planning.comment,
      expression: "newBudgetRequest.planning.comment"
    }],
    staticClass: "form-control",
    class: [_vm.validateBudgetRequests.comment ? 'is-invalid' : ''],
    attrs: {
      "id": "inputComment",
      "autocomplete": "none",
      "placeholder": "Please enter a comment",
      "type": "text"
    },
    domProps: {
      "value": _vm.newBudgetRequest.planning.comment
    },
    on: {
      "change": function ($event) {
        return _vm.clearError('comment');
      },
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newBudgetRequest.planning, "comment", $event.target.value);
      }
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Please provide a comment. ")])])])])])]), _c('div', {
    staticClass: "accordion-finance-budget mt-3",
    attrs: {
      "id": "accordion"
    }
  }, [_vm._m(2), _c('div', {
    staticClass: "collapse show budget-request-wrapper",
    attrs: {
      "id": "accordionFinance",
      "aria-labelledby": "heading_accordion_budget",
      "data-parent": "#accordion"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    attrs: {
      "for": "inputWbs"
    }
  }, [_vm._v("WBS (PSP) Element "), _c('span', {
    staticClass: "wrapper-icon",
    attrs: {
      "id": "wbs_element_tooltip"
    }
  }, [_c('icon', {
    staticClass: "icon-info",
    attrs: {
      "name": "info"
    }
  })], 1), _c('b-tooltip', {
    attrs: {
      "custom-class": "my-tooltip-class",
      "target": "wbs_element_tooltip",
      "triggers": "hover"
    }
  }, [_c('div', [_vm._v("The WBS Element is sourced from SAP. If not yet available, please enter \"N/A\". ")])])], 1), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newBudgetRequest.planning.wbsElement,
      expression: "newBudgetRequest.planning.wbsElement"
    }],
    staticClass: "form-control",
    class: [_vm.validateBudgetRequests.wbs ? 'is-invalid' : ''],
    attrs: {
      "id": "inputWbs",
      "autocomplete": "none",
      "placeholder": "Please enter an ID",
      "type": "text"
    },
    domProps: {
      "value": _vm.newBudgetRequest.planning.wbsElement
    },
    on: {
      "change": function ($event) {
        return _vm.clearError('wbs');
      },
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newBudgetRequest.planning, "wbsElement", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Please provide an ID, e.g. „0301.A01234“. ")]), _c('small', {
    staticClass: "form-text text-muted"
  }, [_vm._v("e.g. \"0301.A01234\". If it's not yet available, please enter \"N/A\" ")])])]), _c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    class: [_vm.validateMappings.planningAndControllingClassificationsAreEqual ? '' : 'warning-label'],
    attrs: {
      "for": "inputControlling"
    }
  }, [_vm._v("Planning & Controlling Classification "), _c('span', {
    staticClass: "wrapper-icon",
    attrs: {
      "id": "tooltip-target-3"
    }
  }, [_c('icon', {
    staticClass: "icon-info",
    attrs: {
      "name": "info"
    }
  })], 1), _c('b-tooltip', {
    attrs: {
      "custom-class": "my-tooltip-class",
      "target": "tooltip-target-3",
      "triggers": "hover"
    }
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_vm._v(" Indication of controlling classification of the respective budget request: ")]), _c('div', {
    staticClass: "mb-3"
  }, [_c('b', {
    staticClass: "tooltip-white-text"
  }, [_vm._v(" CAPEX: Maintenance ")]), _vm._v(" – Comprises all investments to retain and restore the existing performance (capacity, efficiency, availability, reliability, flexibility). ")]), _c('div', {
    staticClass: "mb-3"
  }, [_c('b', {
    staticClass: "tooltip-white-text"
  }, [_vm._v(" CAPEX: Replacement ")]), _vm._v(" – Investments that intend to change the technical functionality / character of an asset are to be classified as replacement investments. ")]), _c('div', {
    staticClass: "mb-3"
  }, [_c('b', {
    staticClass: "tooltip-white-text"
  }, [_vm._v(" CAPEX: Discretionary Growth ")]), _vm._v(" – Comprises development and expansion within existing and new regions and markets which refer to projects that have not been approved by certain approval body (e.g. new Asset Development projects). ")]), _c('div', {
    staticClass: "mb-3"
  }, [_c('b', {
    staticClass: "tooltip-white-text"
  }, [_vm._v(" CAPEX - Green Growth Strategy ")]), _vm._v(" – All existing projects and new projects that are attributable to the “new strategy” e.g. Renewables, Green Molecules Assets, Clean Dispatchable Power etc. “New strategy” - all investments related to the strategic pillars Renewables, Clean Dispatchable Power, Power RES PPA and clean certificates, Green Molecules origination, Green Molecules assets and Green Storage. ")]), _c('div', {
    staticClass: "mb-3"
  }, [_c('b', {
    staticClass: "tooltip-white-text"
  }, [_vm._v(" CAPEX - Green Growth Other ")]), _vm._v(" – Projects that are not attributable to the “New strategy” but can be classified as green e.g. projects for Green Heat BP and other Hydro Projects. “New strategy” - all investments related to the strategic pillars Renewables, Clean Dispatchable Power, Power RES PPA and clean certificates, Green Molecules origination, Green Molecules assets and Green Storage. ")]), _c('div', {
    staticClass: "mb-3"
  }, [_c('b', {
    staticClass: "tooltip-white-text"
  }, [_vm._v(" CAPEX - Other Growth ")]), _vm._v(" - Projects related to all growth projects that are not related to the “New strategy” and are not classified as green. ")]), _c('div', {
    staticClass: "mb-3"
  }, [_c('b', {
    staticClass: "tooltip-white-text"
  }, [_vm._v(" OPEX ")]), _vm._v(" – Operational spend. ")]), _c('div', [_c('b', {
    staticClass: "tooltip-white-text"
  }, [_vm._v(" Provision ")]), _vm._v(" – A provision is an amount set aside for the probable, but uncertain, economic obligations. ")])])], 1), _c('multiselect', {
    class: [_vm.validateBudgetRequests.planning ? 'is-invalid form-control' : ''],
    attrs: {
      "allow-empty": false,
      "options": _vm.planningAndControllingClassificationSortedOptions,
      "searchable": false,
      "show-labels": false,
      "placeholder": "Please select"
    },
    on: {
      "input": function ($event) {
        return _vm.clearError('planning');
      }
    },
    scopedSlots: _vm._u([{
      key: "singleLabel",
      fn: function (props) {
        return [_vm._v(" " + _vm._s(_vm.data.constants.allPlanningAndControllingClassification[props.option]) + " ")];
      }
    }, {
      key: "option",
      fn: function (props) {
        return [_vm._v(" " + _vm._s(_vm.data.constants.allPlanningAndControllingClassification[props.option]) + " ")];
      }
    }], null, false, 720977302),
    model: {
      value: _vm.selectedPlanningAndControllingClassification,
      callback: function ($$v) {
        _vm.selectedPlanningAndControllingClassification = $$v;
      },
      expression: "selectedPlanningAndControllingClassification"
    }
  }), _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" You must select an option. ")]), _c('small', {
    staticClass: "form-text text-muted"
  })], 1)]), _c('label', {
    attrs: {
      "for": "inputShareBudget"
    }
  }, [_vm._v("Uniper Share of Budget")]), _c('div', {
    staticClass: "form-row mb-3"
  }, [_c('div', {
    staticClass: "input-group col-md-6"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newBudgetRequest.planning.uniperShareOfBudget,
      expression: "newBudgetRequest.planning.uniperShareOfBudget"
    }],
    staticClass: "form-control",
    class: [_vm.validateBudgetRequests.uniperShareOfBudget ? 'is-invalid' : ''],
    attrs: {
      "id": "inputShareBudget",
      "type": "number"
    },
    domProps: {
      "value": _vm.newBudgetRequest.planning.uniperShareOfBudget
    },
    on: {
      "change": function ($event) {
        return _vm.clearError('uniperShareOfBudget');
      },
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newBudgetRequest.planning, "uniperShareOfBudget", $event.target.value);
      }
    }
  }), _vm._m(3), _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" You must select an option. ")])])]), _c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    attrs: {
      "for": "uel"
    }
  }, [_vm._v(" Useful Economic Life "), _c('span', {
    staticClass: "wrapper-icon",
    attrs: {
      "id": "uel_tooltip"
    }
  }, [_c('icon', {
    staticClass: "icon-info",
    attrs: {
      "name": "info"
    }
  })], 1), _c('b-tooltip', {
    attrs: {
      "custom-class": "my-tooltip-class",
      "target": "uel_tooltip",
      "triggers": "hover"
    }
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_vm._v(" Useful Economic Life (UEL) is number of years a particular equipment can be operational without any need of preventive maintenance activity. ")]), _c('div', {
    staticClass: "mb-3"
  }, [_vm._v(" For Risk and Opportunity projects, UEL is direct link from PT-Risk “Risk Group Life”. This value will be the expected running life of the station (as advised by Asset Strategy à EAS One pager) OR the life of the replacement component (whichever is lower). ")]), _c('div', [_vm._v(" For Maintenance and Statutory projects, UEL is a direct link from MSPT “UEL”. UEL shall be defined by site and central teams based on the driver defined in MSPT. ")])])], 1), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.newBudgetRequest.planning.usefulEconomicLife,
      expression: "newBudgetRequest.planning.usefulEconomicLife",
      modifiers: {
        "number": true
      }
    }],
    staticClass: "form-control",
    attrs: {
      "id": "uel",
      "placeholder": "Please enter an value in years",
      "type": "number"
    },
    domProps: {
      "value": _vm.newBudgetRequest.planning.usefulEconomicLife
    },
    on: {
      "change": function ($event) {
        return _vm.clearError('usefulEconomicLife');
      },
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newBudgetRequest.planning, "usefulEconomicLife", _vm._n($event.target.value));
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })])]), _c('div', {
    staticClass: "form-row"
  }, [_c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    staticClass: "d-block"
  }, [_vm._v("Currency")]), !_vm.loading ? [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newBudgetRequest.planning.selectedCurrencyId,
      expression: "newBudgetRequest.planning.selectedCurrencyId"
    }],
    staticClass: "vis-hidden form-control",
    class: [_vm.validateBudgetRequests.currency ? 'is-invalid' : ''],
    attrs: {
      "id": _vm.plant.country.currency.id,
      "name": "currency",
      "type": "radio"
    },
    domProps: {
      "value": _vm.plant.country.currency.id,
      "checked": _vm._q(_vm.newBudgetRequest.planning.selectedCurrencyId, _vm.plant.country.currency.id)
    },
    on: {
      "change": [function ($event) {
        return _vm.$set(_vm.newBudgetRequest.planning, "selectedCurrencyId", _vm.plant.country.currency.id);
      }, function ($event) {
        return _vm.clearError('currency');
      }]
    }
  }), _c('label', {
    staticClass: "sameSize"
  }, [_vm._v(_vm._s(_vm.plant.country.currency.code))])] : _vm._e(), _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Please select an option. ")])], 2)]), _c('div', {
    staticClass: "form-row border-row pb-3"
  }, [_c('div', {
    staticClass: "form-group col-md-6"
  }, [_c('label', {
    staticClass: "d-block"
  }, [_vm._v("Has Planning Costs "), _c('span', {
    staticClass: "wrapper-icon",
    attrs: {
      "id": "tooltip-target-hasplanningcosts"
    }
  }, [_c('icon', {
    staticClass: "icon-info",
    attrs: {
      "name": "info"
    }
  })], 1), _c('b-tooltip', {
    attrs: {
      "custom-class": "my-tooltip-class",
      "target": "tooltip-target-hasplanningcosts",
      "triggers": "hover"
    }
  }, [_c('div', [_vm._v("The costs related to a feasibility study and services to cover a preparation stage to run a project (less than 10% of the total project amount).")])])], 1), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newBudgetRequest.planning.hasPlanningCosts,
      expression: "newBudgetRequest.planning.hasPlanningCosts"
    }],
    staticClass: "vis-hidden form-control",
    attrs: {
      "id": "planning_yes",
      "name": "planning",
      "type": "radio"
    },
    domProps: {
      "value": true,
      "checked": _vm._q(_vm.newBudgetRequest.planning.hasPlanningCosts, true)
    },
    on: {
      "change": [function ($event) {
        return _vm.$set(_vm.newBudgetRequest.planning, "hasPlanningCosts", true);
      }, function ($event) {
        return _vm.clearError('hasPlanningCosts');
      }]
    }
  }), _c('label', {
    staticClass: "sameSize",
    attrs: {
      "for": "planning_yes"
    }
  }, [_vm._v(" Yes ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newBudgetRequest.planning.hasPlanningCosts,
      expression: "newBudgetRequest.planning.hasPlanningCosts"
    }],
    staticClass: "vis-hidden form-control",
    attrs: {
      "id": "planning_no",
      "name": "planning",
      "type": "radio"
    },
    domProps: {
      "value": false,
      "checked": _vm._q(_vm.newBudgetRequest.planning.hasPlanningCosts, false)
    },
    on: {
      "change": [function ($event) {
        return _vm.$set(_vm.newBudgetRequest.planning, "hasPlanningCosts", false);
      }, function ($event) {
        return _vm.clearError('hasPlanningCosts');
      }]
    }
  }), _c('label', {
    staticClass: "sameSize",
    attrs: {
      "for": "planning_no"
    }
  }, [_vm._v(" No ")]), _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Please select an option. ")])])]), _c('h6', {
    staticClass: "d-block pl-3 pt-2 pb-2"
  }, [_vm._v(" Planning ")]), _c('div', {
    staticClass: "form-row planning"
  }, [_c('div', {
    staticClass: "form-group col-md-12"
  }, [_c('table', {
    staticClass: "planning-table"
  }, [_c('thead', [_c('tr', [_c('th', {
    class: [_vm.validateMappings.spentUntilsAreEqual ? '' : 'warning-label']
  }, [_vm._v(" Spend until end of " + _vm._s(_vm.currentYear - 1) + " ")]), _c('th', {
    class: [_vm.validateMappings.forecastsAreEqual ? '' : 'warning-label']
  }, [_vm._v(" Forecast MTP " + _vm._s(_vm.currentYear) + " ")]), _c('th', {
    class: [_vm.validateMappings.nextOneYearsAreEqual ? '' : 'warning-label']
  }, [_vm._v(" New MTP " + _vm._s(_vm.currentYear + 1) + " ")]), _c('th', {
    class: [_vm.validateMappings.nextTwoYearsAreEqual ? '' : 'warning-label']
  }, [_vm._v(" New MTP " + _vm._s(_vm.currentYear + 2) + " ")]), _c('th', {
    class: [_vm.validateMappings.nextThreeYearsAreEqual ? '' : 'warning-label']
  }, [_vm._v(" New MTP " + _vm._s(_vm.currentYear + 3) + " ")]), _c('th', {
    class: [_vm.validateMappings.aftersAreEqual ? '' : 'warning-label']
  }, [_vm._v(" After " + _vm._s(_vm.currentYear + 3) + " ")]), _c('th', {
    class: [_vm.validateMappings.totalsAreEqual ? '' : 'warning-label']
  }, [_vm._v(" Total ")])])]), _c('tbody', [_c('tr', [_c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.newBudgetRequest.planning.spendUntilEndOfYear,
      expression: "newBudgetRequest.planning.spendUntilEndOfYear",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "min": "0",
      "step": "0.001",
      "type": "number"
    },
    domProps: {
      "value": _vm.newBudgetRequest.planning.spendUntilEndOfYear
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newBudgetRequest.planning, "spendUntilEndOfYear", _vm._n($event.target.value));
      }, function ($event) {
        return _vm.checkEmpty('spendUntilEndOfYear');
      }],
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.newBudgetRequest.planning.forecast,
      expression: "newBudgetRequest.planning.forecast",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "step": "0.001",
      "type": "number"
    },
    domProps: {
      "value": _vm.newBudgetRequest.planning.forecast
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newBudgetRequest.planning, "forecast", _vm._n($event.target.value));
      }, function ($event) {
        return _vm.checkEmpty('forecast');
      }],
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.newBudgetRequest.planning.new1,
      expression: "newBudgetRequest.planning.new1",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "step": "0.001",
      "type": "number"
    },
    domProps: {
      "value": _vm.newBudgetRequest.planning.new1
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newBudgetRequest.planning, "new1", _vm._n($event.target.value));
      }, function ($event) {
        return _vm.checkEmpty('new1');
      }],
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.newBudgetRequest.planning.new2,
      expression: "newBudgetRequest.planning.new2",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "step": "0.001",
      "type": "number"
    },
    domProps: {
      "value": _vm.newBudgetRequest.planning.new2
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newBudgetRequest.planning, "new2", _vm._n($event.target.value));
      }, function ($event) {
        return _vm.checkEmpty('new2');
      }],
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.newBudgetRequest.planning.new3,
      expression: "newBudgetRequest.planning.new3",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "step": "0.001",
      "type": "number"
    },
    domProps: {
      "value": _vm.newBudgetRequest.planning.new3
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newBudgetRequest.planning, "new3", _vm._n($event.target.value));
      }, function ($event) {
        return _vm.checkEmpty('new3');
      }],
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.newBudgetRequest.planning.after,
      expression: "newBudgetRequest.planning.after",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "step": "0.001",
      "type": "number"
    },
    domProps: {
      "value": _vm.newBudgetRequest.planning.after
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newBudgetRequest.planning, "after", _vm._n($event.target.value));
      }, function ($event) {
        return _vm.checkEmpty('after');
      }],
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _c('td', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.newBrPlanningTotal,
      expression: "newBrPlanningTotal",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "disabled": "",
      "type": "number"
    },
    domProps: {
      "value": _vm.newBrPlanningTotal
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.newBrPlanningTotal = _vm._n($event.target.value);
      },
      "blur": function ($event) {
        return _vm.$forceUpdate();
      }
    }
  })])])])])])]), _c('small', {
    staticClass: "form-text text-muted"
  }, [_vm._v("-e.g. \"0.570\" = 570.000€.")]), _c('small', {
    staticClass: "form-text text-muted"
  }, [_vm._v("-\"Total\" value is calculated differently depending on whether the budget request is a \"project\" or \"Non-project\" ")]), _vm._m(4)])])])]), _c('div', {
    staticClass: "col-xl-4 col-lg-5"
  }, [_c('pui-collapsible', {
    attrs: {
      "expandable": false,
      "headline": "Focus Topics"
    }
  }, [_c('div', {
    staticClass: "collapsible-padding"
  }, [_c('tags', {
    attrs: {
      "view-only": _vm.isTagsViewOnly,
      "current-mode": _vm.editPage ? _vm.brTagsMode : _vm.emitTagsMode,
      "request-id": _vm.newBudgetRequest.planning.id,
      "initial-tags": _vm.tagsList
    },
    on: {
      "tags-list-changed": _vm.handleTagsListChanged
    }
  })], 1)]), _vm.editPage ? _c('div', {
    staticClass: "accordion-review mb-4",
    attrs: {
      "id": "accordionReview"
    }
  }, [_vm._m(5), _c('div', {
    staticClass: "collapse show budget-request-wrapper",
    attrs: {
      "id": "accordionRev",
      "aria-labelledby": "heading_rev",
      "data-parent": "#accordionReview"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-row"
  }, [_vm._m(6), _c('div', {
    staticClass: "col-12"
  }, [_c('h6', [_vm._v(" " + _vm._s(_vm.data.constants.primaryDepartment[_vm.newBudgetRequest.planning.primaryDepartment]) + " ")])])]), _c('div', {
    staticClass: "form-row pt-2"
  }, [_vm._m(7), _c('div', {
    staticClass: "col-12"
  }, [_vm.newBudgetRequest.planning.mainFlag === 0 ? _c('h6', [_vm._v(" No Flag ")]) : _c('h6', [_vm._v(" " + _vm._s(_vm.data.constants.flags[_vm.newBudgetRequest.planning.mainFlag]) + " ")])])]), _c('div', {
    staticClass: "form-row pt-2"
  }, [_vm._m(8), _c('div', {
    staticClass: "col-12"
  }, [_vm.newBudgetRequest.planning.otherFlags.length === 0 ? _c('h6', [_vm._v(" No Flag ")]) : _vm._e(), _vm._l(_vm.newBudgetRequest.planning.otherFlags, function (otherflags, index) {
    return [_c('h6', {
      key: index
    }, [_vm._v(" " + _vm._s(_vm.data.constants.flags[otherflags]) + " ")])];
  })], 2)]), _c('div', {
    staticClass: "form-row pt-2"
  }, [_vm._m(9), _c('div', {
    staticClass: "col-12"
  }, [_vm.newBudgetRequest.planning.reviewComment ? _c('h6', [_vm._v(" " + _vm._s(_vm.newBudgetRequest.planning.reviewComment.text) + " ")]) : _vm._e(), _vm.newBudgetRequest.planning.reviewComment && _vm.formatDateReview ? _c('h6', {
    staticClass: "float-left pr-2 mb-0 nameReview"
  }, [_vm._v(" " + _vm._s(_vm.formatDateReview) + " ")]) : _vm._e(), _vm.newBudgetRequest.planning.reviewers ? _c('h6', [_vm._v(" " + _vm._s(_vm.newBudgetRequest.planning.reviewers) + " ")]) : _vm._e()])])])])]) : _vm._e(), _c('div', {
    staticClass: "accordion-help",
    attrs: {
      "id": "accordionSup"
    }
  }, [_vm._m(10), _c('div', {
    staticClass: "collapse show budget-request-wrapper",
    attrs: {
      "id": "accordionSupport",
      "aria-labelledby": "heading_help_support",
      "data-parent": "#accordionSup"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-row border-row"
  }, [_c('p', [_vm._v(" If you are unsure or have questions regarding the MTP process, please refer to the guidance and documentation available on the "), _vm.userPermissions.includes('OverviewPageView') ? _c('router-link', {
    staticClass: "button-edit",
    attrs: {
      "to": {
        path: 'overview'
      }
    }
  }, [_vm._v(" Overview page ")]) : _vm._e()], 1)])])])]), _vm.externalList && _vm.externalList.length > 0 && !_vm.loadingSlick ? _c('slick', {
    ref: "slick",
    staticClass: "external-slider mt-3",
    attrs: {
      "options": _vm.slickOptions
    }
  }, _vm._l(_vm.externalList, function (elem, key) {
    return _c('div', {
      key: key
    }, [elem.data !== null && elem.type === 'M' ? _c('maintenance-budget-request', {
      attrs: {
        "currency": _vm.plant.country.currency.code,
        "disable-check-boxes": _vm.externalList.length > 1,
        "element-index": key,
        "is-details": false,
        "maintenance": elem
      },
      on: {
        "copy": _vm.maintenanceCopy,
        "onCheck": _vm.applyCheckbox
      }
    }) : _vm._e(), elem.data !== null && elem.type === 'R' ? _c('risk-budget-request', {
      attrs: {
        "element-index": key,
        "risk": elem
      },
      on: {
        "copy": _vm.riskCopy
      }
    }) : _vm._e(), elem.data !== null && elem.type === 'O' ? _c('opportunity-budget-request', {
      attrs: {
        "element-index": key,
        "opportunity": elem
      },
      on: {
        "copy": _vm.opportunityCopy
      }
    }) : _vm._e()], 1);
  }), 0) : _vm._e(), _vm.loadingSlick ? _c('div', {
    staticClass: "text-center mt-4 external-slider",
    staticStyle: {
      "padding": "50px 0"
    }
  }, [_vm._v(" Loading external data... ")]) : _vm._e()], 1)])])], 1) : _c('div', [_c('div', {
    staticClass: "container-fluid pt-3"
  }, [_c('access-denied-modal', {
    on: {
      "clickedcanceleditstate": _vm.onClickCancel
    }
  })], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header",
    attrs: {
      "id": "heading_accordion_budget"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_c('button', {
    staticClass: "btn"
  }, [_vm._v(" Details ")])])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-group col-md-6 pr-3 pt-3 connection-text"
  }, [_c('small', {
    staticClass: "form-text text-muted"
  }, [_c('i', [_vm._v("Connect this Budget request to an external resource. By entering the external ID (Maintenance or Risk) PML will automatically check whether this is a valid ID and it will give you the posibility to copy data from the external resource to PML ")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header",
    attrs: {
      "id": "heading_accordion_budget"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_c('button', {
    staticClass: "btn"
  }, [_vm._v(" FINANCE & PLANNING ")])])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "input-group-append"
  }, [_c('span', {
    staticClass: "input-group-text",
    attrs: {
      "id": "basic-addon2"
    }
  }, [_vm._v("% ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('small', {
    staticClass: "form-text text-muted pl-3"
  }, [_vm._v(" -Project: \"Total\" = \"Spend until end of...\" + \"Forecast MTP...\" + \"New MTPs\" + \"After...\" "), _c('br'), _vm._v(" -Non-project: \"Total\" = sum of \"New MTPs\" ")]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header",
    attrs: {
      "id": "heading_rev"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_c('button', {
    staticClass: "btn",
    attrs: {
      "aria-controls": "accordionRev",
      "aria-expanded": "true",
      "data-target": "#accordionRev",
      "data-toggle": "collapse"
    }
  }, [_vm._v(" Review ")])])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_vm._v(" Primary Department ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_vm._v(" Main Flag ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_vm._v(" Other Flags ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('h5', {
    staticClass: "mb-1"
  }, [_vm._v(" Comment ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header",
    attrs: {
      "id": "heading_help_support"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_c('button', {
    staticClass: "btn"
  }, [_vm._v(" HELP & SUPPORT ")])])])]);
}]

export { render, staticRenderFns }