import { render, staticRenderFns } from "./approval-collapsible.vue?vue&type=template&id=253a4cfc&"
import script from "./approval-collapsible.ts?vue&type=script&lang=ts&"
export * from "./approval-collapsible.ts?vue&type=script&lang=ts&"
import style0 from "./approval-collapsible.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports