var render = function () {
  var _vm$department;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.FULL_ROW,
      expression: "formLayout.FULL_ROW"
    }],
    attrs: {
      "is-disabled": true,
      "label": _vm.label
    }
  }, [_c('pui-form-input-field', {
    attrs: {
      "is-disabled": true,
      "value": (_vm$department = _vm.department) === null || _vm$department === void 0 ? void 0 : _vm$department.name,
      "type": "text"
    },
    on: {
      "value:update": _vm.checkIfDepartmentIsSet
    }
  }), _c('pui-form-type-ahead', {
    attrs: {
      "is-disabled": !_vm.isDepartmentSet,
      "is-loading": _vm.isFormLoading,
      "is-valid": _vm.isUserInputValid,
      "options": _vm.searchResults,
      "use-local-cache": false,
      "label": ""
    },
    on: {
      "abort": _vm.clearSearchResults,
      "blur": _vm.checkEmptyInput,
      "change": _vm.hasSelectedUser,
      "search": _vm.listUsers
    },
    model: {
      value: _vm.userInput,
      callback: function ($$v) {
        _vm.userInput = $$v;
      },
      expression: "userInput"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }