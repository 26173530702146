var render = function () {
  var _vm$estimatedPrioriti, _vm$estimatedPrioriti2, _vm$estimatedPrioriti3, _vm$estimatedPrioriti4, _vm$estimatedPrioriti6;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "estimation"
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "can-cancel": true
    },
    on: {
      "update:active": function ($event) {
        _vm.loading = $event;
      }
    }
  }), (_vm$estimatedPrioriti = _vm.estimatedPrioritization) !== null && _vm$estimatedPrioriti !== void 0 && _vm$estimatedPrioriti.hasData ? [_c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.HALF_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "estimation__headline",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Run by ")]), _c('pui-headline', {
    staticClass: "estimation__headline",
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s((_vm$estimatedPrioriti2 = (_vm$estimatedPrioriti3 = _vm.estimatedPrioritization) === null || _vm$estimatedPrioriti3 === void 0 ? void 0 : _vm$estimatedPrioriti3.draftPrioritizationRanBy) !== null && _vm$estimatedPrioriti2 !== void 0 ? _vm$estimatedPrioriti2 : "-") + " ")])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.pageLayout.HALF_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "estimation__headline",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Date ")]), _c('pui-headline', {
    staticClass: "estimation__headline",
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDate) + " ")])], 1)], 1), _c('pui-tabs', {
    staticClass: "estimation__tabs",
    attrs: {
      "selected": 0
    }
  }, _vm._l((_vm$estimatedPrioriti4 = _vm.estimatedPrioritization) === null || _vm$estimatedPrioriti4 === void 0 ? void 0 : _vm$estimatedPrioriti4.budgets, function (budget) {
    var _vm$estimatedPrioriti5, _vm$data$constants$pr, _vm$data, _vm$data$constants, _vm$data$constants$pr2, _budget$prioritizatio, _vm$data$constants$pr3, _vm$data2, _vm$data2$constants, _vm$data2$constants$p, _budget$oldPrioritiza;

    return _c('pui-tab', {
      key: budget.globalId,
      attrs: {
        "title": budget.globalId
      }
    }, [((_vm$estimatedPrioriti5 = _vm.estimatedPrioritization) === null || _vm$estimatedPrioriti5 === void 0 ? void 0 : _vm$estimatedPrioriti5.budgets.length) === 1 ? _c('pui-headline', {
      staticClass: "estimation__tabs__headline",
      attrs: {
        "type": "h5"
      }
    }, [_vm._v(" " + _vm._s(budget.globalId) + " ")]) : _vm._e(), _c('div', {
      staticClass: "estimation__tabs__content"
    }, [_c('pui-grid-row', [_c('pui-grid-column', {
      attrs: {
        "cols": _vm.pageLayout.HALF_ROW
      }
    }, [_c('pui-headline', {
      staticClass: "estimation__headline",
      attrs: {
        "type": "h6"
      }
    }, [_vm._v(" Category ")]), _c('pui-headline', {
      staticClass: "estimation__headline",
      attrs: {
        "type": "h5"
      }
    }, [_vm._v(" " + _vm._s((_vm$data$constants$pr = (_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : (_vm$data$constants = _vm$data.constants) === null || _vm$data$constants === void 0 ? void 0 : (_vm$data$constants$pr2 = _vm$data$constants.prioritizationCategory) === null || _vm$data$constants$pr2 === void 0 ? void 0 : _vm$data$constants$pr2[budget === null || budget === void 0 ? void 0 : budget.prioritizationCategory]) !== null && _vm$data$constants$pr !== void 0 ? _vm$data$constants$pr : "-") + " ")])], 1), _c('pui-grid-column', {
      attrs: {
        "cols": _vm.pageLayout.HALF_ROW
      }
    }, [_c('pui-headline', {
      staticClass: "estimation__headline",
      attrs: {
        "type": "h6"
      }
    }, [_vm._v(" Rank ")]), _c('pui-headline', {
      staticClass: "estimation__headline",
      attrs: {
        "type": "h5"
      }
    }, [_vm._v(" " + _vm._s((_budget$prioritizatio = budget === null || budget === void 0 ? void 0 : budget.prioritizationRank) !== null && _budget$prioritizatio !== void 0 ? _budget$prioritizatio : "-") + " ")])], 1)], 1), _c('pui-grid-row', [_c('pui-grid-column', {
      attrs: {
        "cols": _vm.pageLayout.HALF_ROW
      }
    }, [_c('pui-headline', {
      staticClass: "estimation__headline",
      attrs: {
        "type": "h6"
      }
    }, [_vm._v(" Old Category ")]), _c('pui-headline', {
      staticClass: "estimation__headline",
      attrs: {
        "type": "h5"
      }
    }, [_vm._v(" " + _vm._s((_vm$data$constants$pr3 = (_vm$data2 = _vm.data) === null || _vm$data2 === void 0 ? void 0 : (_vm$data2$constants = _vm$data2.constants) === null || _vm$data2$constants === void 0 ? void 0 : (_vm$data2$constants$p = _vm$data2$constants.prioritizationCategory) === null || _vm$data2$constants$p === void 0 ? void 0 : _vm$data2$constants$p[budget === null || budget === void 0 ? void 0 : budget.oldPrioritizationCategory]) !== null && _vm$data$constants$pr3 !== void 0 ? _vm$data$constants$pr3 : "-") + " ")])], 1), _c('pui-grid-column', {
      attrs: {
        "cols": _vm.pageLayout.HALF_ROW
      }
    }, [_c('pui-headline', {
      staticClass: "estimation__headline",
      attrs: {
        "type": "h6"
      }
    }, [_vm._v(" Old Rank ")]), _c('pui-headline', {
      staticClass: "estimation__headline",
      attrs: {
        "type": "h5"
      }
    }, [_vm._v(" " + _vm._s((_budget$oldPrioritiza = budget === null || budget === void 0 ? void 0 : budget.oldPrioritizationRank) !== null && _budget$oldPrioritiza !== void 0 ? _budget$oldPrioritiza : "-") + " ")])], 1)], 1)], 1)], 1);
  }), 1)] : [_c('pui-loader-error', {
    staticClass: "estimation__empty-estimation-error",
    attrs: {
      "title": "",
      "message": "No estimation run",
      "icon": "error-empty-data"
    }
  })], _c('div', {
    staticClass: "estimation__action-button"
  }, [_c('pui-button', {
    attrs: {
      "disabled": _vm.loading
    },
    on: {
      "click": _vm.onTriggerRunEstimation
    }
  }, [_vm._v(" " + _vm._s((_vm$estimatedPrioriti6 = _vm.estimatedPrioritization) !== null && _vm$estimatedPrioriti6 !== void 0 && _vm$estimatedPrioriti6.hasData ? "Rerun Estimation" : "Run Estimation") + " ")])], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }