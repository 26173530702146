var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "delete-lightbox-wrapper"
  }, [_c('pui-lightbox', {
    ref: _vm.REF_CONSTANTS.LIGHTBOX,
    attrs: {
      "default-header-label": "Confirm Delete",
      "default-footer-confirm-label": "Delete",
      "default-footer-cancel-label": "Cancel",
      "default-footer-confirm-disabled": _vm.loading,
      "on-confirm-callback": _vm.triggerDelete,
      "close-on-e-s-c": false,
      "fit-content": ""
    },
    on: {
      "puilightbox:close": _vm.emitClose
    }
  }, [_c('div', {
    staticClass: "delete-lightbox-wrapper__content"
  }, [_vm._v(" " + _vm._s(_vm.message) + " ")])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }