var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('pui-grid-container', {
    staticClass: "bg-white elements-center"
  }, [_c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.PAGE_LAYOUT.FULL_ROW
    }
  }, [_c('pui-icon', {
    staticClass: "iybr-form-confirmation-icon",
    attrs: {
      "icon-name": "check-solid",
      "size": "250"
    }
  })], 1), _c('pui-grid-column', {
    staticClass: "iybr-form-confirmation-p-column",
    attrs: {
      "cols": _vm.PAGE_LAYOUT.FULL_ROW
    }
  }, [_c('p', {
    staticClass: "confirm-message"
  }, [_vm._v(" Thank you! Your request was succesfully submmited. To view the status of your request use the Unique ID bellow. ")])]), _c('pui-grid-column', [_c('p', {
    staticClass: "confirm-unique-id"
  }, [_vm._v(" Unique ID: ")]), _c('router-link', {
    staticClass: "unique-id",
    attrs: {
      "to": _vm.viewChangeRequestRouteConfig
    }
  }, [_vm._v(" " + _vm._s(_vm.uniqueId) + " ")])], 1), _c('pui-grid-column', {
    staticClass: "iybr-overview-btn",
    attrs: {
      "cols": _vm.PAGE_LAYOUT.FULL_ROW
    }
  }, [_c('pui-button', {
    attrs: {
      "state": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.handleOverviewButtonClick();
      }
    }
  }, [_vm._v(" Go to overview ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }