var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('pui-collapsible', {
    attrs: {
      "configurable": true,
      "explandale": true,
      "headline": "FILES"
    }
  }, [!_vm.isViewPage ? _c('div', {
    attrs: {
      "slot": "collapsibleConfigurationContent"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
      }
    },
    slot: "collapsibleConfigurationContent"
  }, [_c('pui-form-file', {
    ref: _vm.ADD_FILE_BUTTON_REF,
    staticClass: "add-file-button",
    attrs: {
      "label": "Add file"
    },
    on: {
      "puiFormFile:added": _vm.onAddFilesButtonClick
    }
  })], 1) : _vm._e(), _vm.isUploading ? _c('pui-loader-spinner', {
    attrs: {
      "title": "File is uploading..."
    }
  }) : _vm.isDeleting ? _c('pui-loader-spinner', {
    attrs: {
      "title": "File is being deleted..."
    }
  }) : _c('div', _vm._l(_vm.changeRequestFiles, function (changeRequestFile) {
    return _c('file-card-display', {
      key: changeRequestFile.created,
      staticClass: "file-card",
      attrs: {
        "change-request-file": changeRequestFile,
        "is-edit-page": _vm.isEditPage,
        "is-view-page": _vm.isViewPage
      },
      on: {
        "delete-file": _vm.deleteFile,
        "delete-local-file": _vm.deleteFileLocal,
        "download-file": _vm.downloadFile
      }
    });
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }