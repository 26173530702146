import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { MasterService } from '~/services/master-service';
import './view-history.scss';
import { convertServerDateToBrowserDateAndTime } from '~/utils/date-helper';
import TableAttachmentComponent from '~/components/shared/table/table-attachment/table-attachment.vue';
import TablePaginationComponent from '~/components/shared/table/table-pagination/table-pagination.vue';
import { TablePaginationDefaults } from '~/components/shared/table/table-pagination/table-pagination';
import { TABLE_LOADER_CONSTANT_SIZES } from '~/components/in-year-budget-requests/constants/table-loader-sizes';
import { PuiTableVendorOptions } from '~/models/libraries/pebble-ui';
import { GetMiniFdHistoryEntry, SearchMiniFdHistoryPayload } from '~/models/services/minifd';

@Component({
    components: {
        tablePagination: TablePaginationComponent,
        tableAttachment: TableAttachmentComponent
    }
})

export default class ViewHistoryComponent extends Vue {
    @Prop()
    changeRequestId: string;
    $refs!: {
        tablePagination?: TablePaginationComponent
    };
    private readonly CONSTANT_SIZES = TABLE_LOADER_CONSTANT_SIZES;
    private readonly miniFdService = MasterService.instance.miniFdService;
    private tableEntryRows = [];
    private totalCount = 0;
    private pageNumber = TablePaginationDefaults.INITIAL_PAGE_NUMBER;
    private itemsPerPage = TablePaginationDefaults.INITIAL_PAGE_SIZE;
    private isLoadingNewInformation = false;
    private showNoRecordsFoundCard = false;

    private get columns(): Record<string, string> {
        return {
            dateTime: 'DATE & TIME',
            userKID: 'USER(KID)',
            type: 'TYPE',
            description: 'DESCRIPTION'
        };
    }

    private get paginationFilters(): SearchMiniFdHistoryPayload {
        return {
            pageSize: this.itemsPerPage,
            page: this.pageNumber,
            miniFdId: this.changeRequestId
        };
    }

    private get shouldShowTable(): boolean {
        return !this.isLoadingNewInformation && !this.showNoRecordsFoundCard;
    }

    private get maxPageSize(): number {
        return Math.max(...TablePaginationDefaults.PAGE_SIZE_SELECTION_OPTIONS);
    }

    private get tableOptions(): PuiTableVendorOptions {
        return {
            data: this.tableEntryRows,
            options: {
                sortable: [],
                perPage: this.maxPageSize
            },
            columns: Object.values(this.columns)
        };
    }

    async mounted(): Promise<void> {
        await this.getMiniFdHistory();
    }

    public handlePageNumberChange(pageNumber: number): void {
        this.pageNumber = pageNumber;
    }

    public handlePageSizeChange(size: number): void {
        this.itemsPerPage = size;
    }

    @Watch('paginationFilters')
    private async onPaginationFiltersChange(): Promise<void> {
        await this.getMiniFdHistory();
    }

    private async getMiniFdHistory(): Promise<void> {
        let items: GetMiniFdHistoryEntry[];

        this.isLoadingNewInformation = true;
        try {
            const response = await this.miniFdService.getHistory(this.paginationFilters);
            this.showNoRecordsFoundCard = false;

            items = response.activityTrackings;
            this.totalCount = response.total;
        } catch (e) {
            if (e.response?.status === 404) {
                this.showNoRecordsFoundCard = true;
            } else {
                this.$bvToast.show('toast-data-error');
            }

            items = [];
            this.totalCount = 0;
        }
        this.isLoadingNewInformation = false;
        this.tableEntryRows = this.mapTableData(items);
    }

    private mapTableData(miniFdHistoryEntries: GetMiniFdHistoryEntry[]): Record<string, string>[] {
        const data = [];
        miniFdHistoryEntries.forEach((miniFdHistoryEntry) => {
            data.push({
                [this.columns['dateTime']]: convertServerDateToBrowserDateAndTime(miniFdHistoryEntry.date),
                [this.columns['userKID']]: miniFdHistoryEntry.kId,
                [this.columns['type']]: miniFdHistoryEntry.activityType,
                [this.columns['description']]: miniFdHistoryEntry.description
            });
        });
        return data;
    }

}
