import Vue from 'vue';
import Component from 'vue-class-component';


@Component({})
export default class FirstMoveBulkModalComponent extends Vue {

    private emitSecondModal() {
        $('#firstMoveBulkModal').modal('hide');
        $('#secondMoveBulkModal').modal('show');
    }
}
