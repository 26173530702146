var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "edit-iybr-wrapper"
  }, [_c('pui-lightbox', {
    ref: _vm.SUBMIT_EDIT_LOADING_MODAL,
    attrs: {
      "fullscreen": true
    }
  }, [_c('p', {
    attrs: {
      "slot": "default"
    },
    slot: "default"
  }, [_c('pui-loader-spinner', {
    attrs: {
      "message": "Changes are being saved.",
      "min-height": "500px",
      "title": "Please wait!"
    }
  })], 1)]), _c('pui-grid-container', [_c('pui-grid-row', [_c('pui-grid-column', [_c('pui-breadcrumb', {
    attrs: {
      "links": _vm.breadcrumbLinks
    },
    on: {
      "changed:active-route": _vm.onBreadcrumbLinkClick
    }
  })], 1)], 1), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.formLayout.TWO_THIRDS_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "iybr-headline",
    attrs: {
      "type": "h3"
    }
  }, [_vm._v(" Edit Change Request ")])], 1)], 1), _c('create-iybr-form', {
    ref: "form",
    attrs: {
      "edit-mode": true
    },
    on: {
      "currently-submitting-edit": _vm.openLoadingScreen,
      "error-submitting": _vm.handleSubmitError,
      "iybr-form-submitted": _vm.onFormSubmit,
      "update:project-name": _vm.onProjectNameUpdated
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }