var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "linked-budget-request-view-content"
  }, [_c('div', {
    staticClass: "linked-budget-request-view-content__breadcrumb"
  }, [_c('pui-breadcrumb', {
    attrs: {
      "links": _vm.breadcrumbLinks
    },
    on: {
      "changed:active-route": _vm.onActiveRouteChanged
    }
  })], 1), _vm.initialError.show || _vm.loading ? [_vm.loading ? _c('pui-loader-spinner', {
    attrs: {
      "title": "Loading Combined Budget..."
    }
  }) : _c('pui-loader-error', {
    staticClass: "linked-budget-request-view-content__empty-error",
    attrs: {
      "title": "",
      "message": _vm.initialError.title,
      "icon": "error-empty-data"
    }
  })] : [_c('div', {
    staticClass: "linked-budget-request-view-content__header"
  }, [_c('div', {
    staticClass: "linked-budget-request-view-content__header__title"
  }, [_c('pui-headline', {
    attrs: {
      "type": "h3"
    }
  }, [_vm._v(" Combined Budget Request Details ")])], 1), _c('div', {
    staticClass: "linked-budget-request-view-content__header__actions"
  }, [_c('pui-button', {
    staticClass: "linked-budget-request-view-content__header__actions__delete",
    attrs: {
      "state": "secondary",
      "disabled": _vm.loading || _vm.isButtonDisabled
    },
    on: {
      "click": _vm.handleOpenDeleteLightbox
    }
  }, [_vm._v(" Delete ")]), _c('pui-button', {
    attrs: {
      "state": "secondary",
      "disabled": _vm.loading || _vm.isButtonDisabled
    },
    on: {
      "click": _vm.sendToEdit
    }
  }, [_vm._v(" Edit ")])], 1)]), _c('pui-collapsible', {
    attrs: {
      "headline": "Details",
      "expandable": false,
      "open": true
    }
  }, [_c('div', {
    staticClass: "linked-budget-request-view-content__container"
  }, [_c('pui-grid-container', [_c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.FULL_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Global IDs"
    }
  })], 1)], 1), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.FULL_ROW
    }
  }, [_vm.combinedBudget.budgetIds.length > 0 ? _vm._l(_vm.combinedBudget.budgetIds, function (budgetId) {
    return _c('pui-chip', {
      key: budgetId.id,
      staticClass: "linked-budget-request-view-content__chip-list",
      attrs: {
        "label": budgetId.globalId
      }
    });
  }) : [_c('p', [_vm._v("-")])]], 2)], 1), _c('hr', {
    staticClass: "linked-budget-request-view-content__separator"
  }), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.QUARTER_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Year",
      "text": _vm.year.toString()
    }
  })], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.QUARTER_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "MTP Status",
      "text": _vm.data.constants.mtpStatus[_vm.combinedBudget.status].name
    }
  })], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.QUARTER_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Funding Status",
      "text": _vm.data.constants.fundingStatus[_vm.combinedBudget.fundingStatus]
    }
  })], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.QUARTER_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Poject Status",
      "text": _vm.data.constants.projectStatusType[_vm.combinedBudget.projectStatus]
    }
  })], 1)], 1), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.QUARTER_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Country",
      "text": _vm.combinedBudget.countryName
    }
  })], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.QUARTER_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Technology",
      "text": _vm.combinedBudget.technologyName
    }
  })], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.QUARTER_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Plant / River Group",
      "text": _vm.combinedBudget.plantGroupName
    }
  })], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.QUARTER_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Plant",
      "text": _vm.combinedBudget.plantName
    }
  })], 1)], 1), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.QUARTER_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Country Sid",
      "text": _vm.combinedBudget.countrySid.toString()
    }
  })], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.QUARTER_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Technology Sid",
      "text": _vm.combinedBudget.technologySid.toString()
    }
  })], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.QUARTER_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Unit Sid",
      "text": _vm.combinedBudget.plantGroupSid.toString()
    }
  })], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.QUARTER_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Plant Sid",
      "text": _vm.combinedBudget.plantSid.toString()
    }
  })], 1)], 1), _c('hr', {
    staticClass: "linked-budget-request-view-content__separator"
  }), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_THIRD_ROW
    }
  })], 1), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.FULL_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Risk / Maintenance ID"
    }
  })], 1)], 1), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.FULL_ROW
    }
  }, [_vm.combinedBudget.externalIds.length > 0 ? _vm._l(_vm.combinedBudget.externalIds, function (externalId) {
    return _c('pui-chip', {
      key: externalId,
      staticClass: "linked-budget-request-view-content__chip-list",
      attrs: {
        "label": externalId
      }
    });
  }) : [_c('p', [_vm._v("-")])]], 2)], 1), _c('hr', {
    staticClass: "linked-budget-request-view-content__separator"
  }), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.HALF_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Name",
      "text": _vm.combinedBudget.name
    }
  })], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.HALF_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Name (Local Language)",
      "text": _vm.combinedBudget.localLanguageName
    }
  })], 1)], 1), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.HALF_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Project Type",
      "text": _vm.data.constants.projectTypes[_vm.combinedBudget.projectType]
    }
  })], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.HALF_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Lump Sum",
      "text": _vm.combinedBudget.lumpSum ? 'Yes' : 'No'
    }
  })], 1)], 1), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.HALF_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Start Date",
      "text": _vm.formatDate(_vm.combinedBudget.startDate)
    }
  })], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.HALF_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "End Date",
      "text": _vm.formatDate(_vm.combinedBudget.endDate)
    }
  })], 1)], 1), _c('hr', {
    staticClass: "linked-budget-request-view-content__separator"
  }), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.FULL_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Aero Classification",
      "text": _vm.data.constants.aeroClassification[_vm.combinedBudget.aeroClassification]
    }
  })], 1)], 1), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_THIRD_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Justification Classification",
      "text": _vm.combinedBudget.justificationOnClassification
    }
  })], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_THIRD_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Primary Department",
      "text": _vm.data.constants.primaryDepartment[_vm.combinedBudget.primaryDepartment]
    }
  })], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_THIRD_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Maintenance Type",
      "text": _vm.data.constants.maintenanceType[_vm.combinedBudget.maintenanceType]
    }
  })], 1)], 1), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_THIRD_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Outage Type",
      "text": _vm.data.constants.outageType[_vm.combinedBudget.outageType]
    }
  })], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_THIRD_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Outage ID",
      "text": _vm.combinedBudget.outageIds.join(', ')
    }
  })], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_THIRD_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Procurement Category",
      "text": _vm.data.constants.procurementCategory[_vm.combinedBudget.procurementCategory]
    }
  })], 1)], 1), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.FULL_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Comment",
      "text": _vm.combinedBudget.comment
    }
  })], 1)], 1), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_THIRD_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Main Flag",
      "text": _vm.data.constants.flags[_vm.combinedBudget.mainFlag]
    }
  })], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.TWO_THIRDS_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Other Flags",
      "text": _vm.combinedBudget.otherFlags.map(function (flag) {
        return _vm.data.constants.flags[flag];
      }).join(', ')
    }
  })], 1)], 1)], 1)], 1)]), _c('pui-collapsible', {
    attrs: {
      "headline": "Finance & Planning",
      "expandable": false,
      "open": true
    }
  }, [_c('div', {
    staticClass: "linked-budget-request-view-content__container"
  }, [_c('pui-grid-container', [_c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.HALF_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "WBS (PSP) Element",
      "text": _vm.combinedBudget.wbsElement
    }
  })], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.HALF_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Planning and Controlling Classification",
      "text": _vm.data.constants.newPlanningAndControllingClassification[_vm.combinedBudget.planningAndControllingClassification]
    }
  })], 1)], 1), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.HALF_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Uniper Share of Budget",
      "text": ((_vm.combinedBudget.uniperShareOfBudget.toString()) + "%")
    }
  })], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.HALF_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Useful Economic Life",
      "text": _vm.combinedBudget.usefulEconomicLife.toString()
    }
  })], 1)], 1), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.HALF_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Has Planning Costs",
      "text": _vm.combinedBudget.hasPlanningCosts ? 'Yes' : 'No'
    }
  })], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.HALF_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Currency",
      "text": _vm.combinedBudget.currencyCode
    }
  })], 1)], 1), _c('hr', {
    staticClass: "linked-budget-request-view-content__separator"
  }), _c('div', {
    staticClass: "linked-budget-request-view-content__headline"
  }, [_c('pui-headline', {
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Planning ")])], 1), _c('div', {
    staticClass: "linked-budget-request-view-content__scrollable-row"
  }, [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_SIXTH_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": ("Spend until end of " + (_vm.year)),
      "text": _vm.combinedBudget.spendUntilEndOfYear.toString()
    }
  })], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_SIXTH_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": ("Forecast MTP " + (_vm.year)),
      "text": _vm.combinedBudget.forecast.toString()
    }
  })], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_SIXTH_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": ("New MTP " + (_vm.year + 1)),
      "text": _vm.combinedBudget.new1.toString()
    }
  })], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_SIXTH_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": ("New MTP " + (_vm.year + 2)),
      "text": _vm.combinedBudget.new2.toString()
    }
  })], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_SIXTH_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": ("New MTP " + (_vm.year + 3)),
      "text": _vm.combinedBudget.new3.toString()
    }
  })], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_SIXTH_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": ("After " + (_vm.year + 3)),
      "text": _vm.combinedBudget.after.toString()
    }
  })], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_SIXTH_ROW
    }
  }, [_c('pui-card-label-text', {
    attrs: {
      "label": "Total",
      "text": _vm.combinedBudget.total.toString()
    }
  })], 1)], 1)], 1)], 1)]), _c('delete-confirmation', {
    attrs: {
      "loading": _vm.isDeleteInProgress,
      "lightbox-open": _vm.isDeleteLightboxOpen
    },
    on: {
      "close-lightbox": _vm.handleCloseDeleteLightbox,
      "trigger-delete": _vm.handleDelete
    }
  })]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }