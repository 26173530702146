var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "action-buttons"
  }, [_c('pui-lightbox', {
    ref: _vm.REF_CONSTANTS.SUBMIT_LOADING_MODAL,
    attrs: {
      "fullscreen": true
    }
  }, [_c('p', {
    attrs: {
      "slot": "default"
    },
    slot: "default"
  }, [_c('pui-loader-spinner', {
    attrs: {
      "message": "Change request is being submitted.",
      "min-height": "500px",
      "title": "Please wait!"
    }
  })], 1)]), _c('confirm-iybr-delete-modal', {
    ref: _vm.REF_CONSTANTS.CONFIRM_IYBR_DELETE_MODAL,
    attrs: {
      "is-active": _vm.isMiniFdActive,
      "mini-fd-id": _vm.iybrData.id
    }
  }), _c('edit-approval-modal', {
    ref: _vm.REF_CONSTANTS.EDIT_APPROVAL_MODAL,
    attrs: {
      "iybr-data": _vm.iybrData
    },
    on: {
      "update:approvers": _vm.emitReloadData
    }
  }), _c('edit-budget-compensation-modal', {
    ref: _vm.REF_CONSTANTS.EDIT_BUDGET_COMPENSATION_MODAL,
    attrs: {
      "iybr-data": _vm.iybrData,
      "plant-data": _vm.plantData
    },
    on: {
      "update:budget-compensation": _vm.emitReloadData
    }
  }), _vm.contextMenuActionButtons.length ? _c('pui-context-menu', [_vm._l(_vm.contextMenuActionButtons, function (button) {
    return _c('pui-context-menu-item', {
      key: button.text,
      nativeOn: {
        "click": function ($event) {
          return button.onClick.apply(null, arguments);
        }
      }
    }, [_c('div', {
      staticClass: "view-iybr-dropdown-item"
    }, [_c('pui-icon', {
      attrs: {
        "icon-name": button.iconName
      }
    }), _c('pui-headline', {
      attrs: {
        "type": "h6"
      }
    }, [_vm._v(" " + _vm._s(button.text) + " ")])], 1)]);
  }), _c('div', {
    staticClass: "view-iybr-dropdown-item view-iybr-dropdown-item--trigger",
    attrs: {
      "slot": "context-menu-trigger"
    },
    slot: "context-menu-trigger"
  }, [_c('pui-icon', {
    attrs: {
      "icon-name": "small-down",
      "icon-color": "black"
    }
  }), _c('pui-headline', {
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" More actions ")])], 1)], 2) : _vm._e(), _vm._l(_vm.largeActionButtons, function (button) {
    return [_c('pui-button', {
      key: button.text,
      attrs: {
        "small": "",
        "icon": button.iconName
      },
      on: {
        "click": button.onClick
      }
    }, [_vm._v(" " + _vm._s(button.text) + " ")])];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }