import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import 'vue-awesome/icons/chevron-down';
import { Period, PeriodTypes } from '~/utils/interfaces';
import { Helper } from '~/utils';
import moment from 'moment-timezone';

@Component({})

export default class ViewPeriodsComponent extends Vue {
    private currentYear = new Date(Date.now()).getFullYear();

    @Prop()
    private periodType: PeriodTypes;

    @Prop()
    private hasPermissionToEdit: boolean;

    @Prop()
    private loading: boolean;

    @Prop()
    private periods: Period[];

    private get periodTypeConfig() {
        return {
            [PeriodTypes.MTP]: {
                tableName: 'MTP Periods',
                tableHeaderYear: 'MTP Year',
                tableHeaderStartDate: 'MTP Start Date',
                tableHeaderEndDate: 'MTP End Date',
                noPeriodsFound: 'No MTP periods found.',
                startDateProperty: 'mtpStartDate',
                endDateProperty: 'mtpEndDate',
                editProperty: 'year',
                hasEditValidation: true,
            },
            [PeriodTypes.CR]: {
                tableName: 'Change Requests Periods',
                tableHeaderYear: 'MTP Year',
                tableHeaderStartDate: 'Change Request Start Date',
                tableHeaderEndDate: 'Change Request End Date',
                noPeriodsFound: 'No CR periods found.',
                startDateProperty: 'startDate',
                endDateProperty: 'endDate',
                editProperty: 'id',
                hasEditValidation: false,
            }
        };
    }

    private get periodConfig() {
        return this.periodTypeConfig[this.periodType];
    }

    @Watch('periods')
    async onPeriodsChanged() {
        if (this.periods !== null) {
            this.formatDates();
        }
    }

    private formatDates() {
        this.periods.forEach((a) => {
            a.startDate = moment(Helper.UtcToLocalTime(a[this.getPeriodConfigPropertyValue('startDateProperty')])).format('DD/MM/YYYY');
            a.endDate = moment(Helper.UtcToLocalTime(a[this.getPeriodConfigPropertyValue('endDateProperty')])).format('DD/MM/YYYY');
        });
    }

    private isEditEnabledForYear(year: number): boolean {
        return this.getPeriodConfigPropertyValue('hasEditValidation') ? year >= this.currentYear : true;
    }

    private async editPeriod(editId: string | number, year: number) {
        if (!this.isEditEnabledForYear(year)) {
            return;
        }
        this.$emit('editIdChanged', editId);
    }

    private getPeriodConfigPropertyValue(property: string): any {
        return this.periodConfig && this.periodConfig[property] ? this.periodConfig[property] : '';
    }
}
