var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "maintenance-budget-request"
  }, [_c('div', {
    staticClass: "accordion-connection"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header"
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_c('button', {
    staticClass: "btn"
  }, [_vm._v(" " + _vm._s(_vm.maintenance.id) + " ")])])])]), _c('div', {
    staticClass: "budget-request-wrapper"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-row"
  }, [_c('p', {
    staticClass: "col-xl-12 mb-2"
  }, [_c('b', [_vm._v("Maintenance Name: ")]), _vm._v(" " + _vm._s(_vm.maintenance.data.maintenanceName !== null && _vm.maintenance.data.maintenanceName !== "" ? _vm.maintenance.data.maintenanceName : "-") + " ")])]), _c('div', {
    staticClass: "form-row"
  }, [_c('p', {
    staticClass: "col-xl-12 mb-2"
  }, [_c('b', [_vm._v("Maintenance Name (local language): ")]), _vm._v(" " + _vm._s(_vm.maintenance.data.maintenanceLocalLanguageName !== null && _vm.maintenance.data.maintenanceLocalLanguageName !== "" ? _vm.maintenance.data.maintenanceLocalLanguageName : "-") + " ")])]), _c('div', {
    staticClass: "form-row"
  }, [_c('p', {
    staticClass: "col-xl-12 mb-2"
  }, [_c('b', [_vm._v("Maintenance Activity: ")]), _vm._v(" " + _vm._s(_vm.maintenance.data.maintenanceActivity !== null && _vm.maintenance.data.maintenanceActivity !== "" ? _vm.maintenance.data.maintenanceActivity : "-") + " ")])]), _c('div', {
    staticClass: "form-row"
  }, [_c('p', {
    staticClass: "col-xl-12 mb-2"
  }, [_c('b', [_vm._v("Planning & Controlling Classification: ")]), _vm._v(" " + _vm._s(_vm.maintenance.data.planningAndControllingClassification !== null && _vm.maintenance.data.planningAndControllingClassification !== "" ? _vm.maintenance.data.planningAndControllingClassification : "-") + " ")])]), _c('div', {
    staticClass: "form-row"
  }, [_c('p', {
    staticClass: "col-xl-6 mb-2"
  }, [_c('b', [_vm._v("Category: ")]), _vm._v(" " + _vm._s(_vm.maintenance.data.category !== null && _vm.maintenance.data.category !== "" ? _vm.maintenance.data.category : "-") + " ")]), _c('p', {
    staticClass: "col-xl-6 mb-2"
  }, [_c('b', [_vm._v("Justification: ")]), _vm._v(" " + _vm._s(_vm.maintenance.data.justification !== null && _vm.maintenance.data.justification !== "" ? _vm.maintenance.data.justification : "-") + " ")])]), _c('div', {
    staticClass: "form-row"
  }, [_c('p', {
    staticClass: "col-xl-6 mb-2"
  }, [_c('b', [_vm._v("Maintenance Type: ")]), _vm._v(" " + _vm._s(_vm.maintenance.data.maintenanceType !== null && _vm.maintenance.data.maintenanceType !== "" ? _vm.maintenance.data.maintenanceType : "-") + " ")]), _c('p', {
    staticClass: "col-xl-6 mb-2"
  }, [_c('b', [_vm._v("Outage Type: ")]), _vm._v(" " + _vm._s(_vm.maintenance.data.outageType !== null && _vm.maintenance.data.outageType !== "" ? _vm.maintenance.data.outageType : "-") + " ")])]), _c('div', {
    staticClass: "form-row"
  }, [_c('p', {
    staticClass: "col-xl-6 mb-2"
  }, [_c('b', [_vm._v("Project Type: ")]), _vm._v(" " + _vm._s(_vm.maintenance.data.projectType !== null && _vm.maintenance.data.projectType !== "" ? _vm.maintenance.data.projectType : "-") + " ")]), _c('p', {
    staticClass: "col-xl-6 mb-2"
  }, [_c('b', [_vm._v("Lump Sum: ")]), _vm._v(" " + _vm._s(_vm.maintenance.data.lumpSum !== null && _vm.maintenance.data.lumpSum !== "" ? _vm.maintenance.data.lumpSum : "-") + " ")])]), _c('div', {
    staticClass: "form-row"
  }, [_c('p', {
    staticClass: "col-xl-6 mb-2"
  }, [_c('b', [_vm._v("Next planned start date: ")]), _vm._v(" " + _vm._s(_vm.maintenance.data.nextPlannedStartDate !== null ? _vm.maintenance.data.nextPlannedStartDate : "-") + " ")]), _c('p', {
    staticClass: "col-xl-6 mb-2"
  }, [_c('b', [_vm._v("Duration: ")]), _vm._v(" " + _vm._s(_vm.maintenance.data.nextPlannedStartDateDuration !== null ? _vm.maintenance.data.nextPlannedStartDateDuration + " days" : "-") + " ")])]), _c('div', {
    staticClass: "form-row"
  }, [_c('p', {
    staticClass: "col-xl-6 mb-2"
  }, [_c('b', [_vm._v("Last maintenace date: ")]), _vm._v(" " + _vm._s(_vm.maintenance.data.lastMaintenanceDate !== null ? _vm.maintenance.data.lastMaintenanceDate : "-") + " ")]), _c('p', {
    staticClass: "col-xl-6 mb-2"
  }, [_c('b', [_vm._v("Duration: ")]), _vm._v(" " + _vm._s(_vm.maintenance.data.lastMaintenanceDateDuration !== null ? _vm.maintenance.data.lastMaintenanceDateDuration + " days" : "-") + " ")])]), _vm.maintenance.data.uEL ? _c('div', {
    staticClass: "form-row"
  }, [_c('p', {
    staticClass: "col-xl-12 mb-2"
  }, [_c('strong', [_vm._v("Useful Economic Life:")]), _vm._v(" " + _vm._s(_vm.maintenance.data.uEL) + " year(s) ")])]) : _vm._e(), _vm._m(0), _c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "row border-row"
  }, [_vm.maintenance.data.maintenancePlanning && _vm.maintenance.data.maintenancePlanning.length > 0 ? _c('table', {
    staticClass: "table table-connect"
  }, [_c('thead', [_c('tr', [_c('th', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(" No ")]), _c('th', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(" Date ")]), _vm.isTotal ? _c('th', {
    staticClass: "total",
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(" Total cost "), _c('a', {
    on: {
      "click": function ($event) {
        return _vm.changeTotal();
      }
    }
  }, [_vm._v("Adjacent years")])]) : _vm._e(), !_vm.isTotal ? _c('th', {
    staticClass: "adjacent",
    attrs: {
      "colspan": "4"
    }
  }, [_c('a', {
    on: {
      "click": function ($event) {
        return _vm.changeTotal();
      }
    }
  }, [_vm._v("Show total")])]) : _vm._e(), _c('th', {
    attrs: {
      "rowspan": "2"
    }
  }, [_vm._v(" Outage ID ")]), _c('th', {
    attrs: {
      "rowspan": "2"
    }
  })]), _c('tr', [!_vm.isTotal ? _c('th', {
    staticClass: "adjacent-cost"
  }, [_vm._v(" -2year ")]) : _vm._e(), !_vm.isTotal ? _c('th', {
    staticClass: "adjacent-cost"
  }, [_vm._v(" -1year ")]) : _vm._e(), !_vm.isTotal ? _c('th', {
    staticClass: "adjacent-cost"
  }, [_vm._v(" Current ")]) : _vm._e(), !_vm.isTotal ? _c('th', {
    staticClass: "adjacent-cost"
  }, [_vm._v(" +1year ")]) : _vm._e()])]), _c('tbody', _vm._l(_vm.maintenance.data.maintenancePlanning, function (el, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_vm._v(_vm._s(index + 1))]), _c('td', [_vm._v(_vm._s(el.planningDate !== null ? el.planningDate : "-"))]), _vm.isTotal ? _c('td', [_vm._v(" " + _vm._s(el.totalCost) + _vm._s(_vm.symbolCurrency) + " ")]) : _vm._e(), !_vm.isTotal ? _c('td', [_vm._v(" " + _vm._s(el.costTwoYearsPrior) + _vm._s(_vm.symbolCurrency) + " ")]) : _vm._e(), !_vm.isTotal ? _c('td', [_vm._v(" " + _vm._s(el.costPreviousYear) + _vm._s(_vm.symbolCurrency) + " ")]) : _vm._e(), !_vm.isTotal ? _c('td', [_vm._v(" " + _vm._s(el.costCurrentYear) + _vm._s(_vm.symbolCurrency) + " ")]) : _vm._e(), !_vm.isTotal ? _c('td', [_vm._v(" " + _vm._s(el.costNextYear) + _vm._s(_vm.symbolCurrency) + " ")]) : _vm._e(), _c('td', [_vm._v(_vm._s(el.outageId))]), _c('td', [_c('label', {
      staticClass: "container"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: el.isSelected,
        expression: "el.isSelected"
      }],
      attrs: {
        "disabled": _vm.isDetails || _vm.disableCheckBoxes,
        "type": "checkbox"
      },
      domProps: {
        "checked": Array.isArray(el.isSelected) ? _vm._i(el.isSelected, null) > -1 : el.isSelected
      },
      on: {
        "change": [function ($event) {
          var $$a = el.isSelected,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = null,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && _vm.$set(el, "isSelected", $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(el, "isSelected", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(el, "isSelected", $$c);
          }
        }, function ($event) {
          !_vm.disableCheckBoxes ? _vm.emitCheck(_vm.elementIndex) : '';
        }]
      }
    }), _c('span', {
      staticClass: "checkmark"
    })])])]);
  }), 0)]) : _vm._e(), _vm.maintenance.data.maintenancePlanning === null || _vm.maintenance.data.maintenancePlanning === undefined || _vm.maintenance.data.maintenancePlanning.length === 0 ? _c('div', {
    staticClass: "table pl-3 pb-3"
  }, [_vm._v(" There is no maintenance planning done for this activity. Please go to MSP and review the maintenance activity. ")]) : _vm._e()])]), _vm._m(1), _c('div', {
    staticClass: "text-right"
  }, [_c('button', {
    staticClass: "btn btn-primary mb-4",
    attrs: {
      "disabled": _vm.maintenance.data.dataToBeMappedInPml === null
    },
    on: {
      "click": function ($event) {
        return _vm.emitCopy(_vm.elementIndex);
      }
    }
  }, [_vm._v(" Copy ")])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "form-row"
  }, [_c('p', {
    staticClass: "col-xl-12 mb-2 mt-2"
  }, [_c('b', [_vm._v("Maintenance Planning:")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-left mb-2"
  }, [_c('small', {
    staticClass: "form-text text-muted"
  }, [_c('i', [_vm._v("When you choose to copy, the maintenance details will be copied over from MSP to the budget request form and the Global ID created for this budget request will be linked to the maintenance planning items that you previously selected above. ")])])]);
}]

export { render, staticRenderFns }