var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.hasPermission ? _c('div', {
    staticClass: "linked-budget-request-edit"
  }, [_c('linked-budget-request-form', {
    attrs: {
      "id": _vm.id,
      "combined-data": _vm.combinedData
    }
  })], 1) : _c('div', [_c('div', {
    staticClass: "container-fluid"
  }, [_c('access-denied-modal', {
    on: {
      "clickedcanceleditstate": _vm.onClickCancel
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }