import Vue from 'vue';
import Component from 'vue-class-component';
import { EventBus } from '~/utils/eventbus';

@Component({})
export default class ConfirmationModalComponent extends Vue {
    private selectedSaveChanges: boolean = false;
    private selectedDiscardChanges: boolean = false;

    constructor() {
        super();
    }

    private saveChanges() {
        this.selectedSaveChanges = true;
        EventBus.$emit(EventBus.BUDGET_REQUEST.SAVE_CHANGES_MODAL, this.selectedSaveChanges);
    }

    private discardChanges() {
        this.selectedDiscardChanges = true;
        EventBus.$emit(EventBus.BUDGET_REQUEST.DISCARD_CHANGES_MODAL, this.selectedDiscardChanges);
    }
}
