import Vue from 'vue';
import Component from 'vue-class-component';
import './risk-budget-request.scss';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class RiskBudgetRequestComponent extends Vue {
    private isTotal: boolean = true;

    @Prop()
    private risk: any;
    @Prop()
    private elementIndex: number;

    constructor() {
        super();
    }

    private emitCopy(index: number) {
        this.$emit('copy', index);
    }
}
