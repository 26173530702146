import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { BudgetRequestPlanningStatus, MtpStatus } from '~/utils/interfaces';
import { safeInvertedDifferenceOfDecimal, safeSum, zeroIfNaN } from '~/utils/math-helper';
import { cellValueClass, tableCellTextFormat } from '~/utils/table-helper';
import {
    IybrGlobalIdsCollapsibleTableData,
    ProjectTypes
} from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/iybr-global-ids-collapsible/iybr-global-ids-collapsible';
import { IybrNewProjectCollapsibleFormData } from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/iybr-new-project-collapsible/iybr-new-project-collapsible';
import { checkIfAllArrayItemsAreEqualTo } from '~/utils/array-utils';
import { CompensationTableTotals } from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/iybr-compensation-table/iybr-compensation-table';
import {
    CurrencyRate,
    TABLE_FIELDS,
    TABLE_KEYS
} from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/iybr-new-project-collapsible/create-iybr-form-utils';
import { Data } from '~/utils';
import { PuiTableVendorOptions } from '~/models/libraries/pebble-ui';
import {
    CompensationRequiredTotals
} from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/create-iybr-form-model';

export type SumBudgetRequestData = {
    total: number,
    approved: number,
    pending: number
};

@Component({})
export default class IybrCostTableComponent extends Vue {
    @Prop()
    private currencyName!: string;

    @Prop()
    private globalIdsCollapsibleTableData?: IybrGlobalIdsCollapsibleTableData;

    @Prop()
    private newProjectFormData?: IybrNewProjectCollapsibleFormData;

    @Prop()
    private compensationTotals?: CompensationTableTotals;

    @Prop()
    private requestType?: string;

    @Prop()
    private projectType: string;

    @Prop({ default: () => new Date().getFullYear() })
    private mtpYear?: number;

    private readonly tableInputFields = [
        TABLE_KEYS.spentAfterYear,
        TABLE_KEYS.spentYearPlus1,
        TABLE_KEYS.spentYearPlus2,
        TABLE_KEYS.spentYearPlus3
    ];

    private readonly projectFields = [
        TABLE_KEYS.spentYearMinus1,
        TABLE_KEYS.spentYear,
        TABLE_KEYS.spentAfterYear
    ];

    private readonly totalKeys = [
        ...this.projectFields,
        TABLE_KEYS.spentYearPlus1,
        TABLE_KEYS.spentYearPlus2,
        TABLE_KEYS.spentYearPlus3
    ];

    private readonly ONE_MILLION = 1000000;
    private cellValueClass = cellValueClass;
    private tableCellTextFormat = tableCellTextFormat;

    public get formTotal(): number {
        let totals: number[];

        if (this.requestType !== BudgetRequestPlanningStatus.NEW_PROJECT.toString()) {
            const budgetDataEntries = Object.values(this.globalIdsCollapsibleTableData).map(entry => entry.budgetData);

            totals = this.totalKeys.map((key) => budgetDataEntries.map((entry) => {
                if (this.projectType === ProjectTypes.NonProject && this.projectFields.includes(key)) {
                    return 0;
                }
                return entry[key];
            }).reduce(safeSum, 0));
        } else {
            totals = this.totalKeys.map((key) => {
                if (this.projectType === ProjectTypes.NonProject && this.projectFields.includes(key)) {
                    return 0;
                }
                return this.newProjectFormData[key];
            });
        }

        this.updateConvertedTotalCost();
        return totals.reduce(safeSum, 0);
    }

    private get globalIdCount(): number {
        return this.globalIdsCollapsibleTableData ? Object.values(this.globalIdsCollapsibleTableData).length : 0;
    }

    private get columnNameMap(): Record<string, string> {
        return {
            'MTP-1': 'spendUntilEndOfYear',
            MTP: 'forecast',
            previousYears: 'forecast',
            'MTP+1': 'new1',
            'MTP+2': 'new2',
            'MTP+3': 'new3',
            ff: 'after',
            total: 'total',
        };
    }

    private get columns(): Record<string, string> {
        return {
            title: `Currency: ${this.currencyName}`,
            'MTP-1': `${this.mtpYear - 1}`,
            MTP: `${this.mtpYear}`,
            'previousYears': `Before ${this.mtpYear + 1}`,
            'MTP+1': `${this.mtpYear + 1}`,
            'MTP+2': `${this.mtpYear + 2}`,
            'MTP+3': `${this.mtpYear + 3}`,
            ff: 'FF.',
            total: 'Total',
        };
    }

    private get shownColumns(): string[] {
        const hiddenColumns = ['MTP-1', 'MTP'];

        if (this.projectType === ProjectTypes.NonProject) {
            hiddenColumns.push('previousYears');
        }

        return Object.keys(this.columns)
            .filter(key => !hiddenColumns.includes(key))
            .map(key => this.columns[key]);
    }

    private get rows(): Record<string, string> {
        const budgetRequestMtpStatuses = Object.keys(this.globalIdsCollapsibleTableData).map(key => this.globalIdsCollapsibleTableData[key].budgetRequestData?.planning?.mtpStatus);
        let budgetInMtpStatus = '';

        if (checkIfAllArrayItemsAreEqualTo(budgetRequestMtpStatuses, MtpStatus.APPROVED)) {
            budgetInMtpStatus = '(Approved)';
        } else if (checkIfAllArrayItemsAreEqualTo(budgetRequestMtpStatuses, MtpStatus.FLAGGED)) {
            budgetInMtpStatus = '(Flagged)';
        }

        return {
            mtpBudget: `Budget in MTP ${budgetInMtpStatus}`,
            totalBudget: 'Total Budget Required',
            compensation: 'Compensation Required',
            toBeApproved: 'Budget to be approved',
            compensationAvailable: 'Compensation Available'
        };
    }

    private get tableOptions(): PuiTableVendorOptions {
        const sumBudgetRequestData = (field: string): SumBudgetRequestData => {
            if (this.globalIdsCollapsibleTableData) {
                const approved = Object.keys(this.globalIdsCollapsibleTableData)
                    .filter(
                        (key) =>
                            this.globalIdsCollapsibleTableData[key].budgetRequestData.planning.mtpStatus ===
                            MtpStatus.APPROVED
                    )
                    .map((key) => this.globalIdsCollapsibleTableData[key].budgetRequestData.planning[field])
                    .reduce((previous, current) => previous + current, 0);

                const pending = Object.keys(this.globalIdsCollapsibleTableData)
                    .filter(
                        (key) =>
                            this.globalIdsCollapsibleTableData[key].budgetRequestData.planning.mtpStatus !==
                            MtpStatus.APPROVED
                    )
                    .map((key) => this.globalIdsCollapsibleTableData[key].budgetRequestData.planning[field])
                    .reduce((previous, current) => previous + current, 0);

                return {
                    approved,
                    pending,
                    total: approved + pending,
                };
            }

            return {
                approved: 0,
                pending: 0,
                total: 0,
            };
        };

        const sumBudgetCostData = (field: string): number => {
            if (this.requestType !== BudgetRequestPlanningStatus.NEW_PROJECT.toString()) {
                if (this.globalIdsCollapsibleTableData) {
                    return Object.keys(this.globalIdsCollapsibleTableData)
                        .map((key) => this.globalIdsCollapsibleTableData[key].budgetData[field])
                        .reduce(safeSum, 0);
                }
            } else {
                if (this.newProjectFormData) {
                    const value = parseFloat(this.newProjectFormData[field]);
                    return zeroIfNaN(value);
                }
            }

            return 0;
        };

        const isNonProjectChangeRequest = this.projectType === ProjectTypes.NonProject;

        const budgetRequestRow = {
            [this.columns['title']]: this.rows.mtpBudget,
            [this.columns['MTP-1']]: sumBudgetRequestData('spendUntilEndOfYear').total,
            [this.columns['MTP']]: sumBudgetRequestData('forecast').total,
            [this.columns['previousYears']]: isNonProjectChangeRequest ? 0 : sumBudgetRequestData('spendUntilEndOfYear').total + sumBudgetRequestData('forecast').total,
            [this.columns['MTP+1']]: sumBudgetRequestData('new1').total,
            [this.columns['MTP+2']]: sumBudgetRequestData('new2').total,
            [this.columns['MTP+3']]: sumBudgetRequestData('new3').total,
            [this.columns['ff']]: sumBudgetRequestData('after').total,
            [this.columns['total']]: sumBudgetRequestData('total').total,
        };

        const totalBudgetRow = {
            [this.columns['title']]: this.rows.totalBudget,
            [this.columns['MTP-1']]: sumBudgetCostData('spentYearMinus1'),
            [this.columns['MTP']]: sumBudgetCostData('spentYear'),
            [this.columns['previousYears']]: isNonProjectChangeRequest ? 0 : sumBudgetCostData('spentYearMinus1') + sumBudgetCostData('spentYear'),
            [this.columns['MTP+1']]: sumBudgetCostData('spentYearPlus1'),
            [this.columns['MTP+2']]: sumBudgetCostData('spentYearPlus2'),
            [this.columns['MTP+3']]: sumBudgetCostData('spentYearPlus3'),
            [this.columns['ff']]: sumBudgetCostData('spentAfterYear'),
            [this.columns['total']]: this.formTotal,
        };

        const compensationRowCellValue = (columnName: string): number => {
            return safeInvertedDifferenceOfDecimal(
                budgetRequestRow[this.columns[columnName]],
                totalBudgetRow[this.columns[columnName]]
            );
        };

        const compensationValues: Record<string, number> = {
            'MTP-1': compensationRowCellValue('MTP-1'),
            'MTP': compensationRowCellValue('MTP'),
            'previousYears': compensationRowCellValue('previousYears'),
            'MTP+1': compensationRowCellValue('MTP+1'),
            'MTP+2': compensationRowCellValue('MTP+2'),
            'MTP+3': compensationRowCellValue('MTP+3'),
            'ff': compensationRowCellValue('ff')
        };

        this.onCompensationRequiredChange({
            mtpPlus1Required: compensationValues['MTP+1'],
            mtpPlus2Required: compensationValues['MTP+2'],
            mtpPlus3Required: compensationValues['MTP+3'],
        });

        let compensationTotal = compensationValues['MTP+1'] + compensationValues['MTP+2'] + compensationValues['MTP+3'];

        if (!isNonProjectChangeRequest) {
            compensationTotal += compensationValues['ff'] + compensationValues['previousYears'];
        }

        const compensationRow = {
            [this.columns['title']]: this.rows.compensation,
            [this.columns['MTP-1']]: compensationValues['MTP-1'],
            [this.columns['MTP']]: compensationValues['MTP'],
            [this.columns['previousYears']]: 0,
            [this.columns['MTP+1']]: compensationValues['MTP+1'],
            [this.columns['MTP+2']]: compensationValues['MTP+2'],
            [this.columns['MTP+3']]: compensationValues['MTP+3'],
            [this.columns['ff']]: compensationValues['ff'],
            [this.columns['total']]: compensationTotal,
        };

        const toBeApprovedRowCellValue = (columnName: string): number => {
            return safeSum(
                compensationRow[this.columns[columnName]],
                sumBudgetRequestData(this.columnNameMap[columnName]).pending
            );
        };

        const toBeApprovedValues: Record<string, number> = {
            'MTP-1': toBeApprovedRowCellValue('MTP-1'),
            'MTP': toBeApprovedRowCellValue('MTP'),
            'previousYears': toBeApprovedRowCellValue('previousYears'),
            'MTP+1': toBeApprovedRowCellValue('MTP+1'),
            'MTP+2': toBeApprovedRowCellValue('MTP+2'),
            'MTP+3': toBeApprovedRowCellValue('MTP+3'),
            'ff': toBeApprovedRowCellValue('ff')
        };

        let toBeApprovedTotal = toBeApprovedValues['MTP+1'] + toBeApprovedValues['MTP+2'] + toBeApprovedValues['MTP+3'];

        if (!isNonProjectChangeRequest) {
            toBeApprovedTotal += toBeApprovedValues['ff'] + toBeApprovedValues['previousYears'];
        }

        const toBeApprovedRow = {
            [this.columns['title']]: this.rows.toBeApproved,
            [this.columns['MTP-1']]: toBeApprovedValues['MTP-1'],
            [this.columns['MTP']]: toBeApprovedValues['MTP'],
            [this.columns['previousYears']]: 0,
            [this.columns['MTP+1']]: toBeApprovedValues['MTP+1'],
            [this.columns['MTP+2']]: toBeApprovedValues['MTP+2'],
            [this.columns['MTP+3']]: toBeApprovedValues['MTP+3'],
            [this.columns['ff']]: toBeApprovedValues['ff'],
            [this.columns['total']]: toBeApprovedTotal,
        };

        const frontOptionalRows = [];
        const backOptionalRows = [];

        if (this.requestType !== BudgetRequestPlanningStatus.NEW_PROJECT.toString() && this.globalIdCount !== 1) {
            frontOptionalRows.push(budgetRequestRow, totalBudgetRow);
        }

        if (this.compensationTotals && this.compensationTotals.hasCompensationEntries) {
            const compensationAvailable = {
                [this.columns['title']]: this.rows.compensationAvailable,
                [this.columns['MTP+1']]: this.compensationTotals.mtpYearPlus1Total,
                [this.columns['MTP+2']]: this.compensationTotals.mtpYearPlus2Total,
                [this.columns['MTP+3']]: this.compensationTotals.mtpYearPlus3Total,
                [this.columns['ff']]: '-',
                [this.columns['total']]: this.compensationTotals.totalCompensation,
            };

            backOptionalRows.push(compensationAvailable);
        }

        return {
            data: [...frontOptionalRows, compensationRow, toBeApprovedRow, ...backOptionalRows],
            options: {
                sortable: [],
            },
            columns: Object.values(this.shownColumns),
        };
    }

    private updateConvertedTotalCost(): void {
        if (!this.currencyName || !this.mtpYear) {
            return;
        }

        let inputTotals: number[];

        const currencyRate = this.findCurrencyRate(this.currencyName, this.mtpYear);

        if (this.requestType !== BudgetRequestPlanningStatus.NEW_PROJECT.toString()) {
            const budgetDataEntries = Object.values(this.globalIdsCollapsibleTableData).map(entry => entry.budgetData);

            inputTotals = this.totalKeys.map((key) => budgetDataEntries.map((entry) => {
                if (!this.tableInputFields.includes(key)) {
                    return 0;
                }
                return entry[key] * this.ONE_MILLION * currencyRate[TABLE_FIELDS[key]];
            }).reduce(safeSum, 0));
        } else {

            inputTotals = this.totalKeys.map((key) => {
                if (!this.tableInputFields.includes(key)) {
                    return 0;
                }
                return this.newProjectFormData[key] * this.ONE_MILLION * currencyRate[TABLE_FIELDS[key]];
            });
        }

        const inputsTotalCost = inputTotals.reduce(safeSum, 0);
        this.$emit('total-cost-updated', inputsTotalCost);
    }

    private findCurrencyRate(baseCurrency: string, year: number): CurrencyRate {
        const currencyRates: CurrencyRate[] = Data.Instance.constants.currencyRates;
        if (currencyRates) {
            return currencyRates.find(currencyRate =>
                currencyRate.year === year &&
                currencyRate.baseCurrency === baseCurrency &&
                currencyRate.targetCurrency === 'mEUR');
        }
    }

    private onCompensationRequiredChange(compensationRequiredTotals: CompensationRequiredTotals): void {
        this.$emit('change:compensation-required', compensationRequiredTotals);
    }
}
