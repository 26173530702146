import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class FirstDeleteBulkModalComponent extends Vue {

    private emitSecondModal() {
        $('#firstDeleteBulkModal').modal('hide');
        $('#secondDeleteBulkModal').modal('show');
    }
}
