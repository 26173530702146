import Component from 'vue-class-component';
import Vue from 'vue';
import { SelectOption } from '~/utils/interfaces';
import { MasterService } from '~/services/master-service';
import { Prop } from 'vue-property-decorator';

const MAX_NUMBER_OF_RESULTS = 5 as const;

@Component({})
export default class UamUserInputComponent extends Vue {
    @Prop({ default: () => null })
    private initialValue: SelectOption | null;

    @Prop({ default: () => true })
    private isValid!: boolean;

    @Prop({ default: () => false })
    private isDisabled!: boolean;

    private userInput: SelectOption[] | null = null;

    private searchResults: SelectOption[] = [];
    private isLoading = false;

    private mounted(): void {
        this.userInput = [this.initialValue];
    }

    private async fetchUsers(searchQuery: string): Promise<void> {
        this.isLoading = true;
        this.clearSearchResults();

        const users = await MasterService.instance.uamService.SearchUsers(searchQuery);
        this.searchResults = users.slice(0, MAX_NUMBER_OF_RESULTS).map(user => ({
            label: user.name,
            value: user.kid,
        }));

        this.isLoading = false;
    }

    private userChanged(): void {
        if (this.userInput.length === 0) {
            this.$emit('clear:user');
            return;
        }

        this.$emit('update:user', this.userInput[0]);
    }

    private clearSearchResults(): void {
        this.searchResults = [];
    }
}
