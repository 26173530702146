var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-header"
  }, [_c('div', {
    staticClass: "table-header__left"
  }, [_c('div', {
    staticClass: "table-header__left__search"
  }, [!_vm.hideSearch ? [_c('pui-form-input-field', {
    attrs: {
      "id": _vm.searchFieldId,
      "placeholder-text": "Search in table",
      "trailing-icon-name": "close",
      "trailing-icon-is-clickable": ""
    },
    on: {
      "trailing-icon:click": _vm.onClearSearchTerm
    },
    model: {
      value: _vm.tableExtraFilters.searchTerm,
      callback: function ($$v) {
        _vm.$set(_vm.tableExtraFilters, "searchTerm", $$v);
      },
      expression: "tableExtraFilters.searchTerm"
    }
  }), _c('div', {
    staticClass: "table-header__left__search__button"
  }, [_c('pui-button', {
    attrs: {
      "icon": "search",
      "state": "primary",
      "icon-only": ""
    },
    on: {
      "click": _vm.onTriggerSearch
    }
  })], 1)] : _vm._e()], 2), _vm._t("default")], 2), _c('div', {
    staticClass: "table-header__page-size"
  }, [_vm._v(" Results per page: "), _c('div', {
    staticClass: "table-header__page-size__select"
  }, [_c('pui-form-select', {
    attrs: {
      "label": "",
      "search-input-placeholder": " ",
      "value": _vm.tableExtraFilters.pageSize,
      "options": _vm.PAGE_SIZE_OPTIONS
    },
    on: {
      "change": _vm.onPageSizeChanged
    },
    model: {
      value: _vm.tableExtraFilters.pageSize,
      callback: function ($$v) {
        _vm.$set(_vm.tableExtraFilters, "pageSize", $$v);
      },
      expression: "tableExtraFilters.pageSize"
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }