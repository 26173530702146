
import { NewBudgetRequests, ReviewBudgetRequest } from '~/utils/interfaces';
import { MiniFd, SearchMiniFdResponseMtp } from '~/models/services/minifd';

export type BudgetRequest = {
    id: string;
    globalId: string;
    plantSid: number;
    technologySid: number;
    unitSid: number;
    name: string;
    localLanguageName: string;
    unitName: string;
    plantName: string;
    groupName: string;
    technologyName: string;
    countryCode: string;
    countryName: string;
    projectType: number;
    isActive: boolean;
    planning: BudgetRequestPlanning;
    mtpList: number[];
    miniFdList: number[];
    miniFinancialDecision: MiniFd;
    budgetCompensation: BudgetCompensation;
    created: string;
    createdBy: string;
    isDeleted: boolean;
};

export type BudgetRequestPlanning = SearchMiniFdResponseMtp;

export type BudgetCompensation = {
    budgetRequestId: string;
    miniFdId: string;
    year: number;
    yearPlus1: number;
    yearPlus2: number;
    selectedMtpYear: number;
};

export interface BudgetRequestByOutageSearchResult {
    outageName: string;
    budgetRequests: BudgetRequestSearchResultEntry[];
}

export type BudgetRequestSearchResult = BudgetRequestSearchResultEntry[];

export enum OutageType {
    OUTAGE = 1,
    NON_OUTAGE = 2
}

export interface BudgetRequestSearchResultEntry {
    budgetRequestId: string;
    globalId: string;
    name: string;
    technologySid: number;
    plantSid: number;
    unitSid: number;
    hasChangeRequest: boolean;
    mtpList: {
        year: number;
        status: number;
        outageType: OutageType;
        outageId?: string;
        outageName?: string;
        canCreateFromReviewed: boolean;
    }[];
    miniFdList: {
        uniqueId: string;
        mtpYear: number;
        miniFdYear: number;
        outageType: OutageType;
        outageId?: string;
        outageName?: string;
        status: number;
        approvalDate: string;
        canCreateFromReviewed: boolean;
    }[];
}

export interface LatestBudgetRequestDataResponse {
    budgetRequestId: string;
    globalId: string;
    projectName: string;
    new1: number;
    new2: number;
    new3: number;
}

export type SearchBudgetRequestsByGlobalIdResponse = {
    budgetRequests: BudgetRequestSearchResult
};

export type SearchBudgetRequestsByOutageIdResponse = {
    budgetRequests: BudgetRequestByOutageSearchResult
};

export type ChangeMtpStatusesPayload = {
    globalIds: string[],
    mtpYear: number,
    mtpStatus: number
};

export type ChangeMtpStatusesResponse = {
    items: {
        globalId: string;
        name: string;
        hasErrors: boolean;
        error: string;
    }[],
    requestIsValid: boolean,
    countUpdates: number,
    text: string
};

export type ReviewBudgetRequestPayload = ReviewBudgetRequest;

export type ReviewBudgetRequestResponse = ChangeMtpStatusesResponse;

export type ReleaseBudgetRequestPayload = {
    globalIds: string[],
    mtpYear: number,
    fundingStatus: number
};

export type ReleaseBudgetRequestResponse = ChangeMtpStatusesResponse;

export type DeleteBudgetRequestPayload = {
    globalIds: string[],
    isActive: boolean,
    mtpYear: number
};

export type DeleteBudgetRequestResponse = {
    countDeleted: number
};

export type SetBudgetRequestsInactivePayload = {
    globalIds: string[],
    mtpYear: number
};

export type SetBudgetRequestsInactiveResponse = {
    deactivatedBudgetsCount: number
};

export type AddToCurrentMtpPayload = NewBudgetRequests;

export type AddToCurrentMtpResponse = {
    id: string
};

export type BulkDeleteInactiveResponse = {
    requestIsValid: boolean,
    errorMessage: string,
    countDeletedBudgetRequests: number,
    countDeletedCurrentMtps: number
};

export type LatestChangeInPlantPayload = {
    currentPlantIds: number[]
};

export type LatestChangeInPlantResponse = {
    kId: string,
    dateTime: string
};

export type ControllingInformation = {
    budgetRequestId?: string,
    globalId: string,
    year: number,
    mtpStatus: number,
    wbsElement: string,
    planningAndControllingClassification: number,
    outageType: number,
    outageId?: string,
    comment: string
};

export type EditControllingInformationPayload = ControllingInformation;

export type EditControllingInformationResponse = {
    id: string
};

export type EditProjectStatusPayload = {
    globalIds: string[];
    mtpYear: number;
    projectStatus: number;
};

export type EditProjectStatusResponse = {
    countUpdates: number; 
}
