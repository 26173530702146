import moment from 'moment-timezone';

export class Helper {
    /**
     *
     * @param list supplied list
     * @param key property of the object from which to create the map
     * @description Create a map from a list, key will be property of the result object.
     * @return object
     */
    public static createMap(list: any[], key: string): any {
        let result = {};
        list.forEach((el) => {
            if (el.hasOwnProperty(key)) {
                result[el[key].toString()] = el;
            }
        });
        return result;
    }

    /**
     *
     * @param object source object
     * @param result destination object
     * @description Deep clone an object.
     * @return object
     */
    public static deepCopy(object: any, result): any {
        result = Object.assign({}, object);
        for (let prop in object) {
            if (object[prop] !== null && object[prop].constructor === Object) {
                result[prop] = this.deepCopy(object[prop], result);
            }
        }
        return result;
    }

    /**
     *
     * @param list array of objects
     * @param condition function with boolean return
     * @description Takes an array and a function as arguments,
     * returns an array of objects which meet the supplied condition.
     * @return array
     */
    public static filterItemsFromListOnCondition(list: any[], condition: (i) => boolean): any[] {
        let result = [];
        list.forEach(item => {
            if (condition(item)) {
                result.push(item);
            }
        });
        return result;
    }

    /**
     *
     * @param list list of objects
     * @param property property to be cumulated
     * @description Takes an array and a string as arguments,
     * returns the summed value of a property of the objects in the array.
     * @return number || string
     */
    public static sumProperty(list: any[], property: string): any {
        if (list.length > 1) {
            return this.pluckProp(list.reduce((a, b) => {
                let res = {};
                res[property] = a[property] + b[property];
                return res;
            }));
        } else if (list.length === 1) {
            // if the list is composed of one element, return the value of the first element.
            return list[0][property];
        }
        return 0;
    }

    /**
     *
     * @param obj object
     * @param index index of a property, default 0
     * @description Takes an object and an index as arguments,
     * returns the object's property found at the supplied index.
     * @return any
     */
    public static pluckProp(obj, index: number = 0): any {
        return obj[Object.keys(obj)[index]];
    }

    /**
     * @description Get the list of months.
     * @return array
     */
    public static getMonths(): string[] {
        return [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sept',
            'Oct',
            'Nov',
            'Dec'
        ];
    }

    /**
     * @description Initialize all tooltips on a page.
     */
    public static initializeTooltips(): void {
        $('[data-toggle="tooltip"]').tooltip();
    }

    /**
     * @description Checks if a date is set later than another.
     * @param a
     * @param b
     * @returns boolean
     */
    public static dateGreaterThan(date: Date, comparedTo: Date): boolean {
        return date.getTime() > comparedTo.getTime();
    }

    /**
     * @description Checks if a date is set earlier than another.
     * @param date
     * @param comparedTo
     * @returns boolean
     */
    public static dateLessThan(date: Date, comparedTo: Date): boolean {
        return date.getTime() < comparedTo.getTime();
    }

    /**
     * @description Parse a date object to a 'yyyy-MM-dd' format.
     * @param dateObject
     * @returns string
     */
    public static parseStringFromDate(dateObject: Date): string {
        let month = dateObject.getMonth() + 1;
        let date = dateObject.getDate();
        let monthString = month < 10 ? `0${month.toString()}` : month;
        let dateString = date < 10 ? `0${date.toString()}` : date;

        return `${dateObject.getFullYear()}-${monthString}-${dateString}`;
    }

    /**
     * @description Generate a GUID composed of random characters.
     * @param length (optional) Number of characters, default 4.
     * @returns string
     */
    public static generateGUID(length: number = 4): string {
        let text = '';
        let possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for (let i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }

    public static generateGUIDv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }


    /**
     * @description Forces an object property to be a positive number(not less than zero).
     * @param object
     * @param property
     */
    public static enforcePositiveNumber(object: any, property: string): void {
        if (object.hasOwnProperty(property) && object[property] < 0) {
            object[property] = 0;
        }
    }

    public static UtcToLocalTime(datetime): Date {
        return moment.utc(datetime).toDate();
    }

    public static getYearsForLabel(year: number): string {
        return `${year} (${year + 1} - ${year + 3})`;
    }
}
