var render = function () {
  var _this = this;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      'pt-4': !_vm.data.isAssetDataAvailable
    }
  }, [_c('loading', {
    attrs: {
      "active": _vm.isLoading,
      "can-cancel": true
    },
    on: {
      "update:active": function ($event) {
        _vm.isLoading = $event;
      }
    }
  }), _vm.data.changedMtpLoading === true || _vm.data.removeFromMtp === true || _vm.data.deleteBr === true || _vm.data.exportBr === true || _vm.data.switchPageView === true || _vm.data.uploadingSpreadSheet === true || _vm.data.addFile === true || _vm.data.deleteFile === true || _vm.data.downloadFile ? _c('loading-spinner') : _vm._e(), _vm.data.constants ? _c('div', [_c('b-toast', {
    attrs: {
      "id": "toast-mtp",
      "auto-hide-delay": "20000",
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center max-width-toast-update-mtp"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [_c('div', [_c('icon', {
    staticClass: "icon-toast text-center",
    attrs: {
      "name": "check"
    }
  })], 1), _c('div', [_vm.data.selectedOneMtpUpdateStatus && _vm.data.selectedOneMtpUpdateStatus.split('.')[0] ? _c('span', [_vm._v(_vm._s(_vm.data.selectedOneMtpUpdateStatus.split('.')[0] + '.') + " ")]) : _vm._e(), _vm.data.selectedOneMtpUpdateStatus && _vm.data.selectedOneMtpUpdateStatus.split('.')[1] ? _c('span', [_vm._v(" " + _vm._s(_vm.data.selectedOneMtpUpdateStatus.split('.')[1] + '.') + " " + _vm._s(_vm.data.selectedOneMtpUpdateStatus.split('.')[2]) + " ")]) : _vm._e()]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideToastDelBr();
      }
    }
  }, [_vm._v(" Dismiss ")])])]), _c('b-toast', {
    attrs: {
      "id": "remove-and-set-inactive",
      "auto-hide-delay": "20000",
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center max-width-toast"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [_c('div', [_c('icon', {
    staticClass: "icon-toast",
    attrs: {
      "name": "trash-alt"
    }
  })], 1), _vm.deleteBudgetRequests && _vm.setToInactiveBr ? _c('div', [_vm.deleteBudgetRequests.globalIds.length !== 0 ? _c('span', [_vm._v(_vm._s(_vm.deleteBudgetRequests.globalIds.length) + " " + _vm._s(_vm.deleteBudgetRequests.globalIds.length !== 1 ? "budget requests were successfully deleted" : "budget request was successfully deleted") + " ")]) : _vm._e(), _vm.setToInactiveBr.globalIds.length !== 0 ? _c('h6', {
    staticStyle: {
      "color": "white"
    }
  }, [_vm._v(" " + _vm._s(_vm.setToInactiveBr.globalIds.length) + " " + _vm._s(_vm.setToInactiveBr.globalIds.length !== 1 ? " of the budget requests were set to inactive and can be accessed via the dropdown at the top of the page." : " of the budget request was set to inactive and can be accessed via the dropdown at the top of the page.") + " ")]) : _vm._e()]) : _vm._e(), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideToastDelBr();
      }
    }
  }, [_vm._v(" Dismiss ")])])]), _c('b-toast', {
    attrs: {
      "id": "funding-status-toast",
      "auto-hide-delay": "20000",
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center funding-status-toast"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [_c('div', [_c('icon', {
    staticClass: "icon-toast text-center",
    attrs: {
      "name": "check"
    }
  })], 1), _c('div', [_vm.data.updatedFundingStatus ? _c('span', [_vm._v(_vm._s(_vm.data.updatedFundingStatus))]) : _vm._e()]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideToastDelBr();
      }
    }
  }, [_vm._v(" Dismiss ")])])]), _c('b-toast', {
    attrs: {
      "id": "project-status-toast",
      "auto-hide-delay": "20000",
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center project-status-toast"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [_c('div', [_c('icon', {
    staticClass: "icon-toast text-center",
    attrs: {
      "name": "check"
    }
  })], 1), _c('div', [_vm.data.updatedProjectStatus ? _c('span', [_vm._v(_vm._s(_vm.data.updatedProjectStatus))]) : _vm._e()]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideToastDelBr();
      }
    }
  }, [_vm._v(" Dismiss ")])])]), _c('b-toast', {
    attrs: {
      "id": "edit-controlling-toast",
      "auto-hide-delay": "20000",
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [_c('div', [_c('icon', {
    staticClass: "icon-toast text-center",
    attrs: {
      "name": "check"
    }
  })], 1), _c('div', [_c('span', [_vm._v("The data has been updated.")])]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideToastDelBr();
      }
    }
  }, [_vm._v(" Dismiss ")])])]), _c('confirmation-modal'), _c('delete-br-modal'), _c('set-inactive-modal'), _c('update-mtp-modal'), _c('add-budget-requests-modal', {
    ref: "import-from-excel",
    attrs: {
      "data": _vm.data
    },
    on: {
      "showerror": _vm.showError,
      "succesimport": function ($event) {
        return _vm.succesImport();
      }
    }
  }), _c('delete-remain-table-modal', {
    on: {
      "deleteonlydraftandsubmitted": _vm.deleteOnlyDraftAndSubmitted
    }
  }), _c('confirm-delete-modal', {
    on: {
      "deletebudget": _vm.deleteBrInactive
    }
  }), _c('remove-br-modal', {
    ref: "remove-br-modal",
    attrs: {
      "created-year": _vm.createdYear,
      "selected-mtp": _vm.mtpYear
    }
  }), _c('remove-from-mtp-table-modal', {
    ref: "remove-from-mtp-table-modal",
    attrs: {
      "data": _vm.data,
      "items-checked": _vm.itemsChecked
    },
    on: {
      "update": _vm.updateRemoveMtp
    }
  }), _c('change-review-modal', {
    ref: "change-review-modal",
    attrs: {
      "br-id-change-mtp": _vm.brIdChangeMtp,
      "comment-from-change-mtp": _vm.commentFromChangeMtp,
      "data": _vm.data,
      "global-id-from-change-mtp": _vm.globalIdFromChangeMtp,
      "key-id-from-change-mtp": _vm.keyIdFromChangeMtp,
      "main-flag-from-change-mtp": _vm.mainFlagFromChangeMtp,
      "other-flags-from-change-mtp": _vm.otherFlagsFromChangeMtp,
      "year-from-change-mtp": _vm.yearFromChangeMtp
    }
  }), _c('error-modal', {
    attrs: {
      "error-import": _vm.errorImport
    }
  }), _c('move-current-mtp-warning-modal', {
    attrs: {
      "global-id": _vm.selectedGlobalId,
      "selected-mtp-year": _vm.selectedMtpYear
    }
  }), _c('bulk-approve-modal', {
    on: {
      "bulkApproval": function ($event) {
        return _vm.bulkApproval();
      }
    }
  }), _c('edit-controlling-modal', {
    key: _vm.selectedGlobalId,
    ref: "edit-controlling",
    on: {
      "edit-controlling-information": _vm.getDataEditControlling
    }
  }), _vm.data.isAssetDataAvailable ? _c('div', {
    staticClass: "row heading-row"
  }, [_c('div', {
    staticClass: "col-xl-3 col-lg-4 col-md-5"
  }, [_c('plant-selector')], 1), _c('div', {
    staticClass: "col-xl-3 col-lg-4 col-md-5"
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedMtpFilter,
      expression: "selectedMtpFilter"
    }],
    staticClass: "form-control select-input",
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.selectedMtpFilter = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, _vm.changeMtpPeriod]
    }
  }, _vm._l(_vm.data.sortedMtpFilters, function (mtpFilter, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": ((mtpFilter.year) + "-" + (mtpFilter.miniFdYear))
      }
    }, [_vm._v(" " + _vm._s(mtpFilter.description) + " ")]);
  }), 0)]), _c('div', {
    staticClass: "col-xl-6 col-lg-4 col-md-12 pb-lg-4 pt-lg-5"
  }, [_vm.isCurrentMtp && (_vm.userPermissions.includes('BudgetRequestDetailsEdit') && _vm.isMtpPeriod || _vm.userPermissions.includes('BudgetRequestDetailsEditAnytime')) ? _c('a', {
    staticClass: "button-edit float-sm-right float-none text-right d-block mb-2 pr-sm-0 pr-3",
    on: {
      "click": function ($event) {
        return _vm.addBudReq();
      }
    }
  }, [_c('icon', {
    staticClass: "icon-add",
    attrs: {
      "name": "plus"
    }
  }), _vm._v(" Add Budget Request ")], 1) : _vm._e(), _vm.userPermissions.includes('BudgetRequestDetailsEdit') && _vm.isMtpPeriod || _vm.userPermissions.includes('BudgetRequestDetailsEditAnytime') ? _c('input', {
    staticClass: "float-right",
    attrs: {
      "id": "file",
      "accept": ".xlsx, .xls",
      "hidden": "",
      "name": "file",
      "type": "file"
    },
    on: {
      "change": function ($event) {
        return _vm.importFromExcel($event);
      }
    }
  }) : _vm._e(), _vm.isCurrentMtp && (_vm.userPermissions.includes('BudgetRequestDetailsEdit') && _vm.isMtpPeriod || _vm.userPermissions.includes('BudgetRequestDetailsEditAnytime')) ? _c('label', {
    staticClass: "pointerLink float-sm-right float-none text-right d-block pr-3",
    attrs: {
      "for": "file"
    },
    on: {
      "click": function ($event) {
        return _vm.resetValueImport();
      }
    }
  }, [_c('icon', {
    staticClass: "pr-2",
    attrs: {
      "name": "download"
    }
  }), _vm._v(" Import From Excel ")], 1) : _vm._e(), _vm.isCurrentMtp && _vm.userPermissions.includes('BudgetRequestApproveEdit') ? _c('label', {
    staticClass: "pointerLink float-sm-right float-none text-right d-block pr-3",
    on: {
      "click": function ($event) {
        return _vm.bulkApprovalConfirm();
      }
    }
  }, [_c('icon', {
    staticClass: "icon-check",
    attrs: {
      "name": "check"
    }
  }), _vm._v(" Bulk approve ")], 1) : _vm._e()])]) : _vm._e(), !_vm.data.isAssetDataAvailable ? _c('div', {
    staticClass: "pt-3"
  }, [_vm._v(" Master data assets not available ")]) : _vm._e(), _vm.data.isAssetDataAvailable ? _c('chart-budget-requests', {
    ref: "get-budget-distribution",
    attrs: {
      "mini-fd-year": _vm.miniFdYear,
      "selected-mtp-filter": Math.abs(_vm.mtpYear)
    },
    on: {
      "mtpstatuses": _vm.filteredMtpStatuses
    }
  }) : _vm._e(), _vm.data.isAssetDataAvailable ? _c('files-budget-request', {
    attrs: {
      "is-active": _vm.isActive,
      "selected-mtp-filter": _vm.mtpYear
    }
  }) : _vm._e(), !_vm.loading && (_vm.mtpYear || _vm.mtpYear === 0) ? _c('div', {
    staticClass: "accordion-budget-requests-table__table",
    attrs: {
      "id": "accordion"
    }
  }, [_c('div', {
    staticClass: "accordion-budget-requests-table__table__extra-header"
  }, [_c('div', {
    staticClass: "accordion-budget-requests-table__table__extra-header__title"
  }, [_c('pui-headline', {
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Budget Requests ")])], 1)]), _vm.itemsChecked.length > 0 ? _c('div', {
    staticClass: "accordion-budget-requests-table__table__extra-header"
  }, [_c('div', {
    staticClass: "accordion-budget-requests-table__table__extra-header__title"
  }, [_c('pui-headline', {
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.itemsChecked.length) + " item(s) selected ")])], 1), _c('div', {
    staticClass: "accordion-budget-requests-table__table__extra-header__actions"
  }, [!_vm.isActive && (_vm.userPermissions.includes('BudgetRequestDetailsEdit') && _vm.isMtpPeriod || _vm.userPermissions.includes('BudgetRequestDetailsEditAnytime')) && !this.itemsChecked.some(function (e) {
    return e.created !== _this.currentYear.toString();
  }) ? _c('pui-button', {
    attrs: {
      "icon": "delete",
      "state": "primary",
      "small": ""
    },
    on: {
      "click": _vm.deleteBudgetRequest
    }
  }, [_vm._v(" Delete ")]) : _vm._e(), _vm.isEverySelectedItemInCurrentYear && parseInt(this.mtpYear) === 0 && parseInt(this.miniFdYear) === 0 && _vm.userPermissions.includes('BudgetRequestDetailsEdit') ? _c('pui-button', {
    attrs: {
      "icon": "delete",
      "state": "primary",
      "small": ""
    },
    on: {
      "click": _vm.bulkDeleteInactiveMTP
    }
  }, [_vm._v(" Delete Inactive MTP ")]) : _vm._e(), _vm.isCurrentMtp && (_vm.userPermissions.includes('BudgetRequestDetailsEdit') && _vm.isMtpPeriod || _vm.userPermissions.includes('BudgetRequestDetailsEditAnytime')) ? _c('pui-button', {
    attrs: {
      "icon": "delete",
      "state": "primary",
      "small": ""
    },
    on: {
      "click": _vm.removeMultipleMtp
    }
  }, [_vm._v(" Remove from MTP ")]) : _vm._e(), (Object.keys(_vm.itemsChecked).length === 1 || parseInt(_vm.mtpYear) !== 0) && _vm.userPermissions.includes('MtpSiteDocumentsView') ? _c('pui-button', {
    attrs: {
      "icon": "exported",
      "state": "primary",
      "small": ""
    },
    on: {
      "click": _vm.exportToExcelTable
    }
  }, [_vm._v(" Export to Excel ")]) : _vm._e(), _c('div', {
    staticClass: "dropdown d-md-inline d-block"
  }, [_vm.isCurrentMtp && (_vm.userPermissions.includes('BudgetRequestDetailsEdit') && _vm.isMtpPeriod || _vm.userPermissions.includes('BudgetRequestReviewEdit') || _vm.userPermissions.includes('BudgetRequestDetailsEditAnytime') || _vm.userPermissions.includes('BudgetRequestApproveEdit')) ? _c('pui-button', {
    staticClass: "pointerLink dropdown-toggle",
    attrs: {
      "icon": "edit",
      "state": "primary",
      "small": "",
      "id": "dropdownMenuButton",
      "aria-expanded": "false",
      "aria-haspopup": "true",
      "data-toggle": "dropdown"
    }
  }, [_vm._v(" Update MTP Status ")]) : _vm._e(), _c('div', {
    staticClass: "dropdown-menu"
  }, _vm._l(_vm.data.constants.mtpStatus, function (mtpstatus, keyMtpStatus) {
    return _c('div', {
      key: keyMtpStatus
    }, [_vm.userPermissions.includes('BudgetRequestReviewEdit') && (keyMtpStatus === '3' || keyMtpStatus === '4') || (_vm.userPermissions.includes('BudgetRequestDetailsEdit') && _vm.isMtpPeriod || _vm.userPermissions.includes('BudgetRequestDetailsEditAnytime')) && (keyMtpStatus === '1' || keyMtpStatus === '2') || _vm.userPermissions.includes('BudgetRequestApproveEdit') && keyMtpStatus === '5' ? _c('button', {
      staticClass: "dropdown-item",
      attrs: {
        "disabled": _vm.disableUpdateMtpByMultipleSelected(keyMtpStatus),
        "keyMtpStatus": keyMtpStatus
      },
      on: {
        "click": function ($event) {
          return _vm.updateMultipleMtpStatuses(keyMtpStatus);
        }
      }
    }, [_c('div', {
      staticClass: "box",
      class: mtpstatus.color
    }), _vm._v(" " + _vm._s(mtpstatus.name) + " ")]) : _vm._e()]);
  }), 0)], 1), _c('div', {
    staticClass: "dropdown d-md-inline d-block"
  }, [_vm.isCurrentMtp && _vm.userPermissions.includes('BudgetRequestReviewEdit') ? _c('pui-button', {
    staticClass: "pointerLink dropdown-toggle",
    attrs: {
      "icon": "edit",
      "state": "primary",
      "small": "",
      "id": "fundigStatusDropdown",
      "aria-expanded": "false",
      "aria-haspopup": "true",
      "data-toggle": "dropdown"
    }
  }, [_vm._v(" Update Funding Status ")]) : _vm._e(), _c('div', {
    staticClass: "dropdown-menu",
    attrs: {
      "aria-labelledby": "fundigStatusDropdown"
    }
  }, [_vm.userPermissions.includes('BudgetRequestReviewEdit') ? _c('div', _vm._l(_vm.data.constants.fundingStatus, function (fundingStatus, fundingStatusKey) {
    return _c('button', {
      key: fundingStatusKey,
      staticClass: "dropdown-item",
      on: {
        "click": function ($event) {
          return _vm.changeMultipleFundingStatus(fundingStatusKey);
        }
      }
    }, [_vm._v(" " + _vm._s(fundingStatus) + " ")]);
  }), 0) : _vm._e()])], 1), _c('div', {
    staticClass: "dropdown d-md-inline d-block"
  }, [_vm.isCurrentMtp && _vm.userPermissions.includes('BudgetRequestReviewEdit') ? _c('pui-button', {
    staticClass: "pointerLink dropdown-toggle",
    attrs: {
      "icon": "edit",
      "state": "primary",
      "small": "",
      "id": "projectStatusDropdown",
      "aria-expanded": "false",
      "aria-haspopup": "true",
      "data-toggle": "dropdown"
    }
  }, [_vm._v(" Update Project Status ")]) : _vm._e(), _c('div', {
    staticClass: "dropdown-menu",
    attrs: {
      "aria-labelledby": "projectStatusDropdown"
    }
  }, [_vm.userPermissions.includes('BudgetRequestReviewEdit') ? _c('div', _vm._l(_vm.data.constants.projectStatusType, function (projectStatus, projectStatusKey) {
    return _c('button', {
      key: projectStatusKey,
      staticClass: "dropdown-item",
      on: {
        "click": function ($event) {
          return _vm.changeMultipleProjectStatus(projectStatusKey);
        }
      }
    }, [_vm._v(" " + _vm._s(projectStatus) + " ")]);
  }), 0) : _vm._e()])], 1), _vm.isCurrentMtp && _vm.userPermissions.includes('BudgetRequestReviewEdit') ? _c('pui-button', {
    attrs: {
      "icon": "close",
      "state": "primary",
      "small": ""
    },
    on: {
      "click": _vm.clearItemsChecked
    }
  }, [_vm._v(" Cancel ")]) : _vm._e()], 1)]) : _vm._e(), _c('table-header', {
    attrs: {
      "page-size": _vm.tableExtraFilters.pageSize
    },
    on: {
      "page-size-changed": _vm.onPageSizeChanged,
      "search-triggered": _vm.onTriggerSearch,
      "clear-search-term": _vm.onClearSearchTerm
    }
  }, [_vm.isFilterApplied ? _c('pui-button', {
    attrs: {
      "icon": "reset",
      "state": "primary",
      "small": ""
    },
    on: {
      "click": _vm.resetFilterState
    }
  }, [_vm._v(" Reset All Filters ")]) : _vm._e(), _c('div', {
    staticClass: "accordion-budget-requests-table__table__extra-header__toggle"
  }, [_c('pui-toggle', {
    attrs: {
      "checked": _vm.selectedIsReadyForApproval
    },
    on: {
      "change": _vm.applyIsReadyForApprovalFilter
    },
    scopedSlots: _vm._u([{
      key: "puiToggleLeft",
      fn: function () {
        return [_vm._v(" Ready for approval ")];
      },
      proxy: true
    }], null, false, 3010119407)
  })], 1)], 1), _c('ag-grid-vue', {
    staticClass: "ag-theme-alpine",
    attrs: {
      "grid-options": _vm.gridOptions,
      "column-defs": _vm.columnDefs
    },
    on: {
      "grid-ready": _vm.onGridReady
    }
  })], 1) : _vm._e()], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }