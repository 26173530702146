import Component from 'vue-class-component';
import Vue from 'vue';
import CreateIybrFormComponent from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/create-iybr-form.vue';
import { BreadcrumbLink } from '~/utils/interfaces';
import './edit-in-year-budget-request.scss';
import { ROWS } from '~/components/in-year-budget-requests/constants/rows';
import { PuiLightbox } from '~/models/libraries/pebble-ui';

@Component({
    components: {
        createIybrForm: CreateIybrFormComponent
    }
})
export default class EditInYearBudgetRequestComponent extends Vue {
    $refs!: {
        form: CreateIybrFormComponent;
    };

    private readonly formLayout = ROWS;
    private readonly SUBMIT_EDIT_LOADING_MODAL = 'submitEditLoadingModal';
    private projectName: string = null;

    private get breadcrumbLinks(): BreadcrumbLink[] {
        const uniqueId = this.$route.query.uniqueId as string;

        return [
            {
                label: 'Change Requests',
                href: '/in-year-budget-requests'
            },
            {
                label: this.projectName || uniqueId,
                href: `/view-in-year-budget-request?uniqueId=${uniqueId}`
            },
            {
                label: 'Edit Change Request',
                href: `/edit-in-year-budget-request?uniqueId=${uniqueId}`
            }
        ];
    }

    private onProjectNameUpdated(projectName: string): void {
        this.projectName = projectName;
    }

    private async onFormSubmit(uniqueId: string): Promise<void> {
        (this.$refs[this.SUBMIT_EDIT_LOADING_MODAL] as PuiLightbox).close();
        await this.$router.push({
            path: '/view-in-year-budget-request',
            query: {
                uniqueId: uniqueId
            }
        });
    }

    private onBreadcrumbLinkClick(param: { href: string }): void {
        this.$router.push(param.href).then();
    }

    private openLoadingScreen(): void {
        (this.$refs[this.SUBMIT_EDIT_LOADING_MODAL] as PuiLightbox).open();
    }

    private handleSubmitError(): void {
        (this.$refs[this.SUBMIT_EDIT_LOADING_MODAL] as PuiLightbox).close();
        this.$bvToast.show('toast-error-submit-change-request');
    }
}
