import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { MtpStatus, Plant } from '~/utils/interfaces';
import { safeInvertedDifference, safeSum, zeroIfNaN } from '~/utils/math-helper';
import { cellValueClass, tableCellTextFormat } from '~/utils/table-helper';
import { checkIfAllArrayItemsAreEqualTo } from '~/utils/array-utils';
import { ProjectTypes } from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/iybr-global-ids-collapsible/iybr-global-ids-collapsible';
import { CompensationTableTotals } from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/iybr-compensation-table/iybr-compensation-table';
import { formatToDecimals } from '~/utils/number-helper';
import { PuiTableVendorOptions } from '~/models/libraries/pebble-ui';
import { GetMiniFdByGlobalIdResponse } from '~/models/services/minifd';
import {
    SumBudgetRequestData
} from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/iybr-cost-table/iybr-cost-table';
import { PlantAttributesItem } from '~/models/services/plants';
import {
    RequestOptions
} from "~/components/in-year-budget-requests/add-in-year-budget-requests-modal/add-in-year-budget-requests-modal"

@Component({})
export default class ViewCostTableComponent extends Vue {
    @Prop()
    private iybrData?: GetMiniFdByGlobalIdResponse;

    @Prop()
    private plantData?: PlantAttributesItem;
    private cellValueClass = cellValueClass;
    private tableCellTextFormat = tableCellTextFormat;

    private readonly columnNameMap = {
        'MTP-1': 'spendUntilEndOfYear',
        'MTP': 'forecast',
        'previousYears': 'forecast',
        'MTP+1': 'new1',
        'MTP+2': 'new2',
        'MTP+3': 'new3',
        'ff': 'after',
        'total': 'total'
    } as const;

    private get hasDataFromMtp(): boolean {
        return this.iybrData?.budgetRequests[0].planning !== undefined;
    }

    private get compensationDataTotals(): CompensationTableTotals {
        let mtpYearPlus1Total = 0;
        let mtpYearPlus2Total = 0;
        let mtpYearPlus3Total = 0;

        if (this.iybrData) {
            Object.values(this.iybrData?.budgetCompensations).forEach(budgetCompensation => {
                mtpYearPlus1Total += budgetCompensation.original1 - zeroIfNaN(budgetCompensation.yearPlus1);
                mtpYearPlus2Total += budgetCompensation.original2 - zeroIfNaN(budgetCompensation.yearPlus2);
                mtpYearPlus3Total += budgetCompensation.original3 - zeroIfNaN(budgetCompensation.yearPlus3);
            });
        }

        const totalCompensation = formatToDecimals(mtpYearPlus1Total + mtpYearPlus2Total + mtpYearPlus3Total, 3);
        const hasCompensationEntries = this.iybrData?.budgetCompensations.length > 0;

        mtpYearPlus1Total = formatToDecimals(mtpYearPlus1Total, 3);
        mtpYearPlus2Total = formatToDecimals(mtpYearPlus2Total, 3);
        mtpYearPlus3Total = formatToDecimals(mtpYearPlus3Total, 3);

        return {
            hasCompensationEntries,
            mtpYearPlus1Total,
            mtpYearPlus2Total,
            mtpYearPlus3Total,
            totalCompensation
        };
    }

    private get tableOptions(): PuiTableVendorOptions {
        const mtpYear = this.iybrData?.budgetRequestsMiniFinancialDecisions[0].year ?? 0;

        const sumBudgetRequestData = (field: string): SumBudgetRequestData => {
            const approved = this.iybrData?.budgetRequests
                .filter(budgetRequest => budgetRequest.planning?.mtpStatus === MtpStatus.APPROVED)
                .map(budgetRequest => budgetRequest.planning[field])
                .reduce((previous, current) => previous + current, 0);

            const pending = this.iybrData?.budgetRequests
                .filter(budgetRequest => budgetRequest.planning && budgetRequest.planning?.mtpStatus !== MtpStatus.APPROVED)
                .map(budgetRequest => budgetRequest.planning[field])
                .reduce((previous, current) => previous + current, 0);

            return {
                approved: approved,
                pending: pending,
                total: approved + pending
            };
        };

        const budgetRequestsMtpStatuses = this.iybrData?.budgetRequests.map(br => br.planning?.mtpStatus);
        let budgetInMtpStatus = '';

        if (checkIfAllArrayItemsAreEqualTo(budgetRequestsMtpStatuses, MtpStatus.APPROVED)) {
            budgetInMtpStatus = '(Approved)';
        } else if (checkIfAllArrayItemsAreEqualTo(budgetRequestsMtpStatuses, MtpStatus.FLAGGED)) {
            budgetInMtpStatus = '(Flagged)';
        }

        const isNonProjectType = this.iybrData?.projectType.toString() === ProjectTypes.NonProject;

        const columns = {
            'title': `Currency: ${this.plantData?.country.currency.code}`,
            'MTP-1': `${mtpYear - 1}`,
            'MTP': `${mtpYear}`,
            'previousYears': `Before ${mtpYear + 1}`,
            'MTP+1': `${mtpYear + 1}`,
            'MTP+2': `${mtpYear + 2}`,
            'MTP+3': `${mtpYear + 3}`,
            'ff': 'FF',
            'total': 'Total'
        };

        const hiddenColumns = ['MTP-1', 'MTP'];

        const shownColumns = Object.keys(columns)
            .filter(key => !hiddenColumns.includes(key))
            .map(key => columns[key]);

        const budgetInMtpRow = {
            [columns['title']]: `Budget in MTP ${budgetInMtpStatus}`,
            [columns['MTP-1']]: sumBudgetRequestData('spendUntilEndOfYear').total,
            [columns['MTP']]: sumBudgetRequestData('forecast').total,
            [columns['previousYears']]: sumBudgetRequestData('spendUntilEndOfYear').total + sumBudgetRequestData('forecast').total,
            [columns['MTP+1']]: sumBudgetRequestData('new1').total,
            [columns['MTP+2']]: sumBudgetRequestData('new2').total,
            [columns['MTP+3']]: sumBudgetRequestData('new3').total,
            [columns['ff']]: sumBudgetRequestData('after').total,
            [columns['total']]: sumBudgetRequestData('total').total
        };

        let budgetRequiredTotal = 0;
        let previousYearsTotal = 0;
        if (isNonProjectType) {
            budgetRequiredTotal = this.iybrData?.spentYearPlus1
                + this.iybrData?.spentYearPlus2 + this.iybrData?.spentYearPlus3;
        } else {
            budgetRequiredTotal = this.iybrData?.spentYearMinus1 + this.iybrData?.spentYear + this.iybrData?.spentYearPlus1
                + this.iybrData?.spentYearPlus2 + this.iybrData?.spentYearPlus3 + this.iybrData?.spentAfterYear;
            previousYearsTotal = this.iybrData?.spentYearMinus1 + this.iybrData?.spentYear;
        }

        const budgetRequiredRow = {
            [columns['title']]: 'Total Budget Required',
            [columns['MTP-1']]: this.iybrData?.spentYearMinus1,
            [columns['MTP']]: this.iybrData?.spentYear,
            [columns['previousYears']]: previousYearsTotal,
            [columns['MTP+1']]: this.iybrData?.spentYearPlus1,
            [columns['MTP+2']]: this.iybrData?.spentYearPlus2,
            [columns['MTP+3']]: this.iybrData?.spentYearPlus3,
            [columns['ff']]: this.iybrData?.spentAfterYear,
            [columns['total']]: budgetRequiredTotal
        };

        const compensationRowCellValue = (columnName: string): number => {
            return safeInvertedDifference(budgetInMtpRow[columns[columnName]], budgetRequiredRow[columns[columnName]]);
        };

        const compensationValues: Record<string, number> = {
            'MTP-1': compensationRowCellValue('MTP-1'),
            'MTP': compensationRowCellValue('MTP'),
            'previousYears': 0,
            'MTP+1': compensationRowCellValue('MTP+1'),
            'MTP+2': compensationRowCellValue('MTP+2'),
            'MTP+3': compensationRowCellValue('MTP+3'),
            'ff': compensationRowCellValue('ff')
        };

        let compensationTotal = compensationValues['MTP+1'] + compensationValues['MTP+2'] + compensationValues['MTP+3'];

        if (!isNonProjectType) {
            compensationTotal += compensationValues['ff'] + compensationValues['previousYears'];
        }

        const compensationRow = {
            [columns['title']]: 'Compensation Required',
            [columns['MTP-1']]: compensationValues['MTP-1'],
            [columns['MTP']]: compensationValues['MTP'],
            [columns['previousYears']]: compensationValues['previousYears'],
            [columns['MTP+1']]: compensationValues['MTP+1'],
            [columns['MTP+2']]: compensationValues['MTP+2'],
            [columns['MTP+3']]: compensationValues['MTP+3'],
            [columns['ff']]: compensationValues['ff'],
            [columns['total']]: compensationTotal
        };

        const toBeApprovedRowCellValue = (columnName: string): number => {
            return safeSum(compensationRow[columns[columnName]], sumBudgetRequestData(this.columnNameMap[columnName]).pending);
        };

        const toBeApprovedValues: Record<string, number> = {
            'MTP-1': toBeApprovedRowCellValue('MTP-1'),
            'MTP': toBeApprovedRowCellValue('MTP'),
            'previousYears': 0,
            'MTP+1': toBeApprovedRowCellValue('MTP+1'),
            'MTP+2': toBeApprovedRowCellValue('MTP+2'),
            'MTP+3': toBeApprovedRowCellValue('MTP+3'),
            'ff': toBeApprovedRowCellValue('ff')
        };

        let toBeApprovedTotal = toBeApprovedValues['MTP+1'] + toBeApprovedValues['MTP+2'] + toBeApprovedValues['MTP+3'];

        if (!isNonProjectType) {
            toBeApprovedTotal += toBeApprovedValues['ff'] + toBeApprovedValues['previousYears'];
        }

        const toBeApprovedRow = {
            [columns['title']]: 'Budget to be approved',
            [columns['MTP-1']]: toBeApprovedValues['MTP-1'],
            [columns['MTP']]: toBeApprovedValues['MTP'],
            [columns['previousYears']]: toBeApprovedValues['previousYears'],
            [columns['MTP+1']]: toBeApprovedValues['MTP+1'],
            [columns['MTP+2']]: toBeApprovedValues['MTP+2'],
            [columns['MTP+3']]: toBeApprovedValues['MTP+3'],
            [columns['ff']]: toBeApprovedValues['ff'],
            [columns['total']]: toBeApprovedTotal
        };

        const frontOptionalRows = [];
        const backOptionalRows = [];

        if (this.hasDataFromMtp) {
            frontOptionalRows.push(budgetInMtpRow);
        }

        if (this.compensationDataTotals.hasCompensationEntries) {
            const compensationRow = {
                [columns['title']]: 'Compensation Available',
                [columns['MTP+1']]: this.compensationDataTotals.mtpYearPlus1Total,
                [columns['MTP+2']]: this.compensationDataTotals.mtpYearPlus2Total,
                [columns['MTP+3']]: this.compensationDataTotals.mtpYearPlus3Total,
                [columns['ff']]: '-',
                [columns['total']]: this.compensationDataTotals.totalCompensation
            };

            backOptionalRows.push(compensationRow);
        }

        return {
            data: [
                ...frontOptionalRows,
                budgetRequiredRow,
                compensationRow,
                toBeApprovedRow,
                ...backOptionalRows
            ],
            options: {
                sortable: []
            },
            columns: Object.values(shownColumns)
        };
    }
}
