export interface Options {
    text: string;
    id: string;
    options: Options[];
}

export interface Config {
    placeholder: string;
    listPlaceholders: string[];
    options: Options[];
}

export class Plants {
    config: Config;
    labelScope: string;
    selectedId: string;
    maxVisibleListItems: number;

    constructor(config: Config, labelScope: string, selectedId: string, maxVisibleItems: number) {
        this.config = config;
        this.labelScope = labelScope;
        this.selectedId = selectedId;
        this.maxVisibleListItems = maxVisibleItems;
    }
}
