var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.hasPermission ? _c('div', {
    staticClass: "rates-dashboard"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('pui-headline', {
    staticClass: "rates-dashboard__title",
    attrs: {
      "type": "h3"
    }
  }, [_vm._v(" Rates Dashboard ")]), _c('pui-tabs', {
    attrs: {
      "selected": _vm.selectedTabIndex
    },
    on: {
      "changed": _vm.onTabChanged
    }
  }, [_c('pui-tab', {
    attrs: {
      "title": "Currency Rates"
    }
  }, [_c('currency-rates-table')], 1), _c('pui-tab', {
    attrs: {
      "title": "Hurdle Rates"
    }
  }, [_c('hurdle-rates-table')], 1)], 1)], 1)]) : _c('div', [_c('div', {
    staticClass: "container-fluid"
  }, [_c('access-denied-modal', {
    on: {
      "clickedcanceleditstate": _vm.onClickCancel
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }