import { format } from 'date-fns';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { EstimatedPrioritization } from '~/models/services/minifd';
import { MasterService } from '~/services/master-service';
import { Data } from '~/utils';

@Component({})
export default class EstimationComponent extends Vue {
    private data: Data = Data.Instance;
    private loading = false;

    private estimatedPrioritization: EstimatedPrioritization = null;

    private readonly pageLayout = {
        FULL_ROW: { s: 12, m: 12, l: 12, xl: 12 },
        TWO_THIRDS_ROW: { s: 12, m: 12, l: 8, xl: 8 },
        HALF_ROW: { s: 12, m: 12, l: 6, xl: 6 },
        THIRD_ROW: { s: 12, m: 12, l: 4, xl: 4 }
    };

    @Prop()
    private uniqueId: string;

    private get formatDate(): string {
        return this.estimatedPrioritization?.dateOfDraftPrioritizationRun
            ? format(new Date(this.estimatedPrioritization?.dateOfDraftPrioritizationRun), 'yyyy-MM-dd')
            : '-';
    }

    private async mounted(): Promise<void> {
        this.loading = true;
        await this.getEstimatedPrioritization();
        this.loading = false;
    }

    private async getEstimatedPrioritization(): Promise<void> {
        this.estimatedPrioritization = await MasterService.instance.miniFdService.getEstimatedPrioritization(this.uniqueId);
    }
    
    private async onTriggerRunEstimation(): Promise<void> {
        this.loading = true;

        try {
            await MasterService.instance.miniFdService.runEstimatedPrioritization(this.uniqueId);
            await this.getEstimatedPrioritization();
        } catch (error) {
            this.$pui.toast({
                type: 'error',
                title: 'An error occurred when attempting to run estimated prioritization.',
                copy: error?.response?.data?.errors?.[0],
            })
        } finally {
            this.loading = false;
        }
    }
}
