import Component from 'vue-class-component';

// Register the router hooks with their names
Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
    'beforeRouteUpdate',
    'afterEach',
    'beforeUpdate',
    'beforeCreate', // for vue-router 2.2+
]);