










import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';

interface StatusCell extends ICellRendererParams {
    color: string,
}

@Component({})
export default class StatusCellComponent extends Vue {
    private params?: StatusCell;

    private get title(): string {
        return this.params.value;
    }

    private get color(): string | undefined {
        return this.params?.color;
    }
}
