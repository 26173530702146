var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('pui-form-input-field', {
    staticClass: "table-search",
    attrs: {
      "trailing-icon-is-clickable": true,
      "placeholder-text": "Search by...",
      "trailing-icon-name": "search"
    },
    on: {
      "trailing-icon:click": _vm.filterByKeyword
    },
    nativeOn: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.filterByKeyword.apply(null, arguments);
      }
    },
    model: {
      value: _vm.searchTerm,
      callback: function ($$v) {
        _vm.searchTerm = $$v;
      },
      expression: "searchTerm"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }