import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { Data, User } from '~/utils';
import LinkedBudgetRequestViewContent from '~/components/project-portfolio/linked-budget-request-view/linked-budget-request-view-content/linked-budget-request-view-content.vue';

@Component({
    components: {
        LinkedBudgetRequestViewContent,
    }
})
export default class LinkedBudgetRequestViewComponent extends Vue {
    private userPermissions = <any>[];
    private isAccessDeniedModalActive: boolean = false;
    private data: Data = Data.Instance;

    @Prop()
    private id: string;

    async created() {
        this.userPermissions = await User.getPerm();
        if (!this.hasPermission) {
            $('#accessDeniedModal').modal('show');
            this.isAccessDeniedModalActive = true;
        }
    }

    private get hasPermission(): boolean {
        return this.userPermissions.includes('CombinedBudgetEdit');
    }

    private onClickCancel(value) {
        this.isAccessDeniedModalActive = value;
    }
}
