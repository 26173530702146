import Vue from 'vue';
import VueRouter from 'vue-router';
import HomeComponent from '~/components/home/home.vue';
import './register-hooks';
import BudgetRequestsComponent from '~/components/budget-requests/budget-requests.vue';
import BudgetRequestComponent from '~/components/budget-request/budget-request.vue';
import DetailsPageComponent from '~/components/details-page/details-page.vue';
import OverviewComponent from '~/components/overview/overview.vue';
import ActivityTrackingComponent from '~/components/activity-tracking/activity-tracking.vue';
import AdminComponent from '~/components/admin/admin.vue';
import ReportingComponent from '~/components/reporting/reporting.vue';
import MtpPeriodsComponent from '~/components/admin-periods/mtp-periods/mtp-periods.vue';
import CrPeriodsComponent from '~/components/admin-periods/cr-periods/cr-periods.vue';
import InYearBudgetRequestsComponent from '~/components/in-year-budget-requests/in-year-budget-requests.vue';
import CreateInYearBudgetRequestComponent from '~/components/in-year-budget-requests/create-in-year-budget-request/create-in-year-budget-request.vue';
import ViewInYearBudgetRequestComponent from '~/components/in-year-budget-requests/view-in-year-budget-request/view-in-year-budget-request.vue';
import EditInYearBudgetRequestComponent from '~/components/in-year-budget-requests/edit-in-year-budget-request/edit-in-year-budget-request.vue';
import FocusTopicsComponent from '~/components/focus-topics/focus-topics.vue';
import LivePmlComponent from '~/components/live-pml/live-pml.vue';
import ProjectPortfolioComponent from '~/components/project-portfolio/project-portfolio.vue';
import LinkedBudgetRequestViewComponent from '~/components/project-portfolio/linked-budget-request-view/linked-budget-request-view.vue';
import LinkedBudgetRequestEditComponent from '~/components/project-portfolio/linked-budget-request-edit/linked-budget-request-edit.vue';
import RatesDashboardComponent from '~/components/rates-dashboard/rates-dashboard.vue';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    routes: [
        { path: '/', component: HomeComponent, name: 'home', meta: { requiresAuth: true } },
        { path: '/overview', component: OverviewComponent, name: 'overview', meta: { requiresAuth: true } },
        {
            path: '/budget-requests',
            component: BudgetRequestsComponent,
            name: 'budgetRequests',
            meta: { requiresAuth: true }
        },
        {
            path: '/budget-request',
            component: BudgetRequestComponent,
            name: 'budgetRequest',
            meta: { requiresAuth: true }
        },
        { path: '/details-page', component: DetailsPageComponent, name: 'detailsPage' },
        { path: '/admin', component: AdminComponent, name: 'admin', meta: { requiresAuth: true } },
        { path: '/activity-tracking', component: ActivityTrackingComponent, name: 'activityTracking' },
        { path: '/reporting', component: ReportingComponent, name: 'reporting', meta: { requiresAuth: true } },
        { path: '/live-pml', component: LivePmlComponent, name: 'live-pml', meta: { requiresAuth: true } },
        { path: '/mtp-periods', component: MtpPeriodsComponent, name: 'mtpPeriods', meta: { requiresAuth: true } },
        { path: '/cr-periods', component: CrPeriodsComponent, name: 'crPeriods', meta: { requiresAuth: true } },
        { path: '/focus-topics', component: FocusTopicsComponent, name: 'focusTopics', meta: { requiresAuth: true } },
        { path: '/rates-dashboard', component: RatesDashboardComponent, name: 'ratesDashboard', meta: { requiresAuth: true } },
        {
            path: '/in-year-budget-requests',
            component: InYearBudgetRequestsComponent,
            name: 'inYearBudgetRequests',
            meta: { requiresAuth: true }
        },
        {
            path: '/create-in-year-budget-request',
            component: CreateInYearBudgetRequestComponent,
            name: 'createInYearBudgetRequest',
            meta: { requiresAuth: true }
        },
        {
            path: '/view-in-year-budget-request',
            component: ViewInYearBudgetRequestComponent,
            name: 'viewInYearBudgetRequest',
            meta: { requiresAuth: true }
        },
        {
            path: '/edit-in-year-budget-request',
            component: EditInYearBudgetRequestComponent,
            name: 'editInYearBudgetRequest',
            meta: { requiresAuth: true }
        },
        {
            path: '/project-portfolio',
            component: ProjectPortfolioComponent,
            name: 'projectPortfolio',
            meta: { requiresAuth: true }
        },
        {
            path: '/project-portfolio/:id/view',
            component: LinkedBudgetRequestViewComponent,
            name: 'linkedBudgetRequestView',
            meta: { requiresAuth: true },
            props: true,
        },
        {
            path: '/project-portfolio/:id/edit',
            component: LinkedBudgetRequestEditComponent,
            name: 'linkedBudgetRequestEdit',
            meta: { requiresAuth: true },
            props: true,
        },
    ]
});

export const Router = router;
