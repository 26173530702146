import { Connection } from '~/utils';
import { Application, GetUseCaseResponse } from '~/models/services/app';
import { ApiResponse } from '~/models/services/common';
import Vue from 'vue';

export class AppService {
    private readonly api: Connection = new Connection(Vue.prototype.$sdk.$http.$core.metaData.request.api);

    public async getApps(): Promise<Application[]> {
        return (await this.api.get<ApiResponse<GetUseCaseResponse>>('use-cases')).data.result.items;
    }
}
