import Component from 'vue-class-component';
import Vue from 'vue';
import { Data } from '~/utils';

@Component({})
export default class MasterDataLoaderErrorComponent extends Vue {
    private data = Data.Instance;

    private retryLoadingMasterData(): void {
        this.data.retryLoadFleetHierarchyFromServer().then();
    }
}
