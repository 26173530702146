import { render, staticRenderFns } from "./second-delete-bulk-modal.vue?vue&type=template&id=4d05467a&"
import script from "./second-delete-bulk-modal.ts?vue&type=script&lang=ts&"
export * from "./second-delete-bulk-modal.ts?vue&type=script&lang=ts&"
import style0 from "./second-delete-bulk-modal.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports