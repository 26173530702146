var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-toast', {
    attrs: {
      "id": "toast-error",
      "auto-hide-delay": "20000",
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center toast-error-import"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [_vm.errorMessage ? [_c('div', {
    staticStyle: {
      "padding-right": "20px"
    }
  }, [_c('icon', {
    staticClass: "icon-toast text-center",
    staticStyle: {
      "color": "#F21100"
    },
    attrs: {
      "name": "times"
    }
  })], 1)] : _vm._e(), _vm.errorMessage ? _c('div', [!_vm.errorMessage.isUploaded ? _c('span', [_vm._v(_vm._s(_vm.errorMessage))]) : _vm._e()]) : _vm._e(), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideToast();
      }
    }
  }, [_vm._v(" Dismiss ")])], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }