var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('navbar-app-drawer', {
    ref: "appDrawer"
  }), _c('adam-header-bar', {
    class: [{
      'pml-navbar--dev': _vm.currentEnvironment === 'dev' || _vm.currentEnvironment === 'development',
      'pml-navbar--uat': _vm.currentEnvironment === 'uat',
      'pml-navbar--prd': _vm.currentEnvironment === 'prd'
    }, 'pml-navbar'],
    attrs: {
      "fixed": ""
    },
    domProps: {
      "notifications": _vm.headerNotifications
    },
    on: {
      "apps-click": _vm.toggleDrawer,
      "notification-click": _vm.redirectToAppStore,
      "notification-approve-click": _vm.handleApprove,
      "notification-reject-click": _vm.handleReject,
      "notification-redirect-click": _vm.handleRedirect,
      "view-history-click": _vm.redirectToAppStore
    }
  }, [_c('span', {
    attrs: {
      "slot": "headerContent"
    },
    slot: "headerContent"
  }, [_c('div', {
    staticClass: "coode-logo-white"
  }, [_c('img', {
    attrs: {
      "alt": "COODE Logo",
      "src": "assets/img/logo-coode-white.svg"
    }
  })]), _c('span', {
    staticClass: "header-separator"
  }), _c('span', [_vm._v("PML (Project and Maintenance List)")])]), _c('span', {
    attrs: {
      "slot": "profileDetails"
    },
    slot: "profileDetails"
  }, [_c('navbar-user-actions')], 1), _c('span', {
    staticClass: "notification-header",
    attrs: {
      "slot": "notificationsHeader"
    },
    slot: "notificationsHeader"
  }, [_c('pui-context-menu', {
    staticClass: "notification-header__context-menu"
  }, [_c('pui-context-menu-item', {
    key: 0,
    staticClass: "notification-header__context-menu__item",
    attrs: {
      "disabled": false,
      "label": "Clear Notifications"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.clearAllNotifications.apply(null, arguments);
      }
    }
  }, [_c('pui-icon', {
    staticClass: "notification-header__context-menu__item--icon",
    attrs: {
      "slot": "default",
      "icon-name": "delete",
      "size": "48px"
    },
    slot: "default"
  })], 1), _c('pui-context-menu-item', {
    key: 1,
    staticClass: "notification-header__context-menu__item",
    attrs: {
      "disabled": true,
      "label": "Settings"
    }
  }, [_c('pui-icon', {
    staticClass: "notification-header__context-menu__item--icon",
    attrs: {
      "slot": "default",
      "icon-name": "settings",
      "size": "48px"
    },
    slot: "default"
  })], 1), _c('pui-context-menu-item', {
    key: 2,
    staticClass: "notification-header__context-menu__item",
    attrs: {
      "disabled": false,
      "label": "Open Notifications"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.redirectToAppStore.apply(null, arguments);
      }
    }
  }, [_c('pui-icon', {
    staticClass: "notification-header__context-menu__item--icon",
    attrs: {
      "slot": "default",
      "icon-name": "folder-open",
      "size": "48px"
    },
    slot: "default"
  })], 1)], 1)], 1)]), _c('navmenu')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }