import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import '~/components/home/home.scss';
import { User } from '~/utils';
import { Period, PeriodTypes } from '~/utils/interfaces';
import { Permission } from '~/utils/permission';
import { CrService } from '~/services/cr-service';
import AddNewPeriodComponent from '../add-new-period/add-new-period.vue';
import ViewPeriodsComponent from '../view-periods/view-periods.vue';

@Component({
    components: {
        'add-new-period': AddNewPeriodComponent,
        'view-periods': ViewPeriodsComponent
    }
})

export default class CrPeriodsComponent extends Vue {
    public CrService: CrService = new CrService();
    public periods: Period[] = [];
    private userPermissions = <any>[];
    private isAccessDeniedModalActive: boolean = false;
    private loading: boolean = false;
    private error: string = '';
    private periodType = PeriodTypes.CR;
    private editId = null;

    private get hasPermissionToEdit(): boolean {
        return this.userPermissions.includes(Permission.MTP_PERIODS_WRITE);
    }

    async created() {
        this.userPermissions = await User.getPerm();
        if (this.hasPermissionToEdit) {
            await this.load();
        } else {
            $('#accessDeniedModal').modal('show');
            this.isAccessDeniedModalActive = true;
        }
    }

    beforeRouteLeave(to, from, next) {
        if (this.isAccessDeniedModalActive) {
            next(false);
        } else {
            next(true);
        }
    }

    private onClickCancel(value) {
        this.isAccessDeniedModalActive = value;
    }

    private hideToast() {
        this.$bvToast.hide('toast-success');
    }

    private async load() {
        this.loading = true;
        try {
            this.periods = await this.CrService.getCrPeriods();
        } catch (error) {
            if (error && this.$route.path !== '/in-year-budget-requests') {
                await this.$router.push({ path: '/in-year-budget-requests' });
            }
        }
        this.loading = false;
    }

    private handleLoadingChanged(loading) {
        this.loading = loading;
    }

    private async handlePeriodSaved() {
        await this.load();
    }

    private handleErrorChanged(error: string) {
        this.error = error;
    }

    private handleEditIdChanged(id: string) {
        this.editId = id;
    }
}
