import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import '~/components/home/home.scss';
import ChartBudgetRequestsComponent from '../budget-requests/chart-budget-requests/chart-budget-requests.vue';
import { Data, User } from '~/utils';
import FilesBudgetRequestsComponent from '../budget-requests/files-budget-requests/files-budget-requests.vue';
import { MasterService } from '~/services/master-service';

type ChartSeries = {
    title: string;
    series: number[];
};

enum OverviewDataType {
    BUDGET_REQUEST = 1,
    CHANGE_REQUEST = 2,
}

@Component({
    components: {
        'chart-budget-requests': ChartBudgetRequestsComponent,
        'files-budget-request': FilesBudgetRequestsComponent
    }
})
export default class OverviewComponent extends Vue {
    private readonly STATUS_CONFIG = {
        [OverviewDataType.BUDGET_REQUEST]: [
            {
                status: 1,
                color: '#d6d6d6',
                label: 'Draft',
            },
            {
                status: 2,
                color: '#607d8b',
                label: 'Submitted',
            },
            {
                status: 3,
                color: '#ffab00',
                label: 'Flagged',
            },
            {
                status: 4,
                color: '#19c142',
                label: 'Reviewed',
            },
            {
                status: 5,
                color: '#0078dc',
                label: 'Approved',
            },
        ],
        [OverviewDataType.CHANGE_REQUEST]: [
            {
                status: 1,
                color: '#949597',
                label: 'Draft',
            },
            {
                status: 3,
                color: '#9ec15b',
                label: 'In Review',
            },
            {
                status: 4,
                color: '#3eaaa5',
                label: 'Approved',
            },
            {
                status: 5,
                color: '#f7bf70',
                label: 'Rejected',
            },
        ],
    } as const;

    private readonly OVERVIEW_DATA_TYPE_CONFIG = {
        [OverviewDataType.BUDGET_REQUEST]: {
            faucetKey: 'mtpStatus',
        },
        [OverviewDataType.CHANGE_REQUEST]: {
            faucetKey: 'miniFdStatus',
        },
    } as const;

    private currentOverviewType = OverviewDataType.BUDGET_REQUEST;
    private userPermissions = <any>[];
    private countries = <any>[];
    private selectedCountry = null;
    private loading: boolean = false;
    private data: Data = Data.Instance;
    private currentYear = new Date(Date.now()).getFullYear();
    private mtpYear = Data.Instance.currentMtpPeriod?.year ?? this.currentYear;
    private miniFdYear = 0;
    private selectedMtpFilter = `${this.mtpYear}-0`;
    private years = null;
    private isAccessDeniedModalActive: boolean = false;

    private chartSeries: ChartSeries[] = [];

    private get chartOptions() {
        return {
            colors: this.STATUS_CONFIG[this.currentOverviewType].map(e => e.color),
            labels: this.STATUS_CONFIG[this.currentOverviewType].map(e => e.label),
            states: {
                normal: {
                    filter: {
                        type: 'none',
                        value: 0,
                    }
                },
                hover: {
                    filter: {
                        type: 'none',
                        value: 0.15,
                    }
                },
                active: {
                    allowMultipleDataPointsSelection: false,
                    filter: {
                        type: 'none',
                        value: 0.35
                    }
                }
            },
            dataLabels:
                {
                    enabled: false,
                },
            plotOptions: {
                pie: {
                    expandOnClick: false,
                    donut: {
                        labels: {
                            show: true,
                            name: {
                                show: true,
                            },
                            value: {
                                show: true,
                            },
                            total: {
                                show: true
                            }
                        }
                    }
                }
            },
            legend: {
                formatter: function (seriesName, opts) {
                    return [opts.w.globals.series[opts.seriesIndex] + ' - ' + seriesName];
                },
                fontSize: '16px',
                offsetX: -10,
                offsetY: 10

            },
            stroke: {
                show: true,
                curve: 'smooth',
                width: 0,
            },
        };
    }

    async created() {
        this.userPermissions = await User.getPerm();
    }

    async mounted() {
        this.loading = true;
        await this.getCountries();
        await this.fetchChartData();
        if (this.data.constants) {
            this.years = this.data.constants.mtpFilters;
            this.years = this.years.filter(function (e) {
                return e.year !== 0;
            });
            this.years.sort(function (a, b) {
                return b.year - a.year;
            });
        }
        this.loading = false;

        if (!this.userPermissions.includes('OverviewPageView')) {
            $('#accessDeniedModal').modal('show');
            this.isAccessDeniedModalActive = true;
        }

    }

    beforeRouteLeave(to, from, next) {
        if (this.isAccessDeniedModalActive) {
            next(false);
        } else {
            next(true);
        }
    }

    private async getCountries() {
        this.countries = await MasterService.instance.chartService.getCountries();
    }

    private async changeMtpYear() {
        this.loading = true;

        this.mtpYear = parseInt(this.selectedMtpFilter.split('-')[0]);
        this.miniFdYear = parseInt(this.selectedMtpFilter.split('-')[1]);
        await this.fetchChartData();

        this.loading = false;
    }

    private async fetchChartData() {
        const chartData = await MasterService.instance.chartService.getOverviewBudgetDistribution(this.selectedCountry, this.mtpYear, this.miniFdYear);

        this.chartSeries = [];
        this.currentOverviewType = chartData.isChangeRequestInstance ? OverviewDataType.CHANGE_REQUEST : OverviewDataType.BUDGET_REQUEST;

        const globalSeries: number[] = [];
        this.STATUS_CONFIG[this.currentOverviewType].forEach(e => {
            globalSeries.push(chartData.globalMtpStatusDistributionResponse.budgetRequests.find(item => item[this.OVERVIEW_DATA_TYPE_CONFIG[this.currentOverviewType].faucetKey] === e.status)?.counter ?? 0);
        });

        this.chartSeries.push({
            title: 'Global',
            series: globalSeries,
        });

        chartData.technologyMtpStatusDistributionResponses.forEach(technology => {
            const currentSeries: number[] = [];

            this.STATUS_CONFIG[this.currentOverviewType].forEach(e => {
                currentSeries.push(technology.budgetRequests.find(item => item[this.OVERVIEW_DATA_TYPE_CONFIG[this.currentOverviewType].faucetKey] === e.status)?.counter ?? 0);
            });

            this.chartSeries.push({
                title: technology.technologyName,
                series: currentSeries,
            });
        });
    }

    private async changeCountry() {
        this.loading = true;
        await this.fetchChartData();
        this.loading = false;
    }

    private onClickCancel(value) {
        this.isAccessDeniedModalActive = value;
    }
}
