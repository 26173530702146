import { Component, Vue } from 'vue-property-decorator';

@Component({})
export class Debouncer extends Vue {
    private debouncers = new Map<string, number>();

    protected debounce(id: string, callback: () => any, timeout: number): void {
        if (this.debouncers.has(id)) {
            clearTimeout(this.debouncers.get(id));
        }

        const timeoutId = Number(setTimeout(callback, timeout));
        this.debouncers.set(id, timeoutId);
    }

    protected clearDebouncer(id: string): void {
        if (!this.debouncers.has(id)) {
            return;
        }

        clearTimeout(this.debouncers.get(id));
        this.debouncers.delete(id);
    }
}
