import { MiniFdStatus } from '~/models/services/minifd';

export enum StatusColors {
    approved = '#3eaaa5',
    rejected = '#f7bf70',
    inReview = '#9ec15b',
    submitted = '#2975cd',
    draft = '#949597'
}

export const ChangeRequestStatusColors = {
    [MiniFdStatus.APPROVED]: StatusColors.approved,
    [MiniFdStatus.SUBMITTED]: StatusColors.submitted,
    [MiniFdStatus.DRAFT]: StatusColors.draft,
    [MiniFdStatus.IN_REVIEW]: StatusColors.inReview,
    [MiniFdStatus.REJECTED]: StatusColors.rejected,
} as const;
