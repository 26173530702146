var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.userPermissions.includes('BulkMoveBudgetRequestsToCurrentMtp') || _vm.userPermissions.includes('BulkDeleteInactiveBudgetRequests') ? _c('div', {
    staticClass: "admin"
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "can-cancel": true
    },
    on: {
      "update:active": function ($event) {
        _vm.loading = $event;
      }
    }
  }), _vm.data.exportPrioritization === true ? _c('loading-spinner') : _vm._e(), _c('b-toast', {
    attrs: {
      "id": "delete-inactive-toast",
      "auto-hide-delay": "20000",
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center max-width-toast-update-mtp"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [_c('div', [_c('icon', {
    staticClass: "icon-toast text-center",
    staticStyle: {
      "min-width": "40px"
    },
    attrs: {
      "name": "check"
    }
  })], 1), _c('div', [_vm.deletedNumber === 0 ? _c('span', [_vm._v(" No inactive budget request found. ")]) : _vm._e(), _vm.deletedNumber === 1 ? _c('span', [_vm._v(" " + _vm._s(_vm.deletedNumber) + " inactive budget request deleted successfully. ")]) : _vm._e(), _vm.deletedNumber > 1 ? _c('span', [_vm._v(" " + _vm._s(_vm.deletedNumber) + " inactive budget requests deleted successfully. ")]) : _vm._e()]), _c('button', {
    staticClass: "btn btn-outline-primary ml-3",
    on: {
      "click": function ($event) {
        return _vm.hideToast();
      }
    }
  }, [_vm._v(" Dismiss ")])])]), _c('b-toast', {
    attrs: {
      "id": "bulk-move-toast",
      "auto-hide-delay": "20000",
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center max-width-toast-update-mtp"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [_c('div', [_c('icon', {
    staticClass: "icon-toast text-center",
    staticStyle: {
      "min-width": "40px"
    },
    attrs: {
      "name": "check"
    }
  })], 1), _c('div', [_vm.movedNumber === 0 ? _c('span', [_vm._v(" No budget request found in the previous MTP. ")]) : _vm._e(), _vm.movedNumber === 1 ? _c('span', [_vm._v(" Successfully added " + _vm._s(_vm.movedNumber) + " budget request to current MTP. ")]) : _vm._e(), _vm.movedNumber > 1 ? _c('span', [_vm._v(" Successfully added " + _vm._s(_vm.movedNumber) + " budget requests to current MTP. ")]) : _vm._e()]), _c('button', {
    staticClass: "btn btn-outline-primary ml-3",
    on: {
      "click": function ($event) {
        return _vm.hideToast();
      }
    }
  }, [_vm._v(" Dismiss ")])])]), _c('b-toast', {
    attrs: {
      "id": "toast-exception",
      "auto-hide-delay": "20000",
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center max-width-toast-update-mtp"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [_c('div', [_c('icon', {
    staticClass: "icon-toast text-center",
    staticStyle: {
      "color": "#F21100"
    },
    attrs: {
      "name": "times"
    }
  })], 1), _c('div', [_c('span', [_vm._v("Something bad happened.")])]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideToast();
      }
    }
  }, [_vm._v(" Dismiss ")])])]), _c('b-toast', {
    attrs: {
      "id": "toast-error-import",
      "auto-hide-delay": _vm.errorMessage && _vm.errorMessage.requestIsValid ? 20000 : 600000,
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center toast-error-import"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [_vm.errorMessage ? [_vm.errorMessage.requestIsValid ? _c('div', [_c('icon', {
    staticClass: "icon-toast text-center",
    attrs: {
      "name": "check"
    }
  })], 1) : _vm._e(), !_vm.errorMessage.requestIsValid ? _c('div', [_c('icon', {
    staticClass: "icon-toast text-center",
    staticStyle: {
      "color": "#F21100"
    },
    attrs: {
      "name": "times"
    }
  })], 1) : _vm._e()] : _vm._e(), _vm.errorMessage ? _c('div', [_c('span', [_vm._v(_vm._s(_vm.errorMessage.text))]), !_vm.errorMessage.requestIsValid ? _c('span', {
    staticClass: "pointerLink",
    on: {
      "click": function ($event) {
        return _vm.showModalError();
      }
    }
  }, [_vm._v("Please click here to check all the errors ")]) : _vm._e()]) : _vm._e(), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideToast();
      }
    }
  }, [_vm._v(" Dismiss ")])], 2)]), _c('div', {
    staticClass: "container mt-4"
  }, [_c('first-move-bulk-modal'), _c('second-move-bulk-modal', {
    on: {
      "confirmBulkMove": _vm.moveBulk
    }
  }), _c('first-delete-bulk-modal'), _c('second-delete-bulk-modal', {
    on: {
      "confirmBulkDelete": _vm.deleteBulk
    }
  }), _c('export-prioritization-modal', {
    on: {
      "startExport": _vm.exportPrioritizationToExcel
    }
  }), _c('error-modal', {
    attrs: {
      "error-import": _vm.errorImport
    }
  }), _c('div', {
    staticClass: "row"
  }, [_vm._m(0), _c('div', {
    staticClass: "col-12"
  }, [_vm.userPermissions.includes('BulkMoveBudgetRequestsToCurrentMtp') ? _c('button', {
    staticClass: "btn btn-outline-primary mt-4",
    on: {
      "click": function ($event) {
        return _vm.openBulkMoveModal();
      }
    }
  }, [_vm._v(" Bulk move to current MTP ")]) : _vm._e()]), _vm.userPermissions.includes('BulkMoveBudgetRequestsToCurrentMtp') ? _c('div', {
    staticClass: "col-12 pt-4"
  }, [_vm._m(1)]) : _vm._e(), _c('div', {
    staticClass: "col-12"
  }, [_vm.userPermissions.includes('BulkDeleteInactiveBudgetRequests') ? _c('button', {
    staticClass: "btn btn-outline-primary mt-4",
    attrs: {
      "disabled": _vm.isMtpPeriod
    },
    on: {
      "click": function ($event) {
        return _vm.openBulkDeleteModal();
      }
    }
  }, [_vm._v(" Bulk delete inactive budget requests ")]) : _vm._e()]), _vm.userPermissions.includes('BulkDeleteInactiveBudgetRequests') ? _c('div', {
    staticClass: "col-12 pt-4"
  }, [_vm._m(2)]) : _vm._e(), _c('div', {
    staticClass: "col-12"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary mt-4",
    on: {
      "click": function ($event) {
        return _vm.openExportPrioritizationModal();
      }
    }
  }, [_vm._v(" Export Project Prioritization Excel ")])]), _vm._m(3)])], 1)], 1) : _c('div', [_c('div', {
    staticClass: "container-fluid pt-3"
  }, [_c('access-denied-modal', {
    attrs: {
      "is-admin-page": true
    },
    on: {
      "goToBRPage": function ($event) {
        return _vm.goToBRPage();
      }
    }
  })], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('h3', [_vm._v("Admin options")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "color": "red"
    }
  }, [_c('i', [_vm._v("This functionality will move all existing budget requests from the previous MTP (eg. if current MTP is 2020 then it will move everything from MTP 2019 to MTP 2020). This action cannot be reverted. ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticStyle: {
      "color": "red"
    }
  }, [_c('i', [_vm._v("This functionality is available after the end date of MTP period and will delete all inactive budget requests from PML. This action cannot be reverted. ")])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12 pt-4"
  }, [_c('p', {
    staticStyle: {
      "color": "red"
    }
  }, [_c('i', [_vm._v("This functionality does the Project Prioritization process, update the columns \"Prioritization category\" and \"Prioritization rank\" for the budget requests and generate an Excel file with the Project Prioritization details. This action cannot be reverted. ")])])]);
}]

export { render, staticRenderFns }