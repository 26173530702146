import Component from 'vue-class-component';
import Vue from 'vue';
import { BreadcrumbLink } from '~/utils/interfaces';
import { MasterService } from '~/services/master-service';
import { Data, User } from '~/utils';
import moment from 'moment';
import CommentsCollapsibleComponent from './comments-collapsible/comments-collapsible.vue';
import ViewCostTableComponent
    from '~/components/in-year-budget-requests/view-in-year-budget-request/view-cost-table/view-cost-table.vue';
import ViewCompensationTableComponent
    from '~/components/in-year-budget-requests/view-in-year-budget-request/view-compensation-table/view-compensation-table.vue';
import ApprovalCollapsibleComponent from './approval-collapsible/approval-collapsible.vue';
import LinkedExternalIdsCollapsibleComponent
    from '~/components/in-year-budget-requests/linked-external-ids-collapsible/linked-external-ids-collapsible.vue';
import { DEPARTMENT_GROUP_NAMES } from '~/components/in-year-budget-requests/constants/department-group-names';
import ViewHistoryComponent
    from '~/components/in-year-budget-requests/view-in-year-budget-request/view-history/view-history.vue';
import { Watch } from 'vue-property-decorator';
import ViewGlobalIdsCollapsibleComponent
    from '~/components/in-year-budget-requests/view-in-year-budget-request/view-global-ids-collapsible/view-global-ids-collapsible.vue';
import {
    RequestOptions
} from '~/components/in-year-budget-requests/add-in-year-budget-requests-modal/add-in-year-budget-requests-modal';
import FilesChangeRequestComponent
    from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/files-change-request/files-change-request.vue';
import { GetMiniFdByGlobalIdResponse, MiniFdComment, MiniFdStatus, Stakeholder } from '~/models/services/minifd';
import { PlantAttributesItem } from '~/models/services/plants';
import ViewIybrActionButtonsComponent
    from '~/components/in-year-budget-requests/view-in-year-budget-request/view-iybr-action-buttons/view-iybr-action-buttons.vue';
import Tags from '~/components/focus-topics/tags/tags.vue';
import { TagsMode } from '~/models/services/tags';
import Estimation from '~/components/in-year-budget-requests/view-in-year-budget-request/estimation/estimation.vue';

enum DEPARTMENT_APPROVAL_STATE {
    SKIPPED = 1,
    PENDING = 2,
    CURRENT_USER_NEEDS_TO_APPROVE = 3,
    APPROVED = 4
}

enum MINI_FD_TYPE {
    MFD = 1,
    FD = 2,
    SFD = 3,
}

const REF_CONSTANTS = {
    ACTION_BUTTONS: 'action-buttons',
} as const;

@Component({
    components: {
        linkedExternalIdsCollapsible: LinkedExternalIdsCollapsibleComponent,
        commentsCollapsible: CommentsCollapsibleComponent,
        viewCostTable: ViewCostTableComponent,
        viewCompensationTable: ViewCompensationTableComponent,
        approvalCollapsible: ApprovalCollapsibleComponent,
        viewHistory: ViewHistoryComponent,
        viewGlobalIdsCollapsibleComponent: ViewGlobalIdsCollapsibleComponent,
        filesChangeRequest: FilesChangeRequestComponent,
        viewIybrActionButtons: ViewIybrActionButtonsComponent,
        Tags,
        Estimation,
    }
})
export default class ViewInYearBudgetRequestComponent extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;
    $refs!: {
        [REF_CONSTANTS.ACTION_BUTTONS]: ViewIybrActionButtonsComponent & {
            shouldDisplayEditBudgetCompensation: boolean;
        };
    };

    private readonly pageLayout = {
        FULL_ROW: { s: 12, m: 12, l: 12, xl: 12 },
        TWO_THIRDS_ROW: { s: 12, m: 12, l: 8, xl: 8 },
        HALF_ROW: { s: 12, m: 12, l: 6, xl: 6 },
        THIRD_ROW: { s: 12, m: 12, l: 4, xl: 4 }
    };
    private readonly departmentGroupNames = DEPARTMENT_GROUP_NAMES;
    private readonly FILES_COMPONENT_REF = 'filesChangeRequest';

    private iybrData?: GetMiniFdByGlobalIdResponse = null;
    private plantData?: PlantAttributesItem = null;
    private externalIds: string[] = [];
    private miniFdId: string = '';
    private workflowId: string = '';
    private miniFdComments: MiniFdComment[] = [];
    private isPartOfApprovalWorkFlow: boolean = false;
    private hasToReview: boolean = false;
    private currentUserKid: string = null;
    private contributorsKids: string[] = [];
    private readonly miniFdStatus = Data.Instance.constants.miniFdStatusesTypes;
    private selectedTab: number = 0;
    private isHistoryTabActive: boolean = null;
    private isFlaggedProject: boolean = false;

    private currentTagsMode: TagsMode = TagsMode.CR;
    private loading: boolean = false;
    private currentYear = new Date(Date.now()).getFullYear();

    private get isQueryDataChanged(): boolean {
        return this.$route.query.uniqueId !== this.iybrData.uniqueId.trim() || this.$route.query.history !== this.isHistoryTabActive.toString();
    }

    private get stakeholders(): Stakeholder[] {
        const stakeholders = this.sortStakeholdersByDepartment(this.iybrData?.miniFdStakeholders ?? []);

        return stakeholders;
    }

    private get nonReviewerStakeholders(): Stakeholder[] {
        return this.filterNonReviewerStakeholders(this.iybrData?.miniFdStakeholders ?? []);
    }

    private get breadcrumbLinks(): BreadcrumbLink[] {
        return [
            {
                label: 'Change Requests',
                href: '/in-year-budget-requests'
            },
            {
                label: this.iybrData?.projectName || '',
                href: '#'
            }
        ];
    }

    private get showEstimatedPrioritization(): boolean {
        return this.iybrData?.year === this.currentYear && this.iybrData?.status !== 4;
    }

    private get isTagsViewOnly(): boolean {
        return !this.$refs[REF_CONSTANTS.ACTION_BUTTONS]?.shouldDisplayEditBudgetCompensation;
    }

    async mounted(): Promise<void> {
        await this.loadData();
        this.changeTabs();
    }

    private async loadData(): Promise<void> {
        this.loading = true;
        await this.loadIybrData();
        await this.checkMiniFdStatus();
        this.loading = false;
    }

    private async loadIybrData(): Promise<void> {
        const uniqueId = this.$route.query.uniqueId as string;
        const miniFd = await MasterService.instance.miniFdService.getMiniFdByUniqueId(uniqueId);
        const user = await User.getUserData();

        this.currentUserKid = user.kid;
        this.iybrData = miniFd;
        this.miniFdId = miniFd.id;
        this.workflowId = miniFd.workflowInstanceId;
        this.miniFdComments = miniFd.miniFdComments;

        this.contributorsKids = (await MasterService.instance.miniFdService.getMiniFdContributors(miniFd.id)).contributorsKids;
        this.plantData = await MasterService.instance.plantsService.getPlants(miniFd.budgetRequests[0].plantSid.toString());

        this.externalIds = this.iybrData.externalIds.filter(externalId => externalId !== '');

        if (miniFd.requestType === parseInt(RequestOptions.NotReleased)) {
            this.isFlaggedProject = true;
        }
    }

    private async checkMiniFdStatus(): Promise<void> {
        const submittedStatus = this.miniFdStatus[2];
        const inReviewStatus = this.miniFdStatus[3];

        await this.checkIfUserIsPartOfApprovalWorkflow();
        if (this.miniFdStatus[this.iybrData.status] === submittedStatus
            || this.miniFdStatus[this.iybrData.status] === inReviewStatus) {
            this.checkIfCurrentUserHasToReview();
        } else {
            this.hasToReview = false;
        }
    }

    private async getListOfPeopleWhoArePartOfApprovalWorkflow(): Promise<string[]>  {
        let usersPartOfTheApprovalWorkflow: string[] = [];
        const stakeHolders = this.iybrData.miniFdStakeholders;

        if (stakeHolders) {
            stakeHolders.forEach(miniFdStakeHolder => usersPartOfTheApprovalWorkflow.push(miniFdStakeHolder.kId));
        }

        return usersPartOfTheApprovalWorkflow;
    }

    private async checkIfUserIsPartOfApprovalWorkflow(): Promise<void> {
        const user = await User.getUserData();
        const listOfPeopleWhoArePartOfApprovalWorkflow = await this.getListOfPeopleWhoArePartOfApprovalWorkflow();
        if (listOfPeopleWhoArePartOfApprovalWorkflow.indexOf(user.kid) > -1) {
            this.isPartOfApprovalWorkFlow = true;
        }
    }

    private checkIfCurrentUserHasToReview(): void {
        const plantManagerApprovalState = this.getApprovalStateForDepartment(this.departmentGroupNames.PLANT_MANAGER);
        const primaryDepartmentApprovalState = this.getApprovalStateForDepartment(this.departmentGroupNames.PRIMARY_DEPARTMENT, [plantManagerApprovalState]);
        const assetStrategyApprovalState = this.getApprovalStateForDepartment(this.departmentGroupNames.ASSET_STRATEGY, [primaryDepartmentApprovalState]);
        const assetOperationsApprovalState = this.getApprovalStateForDepartment(this.departmentGroupNames.ASSET_OPERATIONS, [primaryDepartmentApprovalState, assetStrategyApprovalState]);
        const assetManagementApprovalState = this.getApprovalStateForDepartment(this.departmentGroupNames.ASSET_MANAGEMENT, [primaryDepartmentApprovalState, assetStrategyApprovalState]);
        const businessControllingApprovalState = this.getApprovalStateForDepartment(this.departmentGroupNames.BUSINESS_CONTROLLING, [primaryDepartmentApprovalState, assetStrategyApprovalState, assetOperationsApprovalState, assetManagementApprovalState]);

        const approvalStates = [
            plantManagerApprovalState,
            primaryDepartmentApprovalState,
            assetStrategyApprovalState,
            assetOperationsApprovalState,
            assetManagementApprovalState,
            businessControllingApprovalState
        ];

        this.hasToReview = this.isPartOfApprovalWorkFlow && approvalStates.some(e => e === DEPARTMENT_APPROVAL_STATE.CURRENT_USER_NEEDS_TO_APPROVE);
    }

    private getApprovalStateForDepartment(departmentGroupName: string, dependentApprovalStates?: DEPARTMENT_APPROVAL_STATE[]): DEPARTMENT_APPROVAL_STATE {
        const stakeholder = this.iybrData.miniFdStakeholders.find(stakeholder => stakeholder.departmentGroupName === departmentGroupName);

        if (!stakeholder) {
            return DEPARTMENT_APPROVAL_STATE.SKIPPED;
        }

        if (stakeholder.approvalDate) {
            return DEPARTMENT_APPROVAL_STATE.APPROVED;
        }

        if (dependentApprovalStates && dependentApprovalStates.some(e => e === DEPARTMENT_APPROVAL_STATE.PENDING || e === DEPARTMENT_APPROVAL_STATE.CURRENT_USER_NEEDS_TO_APPROVE)) {
            return DEPARTMENT_APPROVAL_STATE.PENDING;
        }

        return stakeholder.kId === this.currentUserKid ? DEPARTMENT_APPROVAL_STATE.CURRENT_USER_NEEDS_TO_APPROVE : DEPARTMENT_APPROVAL_STATE.PENDING;
    }

    private formatTextForDisplay(text: string): string {
        if (!text || text === '') {
            return 'N/A';
        } else {
            return text;
        }
    }

    private formatDate(dateString?: string): string {
        if (dateString) {
            return moment(dateString).format('YYYY-MM-DD');
        } else {
            return '';
        }
    }

    private getValueForConstant(constantType: string, index?: string | number): string {
        if (!index) {
            return 'N/A';
        }

        const constantValue = Data.Instance.constants[constantType][index];

        if (!constantValue) {
            return 'Unknown';
        }

        return constantValue;
    }

    private handleTabChange(tabIndex: number): void {
        this.selectedTab = tabIndex;
    }

    private onBreadcrumbLinkClick(param: { href: string }): void {
        this.$router.push(param.href).then();
    }

    private async hasReviewedMiniFd(): Promise<void> {
        this.hasToReview = false;
        await this.loadIybrData();
        await this.checkMiniFdStatus();
    }

    @Watch('$route.query.history')
    private changeTabs(): void {
        this.isHistoryTabActive = this.$route.query.history === 'true';
        if (this.isHistoryTabActive) {
            this.goToHistory();
        } else {
            this.goToDetails();
        }
    }

    private goToHistory(): void {
        this.isHistoryTabActive = true;
        if (this.isQueryDataChanged) {
            this.$router.push({
                path: '/view-in-year-budget-request',
                query: {
                    uniqueId: this.iybrData.uniqueId.trim(),
                    history: this.isHistoryTabActive.toString()
                }
            });
        }
    }

    private goToDetails(): void {
        this.isHistoryTabActive = false;
        if (this.isQueryDataChanged) {
            this.$router.push({
                path: '/view-in-year-budget-request',
                query: {
                    uniqueId: this.iybrData.uniqueId.trim(),
                    history: this.isHistoryTabActive.toString()
                }
            });
        }
    }

    private sortStakeholdersByDepartment(stakeholders: Stakeholder[]): Stakeholder[] {
        let sortedStakeholders: Stakeholder[] = [];
        if (stakeholders && stakeholders.length > 1) {
            const plantManagerStakeholder = stakeholders.find((stakeholder) =>
                stakeholder.departmentGroupName === DEPARTMENT_GROUP_NAMES.PLANT_MANAGER);
            if (plantManagerStakeholder) {
                sortedStakeholders.push(plantManagerStakeholder);
            }
            const primaryDepartmentStakeholder = stakeholders.find((stakeholder) =>
                stakeholder.departmentGroupName === DEPARTMENT_GROUP_NAMES.PRIMARY_DEPARTMENT);
            sortedStakeholders.push(primaryDepartmentStakeholder);
            const assetStrategyStakeholder = stakeholders.find((stakeholder) =>
                stakeholder.departmentGroupName === DEPARTMENT_GROUP_NAMES.ASSET_STRATEGY);
            if (assetStrategyStakeholder) {
                sortedStakeholders.push(assetStrategyStakeholder);
            }
            const assetOperationsStakeholder = stakeholders.find((stakeholder) =>
                stakeholder.departmentGroupName === DEPARTMENT_GROUP_NAMES.ASSET_OPERATIONS);
            if (assetOperationsStakeholder) {
                sortedStakeholders.push(assetOperationsStakeholder);
            }
            const assetManagementStakeholder = stakeholders.find((stakeholder) =>
                stakeholder.departmentGroupName === DEPARTMENT_GROUP_NAMES.ASSET_MANAGEMENT);
            sortedStakeholders.push(assetManagementStakeholder);
            const businessControllingStakeholder = stakeholders.find((stakeholder) =>
                stakeholder.departmentGroupName === DEPARTMENT_GROUP_NAMES.BUSINESS_CONTROLLING);
            sortedStakeholders.push(businessControllingStakeholder);
        }
        return sortedStakeholders;
    }

    private filterNonReviewerStakeholders(stakeholders: Stakeholder[]): Stakeholder[] {
        const nonReviewerStakeholders = stakeholders.filter((stakeholder) => {
            return stakeholder.departmentGroupName === DEPARTMENT_GROUP_NAMES.NON_REVIEWERS;
        });
        return nonReviewerStakeholders;
    }

    private get isSdFd(): boolean {
        return this.iybrData?.miniFdType === MINI_FD_TYPE.FD
            || this.iybrData?.miniFdType === MINI_FD_TYPE.SFD
    }
}
