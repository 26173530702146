import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { FileService } from '~/services/file-service';

@Component({})
export default class AddFilesModalComponent extends Vue {
    @Prop()
    private data: any;

    @Prop()
    private userPermissions: string[];

    private selectedTechnology = '';
    private selectedGroup = '';
    private appliedPlant = '';
    private errorMessage = '';
    private FileService: FileService = new FileService();

    created() {
        this.selectedTechnology = this.data.selectedTechnology;
        this.selectedGroup = this.data.selectedPlantGroup;
        this.$root.$on('changedTechnology', (selectedTechnology, selectedGroup) => {
            this.selectedTechnology = selectedTechnology;
            this.selectedGroup = selectedGroup;
            this.appliedPlant = '';
        });
        this.$root.$on('changedGroup', (selectedGroup) => {
            this.selectedGroup = selectedGroup;
            this.appliedPlant = '';
        });
    }

    private async addFile(event) {
        this.$emit('addfile', event, this.appliedPlant);
        $('#addFilesModal').modal('hide');
    }
}
