import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export class ManagedAbortControllers extends Vue {
    private abortControllers = new Map<string, AbortController>();

    protected getSignal(key: string, abortCurrent = false): AbortSignal {
        if (abortCurrent) {
            this.abortSignal(key);
        }

        if (!this.abortControllers.has(key)) {
            this.abortControllers.set(key, new AbortController());
        }

        // Non-Null value is guaranteed by previous Map#has check.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        return this.abortControllers.get(key)!.signal;
    }

    protected abortSignal(key: string): void {
        if (!this.abortControllers.has(key)) {
            return;
        }

        // Non-Null value is guaranteed by previous Map#has check.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        this.abortControllers.get(key)!.abort();
        this.abortControllers.set(key, new AbortController());
    }

    protected abortAndClearAllSignals(): void {
        this.abortControllers.forEach(controller => {
            controller.abort();
        })

        this.abortControllers.clear();
    }
}
