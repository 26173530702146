import Component from 'vue-class-component';
import LinkedRiskCollapsibleComponent from '~/components/in-year-budget-requests/linked-external-ids-collapsible/linked-risk-collapsible/linked-risk-collapsible.vue';
import LinkedOpportunityCollapsibleComponent from '~/components/in-year-budget-requests/linked-external-ids-collapsible/linked-opportunity-collapsible/linked-opportunity-collapsible.vue';
import LinkedMaintenanceCollapsibleComponent from '~/components/in-year-budget-requests/linked-external-ids-collapsible/linked-maintenance-collapsible/linked-maintenance-collapsible.vue';
import Vue from 'vue';
import { Emit, Prop, Watch } from 'vue-property-decorator';
import { ExternalDataInformation } from '~/utils/interfaces';
import { MasterService } from '~/services/master-service';
import './linked-external-ids-collapsible.scss';
import { GetByExternalIdResponse } from '~/models/services/cross-app-linking';

@Component({
    components: {
        linkedRiskCollapsible: LinkedRiskCollapsibleComponent,
        linkedOpportunityCollapsible: LinkedOpportunityCollapsibleComponent,
        linkedMaintenanceCollapsible: LinkedMaintenanceCollapsibleComponent
    }
})
export default class LinkedExternalIdsCollapsibleComponent extends Vue {
    @Prop()
    externalIds?: string[];

    externalDataCacheMap = new Map<string, ExternalDataInformation>();
    invalidExternalIds: string[] = [];
    externalData: ExternalDataInformation[] = [];

    private get notEmptyExternalIds(): string[] {
        return this.externalIds.filter(e => e !== '');
    }

    @Watch('externalIds', { immediate: true })
    private updateExternalIds(): void {
        this.externalData = [];
        this.invalidExternalIds = [];

        if (this.externalIds.length === 0) {
            return;
        }

        const unknownExternalIds = this.notEmptyExternalIds.filter(externalId => !this.externalDataCacheMap.has(externalId));
        const knownExternalData = this.notEmptyExternalIds.filter(externalId => this.externalDataCacheMap.has(externalId)).map(key => this.externalDataCacheMap.get(key));

        const getExternalIdPromises = unknownExternalIds.map(externalId => MasterService.instance.crossAppLinkingService.getExternalData({
            externalId,
            existingExternalIds: [],
            userSelectionMaintenancePlanningIds: []
        }));

        Promise.all(getExternalIdPromises).then((responses: GetByExternalIdResponse[]) => {
            responses.forEach(response => {
                if (response.data) {
                    this.externalData.push(response.data);
                    this.externalDataCacheMap.set(response.externalId, response.data);
                } else {
                    this.invalidExternalIds.push(response.externalId);
                }
            });

            this.externalData.push(...knownExternalData);

            this.notifyInvalidEntries();
        });
    }

    @Emit('invalid-external-ids')
    private notifyInvalidEntries(): string[] {
        return this.invalidExternalIds;
    }
}
