var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.data.hasNavigationStructureError ? _c('div', [_vm.userPermissions.includes('SingleSitePageView') ? _c('div', {
    staticClass: "budget-requests"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('accordion-budget-request')], 1)])])]) : _c('div', [_c('div', {
    staticClass: "container-fluid pt-3"
  }, [_c('access-denied-modal', {
    on: {
      "clickedcanceleditstate": _vm.onClickCancel
    }
  })], 1)])]) : _c('div', [_c('master-data-loader-error')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }