import Vue from 'vue';
import Component from 'vue-class-component';
import { Data } from '~/utils';

@Component({})
export default class LoadingSpinnerComponent extends Vue {
    private data: Data = Data.Instance;

    constructor() {
        super();
    }
}
