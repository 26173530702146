var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "linked-budget-request-form"
  }, [_c('div', {
    staticClass: "linked-budget-request-form__breadcrumb"
  }, [_c('pui-breadcrumb', {
    attrs: {
      "links": _vm.breadcrumbLinks
    },
    on: {
      "changed:active-route": _vm.onActiveRouteChanged
    }
  })], 1), _vm.initialError.show || _vm.loader.loading ? [_vm.loader.loading ? _c('pui-loader-spinner', {
    attrs: {
      "title": _vm.loader.message
    }
  }) : _c('pui-loader-error', {
    staticClass: "linked-budget-request-form__empty-error",
    attrs: {
      "title": "",
      "message": _vm.initialError.title,
      "icon": "error-empty-data"
    }
  })] : [_c('div', {
    staticClass: "linked-budget-request-form__header"
  }, [_c('div', {
    staticClass: "linked-budget-request-form__header__title"
  }, [_c('pui-headline', {
    attrs: {
      "type": "h3"
    }
  }, [_vm._v(" " + _vm._s(_vm.pageTitle) + " ")])], 1), _c('div', {
    staticClass: "linked-budget-request-form__header__actions"
  }, [_vm.isEdit ? _c('pui-button', {
    attrs: {
      "state": "secondary"
    },
    on: {
      "click": function ($event) {
        return _vm.onActiveRouteChanged({
          href: ("/project-portfolio/" + (_vm.id) + "/view")
        });
      }
    }
  }, [_vm._v(" Cancel ")]) : _vm._e(), _c('pui-button', {
    attrs: {
      "state": "secondary",
      "disabled": _vm.globalIdsChanged || _vm.combinedDataLoading
    },
    on: {
      "click": _vm.reloadCombinedData
    }
  }, [_vm._v(" Reload ")]), _c('pui-button', {
    attrs: {
      "state": "primary",
      "disabled": !_vm.globalIdsChanged || _vm.combinedDataLoading
    },
    on: {
      "click": _vm.submitForm
    }
  }, [_vm._v(" Save ")])], 1)]), _vm.numberOfErrors ? _c('pui-form-inline-notification', {
    staticClass: "linked-budget-request-form__inline-notification",
    attrs: {
      "title": ((_vm.numberOfErrors) + " errors found!"),
      "text": "Please check the highlighted information you have provided and make updates where neccessary."
    }
  }) : _vm._e(), _c('pui-collapsible', {
    attrs: {
      "headline": "Details",
      "expandable": false,
      "open": true
    }
  }, [_vm.combinedDataLoading ? _c('pui-loader-spinner') : _c('div', {
    staticClass: "linked-budget-request-form__container"
  }, [_c('pui-grid-container', [_c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.THREE_QUARTER_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Global IDs",
      "label-for": "globalIdInput"
    }
  }, [_c('div', {
    staticClass: "linked-budget-request-form__input-with-icon"
  }, [_c('div', {
    staticClass: "linked-budget-request-form__global-id-type-ahead"
  }, [_c('pui-form-type-ahead', {
    class: {
      'unremovable-chip': !_vm.canRemoveGlobalIds
    },
    attrs: {
      "label": "Global IDs",
      "search-input-id": "globalIdInput",
      "multiple": "",
      "is-loading": _vm.globalIdsLoading,
      "options": _vm.globalIdOptions,
      "use-local-cache": false
    },
    on: {
      "search": _vm.handleGlobalIdSearch,
      "change": _vm.updateGlobalIdValues
    },
    model: {
      value: _vm.formData.values.selectedGlobalIds,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "selectedGlobalIds", $$v);
      },
      expression: "formData.values.selectedGlobalIds"
    }
  })], 1), _c('div', {
    staticClass: "linked-budget-request-form__input-with-icon__icon"
  }, [_c('pui-button', {
    attrs: {
      "icon": "reset",
      "state": "primary",
      "icon-only": "",
      "disabled": _vm.globalIdsChanged
    },
    on: {
      "click": _vm.resetGlobalIdValues
    }
  })], 1)])])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.QUARTER_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Year"
    }
  }, [_c('pui-form-input-field', {
    attrs: {
      "type": "number",
      "is-readonly": true,
      "is-disabled": true
    },
    model: {
      value: _vm.formData.values.year,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "year", $$v);
      },
      expression: "formData.values.year"
    }
  })], 1)], 1)], 1), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_THIRD_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "MTP Status",
      "is-valid": _vm.formData.validation.status
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.status) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-select', {
    attrs: {
      "label": "MTP Status",
      "value": _vm.formData.values.status,
      "options": _vm.mtpStatusOptionsList,
      "is-valid": _vm.formData.validation.status
    },
    on: {
      "change": function ($event) {
        return _vm.validateField('status');
      }
    },
    model: {
      value: _vm.formData.values.status,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "status", $$v);
      },
      expression: "formData.values.status"
    }
  })], 1)], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_THIRD_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Funding Status",
      "is-valid": _vm.formData.validation.fundingStatus
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.fundingStatus) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-select', {
    attrs: {
      "label": "Funding Status",
      "value": _vm.formData.values.fundingStatus,
      "options": _vm.constantsToOptionsList(_vm.data.constants.fundingStatus),
      "is-valid": _vm.formData.validation.fundingStatus
    },
    on: {
      "change": function ($event) {
        return _vm.validateField('fundingStatus');
      }
    },
    model: {
      value: _vm.formData.values.fundingStatus,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "fundingStatus", $$v);
      },
      expression: "formData.values.fundingStatus"
    }
  })], 1)], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_THIRD_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Project Status",
      "is-valid": _vm.formData.validation.projectStatus
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.projectStatus) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-select', {
    attrs: {
      "label": "Project Status",
      "value": _vm.formData.values.projectStatus,
      "options": _vm.constantsToOptionsList(_vm.data.constants.projectStatusType),
      "is-valid": _vm.formData.validation.projectStatus
    },
    on: {
      "change": function ($event) {
        return _vm.validateField('projectStatus');
      }
    },
    model: {
      value: _vm.formData.values.projectStatus,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "projectStatus", $$v);
      },
      expression: "formData.values.projectStatus"
    }
  })], 1)], 1)], 1), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.QUARTER_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Country"
    }
  }, [_c('pui-form-select', {
    attrs: {
      "label": "Country",
      "value": _vm.formData.values.countrySid,
      "options": _vm.singleOption(_vm.formData.values.countryName, _vm.formData.values.countrySid),
      "is-valid": _vm.formData.validation.countrySid,
      "is-readonly": true,
      "is-disabled": true
    },
    model: {
      value: _vm.formData.values.countrySid,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "countrySid", $$v);
      },
      expression: "formData.values.countrySid"
    }
  })], 1)], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.QUARTER_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Technology"
    }
  }, [_c('pui-form-select', {
    attrs: {
      "label": "Technology",
      "value": _vm.formData.values.technologySid,
      "options": _vm.singleOption(_vm.formData.values.technologyName, _vm.formData.values.technologySid),
      "is-valid": _vm.formData.validation.technologySid,
      "is-readonly": true,
      "is-disabled": true
    },
    model: {
      value: _vm.formData.values.technologySid,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "technologySid", $$v);
      },
      expression: "formData.values.technologySid"
    }
  })], 1)], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.QUARTER_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Plant Name / River Group"
    }
  }, [_c('pui-form-select', {
    attrs: {
      "label": "Plant Name / River Group",
      "value": _vm.formData.values.plantGroupSid,
      "options": _vm.singleOption(_vm.formData.values.plantGroupName, _vm.formData.values.plantGroupSid),
      "is-valid": _vm.formData.validation.plantGroupSid,
      "is-readonly": true,
      "is-disabled": true
    },
    model: {
      value: _vm.formData.values.plantGroupSid,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "plantGroupSid", $$v);
      },
      expression: "formData.values.plantGroupSid"
    }
  })], 1)], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.QUARTER_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Plant"
    }
  }, [_c('pui-form-select', {
    attrs: {
      "label": "Plant",
      "value": _vm.formData.values.plantSid,
      "options": _vm.singleOption(_vm.formData.values.plantName, _vm.formData.values.plantSid),
      "is-valid": _vm.formData.validation.plantSid,
      "is-readonly": true,
      "is-disabled": true
    },
    model: {
      value: _vm.formData.values.plantSid,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "plantSid", $$v);
      },
      expression: "formData.values.plantSid"
    }
  })], 1)], 1)], 1), _c('hr', {
    staticClass: "linked-budget-request-form__separator"
  }), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_THIRD_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Risk / Maintenance ID",
      "is-valid": _vm.externalId.isValid
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.externalId.errorMessage) + " ")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "linked-budget-request-form__input-with-icon"
  }, [_c('pui-form-input-field', {
    attrs: {
      "placeholder-text": "Please enter ID",
      "trailing-icon-name": "close",
      "trailing-icon-is-clickable": "",
      "is-valid": _vm.externalId.isValid,
      "is-readonly": _vm.externalId.loading,
      "is-disabled": _vm.externalId.loading
    },
    on: {
      "trailing-icon:click": function ($event) {
        return _vm.resetExternalId();
      }
    },
    model: {
      value: _vm.externalId.value,
      callback: function ($$v) {
        _vm.$set(_vm.externalId, "value", $$v);
      },
      expression: "externalId.value"
    }
  }), _c('div', {
    staticClass: "linked-budget-request-form__input-with-icon__icon"
  }, [_c('pui-button', {
    attrs: {
      "icon": "search",
      "state": "primary",
      "icon-only": "",
      "disabled": _vm.externalId.loading
    },
    on: {
      "click": function ($event) {
        return _vm.getDataByExternalId();
      }
    }
  })], 1)], 1)])], 1)], 1), _vm.formData.values.externalIds.length > 0 ? _c('pui-grid-row', {
    staticClass: "linked-budget-request-form__container__section-margin-top"
  }, [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.FULL_ROW
    }
  }, _vm._l(_vm.formData.values.externalIds, function (externalId) {
    return _c('pui-chip', {
      key: externalId,
      staticClass: "linked-budget-request-form__chip-list",
      attrs: {
        "label": externalId,
        "removable": ""
      },
      on: {
        "remove": function ($event) {
          return _vm.handleExternalIdRemove(externalId);
        }
      }
    });
  }), 1)], 1) : _vm._e(), _c('hr', {
    staticClass: "linked-budget-request-form__separator"
  }), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.HALF_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Name (English)",
      "is-valid": _vm.formData.validation.name
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.name) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-input-field', {
    attrs: {
      "placeholder-text": "Please enter name",
      "is-valid": _vm.formData.validation.name
    },
    on: {
      "input": function ($event) {
        return _vm.validateField('name');
      }
    },
    model: {
      value: _vm.formData.values.name,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "name", $$v);
      },
      expression: "formData.values.name"
    }
  })], 1)], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.HALF_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Name (Local Language)",
      "is-valid": _vm.formData.validation.localLanguageName
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.localLanguageName) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-input-field', {
    attrs: {
      "placeholder-text": "Please enter name",
      "required": false,
      "is-valid": _vm.formData.validation.localLanguageName
    },
    on: {
      "input": function ($event) {
        return _vm.validateField('localLanguageName');
      }
    },
    model: {
      value: _vm.formData.values.localLanguageName,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "localLanguageName", $$v);
      },
      expression: "formData.values.localLanguageName"
    }
  })], 1)], 1)], 1), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.HALF_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Project Type",
      "is-valid": _vm.formData.validation.projectType
    },
    scopedSlots: _vm._u([{
      key: "helper-text",
      fn: function () {
        return [_vm._v(" A \"Project\" has a defined start and end date \"Non-Project\" items are recurring e.g. daily maintenance. ")];
      },
      proxy: true
    }, {
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.projectType) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-input-selector', {
    attrs: {
      "name": "projectType",
      "options": _vm.constantsToOptionsList(_vm.data.constants.projectTypes),
      "selected-value": _vm.formData.values.projectType
    },
    on: {
      "update:selectedValue": function ($event) {
        return _vm.$set(_vm.formData.values, "projectType", $event);
      },
      "update:selected-value": [function ($event) {
        return _vm.$set(_vm.formData.values, "projectType", $event);
      }, function () {
        _vm.validateField('projectType');

        _vm.validateField('total');
      }]
    }
  })], 1)], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.HALF_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Lump Sum",
      "is-valid": _vm.formData.validation.lumpSum
    },
    scopedSlots: _vm._u([{
      key: "helper-text",
      fn: function () {
        return [_vm._v(" A lump sum is defined as a set of projects/activities whose individual total costs are below 200k€ each. ")];
      },
      proxy: true
    }, {
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.lumpSum) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-input-selector', {
    attrs: {
      "name": "lumpSum",
      "options": _vm.answerOptions,
      "selected-value": _vm.formData.values.lumpSum
    },
    on: {
      "update:selectedValue": function ($event) {
        return _vm.$set(_vm.formData.values, "lumpSum", $event);
      },
      "update:selected-value": [function ($event) {
        return _vm.$set(_vm.formData.values, "lumpSum", $event);
      }, function ($event) {
        return _vm.validateField('lumpSum');
      }]
    }
  })], 1)], 1)], 1), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.HALF_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Start Date",
      "is-valid": _vm.formData.validation.startDate
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.startDate) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-input-field', {
    attrs: {
      "type": "date",
      "required": false,
      "is-valid": _vm.formData.validation.startDate
    },
    on: {
      "input": function () {
        _vm.validateField('startDate');

        _vm.validateField('endDate');
      }
    },
    model: {
      value: _vm.formData.values.startDate,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "startDate", $$v);
      },
      expression: "formData.values.startDate"
    }
  })], 1)], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.HALF_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "End Date",
      "is-valid": _vm.formData.validation.endDate
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.endDate) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-input-field', {
    attrs: {
      "type": "date",
      "required": false,
      "is-valid": _vm.formData.validation.endDate
    },
    on: {
      "input": function ($event) {
        return _vm.validateField('endDate');
      }
    },
    model: {
      value: _vm.formData.values.endDate,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "endDate", $$v);
      },
      expression: "formData.values.endDate"
    }
  })], 1)], 1)], 1), _c('hr', {
    staticClass: "linked-budget-request-form__separator"
  }), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.FULL_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "AERO Classification",
      "is-valid": _vm.formData.validation.aeroClassification
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.aeroClassification) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-input-selector', {
    attrs: {
      "name": "aeroClassification",
      "options": _vm.constantsToOptionsList(_vm.data.constants.aeroClassification),
      "selected-value": _vm.formData.values.aeroClassification
    },
    on: {
      "update:selectedValue": function ($event) {
        return _vm.$set(_vm.formData.values, "aeroClassification", $event);
      },
      "update:selected-value": [function ($event) {
        return _vm.$set(_vm.formData.values, "aeroClassification", $event);
      }, function (value) {
        _vm.fillPrimaryDepartment(value);

        _vm.validateField('aeroClassification');

        _vm.validateField('primaryDepartment');

        _vm.validateField('justificationOnClassification');
      }]
    }
  })], 1)], 1)], 1), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_THIRD_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Justification on Classification",
      "is-valid": _vm.formData.validation.justificationOnClassification
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.justificationOnClassification) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-input-field', {
    attrs: {
      "placeholder-text": "Please enter a justification",
      "is-valid": _vm.formData.validation.justificationOnClassification
    },
    on: {
      "input": function ($event) {
        return _vm.validateField('justificationOnClassification');
      }
    },
    model: {
      value: _vm.formData.values.justificationOnClassification,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "justificationOnClassification", $$v);
      },
      expression: "formData.values.justificationOnClassification"
    }
  })], 1)], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_THIRD_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Primary Department",
      "is-valid": _vm.formData.validation.primaryDepartment
    },
    scopedSlots: _vm._u([_vm.isPrimaryDepartmentDefinedbyAero ? {
      key: "helper-text",
      fn: function () {
        return [_vm._v(" Defined by \"AERO Classification\". ")];
      },
      proxy: true
    } : null, {
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.primaryDepartment) + " ")];
      },
      proxy: true
    }], null, true)
  }, [_c('pui-form-select', {
    attrs: {
      "label": "Primary Department",
      "value": _vm.formData.values.primaryDepartment,
      "options": _vm.constantsToOptionsList(_vm.data.constants.primaryDepartment),
      "is-valid": _vm.formData.validation.primaryDepartment
    },
    on: {
      "change": function ($event) {
        return _vm.validateField('primaryDepartment');
      }
    },
    model: {
      value: _vm.formData.values.primaryDepartment,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "primaryDepartment", $$v);
      },
      expression: "formData.values.primaryDepartment"
    }
  })], 1)], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_THIRD_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Maintenance Type",
      "is-valid": _vm.formData.validation.maintenanceType
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.maintenanceType) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-select', {
    attrs: {
      "label": "Maintenance Type",
      "value": _vm.formData.values.maintenanceType,
      "options": _vm.constantsToOptionsList(_vm.data.constants.maintenanceType),
      "is-valid": _vm.formData.validation.maintenanceType
    },
    on: {
      "change": function ($event) {
        return _vm.validateField('maintenanceType');
      }
    },
    model: {
      value: _vm.formData.values.maintenanceType,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "maintenanceType", $$v);
      },
      expression: "formData.values.maintenanceType"
    }
  })], 1)], 1)], 1), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_THIRD_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Outage Type",
      "is-valid": _vm.formData.validation.outageType
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.outageType) + " ")];
      },
      proxy: true
    }, {
      key: "helper-text",
      fn: function () {
        return [_vm._v(" For Fossil plants, please indicate whether the works are part of an \"Outage\" or not. For Hydro plants please choose \"Non-Outage\" ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-input-selector', {
    attrs: {
      "name": "outageType",
      "options": _vm.constantsToOptionsList(_vm.data.constants.outageType),
      "selected-value": _vm.formData.values.outageType
    },
    on: {
      "update:selectedValue": function ($event) {
        return _vm.$set(_vm.formData.values, "outageType", $event);
      },
      "update:selected-value": [function ($event) {
        return _vm.$set(_vm.formData.values, "outageType", $event);
      }, function () {
        _vm.validateField('outageType');

        _vm.validateField('outageIds');
      }]
    }
  })], 1)], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_THIRD_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Outage ID",
      "is-valid": _vm.formData.validation.outageIds
    },
    scopedSlots: _vm._u([{
      key: "helper-text",
      fn: function () {
        return [_vm._v(" e.g. \"OUT/WHV/1/21.01\". For small outages without outage IDs, please enter the standard term \"N/A small(<2.5m€) outage.\" ")];
      },
      proxy: true
    }, {
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.outageIds) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-input-field', {
    attrs: {
      "placeholder-text": "Please enter text",
      "is-disabled": _vm.formData.values.outageType !== '1',
      "is-valid": _vm.formData.validation.outageIds
    },
    on: {
      "input": function ($event) {
        return _vm.validateField('outageIds');
      }
    },
    model: {
      value: _vm.formData.values.outageIds,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "outageIds", $$v);
      },
      expression: "formData.values.outageIds"
    }
  })], 1)], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_THIRD_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Procurement Category",
      "is-valid": _vm.formData.validation.procurementCategory
    },
    scopedSlots: _vm._u([{
      key: "helper-text",
      fn: function () {
        return [_vm._v(" If a lump sum spend, please select \"Others\" ")];
      },
      proxy: true
    }, {
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.procurementCategory) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-select', {
    attrs: {
      "label": "Procurement Category",
      "value": _vm.formData.values.procurementCategory,
      "options": _vm.constantsToOptionsList(_vm.data.constants.procurementCategory),
      "is-valid": _vm.formData.validation.procurementCategory
    },
    on: {
      "change": function ($event) {
        return _vm.validateField('procurementCategory');
      }
    },
    model: {
      value: _vm.formData.values.procurementCategory,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "procurementCategory", $$v);
      },
      expression: "formData.values.procurementCategory"
    }
  })], 1)], 1)], 1), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.FULL_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Comment",
      "is-valid": _vm.formData.validation.comment
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.comment) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-textarea', {
    attrs: {
      "value": _vm.formData.values.comment,
      "grow": true,
      "rows": 2,
      "is-valid": _vm.formData.validation.comment
    },
    on: {
      "input": function ($event) {
        return _vm.validateField('comment');
      }
    },
    model: {
      value: _vm.formData.values.comment,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "comment", $$v);
      },
      expression: "formData.values.comment"
    }
  })], 1)], 1)], 1), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_THIRD_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Main Flag",
      "is-valid": _vm.formData.validation.mainFlag,
      "show-optional-label": "",
      "has-cta": "",
      "cta-label": "Clear",
      "cta-callback": _vm.resetMainFlag
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.mainFlag) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-select', {
    attrs: {
      "label": "Main Flag",
      "value": _vm.formData.values.mainFlag,
      "options": _vm.constantsToOptionsList(_vm.data.constants.flags),
      "is-valid": _vm.formData.validation.mainFlag
    },
    on: {
      "change": function ($event) {
        return _vm.validateField('mainFlag');
      }
    },
    model: {
      value: _vm.formData.values.mainFlag,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "mainFlag", $$v);
      },
      expression: "formData.values.mainFlag"
    }
  })], 1)], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.TWO_THIRDS_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Other Flags",
      "is-valid": _vm.formData.validation.otherFlags,
      "show-optional-label": ""
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.otherFlags) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-multi-select', {
    attrs: {
      "label": "Other Flags",
      "value": _vm.formData.values.otherFlags,
      "options": _vm.constantsToOptionsList(_vm.data.constants.flags),
      "is-valid": _vm.formData.validation.otherFlags
    },
    on: {
      "change": function ($event) {
        return _vm.validateField('otherFlags');
      }
    },
    model: {
      value: _vm.formData.values.otherFlags,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "otherFlags", $$v);
      },
      expression: "formData.values.otherFlags"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1), _c('pui-collapsible', {
    attrs: {
      "headline": "Finance & Planning",
      "expandable": false,
      "open": true
    }
  }, [_vm.combinedDataLoading ? _c('pui-loader-spinner') : _c('div', {
    staticClass: "linked-budget-request-form__container"
  }, [_c('pui-grid-container', [_c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.HALF_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "WBS (PSP) Element",
      "is-valid": _vm.formData.validation.wbsElement
    },
    scopedSlots: _vm._u([{
      key: "helper-text",
      fn: function () {
        return [_vm._v(" e.g. \"0301.A01234\". If it's not yet available, please enter \"N/A\" ")];
      },
      proxy: true
    }, {
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.wbsElement) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-input-field', {
    attrs: {
      "placeholder-text": "Please enter an ID",
      "is-valid": _vm.formData.validation.wbsElement
    },
    on: {
      "input": function ($event) {
        return _vm.validateField('wbsElement');
      }
    },
    model: {
      value: _vm.formData.values.wbsElement,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "wbsElement", $$v);
      },
      expression: "formData.values.wbsElement"
    }
  })], 1)], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.HALF_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Planning & Controlling Classification",
      "is-valid": _vm.formData.validation.planningAndControllingClassification
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.planningAndControllingClassification) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-select', {
    attrs: {
      "label": "Planning & Controlling Classification",
      "value": _vm.formData.values.planningAndControllingClassification,
      "options": _vm.constantsToOptionsList(_vm.data.constants.newPlanningAndControllingClassification),
      "is-valid": _vm.formData.validation.planningAndControllingClassification
    },
    on: {
      "change": function ($event) {
        return _vm.validateField('planningAndControllingClassification');
      }
    },
    model: {
      value: _vm.formData.values.planningAndControllingClassification,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "planningAndControllingClassification", $$v);
      },
      expression: "formData.values.planningAndControllingClassification"
    }
  })], 1)], 1)], 1), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.HALF_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Uniper Share of Budget",
      "is-valid": _vm.formData.validation.uniperShareOfBudget
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.uniperShareOfBudget) + " ")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "linked-budget-request-form__input-with-icon"
  }, [_c('pui-form-input-field', {
    attrs: {
      "type": "number",
      "placeholder-text": "Please enter a number",
      "is-valid": _vm.formData.validation.uniperShareOfBudget,
      "min": 0,
      "max": 100,
      "step": 1
    },
    on: {
      "input": function ($event) {
        return _vm.validateField('uniperShareOfBudget');
      }
    },
    model: {
      value: _vm.formData.values.uniperShareOfBudget,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "uniperShareOfBudget", $$v);
      },
      expression: "formData.values.uniperShareOfBudget"
    }
  }), _c('div', {
    staticClass: "linked-budget-request-form__input-with-icon__icon linked-budget-request-form__input-with-icon__icon--informative"
  }, [_c('pui-headline', {
    staticClass: "linked-budget-request-form__input-with-icon__icon__headline",
    attrs: {
      "type": "h4"
    }
  }, [_vm._v(" % ")])], 1)], 1)])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.HALF_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Useful Economic Life",
      "is-valid": _vm.formData.validation.usefulEconomicLife
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.usefulEconomicLife) + " ")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "linked-budget-request-form__input-with-icon"
  }, [_c('pui-form-input-field', {
    attrs: {
      "type": "number",
      "placeholder-text": "Please enter number of years",
      "is-valid": _vm.formData.validation.usefulEconomicLife,
      "min": 0,
      "step": 1
    },
    on: {
      "input": function ($event) {
        return _vm.validateField('usefulEconomicLife');
      }
    },
    model: {
      value: _vm.formData.values.usefulEconomicLife,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "usefulEconomicLife", $$v);
      },
      expression: "formData.values.usefulEconomicLife"
    }
  }), _c('div', {
    staticClass: "linked-budget-request-form__input-with-icon__icon linked-budget-request-form__input-with-icon__icon--informative"
  }, [_c('pui-headline', {
    staticClass: "linked-budget-request-form__input-with-icon__icon__headline",
    attrs: {
      "type": "h4"
    }
  }, [_vm._v(" # ")])], 1)], 1)])], 1)], 1), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.HALF_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Has Planning Costs",
      "is-valid": _vm.formData.validation.hasPlanningCosts
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.hasPlanningCosts) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-input-selector', {
    attrs: {
      "name": "hasPlanningCosts",
      "options": _vm.answerOptions,
      "selected-value": _vm.formData.values.hasPlanningCosts
    },
    on: {
      "update:selectedValue": function ($event) {
        return _vm.$set(_vm.formData.values, "hasPlanningCosts", $event);
      },
      "update:selected-value": [function ($event) {
        return _vm.$set(_vm.formData.values, "hasPlanningCosts", $event);
      }, function ($event) {
        return _vm.validateField('hasPlanningCosts');
      }]
    }
  })], 1)], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.HALF_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Currency",
      "is-valid": _vm.formData.validation.currencyCode
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.currencyCode) + " ")];
      },
      proxy: true
    }])
  }, [_c('pui-form-input-selector', {
    attrs: {
      "name": "currencyCode",
      "options": _vm.currencyCodeOptions,
      "selected-value": _vm.formData.values.currencyCode
    },
    on: {
      "update:selectedValue": function ($event) {
        return _vm.$set(_vm.formData.values, "currencyCode", $event);
      },
      "update:selected-value": [function ($event) {
        return _vm.$set(_vm.formData.values, "currencyCode", $event);
      }, function ($event) {
        return _vm.validateField('currencyCode');
      }]
    }
  })], 1)], 1)], 1), _c('hr', {
    staticClass: "linked-budget-request-form__separator"
  }), _c('div', {
    staticClass: "linked-budget-request-form__headline"
  }, [_c('pui-headline', {
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Planning ")])], 1), _c('div', {
    staticClass: "linked-budget-request-form__scrollable-row"
  }, [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_SIXTH_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": ("Spend until end of " + (_vm.year)),
      "is-valid": _vm.formData.validation.spendUntilEndOfYear
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.spendUntilEndOfYear) + " ")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "linked-budget-request-form__input-with-icon"
  }, [_c('pui-form-input-field', {
    attrs: {
      "type": "number",
      "placeholder-text": "Please enter a number",
      "is-valid": _vm.formData.validation.spendUntilEndOfYear,
      "min": 0,
      "step": 0.001
    },
    on: {
      "input": function ($event) {
        return _vm.validateField('spendUntilEndOfYear');
      }
    },
    model: {
      value: _vm.formData.values.spendUntilEndOfYear,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "spendUntilEndOfYear", $$v);
      },
      expression: "formData.values.spendUntilEndOfYear"
    }
  }), _c('div', {
    staticClass: "linked-budget-request-form__input-with-icon__icon linked-budget-request-form__input-with-icon__icon--currency"
  }, [_c('pui-headline', {
    staticClass: "linked-budget-request-form__input-with-icon__icon__headline linked-budget-request-form__input-with-icon__icon--currency__headline",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.values.currencyCode) + " ")])], 1)], 1)])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_SIXTH_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": ("Forecast MTP " + (_vm.year)),
      "is-valid": _vm.formData.validation.forecast
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.forecast) + " ")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "linked-budget-request-form__input-with-icon"
  }, [_c('pui-form-input-field', {
    attrs: {
      "type": "number",
      "placeholder-text": "Please enter a number",
      "is-valid": _vm.formData.validation.forecast,
      "min": 0,
      "step": 0.001
    },
    on: {
      "input": function ($event) {
        return _vm.validateField('forecast');
      }
    },
    model: {
      value: _vm.formData.values.forecast,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "forecast", $$v);
      },
      expression: "formData.values.forecast"
    }
  }), _c('div', {
    staticClass: "linked-budget-request-form__input-with-icon__icon linked-budget-request-form__input-with-icon__icon--currency"
  }, [_c('pui-headline', {
    staticClass: "linked-budget-request-form__input-with-icon__icon__headline linked-budget-request-form__input-with-icon__icon--currency__headline",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.values.currencyCode) + " ")])], 1)], 1)])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_SIXTH_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": ("New MTP " + (Number(_vm.year) + 1)),
      "is-valid": _vm.formData.validation.new1
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.new1) + " ")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "linked-budget-request-form__input-with-icon"
  }, [_c('pui-form-input-field', {
    attrs: {
      "type": "number",
      "placeholder-text": "Please enter a number",
      "is-valid": _vm.formData.validation.new1,
      "min": 0,
      "step": 0.001
    },
    on: {
      "input": function ($event) {
        return _vm.validateField('new1');
      }
    },
    model: {
      value: _vm.formData.values.new1,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "new1", $$v);
      },
      expression: "formData.values.new1"
    }
  }), _c('div', {
    staticClass: "linked-budget-request-form__input-with-icon__icon linked-budget-request-form__input-with-icon__icon--currency"
  }, [_c('pui-headline', {
    staticClass: "linked-budget-request-form__input-with-icon__icon__headline linked-budget-request-form__input-with-icon__icon--currency__headline",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.values.currencyCode) + " ")])], 1)], 1)])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_SIXTH_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": ("New MTP " + (Number(_vm.year) + 2)),
      "is-valid": _vm.formData.validation.new2
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.new2) + " ")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "linked-budget-request-form__input-with-icon"
  }, [_c('pui-form-input-field', {
    attrs: {
      "type": "number",
      "placeholder-text": "Please enter a number",
      "is-valid": _vm.formData.validation.new2,
      "min": 0,
      "step": 0.001
    },
    on: {
      "input": function ($event) {
        return _vm.validateField('new2');
      }
    },
    model: {
      value: _vm.formData.values.new2,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "new2", $$v);
      },
      expression: "formData.values.new2"
    }
  }), _c('div', {
    staticClass: "linked-budget-request-form__input-with-icon__icon linked-budget-request-form__input-with-icon__icon--currency"
  }, [_c('pui-headline', {
    staticClass: "linked-budget-request-form__input-with-icon__icon__headline linked-budget-request-form__input-with-icon__icon--currency__headline",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.values.currencyCode) + " ")])], 1)], 1)])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_SIXTH_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": ("New MTP " + (Number(_vm.year) + 3)),
      "is-valid": _vm.formData.validation.new3
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.new3) + " ")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "linked-budget-request-form__input-with-icon"
  }, [_c('pui-form-input-field', {
    attrs: {
      "type": "number",
      "placeholder-text": "Please enter a number",
      "is-valid": _vm.formData.validation.new3,
      "min": 0,
      "step": 0.001
    },
    on: {
      "input": function ($event) {
        return _vm.validateField('new3');
      }
    },
    model: {
      value: _vm.formData.values.new3,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "new3", $$v);
      },
      expression: "formData.values.new3"
    }
  }), _c('div', {
    staticClass: "linked-budget-request-form__input-with-icon__icon linked-budget-request-form__input-with-icon__icon--currency"
  }, [_c('pui-headline', {
    staticClass: "linked-budget-request-form__input-with-icon__icon__headline linked-budget-request-form__input-with-icon__icon--currency__headline",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.values.currencyCode) + " ")])], 1)], 1)])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_SIXTH_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": ("After " + (Number(_vm.year) + 3)),
      "is-valid": _vm.formData.validation.after
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.after) + " ")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "linked-budget-request-form__input-with-icon"
  }, [_c('pui-form-input-field', {
    attrs: {
      "type": "number",
      "placeholder-text": "Please enter a number",
      "is-valid": _vm.formData.validation.after,
      "min": 0,
      "step": 0.001
    },
    on: {
      "input": function ($event) {
        return _vm.validateField('after');
      }
    },
    model: {
      value: _vm.formData.values.after,
      callback: function ($$v) {
        _vm.$set(_vm.formData.values, "after", $$v);
      },
      expression: "formData.values.after"
    }
  }), _c('div', {
    staticClass: "linked-budget-request-form__input-with-icon__icon linked-budget-request-form__input-with-icon__icon--currency"
  }, [_c('pui-headline', {
    staticClass: "linked-budget-request-form__input-with-icon__icon__headline linked-budget-request-form__input-with-icon__icon--currency__headline",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.values.currencyCode) + " ")])], 1)], 1)])], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.ONE_SIXTH_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Total",
      "is-valid": _vm.formData.validation.total
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.errorMessages.total) + " ")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "linked-budget-request-form__input-with-icon"
  }, [_c('pui-form-input-field', {
    attrs: {
      "is-valid": _vm.formData.validation.total,
      "is-readonly": true,
      "is-disabled": true,
      "min": 0,
      "step": 0.001
    },
    on: {
      "value:update": function ($event) {
        return _vm.validateField('total');
      }
    },
    model: {
      value: _vm.planningTotal,
      callback: function ($$v) {
        _vm.planningTotal = $$v;
      },
      expression: "planningTotal"
    }
  }), _c('div', {
    staticClass: "linked-budget-request-form__input-with-icon__icon linked-budget-request-form__input-with-icon__icon--currency"
  }, [_c('pui-headline', {
    staticClass: "linked-budget-request-form__input-with-icon__icon__headline linked-budget-request-form__input-with-icon__icon--currency__headline",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.formData.values.currencyCode) + " ")])], 1)], 1)])], 1)], 1)], 1)], 1)], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }