import { Connection } from '~/utils/';
import { ApiResponse, FileResponse } from '~/models/services/common';
import { ExportPayload } from '~/models/services/reporting';
import { BudgetRequests, SearchModel } from '~/utils/interfaces';

export class ReportingService {
    private readonly api = new Connection();

    // Simple budget request export.
    public async exportBudgetRequests(exportBudgetRequests: ExportPayload): Promise<FileResponse> {
        const content = await this.api.post<Blob>('reporting/export', exportBudgetRequests, { responseType: 'blob' });
        const name = content.headers['content-disposition'].split('UTF-8\'\'')[1];

        return {
            name,
            content: content.data
        };
    }

    // Export all budget requests.
    public async exportReportingBudgetRequests(exportBudgetRequests: SearchModel): Promise<FileResponse> {
        const content = await this.api.post<Blob>('reporting/export-all', exportBudgetRequests, { responseType: 'blob' });
        const name = content.headers['content-disposition'].split('UTF-8\'\'')[1];

        return {
            name,
            content: content.data
        };
    }

    public async getBudgetRequest(searchModel: SearchModel): Promise<BudgetRequests> {
        return (await this.api.post<ApiResponse<BudgetRequests>>('reporting/search', searchModel)).data.result;
    }
}
