import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class ToastErrorMessageComponent extends Vue {
    private errorMessage = '';

    mounted() {
        this.$root.$on('showErrorToastGlobalIdNotFound', (e) => {
            this.errorMessage = e;
            this.$bvToast.show('toast-error');
        });
    }

    private hideToast() {
        this.$bvToast.hide('toast-error');
    }
}
