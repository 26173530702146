import { Connection } from '~/utils/';
import { ApiResponse, FileResponse } from '~/models/services/common';
import { BudgetRequestsLivePml, SearchModel } from '~/utils/interfaces';

export class LivePmlService {
    private readonly api = new Connection();

    public async getLivePml(searchModel: SearchModel): Promise<BudgetRequestsLivePml> {
        return (await this.api.post<ApiResponse<BudgetRequestsLivePml>>('live-pml/list-live-pml', searchModel)).data.result;
    }

    public async exportLivePml(searchModel: SearchModel): Promise<FileResponse> {
        const content = await this.api.post<Blob>('live-pml/export-live-pml', searchModel, { responseType: 'blob' });
        const name = content.headers['content-disposition'].split('UTF-8\'\'')[1];

        return {
            name,
            content: content.data
        };
    }
}
