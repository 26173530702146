var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.hasPermissionToEdit ? _c('div', {
    staticClass: "cr-periods"
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "can-cancel": true
    },
    on: {
      "update:active": function ($event) {
        _vm.loading = $event;
      }
    }
  }), _c('b-toast', {
    attrs: {
      "id": "toast-success",
      "auto-hide-delay": "20000",
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center max-width-toast-update-mtp"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [_c('div', [_c('icon', {
    staticClass: "icon-toast text-center",
    staticStyle: {
      "min-width": "40px"
    },
    attrs: {
      "name": "check"
    }
  })], 1), _c('div', [_c('span', [_vm._v("CR Period saved successfully.")])]), _c('button', {
    staticClass: "btn btn-outline-primary ml-3",
    on: {
      "click": function ($event) {
        return _vm.hideToast();
      }
    }
  }, [_vm._v(" Dismiss ")])])]), _c('div', {
    staticClass: "container-fluid pt-3 pb-3 pl-lg-5"
  }, [_c('div', {
    staticClass: "row justify-content-start"
  }, [_c('div', {
    staticClass: "col-lg-10 col-12 offset-lg-1 pt-3"
  }, [_c('h3', [_vm._v("CR Periods")]), _vm.error.length > 0 ? _c('p', {
    staticStyle: {
      "color": "#dc3545"
    }
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "row justify-content-start mb-2"
  }, [_c('div', {
    staticClass: "col-xl-5 col-md-6 offset-lg-1 col-12 order-md-1 order-2 pt-3"
  }, [_c('add-new-period', {
    attrs: {
      "edit-id": _vm.editId,
      "has-permission-to-edit": _vm.hasPermissionToEdit,
      "period-type": _vm.periodType,
      "periods": _vm.periods
    },
    on: {
      "update:editId": function ($event) {
        _vm.editId = $event;
      },
      "update:edit-id": function ($event) {
        _vm.editId = $event;
      },
      "errorChanged": _vm.handleErrorChanged,
      "loadingChanged": _vm.handleLoadingChanged,
      "periodSaved": _vm.handlePeriodSaved
    }
  })], 1)]), _c('div', {
    staticClass: "row justify-content-start mb-4"
  }, [_c('div', {
    staticClass: "col-lg-10 offset-lg-1"
  }, [_c('view-periods', {
    attrs: {
      "has-permission-to-edit": _vm.hasPermissionToEdit,
      "loading": _vm.loading,
      "period-type": _vm.periodType,
      "periods": _vm.periods
    },
    on: {
      "editIdChanged": _vm.handleEditIdChanged,
      "loadingChanged": _vm.handleLoadingChanged
    }
  })], 1)])])], 1) : _c('div', [_c('div', {
    staticClass: "container-fluid pt-3"
  }, [_c('access-denied-modal', {
    on: {
      "clickedcanceleditstate": _vm.onClickCancel
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }