import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import 'vue-awesome/icons/chevron-down';

@Component({})
export default class MaintenanceBudgetRequestComponent extends Vue {
    public symbolCurrency: string = null;
    private isTotal: boolean = true;
    @Prop()
    private maintenance: any;
    @Prop()
    private disableCheckBoxes: boolean;
    @Prop()
    private isDetails: boolean;
    @Prop()
    private elementIndex: number;
    @Prop()
    private currency: string;

    constructor() {
        super();
        switch (this.currency) {
            case 'mSEK':
                this.symbolCurrency = ' k kr';
                break;
            case 'mGBP':
                this.symbolCurrency = ' k£';
                break;
            case 'mEUR':
                this.symbolCurrency = ' k€';
                break;
            default:
                this.symbolCurrency = ' k€';
        }
    }

    private changeTotal(index: number) {
        this.isTotal = !this.isTotal;
    }

    private emitCopy(index: number) {
        this.$emit('copy', index);
    }

    private emitCheck(index: number) {
        this.$emit('onCheck', index);
    }

    private selectedSymbolCurrency(currency) {

    }
}
