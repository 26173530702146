var render = function () {
  var _vm$changeRequestFile, _vm$changeRequestFile2, _vm$changeRequestFile3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('pui-grid-row', {
    staticClass: "file-row"
  }, [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.PAGE_LAYOUT.QUARTER_ROW
    }
  }, [_c('div', {
    staticClass: "file-type"
  }, [_vm._v(" " + _vm._s(_vm.changeRequestFile.extension) + " ")])]), _c('pui-grid-column', {
    staticClass: "file-name",
    attrs: {
      "cols": _vm.PAGE_LAYOUT.QUARTER_ROW
    }
  }, [_vm._v(" " + _vm._s(_vm.changeRequestFile.name) + " " + _vm._s((_vm$changeRequestFile = _vm.changeRequestFile) === null || _vm$changeRequestFile === void 0 ? void 0 : _vm$changeRequestFile.size) + " "), _vm.isEditPage || _vm.isViewPage ? _c('pui-grid-column', {
    staticClass: "file-created-by",
    attrs: {
      "cols": _vm.PAGE_LAYOUT.QUARTER_ROW
    }
  }, [_vm._v(" " + _vm._s(_vm.convertDate((_vm$changeRequestFile2 = _vm.changeRequestFile) === null || _vm$changeRequestFile2 === void 0 ? void 0 : _vm$changeRequestFile2.created)) + " by " + _vm._s((_vm$changeRequestFile3 = _vm.changeRequestFile) === null || _vm$changeRequestFile3 === void 0 ? void 0 : _vm$changeRequestFile3.createdBy) + " ")]) : _vm._e()], 1), _vm.isEditPage || _vm.isViewPage ? _c('pui-grid-column', {
    staticClass: "file-download",
    attrs: {
      "cols": _vm.isViewPage ? _vm.PAGE_LAYOUT.HALF_ROW : _vm.PAGE_LAYOUT.QUARTER_ROW
    }
  }, [_c('div', {
    on: {
      "click": _vm.onDownloadButtonClick
    }
  }, [_c('pui-icon', {
    attrs: {
      "icon-name": "download",
      "size": "4vh"
    }
  }), _c('pui-grid-column', {
    staticClass: "file-download-text"
  }, [_vm._v(" Download ")])], 1)]) : _vm._e(), !_vm.isViewPage ? _c('pui-grid-column', {
    staticClass: "file-delete",
    attrs: {
      "cols": _vm.isEditPage ? _vm.PAGE_LAYOUT.QUARTER_ROW : _vm.PAGE_LAYOUT.HALF_ROW
    }
  }, [_c('pui-lightbox', {
    ref: _vm.CONFIRM_DELETE_MODAL_REF,
    staticClass: "confirm-delete-modal",
    attrs: {
      "fit-content": true,
      "on-confirm-callback": _vm.onConfirmDeleteButtonClick,
      "default-footer-cancel-label": "Cancel",
      "default-footer-confirm-label": "Delete"
    }
  }, [_c('div', {
    staticClass: "confirm-delete-modal-header-text",
    attrs: {
      "slot": "lightbox-header"
    },
    slot: "lightbox-header"
  }, [_c('pui-icon', {
    staticClass: "confirm-delete-modal-warning-icon",
    attrs: {
      "icon-name": "warning",
      "size": "4rem"
    }
  }), _c('h3', [_vm._v(" Warning ")])], 1), _c('p', {
    attrs: {
      "slot": "default"
    },
    slot: "default"
  }, [_vm._v(" Are you sure you want to delete this file? ")]), _c('div', {
    attrs: {
      "slot": "lightbox-trigger"
    },
    on: {
      "click": _vm.onDeleteButtonClick
    },
    slot: "lightbox-trigger"
  }, [_c('pui-icon', {
    attrs: {
      "icon-name": "delete",
      "size": "4vh"
    }
  }), _c('pui-grid-column', {
    staticClass: "file-download-text"
  }, [_vm._v(" Delete ")])], 1)])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }