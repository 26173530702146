import Vue from 'vue';

export const EventBus = new Vue({
    data() {
        return {
            GLOBAL: {
                SHOW_SNACKBAR: 'global.show.snackbar',
                REFRESH: 'global.refresh',
                ADD_COMPANY: 'global.add-company',
                ADD_GROUP: 'global.add-group',
                ADD_PLANT: 'global.add-plant'
            },
            LOGIN: {
                SHOW: 'login.show',
                LOGIN: 'login.success'
            },
            UNIT: {
                OPEN_UNIT_MODAL: 'unit.open-unit-modal',
                ADD_TRIGGER: 'unit.add-trigger',
                CANCEL_ADD_TRIGGER: 'unit.cancel-add-trigger'
            },
            TABLE: {
                OPEN_PLANT_ITEM_MODAL: 'table.open-plant-item',
                OPEN_MAINTENANCE_ITEM: 'table.open-maintenance-item',
                OPEN_ACM_TOOLTIP_ITEM: 'table.open-acm-tooltip-item',
                OPEN_RISK_DETAILS_MODAL: 'table.open-risk-details-modal',
                OPEN_OPPORTUNITY_DETAILS_MODAL: 'table.open-opportunity-details-modal'
            },
            BUDGET_REQUESTS: {
                PLANT_CHANGE: 'budget-requests.plant-change',
                GLOBAL_ID_ITEM: 'budget.requests.global-id-item'

            },
            BUDGET_REQUEST: {
                SAVE_CHANGES_MODAL: 'budget-requests.save-changes-modal',
                YES_CHANGES_MODAL: 'budget-requests.yes-changes-modal',
                DISCARD_CHANGES_MODAL: 'budget-requests.discard-changes-modal',
                ADD_BUDGET_REQUEST: 'budget-requests.add-budget-request',
                EDIT_BUDGET_REQUEST: 'budget-requests.edit-budget-request'
            },
            MTP_STATUS: {
                UPDATE_FLAGGED_MODAL: 'mtp-status.update-flagged-modal'
            },
            REVIEW_CHANGES: {
                SAVE_CHANGES_MODAL: 'review-changes.save-changes-modal',
                DISCARD_CHANGES_MODAL: 'review-changes.discard-changes-modal',
            },
            REMOVE_BUDGET_REQUEST: {
                REMOVE_MODAL: 'remove-budget-request.remove-modal',
                SET_INACTIVE_MODAL: 'remove-budget-requestset-inactive-modal'
            },
            DELETE_BUDGET_REQUEST: {
                DELETE_MODAL: 'delete-budget-request.delete-modal'
            },
            UPDATE_MTP_STATUS: {
                UPDATE_MTP_MODAL: 'update-mtp-status.update-mtp-modal',
                UPDATE_MTP_REVIEWED_MODAL: 'update-mtp-status.update-mtp-reviewed-modal',
                UPDATE_MTP_FLAGGED_MODAL: 'update-mtp-status.update-mtp-flagged-modal'
            },
            CHANGE_REQUEST: {
                GET_DEPARTMENTS_ID: 'get-departments-id'
            }
        };
    }
});