var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.data.navBarRefresh ? _c('loading', {
    attrs: {
      "active": _vm.data.navBarRefresh,
      "can-cancel": true
    },
    on: {
      "update:active": function ($event) {
        return _vm.$set(_vm.data, "navBarRefresh", $event);
      }
    }
  }) : _c('div', {
    staticClass: "project-portfolio-table"
  }, [_c('div', {
    staticClass: "project-portfolio-table__actions"
  }, [_c('div', {
    staticClass: "project-portfolio-table__actions__filter"
  }, [_c('div', {
    staticClass: "project-portfolio-table__actions__filter__select"
  }, [_c('pui-form-select', {
    attrs: {
      "label": "",
      "search-input-placeholder": " ",
      "value": _vm.selectedMtpFilter,
      "options": _vm.mtpOptions
    },
    on: {
      "change": _vm.mtpChanged
    },
    model: {
      value: _vm.selectedMtpFilter,
      callback: function ($$v) {
        _vm.selectedMtpFilter = $$v;
      },
      expression: "selectedMtpFilter"
    }
  })], 1)]), _c('div', {
    staticClass: "project-portfolio-table__actions__button"
  }, [_c('pui-button', {
    attrs: {
      "disabled": _vm.isLinkDisabled
    },
    on: {
      "click": _vm.handleLightboxOpen
    }
  }, [_vm._v(" Link Budget Requests ")])], 1)]), _c('div', {
    staticClass: "project-portfolio-table__table"
  }, [_c('div', {
    staticClass: "project-portfolio-table__table__extra-header"
  }, [_c('div', {
    staticClass: "project-portfolio-table__table__extra-header__title"
  }, [_vm.data.selectedPlantGroup ? _c('pui-headline', {
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.selectedPlantGroup.name) + " ")]) : _vm._e()], 1)]), _c('table-header', {
    attrs: {
      "page-size": _vm.tableExtraFilters.pageSize
    },
    on: {
      "page-size-changed": _vm.onPageSizeChanged,
      "search-triggered": _vm.onTriggerSearch,
      "clear-search-term": _vm.onClearSearchTerm
    }
  }, [_vm.isFilterApplied ? _c('pui-button', {
    attrs: {
      "icon": "reset",
      "state": "primary",
      "small": ""
    },
    on: {
      "click": _vm.resetFilterState
    }
  }, [_vm._v(" Reset All Filters ")]) : _vm._e()], 1), _c('ag-grid-vue', {
    staticClass: "ag-theme-alpine",
    attrs: {
      "grid-options": _vm.gridOptions,
      "column-defs": _vm.columnDefs
    },
    on: {
      "grid-ready": _vm.onGridReady
    }
  })], 1), _c('link-budget-requests-lightbox', {
    attrs: {
      "mtp-year": _vm.selectedMtpFilter,
      "lightbox-open": _vm.isLightboxOpen
    },
    on: {
      "close-lightbox": _vm.handleLightboxClose
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }