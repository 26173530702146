import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import type { ExternalDataMaintenanceInformation } from '~/utils/interfaces';
import moment from 'moment';
import { PuiTableVendorOptions } from '~/models/libraries/pebble-ui';

@Component({})
export default class LinkedMaintenanceCollapsibleComponent extends Vue {
    private readonly pageLayout = {
        HALF_ROW: { s: 12, m: 12, l: 6, xl: 6 }
    };

    @Prop()
    private data!: ExternalDataMaintenanceInformation;

    private showAdjacentCostTable = false;

    private get totalCostColumns(): Record<string, string> {
        return {
            number: 'No',
            date: 'Date',
            totalCost: 'Total Cost',
            outageId: 'Outage Id'
        };
    }

    private get totalCostTableData(): PuiTableVendorOptions {
        const totalCostRows = this.data.maintenancePlanning.map((planning, index) => ({
            [this.totalCostColumns.number]: (index + 1).toString(),
            [this.totalCostColumns.date]: this.formatDate(planning.planningDate.toString()),
            [this.totalCostColumns.totalCost]: planning.totalCost.toString(),
            [this.totalCostColumns.outageId]: planning.outageId
        }));

        return {
            data: totalCostRows,
            columns: Object.values(this.totalCostColumns)
        };
    }

    private get adjacentYearsColumns(): Record<string, string> {
        return {
            number: 'No',
            date: 'Date',
            minusYear2: '-2 YEAR',
            minusYear1: '-1 YEAR',
            currentYear: 'CURRENT YEAR',
            plusYear1: '+1 YEAR',
            outageId: 'Outage Id'
        };
    }

    private get adjacentYearsTableData(): PuiTableVendorOptions {
        const adjacentYearsTableData = this.data.maintenancePlanning.map((planning, index) => ({
            [this.adjacentYearsColumns.number]: (index + 1).toString(),
            [this.adjacentYearsColumns.date]: this.formatDate(planning.planningDate.toString()),
            [this.adjacentYearsColumns.minusYear2]: planning.costTwoYearsPrior.toString(),
            [this.adjacentYearsColumns.minusYear1]: planning.costPreviousYear.toString(),
            [this.adjacentYearsColumns.currentYear]: planning.costCurrentYear.toString(),
            [this.adjacentYearsColumns.plusYear1]: planning.costNextYear.toString(),
            [this.adjacentYearsColumns.outageId]: planning.outageId
        }));

        return {
            data: adjacentYearsTableData,
            columns: Object.values(this.adjacentYearsColumns)
        };
    }

    private toggleTable(): void {
        this.showAdjacentCostTable = !this.showAdjacentCostTable;
    }

    private formatDate(text?: string): string {
        if (text === '' || text === null || text === undefined) {
            return '-';
        } else {
            return moment(text).format('YYYY-MM-DD');
        }
    }

    private replaceNullText(text?: string): string {
        if (text === '' || text === null) {
            return '-';
        } else {
            return text;
        }
    }
}
