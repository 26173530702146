import Vue from 'vue';
import Component from 'vue-class-component';
import { Data, User } from '~/utils';
import { Permission } from '~/utils/permission';
import { Watch } from 'vue-property-decorator';
import { IYBRModalInputs } from './add-in-year-budget-requests-modal/add-in-year-budget-requests-modal';
import MasterDataLoaderErrorComponent from '~/components/master-data-loader-error/master-data-loader-error.vue';
import PlantSelectorComponent from '~/components/plant-selector/plant-selector.vue';

@Component({
    components: {
        masterDataLoaderError: MasterDataLoaderErrorComponent,
        plantSelector: PlantSelectorComponent
    }
})
export default class InYearBudgetRequestsComponent extends Vue {
    private isAccessDeniedModalActive = false;
    private data = Data.Instance;
    private showModal = false;
    private currentYear = new Date(Date.now()).getFullYear();
    private selectedYear: number = null;

    private get canReadSingleSitePage(): boolean {
        return User.hasPermission(Permission.SINGLE_SITE_PAGE_READ);
    }

    private get plantsId(): number[] {
        return this.data.loadedPlants.map((plants) => plants.plantSid);
    }

    async mounted(): Promise<void> {
        this.setSelectedYear();
        this.$root.$on('addMiniFd', (inputs) => {
            this.addNewInYearBudgetRequest(inputs);
        });
    }

    beforeRouteEnter(to, from, next): void {
        Data.Instance.loadFleetHierarchyFromServer().then();
        next(true);
    }

    beforeRouteLeave(to, from, next): void {
        // Prevent user from switching routes if modal is shown.
        next(!this.isAccessDeniedModalActive);
    }

    @Watch('canReadSingleSitePage')
    private loadAccessDeniedModalIfNeeded(): void {
        this.$nextTick(() => {
            if (!this.canReadSingleSitePage) {
                $('#accessDeniedModal').modal('show');
                this.isAccessDeniedModalActive = true;
            } else {
                $('#accessDeniedModal').modal('hide');
                this.isAccessDeniedModalActive = false;
            }
        });
    }

    private addNewInYearBudgetRequest(modalInputs: IYBRModalInputs): void {
        this.$router
            .push({
                path: '/create-in-year-budget-request',
                query: modalInputs,
            })
            .then();
    }

    private onAccessDeniedModalClose(): void {
        this.isAccessDeniedModalActive = false;
    }

    private toggleAddModal(): void {
        this.showModal = !this.showModal;
    }

    private async changeMtpPeriod(): Promise<void> {
        localStorage.setItem('selectedMiniFdYear', this.selectedYear.toString());
    }

    private setSelectedYear(): void {
        this.selectedYear = Data.Instance.currentCrPeriod?.year ?? this.currentYear;
        localStorage.setItem('selectedMiniFdYear', this.selectedYear.toString());
    }

    private get isActiveYear(): boolean {
        return !!this.data.constants.miniFdYearFilters.find(year => year.year === this.selectedYear)?.isActive;
    }
}
