import Vue from 'vue';
import Component from 'vue-class-component';
import { PuiLightbox } from '~/models/libraries/pebble-ui';
import { ROWS } from '~/components/in-year-budget-requests/constants/rows';
import { Prop } from 'vue-property-decorator';
import { MasterService } from '~/services/master-service';

const REFS = {
    LIGHTBOX: 'lightbox',
} as const;

enum DELETION_TYPE {
    SET_AS_INACTIVE = 'inactive',
    DELETE = 'delete',
}

@Component({})
export default class ConfirmIybrDeleteModalComponent extends Vue {
    private readonly REFS = REFS;
    private readonly ROWS = ROWS;
    private readonly DELETION_TYPE = DELETION_TYPE;

    /*
     * Prop used to provide the miniFdId on which the changes need to be applied.
     */
    @Prop({ required: true })
    private miniFdId!: string;

    /*
     * Prop used to provide the isActive state to the modal.
     */
    @Prop({ default: () => true })
    private isActive!: boolean;

    private pendingPromise: Promise<unknown> = Promise.resolve();
    private selectedDeletionType: DELETION_TYPE | '' = '';
    private hasSubmitted = false;

    $refs!: {
        [REFS.LIGHTBOX]: PuiLightbox
    };

    private get isFormValid(): boolean {
        return this.selectedDeletionType !== '';
    }

    public openLightbox(): void {
        this.$refs[REFS.LIGHTBOX].open();
    }

    private async onLightboxConfirm(): Promise<void> {
        this.hasSubmitted = true;
        this.pendingPromise = this.executeDeletion();

        try {
            await this.pendingPromise;

            this.$refs[REFS.LIGHTBOX].close();
            this.$router.push({ path: '/in-year-budget-requests' });
        } catch {
            this.hasSubmitted = false;
        }
    }

    private onLightboxBeforeClose(): void {
        this.resetFormState();
    }

    private async executeDeletion(): Promise<void> {
        switch (this.selectedDeletionType) {
            case DELETION_TYPE.SET_AS_INACTIVE:
                await MasterService.instance.miniFdService.setMiniFdInactive(this.miniFdId);
                break;
            case DELETION_TYPE.DELETE:
                await MasterService.instance.miniFdService.deleteMiniFd(this.miniFdId);
                break;
        }
    }

    private resetFormState(): void {
        this.selectedDeletionType = '';
        this.hasSubmitted = false;
    }
}
