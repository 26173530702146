import { render, staticRenderFns } from "./uam-user-input.vue?vue&type=template&id=2c1528ae&"
import script from "./uam-user-input.ts?vue&type=script&lang=ts&"
export * from "./uam-user-input.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports