import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import 'vue-awesome/icons/plus';
import '~/components/home/home.scss';
import AccordionBudgetRequestsComponent from './accordion-budget-requests/accordion-budget-requests.vue';
import { Data, User } from '~/utils';
import FilesBudgetRequestsComponent from './files-budget-requests/files-budget-requests.vue';
import MasterDataLoaderErrorComponent from '~/components/master-data-loader-error/master-data-loader-error.vue';


@Component({
    components: {
        'accordion-budget-request': AccordionBudgetRequestsComponent,
        'files-budget-request': FilesBudgetRequestsComponent,
        'master-data-loader-error': MasterDataLoaderErrorComponent
    }
})
export default class BudgetRequestsComponent extends Vue {
    private addBudRequest: boolean = true;
    private userPermissions = <any>[];
    private isAccessDeniedModalActive: boolean = false;
    private data = Data.Instance;

    async created() {
        this.userPermissions = await User.getPerm();
        if (!this.userPermissions.includes('SingleSitePageView')) {
            $('#accessDeniedModal').modal('show');
            this.isAccessDeniedModalActive = true;
        }
    }

    beforeRouteLeave(to, from, next) {
        $('#stateEditModal').modal('hide');
        this.$bvToast.hide('example-toast');
        this.$bvToast.hide('toast-mtp');
        this.$bvToast.hide('add-to-current-mtp');
        this.$bvToast.hide('inactive');
        this.$bvToast.hide('toast-error-delete-file');
        this.$bvToast.show('toast-error-import-file');
        $('#updateMtpModal').modal('hide');
        $('#changeReviewModal').modal('hide');
        $('#removeFromMtpTableModal').modal('hide');
        $('#removeBrModal').modal('hide');
        $('#setInactiveModal').modal('hide');
        $('#deleteRemainTableModal').modal('hide');
        $('#deleteBrModal').modal('hide');
        $('#errorModal').modal('hide');
        $('#addBudgetRequestsModal').modal('hide');
        $('#deleteFileModal').modal('hide');
        $('#confirmDeleteModal').modal('hide');
        $('#moveCurrentMtpWarningModal').modal('hide');
        $('#bulkApproveModal').modal('hide');
        sessionStorage.removeItem('redirectUrl');
        if (this.isAccessDeniedModalActive) {
            next(false);
        } else {
            next(true);
        }
    }

    private onClickCancel(value) {
        this.isAccessDeniedModalActive = value;
    }
}
