export const REQUEST_TYPES_INFO = [
    {
        requestType: 'Not released (Flagged project)',
        requestInfo: 'The project was planned in MTP and flagged in PML during the review process.'
    },
    {
        requestType: 'New project',
        requestInfo: 'The project was not planned in MTP. Budget compensation is required.'
    },
    {
        requestType: 'Budget overrun',
        requestInfo: 'It refers to a potential overspend of >10% of the approved total project value or >0,1m€.'
    },
    {
        requestType: 'Advanced release',
        requestInfo: 'The project was originally planned for future years in the MTP but need to be preponed. Or a prepayment is needed.'
    },
] as const;

export const MINIFD_TYPES_INFO = [
    {
        miniFdType: 'MFD',
        miniFdInfo: 'Mini Financial Decision'
    },
    {
        miniFdType: 'FD',
        miniFdInfo: 'Financial Decision'
    },
    {
        miniFdType: 'SFD',
        miniFdInfo: 'Simplified Financial Decision'
    },
] as const;
