var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "accordion-focus-topics",
    attrs: {
      "id": "accordion"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header",
    attrs: {
      "id": "heading_accordion"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_c('button', {
    staticClass: "btn",
    attrs: {
      "aria-controls": "collapseOne",
      "aria-expanded": "true",
      "data-target": "#collapseOne",
      "data-toggle": "collapse"
    }
  }, [_vm._v(" Focus Topics "), _c('icon', {
    attrs: {
      "name": "chevron-down"
    }
  })], 1)])])]), _c('div', {
    staticClass: "collapse show budget-request-wrapper",
    attrs: {
      "id": "collapseOne",
      "aria-labelledby": "heading_accordion",
      "data-parent": "#accordion"
    }
  }, [_c('div', {
    staticClass: "card-body pb-0"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm.focusTopics ? _c('table', {
    staticClass: "table mb-0"
  }, [!_vm.loading ? _c('thead', [_vm._m(0)]) : _vm._e(), !_vm.loading ? _c('tbody', _vm._l(_vm.focusTopics, function (el, key) {
    var _el$updatedAt, _el$updatedBy;

    return _c('tr', {
      key: key
    }, [_c('td', {
      staticClass: "actions-column"
    }, [_vm.hasPermissionToEdit ? _c('button', {
      staticClass: "btn btn-action",
      attrs: {
        "disabled": _vm.isRequestPending
      },
      on: {
        "click": function ($event) {
          return _vm.activateFocusTopic(el);
        }
      }
    }, [_c('icon', {
      staticClass: "icon-edit",
      attrs: {
        "name": "plug"
      }
    }), _c('span', [_vm._v(_vm._s(el.isActive ? "Deactivate" : "Activate"))])], 1) : _vm._e(), _vm.hasPermissionToEdit ? _c('button', {
      class: ['btn btn-action', {
        'btn-action--disabled': !el.canBeDeleted
      }],
      attrs: {
        "disabled": !el.canBeDeleted || _vm.isRequestPending
      },
      on: {
        "click": function ($event) {
          return _vm.editFocusTopic(el);
        }
      }
    }, [_c('icon', {
      staticClass: "icon-edit",
      attrs: {
        "name": "pencil-alt"
      }
    }), _c('span', [_vm._v("Edit")])], 1) : _vm._e(), _vm.hasPermissionToEdit ? _c('button', {
      class: ['btn btn-action btn-action--delete', {
        'btn-action--disabled': !el.canBeDeleted
      }],
      attrs: {
        "disabled": !el.canBeDeleted || _vm.isRequestPending
      },
      on: {
        "click": function ($event) {
          return _vm.deleteFocusTopic(el);
        }
      }
    }, [_c('icon', {
      staticClass: "icon-edit",
      attrs: {
        "name": "trash-alt"
      }
    }), _c('span', [_vm._v("Delete")])], 1) : _vm._e()]), _c('td', [_vm._v(_vm._s(el.name))]), _c('td', [_vm._v(_vm._s((_el$updatedAt = el.updatedAt) !== null && _el$updatedAt !== void 0 ? _el$updatedAt : el.createdAt))]), _c('td', [_vm._v(_vm._s((_el$updatedBy = el.updatedBy) !== null && _el$updatedBy !== void 0 ? _el$updatedBy : el.createdBy))])]);
  }), 0) : _vm._e()]) : _vm._e(), _vm.focusTopics.length === 0 ? _c('div', {
    staticClass: "table pl-3 pt-3 pb-3"
  }, [_vm._v(" No Focus Topics Found. ")]) : _vm._e()])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('th', [_vm._v("Action")]), _c('th', [_vm._v("Name")]), _c('th', [_vm._v("Updated At")]), _c('th', [_vm._v("Updated By")])]);
}]

export { render, staticRenderFns }