import { Connection } from '~/utils/';
import { BulkApprovePayload, BulkApproveResponse } from '~/models/services/approve';
import { ApiResponse } from '~/models/services/common';

export class ApproveService {
    private readonly api: Connection = new Connection();

    public async BulkApprove(bulkApprove: BulkApprovePayload): Promise<BulkApproveResponse> {
        return (await this.api.post<ApiResponse<BulkApproveResponse>>('external/budget-request/approve', bulkApprove)).data.result;
    }
}
