import { MiniFdPeriod } from '~/models/services/cr-period';
import { UserPermissions } from '@coode/fe-sdk/dist/types';
import { Tag } from '~/models/services/tags';

export interface BreadcrumbLink {
    label: string;
    href: string;
}

export interface PuiFormMultiselectOption<T> {
    label: string;
    secondaryLabel?: string;
    value: T;
}

export interface Currency {
    code: string;
    value: number;
    symbol: string;
}

export interface ExternalDataRequest {
    budgetRequestId: string;
    externalIds: string[];
    mtpYear: number;
    userSelectionMaintenancePlanningIds: string[];
}

export interface ExternalDataResponse {
    isRequestValid: boolean;
    errorMessage?: string;
    data: ExternalDataInformation;
}

export type ExternalDataInformation =
    ExternalDataRiskInformation
    | ExternalDataOpportunityInformation
    | ExternalDataMaintenanceInformation;

export interface ExternalDataRiskInformation {
    riskId: string;
    riskTitle: string;
    riskStatus: string;
    englishTitleShortForFcm: string;
    strategyCost: number;
    mitigatedIrr: number;
    costRiskBeforeTotalScore: number;
    costRiskAfterTotalScore: number;
    safetyRiskBeforeTotalScore: number;
    safetyRiskAfterTotalScore: number;
    environmentRiskBeforeTotalScore: number;
    environmentRiskAfterTotalScore: number;
    regulatoryRiskBeforeTotalScore: number;
    regulatoryRiskAfterTotalScore: number;
    reputationRiskBeforeTotalScore: number;
    reputationRiskAfterTotalScore: number;
    dataToBeMappedInPml: ExternalDataToBeMappedInPml;
    currencySymbol: string;
    uEL?: number;
}

export interface ExternalDataMaintenanceInformation {
    maintenanceId: string;
    maintenanceName: string;
    maintenanceLocalLanguageName?: any;
    maintenanceActivity: string;
    maintenanceType: string;
    category: string;
    justification: string;
    planningAndControllingClassification: string;
    projectType: string;
    outageType: string;
    lumpSum: string;
    nextPlannedStartDate?: any;
    lastMaintenanceDate: Date;
    nextPlannedStartDateDuration?: any;
    lastMaintenanceDateDuration: number;
    maintenancePlanning: ExternalMaintenancePlanning[];
    dataToBeMappedInPml: ExternalMaintenanceDataToBeMappedInPml;
}

export interface ExternalMaintenanceDataToBeMappedInPml {
    name: string;
    localLanguageName?: any;
    projectType: number;
    aeroClassification: number;
    maintenanceType: number;
    justificationOnClassification: string;
    lumpSum: boolean;
    planningAndControllingClassification: number;
    outageIds: string;
    outageType: number;
    forecast: number;
    spentUntil: number;
    nextOneYear: number;
    nextTwoYears: number;
    nextThreeYears: number;
    after: number;
    startDate?: any;
    endDate?: any;
    maintenanceId: string;
}

export interface ExternalMaintenancePlanning {
    id: number;
    planningDate: Date;
    totalCost: number;
    costTwoYearsPrior: number;
    costPreviousYear: number;
    costCurrentYear: number;
    costNextYear: number;
    outageId: string;
    isSelected: boolean;
}

export interface ExternalDataOpportunityInformation {
    opportunityId: string;
    opportunityTitle: string;
    opportunityStatus: string;
    investmentCost: number;
    irr: number;
    evaluationPeriod: number;
    paybackTime: number;
    dataToBeMappedInPml: ExternalDataToBeMappedInPml;
    currencySymbol: string;
}

export interface ExternalDataToBeMappedInPml {
    externalId: string;
    aeroClassification: number;
    nextMtpYearCost: number;
    name: string;
    nameLocalLanguage: string;
}

export enum MtpStatus {
    DRAFT = 1,
    SUBMITTED = 2,
    FLAGGED = 3,
    REVIEWED = 4,
    APPROVED = 5
}

export enum BudgetRequestPlanningStatus {
    FLAGGED = 1,
    NEW_PROJECT = 2,
    BUDGET_OVERRUN = 3,
    ADVANCE_RELEASE = 4
}

export enum PeriodTypes {
    MTP = 1,
    CR = 2
}

export interface Plants {
    plantId: string;
    technologyId: string;
    countryId: string;
    currencyId: string;
    name: string;
}

export interface Plant {
    id: string;
    plantSId: string;
    name: string;
    technology: string;
    technologySid: string;
    plantGroupName: string;
    country: {
        currency: {
            code: '';
            id: ''
        };
        name: '',
        code: ''
    };
    units: [{
        name: '';
        unitSid: '';
    }];
    currency: string;
}

export interface BudgetRequests {
    budgetRequests: SearchBudgetRequests[];
    total: string;
}

export interface SearchBudgetRequestsMtpPlanning {
    year: number;
    wbsElement: string;
    mtpStatus: number;
    miniFdStatus?: number;
    mainFlag: number;
    otherFlags: number[];
    fundingStatus: number;
    spendUntilEndOfYear: number;
    previousMtp1: number;
    previousMtp2: number;
    previousMtp3: number;
    previousTotal: number;
    forecast: number;
    new1: number;
    new2: number;
    new3: number;
    forecastEur: number;
    new1Eur: number;
    new2Eur: number;
    new3Eur: number;
    after: number;
    total: number;
    previousCrYear1?: number;
    previousCrYear2?: number;
    previousCrYear3?: number;
    previousCrTotal?: number;
    comment: string;
    hasDifferences: boolean | null;
    outageType: number;
    outageId: string;
    hasChangeRequest: boolean;
    miniFdYear: number;
    startDate: string;
    hasPlanningCosts: boolean;
}

export interface SearchBudgetRequests {
    id: string;
    changeRequestId?: string;
    plantName: string;
    plantGroupName: string;
    selectedCurrencyCode: string;
    countryCodes: string[];
    globalId: string;
    name: string;
    localLanguageName: string;
    unitName: string;
    projectType: number;
    lumpSum: boolean;
    aeroClassification: number;
    justificationOnClassification: string;
    maintenanceType: number;
    riskOpportunityId: string;
    procurementCategory: number;
    planningAndControllingClassification: number;
    uniperShareOfBudget: number;
    startDate: Date;
    endDate: Date;
    primaryDepartment: string;
    prioritizationCategory: number;
    prioritizationRank: string;
    created: Date;
    createdBy: string;
    modified: Date;
    modifiedBy: string;
    currentMtpPlanning: SearchBudgetRequestsMtpPlanning;
}

export interface BudgetRequestsLivePml {
    budgetRequests: SearchBudgetRequestsLivePml[];
    total: string;
}

export interface SearchBudgetRequestsLivePml {
    id: string;
    globalId: string;
    changeRequestId: string;
    unitSid: number;
    plantSid: number;
    technologySid: number;
    plantName: string;
    technologyName: string;
    plantGroupName: string;
    unitName: string;
    name: string;
    localLanguageName: string;
    selectedCurrencyCode: string;
    countryName: string;
    projectType: number;
    created: Date;
    createdBy: string;
    miniFdRequestType: number;
    year: number;
    wbsElement: string;
    lumpSum: boolean;
    aeroClassification: number;
    justificationOnClassification: string;
    maintenanceType: number;
    outageType: number;
    outageId: string;
    riskOpportunityId: string;
    procurementCategory: number;
    planningAndControllingClassification: number;
    uniperShareOfBudget: number;
    hasPlanningCosts: boolean;
    startDate: Date;
    endDate: Date;
    primaryDepartment: number;
    prioritizationCategory: number;
    prioritizationRank: number;
    modified: Date;
    modifiedBy: string;
    mtpStatus: number;
    mainFlag: number;
    otherFlags: number[];
    fundingStatus: number;
    spendUntilEndOfYear: number;
    previousMtp1: number;
    previousMtp2: number;
    previousMtp3: number;
    previousTotal: number;
    forecast: number;
    new1: number;
    new2: number;
    new3: number;
    after: number;
    total: number;
    previousCrYear1: number;
    previousCrYear2: number;
    previousCrYear3: number;
    previousCrTotal: number;
    comment: string;
    reviewComment: {
        text: string;
        date: Date;
        userName: string;
    };
    reviewers: string;
    hasDifferences: boolean;
    usefulEconomicLife: number;
    mtpTags: string;
    focusTopics: {
        id: number;
        mtpId: string;
        focusTopicId: string;
        createdAt: Date;
        createdBy: string;
        tagName: string;
    }[];
    forecastEur: number;
    new1Eur: number;
    new2Eur: number;
    new3Eur: number;
}

export interface BudgetRequestHistory {
    activityTrackings: ActivityTrackings[];
    total: string;
}

export interface ActivityTrackings {
    date: string;
    kId: string;
    activityType: string;
    description: string;
}

export interface MtpFilterEntry {
    year: number;
    miniFdYear: number;
    description: string;
}

export interface MiniFdYearFilterEntry {
    year: number;
    description: string;
    isActive: boolean;
}

export interface LivePmlFilterEntry {
    year: number;
    description: string;
}

export interface Constants {
    mtpFilters: [{
        year: number;
        description: string;
    }];
    flags: {};
    aeroClassification: {};
    maintenanceType: {};
    outageType: {};
    planningAndControllingClassification: {};
    procurementCategory: {};
    focusTopics: {};
    livePmlFilters: LivePmlFilterEntry[];
    projectTypes: {};
    mtpStatus: {};
    currencies: [{
        id: string;
        code: string
    }];
    fundingStatus: {};
    primaryDepartment: {};

}

export interface NewBudgetRequests {
    id: string;
    globalId: string;
    plantSid: number;
    unitId: string;
    unitSid: number;
    createdBy: string;
    name: string;
    localLanguageName: string;
    unitName: string;
    plantName: string;
    groupName: string;
    technologyName: string;
    technologySid: number;
    countryCode: string;
    countryName: string;
    projectType: number;
    created: Date;
    isActive: boolean;
    isNew: boolean;
    planning: {
        year: number;
        selectedCurrencyId: string;
        wbsElement: string;
        lumpSum: boolean;
        aeroClassification: number;
        justificationOnClassification: string;
        maintenanceType: number;
        outageType: number;
        outageId: string;
        riskOpportunityId: string;
        procurementCategory: number;
        planningAndControllingClassification: number;
        reviewComment?: {
            date: Date;
            text: string;
        };
        uniperShareOfBudget: number;
        startDate: any;
        endDate: any;
        primaryDepartment: number;
        prioritizationCategory: number;
        prioritizationRank: number;
        modified: Date;
        modifiedBy: string;
        mtpStatus: MtpStatus;
        mainFlag: number;
        otherFlags: number[];
        fundingStatus: number;
        spendUntilEndOfYear: number;
        forecast: number;
        new1: number;
        new2: number;
        new3: number;
        after: number;
        total: number;
        projectType: number;
        comment: string;
        maintenancePlanningIds: string;
        attachedExternalId: string;
        hasDifferences: boolean | null;
        usefulEconomicLife?: number | string;
        hasPlanningCosts?: boolean;
        mtpTags: Tag[];
    };
}

export interface ValidateBudgetRequests {
    unit: boolean;
    name: boolean;
    projectType: boolean;
    lumpSum: boolean;
    startDate: boolean;
    endDate: boolean;
    aero: boolean;
    justification: boolean;
    primaryDepartment: boolean;
    maintenanceType: boolean;
    outageType: boolean;
    outageId: boolean;
    riskId: boolean;
    procurementCategory: boolean;
    wbs: boolean;
    planning: boolean;
    uniperShareOfBudget: boolean;
    currency: boolean;
}

export interface ValidateMappings {
    namesAreEqual: boolean;
    nameLocalLanguagesAreEqual: boolean;
    projectTypesAreEqual: boolean;
    maintenanceTypesAreEqual: boolean;
    aeroClassificationsAreEqual: boolean;
    justificationOnClassificationsAreEqual: boolean;
    lumpSumsAreEqual: boolean;
    planningAndControllingClassificationsAreEqual: boolean;
    outageIdsAreEqual: boolean;
    outageTypesAreEqual: boolean;
    forecastsAreEqual: boolean;
    spentUntilsAreEqual: boolean;
    nextOneYearsAreEqual: boolean;
    nextTwoYearsAreEqual: boolean;
    nextThreeYearsAreEqual: boolean;
    aftersAreEqual: boolean;
    totalsAreEqual: boolean;
    startDatesAreEqual: boolean;
    endDatesAreEqual: boolean;
}

export interface ReviewBudgetRequest {
    budgetRequestId: string;
    mtpYear: number;
    mainFlag: number;
    otherFlags: number[];
    comment: {
        text: string;
        date: Date
    };
    mtpStatus: number;
}

export interface SearchModel {
    plantIds: string[];
    mtpYear: number;
    miniFdYear?: number;
    itemsPerPage: number;
    pageNo: number;
    isActive: boolean;
    filters: {
        mtpStatusFilters: number[];
        projectTypeFilters: number[];
        aeroClassificationFilters: number[];
        prioritizationCategoryFilters: number[];
        maintenanceTypeFilters: number[];
        outageTypeFilters: number[];
        planningAndControllingClassificationFilters: number[];
        fundingStatusFilters: number[];
        projectStatusTypeFilters: number[];
        primaryDepartmentFilters: number[];
        mainFlagFilters: number[];
        lumpSumFilters: any[];
        procurementCategoryFilters: number[];
        focusTopicFilters: string[];
        miniFdStatusFilters: number[];
        requestTypeFilters: number[];
        isReadyForApproval: boolean;
        isCrReadyForApproval?: boolean;
    };
    searchText: string;
}

export interface HistorySearchModel {
    globalId: string;
    plantIds: string[];
    itemsPerPage: number;
    pageNo: number;
    filters: {
        activityTypes: number[];
        date: number;
        range: {
            startDate: string;
            endDate: string;
        }
    };
}

export interface History {
    plantActivityTrackings?: PlantActivityTrackings[];
    total: string;
}

export interface PlantActivityTrackings {
    date: string;
    kId: string;
    globalId: string;
    activityType: string;
}

export type Period = (Partial<MtpPeriod> | Partial<MiniFdPeriod>) & {
    id?: string,
    startDate?: any,
    endDate?: any
};

export interface MtpPeriod {
    year: number;
    mtpStartDate: any;
    mtpEndDate: any;
}

export interface ValidateMtpPeriod {
    mtpStartDate: boolean;
    mtpEndDate: boolean;
}

export interface SelectOption {
    value: number | string | boolean;
    secondaryLabel?: string;
    label: string;
}

export interface PeriodConfig {
    [key: number]: {
        addNew: string;
        edit: string;
        path: string;
        startDateProperty: string;
        endDateProperty: string;
        getAction: any;
        addAction: any;
        editAction: any;
        validationType: number;
        hasIdInPayload: boolean;
    };
}

export type ExtendedUserPermissions = UserPermissions & {
    email: string;
    name: string;
    kid: string;
};

export interface GlobalIdsToCombineResult {
    globalIds: GlobalIdToCombine[];
    count: number;
}

export interface GlobalIdToCombine {
    globalId: string;
    id: string;
}
