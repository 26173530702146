var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.showCheckMark ? _c('pui-icon', {
    attrs: {
      "icon-color": _vm.color,
      "icon-name": "check"
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.title) + " ")], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }