var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal fade",
    attrs: {
      "id": "exportPrioritizationModal",
      "aria-hidden": "true",
      "aria-labelledby": "exampleModalLabel",
      "data-backdrop": "static",
      "role": "dialog",
      "tabindex": "-1"
    }
  }, [_c('div', {
    staticClass: "modal-dialog",
    attrs: {
      "role": "document"
    }
  }, [_c('div', {
    staticClass: "modal-content"
  }, [_vm._m(0), _c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('p', [_vm._v("This functionality will prelucrate all the budget requests from the current year.")]), _c('p', [_vm._v(" If you want to exclude some budget requests you have to enter the global Id for the specific budget request. ")]), _c('pui-form-group', {
    attrs: {
      "label": "Global IDs list",
      "label-for": "globalIdsListInput"
    }
  }, [_c('pui-form-input-field', {
    attrs: {
      "autofocus": true,
      "placeholder-text": "Enter Global IDs"
    },
    model: {
      value: _vm.globalIdsListInput,
      callback: function ($$v) {
        _vm.globalIdsListInput = $$v;
      },
      expression: "globalIdsListInput"
    }
  })], 1)], 1)])])]), _c('div', {
    staticClass: "modal-footer"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary",
    attrs: {
      "data-dismiss": "modal",
      "type": "button"
    }
  }, [_vm._v(" CANCEL ")]), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.exportGlobalIds();
      }
    }
  }, [_vm._v(" EXPORT ")])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal-header"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('h5', {
    staticClass: "modal-title",
    attrs: {
      "id": "exampleModalLabel"
    }
  }, [_vm._v(" Export Project Prioritization Excel ")])])])]), _c('button', {
    staticClass: "close",
    attrs: {
      "aria-label": "Close",
      "data-dismiss": "modal",
      "type": "button"
    }
  }, [_c('span', {
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("×")])])]);
}]

export { render, staticRenderFns }