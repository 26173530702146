import { NewBudgetRequests } from '~/utils/interfaces';
import { BudgetCompensation, BudgetRequest, BudgetRequestPlanning } from '~/models/services/budget-request';
import { BudgetCompensationType } from "~/models/services/constants-list"
import { Tag } from './tags';

export interface SearchMiniFdRequestItemStakeholder {
    id: string;
    miniFdId: string;
    kId: string;
    name: string;
    departmentId: string;
    approvalDate: string;
    rejectionDate: string;
    departmentGroupName: string;
}

export type AddBudgetCompensationEntry = {
    budgetRequestId: string;
    justification: number;
    yearPlus1?: number;
    yearPlus2?: number;
    yearPlus3?: number;
    requestedYearPlus1?: number;
    requestedYearPlus2?: number;
    requestedYearPlus3?: number;
    selectedMtpYear: number;
};

export type GetBudgetCompensationEntry = AddBudgetCompensationEntry & {
    projectName: string;
    globalId: string;
    original1: number;
    original2: number;
    original3: number;
};

export enum MiniFdStatus {
    DRAFT = 1,
    SUBMITTED = 2,
    IN_REVIEW = 3,
    APPROVED = 4,
    REJECTED = 5
}

export type AddMiniFdRequestData = {
    globalId?: string[];
    globalIds?: string[];
    miniFinancialDecisionId?: string;
    miniFinancialDecisions?: GetMiniFdResponse[],
    projectName: string;
    projectSapId: string;
    currencyId: string;
    planningAndControllingClassification: number;
    category: number;
    startDate: string;
    endDate: string;
    latestDateForDecision: string;
    miniFdSummary: string;
    uniperShareOfBudget: number;
    budgetCompensationType: number;
    outageType: number;
    outageIds: string[];
    projectType: number;
    requestType: number;
    externalIds?: string[];
    year: number;
    budgetCompensations: AddBudgetCompensationEntry[];
    miniFdStakeholders: SelectedStakeholder[];
    furtherFinancialParameters: string;
    maintenanceType: number;
    primaryDepartment: number;
    procurementCategory: number;
    procurementInvolvment: string;
    projectAnchoring: string;
    projectSetup: string;
    riskAndOpportunities: string;
    status: MiniFdStatus;
    budgetRequests?: {
        plantSid: number;
        technologySid: number;
        unitSid: number;
        name: string;
        localLanguageName: string;
        unitName: string;
        plantName: string;
        groupName: string;
        technologyName: string;
        countryCode: string;
        countryName: string;
        projectType: number;
        planning?: BudgetRequestPlanning;
    }[];
    budgetRequestsMiniFinancialDecisions: BudgetRequestsMiniFinancialDecision[];
    tags?: Tag[];
    miniFdTags?: string[];
    miniFdType?: number;
};

export interface BudgetRequestsMiniFinancialDecision {
    year: number;
    spentYearMinus1: number;
    spentYear: number;
    spentYearPlus1: number;
    spentYearPlus2: number;
    spentYearPlus3: number;
    spentAfterYear: number;
    globalId?: string;
    budgetRequestId?: string;
    budgetRequest?: NewBudgetRequests;
}

export type EditMiniFdRequestData = AddMiniFdRequestData & { id: string };

export type MiniFdFilters = {
    unitSidFilters?: number[],
    requestTypeFilters?: number[],
    categoryFilters?: number[],
    controllingCategoryFilters?: number[],
    localBudgetCompensationFilter?: number[],
    outageTypeFilter?: number[],
    statusFilter?: number[]
    yearFilter?: number;
};
export type SearchMiniFdRequestsPayload = {
    plantIds: number[];
    itemsPerPage: number;
    pageNo: number;
    filters: MiniFdFilters;
    searchText: string;
};

export interface SearchMiniFdResponse {
    miniFdRequests: SearchMiniFdRequestItem[];
    total: number;
}

export interface SearchMiniFdRequestItem {
    id: string;
    uniqueId: string;
    globalIds: string[];
    projectName: string;
    countryName: string;
    localLanguageName: string;
    plantSid: number;
    plantName: string;
    technologySid: number;
    technologyName: string;
    unitSid: number;
    unitName: string;
    projectSapId: string;
    category: number;
    outageType: number;
    outageId: string;
    startDate: string;
    endDate: string;
    latestDateForDecision: string;
    planningAndControllingClassification: number;
    requestType: number;
    budgetCompensation: number;
    miniFdSummary: string;
    createdBy: string;
    dateCreated: string;
    dateApproved: string;
    stakeholders: SearchMiniFdRequestItemStakeholder[];
    uniperShareOfBudget: number;
    mtps: SearchMiniFdResponseMtp[];
    totalBudget: number;
    compensationPlanGlobalIds: number[];
    projectPrioritizationRank: number;
    status: number;
    mainFlag?: number;
    year: number;
    mtpYear: number;
    budgetRequestsMiniFinancialDecision: BudgetRequestMiniFinancialDecision[];
}

export type MiniFd = {
    id: string,
    uniqueId: string,
    plantSid: string,
    budgetCompensation: number,
    plantName: string,
    technologyName: string,
    unitName: string,
    spentYearMinus1: number;
    spentYear: number;
    spentYearPlus1: number;
    spentYearPlus2: number;
    spentYearPlus3: number;
    spentAfterYear: number;
    miniFdStakeHolders: Stakeholder[],
    miniFdComments: MiniFdComment[],
    createdBy: string,
    mainFlag: number
} & AddMiniFdRequestData;

export type GetMiniFdByGlobalIdResponse =
    Omit<MiniFd, 'budgetRequests' | 'budgetCompensations' | 'miniFdStakeholders'>
    &
    {
        miniFinancialDecisions: {
            uniqueId: string;
        }[],
        budgetRequests: NewBudgetRequests[],
        budgetCompensations: GetBudgetCompensationEntry[],
        miniFdStakeholders: Stakeholder[],
        workflowInstanceId: string,
        isActive: boolean
    };

export type GetMiniFdResponse = {
    miniFdRequests: MiniFd[],
    total: number
};

export type Department = {
    id: string,
    name: string,
    from: number,
    to: number
};

export type DepartmentGroups = {
    name: string,
    departments: Department[]
};

export type Stakeholder = {
    id: string,
    kId: string,
    name: string,
    departmentId: string,
    approvalDate: string,
    rejectionDate: string,
    departmentGroupName: string,
    isReviewer: boolean
};

export type SelectedStakeholder = {
    id?: string,
    kId: string,
    name: string,
    departmentId: string,
    isReviewer: boolean
};

export type MiniFdComment = {
    id: string,
    miniFdId: string,
    name: string,
    text: string,
    createdAt: string
};

export type AddMiniFdComment = {
    miniFdId: string,
    text: string,
};

export type ReviewComment = {
    UserComment: string | null;
};

export type MiniFdReview = {
    workflowActionId: number,
    content: ReviewComment | null;
};

export type SearchMiniFdResponseMtp = {
    id: string;
    createdBy: string;
    created: string;
    updatedAt: string;
    updatedBy: string;
    isDeleted: boolean;
    year: number;
    selectedCurrencyId: string;
    wbsElement: string;
    lumpSum: boolean;
    aeroClassification: number;
    justificationOnClassification: string;
    maintenanceType: number;
    outageType: number;
    outageId: string;
    riskOpportunityId: string;
    procurementCategory: number;
    planningAndControllingClassification: number;
    uniperShareOfBudget: number;
    startDate: string;
    endDate: string;
    primaryDepartment: number;
    prioritizationCategory: number;
    prioritizationRank: number;
    modified: string;
    modifiedBy: string;
    mtpStatus: number;
    mainFlag: number;
    otherFlags: number[];
    fundingStatus: number;
    spendUntilEndOfYear: number;
    forecast: number;
    new1: number;
    new2: number;
    new3: number;
    after: number;
    total: number;
    reviewers: string;
    projectType: number;
    maintenancePlanningIds: string;
    comment: string;
    reviewComment: MtpReviewComment;
    attachedExternalId: string;
    hasDifferences: boolean;
};

export type MtpReviewComment = {
    text: string;
    date: string;
    userName: string;
};

export type BudgetRequestMiniFinancialDecision = {
    id: number;
    year: number;
    spentYearMinus1: number;
    spentYear: number;
    spentYearPlus1: number;
    spentYearPlus2: number;
    spentYearPlus3: number;
    spentAfterYear: number;
    total: number;
    globalId: string;
    miniFdId: string;
    budgetRequestsId: string;
    budgetRequest: BudgetRequest;
    miniFinancialDecision: MiniFd;
};

export type SearchMiniFdHistoryPayload = {
    pageSize: number,
    page: number,
    miniFdId: string
};

export type GetMiniFdHistoryResponse = {
    activityTrackings: GetMiniFdHistoryEntry[],
    total: number
};

export type GetMiniFdHistoryEntry = {
    date: string,
    kId: string,
    activityType: string,
    description: string
};

export type ChangeStatusPayload = {
    financialId: string,
    newStatus: number,
    kid: string
};

export type EditBudgetCompensationPayload = {
    miniFdId: string,
    budgetCompensationType: BudgetCompensationType,
    budgetCompensations: AddBudgetCompensationEntry[]
};

export type EditBudgetCompensationResponse = {
    budgetCompensations: BudgetCompensation[]
};

export type GetMiniFdContributorsResponse = {
    contributorsKids: string[]
};

export type ChangeStatusResponse = {
    response: boolean
};

export type GetDepartmentsResponse = {
    departmentGroups: DepartmentGroups[]
};

export type SetInactiveMiniFdResponse = {
    miniFdId: string,
    isActive: boolean
};

export type DeleteMiniFdResponse = {
    miniFdId: string,
    isDeleted: boolean
};

export enum DepartmentGroup {
    PLANT_MANAGER,
    PRIMARY_DEPARTMENT,
    ASSET_STRATEGY,
    ASSET_OPERATIONS,
    ASSET_MANAGEMENT,
    BUSINESS_CONTROLLING
}

export type EditReviewerInApprovalWorkflowPayload = {
    miniFdId: string,
    stakeholders: {
        departmentGroup: DepartmentGroup,
        stakeholderKid: string,
        stakeholderName: string
    }[],
};

export type EstimatedPrioritizationBudget = {
    globalId: string;
    prioritizationCategory?: number;
    prioritizationRank?: number;
    oldPrioritizationCategory?: number;
    oldPrioritizationRank?: number;
}

export type EstimatedPrioritization = {
    hasData: boolean;
    draftPrioritizationRanBy?: string;
    dateOfDraftPrioritizationRun?: string;
    budgets?: EstimatedPrioritizationBudget[];
}
