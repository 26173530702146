import Vue from 'vue';
import Component from 'vue-class-component';
import { FocusTopicsService } from '~/services/focus-topics-service';
import { FocusTopic } from '~/models/services/focus-topics';
import { SelectOption } from '~/utils/interfaces';
import { Prop } from 'vue-property-decorator';
import { AddMtpTagsPayload } from '~/models/services/mtp';
import { MtpService } from '~/services/mtp-service';
import { Tag, TagsConfig, TagsMode } from '~/models/services/tags';
import { CrService } from '~/services/cr-service';
import { AddMiniFdTagsPayload } from '~/models/services/cr-period';
import PmlTypeAhead from '~/components/input/pml-type-ahead/pml-type-ahead.vue';

const REF_CONSTANTS = {
    PML_TYPE_AHEAD: 'pml-type-ahead',
} as const;

@Component({
    components: {
        PmlTypeAhead,
    }
})
export default class TagSelectorComponent extends Vue {
    public FocusTopicService: FocusTopicsService = new FocusTopicsService();
    public MtpService: MtpService = new MtpService();
    public CrService: CrService = new CrService();

    private readonly REF_CONSTANTS = REF_CONSTANTS;
    $refs!: {
        [REF_CONSTANTS.PML_TYPE_AHEAD]: PmlTypeAhead & {
            clearInput: () => void;
        };
    };

    private selectedOption: SelectOption | null = null;
    private options: FocusTopic[] = [];
    private isLoading: boolean = false;

    private readonly CONFIG: TagsConfig = {
        [TagsMode.BR]: {
            importFn: this.importMtpTags,
        },
        [TagsMode.CR]: {
            importFn: this.importMiniFdTags,
        },
        [TagsMode.EMIT]: {
            importFn: this.emitTags,
        }
    } as const;

    @Prop()
    private currentMode!: TagsMode;

    @Prop()
    requestId: string;
    
    @Prop({ default: () => [] })
    hiddenOptions: Tag[];

    private get isDisabled() {
        return !this.selectedOption?.value || this.isLoading;
    }

    private get selectionOptions(): SelectOption[] {
        return this.options
            .filter(item => !this.hiddenOptions.some(hiddenOption => hiddenOption.focusTopicId === item.id))
            .map(tag => ({
                label: tag.name,
                value: tag.id,
            }))
    }

    private focusTopicsPromiseGenerator = async (searchTerm: string | null = null, signal: AbortSignal): Promise<SelectOption[]> => {
        this.options = await this.FocusTopicService.getFocusTopics({ searchTerm, isMtp: true, signal });
        return this.selectionOptions;
    }

    private async onTriggerImport(): Promise<void> {
        this.isLoading = true;
        try {
            await this.CONFIG[this.currentMode].importFn();

            this.$refs[REF_CONSTANTS.PML_TYPE_AHEAD].clearInput();
            this.resetSelectedOption();

            if (TagsMode.EMIT !== this.currentMode) {
                this.$emit('reload-tags');
            }
        } finally {
            this.isLoading = false;
        }
    }

    private async importMtpTags(): Promise<void> {
        if (!this.selectedOption.value) {
            return;
        }
        
        const selectedTag = this.selectedOption.value.toString();
        const payload: AddMtpTagsPayload = {
            mtpId: this.requestId,
            tags: [selectedTag],
        };

        await this.MtpService.addMtpTags(payload);
    }

    private async importMiniFdTags(): Promise<void> {
        if (!this.selectedOption.value) {
            return;
        }

        const selectedTag = this.selectedOption.value.toString();
        const payload: AddMiniFdTagsPayload = {
            miniFdId: this.requestId,
            tags: [selectedTag],
        };

        await this.CrService.addMiniFdTags(payload);
    }

    private emitTags(): void {
        this.$emit('tags-list-changed', {
            focusTopicId: this.selectedOption.value.toString(),
            tagName: this.selectedOption.label,
        });
    }

    private resetSelectedOption(): void {
        this.selectedOption = null;
    }
}
