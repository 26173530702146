var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('pui-collapsible', {
    staticClass: "opportunity-collapsible",
    attrs: {
      "expandable": false,
      "headline": _vm.data.opportunityId + ' | Linked Opportunity'
    }
  }, [_c('pui-grid-container', [_c('pui-grid-row', [_c('pui-grid-column', {
    staticClass: "entry-column"
  }, [_c('pui-headline', {
    staticClass: "title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Opportunity title ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.opportunityTitle) + " ")])], 1), _c('pui-grid-column', {
    staticClass: "entry-column"
  }, [_c('pui-headline', {
    staticClass: "title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Status ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.opportunityStatus) + " ")])], 1), _c('pui-grid-column', {
    staticClass: "entry-column"
  }, [_c('pui-headline', {
    staticClass: "title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Investment cost ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.investmentCost + _vm.data.currencySymbol) + " ")])], 1), _c('pui-grid-column', {
    staticClass: "entry-column"
  }, [_c('pui-headline', {
    staticClass: "title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" S-IRR ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(((_vm.data.irr) + "%")) + " ")])], 1), _c('pui-grid-column', {
    staticClass: "entry-column"
  }, [_c('pui-headline', {
    staticClass: "title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Evaluation period ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(((_vm.data.evaluationPeriod) + " years")) + " ")])], 1), _c('pui-grid-column', {
    staticClass: "entry-column"
  }, [_c('pui-headline', {
    staticClass: "title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Payback time ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(((_vm.data.paybackTime) + " years")) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }