var render = function () {
  var _ref;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "risk-budget-request"
  }, [_c('div', {
    staticClass: "accordion-connection"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header"
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_c('button', {
    staticClass: "btn"
  }, [_vm._v(" " + _vm._s(_vm.risk.id) + " ")])])])]), _c('div', {
    staticClass: "budget-request-wrapper"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-row"
  }, [_c('p', {
    staticClass: "col-xl-12"
  }, [_c('b', [_vm._v("Risk title: ")]), _vm._v(" " + _vm._s(_vm.risk.data.riskTitle !== null && _vm.risk.data.riskTitle !== "" ? _vm.risk.data.riskTitle : "-") + " ")])]), _c('div', {
    staticClass: "form-row"
  }, [_c('p', {
    staticClass: "col-xl-12"
  }, [_c('b', [_vm._v("Risk status: ")]), _vm._v(" " + _vm._s(_vm.risk.data.riskStatus !== null && _vm.risk.data.riskStatus !== "" ? _vm.risk.data.riskStatus : "-") + " ")])]), _c('div', {
    staticClass: "form-row"
  }, [_c('p', {
    staticClass: "col-xl-12"
  }, [_c('b', [_vm._v("English title short for FCM: ")]), _vm._v(" " + _vm._s(_vm.risk.data.englishTitleShortForFcm !== null && _vm.risk.data.englishTitleShortForFcm !== "" ? _vm.risk.data.englishTitleShortForFcm : "-") + " ")])]), _c('div', {
    staticClass: "form-row"
  }, [_c('p', {
    staticClass: "col-xl-12"
  }, [_c('b', [_vm._v("Strategy cost: ")]), _vm._v(" " + _vm._s(_vm.risk.data.strategyCost !== null ? (_ref = _vm.risk.data.strategyCost + _vm.risk.data.currencySymbol) !== null && _ref !== void 0 ? _ref : '' : "-") + " ")])]), _vm.risk.data.uEL ? _c('div', {
    staticClass: "form-row"
  }, [_c('p', {
    staticClass: "col-xl-12"
  }, [_c('strong', [_vm._v("Useful Economic Life:")]), _vm._v(" " + _vm._s(_vm.risk.data.uEL) + " year(s) ")])]) : _vm._e(), _c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "row border-row"
  }, [_c('table', {
    staticClass: "table table-risk"
  }, [_vm._m(0), _c('tbody', [_vm.risk.data.costRiskBeforeTotalScore !== null && _vm.risk.data.costRiskAfterTotalScore !== null ? _c('tr', [_c('td', [_vm._v("Cost")]), _c('td', [_c('label', {
    staticClass: "risk-label"
  }, [_vm._v(_vm._s(_vm.risk.data.costRiskBeforeTotalScore))])]), _c('td', [_c('label', {
    staticClass: "risk-label"
  }, [_vm._v(_vm._s(_vm.risk.data.costRiskAfterTotalScore))])]), _c('td', [_vm._v(_vm._s(_vm.risk.data.mitigatedIrr !== null ? _vm.risk.data.mitigatedIrr + "%" : "-"))])]) : _vm._e(), _vm.risk.data.safetyRiskBeforeTotalScore !== null && _vm.risk.data.safetyRiskAfterTotalScore !== null ? _c('tr', [_c('td', [_vm._v("Safety")]), _c('td', [_c('label', {
    staticClass: "risk-label"
  }, [_vm._v(_vm._s(_vm.risk.data.safetyRiskBeforeTotalScore))])]), _c('td', [_c('label', {
    staticClass: "risk-label"
  }, [_vm._v(_vm._s(_vm.risk.data.safetyRiskAfterTotalScore))])]), _c('td', [_vm._v("-")])]) : _vm._e(), _vm.risk.data.environmentRiskBeforeTotalScore !== null && _vm.risk.data.environmentRiskAfterTotalScore !== null ? _c('tr', [_c('td', [_vm._v("Environment")]), _c('td', [_c('label', {
    staticClass: "risk-label"
  }, [_vm._v(_vm._s(_vm.risk.data.environmentRiskBeforeTotalScore))])]), _c('td', [_c('label', {
    staticClass: "risk-label"
  }, [_vm._v(_vm._s(_vm.risk.data.environmentRiskAfterTotalScore))])]), _c('td', [_vm._v("-")])]) : _vm._e(), _vm.risk.data.regulatoryRiskBeforeTotalScore !== null && _vm.risk.data.regulatoryRiskAfterTotalScore !== null ? _c('tr', [_c('td', [_vm._v("Regulatory")]), _c('td', [_c('label', {
    staticClass: "risk-label"
  }, [_vm._v(_vm._s(_vm.risk.data.regulatoryRiskBeforeTotalScore))])]), _c('td', [_c('label', {
    staticClass: "risk-label"
  }, [_vm._v(_vm._s(_vm.risk.data.regulatoryRiskAfterTotalScore))])]), _c('td', [_vm._v("-")])]) : _vm._e(), _vm.risk.data.reputationRiskBeforeTotalScore !== null && _vm.risk.data.reputationRiskAfterTotalScore !== null ? _c('tr', [_c('td', [_vm._v("Reputation")]), _c('td', [_c('label', {
    staticClass: "risk-label"
  }, [_vm._v(_vm._s(_vm.risk.data.reputationRiskBeforeTotalScore))])]), _c('td', [_c('label', {
    staticClass: "risk-label"
  }, [_vm._v(_vm._s(_vm.risk.data.reputationRiskAfterTotalScore))])]), _c('td', [_vm._v("-")])]) : _vm._e()])])])]), _vm._m(1), _c('div', {
    staticClass: "text-right"
  }, [_c('button', {
    staticClass: "btn btn-primary mb-4",
    on: {
      "click": function ($event) {
        return _vm.emitCopy(_vm.elementIndex);
      }
    }
  }, [_vm._v(" Copy ")])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', [_vm._v("Category")]), _c('th', [_vm._v("Current risk")]), _c('th', [_vm._v("After scenario")]), _c('th', [_vm._v("MIRR")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-left mb-2"
  }, [_c('small', {
    staticClass: "form-text text-muted"
  }, [_c('i', [_vm._v("When you choose to copy, the risk details will be copied over from PT Risk to the budget request form. ")])])]);
}]

export { render, staticRenderFns }