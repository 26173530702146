var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal fade show",
    attrs: {
      "id": "editControllingModal",
      "aria-hidden": "true",
      "aria-labelledby": "exampleModalLabel",
      "data-backdrop": "static",
      "role": "dialog",
      "tabindex": "-1"
    }
  }, [_c('div', {
    staticClass: "modal-dialog",
    attrs: {
      "role": "document"
    }
  }, [_c('div', {
    staticClass: "modal-content"
  }, [_vm._m(0), _vm.loading ? _c('pui-loader', {
    attrs: {
      "promise": _vm.infinitePromise
    }
  }) : _c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 pb-3"
  }, [_c('p', [_vm._v(" By updating the supporting information the MTP status of the budget request will remain unchanged ")])]), _c('div', {
    staticClass: "col-12 pb-3"
  }, [typeof _vm.errors === 'string' ? _c('div', [_c('p', {
    staticClass: "text-danger"
  }, [_vm._v(" " + _vm._s(_vm.errors) + " ")])]) : _vm._l(_vm.errors, function (error, index) {
    return _c('div', {
      key: index
    }, _vm._l(error, function (err, key) {
      return _c('p', {
        key: key,
        staticClass: "text-danger"
      }, [_vm._v(" " + _vm._s(err) + " ")]);
    }), 0);
  }), _c('table', {
    staticClass: "pt-2 pb-2 planning-table"
  }, [_vm.newValueDetailsBr ? _c('tbody', [_c('tr', [_c('td', [_vm._v("WBS (PSP) Element")]), _c('td', [!_vm.editable.wbsElement ? _c('div', {
    staticClass: "d-inline-block edit-wrapper"
  }, [_vm._v(" " + _vm._s(_vm.newValueDetailsBr.wbsElement) + " ")]) : _vm._e(), _vm.editable.wbsElement ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.wbsElement,
      expression: "wbsElement"
    }],
    staticClass: "input-editable",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.wbsElement
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.wbsElement = $event.target.value;
      }
    }
  }) : _vm._e(), _c('span', {
    staticClass: "float-right",
    on: {
      "click": function ($event) {
        _vm.editable.wbsElement = !_vm.editable.wbsElement;
      }
    }
  }, [!_vm.editable.wbsElement ? _c('icon', {
    staticClass: "icon-cancel",
    attrs: {
      "name": "pencil-alt"
    }
  }) : _vm._e()], 1), _c('span', {
    staticClass: "float-right",
    on: {
      "click": function ($event) {
        _vm.editable.wbsElement = !_vm.editable.wbsElement;
      }
    }
  }, [_vm.editable.wbsElement ? _c('icon', {
    staticClass: "icon-times",
    attrs: {
      "name": "times"
    }
  }) : _vm._e()], 1), _c('span', {
    staticClass: "float-right",
    on: {
      "click": function ($event) {
        return _vm.setValueControlling('wbsElement', _vm.wbsElement);
      }
    }
  }, [_vm.editable.wbsElement ? _c('icon', {
    staticClass: "icon-cancel",
    attrs: {
      "name": "check"
    }
  }) : _vm._e()], 1)])]), _c('tr', [_c('td', [_vm._v("Outage ID")]), _c('td', [!_vm.editable.outageId ? _c('div', {
    staticClass: "d-inline-block edit-wrapper"
  }, [_vm._v(" " + _vm._s(_vm.newValueDetailsBr.outageId) + " ")]) : _vm._e(), _vm.editable.outageId ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.outageId,
      expression: "outageId"
    }],
    staticClass: "input-editable",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.outageId
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.outageId = $event.target.value;
      }
    }
  }) : _vm._e(), _c('span', {
    staticClass: "float-right",
    on: {
      "click": function ($event) {
        _vm.editable.outageId = !_vm.editable.outageId;
      }
    }
  }, [!_vm.editable.outageId ? _c('icon', {
    staticClass: "icon-cancel",
    attrs: {
      "name": "pencil-alt"
    }
  }) : _vm._e()], 1), _c('span', {
    staticClass: "float-right",
    on: {
      "click": function ($event) {
        _vm.editable.outageId = !_vm.editable.outageId;
      }
    }
  }, [_vm.editable.outageId ? _c('icon', {
    staticClass: "icon-times",
    attrs: {
      "name": "times"
    }
  }) : _vm._e()], 1), _c('span', {
    staticClass: "float-right",
    on: {
      "click": function ($event) {
        return _vm.setValueControlling('outageId', _vm.outageId);
      }
    }
  }, [_vm.editable.outageId ? _c('icon', {
    staticClass: "icon-cancel",
    attrs: {
      "name": "check"
    }
  }) : _vm._e()], 1)])]), _c('tr', [_c('td', [_vm._v("Comment")]), _c('td', [!_vm.editable.comment ? _c('div', {
    staticClass: "d-inline-block edit-wrapper"
  }, [_vm._v(" " + _vm._s(_vm.newValueDetailsBr.comment) + " ")]) : _vm._e(), _vm.editable.comment ? _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.comment,
      expression: "comment"
    }],
    staticClass: "input-editable",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.comment
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.comment = $event.target.value;
      }
    }
  }) : _vm._e(), _vm._v(" "), _c('span', {
    staticClass: "float-right",
    on: {
      "click": function ($event) {
        _vm.editable.comment = !_vm.editable.comment;
      }
    }
  }, [!_vm.editable.comment ? _c('icon', {
    staticClass: "icon-cancel",
    attrs: {
      "name": "pencil-alt"
    }
  }) : _vm._e()], 1), _c('span', {
    staticClass: "float-right",
    on: {
      "click": function ($event) {
        _vm.editable.comment = !_vm.editable.comment;
      }
    }
  }, [_vm.editable.comment ? _c('icon', {
    staticClass: "icon-times",
    attrs: {
      "name": "times"
    }
  }) : _vm._e()], 1), _c('span', {
    staticClass: "float-right",
    on: {
      "click": function ($event) {
        return _vm.setValueControlling('comment', _vm.comment);
      }
    }
  }, [_vm.editable.comment ? _c('icon', {
    staticClass: "icon-cancel",
    attrs: {
      "name": "check"
    }
  }) : _vm._e()], 1)])]), _c('tr', [_c('td', [_vm._v("Start Date")]), _c('td', [!_vm.editable.startDate ? _c('div', {
    staticClass: "d-inline-block edit-wrapper"
  }, [_vm._v(" " + _vm._s(_vm.displayedStartDate) + " ")]) : _vm._e(), _vm.editable.startDate ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.startDate,
      expression: "startDate"
    }],
    staticClass: "input-editable",
    attrs: {
      "type": "date"
    },
    domProps: {
      "value": _vm.startDate
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.startDate = $event.target.value;
      }
    }
  }) : _vm._e(), _c('span', {
    staticClass: "float-right",
    on: {
      "click": function ($event) {
        _vm.editable.startDate = !_vm.editable.startDate;
      }
    }
  }, [!_vm.editable.startDate ? _c('icon', {
    staticClass: "icon-cancel",
    attrs: {
      "name": "pencil-alt"
    }
  }) : _vm._e()], 1), _c('span', {
    staticClass: "float-right",
    on: {
      "click": function ($event) {
        _vm.editable.startDate = !_vm.editable.startDate;
      }
    }
  }, [_vm.editable.startDate ? _c('icon', {
    staticClass: "icon-times",
    attrs: {
      "name": "times"
    }
  }) : _vm._e()], 1), _c('span', {
    staticClass: "float-right",
    on: {
      "click": function ($event) {
        return _vm.setValueControlling('startDate', _vm.startDate);
      }
    }
  }, [_vm.editable.startDate ? _c('icon', {
    staticClass: "icon-cancel",
    attrs: {
      "name": "check"
    }
  }) : _vm._e()], 1)])]), _c('tr', [_c('td', [_vm._v("Has Planning Costs")]), _c('td', [!_vm.editable.hasPlanningCosts ? _c('div', {
    staticClass: "d-inline-block edit-wrapper"
  }, [_vm._v(" " + _vm._s(_vm.newValueDetailsBr.hasPlanningCosts ? 'Yes' : 'No') + " ")]) : _vm._e(), _vm.editable.hasPlanningCosts ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.hasPlanningCosts,
      expression: "hasPlanningCosts"
    }],
    staticClass: "input-editable",
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.hasPlanningCosts) ? _vm._i(_vm.hasPlanningCosts, null) > -1 : _vm.hasPlanningCosts
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.hasPlanningCosts,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.hasPlanningCosts = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.hasPlanningCosts = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.hasPlanningCosts = $$c;
        }
      }
    }
  }) : _vm._e(), _c('span', {
    staticClass: "float-right",
    on: {
      "click": function ($event) {
        _vm.editable.hasPlanningCosts = !_vm.editable.hasPlanningCosts;
      }
    }
  }, [!_vm.editable.hasPlanningCosts ? _c('icon', {
    staticClass: "icon-cancel",
    attrs: {
      "name": "pencil-alt"
    }
  }) : _vm._e()], 1), _c('span', {
    staticClass: "float-right",
    on: {
      "click": function ($event) {
        _vm.editable.hasPlanningCosts = !_vm.editable.hasPlanningCosts;
      }
    }
  }, [_vm.editable.hasPlanningCosts ? _c('icon', {
    staticClass: "icon-times",
    attrs: {
      "name": "times"
    }
  }) : _vm._e()], 1), _c('span', {
    staticClass: "float-right",
    on: {
      "click": function ($event) {
        return _vm.setValueControlling('hasPlanningCosts', _vm.hasPlanningCosts);
      }
    }
  }, [_vm.editable.hasPlanningCosts ? _c('icon', {
    staticClass: "icon-cancel",
    attrs: {
      "name": "check"
    }
  }) : _vm._e()], 1)])])]) : _vm._e()])], 2)])])]), !_vm.loading ? _c('div', {
    staticClass: "modal-footer pt-2"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary",
    attrs: {
      "data-dismiss": "modal",
      "type": "button"
    }
  }, [_vm._v(" Cancel ")]), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "disabled": _vm.checkFieldEditable(),
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.saveChanges();
      }
    }
  }, [_vm._v(" Save ")])]) : _vm._e()], 1)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal-header"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('h5', {
    staticClass: "modal-title",
    attrs: {
      "id": "exampleModalLabel"
    }
  }, [_vm._v(" Edit Supporting Information ")])])])]), _c('button', {
    staticClass: "close",
    attrs: {
      "aria-label": "Close",
      "data-dismiss": "modal",
      "type": "button"
    }
  }, [_c('span', {
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("×")])])]);
}]

export { render, staticRenderFns }