import Vue from 'vue';
import Component from 'vue-class-component';
import { BudgetRequestsService } from '~/services/budget-request-service';
import { format, parse } from 'date-fns';

@Component({})
export default class EditControllingModalComponent extends Vue {
    private wbsElement: string = '';
    private comment = null;
    private outageId: string = '';
    private startDate: string = '';
    private hasPlanningCosts: boolean = false;
    private newValueDetailsBr = {
        budgetRequestId: '',
        globalId: '',
        year: null,
        mtpStatus: null,
        wbsElement: '',
        comment: '',
        planningAndControllingClassification: null,
        outageId: '',
        outageType: null,
        startDate: '',
        hasPlanningCosts: false,
    };
    private oldValueDetailsBr: any = {};
    private budgetRequestService: BudgetRequestsService = new BudgetRequestsService();
    private errors = {};
    private editable = {
        wbsElement: false,
        comment: false,
        outageId: false,
        startDate: false,
        hasPlanningCosts: false,
    };

    private infinitePromise = new Promise(() => {});
    private loading: boolean  = false;

    constructor() {
        super();
    }

    private get displayedStartDate(): string {
        try {
            return format(new Date(this.newValueDetailsBr.startDate), 'dd/MM/yyyy');
        } catch {
            return '-';
        }
    }

    public setDataEditControlling(data) {
        this.errors = {};
        this.oldValueDetailsBr = { ...data };
        this.newValueDetailsBr = { ...data };
        this.wbsElement = this.newValueDetailsBr.wbsElement;
        this.comment = this.newValueDetailsBr.comment;
        this.outageId = this.newValueDetailsBr.outageId;
        this.startDate = format(new Date(this.newValueDetailsBr.startDate), 'yyyy-MM-dd');
        this.hasPlanningCosts = this.newValueDetailsBr.hasPlanningCosts;
        this.editable = {
            wbsElement: false,
            comment: false,
            outageId: false,
            startDate: false,
            hasPlanningCosts: false,
        };
    }

    private async saveChanges() {
        this.errors = {};
        this.loading = true;

        try {
            await this.budgetRequestService.editControllingInformation(this.newValueDetailsBr);
        } catch (error) {
            if (error.response !== undefined && error.response.status === 400) {
                this.errors = error.response.data;
            } else {
                this.errors[0] = [error.message];
            }
        }

        if (Object.keys(this.errors).length === 0) {
            $('#editControllingModal').modal('hide');
            if (this.newValueDetailsBr.wbsElement !== this.oldValueDetailsBr.wbsElement
                || this.newValueDetailsBr.comment !== this.oldValueDetailsBr.comment
                || this.newValueDetailsBr.outageId !== this.oldValueDetailsBr.outageId
                || this.newValueDetailsBr.startDate !== this.oldValueDetailsBr.startDate
                || this.newValueDetailsBr.hasPlanningCosts !== this.oldValueDetailsBr.hasPlanningCosts
            ) {
                this.$emit('edit-controlling-information', this.newValueDetailsBr);
            }
        }

        this.loading = false;
    }

    private setValueControlling(nameField: string, valueField: any) {
        this.editable[nameField] = !this.editable[nameField];

        if (nameField === 'startDate') {
            this.newValueDetailsBr[nameField] = format(parse(valueField, 'yyyy-MM-dd', new Date()), "yyyy-MM-dd'T'HH:mm:ss'Z'");
        } else {
            this.newValueDetailsBr[nameField] = valueField;
        }
    }

    private checkFieldEditable() {
        if (Object.values(this.editable).includes(true)) {
            return true;
        }
    }
}
