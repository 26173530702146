import { Connection } from '~/utils/';
import { ApiResponse } from '~/models/services/common';

type ModifiedValue = {
    id: string;
    comment: string;
    forecast: number;
    new1: number;
    new2: number;
    new3: number;
}

export class CurrencyRateService {
    private readonly api = new Connection();

    public async getCurrencyRates(page: number, size: number, term: string): Promise<any> {
        const params = new URLSearchParams();
        params.append('page', page.toString());
        params.append('size', size.toString());
        params.append('Term', term.toString());

        return (await this.api.get<ApiResponse<any>>(`currency-rates?${params.toString()}`)).data.result;
    }

    public async editCurrencyRates(payload: { currencyRatesToEdit: ModifiedValue[] }): Promise<any> {
        return (await this.api.put<ApiResponse<any>>('currency-rates', payload)).data.result;
    }
}
