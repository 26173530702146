import { render, staticRenderFns } from "./focus-topics.vue?vue&type=template&id=0b08e96a&"
import script from "./focus-topics.ts?vue&type=script&lang=ts&"
export * from "./focus-topics.ts?vue&type=script&lang=ts&"
import style0 from "./focus-topics.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports