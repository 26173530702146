var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal fade bd-example-modal-lg",
    attrs: {
      "id": "removeFromMtpTableModal",
      "aria-hidden": "true",
      "aria-labelledby": "exampleModalLabel",
      "data-backdrop": "static",
      "role": "dialog",
      "tabindex": "-1"
    }
  }, [_c('div', {
    staticClass: "modal-dialog modal-lg",
    attrs: {
      "role": "document"
    }
  }, [_c('div', {
    staticClass: "modal-content"
  }, [_vm._m(0), _c('div', {
    staticClass: "modal-body"
  }, [_c('h6', [_vm._v("Please define whether the selected items should be retained or deleted.")]), Object.keys(_vm.itemsChecked).length !== 0 && _vm.data ? _c('table', {
    staticClass: "table"
  }, [_vm._m(1), _c('tbody', _vm._l(_vm.itemsChecked, function (items, index) {
    return _c('tr', {
      key: index
    }, [_c('td', {
      class: _vm.data.constants.mtpStatus[items.mtpStatus].color + '_table',
      staticStyle: {
        "width": "1%",
        "color": "#0078dc"
      }
    }, [_vm._v(" " + _vm._s(items.globalId) + " ")]), _c('td', {
      staticStyle: {
        "max-width": "200px"
      }
    }, [_c('div', [_vm._v(_vm._s(items.name))])]), _c('td', {
      staticStyle: {
        "width": "1%",
        "color": "#0078dc"
      }
    }, [_c('label', {
      staticClass: "container radio d-inline-flex",
      staticStyle: {
        "width": "85px"
      }
    }, [_c('span', {
      staticClass: "pl-3",
      class: (_vm.itemsChecked[index].mtpStatus === 2 || _vm.itemsChecked[index].mtpStatus === 1) && _vm.itemsChecked[index].created === _vm.currentYear.toString() ? '' : 'disabled'
    }, [_vm._v("Delete ")]), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedBR[index],
        expression: "selectedBR[index]"
      }],
      attrs: {
        "disabled": _vm.itemsChecked[index].mtpStatus !== 2 && _vm.itemsChecked[index].mtpStatus !== 1 || _vm.itemsChecked[index].created !== _vm.currentYear.toString(),
        "name": items.globalId,
        "type": "checkbox"
      },
      domProps: {
        "value": items.globalId,
        "checked": Array.isArray(_vm.selectedBR[index]) ? _vm._i(_vm.selectedBR[index], items.globalId) > -1 : _vm.selectedBR[index]
      },
      on: {
        "change": [function ($event) {
          var $$a = _vm.selectedBR[index],
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = items.globalId,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && _vm.$set(_vm.selectedBR, index, $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(_vm.selectedBR, index, $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(_vm.selectedBR, index, $$c);
          }
        }, function ($event) {
          return _vm.checkIfSelectedOptions();
        }],
        "click": function ($event) {
          return _vm.a(items, index);
        }
      }
    }), _c('span', {
      staticClass: "checkmark"
    })]), _c('label', {
      staticClass: "container radio d-inline-flex",
      staticStyle: {
        "width": "140px"
      }
    }, [_c('span', {
      staticClass: "pl-3"
    }, [_vm._v("Set to inactive")]), _c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedBRinactive[index],
        expression: "selectedBRinactive[index]"
      }],
      attrs: {
        "name": items.globalId,
        "type": "checkbox"
      },
      domProps: {
        "value": items.globalId,
        "checked": Array.isArray(_vm.selectedBRinactive[index]) ? _vm._i(_vm.selectedBRinactive[index], items.globalId) > -1 : _vm.selectedBRinactive[index]
      },
      on: {
        "change": [function ($event) {
          var $$a = _vm.selectedBRinactive[index],
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = items.globalId,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && _vm.$set(_vm.selectedBRinactive, index, $$a.concat([$$v]));
            } else {
              $$i > -1 && _vm.$set(_vm.selectedBRinactive, index, $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.$set(_vm.selectedBRinactive, index, $$c);
          }
        }, function ($event) {
          return _vm.checkIfSelectedOptions();
        }],
        "click": function ($event) {
          return _vm.b(items, index);
        }
      }
    }), _c('span', {
      staticClass: "checkmark"
    })])])]);
  }), 0)]) : _vm._e(), _c('h6', [_vm._v("Only \"Draft\" and \"Submitted\" items can be deleted.")])]), _c('div', {
    staticClass: "modal-footer"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary",
    attrs: {
      "data-dismiss": "modal",
      "type": "button"
    }
  }, [_vm._v(" Cancel ")]), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "disabled": _vm.flagActiveRemoveButton,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.updateMtp();
      }
    }
  }, [_vm._v(" Remove ")])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal-header"
  }, [_c('h5', {
    staticClass: "modal-title",
    attrs: {
      "id": "exampleModalLabel"
    }
  }, [_vm._v(" Update MTP status ")]), _c('button', {
    staticClass: "close",
    attrs: {
      "aria-label": "Close",
      "data-dismiss": "modal",
      "type": "button"
    }
  }, [_c('span', {
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("×")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" Global ID ")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" Name ")]), _c('th', {
    attrs: {
      "scope": "col"
    }
  }, [_vm._v(" Options ")])])]);
}]

export { render, staticRenderFns }