var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('pui-collapsible', {
    staticClass: "iybr-new-project-collapsible",
    attrs: {
      "expandable": false,
      "open": true,
      "headline": "New Project Cost"
    }
  }, [_c('pui-table', {
    attrs: {
      "columns-are-center-aligned": true,
      "first-column-is-left-aligned": true,
      "vendor-options": _vm.tableOptions
    },
    scopedSlots: _vm._u([_vm._l(Object.values(_vm.columns), function (columnName) {
      return {
        key: columnName,
        fn: function (data) {
          return [_c('div', {
            key: columnName
          }, [_vm.shouldDisplayInputField(data) ? [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.formData[_vm.formDataMapping[columnName]],
              expression: "formData[formDataMapping[columnName]]"
            }, {
              name: "mask",
              rawName: "v-mask",
              value: _vm.numberInputMask,
              expression: "numberInputMask"
            }],
            class: _vm.cellValueClass(_vm.formData[_vm.formDataMapping[columnName]]),
            attrs: {
              "type": "text"
            },
            domProps: {
              "value": _vm.formData[_vm.formDataMapping[columnName]]
            },
            on: {
              "input": function ($event) {
                if ($event.target.composing) { return; }

                _vm.$set(_vm.formData, _vm.formDataMapping[columnName], $event.target.value);
              }
            }
          })] : [_c('span', {
            class: _vm.cellValueClass(data.row[columnName])
          }, [_vm._v(" " + _vm._s(_vm.tableCellTextFormat(data.row[columnName])) + " ")])]], 2)];
        }
      };
    })], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }