var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tags"
  }, [_vm.loading ? _c('pui-loader-spinner', {
    attrs: {
      "min-height": "100px",
      "size": "60px",
      "title": "Loading Focus Topics..."
    }
  }) : [!_vm.viewOnly ? [_c('tag-selector', {
    attrs: {
      "current-mode": _vm.currentMode,
      "request-id": _vm.requestId,
      "hidden-options": _vm.tags
    },
    on: {
      "reload-tags": function ($event) {
        return _vm.loadTags();
      },
      "tags-list-changed": _vm.addEmitTag
    }
  })] : _vm._e(), _vm.tags.length > 0 ? _vm._l(_vm.tags, function (tag) {
    return _c('div', {
      key: tag.id,
      staticClass: "tags__tag"
    }, [_vm._v(" " + _vm._s(tag.tagName) + " "), !_vm.viewOnly ? _c('button', {
      staticClass: "btn btn-action btn-action--delete",
      on: {
        "click": function ($event) {
          return _vm.removeTag(_vm.isEmit ? tag.focusTopicId : tag.id);
        }
      }
    }, [_c('span', [_vm._v("Remove")])]) : _vm._e()]);
  }) : [_c('pui-loader-error', {
    staticClass: "tags__empty-tags-error",
    attrs: {
      "title": "",
      "message": "No focus topics assigned",
      "icon": "error-empty-data"
    }
  })]]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }