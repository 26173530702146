import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class AccessDeniedModalComponent extends Vue {

    @Prop()
    private isAdminPage: boolean;

    constructor() {
        super();
    }

    private onClickButton() {
        this.$emit('clickedcanceleditstate', false);
    }

    private emitBRPage() {
        this.$emit('goToBRPage');
    }
}
