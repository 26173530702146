import Vue from 'vue';
import Component from 'vue-class-component';
import CreateIybrFormComponent from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/create-iybr-form.vue';
import IYBRFormSubmitConfirmationComponent from './create-iybr-form/iybr-form-submit-confirmation/iybr-form-submit-confirmation.vue';
import { BreadcrumbLink } from '~/utils/interfaces';
import { PuiLightbox } from '~/models/libraries/pebble-ui';

@Component({
    components: {
        createIybrForm: CreateIybrFormComponent,
        iybrFormSubmitConfirmation: IYBRFormSubmitConfirmationComponent
    }
})
export default class CreateInYearBudgetRequestComponent extends Vue {
    $refs!: {
        form: CreateIybrFormComponent;
    };

    private readonly SUBMIT_LOADING_MODAL = 'submitLoadingModal';
    private isSubmmitedSuccessfully = false;
    private miniFdUniqueId = '';

    private get breadcrumbLinks(): BreadcrumbLink[] {
        return [
            {
                label: 'Change Requests',
                href: '/in-year-budget-requests'
            },
            {
                label: 'New Change Request',
                href: '/create-in-year-budget-request'
            }
        ];
    }

    private onFormSubmit(uniqueId: string): void {
        this.miniFdUniqueId = uniqueId;
        this.isSubmmitedSuccessfully = true;
        (this.$refs[this.SUBMIT_LOADING_MODAL] as PuiLightbox).close();
    }

    private onBreadcrumbLinkClick(param: { href: string }): void {
        this.$router.push(param.href).then();
    }

    private openLoadingScreen(): void {
        (this.$refs[this.SUBMIT_LOADING_MODAL] as PuiLightbox).open();
    }

    private handleSubmitError(): void {
        (this.$refs[this.SUBMIT_LOADING_MODAL] as PuiLightbox).close();
        this.$bvToast.show('toast-error-submit-change-request');
    }
}
