var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('pui-collapsible', {
    staticClass: "collapsible-pb collapsible-bg",
    attrs: {
      "expandable": false,
      "headline": "OPTIONAL NOTIFIABLE USERS"
    }
  }, [_c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.FORM_LAYOUT.FULL_ROW,
      expression: "FORM_LAYOUT.FULL_ROW"
    }],
    attrs: {
      "is-disabled": true,
      "label": ""
    }
  }, [_c('pui-form-type-ahead', {
    key: _vm.typeAheadComponentKey,
    attrs: {
      "is-loading": _vm.isFormLoading,
      "options": _vm.searchResults,
      "use-local-cache": false,
      "label": ""
    },
    on: {
      "abort": _vm.clearSearchResults,
      "change": _vm.addUserToList,
      "search": _vm.listUsers
    }
  })], 1)], 1), _vm._l(_vm.listOfNotifiableUsers, function (user) {
    return _c('div', {
      directives: [{
        name: "pui-form-grid-row",
        rawName: "v-pui-form-grid-row"
      }],
      key: user.kId
    }, [_c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: _vm.FORM_LAYOUT.THREE_QUARTER_ROW,
        expression: "FORM_LAYOUT.THREE_QUARTER_ROW"
      }],
      attrs: {
        "is-disabled": true,
        "label": ""
      }
    }, [_c('pui-form-input-field', {
      attrs: {
        "is-readonly": true,
        "is-disabled": true,
        "value": user.name,
        "type": "text"
      }
    })], 1), _c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: _vm.FORM_LAYOUT.QUARTER_ROW,
        expression: "FORM_LAYOUT.QUARTER_ROW"
      }],
      attrs: {
        "is-disabled": true,
        "label": ""
      }
    }, [_c('pui-button', {
      attrs: {
        "icon-only": true,
        "light": true,
        "icon": "delete"
      },
      on: {
        "click": function ($event) {
          return _vm.deleteUserFromList(user.kId);
        }
      }
    })], 1)], 1);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }