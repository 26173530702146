import Vue from 'vue';
import Component from 'vue-class-component';
import { User } from '~/utils';
import { Profile } from 'oidc-client';
import { ExtendedUserPermissions } from "~/utils/interfaces"

@Component({})
export default class NavbarUserActionsComponent extends Vue {
    public user?: ExtendedUserPermissions = null;
    public userPermissions: string[] = [];

    async created(): Promise<void> {
        await this.loadPermissions();
    }

    async loadPermissions(): Promise<void> {
        this.user = await User.getUserData();
        this.userPermissions = await User.getPerm();
    }

    sendToFeedbackForm(): void {
        const useCaseId = Number(process.env.VUE_APP_USE_CASE_ID ?? 0);
        const appStoreUrl = `${process.env.VUE_APP_EXTERNAL_APP_APPSTORE_URL}/contact-page/${useCaseId}?isFeedback=true`;

        window.open(appStoreUrl, '_blank');
    }

    sendToIncidentReporting(): void {
        const useCaseId = Number(process.env.VUE_APP_USE_CASE_ID ?? 0);
        const appStoreUrl = `${process.env.VUE_APP_EXTERNAL_APP_APPSTORE_URL}/incident-page/${useCaseId}`;

        window.open(appStoreUrl, '_blank');
    }

    async logout(): Promise<void> {
        await User.signOut();
    }
}
