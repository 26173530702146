import Vue from 'vue';
import Component from 'vue-class-component';
import { EventBus } from '~/utils/eventbus';

@Component({})
export default class UpdateMtpModalComponent extends Vue {
    private selectedUpdate: boolean = false;

    constructor() {
        super();
    }

    private updateMtp() {
        this.selectedUpdate = true;
        EventBus.$emit(EventBus.UPDATE_MTP_STATUS.UPDATE_MTP_MODAL, this.selectedUpdate);
    }

    private onClickButton() {
        this.$emit('clickedcanceleditstate', false);
    }
}
