import { render, staticRenderFns } from "./mtp-status-reviewed-modal.vue?vue&type=template&id=1bb843e7&"
import script from "./mtp-status-reviewed-modal.ts?vue&type=script&lang=ts&"
export * from "./mtp-status-reviewed-modal.ts?vue&type=script&lang=ts&"
import style0 from "./mtp-status-reviewed-modal.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports