import Vue from 'vue';
import Component from 'vue-class-component';
import { BudgetRequests, MtpPeriod, SearchModel, SelectOption } from '~/utils/interfaces';
import 'vue-awesome/icons/chevron-right';
import 'vue-awesome/icons/chevron-left';
import 'vue-awesome/icons/search';
import 'vue-awesome/icons/chevron-down';
import 'vue-awesome/icons/times';
import 'vue-awesome/icons/upload';
import 'vue-awesome/icons/trash-alt';
import 'vue-awesome/icons/regular/file';
import 'vue-awesome/icons/regular/trash-alt';
import 'vue-awesome/icons/redo';
import 'vue-awesome/icons/check';
import 'vue-awesome/icons/plus';
import 'vue-awesome/icons/copy';
import 'vue-awesome/icons/download';
import 'vue-awesome/icons/exclamation-triangle';
import 'vue-awesome/icons/sync-alt';
import 'vue-awesome/icons/exclamation-circle';
import { Data, EventBus, Helper, User } from '~/utils';
import { BudgetRequestsService } from '~/services';
import { Watch } from 'vue-property-decorator';
import { FileService } from '~/services/file-service';
import { format } from 'date-fns';
import FilesBudgetRequestsComponent from '../files-budget-requests/files-budget-requests.vue';
import ChartBudgetRequestsComponent from '../chart-budget-requests/chart-budget-requests.vue';
import moment from 'moment-timezone';
import { ApproveService } from '~/services/approve-service';
import { MtpService } from '~/services/mtp-service';
import ScrollSync from 'vue-scroll-sync';
import EditControllingModalComponent from '~/components/data-input/edit-controlling-modal/edit-controlling-modal.vue';
import PlantSelectorComponent from '~/components/plant-selector/plant-selector.vue';
import { ExportPayload } from '~/models/services/reporting';
import { AgGridVue } from 'ag-grid-vue';
import { ColDef, ColumnApi, GetContextMenuItemsParams, GridApi, GridOptions, ICellRendererParams, IServerSideGetRowsParams, MenuItemDef, ModelUpdatedEvent, SelectionChangedEvent, SetFilterValuesFuncParams, ValueFormatterParams } from 'ag-grid-community';
import { AgGridCommon } from 'ag-grid-community/dist/lib/interfaces/iCommon';
import TableHeader from '@/components/ag-grid-table/header-types/table-header.vue';
import NoRowsOverlay from '@/components/ag-grid-table/overlay-types/no-rows-overlay.vue';
import GlobalIdCell from '~/components/budget-requests/accordion-budget-requests/cells/global-id-cell.vue';
import StatusCell from '~/components/ag-grid-table/cell-types/status-cell.vue';

const ROWS = {
    FULL_ROW: { s: 12, l: 12 },
    TWO_THIRDS_ROW: { s: 12, l: 8 },
    HALF_ROW: { s: 12, l: 6 },
    ONE_THIRD_ROW: { s: 12, l: 4 },
    QUARTER_ROW: { s: 6, l: 3 },
    THREE_QUARTER_ROW: { s: 12, l: 9 },
    ONE_SIXTH_ROW: { s: 4, l: 2 },
} as const;

const ACCORDION_BUDGET_REQUESTS_TABLE_COLUMN_STATE = 'accordion_budget_requests_table_column_state' as const;
const ACCORDION_BUDGET_REQUESTS_TABLE_FILTER_STATE = 'accordion_budget_requests_table_filter_state' as const;

type ProjectPortfolioSetFilters = {
    mtpStatusFilters: number[];
    projectTypeFilters: number[];
    aeroClassificationFilters: number[];
    maintenanceTypeFilters: number[];
    outageTypeFilters: number[];
    procurementCategoryFilters: number[],
    planningAndControllingClassificationFilters: number[];
    fundingStatusFilters: number[],
    projectStatusTypeFilters: number[],
    primaryDepartmentFilters: number[],
    mainFlagFilters: number[],
    prioritizationCategoryFilters: number[],
};

const STATUS_COLOR_MAP = {
    'Light_grey': '#d6d6d6',
    'Dark_grey': '#607d8b',
    'Magenta_Red': '#ffab00',
    'Amber_Yellow': '#19c142',
    'Green': '#0078dc',
} as const;

@Component({
    components: {
        AgGridVue,
        TableHeader,
        NoRowsOverlay,
        GlobalIdCell,
        StatusCell,
        'plant-selector': PlantSelectorComponent,
        'files-budget-request': FilesBudgetRequestsComponent,
        'chart-budget-requests': ChartBudgetRequestsComponent,
        'edit-controlling-modal': EditControllingModalComponent,
        'scroll-sync': ScrollSync
    }
})
export default class AccordionBudgetRequestsComponent extends Vue {
    private ROWS = ROWS;
    private gridApi: GridApi | null = null;
    private columnApi: ColumnApi | null = null;
    private tableExtraFilters = {
        pageSize: 10,
        searchTerm: '',
    }
    private isFilterApplied: boolean = false;
    private selectedBudgetRequests: {
        id: string;
        globalId: string;
    }[] = [];

    public loading: boolean = false;
    public elementsPerPage: number = 10;
    public firstElementTable: number = 1;
    public numberOfElementTable: number = this.elementsPerPage;
    public currentPage: number = 1;
    public budgetRequestService: BudgetRequestsService = new BudgetRequestsService();
    public MtpService: MtpService = new MtpService();
    public ApproveService: ApproveService = new ApproveService();
    public budgetRequests: BudgetRequests = {
        budgetRequests: [],
        total: '',
    };
    private ExportBudgetRequestService: FileService = new FileService();
    private userPermissions = <any>[];
    private isActive: boolean = true;
    private moreFilterActiv: boolean = false;
    private itemsChecked = [];
    private checkedAll: boolean = false;
    private selectedPlants = [];
    private selectedMtpFilter = null;
    private mtpYear = null;
    private miniFdYear = null;
    private selectedGlobalId = null;
    private data: Data = Data.Instance;
    private exportBudgetRequests = {
        year: null,
        miniFdYear: null,
        globalIds: []
    } as ExportPayload;
    private selectedMaintenanceTypeFilters = [];
    private selectedMtpStatusFilters = [];
    private selectedProjectTypeFilters = [];
    private selectedaeroClassificationFilters = [];
    private selectedoutageTypeFilters = [];
    private selectedplanningAndControllingClassificationFilters = [];
    private selectedfundingStatusFilters = [];
    private selectedprojectStatusTypeFilters = [];
    private selectedprimaryDepartmentFilters = [];
    private selectedmainFlagFilters = [];
    private selectedlumpSumFilters = [];
    private selectedIsReadyForApproval = false;
    private selectedProcurementCategoryFilters = [];
    private selectedFocusTopicFilters = [];
    private selectedPrioritizationCategoryFilters = [];
    private searchText = '';
    private singleChangeMtp = null;
    private mainFlagFromChangeMtp = null;
    private otherFlagsFromChangeMtp = null;
    private commentFromChangeMtp = null;
    private yearFromChangeMtp = null;
    private brIdChangeMtp = null;
    private globalIdFromChangeMtp = null;
    private keyIdFromChangeMtp = null;
    private activeButtonApplyFilter: boolean = false;
    private dataBrToModal = {};
    private deleteBudgetRequests = {
        globalIds: [],
        isActive: null,
        mtpYear: null
    };
    private setToInactiveBr = {
        globalIds: [],
        mtpYear: null
    };
    private checkStatuses: boolean = false;
    private checkReviewedStatuses: boolean = false;
    private errorImport: any[] = null;
    private currentYear = new Date(Date.now()).getFullYear();
    private selectedMtpYear = null;
    private createdYear = null;
    private bulkApprove = {
        plantIds: [],
        mtpYear: 0
    };
    private isMtpPeriod: boolean = false;
    private mtpPeriod: MtpPeriod = <MtpPeriod>{
        year: 0,
        mtpStartDate: null,
        mtpEndDate: null
    };

    public get absMtpYear(): number {
        return Math.abs(parseInt(this.mtpYear));
    }

    get getLumpSumTitleToolTip() {
        let localName = [];
        this.selectedlumpSumFilters.forEach((e) => {
            if (e === true) {
                localName.push('Yes');
            } else if (e === false) {
                if (localName.length === 0) {
                    localName.push('No');
                } else {
                    localName.push(', No');
                }

            }
        });
        return localName;
    }

    private get searchModel(): SearchModel {
        return {
            plantIds: this.selectedPlants,
            mtpYear: parseInt(this.mtpYear),
            miniFdYear: parseInt(this.miniFdYear),
            itemsPerPage: this.elementsPerPage,
            pageNo: this.currentPage,
            isActive: this.isActive,
            filters: {
                aeroClassificationFilters: this.selectedaeroClassificationFilters,
                fundingStatusFilters: this.selectedfundingStatusFilters,
                projectStatusTypeFilters: this.selectedprojectStatusTypeFilters,
                primaryDepartmentFilters: this.selectedprimaryDepartmentFilters,
                lumpSumFilters: this.selectedlumpSumFilters,
                mainFlagFilters: this.selectedmainFlagFilters,
                maintenanceTypeFilters: this.selectedMaintenanceTypeFilters,
                mtpStatusFilters: this.selectedMtpStatusFilters,
                miniFdStatusFilters: [],
                requestTypeFilters: [],
                outageTypeFilters: this.selectedoutageTypeFilters,
                planningAndControllingClassificationFilters: this.selectedplanningAndControllingClassificationFilters,
                projectTypeFilters: this.selectedProjectTypeFilters,
                procurementCategoryFilters: this.selectedProcurementCategoryFilters,
                focusTopicFilters: this.selectedFocusTopicFilters,
                prioritizationCategoryFilters: this.selectedPrioritizationCategoryFilters,
                isReadyForApproval: this.selectedIsReadyForApproval
            },
            searchText: this.searchText
        };
    }

    private get isCurrentMtp(): boolean {
        return this.isActive && this.mtpYear === this.currentYear && this.miniFdYear === 0;
    }

    async created() {
        
        this.userPermissions = await User.getPerm();

        // Check current MTP period to enable/disable edit BR
        if (this.userPermissions.includes('BudgetRequestDetailsEdit')) {
            if (localStorage.mtpPeriod) {
                this.mtpPeriod = JSON.parse(localStorage.mtpPeriod);
            } else {
                try {
                    this.mtpPeriod = await this.MtpService.GetMtpPeriod(this.currentYear);
                    localStorage.setItem('mtpPeriod', JSON.stringify(this.mtpPeriod));
                } catch (error) {
                    if (error.response !== undefined && error.response.status === 400) {
                        localStorage.removeItem('mtpPeriod');
                    }
                }
            }

            if (this.mtpPeriod && this.mtpPeriod.mtpEndDate && this.mtpPeriod.mtpStartDate) {
                let currentDate = format(new Date(Date.now()), 'yyyy-MM-dd\'T00:00:00\'');
                this.mtpPeriod.mtpEndDate = format(new Date(this.mtpPeriod.mtpEndDate), 'yyyy-MM-dd\'T00:00:00\'');

                if (this.mtpPeriod.mtpStartDate <= currentDate && this.mtpPeriod.mtpEndDate >= currentDate) {
                    this.isMtpPeriod = true;
                }
            }
        } else {
            this.isMtpPeriod = false;
        }
    }

    async mounted() {
        this.setMtpFilter();
        EventBus.$on(EventBus.UPDATE_MTP_STATUS.UPDATE_MTP_MODAL, async () => {
            $('#updateMtpModal').modal('hide');
            this.data.changedMtpLoading = true;
            this.data.selectedOneMtpUpdateStatus = this.singleChangeMtp.globalIds.length + ' budget request updated to "Draft". These requests can now be edited.';
            if (this.itemsChecked.length === 1) {
                this.data.selectedOneMtpUpdateStatus = this.singleChangeMtp.globalIds.length + ' budget request updated to "Draft". This request can now be edited.';
            }
            await this.budgetRequestService.changeMtpStatus(this.singleChangeMtp);
            this.clearItemsChecked();
            await (this.$refs['get-budget-distribution'] as any).getDataMtpStatus(false);
            this.$bvToast.show('toast-mtp');
            this.$bvToast.hide('example-toast');
            this.data.changedMtpLoading = false;
            this.refreshTable();
        });
        EventBus.$on(EventBus.UPDATE_MTP_STATUS.UPDATE_MTP_REVIEWED_MODAL, async () => {
            this.data.changedMtpLoading = true;
            this.data.selectedOneMtpUpdateStatus = '1 budget request updated to "Reviewed". This request is now awaiting approval.';
            this.clearItemsChecked();
            await (this.$refs['get-budget-distribution'] as any).getDataMtpStatus(true);
            this.$bvToast.show('toast-mtp');
            this.$bvToast.hide('example-toast');
            this.data.changedMtpLoading = false;
            this.refreshTable();
        });
        EventBus.$on(EventBus.UPDATE_MTP_STATUS.UPDATE_MTP_FLAGGED_MODAL, async () => {
            this.data.changedMtpLoading = true;
            this.data.selectedOneMtpUpdateStatus = '1 budget request updated to "Flagged". This request may require updates. Editing is only available in "Draft" state.';
            this.clearItemsChecked();
            await (this.$refs['get-budget-distribution'] as any).getDataMtpStatus(true);
            this.$bvToast.show('toast-mtp');
            this.$bvToast.hide('example-toast');
            this.data.changedMtpLoading = false;
            this.refreshTable();
        });
        EventBus.$on(EventBus.REMOVE_BUDGET_REQUEST.REMOVE_MODAL, async (stateBr) => {
            this.data.changedMtpLoading = true;
            if (stateBr === 'Delete') {
                $('#removeBrModal').modal('hide');
                this.initialDataRemove();
                await this.removeBr();
                await (this.$refs['get-budget-distribution'] as any).getDataMtpStatus(false);
                this.clearItemsChecked();
                this.$bvToast.show('example-toast');
            } else if (stateBr === 'SetInactive') {
                $('#removeBrModal').modal('hide');
                this.initialDataRemove();
                await this.setBudgetRequestsInactive();
                await (this.$refs['get-budget-distribution'] as any).getDataMtpStatus(false);
                this.clearItemsChecked();
                this.$bvToast.show('inactive');
            }
            this.data.changedMtpLoading = false;
            this.refreshTable();
        });
        EventBus.$on(EventBus.REMOVE_BUDGET_REQUEST.SET_INACTIVE_MODAL, async () => {
            this.data.changedMtpLoading = true;
            $('#setInactiveModal').modal('hide');
            this.initialDataRemove();
            await this.setBudgetRequestsInactive();
            await (this.$refs['get-budget-distribution'] as any).getDataMtpStatus(false);
            this.$bvToast.show('inactive');
            this.data.changedMtpLoading = false;
            this.refreshTable();
        });
    }

    beforeDestroy() {
        EventBus.$off();
    }

    @Watch('data.selectedPlant', { immediate: true, deep: true })
    async onSelectedPlantChanged(val: string, oldVal: string) {
        if (JSON.stringify(val) !== JSON.stringify(oldVal) && this.$route.path === '/budget-requests') {
            this.clearItemsChecked();
            this.resetPageTable();
            await this.loadBudget();
            this.refreshTable();
        }
    }

    public async loadBudget(): Promise<void> {
        if (!this.data.selectedPlant) {
            return;
        }
        this.loading = true;
        this.data.navBarRefresh = true;

        this.selectedPlants = this.data.selectedPlant.map(plant => plant.plantSid);

        if (this.mtpYear === null || this.miniFdYear === null) {
            this.setMtpFilter();
        }

        if (parseInt(this.mtpYear) === 0) {
            this.isActive = false;
        } else {
            this.isActive = true;
        }
        if (sessionStorage.getItem('moreFilterActivBudRequests')) {
            this.moreFilterActiv = JSON.parse(sessionStorage.getItem('moreFilterActivBudRequests'));
        }
        if (localStorage.elementsPerPageBudRequests) {
            this.elementsPerPage = Number(localStorage.elementsPerPageBudRequests);
        }
        this.getBrModelClean();
        if (sessionStorage['searchModel' + this.mtpYear]) {
            this.setSearchModelFilters(sessionStorage['searchModel' + this.mtpYear]);
        }

        this.selectedIsReadyForApproval = this.searchModel.filters.isReadyForApproval;

        this.loading = false;
        this.data.navBarRefresh = false;
    }

    private itemsCheckedBr(globalId, mtpStatus, year, name, currentMtpPlanning, id, created) {
        return {
            globalId: globalId,
            mtpStatus: mtpStatus,
            year: year,
            name: name,
            currentMtpPlanning: currentMtpPlanning,
            id: id,
            created: moment(Helper.UtcToLocalTime(created)).format('YYYY')
        };
    }

    private getBrModelClean() {
        this.selectedMaintenanceTypeFilters = [];
        this.selectedMtpStatusFilters = [];
        this.selectedProjectTypeFilters = [];
        this.selectedaeroClassificationFilters = [];
        this.selectedoutageTypeFilters = [];
        this.selectedplanningAndControllingClassificationFilters = [];
        this.selectedfundingStatusFilters = [];
        this.selectedprojectStatusTypeFilters = [];
        this.selectedFocusTopicFilters = [];
        this.selectedPrioritizationCategoryFilters = [];
        this.selectedprimaryDepartmentFilters = [];
        this.selectedmainFlagFilters = [];
        this.selectedlumpSumFilters = [];
        this.selectedIsReadyForApproval = false;
        this.searchText = '';
    }

    private checkIfSelectedAll() {
        let arrayCheck = [];
        this.checkedAll = true;
        this.budgetRequests.budgetRequests.forEach((t) => {
            const flag = this.itemsChecked.find((f) => f.id === t.id);
            if (flag === undefined) {
                this.checkedAll = false;
            }
        });
        this.initScrollPosition();
    }

    private clearItemsChecked() {
        this.itemsChecked = [];
        this.checkedAll = false;
        this.gridApi?.deselectAll();
    }

    private selectAll() {
        if (!this.checkedAll) {
            this.checkedAll = true;
            const listOfObjects = [];
            this.budgetRequests.budgetRequests.forEach(function (entry) {
                const singleObj = {};
                singleObj['currentMtpPlanning'] = entry.currentMtpPlanning;
                singleObj['globalId'] = entry.globalId;
                singleObj['id'] = entry.id;
                singleObj['mtpStatus'] = entry.currentMtpPlanning.mtpStatus;
                singleObj['year'] = entry.currentMtpPlanning.year;
                singleObj['name'] = entry.name;
                singleObj['created'] = moment(Helper.UtcToLocalTime(entry.created)).format('YYYY');
                listOfObjects.push(singleObj);
            });
            listOfObjects.forEach((e) => {
                this.itemsChecked.push(e);
            });
            this.itemsChecked = this.itemsChecked.filter(obj => !listOfObjects[obj.id] && (listOfObjects[obj.id] = true));
        } else {
            this.clearItemsChecked();
        }
    }

    private selectedBudgetGlobalId(globalId: string) {
        this.selectedGlobalId = globalId;
        this.clearItemsChecked();
    }

    private emitDetails(year, globalId) {
        const routeData = this.$router.resolve({
            path: 'details-page',
            query: { year: year, globalId: globalId, miniFdYear: this.miniFdYear }
        });
        window.open(routeData.href, '_blank');
    }

    private emitEdit(year, globalId, mtpList, miniFdYear?: number): void {
        if (year !== this.currentYear && mtpList.find(y => y === this.currentYear)) {
            this.selectedGlobalId = globalId;
            this.selectedMtpYear = year;
            $('#moveCurrentMtpWarningModal').modal('show');
        } else {
            this.$router.push({
                path: 'budget-request',
                query: {
                    year: year,
                    globalId: globalId,
                    miniFdYear: mtpList.length === 0 ? miniFdYear?.toString() : undefined
                }
            });
        }
    }

    private emitDuplicate(year, globalId, mtpList, miniFdYear?: number): void {
        this.$router.push({
            path: 'budget-request',
            query: {
                year: year,
                globalId: globalId,
                miniFdYear: mtpList.length === 0 ? miniFdYear?.toString() : undefined,
                isDuplicate: '1'
            }
        });
    }

    private resetPageTable() {
        this.currentPage = 1;
        this.elementsPerPage = Number(this.elementsPerPage);
        this.firstElementTable = 1;
    }

    private async changeMtpPeriod() {
        this.mtpYear = parseInt(this.selectedMtpFilter.split('-')[0]);
        this.miniFdYear = parseInt(this.selectedMtpFilter.split('-')[1]);
        this.clearItemsChecked();
        this.resetPageTable();
        if (sessionStorage['searchModel' + this.mtpYear]) {
            this.setSearchModelFilters(sessionStorage['searchModel' + this.mtpYear]);
        } else {
            this.clearAll();
        }

        if (this.mtpYear === 0) {
            this.isActive = false;
        } else {
            this.isActive = true;
        }

        this.data.switchPageView = true;
        this.refreshTable();
        localStorage.setItem('selectedMtp', this.mtpYear);
        localStorage.setItem('selectedMiniFd', this.miniFdYear);
        await (this.$refs['get-budget-distribution'] as any).getDataMtpStatus(false);
        this.data.switchPageView = false;
    }

    private forceFileDownload(name, data) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
    }

    private async exportToExcelTable() {
        this.exportBudgetRequests.globalIds = [];
        this.exportBudgetRequests.year = this.mtpYear;
        this.exportBudgetRequests.miniFdYear = this.miniFdYear;
        const listOfGlobalId = [];
        this.itemsChecked.forEach((e) => {
            listOfGlobalId.push(e.globalId);
        });
        this.exportBudgetRequests.globalIds = listOfGlobalId;
        this.exportExcel();
    }

    private async exportExcel() {
        this.data.exportBr = true;
        const content = await this.ExportBudgetRequestService.exportBudgetRequests(this.exportBudgetRequests);
        this.forceFileDownload(content.name, content.content);
        this.data.exportBr = false;
    }

    private async deleteBudgetRequest(mtpStatus?, globalId?) {
        $('#confirmDeleteModal').modal('hide');

        this.deleteBudgetRequests.globalIds = [];
        const listOfMtpStatus = [];
        const globalIds = [];

        if (globalId) {
            this.deleteBudgetRequests.globalIds = [globalId];
        } else {
            this.itemsChecked.forEach((e) => {
                this.deleteBudgetRequests.globalIds.push(e.globalId);
            });
            this.itemsChecked.forEach((e) => {
                listOfMtpStatus.push(e.mtpStatus);
            });
        }

        if ((listOfMtpStatus.includes(3) || listOfMtpStatus.includes(4) || listOfMtpStatus.includes(5)) && (!listOfMtpStatus.includes(1) && !listOfMtpStatus.includes(2))) {
            $('#deleteBrModal').modal('show');
        } else if ((listOfMtpStatus.includes(1) || listOfMtpStatus.includes(2)) && (!listOfMtpStatus.includes(3) && !listOfMtpStatus.includes(4) && !listOfMtpStatus.includes(5))) {
            $('#confirmDeleteModal').modal('show');
        } else if (listOfMtpStatus.includes(3) || listOfMtpStatus.includes(4) || listOfMtpStatus.includes(5)) {
            $('#deleteRemainTableModal').modal('show');
            this.deleteBudgetRequests.globalIds = [];
            this.itemsChecked.forEach((e) => {
                if (e.mtpStatus === 1 || e.mtpStatus === 2) {
                    this.deleteBudgetRequests.globalIds.push(e.globalId);
                }
            });
        } else if (mtpStatus === 3 || mtpStatus === 4 || mtpStatus === 5) {
            $('#deleteBrModal').modal('show');
        } else if (mtpStatus === 1 || mtpStatus === 2) {
            $('#confirmDeleteModal').modal('show');
        }
    }

    private async searchByMtpStatus() {
        if (this.moreFilterActiv) {
            this.moreFilterActiv = false;
            sessionStorage.setItem('moreFilterActivBudRequests', JSON.stringify(this.moreFilterActiv));
        } else {
            this.moreFilterActiv = true;
            sessionStorage.setItem('moreFilterActivBudRequests', JSON.stringify(this.moreFilterActiv));
        }
    }

    private clearFilter(filter) {
        this[filter] = [];
        this.activeButtonApplyFilter = true;
    }

    private async applyFilter(filtersName, selected) {
        sessionStorage.setItem('searchModel' + this.mtpYear, JSON.stringify(this.searchModel.filters));
        this.resetPageTable();
    }

    private async applyIsReadyForApprovalFilter(newValue: boolean) {
        this.selectedIsReadyForApproval = newValue;
        this.refreshTable();
        this.calcIsFilterApplied();
    }

    private async searchByText() {
        this.resetPageTable();
    }

    private async clearSearch() {
        this.searchText = '';
        this.resetPageTable();
    }

    private async applyFilterLump() {
        this.resetPageTable();
    }

    private async clearAll() {
        sessionStorage.removeItem('searchModel' + this.mtpYear);
        this.currentPage = 1;
        this.firstElementTable = 1;
        this.getBrModelClean();
    }

    private async resetFilters() {
        this.clearAll();
        this.moreFilterActiv = false;
        sessionStorage.setItem('moreFilterActivBudRequests', JSON.stringify(this.moreFilterActiv));
    }

    private nameOfFilters(nameFilters: string, nameConstant: string, isChild: string) {
        let localName = [''];
        this.searchModel.filters[nameFilters].forEach((e: number) => {
            if (isChild !== null) {
                localName.push(this.data.constants[nameConstant][e][isChild] + ', ');
            } else {
                localName.push(this.data.constants[nameConstant][e] + ', ');
            }
        });
        if (localName)
            localName[localName.length - 1] = localName[localName.length - 1].split(',')[0];
        return localName;

    }

    private async changeMtpFromDropDown(mtpStatusKey, brId, brCurrentPlanning, globalId) {
        this.singleChangeMtp = {
            globalIds: [globalId],
            mtpYear: brCurrentPlanning.year,
            mtpStatus: parseInt(mtpStatusKey)
        };
        this.keyIdFromChangeMtp = mtpStatusKey;
        if (parseInt(mtpStatusKey) === 1 && parseInt(mtpStatusKey) !== brCurrentPlanning.mtpStatus) {
            $('#updateMtpModal').modal('show');
        }
        if (parseInt(mtpStatusKey) === 2 && parseInt(mtpStatusKey) !== brCurrentPlanning.mtpStatus) {
            if (brCurrentPlanning.mtpStatus === 1) {
                this.data.changedMtpLoading = true;
                await this.budgetRequestService.changeMtpStatus(this.singleChangeMtp);
                this.data.selectedOneMtpUpdateStatus = this.singleChangeMtp.globalIds.length + ' budget request updated to "Submitted". These requests are now ready to be reviewed.';
                this.clearItemsChecked();
                await (this.$refs['get-budget-distribution'] as any).getDataMtpStatus(false);
                this.data.changedMtpLoading = false;
                this.$bvToast.show('toast-mtp');
            }
        }

        if (parseInt(mtpStatusKey) === 4 && parseInt(mtpStatusKey) !== brCurrentPlanning.mtpStatus) {
            if (brCurrentPlanning.mtpStatus !== 1) {
                this.mainFlagFromChangeMtp = brCurrentPlanning.mainFlag;
                this.otherFlagsFromChangeMtp = brCurrentPlanning.otherFlags;
                this.commentFromChangeMtp = brCurrentPlanning.reviewComment;
                this.yearFromChangeMtp = brCurrentPlanning.year;
                this.brIdChangeMtp = brId;
                this.globalIdFromChangeMtp = globalId;
                (this.$refs['change-review-modal'] as any).clear();
                $('#changeReviewModal').modal('show');
            }
        }

        if (parseInt(mtpStatusKey) === 3 && parseInt(mtpStatusKey) !== brCurrentPlanning.mtpStatus) {
            this.mainFlagFromChangeMtp = brCurrentPlanning.mainFlag;
            this.otherFlagsFromChangeMtp = brCurrentPlanning.otherFlags;
            this.commentFromChangeMtp = brCurrentPlanning.reviewComment;
            this.yearFromChangeMtp = brCurrentPlanning.year;
            this.brIdChangeMtp = brId;
            this.globalIdFromChangeMtp = globalId;
            (this.$refs['change-review-modal'] as any).clear();
            $('#changeReviewModal').modal('show');

        }
        if (parseInt(mtpStatusKey) === 5 && parseInt(mtpStatusKey) !== brCurrentPlanning.mtpStatus) {
            if (brCurrentPlanning.mtpStatus === 4 && brCurrentPlanning.new1 > 0) {
                await this.budgetRequestService.changeMtpStatus(this.singleChangeMtp);
                this.data.selectedOneMtpUpdateStatus = this.singleChangeMtp.globalIds.length + ' budget request updated to "Approved"';
                this.clearItemsChecked();
                await (this.$refs['get-budget-distribution'] as any).getDataMtpStatus(false);
                this.$bvToast.show('toast-mtp');
            }
        }

        this.refreshTable();
    }

    private async exportToExcel(year, globalId, miniFdYear) {
        this.exportBudgetRequests.globalIds = [];
        this.exportBudgetRequests.year = year;
        this.exportBudgetRequests.globalIds.push(globalId);

        if (this.miniFdYear) {
            this.exportBudgetRequests.miniFdYear = miniFdYear;
        }

        this.exportExcel();
    }

    private disableUpdateMtpBySelected(key, selectedMtp, nextOneYear) {
        if (parseInt(selectedMtp) === 1 && parseInt(key) !== 2 && parseInt(key) !== 1) {
            return true;
        }
        if (parseInt(selectedMtp) === 2 && parseInt(key) !== 2 && parseInt(key) !== 1 && parseInt(key) !== 3 && parseInt(key) !== 4) {
            return true;
        }

        if (parseInt(selectedMtp) === 3 && parseInt(key) !== 3 && parseInt(key) !== 1 && parseInt(key) !== 4) {
            return true;
        }
        if (parseInt(selectedMtp) === 4 && parseInt(key) !== 4 && parseInt(key) !== 1 && parseInt(key) !== 3) {
            if (parseInt(key) !== 5) {
                return true;
            } else if (nextOneYear <= 0) {
                return true;
            }
        }
        if (parseInt(selectedMtp) === 5 && parseInt(key) !== 5 && parseInt(key) !== 1 && parseInt(key) !== 3 && parseInt(key) !== 4) {
            return true;
        }

        return false
    }

    private disableUpdateMtpByMultipleSelected(key) {
        let disable = false
        
        this.itemsChecked.forEach(item => {
            if(this.disableUpdateMtpBySelected(Number(key), item.currentMtpPlanning.mtpStatus, item.currentMtpPlanning.new1)) {
                disable = true;
                return;
            }
        });

        return disable;
    }

    private openDropdownUpdateMtp() {
        this.checkStatuses = false;
        this.checkReviewedStatuses = false;
        this.itemsChecked.forEach((e) => {
            if (e.mtpStatus !== 1 && e.mtpStatus !== 2) {
                this.checkStatuses = true;

                if (e.mtpStatus !== 4 || e.currentMtpPlanning.new1 <= 0) {
                    this.checkReviewedStatuses = true;
                }
            }
        });
    }

    private hideToastDelBr() {
        this.$bvToast.hide('example-toast');
        this.$bvToast.hide('toast-mtp');
        this.$bvToast.hide('remove-and-set-inactive');
        this.$bvToast.hide('funding-status-toast');
        this.$bvToast.hide('edit-controlling-toast');
        this.$bvToast.hide('project-status-toast');
    }

    private checkIfSelected() {
        this.checkedAll = false;
    }

    private clearFilterSelected(selected, filters) {
        this.activeButtonApplyFilter = false;
        this[selected] = this.searchModel.filters[filters];
    }

    private activeButtonApply() {
        this.activeButtonApplyFilter = true;
    }

    private openModalRemoveFromMtp(name, globalId, mtpStatus, createdIsFormated?, created?) {
        (this.$refs['remove-br-modal'] as any).clear();
        if (mtpStatus !== 5) {
            if ((mtpStatus === 1 || mtpStatus === 2) && this.isActive) {
                this.createdYear = Number(moment(Helper.UtcToLocalTime(created)).format('YYYY'));
                if (createdIsFormated) {
                    this.createdYear = Number(createdIsFormated);
                }
                $('#removeBrModal').modal('show');
            } else {
                $('#setInactiveModal').modal('show');
            }
            this.dataBrToModal = {
                name: name,
                globalId: globalId,
                mtpStatus: mtpStatus
            };
        }
    }

    private initialDataRemove() {
        this.deleteBudgetRequests.globalIds = [];
        this.deleteBudgetRequests.globalIds.push(this.dataBrToModal['globalId']);
        this.setToInactiveBr.globalIds = [];
        this.setToInactiveBr.globalIds.push(this.dataBrToModal['globalId']);
    }

    private async removeBr() {
        if (this.isActive) {
            this.data.removeFromMtp = true;
            this.deleteBudgetRequests.mtpYear = parseInt(this.mtpYear);
        } else {
            this.data.deleteBr = true;
            this.deleteBudgetRequests.mtpYear = null;
        }
        this.deleteBudgetRequests.isActive = this.isActive;
        await this.budgetRequestService.deleteBudgetRequest(this.deleteBudgetRequests);
        this.resetPageTable();
        this.data.removeFromMtp = false;
        this.data.deleteBr = false;

    }

    private async setBudgetRequestsInactive() {
        this.data.removeFromMtp = true;
        if (this.isActive) {
            this.setToInactiveBr.mtpYear = parseInt(this.mtpYear);
        } else {
            this.setToInactiveBr.mtpYear = null;
        }
        await this.budgetRequestService.setBudgetRequestsInactive(this.setToInactiveBr);
        this.resetPageTable();
        this.data.removeFromMtp = false;

    }

    private removeMultipleMtp() {
        if (this.itemsChecked.length !== 1) {
            $('#removeFromMtpTableModal').modal('show');
        } else {
            this.openModalRemoveFromMtp(this.itemsChecked[0].name, this.itemsChecked[0].globalId, this.itemsChecked[0].mtpStatus, this.itemsChecked[0].created);
        }
        (this.$refs['remove-from-mtp-table-modal'] as any).clear();
    }

    private async updateRemoveMtp(value) {
        this.deleteBudgetRequests.globalIds = [];
        this.deleteBudgetRequests.globalIds = value.delete;
        this.setToInactiveBr.globalIds = [];
        this.setToInactiveBr.globalIds = value.setToInactive;
        if (value.delete.length !== 0 || value.setToInactive.length !== 0) {
            $('#removeFromMtpTableModal').modal('hide');
            await this.removeBr();
            await this.setBudgetRequestsInactive();
            this.clearItemsChecked();
            (this.$refs['get-budget-distribution'] as any).getDataMtpStatus(false);
            this.$bvToast.show('remove-and-set-inactive');
        }
    }

    private async updateMultipleMtpStatuses(keyMtpStatus) {
        let changeMtpStatus = {
            globalIds: [],
            mtpYear: parseInt(this.mtpYear),
            mtpStatus: null,
        };
        this.itemsChecked.forEach((e) => {
            changeMtpStatus.globalIds.push(e.globalId);
            changeMtpStatus.mtpStatus = parseInt(keyMtpStatus);
            this.singleChangeMtp = changeMtpStatus;
        });

        if (parseInt(keyMtpStatus) === 1) {
            $('#updateMtpModal').modal('show');
        }

        if (parseInt(keyMtpStatus) === 2 && !this.checkStatuses) {
            let errorChangeMtp: any;
            this.data.changedMtpLoading = true;
            await this.budgetRequestService.changeMtpStatus(changeMtpStatus).then((response) => {
                errorChangeMtp = response;
            });
            if (errorChangeMtp['requestIsValid']) {
                this.data.selectedOneMtpUpdateStatus = this.singleChangeMtp.globalIds.length + ' budget request updated to "Submitted". These requests are now ready to be reviewed.';
                if (this.itemsChecked.length === 1) {
                    this.data.selectedOneMtpUpdateStatus = this.singleChangeMtp.globalIds.length + ' budget request updated to "Submitted". This request is now ready to be reviewed.';
                }
                this.clearItemsChecked();
                await (this.$refs['get-budget-distribution'] as any).getDataMtpStatus(false);
                this.data.changedMtpLoading = false;
                this.$bvToast.show('toast-mtp');
                this.refreshTable();
            }
        }

        if (parseInt(keyMtpStatus) === 5 && !this.checkReviewedStatuses) {
            let errorChangeMtp: any;
            this.data.changedMtpLoading = true;
            await this.budgetRequestService.changeMtpStatus(changeMtpStatus).then((response) => {
                errorChangeMtp = response;
            });
            if (errorChangeMtp['requestIsValid']) {
                this.data.selectedOneMtpUpdateStatus = this.singleChangeMtp.globalIds.length + ' budget request updated to "Approved".';
                this.clearItemsChecked();
                await (this.$refs['get-budget-distribution'] as any).getDataMtpStatus(false);
                this.data.changedMtpLoading = false;
                this.$bvToast.show('toast-mtp');
                this.refreshTable();
            }
        }

        if (parseInt(keyMtpStatus) === 3 && this.itemsChecked[0].mtpStatus === 2) {
            this.changeMtpFromDropDown(keyMtpStatus, this.itemsChecked[0].id, this.itemsChecked[0].currentMtpPlanning, this.itemsChecked[0].globaId);
        }

        if (parseInt(keyMtpStatus) === 3 && this.itemsChecked[0].mtpStatus === 4) {
            this.changeMtpFromDropDown(keyMtpStatus, this.itemsChecked[0].id, this.itemsChecked[0].currentMtpPlanning, this.itemsChecked[0].globaId);
        }

        if (parseInt(keyMtpStatus) === 3 && this.itemsChecked[0].mtpStatus === 5) {
            this.changeMtpFromDropDown(keyMtpStatus, this.itemsChecked[0].id, this.itemsChecked[0].currentMtpPlanning, this.itemsChecked[0].globaId);
        }

        if (parseInt(keyMtpStatus) === 4 && this.itemsChecked[0].mtpStatus === 3) {
            this.changeMtpFromDropDown(keyMtpStatus, this.itemsChecked[0].id, this.itemsChecked[0].currentMtpPlanning, this.itemsChecked[0].globaId);
        }

        if (parseInt(keyMtpStatus) === 4 && this.itemsChecked[0].mtpStatus === 2) {
            this.changeMtpFromDropDown(keyMtpStatus, this.itemsChecked[0].id, this.itemsChecked[0].currentMtpPlanning, this.itemsChecked[0].globaId);
        }

        if (parseInt(keyMtpStatus) === 4 && this.itemsChecked[0].mtpStatus === 5) {
            this.changeMtpFromDropDown(keyMtpStatus, this.itemsChecked[0].id, this.itemsChecked[0].currentMtpPlanning, this.itemsChecked[0].globaId);
        }
    }

    private async deleteBrInactive() {
        $('#confirmDeleteModal').modal('hide');
        this.setToInactiveBr.globalIds = [];
        this.clearItemsChecked();
        this.deleteBudgetRequests.isActive = this.isActive;
        this.data.deleteBr = true;
        await this.budgetRequestService.deleteBudgetRequest(this.deleteBudgetRequests);
        this.data.deleteBr = false;
        this.$bvToast.show('remove-and-set-inactive');
    }

    private async deleteOnlyDraftAndSubmitted() {
        $('#deleteRemainTableModal').modal('hide');
        await this.deleteBrInactive();
    }

    private addBudReq() {
        $('#addBudgetRequestsModal').modal('show');
        this.$bvToast.hide('toast-exception');
        this.$bvToast.hide('toast-error-import');
    }

    private importFromExcel(event) {
        (this.$refs['import-from-excel'] as any).upload(event);
    }

    private async succesImport() {
        this.resetPageTable();
        (this.$refs['get-budget-distribution'] as any).getDataMtpStatus(false);
    }

    private showError(value) {
        this.errorImport = value;
    }

    private resetValueImport() {
        this.$bvToast.hide('toast-exception');
        this.$bvToast.hide('toast-error-import');
    }

    private async filteredMtpStatuses(value) {
        this.clearItemsChecked();
        if ($('.accordion-budget .btn').hasClass('collapsed')) {
            $('.accordion-budget .btn').click();
        }
        this.resetPageTable();
        this.selectedMtpStatusFilters[0] = value;
        let elmnt = document.getElementById('accordion');
        elmnt.scrollIntoView({
            behavior: 'smooth'
        });
        // new table
        this.gridApi.setFilterModel({
            'currentMtpPlanning.mtpStatus': {
                filterType: 'set',
                values: [value.toString()],
            }
        })
        this.gridApi.onFilterChanged();
    }

    private showLocalDate(date) {
        return moment(Helper.UtcToLocalTime(date)).format('DD/MM/YYYY');
    }

    private checkCreatedDate(date) {
        let formatedDate = moment(Helper.UtcToLocalTime(date)).format('YYYY');
        if (this.currentYear === parseInt(formatedDate)) {
            return true;
        } else {
            return false;
        }
    }

    private async bulkApproval() {
        this.loading = true;
        this.bulkApprove.plantIds = this.selectedPlants;
        this.bulkApprove.mtpYear = this.mtpYear;
        let approvedBudgetRequestNumber = (await this.ApproveService.BulkApprove(this.bulkApprove)).approvedCount;
        this.data.selectedOneMtpUpdateStatus = approvedBudgetRequestNumber + ' budget request updated to "Approved".';
        (this.$refs['get-budget-distribution'] as any).getDataMtpStatus(false);
        this.loading = false;
        this.$bvToast.show('toast-mtp');
        this.refreshTable();
    }

    private bulkApprovalConfirm() {
        $('#bulkApproveModal').modal('show');
    }

    private async changeFundingStatus(fundingStatusKey, globalId, currentFundingStatus, mtpYear) {
        let changeFundingStatus = {
            globalIds: [globalId],
            mtpYear: mtpYear,
            fundingStatus: parseInt(fundingStatusKey)
        };

        if (parseInt(fundingStatusKey) !== currentFundingStatus) {
            try {
                let response = await this.budgetRequestService.releaseBudgetRequests(changeFundingStatus);
                this.data.updatedFundingStatus = response.countUpdates + ` budget request's funding status was updated to "` + this.data.constants.fundingStatus[fundingStatusKey] + `".`;
                this.clearItemsChecked();
                this.$bvToast.show('funding-status-toast');
            } catch (error) {
                this.data.updatedFundingStatus = 'Something bad happened.';
                this.$bvToast.show('funding-status-toast');
            }
        }
    }

    private async changeMultipleFundingStatus(fundingStatusKey) {
        let changeFundingStatus = {
            globalIds: [],
            mtpYear: parseInt(this.mtpYear),
            fundingStatus: parseInt(fundingStatusKey),
        };

        this.itemsChecked.forEach((e) => {
            if (e.currentMtpPlanning.fundingStatus !== parseInt(fundingStatusKey)) {
                changeFundingStatus.globalIds.push(e.globalId);
            }
        });

        try {
            let response = await this.budgetRequestService.releaseBudgetRequests(changeFundingStatus);
            this.data.updatedFundingStatus = response.countUpdates + ` budget request's funding status was updated to "` + this.data.constants.fundingStatus[fundingStatusKey] + `".`;
            this.clearItemsChecked();
            this.$bvToast.show('funding-status-toast');
        } catch (error) {
            this.data.updatedFundingStatus = 'Something bad happened.';
            this.$bvToast.show('funding-status-toast');
        }
    }

    private async changeMultipleProjectStatus(projectStatusKey) {
        let changeProjectStatus = {
            globalIds: [],
            mtpYear: parseInt(this.mtpYear),
            projectStatus: parseInt(projectStatusKey),
        };

        this.itemsChecked.forEach((e) => {
            if (e.currentMtpPlanning.fundingStatus !== parseInt(projectStatusKey)) {
                changeProjectStatus.globalIds.push(e.globalId);
            }
        });

        try {
            let response = await this.budgetRequestService.editProjectStatus(changeProjectStatus);
            this.data.updatedProjectStatus = response.countUpdates + ` budget request's project status was updated to "` + this.data.constants.projectStatusType[projectStatusKey] + `".`;
            this.clearItemsChecked();
            this.$bvToast.show('project-status-toast');
        } catch (error) {
            this.data.updatedProjectStatus = 'Something bad happened.';

            if (error?.response?.data?.errors?.[0]) {
                this.data.updatedProjectStatus = error?.response?.data?.errors?.[0];
            }
            
            this.$bvToast.show('project-status-toast');
        }
    }

    private initScrollPosition() {
        let container = document.querySelector('.table-scroll');
        if (container) {
            container.scrollLeft = 0;
        }
    }

    private editControllingInformation(id: string, globalId: string, year: any, mtpStatus: any, wbsElement: string, comment: string, planningAndControllingClassification: any, outageType: number, outageId: string, startDate: string, hasPlanningCosts: boolean) {
        $('#editControllingModal').modal('show');
        this.$bvToast.hide('edit-controlling-toast');
        let dataEditControlling = {
            budgetRequestId: id,
            globalId: globalId,
            year: year,
            mtpStatus: mtpStatus,
            wbsElement: wbsElement,
            comment: comment,
            planningAndControllingClassification: planningAndControllingClassification,
            outageType: outageType,
            outageId: outageId,
            startDate: startDate,
            hasPlanningCosts: hasPlanningCosts,
        };

        (this.$refs['edit-controlling'] as any).setDataEditControlling(dataEditControlling);
    }

    private getDataEditControlling(data: any) {
        this.budgetRequests.budgetRequests.find(g => g.globalId === data.globalId).currentMtpPlanning.wbsElement = data.wbsElement;
        this.budgetRequests.budgetRequests.find(g => g.globalId === data.globalId).currentMtpPlanning.comment = data.comment;
        this.budgetRequests.budgetRequests.find(g => g.globalId === data.globalId).currentMtpPlanning.outageId = data.outageId;
        this.budgetRequests.budgetRequests.find(g => g.globalId === data.globalId).currentMtpPlanning.startDate = data.startDate;
        this.budgetRequests.budgetRequests.find(g => g.globalId === data.globalId).currentMtpPlanning.hasPlanningCosts = data.hasPlanningCosts;

        this.$bvToast.show('edit-controlling-toast');
    }

    private setMtpFilter() {
        this.mtpYear = Data.Instance.currentMtpPeriod?.year ?? this.currentYear;
        this.miniFdYear = Data.Instance.constants.mtpFilters.find(mtp => mtp.year === this.mtpYear).miniFdYear;
        this.selectedMtpFilter = `${this.mtpYear}-${this.miniFdYear}`;
        localStorage.setItem('selectedMtp', this.mtpYear);
        localStorage.setItem('selectedMiniFd', this.miniFdYear);
    }

    private setSearchModelFilters(filters) {
        const filterValues = JSON.parse(filters);
        this.selectedaeroClassificationFilters = filterValues.aeroClassificationFilters;
        this.selectedfundingStatusFilters = filterValues.fundingStatusFilters;
        this.selectedprojectStatusTypeFilters = filterValues.projectStatusTypeFilters;
        this.selectedprimaryDepartmentFilters = filterValues.primaryDepartmentFilters;
        this.selectedlumpSumFilters = filterValues.lumpSumFilters;
        this.selectedmainFlagFilters = filterValues.mainFlagFilters;
        this.selectedMaintenanceTypeFilters = filterValues.maintenanceTypeFilters;
        this.selectedMtpStatusFilters = filterValues.mtpStatusFilters;
        this.selectedoutageTypeFilters = filterValues.outageTypeFilters;
        this.selectedplanningAndControllingClassificationFilters = filterValues.planningAndControllingClassificationFilters;
        this.selectedProjectTypeFilters = filterValues.projectTypeFilters;
        this.selectedIsReadyForApproval = filterValues.isReadyForApproval;
    }

    private get isLoading(): boolean {
        return this.data.navBarRefresh || this.loading;
    }

    // NEW TABLE

    private get columnDefs(): ColDef[] {
        return [
            {
                headerName: 'Global Id',
                field: 'globalId',
                headerCheckboxSelection: true,
                checkboxSelection: true,
                showDisabledCheckboxes: true,
                pinned: 'left',
                cellRenderer: 'GlobalIdCell',
                cellRendererParams: (params: ICellRendererParams) => ({
                    href: 'details-page',
                    query: {
                        year: this.absMtpYear || params.data.currentMtpPlanning.year,
                        globalId: params.value,
                        miniFdYear: this.miniFdYear,
                    },
                }),
            },
            { headerName: 'Name', field: 'name', pinned: 'left', tooltipField: 'name' },
            { headerName: 'Name (Local Language)', field: 'localLanguageName' },
            { headerName: 'Combined Id', field: 'combinedId' },
            {
                headerName: 'Status',
                field: 'currentMtpPlanning.mtpStatus',
                valueGetter: (params): string => this.data.constants.mtpStatus[params.data.currentMtpPlanning.mtpStatus]?.name,
                cellRenderer: 'StatusCell',
                cellRendererParams: (params: ICellRendererParams) => ({ color: STATUS_COLOR_MAP[this.data.constants.mtpStatus[params.data.currentMtpPlanning.mtpStatus]?.color] }),
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.mtpStatus));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.mtpStatus[params.value].name;
                    }
                },
            },
            { headerName: 'WBS Element', field: 'currentMtpPlanning.wbsElement' },
            {
                headerName: 'Country',
                field: 'countryCodes',
                valueFormatter: (params): string => params.value.join(', '),
            },
            { headerName: 'Plant / River Group', field: 'plantGroupName' },
            { headerName: 'Plant', field: 'plantName' },
            { headerName: 'Unit', field: 'unitName', resizable: true },
            {
                headerName: 'Project Type',
                field: 'projectType',
                valueFormatter: (params): string => this.data.constants.projectTypes[params.value],
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.projectTypes));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.projectTypes[params.value];
                    }
                },
            },
            {
                headerName: 'Lump Sum',
                field: 'currentMtpPlanning.lumpSum',
                valueFormatter: (params): string => params.value ? 'Yes' : 'No',
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: ['yes', 'no'],
                    valueFormatter: (params: ValueFormatterParams): string => params.value === 'yes' ? 'Yes' : 'No'
                },
            },
            {
                headerName: 'AERO Classification',
                field: 'currentMtpPlanning.aeroClassification',
                valueFormatter: (params): string => this.data.constants.aeroClassification[params.value],
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.aeroClassification));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.aeroClassification[params.value];
                    }
                },
            },
            { headerName: 'Justification On Classification', field: 'currentMtpPlanning.justificationOnClassification', resizable: true },
            {
                headerName: 'Maintenance Type',
                field: 'currentMtpPlanning.maintenanceType',
                valueFormatter: (params): string => this.data.constants.maintenanceType[params.value],
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.maintenanceType));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.maintenanceType[params.value];
                    }
                },
            },
            {
                headerName: 'Outage Type',
                field: 'currentMtpPlanning.outageType',
                valueFormatter: (params): string => this.data.constants.outageType[params.value],
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.outageType));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.outageType[params.value];
                    }
                },
            },
            { headerName: 'Outage ID', field: 'currentMtpPlanning.outageId' },
            { headerName: 'Risk / Maintenance ID', field: 'currentMtpPlanning.riskOpportunityId' },
            {
                headerName: 'Procurement Category',
                field: 'currentMtpPlanning.procurementCategory',
                valueFormatter: (params): string => this.data.constants.procurementCategory[params.value],
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.procurementCategory));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.procurementCategory[params.value];
                    }
                },
            },
            { headerName: 'Comment', field: 'currentMtpPlanning.comment', tooltipField: 'currentMtpPlanning.comment' },
            {
                headerName: 'Planning and Controlling Classification',
                field: 'currentMtpPlanning.planningAndControllingClassification',
                valueFormatter: (params): string => this.data.constants.allPlanningAndControllingClassification[params.value],
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.allPlanningAndControllingClassification));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.allPlanningAndControllingClassification[params.value];
                    }
                },
            },
            {
                headerName: 'Useful Economic Life',
                field: 'currentMtpPlanning.usefulEconomicLife',
                valueFormatter: (params): string => params.value ? `${params.value} year(s)` : '-',
            },
            {
                headerName: 'Uniper Share of Budget',
                field: 'currentMtpPlanning.uniperShareOfBudget',
                valueFormatter: (params): string => `${params.value}%`,
            },
            {
                headerName: 'Start Date',
                field: 'currentMtpPlanning.startDate',
                valueFormatter: (params): string => params.value ? format(new Date(params.value), 'MMMM do yyyy') : '',
            },
            {
                headerName: 'End Date',
                field: 'currentMtpPlanning.endDate',
                valueFormatter: (params): string => params.value ? format(new Date(params.value), 'MMMM do yyyy') : '',
            },
            { headerName: 'Currency', field: 'selectedCurrencyCode' },
            { headerName: `Previous MTP ${this.mtpYear}`, field: 'currentMtpPlanning.previousMtp1' },
            { headerName: `Previous MTP ${parseInt(this.mtpYear) + 1}`, field: 'currentMtpPlanning.previousMtp2' },
            { headerName: `Previous MTP ${parseInt(this.mtpYear) + 2}`, field: 'currentMtpPlanning.previousMtp3' },
            { headerName: 'Previous MTP Total', field: 'currentMtpPlanning.previousTotal' },
            { headerName: `Spend until end of ${parseInt(this.mtpYear) - 1}`, field: 'currentMtpPlanning.spendUntilEndOfYear' },
            { headerName: `Forecast MTP ${this.mtpYear}`, field: 'currentMtpPlanning.forecast' },
            { headerName: `New MTP ${parseInt(this.mtpYear) + 1}`, field: 'currentMtpPlanning.new1' },
            { headerName: `New MTP ${parseInt(this.mtpYear) + 2}`, field: 'currentMtpPlanning.new2' },
            { headerName: `New MTP ${parseInt(this.mtpYear) + 3}`, field: 'currentMtpPlanning.new3' },
            { headerName: `After ${parseInt(this.mtpYear) + 3}`, field: 'currentMtpPlanning.after' },
            { headerName: 'Total', field: 'currentMtpPlanning.total' },
            { headerName: `CR ${this.mtpYear}`, field: 'currentMtpPlanning.previousCrYear1' },
            { headerName: `CR ${parseInt(this.mtpYear) + 1}`, field: 'currentMtpPlanning.previousCrYear2' },
            { headerName: `CR ${parseInt(this.mtpYear) + 2}`, field: 'currentMtpPlanning.previousCrYear3' },
            { headerName: 'CR Total', field: 'currentMtpPlanning.previousCrTotal' },
            {
                headerName: 'Funding Status',
                field: 'currentMtpPlanning.fundingStatus',
                valueFormatter: (params): string => this.data.constants.fundingStatus[params.value],
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.fundingStatus));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.fundingStatus[params.value];
                    }
                },
            },
            {
                headerName: 'Project Status',
                field: 'currentMtpPlanning.projectStatus',
                valueFormatter: (params): string => this.data.constants.projectStatusType[params.value] ?? '-',
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.projectStatusType));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.projectStatusType[params.value];
                    }
                },
            },
            {
                headerName: 'Primary Department',
                field: 'currentMtpPlanning.primaryDepartment',
                valueFormatter: (params): string => this.data.constants.primaryDepartment[params.value],
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.primaryDepartment));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.primaryDepartment[params.value];
                    }
                },
            },
            {
                headerName: 'Main Flag',
                field: 'currentMtpPlanning.mainFlag',
                valueFormatter: (params): string => this.data.constants.flags[params.value] ?? '',
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.flags));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.flags[params.value];
                    }
                },
            },
            {
                headerName: 'Other Flags',
                field: 'currentMtpPlanning.otherFlags',
                valueFormatter: (params): string => {
                    const mappedFlags = params.value.map(flag => this.data.constants.flags[flag]);
                    return mappedFlags.join(', ');
                },
                resizable: true,
            },
            {
                headerName: 'Review Comment',
                field: 'currentMtpPlanning.reviewComment',
                valueFormatter: (params): string => {
                    if (params.value) {
                        return `${params.value.text} (${params.value.userName}, ${format(new Date(params.value.date), 'MMMM do yyyy')})`
                    }
                },
                resizable: true,
            },
            {
                headerName: 'Prioritisation Category',
                field: 'currentMtpPlanning.prioritizationCategory',
                valueFormatter: (params): string => this.data.constants.prioritizationCategory[params.value],
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.prioritizationCategory));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.prioritizationCategory[params.value];
                    }
                },
            },
            { headerName: 'Prioritisation Rank', field: 'currentMtpPlanning.prioritizationRank' },
            {
                headerName: 'Created',
                field: 'created',
                valueFormatter: (params): string => format(new Date(params.value), 'MMMM do yyyy'),
            },
            { headerName: 'Created By', field: 'createdBy' },
            {
                headerName: 'Modified',
                field: 'currentMtpPlanning.modified',
                valueFormatter: (params): string => format(new Date(params.value), 'MMMM do yyyy'),
            },
            { headerName: 'Modified By', field: 'currentMtpPlanning.modifiedBy' },
        ];
    }

    private gridOptions: GridOptions = {
        rowModelType: 'serverSide',
        serverSideDatasource: {
            getRows: (params: IServerSideGetRowsParams) => {
                this.fetchData(params);
            }
        },
        defaultColDef: {
            filter: true,
            sortable: false,
            unSortIcon: true,
            menuTabs: [],
            minWidth: 200,
        },
        domLayout: 'autoHeight',
        rowSelection: 'multiple',
        suppressRowClickSelection: true,
        suppressMenuHide: true,
        pagination: true,
        paginationPageSize: this.tableExtraFilters.pageSize,
        cacheBlockSize: this.tableExtraFilters.pageSize,
        noRowsOverlayComponent: 'NoRowsOverlay',
        serverSideFilterOnServer: true,
        popupParent: document.querySelector('body'),
        tooltipShowDelay: 0,
        tooltipMouseTrack: true,
        sideBar: {
            defaultToolPanel: '',
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
        },
        getRowId: (params): string => params.data.globalId,
        getContextMenuItems: (params) => this.globalIdContextMenu(params),
        onSelectionChanged: this.onSelectionChanged,
        onModelUpdated: this.onModelUpdated,
        onColumnMoved: this.setColumnState,
        onColumnVisible: this.setColumnState,
        onColumnPinned: this.setColumnState,
        onFilterChanged: () => {
            this.setFilterState();
            this.refreshTable();
        },
        onToolPanelVisibleChanged: this.sizeColumnsToFit,
        onToolPanelSizeChanged: this.sizeColumnsToFit,
        onGridSizeChanged: this.sizeColumnsToFit,
    };

    private async fetchData(params: IServerSideGetRowsParams): Promise<void> {
        params.api.hideOverlay();

        const { 
            mtpStatusFilters,
            projectTypeFilters,
            aeroClassificationFilters,
            maintenanceTypeFilters,
            outageTypeFilters,
            procurementCategoryFilters,
            planningAndControllingClassificationFilters,
            fundingStatusFilters,
            projectStatusTypeFilters,
            primaryDepartmentFilters,
            mainFlagFilters,
            prioritizationCategoryFilters,
        } = this.getSelectedFiltersForServerRequestParams(params);
        const lumpSumFilters = this.getYesNoFilter(params.request.filterModel['currentMtpPlanning.lumpSum']?.values);
        const pageNumber = this.pageNumber(params.request.endRow);
        const { pageSize, searchTerm } = this.tableExtraFilters;

        const searchParams: SearchModel = {
            plantIds: this.selectedPlants,
            mtpYear: parseInt(this.mtpYear),
            miniFdYear: parseInt(this.miniFdYear),
            searchText: searchTerm,
            itemsPerPage: pageSize,
            pageNo: pageNumber,
            isActive: this.isActive,
            filters: {
                mtpStatusFilters,
                projectTypeFilters,
                aeroClassificationFilters,
                maintenanceTypeFilters,
                outageTypeFilters,
                procurementCategoryFilters,
                planningAndControllingClassificationFilters,
                fundingStatusFilters,
                projectStatusTypeFilters,
                primaryDepartmentFilters,
                mainFlagFilters,
                prioritizationCategoryFilters,
                lumpSumFilters,
                miniFdStatusFilters: [],
                requestTypeFilters: [],
                focusTopicFilters: this.selectedFocusTopicFilters,
                isReadyForApproval: this.selectedIsReadyForApproval
            }
        };

        try {
            if (searchParams.plantIds.length === 0) {
                params.success({ rowData: [], rowCount: 0 });
                return;
            }

            const budgetRequests = await this.budgetRequestService.getBudgetRequest(searchParams);
            params.success({ rowData: budgetRequests.budgetRequests, rowCount: Number(budgetRequests.total) });

            if (Number(budgetRequests.total) === 0) {
                params.api.showNoRowsOverlay();
            }
        } catch (e) {
            params.fail()
        }
    }

    private onGridReady(params: AgGridCommon<any, any>): void {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;

        this.applyColumnState();
        this.applyFilterState();
    }
    
    private applyColumnState(): void {
        const columnState = JSON.parse(localStorage.getItem(ACCORDION_BUDGET_REQUESTS_TABLE_COLUMN_STATE));
        if (columnState) {
            this.columnApi.applyColumnState({ state: columnState, applyOrder: true });
        }
    }

    private setColumnState(): void {
        const columnState = JSON.stringify(this.columnApi.getColumnState());
        localStorage.setItem(ACCORDION_BUDGET_REQUESTS_TABLE_COLUMN_STATE, columnState);
    }

    private applyFilterState(): void {
        const filterState = JSON.parse(localStorage.getItem(ACCORDION_BUDGET_REQUESTS_TABLE_FILTER_STATE));
        if (filterState) {
            this.gridApi.setFilterModel(filterState);
            this.refreshTable();
            this.calcIsFilterApplied();
        }
    }

    private setFilterState(): void {
        const filterState = JSON.stringify(this.gridApi.getFilterModel());
        localStorage.setItem(ACCORDION_BUDGET_REQUESTS_TABLE_FILTER_STATE, filterState);
        this.calcIsFilterApplied();
    }

    private calcIsFilterApplied(): void {
        const filterState = this.gridApi?.getFilterModel();
        const filterStateLength = Object.keys(filterState).length;
        this.isFilterApplied = filterStateLength > 0 || this.selectedIsReadyForApproval;
    }

    private resetFilterState(): void {
        this.selectedIsReadyForApproval = false;
        this.gridApi?.setFilterModel(null);
        this.refreshTable();
        this.calcIsFilterApplied();
    }

    private refreshTable(): void {
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private sizeColumnsToFit(): void {
        this.gridApi?.sizeColumnsToFit();
    }

    private onTriggerSearch(searchTerm: string): void {
        this.tableExtraFilters.searchTerm = searchTerm;
        this.refreshTable();
    }
    
    private onClearSearchTerm(): void {
        this.onTriggerSearch('');
    }

    private onPageSizeChanged(pageSize: number): void {
        this.tableExtraFilters.pageSize = pageSize;
        this.gridApi?.paginationSetPageSize(this.tableExtraFilters.pageSize);
        this.gridApi?.setCacheBlockSize(this.tableExtraFilters.pageSize);
    }

    private pageNumber(endRow: number | undefined): number {
        return Math.floor((endRow ?? this.tableExtraFilters.pageSize) / this.tableExtraFilters.pageSize)
    }

    private getYesNoFilter(options: string[]): boolean[] {
        if (!options || !options.length || options.length === 2) {
            return [];
        }
        return [options[0] === 'yes'];
    }

    private getSelectedFiltersForServerRequestParams(params: IServerSideGetRowsParams): ProjectPortfolioSetFilters {
        return {
            mtpStatusFilters: params.request.filterModel['currentMtpPlanning.mtpStatus']?.values?.map((e: string) => Number(e)) ?? [],
            projectTypeFilters: params.request.filterModel['projectType']?.values?.map((e: string) => Number(e)) ?? [],
            aeroClassificationFilters: params.request.filterModel['currentMtpPlanning.aeroClassification']?.values?.map((e: string) => Number(e)) ?? [],
            maintenanceTypeFilters: params.request.filterModel['currentMtpPlanning.maintenanceType']?.values?.map((e: string) => Number(e)) ?? [],
            outageTypeFilters: params.request.filterModel['currentMtpPlanning.outageType']?.values?.map((e: string) => Number(e)) ?? [],
            procurementCategoryFilters: params.request.filterModel['currentMtpPlanning.procurementCategory']?.values?.map((e: string) => Number(e)) ?? [],
            planningAndControllingClassificationFilters: params.request.filterModel['currentMtpPlanning.planningAndControllingClassification']?.values?.map((e: string) => Number(e)) ?? [],
            fundingStatusFilters: params.request.filterModel['currentMtpPlanning.fundingStatus']?.values?.map((e: string) => Number(e)) ?? [],
            projectStatusTypeFilters: params.request.filterModel['currentMtpPlanning.projectStatus']?.values?.map((e: string) => Number(e)) ?? [],
            primaryDepartmentFilters: params.request.filterModel['currentMtpPlanning.primaryDepartment']?.values?.map((e: string) => Number(e)) ?? [],
            mainFlagFilters: params.request.filterModel['currentMtpPlanning.mainFlag']?.values?.map((e: string) => Number(e)) ?? [],
            prioritizationCategoryFilters: params.request.filterModel['currentMtpPlanning.prioritizationCategory']?.values?.map((e: string) => Number(e)) ?? [],
        };
    }

    private onSelectionChanged(event: SelectionChangedEvent): void {
        event.api.forEachNode(node => {
            if (!node.data) {
                return;
            }

            if (node.isSelected()) {
                if (!this.itemsChecked.find(e => e.id === node.data?.id)) {
                    this.itemsChecked.push(this.itemsCheckedBr(node.data.globalId, node.data.currentMtpPlanning.mtpStatus, node.data.currentMtpPlanning.year, node.data.name, node.data.currentMtpPlanning, node.data.id, node.data.created));
                }
            } else {
                this.itemsChecked = this.itemsChecked.filter(e => e.id !== node.data?.id);
            }
        });
    }

    private onModelUpdated(event: ModelUpdatedEvent): void {
        event.api.forEachNode(node => {
            if (!node.data) {
                return;
            }

            if (this.itemsChecked.find(e => e.id === node.data?.id)) {
                node.setSelected(true, false, true);
            } else {
                node.setSelected(false, false, true);
            }
        });
    }

    private get mtpStatusOptionsList(): SelectOption[] {
        return Object.entries(this.data.constants.mtpStatus).map(([keyMtpStatus, value]) => ({
            label: value.name,
            value: keyMtpStatus,
            disabled: this.itemsChecked.length !== 1 ? ((this.checkStatuses && this.checkReviewedStatuses && keyMtpStatus !== '1') || (!this.checkStatuses && !this.checkReviewedStatuses && keyMtpStatus !== '1' && keyMtpStatus !== '2') || (this.checkStatuses && !this.checkReviewedStatuses && keyMtpStatus !== '1' && keyMtpStatus !== '5')) : (this.itemsChecked[0].mtpStatus === 1 && keyMtpStatus !== '2') || (this.itemsChecked[0].mtpStatus === 2 && keyMtpStatus !== '1' && keyMtpStatus !== '3' && keyMtpStatus !== '4') || (this.itemsChecked[0].mtpStatus === 3 && keyMtpStatus !== '1' && keyMtpStatus !== '4') || (this.itemsChecked[0].mtpStatus === 4 && keyMtpStatus !== '1' && keyMtpStatus !== '3' && keyMtpStatus !== '5') || (this.itemsChecked[0].mtpStatus === 4 && keyMtpStatus === '5' && this.checkReviewedStatuses) || (this.itemsChecked[0].mtpStatus === 5 && keyMtpStatus !== '1' && keyMtpStatus !== '3' && keyMtpStatus !== '4'),
        }));
    }

    private constantsToOptionsList(object: Record<number | string, string>): SelectOption[] {
        return Object.entries(object).map(([key, value]) => ({
            label: value,
            value: key,
        }));
    }

    
    private globalIdContextMenu (params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
        if (params.column.getColId() !== 'globalId') {
            return [];
        }

        const el = params.node.data;
        const globalIdContextMenu: MenuItemDef[] = [];

        if (this.userPermissions.includes('BudgetRequestDetailsView')) {
            globalIdContextMenu.push({
                name: 'Open in New Tab',
                action: () => this.emitDetails(el.currentMtpPlanning.year, el.globalId),
            });
        }

        if (((this.userPermissions.includes('BudgetRequestDetailsEdit') && this.isMtpPeriod) || this.userPermissions.includes('BudgetRequestDetailsEditAnytime')) && this.isCurrentMtp) {
            globalIdContextMenu.push({
                name: 'Edit Details',
                action: () => this.emitEdit(el.currentMtpPlanning.year, el.globalId, el.mtpList),
                disabled: el.currentMtpPlanning.mtpStatus !== 1,
            });
        }

        if ((el.currentMtpPlanning.mtpStatus !== 1 && el.currentMtpPlanning.mtpStatus !== 2) && (((this.userPermissions.includes('BudgetRequestDetailsEdit') || this.userPermissions.includes('BudgetRequestReviewEdit')) && this.isMtpPeriod) || this.userPermissions.includes('BudgetRequestDetailsEditAnytime')) && this.isCurrentMtp) {
            globalIdContextMenu.push({
                name: 'Edit Supporting Information',
                action: () => this.editControllingInformation(el.id, el.globalId, el.currentMtpPlanning.year, el.currentMtpPlanning.mtpStatus, el.currentMtpPlanning.wbsElement, el.currentMtpPlanning.comment, el.currentMtpPlanning.planningAndControllingClassification, el.currentMtpPlanning.outageType, el.currentMtpPlanning.outageId, el.currentMtpPlanning.startDate, el.currentMtpPlanning.hasPlanningCosts),
            });
        }

        if ((this.userPermissions.includes('BudgetRequestDetailsEdit') && this.isMtpPeriod) || this.userPermissions.includes('BudgetRequestDetailsEditAnytime')) {
            globalIdContextMenu.push({
                name: 'Duplicate Budget Request',
                action: () => this.emitDuplicate(el.currentMtpPlanning.year, el.globalId, el.mtpList, el.currentMtpPlanning.miniFdYear),
            });
        }

        if ((this.mtpYear !== this.currentYear && ((this.userPermissions.includes('BudgetRequestDetailsEdit') && this.isMtpPeriod) || this.userPermissions.includes('BudgetRequestDetailsEditAnytime')))) {
            globalIdContextMenu.push({
                name: 'Add to current MTP',
                action: () => this.emitEdit(el.currentMtpPlanning.year, el.globalId, el.mtpList, el.currentMtpPlanning.miniFdYear),
            });
        }

        if (this.isCurrentMtp && ((this.userPermissions.includes('BudgetRequestDetailsEdit') && this.isMtpPeriod) || this.userPermissions.includes('BudgetRequestReviewEdit') || this.userPermissions.includes('BudgetRequestDetailsEditAnytime'))) {
            const subMenu: MenuItemDef[] = [];

            for (const [keyMtpStatus, mtpStatus] of Object.entries(this.data.constants.mtpStatus)) {
                if (
                    ((this.userPermissions.includes('BudgetRequestReviewEdit') &&
                    (keyMtpStatus === '3' || keyMtpStatus === '4')) ||
                    (((this.userPermissions.includes('BudgetRequestDetailsEdit') && this.isMtpPeriod) ||
                        this.userPermissions.includes('BudgetRequestDetailsEditAnytime')) && (keyMtpStatus === '1' || keyMtpStatus === '2')) ||
                    (this.userPermissions.includes('BudgetRequestApproveEdit') && (keyMtpStatus === '5')))
                ) {
                    subMenu.push({
                        name: mtpStatus.name,
                        disabled: this.disableUpdateMtpBySelected(Number(keyMtpStatus), el.currentMtpPlanning.mtpStatus, el.currentMtpPlanning.new1),
                        action: () => this.changeMtpFromDropDown(keyMtpStatus, el.id, el.currentMtpPlanning, el.globalId),
                        checked: parseInt(el.currentMtpPlanning.mtpStatus) === parseInt(keyMtpStatus),
                    });
                }
            }
            
            globalIdContextMenu.push({
                name: 'Update MTP Status',
                subMenu, 
            });
        }

        if (this.isCurrentMtp && this.userPermissions.includes('BudgetRequestReviewEdit')) {
            const subMenu: MenuItemDef[] = [];

            for (const [fundingStatusKey, fundingStatus] of Object.entries(this.data.constants.fundingStatus)) {
                subMenu.push({
                    name: fundingStatus,
                    action: () => this.changeFundingStatus(fundingStatusKey, el.globalId, el.currentMtpPlanning.fundingStatus, el.currentMtpPlanning.year),
                    checked: parseInt(el.currentMtpPlanning.fundingStatus) === parseInt(fundingStatusKey),
                });
            }
            
            globalIdContextMenu.push({
                name: 'Update Funding Status',
                subMenu, 
            });
        }

        if (this.userPermissions.includes('MtpSiteDocumentsView')) {
            globalIdContextMenu.push({
                name: 'Export to Excel',
                action: () => this.exportToExcel(el.currentMtpPlanning.year, el.globalId, el.currentMtpPlanning.miniFdYear),
            });
        }

        if (this.isActive && this.mtpYear === this.currentYear && ((this.userPermissions.includes('BudgetRequestDetailsEdit') && this.isMtpPeriod) || this.userPermissions.includes('BudgetRequestDetailsEditAnytime'))) {
            globalIdContextMenu.push({
                name: 'Remove from MTP',
                action: () => this.openModalRemoveFromMtp(el.name, el.globalId, el.currentMtpPlanning.mtpStatus, null, el.created),
            });
        }

        if (!this.isActive && this.checkCreatedDate(el.created) && ((this.userPermissions.includes('BudgetRequestDetailsEdit') && this.isMtpPeriod) || this.userPermissions.includes('BudgetRequestDetailsEditAnytime'))) {
            globalIdContextMenu.push({
                name: 'Delete',
                action: () => this.deleteBudgetRequest(el.currentMtpPlanning.mtpStatus, el.globalId),
            });
        }

        if (el.currentMtpPlanning.year === this.currentYear && parseInt(this.mtpYear) === 0 && parseInt(this.miniFdYear) === 0 && this.userPermissions.includes('BudgetRequestDetailsEdit')) {
            globalIdContextMenu.push({
                name: 'Delete Inactive MTP',
                action: () => this.deleteInactiveMTP(el.id),
            });
        }
        
        return globalIdContextMenu;
    }

    private get isEverySelectedItemInCurrentYear(): boolean {
        return this.itemsChecked.every(i => i.year === 2023);
    }

    private async deleteInactiveMTP(id: string): Promise<void> {
        try {
            await this.MtpService.deleteInactiveMtp([id]);
            this.itemsChecked = [];
            this.refreshTable();
            this.$pui.toast({
                type: 'success',
                title: 'Success',
                copy: 'Selected inactive MTP deleted.'
            });
        } catch {
            this.$pui.toast({
                type: 'error',
                title: 'Error',
                copy: 'Failed to delete selected inactive MTP.'
            });
        }
    }

    private async bulkDeleteInactiveMTP(): Promise<void> {
        const selectedIds: string[] = this.itemsChecked.map(item => item.id);
        if (selectedIds.length === 0) {
            return;
        }

        try {
            await this.MtpService.deleteInactiveMtp(selectedIds);
            this.itemsChecked = [];
            this.refreshTable();
            this.$pui.toast({
                type: 'success',
                title: 'Success',
                copy: 'Selected inactive MTPs deleted.'
            });
        } catch {
            this.$pui.toast({
                type: 'error',
                title: 'Error',
                copy: 'Failed to delete selected inactive MTPs.'
            });
        }
    }
}
