var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "navbar-user-actions"
  }, [_c('div', {
    staticClass: "user-name pl-2 pr-2"
  }, [_c('strong', [_vm._v(_vm._s(_vm.user !== null ? _vm.user.name : ''))])]), _vm.userPermissions && (_vm.userPermissions.includes('BulkMoveBudgetRequestsToCurrentMtp') || _vm.userPermissions.includes('BulkDeleteInactiveBudgetRequests')) ? _c('router-link', {
    staticClass: "dropdown-item pl-2 pr-2",
    attrs: {
      "to": "/admin"
    }
  }, [_vm._v(" Admin Options ")]) : _vm._e(), _vm.userPermissions && _vm.userPermissions.includes('MtpPeriodsEdit') ? _c('router-link', {
    staticClass: "dropdown-item pl-2 pr-2",
    attrs: {
      "to": "/rates-dashboard"
    }
  }, [_vm._v(" Rates Dashboard ")]) : _vm._e(), _vm.userPermissions && _vm.userPermissions.includes('MtpPeriodsEdit') ? _c('router-link', {
    staticClass: "dropdown-item pl-2 pr-2",
    attrs: {
      "to": "/mtp-periods"
    }
  }, [_vm._v(" MTP Periods ")]) : _vm._e(), _vm.userPermissions && _vm.userPermissions.includes('MtpPeriodsEdit') ? _c('router-link', {
    staticClass: "dropdown-item pl-2 pr-2",
    attrs: {
      "to": "/cr-periods"
    }
  }, [_vm._v(" CR Periods ")]) : _vm._e(), _vm.userPermissions && _vm.userPermissions.includes('FocusTopicEdit') ? _c('router-link', {
    staticClass: "dropdown-item pl-2 pr-2",
    attrs: {
      "to": "/focus-topics"
    }
  }, [_vm._v(" Focus Topics ")]) : _vm._e(), _c('button', {
    staticClass: "dropdown-item pl-2 pr-2",
    on: {
      "click": function ($event) {
        return _vm.sendToFeedbackForm();
      }
    }
  }, [_vm._v(" Send Feedback ")]), _vm.userPermissions && _vm.userPermissions.includes('MtpPeriodsEdit') ? _c('button', {
    staticClass: "dropdown-item pl-2 pr-2",
    on: {
      "click": function ($event) {
        return _vm.sendToIncidentReporting();
      }
    }
  }, [_vm._v(" Report an Incident ")]) : _vm._e(), _c('a', {
    staticClass: "dropdown-item pl-2 pr-2",
    attrs: {
      "href": "javascript:void(0)"
    },
    on: {
      "click": function ($event) {
        return _vm.logout();
      }
    }
  }, [_vm._v(" Logout ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }