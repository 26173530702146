import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component({})
export default class ErrorModalComponent extends Vue {

    @Prop()
    private errorImport: any[] = null;

    constructor() {
        super();
    }

    mounted() {

    }

    @Watch('errorImport')
    async onSelectedPlantChanged(val: any[]) {
        if (val != null) {
            this.errorImport = val;
        }
    }
}
