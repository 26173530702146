import { Connection } from '~/utils/';
import {
    Currency,
    GetCurrencyPayload,
    GetPowerPlantsResponse,
    PlantAttributesItem,
    PlantItem
} from '~/models/services/plants';
import { ApiResponse } from '~/models/services/common';
import { History, HistorySearchModel } from '~/utils/interfaces';
import { CacheContainer } from '~/utils/cache-container'

export class PlantsService {
    private readonly api = new Connection();
    private readonly pingApi = new Connection();

    private readonly plantAttributesCache = new CacheContainer<string | number, PlantAttributesItem>()
    private readonly powerPlantsCache = new CacheContainer<object, PlantItem[]>()
    private readonly currencyCache = new CacheContainer<string, Currency>()

    public async getPlants(id: string | number): Promise<PlantAttributesItem> {
        if (this.plantAttributesCache.hasCachedValue(id)) {
            return this.plantAttributesCache.getCachedValue(id);
        }

        const plantAttributesItem = (await this.api.get<ApiResponse<PlantAttributesItem>>('plant/' + id)).data.result;
        this.plantAttributesCache.storeCachedValue(id, plantAttributesItem);

        return plantAttributesItem;
    }

    public async getMasterDataPlants(): Promise<PlantItem[]> {
        return (await this.pingApi.get<ApiResponse<GetPowerPlantsResponse>>('master-data/plant/list')).data.result.plants;
    }

    public async getPowerPlants(assetGroupIds?: string[] | number[], fleetIds?: string[] | number[]): Promise<PlantItem[]> {
        const cacheKey = { assetGroupIds, fleetIds };

        if (this.powerPlantsCache.hasCachedValue(cacheKey)) {
            return this.powerPlantsCache.getCachedValue(cacheKey);
        }

        const querystring = require('querystring');
        const query = {
            params: {
                ...(assetGroupIds && assetGroupIds.length > 0) && { assetGroupIds: assetGroupIds },
                ...(fleetIds && fleetIds.length > 0) && { fleetSid: fleetIds },
            },
            paramsSerializer: (params: Record<string, string>): string => {
                return querystring.stringify(params);
            },
        };

        const plantItems = (await this.pingApi.get<ApiResponse<GetPowerPlantsResponse>>('master-data/plant/list', query)).data.result.plants;
        this.powerPlantsCache.storeCachedValue(cacheKey, plantItems);
        return plantItems;
    }

    public async GetPlantHistory(searchModel: HistorySearchModel): Promise<History> {
        return (await this.api.post<ApiResponse<History>>('plant/history', searchModel)).data.result;
    }

    public async getCurrency(payload: GetCurrencyPayload): Promise<Currency> {
        const cacheKey = payload.countryName

        if (this.currencyCache.hasCachedValue(cacheKey)) {
            return this.currencyCache.getCachedValue(cacheKey);
        }

        const currency = (await this.api.get<ApiResponse<Currency>>('currency/' + payload.countryName)).data.result;
        this.currencyCache.storeCachedValue(cacheKey, currency);

        return currency;
    }
}
