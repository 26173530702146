import { AssetGroupItem } from '~/models/services/asset-groups';
import { MasterService } from '~/services/master-service';
import { Permission } from '~/utils/permission';
import { LivePmlFilterEntry, MiniFdYearFilterEntry, MtpFilterEntry, MtpPeriod } from '~/utils/interfaces';
import { Application } from '~/models/services/app';
import { FleetItem } from '~/models/services/fleet';
import { PlantItem } from '~/models/services/plants';
import { ConstantsList } from "~/models/services/constants-list"
import { MtpService } from '~/services/mtp-service';
import { MiniFdPeriod } from '~/models/services/cr-period';
import { CrService } from '~/services/cr-service';

type ViewAddDataCurrentMtp = {
    year: number;
    globalId: string;
};

type FleetSelectorOptions = {
    id: string;
    text: string;
    options: FleetSelectorOptions[];
};

export class Data {
    public MtpService: MtpService = new MtpService();
    public CrService: CrService = new CrService();

    private static instance: Data;
    public plants?: PlantItem[] = null;
    public fleet?: FleetItem[] = null;
    public selectedTechnology?: FleetItem = null;
    public selectedPlantGroup?: AssetGroupItem = null;
    public selectedPlant?: PlantItem[] = null;
    public constants?: ConstantsList = null;
    public navBarRefresh: boolean = false;
    public changedMtpLoading: boolean = false;
    public switchingEditMode: boolean = false;
    public loadingSaveBudgetRequest: boolean = false;
    public removeFromMtp: boolean = false;
    public deleteBr: boolean = false;
    public exportBr: boolean = false;
    public addToCurrentMtp: boolean = false;
    public switchPageView: boolean = false;
    public uploadingSpreadSheet: boolean = false;
    public addFile: boolean = false;
    public deleteFile: boolean = false;
    public downloadFile: boolean = false;
    public exportPrioritization: boolean = false;
    public selectedOneMtpUpdateStatus?: string = null;
    public viewDataAddCurrentMtp?: ViewAddDataCurrentMtp = null;
    public updatedFundingStatus?: string = null;
    public updatedProjectStatus?: string = null;
    public isAssetDataAvailable = true;
    public userPermissions: Permission[] = [];
    public coodeApplications: Application[] = [];
    public loadedFleets = new Map<string, FleetItem>();
    public loadedAssetGroups = new Map<string, AssetGroupItem>();
    public loadedFleetStructure: FleetSelectorOptions[] = [];
    public loadedPlants: PlantItem[] = [];
    public hasNavigationStructureError = false;
    private readonly SELECTED_PLANTS_SIDS = 'selectedPlantsSids';
    private hasPulledNavigationStructureFromServer = false;
    private fleetNameSidMap: Record<string, string | number> = {};

    public currentMtpPeriod: MtpPeriod | null = null;
    public currentCrPeriod: MiniFdPeriod | null = null;

    public static get Instance(): Data {
        return this.instance || (this.instance = new this());
    }

    // Constants

    get sortedMtpFilters(): MtpFilterEntry[] {
        if (!this.constants.mtpFilters) {
            return [];
        }

        return this.constants.mtpFilters.slice().sort((a, b) => {
            // Exception for "All Inactive Budget Requests" entry
            if (a.year === 0 && a.miniFdYear === 0 || b.year === 0 && b.miniFdYear === 0) {
                return 1;
            }

            return b.description.localeCompare(a.description);
        });
    }

    get sortedReportingMtpFilters(): MtpFilterEntry[] {
        if (!this.constants.mtpReportingFilters) {
            return [];
        }

        return this.constants.mtpReportingFilters.slice().sort((a, b) => {
            // Exception for "All Inactive" entries
            if (a.year === 0 && a.miniFdYear === 0 || b.year === 0 && b.miniFdYear === 0) {
                return 1;
            }

            if (a.year === 0 && a.miniFdYear === 1 || b.year === 0 && b.miniFdYear === 1) {
                return 1;
            }

            return b.description.localeCompare(a.description);
        });
    }

    get sortedLivePmlFilters(): LivePmlFilterEntry[] {
        if (!this.constants.livePmlFilters) {
            return [];
        }

        return this.constants.livePmlFilters.slice().sort((a, b) => b.description.localeCompare(a.description));
    }

    // Mtp Filters

    get sortedMiniFdYearFilters(): MiniFdYearFilterEntry[] {
        if (!this.constants.miniFdYearFilters) {
            return [];
        }

        return this.constants.miniFdYearFilters.slice().sort((a, b) => b.description.localeCompare(a.description));
    }

    async loadConstantsFromServer(): Promise<void> {
        this.navBarRefresh = true;
        this.constants = await MasterService.instance.constantsListService.getAll();
        this.navBarRefresh = false;
    }

    // Fleet Selector

    async retryLoadFleetHierarchyFromServer(): Promise<void> {
        this.hasPulledNavigationStructureFromServer = false;
        this.hasNavigationStructureError = false;

        await this.loadFleetHierarchyFromServer();
    }

    async loadFleetHierarchyFromServer(): Promise<void> {
        if (this.hasPulledNavigationStructureFromServer) {
            return;
        }

        try {
            this.navBarRefresh = true;

            this.fleet = await MasterService.instance.fleetService.getFleet();
            this.fleet.forEach(fleetItem => {
                this.loadedFleets.set(fleetItem.sid.toString(), fleetItem);
                this.fleetNameSidMap[fleetItem.name] = fleetItem.sid;
            });

            const assetGroupData = await MasterService.instance.assetGroupsService.getAssetGroups(Object.values(this.fleetNameSidMap));
            const assetGroupOptions: { [key: string]: FleetSelectorOptions[] } = {};

            assetGroupData.forEach(assetGroup => {
                this.loadedAssetGroups.set(assetGroup.sid.toString(), assetGroup);
                const fleetSid = this.fleetNameSidMap[assetGroup.fleetName];

                if (assetGroupOptions[fleetSid]) {
                    assetGroupOptions[fleetSid].push({
                        text: assetGroup.name,
                        id: `${fleetSid};${assetGroup.sid}`,
                        options: [],
                    });
                } else {
                    assetGroupOptions[fleetSid] = [{
                        text: assetGroup.name,
                        id: `${fleetSid};${assetGroup.sid}`,
                        options: [],
                    }];
                }
            });

            this.loadedFleetStructure = this.fleet
                .map(fleetItem => {
                    const fleetAssetGroupOptions = assetGroupOptions[fleetItem.sid] ?? [];

                    return {
                        text: fleetItem.name,
                        id: fleetItem.sid.toString(),
                        options: fleetAssetGroupOptions,
                    };
                })
                .filter(fleetItem => fleetItem.options.length !== 0);

            this.hasPulledNavigationStructureFromServer = true;
        } catch {
            this.hasNavigationStructureError = true;
        } finally {
            this.navBarRefresh = false;
        }
    }

    async loadPlantsByFleetAndAssetGroupSid(
        fleetSid: string,
        assetGroupSid: string
    ): Promise<void> {
        this.navBarRefresh = true;

        this.selectedTechnology = this.loadedFleets.get(fleetSid);
        this.selectedPlantGroup = this.loadedAssetGroups.get(assetGroupSid);
        const plants = await MasterService.instance.plantsService.getPowerPlants(
            [assetGroupSid],
            [fleetSid]
        );

        if (localStorage.getItem(this.SELECTED_PLANTS_SIDS)) {
            const existingPlants = plants.filter((plant) => JSON.parse(localStorage.getItem(this.SELECTED_PLANTS_SIDS)).includes(plant.plantSid));
            this.selectedPlant = existingPlants.length ? existingPlants : plants;
            localStorage.setItem(
                this.SELECTED_PLANTS_SIDS,
                JSON.stringify(this.selectedPlant.map((plant) => plant.plantSid))
            );
        } else {
            this.selectedPlant = plants;
        }
        this.loadedPlants = plants;
        this.plants = this.loadedPlants;

        this.hasPulledNavigationStructureFromServer = true;
        this.navBarRefresh = false;
    }

    saveSelectedPlantsById(plantSids: string[]): void {
        const plants = [];

        plantSids.forEach((plantSid) => {
            const plant = this.loadedPlants.find(
                (v) => v.plantSid === parseInt(plantSid)
            );
            plant && plants.push(plant);
        });

        this.selectedPlant = plants;
        let selectedPlantsSids = [];
        for (let plant in this.selectedPlant) {
            selectedPlantsSids.push(this.selectedPlant[plant].plantSid);
        }
        localStorage.setItem(
            this.SELECTED_PLANTS_SIDS,
            JSON.stringify(selectedPlantsSids)
        );
    }

    private clearSelectedFleet(): void {
        this.selectedPlant = [];
        this.selectedTechnology = null;
        this.selectedPlantGroup = null;
    }

    // MTP Periods

    async loadMtpPeriods(): Promise<void> {
        this.navBarRefresh = true;
        const periods = await this.MtpService.getMtpPeriods();
        this.currentMtpPeriod = this.getCurrentActiveMtpPeriod(periods);
        this.navBarRefresh = false;
    }

    getCurrentActiveMtpPeriod(periods: MtpPeriod[]): MtpPeriod | null {
        const currentDate = new Date();

        const activePeriods = periods.filter(
            (range) => new Date(range.mtpStartDate) <= currentDate && new Date(range.mtpEndDate) >= currentDate
        );
        
        const sortedRanges = activePeriods.length > 0
            ? activePeriods.sort((a, b) => new Date(b.mtpEndDate).getTime() - new Date(a.mtpEndDate).getTime())
            : periods.filter((range) => new Date(range.mtpEndDate) < currentDate)
                .sort((a, b) => new Date(b.mtpEndDate).getTime() - new Date(a.mtpEndDate).getTime());
        
        return sortedRanges.length > 0 ? sortedRanges[0] : null;
    }

    // CR Periods

    async loadCrPeriods(): Promise<void> {
        this.navBarRefresh = true;
        const periods = await this.CrService.getCrPeriods();
        this.currentCrPeriod = this.getCurrentActiveCrPeriod(periods);
        this.navBarRefresh = false;
    }

    getCurrentActiveCrPeriod(periods: MiniFdPeriod[]): MiniFdPeriod | null {
        const currentDate = new Date();

        const activePeriods = periods.filter(
            (range) => new Date(range.startDate) <= currentDate && new Date(range.endDate) >= currentDate
        );
        
        const sortedRanges = activePeriods.length > 0
            ? activePeriods.sort((a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime())
            : periods.filter((range) => new Date(range.endDate) < currentDate)
                .sort((a, b) => new Date(b.endDate).getTime() - new Date(a.endDate).getTime());
        
        return sortedRanges.length > 0 ? sortedRanges[0] : null;
    }
}
