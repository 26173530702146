var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal fade show",
    attrs: {
      "id": "bulkApproveModal",
      "aria-hidden": "true",
      "aria-labelledby": "exampleModalLabel",
      "data-backdrop": "static",
      "role": "dialog",
      "tabindex": "-1"
    }
  }, [_c('div', {
    staticClass: "modal-dialog",
    attrs: {
      "role": "document"
    }
  }, [_c('div', {
    staticClass: "modal-content"
  }, [_vm._m(0), _vm._m(1), _c('div', {
    staticClass: "modal-footer pt-2"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary",
    attrs: {
      "data-dismiss": "modal",
      "type": "button"
    }
  }, [_vm._v(" Cancel ")]), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "data-dismiss": "modal",
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.approve();
      }
    }
  }, [_vm._v(" Approve ")])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal-header"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('h5', {
    staticClass: "modal-title",
    attrs: {
      "id": "exampleModalLabel"
    }
  }, [_vm._v(" Bulk approve ")])])])]), _c('button', {
    staticClass: "close",
    attrs: {
      "aria-label": "Close",
      "data-dismiss": "modal",
      "type": "button"
    }
  }, [_c('span', {
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("×")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 pb-3"
  }, [_c('p', [_vm._v(" This action will update all budget requests with status 'Reviewed' and with value in the next MTP year, to MTP status 'Approved'. ")]), _c('p', [_vm._v("Are you sure you want to proceed?")])])])])]);
}]

export { render, staticRenderFns }