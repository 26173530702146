var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "budget-request history"
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "can-cancel": true
    },
    on: {
      "update:active": function ($event) {
        _vm.loading = $event;
      }
    }
  }), _c('div', {
    staticClass: "row justify-content-start mb-3"
  }, [_c('div', {
    staticClass: "col-xl-10 offset-xl-1"
  }, [_c('div', {
    staticClass: "accordion-history mb-4",
    attrs: {
      "id": "accordion"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "budget-request-wrapper"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm.budgetRequestHistory ? _c('table', {
    staticClass: "table"
  }, [!_vm.loading ? _c('thead', [_c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Date & Time")]), _c('th', [_vm._v("User(KID)")]), _c('th', [_vm._v("Type")]), _c('th', [_vm._v("Description")]), _c('th', [_vm.budgetRequestHistory.activityTrackings !== null ? _c('button', {
    staticClass: "btn",
    class: _vm.budgetRequestHistory.total <= 1 ? '' : 'collapsed',
    attrs: {
      "id": "CollapseAllButton",
      "aria-controls": "collapse-all",
      "aria-expanded": "false",
      "data-toggle": "collapse"
    },
    on: {
      "click": function ($event) {
        return _vm.toggleAll();
      }
    }
  }, [_c('icon', {
    staticClass: "plus-icon",
    attrs: {
      "name": "plus"
    }
  }), _c('icon', {
    staticClass: "minus-icon",
    attrs: {
      "name": "minus"
    }
  })], 1) : _vm._e()])])]) : _vm._e(), !_vm.loading ? _c('tbody', _vm._l(_vm.budgetRequestHistory.activityTrackings, function (el, key) {
    return _c('tr', {
      key: key
    }, [_c('td', [_vm._v(_vm._s(_vm.firstElementTable + key))]), _c('td', [_vm._v(_vm._s(el.date))]), _c('td', [_vm._v(_vm._s(el.kId))]), _c('td', [_vm._v(_vm._s(el.activityType))]), _c('td', [_c('p', {
      staticClass: "collapse",
      class: key === 0 ? 'show' : '',
      attrs: {
        "id": 'collapse' + key,
        "aria-labelledby": 'description' + key
      },
      domProps: {
        "innerHTML": _vm._s(el.description)
      }
    })]), _c('td', [_c('button', {
      staticClass: "btn expand",
      class: key === 0 ? '' : 'collapsed',
      attrs: {
        "aria-controls": 'collapse' + key,
        "aria-expanded": key === 0 ? true : false,
        "data-target": '#collapse' + key,
        "data-toggle": "collapse"
      },
      on: {
        "click": function ($event) {
          return _vm.checkIcons(key);
        }
      }
    }, [_c('icon', {
      staticClass: "plus-icon",
      attrs: {
        "name": "plus"
      }
    }), _c('icon', {
      staticClass: "minus-icon",
      attrs: {
        "name": "minus"
      }
    })], 1)])]);
  }), 0) : _vm._e()]) : _vm._e(), _vm.budgetRequestHistory.activityTrackings === null ? _c('div', {
    staticClass: "table pl-3 pt-3 pb-3"
  }, [_vm._v(" No history entries found ")]) : _vm._e(), _vm.budgetRequestHistory.activityTrackings !== null ? _c('div', {
    staticClass: "mr-auto pl-3 pt-2"
  }, [_vm._v(" Show "), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.elementsPerPage,
      expression: "elementsPerPage"
    }],
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.elementsPerPage = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, function ($event) {
        return _vm.reloadTableChangeNumberElement();
      }]
    }
  }, [_c('option', {
    attrs: {
      "selected": ""
    }
  }, [_vm._v(" 10 ")]), _c('option', [_vm._v("15")]), _c('option', [_vm._v("20")]), _c('option', [_vm._v("30")])]), _c('div', {
    staticClass: "show-elements"
  }, [_vm._v(" Showing " + _vm._s(_vm.firstElementTable) + " to " + _vm._s(_vm.numberOfElementTable) + " of " + _vm._s(_vm.budgetRequestHistory.total) + " history entries ")])]) : _vm._e(), _vm.budgetRequestHistory.activityTrackings !== null ? _c('div', {
    staticClass: "pagination ml-auto pr-3 pt-2"
  }, [_vm.currentPage != 1 ? _c('div', {
    staticClass: "arrow mr-2",
    on: {
      "click": function ($event) {
        return _vm.changeNavPageBack();
      }
    }
  }, [_c('icon', {
    attrs: {
      "name": "chevron-left"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "number active",
    on: {
      "click": function ($event) {
        return _vm.changeNavPageBack();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.currentPage) + " ")]), Math.ceil(_vm.budgetRequestHistory.total / _vm.elementsPerPage) > _vm.currentPage ? _c('div', {
    staticClass: "number",
    on: {
      "click": function ($event) {
        return _vm.changeNavPageNext();
      }
    }
  }, [_vm._v(" | " + _vm._s(_vm.currentPage + 1) + " ")]) : _vm._e(), Math.ceil(_vm.budgetRequestHistory.total / _vm.elementsPerPage) > _vm.currentPage ? _c('div', {
    staticClass: "arrow ml-2",
    on: {
      "click": function ($event) {
        return _vm.changeNavPageNext();
      }
    }
  }, [_c('icon', {
    attrs: {
      "name": "chevron-right"
    }
  })], 1) : _vm._e()]) : _vm._e()])])])])])])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header",
    attrs: {
      "id": "heading_accordion_budget"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_c('button', {
    staticClass: "btn"
  }, [_vm._v(" History ")])])])]);
}]

export { render, staticRenderFns }