import { Connection } from '~/utils/';
import { ApiResponse } from '~/models/services/common';
import { CombinedData, CombinedBudgets, GetGlobalIdResponse, GlobalId } from '~/models/services/project-portfolio';

export class ProjectPortfolioService {
    private readonly api = new Connection();

    public async searchGlobalId(plantSid: string, mtpYear: number, term: string): Promise<GlobalId[]> {
        const params = new URLSearchParams();
        params.append('plantSid', plantSid.toString());
        params.append('mtpYear', mtpYear.toString());
        params.append('Term', term);

        return (await this.api.get<ApiResponse<GetGlobalIdResponse>>(`/combined-budget/get-ids-to-combine?${params.toString()}`)).data.result.globalIds;
    }

    public async getCombinedData(budgetIds: string[], mtpYear: number): Promise<any> {
        const params = new URLSearchParams();
        budgetIds.forEach(budgetId => {
            params.append('budgetIds', budgetId);
        });
        params.append('mtpYear', mtpYear.toString());

        return (await this.api.get<ApiResponse<CombinedData>>(`/combined-budget/get-combined-data?${params.toString()}`)).data.result.combinedData;
    }
    
    public async getCombinedBudgets(mtpYear: string, plantSids: number[], statuses: number[], searchTerm: string, pageNumber: number, pageSize: number): Promise<CombinedBudgets> {
        const params = new URLSearchParams();
        params.append('mtpYear', mtpYear.toString());
        params.append('pageNumber', pageNumber.toString());
        params.append('pageSize', pageSize.toString());
        params.append('filters.SearchTerm', searchTerm.toString());
        plantSids.forEach(plantSid => {
            params.append('plantSids', plantSid.toString());
        });
        statuses.forEach(status => {
            params.append('filters.Statuses', status.toString());
        });

        return (await this.api.get<ApiResponse<CombinedBudgets>>(`/combined-budget/list?${params.toString()}`)).data.result;
    }

    public async getCombinedBudget(id: string): Promise<any> {
        return (await this.api.get<ApiResponse<any>>(`/combined-budget/get/${id}`)).data.result;
    }

    public async addCombinedBudget(payload: Record<string, any>): Promise<any> {
        return (await this.api.post<ApiResponse<any>>('/combined-budget/add', payload)).data.result;
    }

    public async editCombinedBudget(payload: Record<string, any>): Promise<any> {
        return (await this.api.put<ApiResponse<any>>('/combined-budget/edit', payload)).data.result;
    }

    public async deleteCombinedBudget(id: string): Promise<any> {
        return (await this.api.delete(`/combined-budget/delete/${id}`)).data.result;
    }
}
