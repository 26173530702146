import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop, Watch } from 'vue-property-decorator';
import { ROWS } from '~/components/in-year-budget-requests/constants/rows';
import { MtpStatus, NewBudgetRequests } from '~/utils/interfaces';
import { cellValueClass, tableCellTextFormat } from '~/utils/table-helper';
import { Data } from '~/utils';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { zeroIfNaN } from '~/utils/math-helper';
import moment from 'moment';
import { formatToDecimals } from '~/utils/number-helper';
import { PuiTableVendorOptions } from '~/models/libraries/pebble-ui';

export type PopulateGlobalIdsCollapsible = {
    spentYearMinus1: number;
    spentYear: number;
    spentYearPlus1: number;
    spentYearPlus2: number;
    spentYearPlus3: number;
    spentAfterYear: number;
    globalId: string;
}[];

interface IybrGlobalIdsCollapsibleFormData {
    spentYearMinus1: string;
    spentYear: string;
    spentYearPlus1: string;
    spentYearPlus2: string;
    spentYearPlus3: string;
    spentAfterYear: string;
}

export interface IybrGlobalIdsCollapsibleTableData {
    [key: string]: {
        budgetData: IybrGlobalIdsCollapsibleFormData,
        budgetRequestData: NewBudgetRequests
    };
}

export enum ProjectTypes {
    'Project' = '1',
    'NonProject' = '2'
}

@Component({})
export default class IybrGlobalIdsCollapsibleComponent extends Vue {
    @Prop()
    currencyName!: string;
    @Prop()
    globalIds!: string[];
    @Prop()
    mtpYear!: number;
    @Prop()
    miniFdYear?: number;
    @Prop()
    projectType!: string;
    private readonly formLayout = ROWS;
    @Prop()
    private loadedBudgetRequests: NewBudgetRequests[];

    private formData: Record<string, IybrGlobalIdsCollapsibleFormData> = {};
    private currentTab = 0;

    private numberInputMask = createNumberMask({
        prefix: '',
        includeThousandsSeparator: false,
        allowDecimal: true,
        decimalLimit: 3,
        allowNegative: true
    });
    private cellValueClass = cellValueClass;
    private tableCellTextFormat = tableCellTextFormat;

    private get isMoreThanOneGlobalId(): boolean {
        return this.globalIds.length > 1;
    }

    private get columns(): Record<string, string> {
        return {
            'title': `Currency: ${this.currencyName}`,
            'MTP-1': `${this.mtpYear - 1}`,
            'MTP': `${this.mtpYear}`,
            'previousYears': `Before ${this.mtpYear + 1}`,
            'MTP+1': `${this.mtpYear + 1}`,
            'MTP+2': `${this.mtpYear + 2}`,
            'MTP+3': `${this.mtpYear + 3}`,
            'ff': 'FF',
            'total': 'Total'
        };
    }

    private get shownColumns(): string[] {
        const hiddenColumns = ['MTP-1', 'MTP'];

        if (this.projectType === ProjectTypes.NonProject) {
            hiddenColumns.push('previousYears');
        }

        return Object.keys(this.columns)
            .filter(key => !hiddenColumns.includes(key))
            .map(key => this.columns[key]);
    }

    private get formDataMapping(): Record<string, string> {
        return {
            [this.columns['previousYears']]: 'spentYear',
            [this.columns['MTP+1']]: 'spentYearPlus1',
            [this.columns['MTP+2']]: 'spentYearPlus2',
            [this.columns['MTP+3']]: 'spentYearPlus3',
            [this.columns['ff']]: 'spentAfterYear',
        };
    }

    private get tableOptions(): Record<string, PuiTableVendorOptions> {
        const options: Record<string, PuiTableVendorOptions> = {};

        Object.keys(this.formData).forEach(key => {
            const budgetRequestData = this.loadedBudgetRequests.find(e => e.globalId === key);

            if (budgetRequestData) {
                const mtpData = budgetRequestData.planning;

                let budgetInMtpStatus = '';

                switch (mtpData.mtpStatus) {
                    case MtpStatus.APPROVED:
                        budgetInMtpStatus = '(Approved)';
                        break;
                    case MtpStatus.FLAGGED:
                        budgetInMtpStatus = '(Flagged)';
                        break;
                }

                const previousYearsBudgetInMtpSum = mtpData ? mtpData.spendUntilEndOfYear + mtpData.forecast : 0;

                const budgetInMtpRow = {
                    [this.columns['title']]: `Budget in MTP ${budgetInMtpStatus}`,
                    [this.columns['MTP-1']]: mtpData?.spendUntilEndOfYear ?? 0,
                    [this.columns['MTP']]: mtpData?.forecast ?? 0,
                    [this.columns['previousYears']]: previousYearsBudgetInMtpSum,
                    [this.columns['MTP+1']]: mtpData?.new1 ?? 0,
                    [this.columns['MTP+2']]: mtpData?.new2 ?? 0,
                    [this.columns['MTP+3']]: mtpData?.new3 ?? 0,
                    [this.columns['ff']]: mtpData?.after ?? 0,
                    [this.columns['total']]: mtpData?.total ?? 0
                };

                const projectFields = ['spentYearMinus1', 'spentYear', 'spentAfterYear'];

                const totalCost = Object.values(this.formDataMapping)
                    .map(field => {
                        if (this.projectType === ProjectTypes.NonProject && projectFields.includes(field)) {
                            return 0;
                        }
                        return zeroIfNaN(parseFloat(this.formData[key][field]));
                    })
                    .reduce((previous, current) => previous + current);

                const totalBudgetRequired = {
                    [this.columns['title']]: 'Total Budget Required',
                    [this.columns['total']]: totalCost
                };

                options[key] = {
                    data: [
                        budgetInMtpRow,
                        totalBudgetRequired
                    ],
                    options: {
                        sortable: []
                    },
                    columns: Object.values(this.shownColumns)
                };
            }
        });

        return options;
    }

    public async populateGlobalIdData(data: PopulateGlobalIdsCollapsible): Promise<void> {
        for (const entry of data) {
            const budgetRequestData = this.loadedBudgetRequests.find(e => e.globalId === entry.globalId);

            if (budgetRequestData) {
                this.$set(this.loadedBudgetRequests, entry.globalId, budgetRequestData);
                this.$set(this.formData, entry.globalId, {
                    spentYearMinus1: '',
                    spentYear: formatToDecimals(entry.spentYearMinus1 + entry.spentYear, 3).toString(),
                    spentYearPlus1: formatToDecimals(entry.spentYearPlus1, 3).toString(),
                    spentYearPlus2: formatToDecimals(entry.spentYearPlus2, 3).toString(),
                    spentYearPlus3: formatToDecimals(entry.spentYearPlus3, 3).toString(),
                    spentAfterYear: formatToDecimals(entry.spentAfterYear, 3).toString()
                });
            }
        }
    }

    @Watch('globalIds')
    private onGlobalIdsChange(): void {
        this.loadGlobalIds();
    }

    @Watch('mtpYear')
    private onMtpYearChange(): void {
        this.loadGlobalIds();
    }

    @Watch('loadedBudgetRequests')
    private onLoadedBudgetRequestsChange(): void {
        this.loadGlobalIds();
    }

    @Watch('formData', { deep: true })
    private onFormDataChange(): void {
        const response: IybrGlobalIdsCollapsibleTableData = {};

        Object.keys(this.formData).forEach(key => {
            const budgetRequestData = this.loadedBudgetRequests.find(e => e.globalId === key);

            response[key] = {
                budgetData: this.formData[key],
                budgetRequestData
            };
        });

        this.$emit('changed:table-data', response);
    }

    private loadGlobalIds(): void {
        if (!this.mtpYear || isNaN(this.mtpYear)) {
            return;
        }

        for (const globalId of this.globalIds) {
            const budgetRequestData = this.loadedBudgetRequests.find(e => e.globalId === globalId);

            if (budgetRequestData && !this.formData[globalId]) {
                this.$set(this.formData, globalId, {
                    spentYearMinus1: '',
                    spentYear: '',
                    spentYearPlus1: '',
                    spentYearPlus2: '',
                    spentYearPlus3: '',
                    spentAfterYear: ''
                });
            }
        }
    }

    private getLoadedBudgetRequest(globalId: string): NewBudgetRequests {
        return this.loadedBudgetRequests.find(e => e.globalId === globalId);
    }

    private shouldDisplayInputField(data): boolean {
        const nonInputColumns = [this.columns.title, this.columns.total];
        return data.index === 2 && !nonInputColumns.some(e => data.column === e);
    }

    private handleTabChange(newTabIndex: number): void {
        this.currentTab = newTabIndex;
    }

    private getValueForConstant(constantType: string, index?: string | number): string {
        if (!index) {
            return 'N/A';
        }

        const constantValue = Data.Instance.constants[constantType][index];

        if (!constantValue) {
            return 'Unknown';
        }

        return constantValue;
    }

    private formatDate(date: string): string {
        if (date) {
            return moment(date).format('YYYY-MM-DD');
        } else {
            return '';
        }
    }
}
