var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "change-request-history"
  }, [_vm.tableEntryRows.length > 0 ? [_c('pui-table', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.shouldShowTable,
      expression: "shouldShowTable"
    }],
    ref: "history-table",
    staticClass: "change-request-history-table",
    attrs: {
      "vendor-options": _vm.tableOptions
    },
    scopedSlots: _vm._u([{
      key: _vm.columns.description,
      fn: function (data) {
        return [_c('pui-collapsible', {
          staticClass: "history-description-collapsible",
          attrs: {
            "expandable": true,
            "open": data.index === 1,
            "visible": true
          }
        }, [_c('span', {
          domProps: {
            "innerHTML": _vm._s(data.row[_vm.columns.description])
          }
        })])];
      }
    }], null, true)
  }), _c('pui-loader-spinner', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isLoadingNewInformation,
      expression: "isLoadingNewInformation"
    }],
    staticClass: "loading-card",
    attrs: {
      "min-height": _vm.CONSTANT_SIZES.LOADER_MIN_HEIGHT,
      "size": _vm.CONSTANT_SIZES.LOADER_CONTENT_SIZE,
      "message": "Currently updating the table with new information...",
      "title": "Please wait!"
    }
  }), _c('pui-loader-error', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isLoadingNewInformation && _vm.showNoRecordsFoundCard,
      expression: "!isLoadingNewInformation && showNoRecordsFoundCard"
    }],
    staticClass: "empty-data-card",
    attrs: {
      "min-height": _vm.CONSTANT_SIZES.LOADER_MIN_HEIGHT,
      "size": _vm.CONSTANT_SIZES.LOADER_CONTENT_SIZE,
      "icon": "error-empty-data",
      "message": "Please try again with other search terms and/or filters.",
      "title": "No records found."
    }
  }), _c('table-attachment', {
    attrs: {
      "attachment-position": "bottom"
    }
  }, [_vm.tableEntryRows.length ? _c('table-pagination', {
    ref: "tablePagination",
    attrs: {
      "total-count": _vm.totalCount
    },
    on: {
      "change:page-number": _vm.handlePageNumberChange,
      "change:page-size": _vm.handlePageSizeChange
    }
  }) : _vm._e()], 1)] : [_c('div', {
    staticClass: "first-load-card"
  }, [_c('pui-loader-spinner', {
    attrs: {
      "min-height": _vm.CONSTANT_SIZES.LOADER_MIN_HEIGHT,
      "size": _vm.CONSTANT_SIZES.LOADER_CONTENT_SIZE,
      "message": "Currently loading the table...",
      "title": "Please wait!"
    }
  })], 1)]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }