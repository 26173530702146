import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import 'vue-awesome/icons/chevron-down';
import { ExtendedUserPermissions } from '~/utils/interfaces';
import { AddFocusTopicRequest, EditFocusTopicRequest, FocusTopic } from '~/models/services/focus-topics';
import { User } from '~/utils';
import { FocusTopicsService } from '~/services/focus-topics-service';

@Component({})
export default class AddNewFocusTopicComponent extends Vue {
    public FocusTopicService: FocusTopicsService = new FocusTopicsService();

    private saveDisabled = false;
    private isEdit = false;

    private current = {
        name: null,
        id: null,
    }

    private newFocusTopic = {
        name: '',
    };

    @Prop()
    private hasPermissionToEdit: boolean;

    @Prop()
    private editFT?: FocusTopic;

    private get isNameValid(): boolean {
        return this.newFocusTopic.name.trim() !== '';
    }

    private get isSaveDisabled(): boolean {
        return !this.isNameValid || this.saveDisabled;
    }

    private get editLabel(): string {
        return `Edit ${this.current.name}`;
    }

    @Watch('editFT')
    async onFocusTopicChanged() {
        if (!this.editFT) {
            return;
        }

        this.clearData();

        this.current = {
            name: this.editFT.name,
            id: this.editFT.id,
        }

        this.newFocusTopic = {
            name: this.editFT.name,
        }
    
        this.isEdit = true;

        this.$emit('update:editFT', null);
    }

    private async saveFocusTopic() {
        this.saveDisabled = true;

        try {
            if (this.current.id) {
                const newFocusTopic: EditFocusTopicRequest = {
                    focusTopics: [
                        {
                            id: this.current.id,
                            name: this.newFocusTopic.name,
                        },
                    ],
                };
                await this.FocusTopicService.editFocusTopic(newFocusTopic);
            } else {
                const newFocusTopic: AddFocusTopicRequest = {
                    focusTopics: [
                        {
                            name: this.newFocusTopic.name,
                        },
                    ],
                };
                await this.FocusTopicService.addFocusTopic(newFocusTopic);
            }

            this.handleSave();
        } catch (error) {
            if (error?.response?.data?.errors.length > 0) {
                this.$emit('errorChanged', error.response.data.errors[0]);
            } else {
                this.$emit('errorChanged', 'Focus Topic could not be saved.');
            }
        } finally {
            this.saveDisabled = false;
        }
    }

    private handleSave() {
        this.clearData();
        this.$emit('focusTopicSaved');
        this.$bvToast.show('toast-focus-topic-success');
    }

    public clearData(): void {  
        this.isEdit = false;
        this.current.id = null;
        this.newFocusTopic = {
            name: '',
        }
        this.$emit('errorChanged', '');
    }
}
