var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.data.navBarRefresh == false && _vm.data.selectedPlant && _vm.isActive ? _c('div', {
    staticClass: "pt-4"
  }, [_c('loading', {
    attrs: {
      "active": _vm.data.navBarRefresh,
      "can-cancel": true
    },
    on: {
      "update:active": function ($event) {
        return _vm.$set(_vm.data, "navBarRefresh", $event);
      }
    }
  }), _c('add-files-modal', {
    attrs: {
      "data": _vm.data,
      "user-permissions": _vm.userPermissions
    },
    on: {
      "addfile": _vm.addFile
    }
  }), _c('delete-file-modal', {
    attrs: {
      "file-name": _vm.fileName
    },
    on: {
      "deletefile": _vm.deleteFile
    }
  }), _c('b-toast', {
    attrs: {
      "id": "toast-error-import-file",
      "auto-hide-delay": "20000",
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center toast-error-import"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [_vm.errorMessage ? [_vm.errorMessage.isUploaded ? _c('div', {
    staticStyle: {
      "padding-right": "20px"
    }
  }, [_c('icon', {
    staticClass: "icon-toast text-center",
    attrs: {
      "name": "check"
    }
  })], 1) : _vm._e(), !_vm.errorMessage.isUploaded ? _c('div', {
    staticStyle: {
      "padding-right": "20px"
    }
  }, [_c('icon', {
    staticClass: "icon-toast text-center",
    staticStyle: {
      "color": "#F21100"
    },
    attrs: {
      "name": "times"
    }
  })], 1) : _vm._e()] : _vm._e(), _vm.errorMessage ? _c('div', [_vm.errorMessage.isUploaded ? _c('span', [_vm._v(_vm._s(_vm.fileName) + " was successfully uploaded")]) : _vm._e(), !_vm.errorMessage.isUploaded ? _c('span', [_vm._v(_vm._s(_vm.errorMessage))]) : _vm._e()]) : _vm._e(), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideToast();
      }
    }
  }, [_vm._v(" Dismiss ")])], 2)]), _c('b-toast', {
    attrs: {
      "id": "toast-error-delete-file",
      "auto-hide-delay": "20000",
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center toast-error-import"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [_vm.errorMessage ? [_vm.errorMessage.canDelete ? _c('div', {
    staticStyle: {
      "padding-right": "20px"
    }
  }, [_c('icon', {
    staticClass: "icon-toast text-center",
    attrs: {
      "name": "trash-alt"
    }
  })], 1) : _vm._e(), !_vm.errorMessage.canDelete ? _c('div', {
    staticStyle: {
      "padding-right": "20px"
    }
  }, [_c('icon', {
    staticClass: "icon-toast text-center",
    staticStyle: {
      "color": "#F21100"
    },
    attrs: {
      "name": "times"
    }
  })], 1) : _vm._e()] : _vm._e(), _vm.errorMessage ? _c('div', [_vm.errorMessage.canDelete ? _c('span', [_vm._v(_vm._s(_vm.fileName) + " was successfully deleted")]) : _vm._e(), _vm.errorMessage.error ? _c('span', [_vm._v(_vm._s(_vm.errorMessage.error))]) : _vm._e(), !_vm.errorMessage.canDelete && !_vm.errorMessage.error ? _c('span', [_vm._v(_vm._s(_vm.errorMessage))]) : _vm._e()]) : _vm._e(), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideToast();
      }
    }
  }, [_vm._v(" Dismiss ")])], 2)]), _c('div', [!_vm.isOverviewPage && _vm.userPermissions.includes('MtpSiteDocumentsView') || _vm.isOverviewPage && _vm.userPermissions.includes('MtpGlobalDocumentsView') ? _c('div', {
    staticClass: "file-budget mb-4",
    attrs: {
      "id": "accordion-files"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header",
    attrs: {
      "id": "headingOne1"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_c('button', {
    ref: "toggleButton",
    staticClass: "btn collapsed",
    attrs: {
      "aria-controls": "collapseOne1",
      "aria-expanded": "false",
      "data-target": "#collapseOne1",
      "data-toggle": "collapse"
    }
  }, [_vm.isOverviewPage ? _c('span', [_vm._v("HELP AND SUPPORT FILES")]) : _c('span', [_vm._v("FILES")]), _c('icon', {
    attrs: {
      "name": "chevron-down"
    }
  })], 1)]), _vm.userPermissions.includes('MtpGlobalDocumentsEdit') && _vm.isOverviewPage ? _c('input', {
    ref: "fileupload",
    staticClass: "btn btn-primary",
    attrs: {
      "id": "addfile",
      "hidden": "",
      "name": "addfile",
      "type": "file"
    },
    on: {
      "change": function ($event) {
        return _vm.addFile($event);
      }
    }
  }) : _vm._e(), !_vm.isOverviewPage && _vm.userPermissions.includes('MtpSiteDocumentsEdit') && _vm.selectedMtpFilter === _vm.currentYear || _vm.isOverviewPage && _vm.userPermissions.includes('MtpGlobalDocumentsEdit') ? _c('label', {
    staticClass: "pointerLink button-edit addFiles",
    attrs: {
      "for": "addfile"
    },
    on: {
      "click": function ($event) {
        return _vm.openAddFilesModal();
      }
    }
  }, [_c('icon', {
    attrs: {
      "name": "plus"
    }
  }), _vm._v(" Add files ")], 1) : _vm._e()])]), _c('div', {
    staticClass: "collapse budget-request-wrapper",
    attrs: {
      "id": "collapseOne1",
      "aria-labelledby": "headingOne1",
      "data-parent": "#accordion-files"
    }
  }, [_vm.loading ? _c('div', {
    staticClass: "loader"
  }) : _vm._e(), !_vm.loading ? _c('div', {
    staticClass: "card-body"
  }, [_vm.files ? _c('div', _vm._l(_vm.files.files, function (file, key) {
    return _c('div', {
      key: key,
      staticClass: "row"
    }, [_c('div', {
      staticClass: "col-md-1 col-2 d-flex align-items-center justify-content-center"
    }, [_c('div', {
      staticClass: "circle"
    }, [_c('span', [_vm._v(_vm._s(file.extension))])])]), _c('div', {
      staticClass: "col-md-6 col-6 nameFile pt-3"
    }, [file.fileApplicationType === 'Pml' || _vm.isOverviewPage ? _c('h6', [_vm._v(" " + _vm._s(file.name) + _vm._s(file.extension) + " (" + _vm._s(file.size) + ") ")]) : _vm._e(), file.fileApplicationType === 'Mspt' ? _c('h6', [_vm._v(" " + _vm._s(file.name) + _vm._s(file.extension) + " ")]) : _vm._e(), _c('span', [_vm._v(_vm._s(_vm.fileDateCreated(file.created)) + " by " + _vm._s(file.createdBy))])]), _c('div', {
      staticClass: "col-md-5 col-4 d-flex buttonFile"
    }, [!_vm.isOverviewPage && _vm.userPermissions.includes('MtpSiteDocumentsEdit') && file.fileApplicationType === 'Pml' || _vm.isOverviewPage && _vm.userPermissions.includes('MtpGlobalDocumentsEdit') ? _c('a', {
      staticClass: "pointerLink mr-4",
      staticStyle: {
        "color": "#adadad"
      },
      on: {
        "click": function ($event) {
          _vm.selectedMtpFilter === _vm.currentYear ? _vm.openDeleteModal(file.id, file.name) : '';
        }
      }
    }, [_c('icon', {
      staticClass: "icon-button-file file-delete",
      attrs: {
        "name": "regular/trash-alt"
      }
    }), _c('span', {
      staticClass: "d-none d-sm-block"
    }, [_vm._v("Delete")])], 1) : _vm._e(), !_vm.isOverviewPage && _vm.userPermissions.includes('MtpSiteDocumentsView') || _vm.isOverviewPage && _vm.userPermissions.includes('MtpGlobalDocumentsView') ? _c('a', {
      staticClass: "pointerLink",
      staticStyle: {
        "color": "#007bff"
      },
      on: {
        "click": function ($event) {
          return _vm.downloadFile(file.id, file.fileApplicationType, file.name, file.extension);
        }
      }
    }, [_c('icon', {
      staticClass: "icon-button-file file-download",
      attrs: {
        "name": "download"
      }
    }), _c('span', {
      staticClass: "d-none d-sm-block"
    }, [_vm._v("Download")])], 1) : _vm._e()])]);
  }), 0) : _vm._e(), _vm.files.total > _vm.elementPerPage ? _c('div', {
    staticClass: "pt-3 d-flex"
  }, [_c('div', {
    staticClass: "showingBR pt-2"
  }, [_vm._v(" Showing " + _vm._s(_vm.firstElementTable) + " to " + _vm._s(_vm.files.files.length + _vm.firstElementTable - 1) + " of " + _vm._s(_vm.files.total) + " files ")]), _c('div', {
    staticClass: "pagination ml-auto pr-1 pt-2"
  }, [_vm.objFiles.pageNo !== 1 ? _c('div', {
    staticClass: "arrow mr-2",
    on: {
      "click": function ($event) {
        return _vm.changeNavPageBack();
      }
    }
  }, [_c('icon', {
    attrs: {
      "name": "chevron-left"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "number pr-0 active",
    on: {
      "click": function ($event) {
        return _vm.changeNavPageBack();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.objFiles.pageNo) + " ")]), Math.ceil(parseInt(_vm.files.total) / _vm.elementPerPage) > _vm.objFiles.pageNo ? _c('div', {
    staticClass: "number",
    on: {
      "click": function ($event) {
        return _vm.changeNavPageNext();
      }
    }
  }, [_vm._v(" | " + _vm._s(_vm.objFiles.pageNo + 1) + " ")]) : _vm._e(), Math.ceil(parseInt(_vm.files.total) / _vm.elementPerPage) > _vm.objFiles.pageNo ? _c('div', {
    staticClass: "arrow ml-2",
    on: {
      "click": function ($event) {
        return _vm.changeNavPageNext();
      }
    }
  }, [_c('icon', {
    attrs: {
      "name": "chevron-right"
    }
  })], 1) : _vm._e()])]) : _vm._e()]) : _vm._e()])]) : _vm._e()])], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }