import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop, Watch } from 'vue-property-decorator';
import IybrCompensationTableComponent from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/iybr-compensation-table/iybr-compensation-table.vue';
import IybrCompensationTableComponentTs, { CompensationTableTotals } from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/iybr-compensation-table/iybr-compensation-table';
import AddCompensationModalComponent from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/add-compensation-modal/add-compensation-modal.vue';
import { ChangeModalDataPayload } from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/add-compensation-modal/add-compensation-modal';
import { MasterService } from '~/services/master-service';
import { Data } from '~/utils';
import { CompensationRequiredTotals, CreateIybrFormCompensationModel } from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/create-iybr-form-model';
import { BudgetRequestsMiniFinancialDecision, GetMiniFdByGlobalIdResponse, MiniFd, MiniFdStatus } from '~/models/services/minifd';
import { PuiLightbox, PuiTableVendorOptions } from '~/models/libraries/pebble-ui';
import { SelectOptions } from "~/components/in-year-budget-requests/add-in-year-budget-requests-modal/options"
import { BudgetCompensationType } from "~/models/services/constants-list"
import { SumBudgetRequestData }    from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/iybr-cost-table/iybr-cost-table.vue';
import { safeInvertedDifference, safeSum } from '~/utils/math-helper';
import { IybrGlobalIdsCollapsibleTableData, ProjectTypes } from '../../create-in-year-budget-request/create-iybr-form/iybr-global-ids-collapsible/iybr-global-ids-collapsible';
import { BudgetRequestPlanningStatus, MtpStatus, NewBudgetRequests } from '~/utils/interfaces';
import { ROWS } from '../../constants/rows';
import { PlantAttributesItem } from '~/models/services/plants';

const REF_CONSTANTS = {
    COST_TABLE: 'costTable',
    COMPENSATION_TABLE: 'compensationTable',
    LIGHTBOX: 'lightbox'
} as const;

@Component({
    components: {
        iybrCompensationTable: IybrCompensationTableComponent,
        addCompensationModal: AddCompensationModalComponent,
    }
})
export default class EditBudgetCompensationModalComponent extends Vue {
    $refs!: {
        [REF_CONSTANTS.COMPENSATION_TABLE]: IybrCompensationTableComponentTs,
        [REF_CONSTANTS.LIGHTBOX]: PuiLightbox
    };

    private readonly REF_CONSTANTS = REF_CONSTANTS;

    private readonly formLayout = ROWS;
    private budgetCompensationMetadata = {
        hasErrors: false,
        isCompensatedValueValid: true,
        isCompensationAvailableExceedingRequired: true,
    };

    private compensationTotals?: CompensationTableTotals = null;
    
    @Prop()
    private plantData!: PlantAttributesItem;

    @Prop()
    private globalIdsCollapsibleTableData?: IybrGlobalIdsCollapsibleTableData;
    
    @Prop()
    private iybrData?: GetMiniFdByGlobalIdResponse;

    @Prop()
    private requestType?: string;

    @Prop({ default: () => new Date().getFullYear() })
    private mtpYear?: number;

    private selectedBudgetCompensationType: BudgetCompensationType | null = null;

    private compensationModel: CreateIybrFormCompensationModel = {};
    private showAddCompensationModal = false;
    private showLoadingSpinner = false;

    private budgetRequest: NewBudgetRequests[];
    private miniBudgetRequest: BudgetRequestsMiniFinancialDecision[];
    
    @Watch('iybrData', { immediate: true })
    private handleIybrDataChange(newIybrData: GetMiniFdByGlobalIdResponse): void {
        this.selectedBudgetCompensationType = newIybrData?.budgetCompensationType;
        this.budgetRequest = newIybrData.budgetRequests;
        this.miniBudgetRequest = newIybrData.budgetRequestsMiniFinancialDecisions;
    }

    private get budgetCompensationMiniFdId(): string | undefined {
        if (this.iybrData.status !== MiniFdStatus.APPROVED) {
            return;
        }
        return this.iybrData.id;
    }

    private get currentTechnologySid(): number | undefined {
        if (this.iybrData?.budgetRequests.length === 0) {
            return;
        }

        return this.iybrData?.budgetRequests[0].technologySid;
    }

    private get currentPlantSid(): number | undefined {
        if (this.iybrData?.budgetRequests.length === 0) {
            return;
        }

        return this.iybrData?.budgetRequests[0].plantSid;
    }

    private get budgetCompensationTypeValues(): SelectOptions<number>[] {
        return Object.keys(Data.Instance.constants.localBudgetCompensationsTypes).map(key => ({
            label: Data.Instance.constants.localBudgetCompensationsTypes[key],
            value: parseInt(key)
        }));
    }

    private get budgetCompensationMtpYear(): number | undefined {
        return this.iybrData?.budgetCompensations.length > 0
            ? this.iybrData?.budgetCompensations[0].selectedMtpYear
            : this.iybrData?.budgetRequestsMiniFinancialDecisions[0].year;
    }

    public openLightbox(): void {
        this.$refs[REF_CONSTANTS.LIGHTBOX].open();
    }

    private onLightboxOpen(): void {
        if (this.iybrData?.budgetCompensations.length > 0) {
            this.$nextTick(() => {
                this.$refs[REF_CONSTANTS.COMPENSATION_TABLE].populateCompensationTable(this.iybrData.budgetCompensations).then();
            });
        }
    }

    private handleCompensationTotalsUpdate(newCompensationTotals: CompensationTableTotals): void {
        this.compensationTotals = newCompensationTotals;
    }

    private handleCompensationModelUpdate(newCompensationModel: CreateIybrFormCompensationModel): void {
        this.compensationModel = newCompensationModel;
    }

    private get columns(): Record<string, string> {
        return {
            'MTP+1': `${this.mtpYear + 1}`,
            'MTP+2': `${this.mtpYear + 2}`,
            'MTP+3': `${this.mtpYear + 3}`,
        };
    }
    private get columnNameMap(): Record<string, string> {
        return {
            'MTP+1': 'new1',
            'MTP+2': 'new2',
            'MTP+3': 'new3',
        };
    }
      
    private tableOptions(): PuiTableVendorOptions {

        const sumBudgetRequestData = (field: string): SumBudgetRequestData => {
            if (this.globalIdsCollapsibleTableData) {
                const approved = Object.keys(this.globalIdsCollapsibleTableData)
                    .filter(
                        (key) =>
                            this.globalIdsCollapsibleTableData[key].budgetRequestData.planning.mtpStatus ===
                            MtpStatus.APPROVED
                    )
                    .map((key) => this.globalIdsCollapsibleTableData[key].budgetRequestData.planning[field])
                    .reduce((previous, current) => previous + current, 0);

                const pending = Object.keys(this.globalIdsCollapsibleTableData)
                    .filter(
                        (key) =>
                            this.globalIdsCollapsibleTableData[key].budgetRequestData.planning.mtpStatus !==
                            MtpStatus.APPROVED
                    )
                    .map((key) => this.globalIdsCollapsibleTableData[key].budgetRequestData.planning[field])
                    .reduce((previous, current) => previous + current, 0);

                return {
                    approved,
                    pending,
                    total: approved + pending,
                };
            }

            return {
                approved: 0,
                pending: 0,
                total: 0,
            };
        };

        const budgetRequestRow = {
            [this.columns['MTP+1']]: this.budgetRequest.map(request => request.planning?.new1 ?? 0),
            [this.columns['MTP+2']]: this.budgetRequest.map(request => request.planning?.new2 ?? 0),
            [this.columns['MTP+3']]: this.budgetRequest.map(request => request.planning?.new3 ?? 0),
        };

        const totalBudgetRow = {
            [this.columns['MTP+1']]: this.miniBudgetRequest.map(request => request.spentYearPlus1),
            [this.columns['MTP+2']]: this.miniBudgetRequest.map(request => request.spentYearPlus2),
            [this.columns['MTP+3']]: this.miniBudgetRequest.map(request => request.spentYearPlus3),
        };
        
        const compensationRowCellValue = (columnName: string): number => {
            return safeInvertedDifference(
                budgetRequestRow[this.columns[columnName]],
                totalBudgetRow[this.columns[columnName]]
            );
        };

        const compensationValues: Record<string, number> = {
            'MTP+1': compensationRowCellValue('MTP+1'),
            'MTP+2': compensationRowCellValue('MTP+2'),
            'MTP+3': compensationRowCellValue('MTP+3'),
        };

        this.onCompensationRequiredChange({
            mtpPlus1Required: compensationValues['MTP+1'],
            mtpPlus2Required: compensationValues['MTP+2'],
            mtpPlus3Required: compensationValues['MTP+3'],
        });

        const compensationRow = {
            [this.columns['MTP+1']]: compensationValues['MTP+1'],
            [this.columns['MTP+2']]: compensationValues['MTP+2'],
            [this.columns['MTP+3']]: compensationValues['MTP+3'],
        };

        const toBeApprovedRowCellValue = (columnName: string): number => {
            return safeSum(
                compensationRow[this.columns[columnName]],
                sumBudgetRequestData(this.columnNameMap[columnName]).pending
            );
        };

        const toBeApprovedValues: Record<string, number> = {
            'MTP+1': toBeApprovedRowCellValue('MTP+1'),
            'MTP+2': toBeApprovedRowCellValue('MTP+2'),
            'MTP+3': toBeApprovedRowCellValue('MTP+3'),
        };

        const toBeApprovedRow = {
            [this.columns['MTP+1']]: toBeApprovedValues['MTP+1'],
            [this.columns['MTP+2']]: toBeApprovedValues['MTP+2'],
            [this.columns['MTP+3']]: toBeApprovedValues['MTP+3'],
        };

        const frontOptionalRows = [];
        const backOptionalRows = [];

        if (this.requestType !== BudgetRequestPlanningStatus.NEW_PROJECT.toString()) {
            frontOptionalRows.push(budgetRequestRow, totalBudgetRow);
        }

        if (this.compensationTotals && this.compensationTotals.hasCompensationEntries) {
            const compensationAvailable = {
                [this.columns['MTP+1']]: this.compensationTotals.mtpYearPlus1Total,
                [this.columns['MTP+2']]: this.compensationTotals.mtpYearPlus2Total,
                [this.columns['MTP+3']]: this.compensationTotals.mtpYearPlus3Total,
            };

            backOptionalRows.push(compensationAvailable);
        }
        return compensationRow
    }

    private onCompensationRequiredChange(compensationRequiredTotals: CompensationRequiredTotals): void {
        this.$emit('change:compensation-required', compensationRequiredTotals);
    }

    private validateBudgetCompensation(): void {
        const compensationRow = this.tableOptions();

        this.budgetCompensationMetadata.hasErrors = false
        this.budgetCompensationMetadata.isCompensatedValueValid = true
        this.budgetCompensationMetadata.isCompensationAvailableExceedingRequired = true;

        for (let key in this.compensationModel) {
            if (
                Number(this.compensationModel[key].yearPlus1.value) < 0 ||
                Number(this.compensationModel[key].yearPlus1.value) > Number(this.compensationModel[key].originalYearPlus1.value) ||
                Number(this.compensationModel[key].yearPlus2.value) < 0 ||
                Number(this.compensationModel[key].yearPlus2.value) > Number(this.compensationModel[key].originalYearPlus2.value) ||
                Number(this.compensationModel[key].yearPlus3.value) < 0 ||
                Number(this.compensationModel[key].yearPlus3.value) > Number(this.compensationModel[key].originalYearPlus3.value)
            ) {
                this.budgetCompensationMetadata.hasErrors = true;
                this.budgetCompensationMetadata.isCompensatedValueValid = false;
            }

        }

        if (!this.compensationTotals || !compensationRow) {
            return;
        }

        const compensationAvailableTotals = {
            mtpPlus1Available: this.compensationTotals.mtpYearPlus1Total,
            mtpPlus2Available: this.compensationTotals.mtpYearPlus2Total,
            mtpPlus3Available: this.compensationTotals.mtpYearPlus3Total,
        };

        if (Object.keys(this.compensationModel).length > 0) {
            if 
            ((compensationAvailableTotals.mtpPlus1Available != 0 && compensationAvailableTotals.mtpPlus1Available > compensationRow[this.columns['MTP+1']]) ||
            (compensationAvailableTotals.mtpPlus2Available != 0 && compensationAvailableTotals.mtpPlus2Available > compensationRow[this.columns['MTP+2']]) ||
            (compensationAvailableTotals.mtpPlus3Available != 0 && compensationAvailableTotals.mtpPlus3Available > compensationRow[this.columns['MTP+3']])) 
            {
                this.budgetCompensationMetadata.hasErrors = true;
                this.budgetCompensationMetadata.isCompensationAvailableExceedingRequired = false;
            }
        }

    }

    private async onLightboxConfirm(): Promise<void> {

        if (!this.iybrData) {
            return;
        }
        this.validateBudgetCompensation();

        if (this.budgetCompensationMetadata.hasErrors) {
            return;
        }


        const budgetCompensations = Object.values(this.compensationModel).map(entry => ({
            budgetRequestId: entry.budgetRequestId.value,
            justification: parseInt(entry.justification.value, 10),
            requestedYearPlus1: parseFloat(entry.yearPlus1.value),
            requestedYearPlus2: parseFloat(entry.yearPlus2.value),
            requestedYearPlus3: parseFloat(entry.yearPlus3.value),
            selectedMtpYear: parseInt(entry.selectedMtpYear.value, 10)
        }));

        try {
            this.showLoadingSpinner = true;

            await MasterService.instance.miniFdService.editBudgetCompensation({
                miniFdId: this.iybrData.id,
                budgetCompensationType: this.selectedBudgetCompensationType,
                budgetCompensations
            });

            this.$emit('update:budget-compensation');
            this.$refs[REF_CONSTANTS.LIGHTBOX].close();

        } catch (err) {
            this.showLoadingSpinner = false;

            this.$pui.toast({
                type: 'error',
                title: 'An error occurred when attempting to submit Budget Compensation',
                copy: err?.response?.data?.errors?.[0]
            })
        }
    }


    private async onLightboxBeforeClose(): Promise<void> {
        this.resetModalData();
    }

    private resetModalData(): void {
        this.showLoadingSpinner = false;
        this.showAddCompensationModal = false;
    }

    private handleNewBudgetCompensationPlanClick(): void {
        this.showAddCompensationModal = true;
    }

    private updateBudgetCompensationTableWithRows(payload: ChangeModalDataPayload): void {
        payload.budgetRequestIds.forEach(budgetRequestId => {
            this.$refs[REF_CONSTANTS.COMPENSATION_TABLE].addNewBudgetRequestForCompensationByInternalId(payload.mtpYear.toString(), budgetRequestId).then();
        });
    }

}
