import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import { ColDef, ColumnApi, GridApi, GridOptions, IServerSideGetRowsParams } from 'ag-grid-community';
import TableHeader from '@/components/ag-grid-table/header-types/table-header.vue';
import NoRowsOverlay from '@/components/ag-grid-table/overlay-types/no-rows-overlay.vue';
import { AgGridCommon } from 'ag-grid-community/dist/lib/interfaces/iCommon';
import { CurrencyRateService } from '~/services/currency-rates';

const CURRENCY_RATES_TABLE_COLUMN_STATE = 'currency_rates_table_column_state' as const;

type ModifiedValue = {
    id: string;
    comment: string;
    forecast: number;
    new1: number;
    new2: number;
    new3: number;
}

@Component({
    components: {
        AgGridVue,
        TableHeader,
        NoRowsOverlay,
    }
})
export default class CurrencyRatesTableComponent extends Vue {
    private CurrencyRateService: CurrencyRateService = new CurrencyRateService();
    private gridApi: GridApi | null = null;
    private columnApi: ColumnApi | null = null;
    private localRates: any = null;
    private modifiedValues = new Map<string, ModifiedValue>();
    private isEdited = false;
    private loading = false;

    private tableExtraFilters = {
        pageSize: 20,
        searchTerm: '',
    }

    private get columnDefs(): ColDef[] {
        return [
            {
                headerName: 'Year',
                field: 'year',
                rowGroup: true,
                hide: true,
                suppressColumnsToolPanel: true,
            },
            { headerName: 'Base Currency', field: 'baseCurrency' },
            { headerName: 'Target Currency', field: 'targetCurrency' },
            {
                headerName: 'Comment', 
                field: 'comment',
                tooltipField: 'comment',
                minWidth: 200,
                editable: true,
                valueSetter: (params): boolean => {
                    if (params.oldValue === params.newValue) {
                        return false;
                    }

                    const id = params.data.id;
                    const comment = params.newValue;
                    const forecast = this.parseValue(params.data.forecast);
                    const new1 = Number(params.data.new1);
                    const new2 = Number(params.data.new2);
                    const new3 = Number(params.data.new3);

                    // Update modified value in this.modifiedValues
                    if (comment) {
                        const modifiedValue: ModifiedValue = { id, comment, forecast, new1, new2, new3 };
                        this.modifiedValues.set(id, modifiedValue);
                        this.isEdited = true;
                        
                        // Update modified value in table data
                        params.data[params.column.getColId()] = comment;
                        return true;
                    }

                    return false;
                }
            },
            {
                headerName: 'Forecast',
                field: 'forecast',
                editable: true,
                valueSetter: (params): boolean => {
                    if (params.oldValue.toString() === params.newValue || params.newValue.trim() === '' || !params.data) {
                        return false;
                    }

                    const id = params.data.id;
                    const comment = params.data.comment;
                    const forecast = this.parseValue(params.newValue);
                    const new1 = Number(params.data.new1);
                    const new2 = Number(params.data.new2);
                    const new3 = Number(params.data.new3);

                    // Update modified value in this.modifiedValues
                    if (forecast) {
                        const modifiedValue: ModifiedValue = { id, comment, forecast, new1, new2, new3 };
                        this.modifiedValues.set(id, modifiedValue);
                        this.isEdited = true;
                        
                        // Update modified value in table data
                        params.data[params.column.getColId()] = forecast;
                        return true;
                    }

                    return false;
                }
            },
            {
                headerName: 'New 1',
                field: 'new1',
                editable: true,
                valueSetter: (params): boolean => {
                    if (params.oldValue.toString() === params.newValue || params.newValue.trim() === '' || !params.data) {
                        return false;
                    }

                    const id = params.data.id;
                    const comment = params.data.comment;
                    const forecast = Number(params.data.forecast);
                    const new1 = this.parseValue(params.newValue);
                    const new2 = Number(params.data.new2);
                    const new3 = Number(params.data.new3);

                    // Update modified value in this.modifiedValues
                    if (new1) {
                        const modifiedValue: ModifiedValue = { id, comment, forecast, new1, new2, new3 };
                        this.modifiedValues.set(id, modifiedValue);
                        this.isEdited = true;
                        
                        // Update modified value in table data
                        params.data[params.column.getColId()] = new1;
                        return true;
                    }

                    return false;
                }
            },
            {
                headerName: 'New 2',
                field: 'new2',
                editable: true,
                valueSetter: (params): boolean => {
                    if (params.oldValue.toString() === params.newValue || params.newValue.trim() === '' || !params.data) {
                        return false;
                    }

                    const id = params.data.id;
                    const comment = params.data.comment;
                    const forecast = Number(params.data.forecast);
                    const new1 = Number(params.data.new1);
                    const new2 = this.parseValue(params.newValue);
                    const new3 = Number(params.data.new3);

                    // Update modified value in this.modifiedValues
                    if (new2) {
                        const modifiedValue: ModifiedValue = { id, comment, forecast, new1, new2, new3 };
                        this.modifiedValues.set(id, modifiedValue);
                        this.isEdited = true;
                        
                        // Update modified value in table data
                        params.data[params.column.getColId()] = new2;
                        return true;
                    }

                    return false;
                }
            },
            {
                headerName: 'New 3',
                field: 'new3',
                editable: true,
                valueSetter: (params): boolean => {
                    if (params.oldValue.toString() === params.newValue || params.newValue.trim() === '' || !params.data) {
                        return false;
                    }

                    const id = params.data.id;
                    const comment = params.data.comment;
                    const forecast = Number(params.data.forecast);
                    const new1 = Number(params.data.new1);
                    const new2 = Number(params.data.new2);
                    const new3 = this.parseValue(params.newValue);

                    // Update modified value in this.modifiedValues
                    if (new3) {
                        const modifiedValue: ModifiedValue = { id, comment, forecast, new1, new2, new3 };
                        this.modifiedValues.set(id, modifiedValue);
                        this.isEdited = true;
                        
                        // Update modified value in table data
                        params.data[params.column.getColId()] = new3;
                        return true;
                    }

                    return false;
                }
            },
        ];
    }

    private gridOptions: GridOptions = {
        rowModelType: 'serverSide',
        serverSideDatasource: {
            getRows: (params: IServerSideGetRowsParams) => {
                if (params.request.groupKeys.length === 0) {
                    this.fetchData(params);
                } else {
                    this.extractGroup(params);
                }
            }
        },
        defaultColDef: {
            filter: true,
            sortable: false,
            unSortIcon: true,
            menuTabs: [],
            minWidth: 150,
            cellClass: (params) => params.colDef.editable ? '' : 'currency-rates-table__table__cell--non-editable',
        },
        autoGroupColumnDef: {
            headerName: 'Year',
            pinned: 'left',
            field: 'year',
        },
        domLayout: 'autoHeight',
        suppressMenuHide: true,
        pagination: true,
        paginationPageSize: this.tableExtraFilters.pageSize,
        cacheBlockSize: this.tableExtraFilters.pageSize,
        noRowsOverlayComponent: 'NoRowsOverlay',
        serverSideFilterOnServer: true,
        popupParent: document.querySelector('body'),
        tooltipShowDelay: 0,
        tooltipMouseTrack: true,
        sideBar: {
            defaultToolPanel: '',
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
        },
        isServerSideGroupOpenByDefault(params) {
            const currentYear = new Date(Date.now()).getFullYear().toString();
            const rowNode = params.rowNode;
            const isYear = rowNode.field === 'year' && rowNode.key.toString() === currentYear;
            return isYear;
        },
        onColumnMoved: this.setColumnState,
        onColumnVisible: this.setColumnState,
        onColumnPinned: this.setColumnState,
        onToolPanelVisibleChanged: this.sizeColumnsToFit,
        onToolPanelSizeChanged: this.sizeColumnsToFit,
        onGridSizeChanged: this.sizeColumnsToFit,
    };

    private onGridReady(params: AgGridCommon<any, any>): void {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;

        this.applyColumnState();
    }

    private applyColumnState(): void {
        let columnState = JSON.parse(localStorage.getItem(CURRENCY_RATES_TABLE_COLUMN_STATE));
        if (columnState) {
            this.columnApi.applyColumnState({ state: columnState, applyOrder: true });
        }
    }

    private setColumnState(): void {
        let columnState = JSON.stringify(this.columnApi.getColumnState());
        localStorage.setItem(CURRENCY_RATES_TABLE_COLUMN_STATE, columnState);
    }

    private resetFilterState(): void {
        this.gridApi?.setFilterModel(null);
    }

    private refreshTable(): void {
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private sizeColumnsToFit(): void {
        this.gridApi?.sizeColumnsToFit();
    }

    private onTriggerSearch(searchTerm: string): void {
        this.tableExtraFilters.searchTerm = searchTerm;
        this.refreshTable();
    }
    
    private onClearSearchTerm(): void {
        this.onTriggerSearch('');
    }

    private onPageSizeChanged(pageSize: number): void {
        this.tableExtraFilters.pageSize = pageSize;
        this.gridApi?.paginationSetPageSize(this.tableExtraFilters.pageSize);
        this.gridApi?.setCacheBlockSize(this.tableExtraFilters.pageSize);
    }
    
    private pageNumber(endRow: number | undefined): number {
        return Math.floor((endRow ?? this.tableExtraFilters.pageSize) / this.tableExtraFilters.pageSize)
    }

    private async fetchData(params: IServerSideGetRowsParams): Promise<void> {
        params.api.hideOverlay();

        const pageNumber = this.pageNumber(params.request.endRow);
        const { pageSize, searchTerm } = this.tableExtraFilters;

        try {
            this.localRates = await this.CurrencyRateService.getCurrencyRates(pageNumber, pageSize, searchTerm);
            params.success({ rowData: this.localRates.currencyRates.currencyRates, rowCount: this.localRates.currencyRates.total });
            if (this.localRates.currencyRates.total === 0) {
                params.api.showNoRowsOverlay()
            }
        } catch (e) {
            params.fail()
        }
    }

    private extractGroup(params: IServerSideGetRowsParams): void {
        const currencyRates = this.localRates.currencyRates.currencyRates.find(e => e.year === params.request.groupKeys[0]);
        const currencyRatesGroup = currencyRates?.currencyRates ?? [];
        params.success({ rowData: currencyRatesGroup, rowCount: currencyRatesGroup.length });
    }

    private parseValue(value: string): number | null {
        const parsedValue = parseFloat(value);
        return isNaN(parsedValue) ? null : parsedValue;
    }

    private get isEditDisabled(): boolean {
        return !this.isEdited || this.loading;
    }

    private async saveChanges(): Promise<void> {
        if (this.modifiedValues.size === 0) {
            return;
        }

        this.loading = true;

        const currencyRatesToEdit: ModifiedValue[] = [];
        this.modifiedValues.forEach(value => currencyRatesToEdit.push(value));

        const payload: { currencyRatesToEdit: ModifiedValue[] } = {
            currencyRatesToEdit,
        }

        try {
            await this.CurrencyRateService.editCurrencyRates(payload);
            this.modifiedValues.clear();
            this.isEdited = false;
            this.gridApi?.refreshServerSide({ purge: true });
            this.$pui.toast({
                type: 'success',
                title: 'Success',
                copy: 'Currency Rates saved.',
            });
        } catch (err) {
            this.$pui.toast({
                type: 'error',
                title: 'Failed',
                copy: 'Currency Rates failed to save.',
            });
        } finally {
            this.loading = false;
        }
    }
}
