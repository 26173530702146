import { Connection } from '~/utils/';
import { ApiResponse, FileResponse } from '~/models/services/common';
import { AxiosResponse } from 'axios';
import {
    DeleteFileResponse,
    GetFilesPayload,
    GetFilesResponse,
    ImportBudgetRequestResponse,
    UploadFileResponse
} from '~/models/services/file';
import { ExportPayload } from '~/models/services/reporting';

export class FileService {
    private readonly api: Connection = new Connection();

    public async exportBudgetRequests(exportBudgetRequests: ExportPayload): Promise<FileResponse> {
        const content = await this.api.post<Blob>('file/export-budget-requests', exportBudgetRequests, { responseType: 'blob' });
        const name = content.headers['content-disposition'].split('UTF-8\'\'')[1];

        return {
            name,
            content: content.data
        };
    }

    public async downloadBudgetRequests(): Promise<AxiosResponse<Blob>> {
        return (await this.api.get<Blob>('file/download-import-template/', { responseType: 'blob'}));
    }

    public async importBudgetRequests(importBudgetRequest: FormData): Promise<ImportBudgetRequestResponse> {
        return (await this.api.post<ApiResponse<ImportBudgetRequestResponse>>('file/import-budget-requests', importBudgetRequest, { headers: { 'Content-Type' : 'multipart/form-data; boundary=${data._boundary}' } })).data.result;
    }

    public async addFile(addFile: FormData): Promise<UploadFileResponse> {
        return (await this.api.post<ApiResponse<UploadFileResponse>>('file', addFile, { headers: { 'Content-Type' : 'multipart/form-data; boundary=${data._boundary}' } })).data.result;
    }

    public async getFiles(detailsFiles: GetFilesPayload): Promise<GetFilesResponse> {
        return (await this.api.post<ApiResponse<GetFilesResponse>>('file/list', detailsFiles)).data.result;
    }

    public async deleteFile(fileId: string): Promise<DeleteFileResponse> {
        return (await this.api.delete('file/' + fileId)).data.result;
    }

    public async downloadFile(fileId: string): Promise<Blob> {
        return (await this.api.get<Blob>('file/download/' + fileId, { responseType: 'blob'})).data;
    }

    public async downloadMspFile(fileId: string, fileExtension: string): Promise<Blob> {
        return (await this.api.get<Blob>('file/download-msp-file/' + fileId + '/' + fileExtension, { responseType: 'blob'})).data;
    }
}
