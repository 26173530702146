import { Connection } from '~/utils/';
import { BudgetRequestHistory, BudgetRequests, NewBudgetRequests, SearchModel } from '~/utils/interfaces';
import {
    AddToCurrentMtpPayload,
    AddToCurrentMtpResponse,
    BudgetRequest,
    BudgetRequestByOutageSearchResult,
    BudgetRequestSearchResult,
    BulkDeleteInactiveResponse,
    ChangeMtpStatusesPayload,
    ChangeMtpStatusesResponse,
    DeleteBudgetRequestPayload,
    DeleteBudgetRequestResponse, EditControllingInformationPayload, EditControllingInformationResponse,
    EditProjectStatusPayload,
    EditProjectStatusResponse,
    LatestBudgetRequestDataResponse, LatestChangeInPlantPayload,
    LatestChangeInPlantResponse,
    ReleaseBudgetRequestPayload,
    ReleaseBudgetRequestResponse,
    ReviewBudgetRequestPayload,
    ReviewBudgetRequestResponse,
    SearchBudgetRequestsByGlobalIdResponse,
    SearchBudgetRequestsByOutageIdResponse,
    SetBudgetRequestsInactivePayload,
    SetBudgetRequestsInactiveResponse
} from '~/models/services/budget-request';
import { ApiResponse } from '~/models/services/common';

export class BudgetRequestsService {
    private readonly api: Connection = new Connection();

    public async getBudgetRequest(searchModel: SearchModel): Promise<BudgetRequests> {
        return (await this.api.post<ApiResponse<BudgetRequests>>('budget-request/search', searchModel)).data.result;
    }

    public async searchBudgetRequestsByGlobalId(globalId: string, mtpYear?: number): Promise<BudgetRequestSearchResult> {
        let queryString = '';
        if (mtpYear) {
            queryString += `?mtpYear=${mtpYear}`;
        }

        return (await this.api.get<ApiResponse<SearchBudgetRequestsByGlobalIdResponse>>(`budget-request/get-budgets-by-global-ids/${globalId}${queryString}`)).data.result.budgetRequests;
    }

    public async searchBudgetRequestsByOutageId(outageId: string, mtpYear?: number): Promise<BudgetRequestByOutageSearchResult> {
        let queryString = '';
        if (mtpYear) {
            queryString += `?mtpYear=${mtpYear}`;
        }

        return (await this.api.get<ApiResponse<SearchBudgetRequestsByOutageIdResponse>>(`budget-request/get-budgets-by-outage-id/${outageId}${queryString}`)).data.result.budgetRequests;
    }

    public async getBudgetRequestHistory(pageSize: number, page: number, budgetRequestId: string): Promise<BudgetRequestHistory> {
        return (await this.api.get<ApiResponse<BudgetRequestHistory>>('budget-request/history/' + pageSize + '/' + page + '/' + budgetRequestId)).data.result;
    }

    public async addBudgetRequest(newBudgetRequests: NewBudgetRequests): Promise<string> {
        return (await this.api.post<ApiResponse<NewBudgetRequests>>('budget-request', newBudgetRequests)).data.result.id;
    }

    public async GetBudgetRequestDetailsByInternalId(mtpYear: string, id: string): Promise<BudgetRequest> {
        return (await this.api.get<ApiResponse<BudgetRequest>>('budget-request/' + mtpYear + '/' + id)).data.result;
    }

    public async getBudgetRequestDetails(mtpYear: string | number, globalId: string, miniFdYear?: string | number): Promise<BudgetRequest> {
        let queryStringParameters: string[] = [];

        if (mtpYear !== undefined && mtpYear !== null) {
            queryStringParameters.push(`mtpYear=${mtpYear}`);
        }

        if (miniFdYear !== undefined && miniFdYear !== null) {
            queryStringParameters.push(`miniFdYear=${miniFdYear}`);
        }

        const queryString = '?' + queryStringParameters.join('&');

        return (await this.api.get<ApiResponse<BudgetRequest>>('budget-request/view/' + globalId + queryString)).data.result;
    }

    public async getBudgetRequestWithCompensationDetails(mtpYear: string, globalId: string): Promise<NewBudgetRequests> {
        let queryStringParameters: string[] = [];

        if (mtpYear !== undefined && mtpYear !== null) {
            queryStringParameters.push(`mtpYear=${mtpYear}`);
        }

        const queryString = '?' + queryStringParameters.join('&');

        return (await this.api.get<ApiResponse<NewBudgetRequests>>('budget-request/with-compensation/' + globalId + queryString)).data.result;
    }

    public async getBudgetRequestEdit(mtpYear: string | number, globalId: string, miniFdYear?: string | number): Promise<NewBudgetRequests> {
        let queryStringParameters: string[] = [];

        if (mtpYear !== undefined && mtpYear !== null) {
            queryStringParameters.push(`mtpYear=${mtpYear}`);
        }

        if (miniFdYear !== undefined && miniFdYear !== null) {
            queryStringParameters.push(`miniFdYear=${miniFdYear}`);
        }

        const queryString = '?' + queryStringParameters.join('&');

        return (await this.api.get<ApiResponse<NewBudgetRequests>>('budget-request/edit/' + globalId + queryString)).data.result;
    }

    public async changeMtpStatus(changeMtpStatus: ChangeMtpStatusesPayload): Promise<ChangeMtpStatusesResponse> {
        return (await this.api.post<ApiResponse<ChangeMtpStatusesResponse>>('budget-request/mtp-status-change', changeMtpStatus)).data.result;
    }

    public async reviewBudgetRequest(reviewBudgetRequests: ReviewBudgetRequestPayload): Promise<ReviewBudgetRequestResponse> {
        return (await this.api.post<ApiResponse<ReviewBudgetRequestResponse>>('budget-request/review', reviewBudgetRequests)).data.result;
    }

    public async releaseBudgetRequests(changeFundingStatus: ReleaseBudgetRequestPayload): Promise<ReleaseBudgetRequestResponse> {
        return (await this.api.post<ApiResponse<ReleaseBudgetRequestResponse>>('budget-request/release', changeFundingStatus)).data.result;
    }

    public async deleteBudgetRequest(deleteBudgetRequest: DeleteBudgetRequestPayload): Promise<DeleteBudgetRequestResponse> {
        return (await this.api.post<ApiResponse<DeleteBudgetRequestResponse>>('budget-request/delete', deleteBudgetRequest)).data.result;
    }

    public async setBudgetRequestsInactive(setBrInactive: SetBudgetRequestsInactivePayload): Promise<SetBudgetRequestsInactiveResponse> {
        return (await this.api.post<ApiResponse<SetBudgetRequestsInactiveResponse>>('budget-request/set-inactive', setBrInactive)).data.result;
    }

    public async addToCurrentMtp(addToCurrentMtp: AddToCurrentMtpPayload): Promise<AddToCurrentMtpResponse> {
        return (await this.api.post<ApiResponse<AddToCurrentMtpResponse>>('budget-request/add-to-current-mtp', addToCurrentMtp)).data.result;
    }

    public async moveAllPreviousMtpToCurrentMtp(): Promise<number> {
        return (await this.api.get('budget-request/move-all-previous-mtp')).data.result.countMoved;
    }

    public async clearAllInactiveBudgetRequests(): Promise<BulkDeleteInactiveResponse> {
        return (await this.api.get<ApiResponse<BulkDeleteInactiveResponse>>('budget-request/delete-all-inactive')).data.result;
    }

    public async getLatestChangeInPlant(payload: LatestChangeInPlantPayload): Promise<LatestChangeInPlantResponse> {
        return (await this.api.post<ApiResponse<LatestChangeInPlantResponse>>('budget-request/latest-change', payload)).data.result;
    }

    public async editControllingInformation(editControllingInformation: EditControllingInformationPayload): Promise<EditControllingInformationResponse> {
        return (await this.api.post<ApiResponse<EditControllingInformationResponse>>('budget-request/controlling-information/edit', editControllingInformation)).data.result;
    }

    public async getLatestBudgetRequestData(budgetRequestId: string, mtpYear: string, currencyCode: string, miniFdId?: string): Promise<LatestBudgetRequestDataResponse> {
        const queryParams = new URLSearchParams();

        queryParams.set('mtpYear', mtpYear);
        queryParams.set('currencyCode', currencyCode);

        if (miniFdId) {
            queryParams.set('miniFdId', miniFdId);
        }
        
        return (await this.api.get<ApiResponse<LatestBudgetRequestDataResponse>>(`budget-request/latest/${budgetRequestId}?${queryParams}`)).data.result;
    }

    public async editProjectStatus(editProjectStatus: EditProjectStatusPayload): Promise<EditProjectStatusResponse> {
        return (await this.api.post<ApiResponse<EditProjectStatusResponse>>('budget-request/project-status-update', editProjectStatus)).data.result;
    }
}
