var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "view-compensation-table"
  }, [_c('pui-table', {
    attrs: {
      "columns-are-center-aligned": true,
      "first-column-is-left-aligned": true,
      "vendor-options": _vm.tableOptions
    },
    scopedSlots: _vm._u([_vm._l(Object.values(_vm.tableOptions.columns), function (columnName) {
      return {
        key: columnName,
        fn: function (data) {
          return [_c('span', {
            key: columnName,
            class: _vm.cellValueClass(data.row[data.column])
          }, [_vm._v(" " + _vm._s(_vm.tableCellTextFormat(data.row[data.column])) + " ")])];
        }
      };
    })], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }