import { Connection } from '~/utils/';
import { AddMiniFdPeriod, AddMiniFdTagsPayload, DeleteMiniFdTagsPayload, GetMiniFdPeriodsResponse, GetMiniFdTagsResponse, MiniFdPeriod } from '~/models/services/cr-period';
import { ApiResponse } from '~/models/services/common';
import { Tag } from '~/models/services/tags';

export class CrService {
    private readonly api: Connection = new Connection();

    public async addCrPeriod(newCrPeriod: AddMiniFdPeriod): Promise<MiniFdPeriod> {
        return (await this.api.post<ApiResponse<MiniFdPeriod>>('mini-fd-period', newCrPeriod)).data.result;
    }

    public async editCrPeriod(newCrPeriod: MiniFdPeriod): Promise<MiniFdPeriod> {
        return (await this.api.put<ApiResponse<MiniFdPeriod>>('mini-fd-period', newCrPeriod)).data.result;
    }

    public async getCrPeriods(): Promise<MiniFdPeriod[]> {
        return (await this.api.get<ApiResponse<GetMiniFdPeriodsResponse>>('mini-fd-period/list')).data.result.miniFdPeriods;
    }

    public async GetCrPeriod(mtpYear: number): Promise<MiniFdPeriod> {
        return (await this.api.get<ApiResponse<MiniFdPeriod>>('mini-fd-period/' + mtpYear)).data.result;
    }
    
    public async getMiniFdTags(miniFdId: string): Promise<Tag[]> {
        return (await this.api.get<ApiResponse<GetMiniFdTagsResponse>>(`mini-fd/list-tags?miniFdId=${miniFdId}`)).data.result.tags;
    }

    public async addMiniFdTags(payload: AddMiniFdTagsPayload): Promise<number> {
        return (await this.api.post<ApiResponse<{ tagsAdded: number }>>('mini-fd/add-tags', payload)).data.result.tagsAdded;
    }

    public async deleteMiniFdTags(payload: DeleteMiniFdTagsPayload): Promise<number> {
        return (await this.api.delete('mini-fd/delete-tags', { data: payload })).data.result.tagsDeleted;
    }
}
