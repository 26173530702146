import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { cellValueClass, tableCellTextFormat } from '~/utils/table-helper';
import { Data } from '~/utils';
import { formatToDecimals } from '~/utils/number-helper';
import { PuiTableVendorOptions } from '~/models/libraries/pebble-ui';
import { GetMiniFdByGlobalIdResponse } from '~/models/services/minifd';

interface CompensationTotals {
    mtpYearPlus1Total: number;
    mtpYearPlus2Total: number;
    mtpYearPlus3Total: number;
}

@Component({})
export default class ViewCompensationTableComponent extends Vue {
    @Prop()
    private iybrData?: GetMiniFdByGlobalIdResponse;
    private cellValueClass = cellValueClass;
    private tableCellTextFormat = tableCellTextFormat;

    private get columns(): Record<string, string> {
        return {
            globalId: 'Global ID',
            projectName: 'Project Name',
            justification: 'Justification',
            originalMtp: `Original ${this.iybrData?.budgetCompensations[0].selectedMtpYear + 1}`,
            compensated: `Compensated ${this.iybrData?.budgetCompensations[0].selectedMtpYear + 1}`,
            newMtp: `New ${this.iybrData?.budgetCompensations[0].selectedMtpYear + 1}`,
            originalMtpPlus1: `Original ${this.iybrData?.budgetCompensations[0].selectedMtpYear + 2}`,
            compensatedPlus1: `Compensated ${this.iybrData?.budgetCompensations[0].selectedMtpYear + 2}`,
            newMtpPlus1: `New ${this.iybrData?.budgetCompensations[0].selectedMtpYear + 2}`,
            originalMtpPlus2: `Original ${this.iybrData?.budgetCompensations[0].selectedMtpYear + 3}`,
            compensatedPlus2: `Compensated ${this.iybrData?.budgetCompensations[0].selectedMtpYear + 3}`,
            newMtpPlus2: `New ${this.iybrData?.budgetCompensations[0].selectedMtpYear + 3}`,
        };
    }

    private get formTotals(): CompensationTotals {
        let mtpYearPlus1Total = 0;
        let mtpYearPlus2Total = 0;
        let mtpYearPlus3Total = 0;

        this.iybrData?.budgetCompensations?.forEach(budgetCompensation => {
            mtpYearPlus1Total += budgetCompensation.requestedYearPlus1 ?? 0;
            mtpYearPlus2Total += budgetCompensation.requestedYearPlus2 ?? 0;
            mtpYearPlus3Total += budgetCompensation.requestedYearPlus3 ?? 0;
        });

        return {
            mtpYearPlus1Total,
            mtpYearPlus2Total,
            mtpYearPlus3Total
        };
    }

    private get tableOptions(): PuiTableVendorOptions {
        const budgetCompensationRows = this.iybrData?.budgetCompensations?.map(entry => ({
            [this.columns.globalId]: entry.globalId,
            [this.columns.projectName]: entry.projectName,
            [this.columns.justification]: this.getValueForConstant('justificationTypes', entry.justification),
            [this.columns.originalMtp]: entry.original1.toString(),
            [this.columns.compensated]: formatToDecimals(entry.requestedYearPlus1, 3).toString(),
            [this.columns.newMtp]: formatToDecimals(entry.yearPlus1, 3).toString(),
            [this.columns.originalMtpPlus1]: entry.original2.toString(),
            [this.columns.compensatedPlus1]: formatToDecimals(entry.requestedYearPlus2, 3).toString(),
            [this.columns.newMtpPlus1]: formatToDecimals(entry.yearPlus2, 3).toString(),
            [this.columns.originalMtpPlus2]: entry.original3.toString(),
            [this.columns.compensatedPlus2]: formatToDecimals(entry.requestedYearPlus3, 3).toString(),
            [this.columns.newMtpPlus2]: formatToDecimals(entry.yearPlus3, 3).toString()
        })) ?? [];

        const totalsRow = {
            [this.columns.globalId]: 'Total budget made available for the project whose approval is required',
            [this.columns.compensated]: this.formTotals.mtpYearPlus1Total,
            [this.columns.compensatedPlus1]: this.formTotals.mtpYearPlus2Total,
            [this.columns.compensatedPlus2]: this.formTotals.mtpYearPlus3Total
        };

        return {
            data: [
                ...budgetCompensationRows,
                totalsRow
            ],
            options: {
                sortable: []
            },
            columns: Object.values(this.columns)
        };
    }

    private getValueForConstant(constantType: string, index?: string | number): string {
        if (!index) {
            return 'N/A';
        }

        const constantValue = Data.Instance.constants[constantType][index];

        if (!constantValue) {
            return 'Unknown';
        }

        return constantValue;
    }
}
