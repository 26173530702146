import Vue from 'vue';
import Component from 'vue-class-component';
import { EventBus } from '~/utils/eventbus';

@Component({})
export default class MtpStatusFlaggedModalComponent extends Vue {
    private selectedYesChanges: boolean = false;

    constructor() {
        super();
    }

    private updateChanges() {
        this.selectedYesChanges = true;
        this.$emit('clickedupdateflagged', false);
        EventBus.$emit(EventBus.MTP_STATUS.UPDATE_FLAGGED_MODAL, this.selectedYesChanges);
    }

    private onClickButton() {
        this.$emit('clickedupdateflagged', false);
    }
}
