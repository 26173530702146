var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('pui-collapsible', {
    staticClass: "risk-collapsible",
    attrs: {
      "expandable": false,
      "headline": _vm.data.riskId + ' | Linked Risk'
    }
  }, [_c('pui-grid-container', [_c('pui-grid-row', [_c('pui-grid-column', {
    staticClass: "entry-column"
  }, [_c('pui-headline', {
    staticClass: "title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Risk title ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.riskTitle) + " ")])], 1), _c('pui-grid-column', {
    staticClass: "entry-column"
  }, [_c('pui-headline', {
    staticClass: "title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Risk status ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.riskStatus) + " ")])], 1), _c('pui-grid-column', {
    staticClass: "entry-column"
  }, [_c('pui-headline', {
    staticClass: "title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" English title short for FCM ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.englishTitleShortForFcm) + " ")])], 1), _c('pui-grid-column', {
    staticClass: "entry-column"
  }, [_c('pui-headline', {
    staticClass: "title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Strategy cost ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.data.strategyCost + _vm.data.currencySymbol) + " ")])], 1)], 1)], 1), _c('pui-table', {
    attrs: {
      "vendor-options": _vm.tableData
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }