var render = function () {
  var _vm$data$nextPlannedS, _vm$data$nextPlannedS2, _vm$data$lastMaintena, _vm$data$lastMaintena2;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('pui-collapsible', {
    staticClass: "maintenance-collapsible",
    attrs: {
      "expandable": false,
      "headline": _vm.data.maintenanceId + ' | Linked Maintenance'
    }
  }, [_c('pui-grid-container', [_c('pui-grid-row', [_c('pui-grid-column', {
    staticClass: "entry-column"
  }, [_c('pui-headline', {
    staticClass: "title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Maintenance Name ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.replaceNullText(_vm.data.maintenanceName)) + " ")])], 1), _c('pui-grid-column', {
    staticClass: "entry-column"
  }, [_c('pui-headline', {
    staticClass: "title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Maintenance Name (Local Language) ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.replaceNullText(_vm.data.maintenanceLocalLanguageName)) + " ")])], 1), _c('pui-grid-column', {
    staticClass: "entry-column"
  }, [_c('pui-headline', {
    staticClass: "title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Maintenance Activity ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.replaceNullText(_vm.data.maintenanceActivity)) + " ")])], 1), _c('pui-grid-column', {
    staticClass: "entry-column"
  }, [_c('pui-headline', {
    staticClass: "title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Planning & Controlling Classification ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.replaceNullText(_vm.data.planningAndControllingClassification)) + " ")])], 1), _c('pui-grid-column', {
    staticClass: "entry-column",
    attrs: {
      "cols": _vm.pageLayout.HALF_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Category ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.replaceNullText(_vm.data.category)) + " ")])], 1), _c('pui-grid-column', {
    staticClass: "entry-column",
    attrs: {
      "cols": _vm.pageLayout.HALF_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Justification ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.replaceNullText(_vm.data.justification)) + " ")])], 1), _c('pui-grid-column', {
    staticClass: "entry-column",
    attrs: {
      "cols": _vm.pageLayout.HALF_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Maintenance Type ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.replaceNullText(_vm.data.maintenanceType)) + " ")])], 1), _c('pui-grid-column', {
    staticClass: "entry-column",
    attrs: {
      "cols": _vm.pageLayout.HALF_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Outage Type ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.replaceNullText(_vm.data.outageType)) + " ")])], 1), _c('pui-grid-column', {
    staticClass: "entry-column",
    attrs: {
      "cols": _vm.pageLayout.HALF_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Project Type ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.replaceNullText(_vm.data.projectType)) + " ")])], 1), _c('pui-grid-column', {
    staticClass: "entry-column",
    attrs: {
      "cols": _vm.pageLayout.HALF_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Lump Sum ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.replaceNullText(_vm.data.lumpSum)) + " ")])], 1), _c('pui-grid-column', {
    staticClass: "entry-column",
    attrs: {
      "cols": _vm.pageLayout.HALF_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Next Planned Start Date ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDate((_vm$data$nextPlannedS = _vm.data.nextPlannedStartDate) === null || _vm$data$nextPlannedS === void 0 ? void 0 : _vm$data$nextPlannedS.toString())) + " ")])], 1), _c('pui-grid-column', {
    staticClass: "entry-column",
    attrs: {
      "cols": _vm.pageLayout.HALF_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Duration ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.replaceNullText((_vm$data$nextPlannedS2 = _vm.data.nextPlannedStartDateDuration) === null || _vm$data$nextPlannedS2 === void 0 ? void 0 : _vm$data$nextPlannedS2.toString())) + " ")])], 1), _c('pui-grid-column', {
    staticClass: "entry-column",
    attrs: {
      "cols": _vm.pageLayout.HALF_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Last Maintenance Date ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDate((_vm$data$lastMaintena = _vm.data.lastMaintenanceDate) === null || _vm$data$lastMaintena === void 0 ? void 0 : _vm$data$lastMaintena.toString())) + " ")])], 1), _c('pui-grid-column', {
    staticClass: "entry-column",
    attrs: {
      "cols": _vm.pageLayout.HALF_ROW
    }
  }, [_c('pui-headline', {
    staticClass: "title",
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Duration ")]), _c('pui-headline', {
    attrs: {
      "type": "h5"
    }
  }, [_vm._v(" " + _vm._s(_vm.replaceNullText((_vm$data$lastMaintena2 = _vm.data.lastMaintenanceDateDuration) === null || _vm$data$lastMaintena2 === void 0 ? void 0 : _vm$data$lastMaintena2.toString())) + " ")])], 1)], 1)], 1), _c('pui-table', {
    attrs: {
      "vendor-options": _vm.showAdjacentCostTable ? _vm.adjacentYearsTableData : _vm.totalCostTableData
    }
  }), _c('pui-button', {
    staticClass: "switch-button",
    attrs: {
      "small": true
    },
    on: {
      "click": _vm.toggleTable
    }
  }, [_vm._v(" " + _vm._s(_vm.showAdjacentCostTable ? 'Switch to Total Cost' : 'Switch to Adjacent Cost') + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }