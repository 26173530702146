import { Connection } from '~/utils/';
import { FleetItem, GetFleetResponse } from '~/models/services/fleet';
import { ApiResponse } from '~/models/services/common';
import { CacheContainer } from '~/utils/cache-container'

export class FleetService {
    private readonly api = new Connection();

    private readonly fleetCache = new CacheContainer<string, FleetItem[]>()

    public async getFleet(): Promise<FleetItem[]> {
        const cacheKey = 'fleet'

        if (this.fleetCache.hasCachedValue(cacheKey)) {
            return this.fleetCache.getCachedValue(cacheKey);
        }

        const fleetItems = (await this.api.get<ApiResponse<GetFleetResponse>>('master-data/fleet/list')).data.result.fleet;
        this.fleetCache.storeCachedValue(cacheKey, fleetItems);

        return fleetItems;
    }
}
