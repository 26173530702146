var render = function () {
  var _ref;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "opportunity-budget-request"
  }, [_c('div', {
    staticClass: "accordion-connection"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header"
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_c('button', {
    staticClass: "btn"
  }, [_vm._v(" " + _vm._s(_vm.opportunity.id) + " ")])])])]), _c('div', {
    staticClass: "budget-request-wrapper"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-row"
  }, [_c('p', {
    staticClass: "col-xl-12"
  }, [_c('b', [_vm._v("Opportunity title: ")]), _vm._v(" " + _vm._s(_vm.opportunity.data.opportunityTitle !== null && _vm.opportunity.data.opportunityTitle !== "" ? _vm.opportunity.data.opportunityTitle : "-") + " ")])]), _c('div', {
    staticClass: "form-row"
  }, [_c('p', {
    staticClass: "col-xl-12"
  }, [_c('b', [_vm._v("Status:")]), _vm._v(" " + _vm._s(_vm.opportunity.data.opportunityStatus !== null && _vm.opportunity.data.opportunityStatus !== "" ? _vm.opportunity.data.opportunityStatus : "-") + " ")])]), _c('div', {
    staticClass: "form-row"
  }, [_c('p', {
    staticClass: "col-xl-12"
  }, [_c('b', [_vm._v("Investment cost: ")]), _vm._v(" " + _vm._s(_vm.opportunity.data.investmentCost !== null ? (_ref = _vm.opportunity.data.investmentCost + _vm.opportunity.data.currencySymbol) !== null && _ref !== void 0 ? _ref : '' : "-") + " ")])]), _c('div', {
    staticClass: "form-row"
  }, [_c('p', {
    staticClass: "col-xl-12"
  }, [_c('b', [_vm._v("S-IRR:")]), _vm._v(" " + _vm._s(_vm.opportunity.data.irr) + "% ")])]), _vm.opportunity.data.uEL ? _c('div', {
    staticClass: "form-row"
  }, [_c('p', {
    staticClass: "col-xl-12"
  }, [_c('strong', [_vm._v("Useful Economic Life:")]), _vm._v(" " + _vm._s(_vm.opportunity.data.uEL) + " year(s) ")])]) : _vm._e(), _c('div', {
    staticClass: "form-row"
  }, [_c('p', {
    staticClass: "col-xl-12"
  }, [_c('b', [_vm._v("Evaluation period: ")]), _vm._v(" " + _vm._s(_vm.opportunity.data.evaluationPeriod !== null ? _vm.opportunity.data.evaluationPeriod + " years" : '-') + " ")])]), _c('div', {
    staticClass: "form-row border-row"
  }, [_c('p', {
    staticClass: "col-xl-12"
  }, [_c('b', [_vm._v("Payback time: ")]), _vm._v(" " + _vm._s(_vm.opportunity.data.paybackTime !== null ? _vm.opportunity.data.paybackTime + " years" : '-') + " ")])]), _vm._m(0), _c('div', {
    staticClass: "text-right"
  }, [_c('button', {
    staticClass: "btn btn-primary mb-4",
    on: {
      "click": function ($event) {
        return _vm.emitCopy(_vm.elementIndex);
      }
    }
  }, [_vm._v(" Copy ")])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "text-left mb-2"
  }, [_c('small', {
    staticClass: "form-text text-muted"
  }, [_c('i', [_vm._v("When you choose to copy, the opportunity details will be copied over from PTRisk to the budget request form. ")])])]);
}]

export { render, staticRenderFns }