var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "accordion-periods",
    attrs: {
      "id": "accordion"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header",
    attrs: {
      "id": "heading_accordion"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_c('button', {
    staticClass: "btn",
    attrs: {
      "aria-controls": "collapseOne",
      "aria-expanded": "true",
      "data-target": "#collapseOne",
      "data-toggle": "collapse"
    }
  }, [_vm._v(" " + _vm._s(_vm.getPeriodConfigPropertyValue('tableName')) + " "), _c('icon', {
    attrs: {
      "name": "chevron-down"
    }
  })], 1)])])]), _c('div', {
    staticClass: "collapse show budget-request-wrapper",
    attrs: {
      "id": "collapseOne",
      "aria-labelledby": "heading_accordion",
      "data-parent": "#accordion"
    }
  }, [_c('div', {
    staticClass: "card-body pb-0"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm.periods ? _c('table', {
    staticClass: "table mb-0"
  }, [!_vm.loading ? _c('thead', [_c('tr', [_c('th', [_vm._v("Action")]), _c('th', [_vm._v(_vm._s(_vm.getPeriodConfigPropertyValue('tableHeaderYear')))]), _c('th', [_vm._v(_vm._s(_vm.getPeriodConfigPropertyValue('tableHeaderStartDate')))]), _c('th', [_vm._v(_vm._s(_vm.getPeriodConfigPropertyValue('tableHeaderEndDate')))])])]) : _vm._e(), !_vm.loading ? _c('tbody', _vm._l(_vm.periods, function (el, key) {
    return _c('tr', {
      key: key
    }, [_c('td', [_vm.hasPermissionToEdit ? _c('button', {
      staticClass: "btn btn-edit",
      attrs: {
        "disabled": !_vm.isEditEnabledForYear(el.year)
      },
      on: {
        "click": function ($event) {
          _vm.editPeriod(el[_vm.getPeriodConfigPropertyValue('editProperty')], el.year);
        }
      }
    }, [_c('icon', {
      staticClass: "icon-edit",
      attrs: {
        "name": "pencil-alt"
      }
    }), _c('span', [_vm._v("Edit")])], 1) : _vm._e()]), _c('td', [_vm._v(_vm._s(el.year))]), _c('td', [_vm._v(_vm._s(el.startDate))]), _c('td', [_vm._v(_vm._s(el.endDate))])]);
  }), 0) : _vm._e()]) : _vm._e(), _vm.periods.length === 0 ? _c('div', {
    staticClass: "table pl-3 pt-3 pb-3"
  }, [_vm._v(" " + _vm._s(_vm.getPeriodConfigPropertyValue('noPeriodsFound')) + " ")]) : _vm._e()])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }