import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { SelectOption } from '~/utils/interfaces';

@Component({})
export default class TableHeaderComponent extends Vue {
    private readonly searchFieldId = 'search-field';
    private readonly PAGE_SIZE_OPTIONS: SelectOption[] = [
        {
            label: '5',
            value: 5,
        },
        {
            label: '10',
            value: 10,
        },
        {
            label: '20',
            value: 20,
        },
        {
            label: '50',
            value: 50,
        },
        {
            label: '100',
            value: 100,
        },
    ];

    /**
     * This prop is required to know the page size.
     */
    @Prop({ required: false, default: () => '' })
    private searchTerm!: string;

    /**
     * This prop is required to know the page size.
     */
    @Prop({ required: true })
    private pageSize!: number;

    /**
     * This prop is used to hide the search bar.
     */
    @Prop(Boolean)
    private hideSearch!: boolean;

    private tableExtraFilters = {
        pageSize: this.pageSize,
        searchTerm: this.searchTerm,
    }

    private get hasSearchTerm(): boolean {
        return this.tableExtraFilters.searchTerm.trim().length >= 1;
    }

    private mounted(): void {
        const inputElement = this.$el.querySelector(`#${this.searchFieldId}`);

        // Typescript not recognizing correct event type.
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        inputElement?.addEventListener('keydown', (event: KeyboardEvent) => {
            if (event.key === 'Enter' && this.hasSearchTerm) {
                this.onTriggerSearch();
            }
        });
    }

    private onPageSizeChanged(): void {
        this.$emit('page-size-changed', this.tableExtraFilters.pageSize);
    }

    private onTriggerSearch(): void {
        if (this.hasSearchTerm) {
            this.$emit('search-triggered', this.tableExtraFilters.searchTerm.trim());
        }
    }

    private onClearSearchTerm(): void {
        if (this.hasSearchTerm) {
            this.tableExtraFilters.searchTerm = '';
        }

        this.$emit('clear-search-term');
    }
}