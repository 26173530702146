var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('app-drawer', {
    ref: "appDrawer",
    domProps: {
      "data": _vm.applications,
      "labels": _vm.appDrawerLabels,
      "onClick": _vm.handleAppClick
    }
  }, [_c('span', {
    staticClass: "app-logo",
    attrs: {
      "slot": "appLogo"
    },
    slot: "appLogo"
  }, [_c('img', {
    attrs: {
      "alt": "COODE logo",
      "src": "assets/img/logo-coode-rgb.svg"
    }
  }), _c('img', {
    attrs: {
      "alt": "Enerlytics logo",
      "src": "assets/img/logo-enerlytics-rgb.svg"
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }