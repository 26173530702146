var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('pui-lightbox', {
    ref: _vm.REFS.LIGHTBOX,
    attrs: {
      "on-confirm-callback": _vm.onLightboxConfirm,
      "fit-content": "",
      "default-footer-cancel-label": "Cancel",
      "default-footer-cancel-disabled": _vm.hasSubmitted,
      "default-footer-confirm-label": "Remove",
      "default-footer-confirm-disabled": !_vm.isFormValid || _vm.hasSubmitted,
      "default-header-label": "Remove Change Request from MTP",
      "show-lightbox-close-icon": !_vm.hasSubmitted
    },
    on: {
      "puilightbox:before-close": _vm.onLightboxBeforeClose
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('pui-loader', {
          attrs: {
            "promise": _vm.pendingPromise
          }
        }, [_c('p', [_vm._v("Please define whether the selected items should be retained or deleted.")]), _c('p', [_vm._v("Are you sure you want to proceed?")]), _c('pui-grid-container', [_c('pui-form', [_c('div', {
          directives: [{
            name: "pui-form-grid-row",
            rawName: "v-pui-form-grid-row"
          }]
        }, [_c('pui-form-radio', {
          directives: [{
            name: "pui-form-grid-column",
            rawName: "v-pui-form-grid-column",
            value: _vm.ROWS.HALF_ROW,
            expression: "ROWS.HALF_ROW"
          }],
          attrs: {
            "label": "Set as Inactive",
            "is-disabled": !_vm.isActive,
            "value": _vm.DELETION_TYPE.SET_AS_INACTIVE
          },
          model: {
            value: _vm.selectedDeletionType,
            callback: function ($$v) {
              _vm.selectedDeletionType = $$v;
            },
            expression: "selectedDeletionType"
          }
        }), _c('pui-form-radio', {
          directives: [{
            name: "pui-form-grid-column",
            rawName: "v-pui-form-grid-column",
            value: _vm.ROWS.HALF_ROW,
            expression: "ROWS.HALF_ROW"
          }],
          attrs: {
            "label": "Delete",
            "value": _vm.DELETION_TYPE.DELETE
          },
          model: {
            value: _vm.selectedDeletionType,
            callback: function ($$v) {
              _vm.selectedDeletionType = $$v;
            },
            expression: "selectedDeletionType"
          }
        })], 1)])], 1)], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }