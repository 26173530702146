import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Config, Plants } from './plants-structure';
import { Data, User } from '~/utils';
import { PuiNavigationToolbackClickEvent } from '~/models/libraries/pebble-ui';

export interface LinkItem {
    displayName: string;
    href: string;
    isActive: boolean;
}

@Component({})
export default class NavmenuComponent extends Vue {
    private readonly OVERVIEW_PATH = '/overview';
    private readonly BUDGET_REQUESTS_PATH = '/budget-requests';
    private readonly IN_YEAR_BUDGET_REQUESTS_PATH = '/in-year-budget-requests';
    private readonly REPORTING_PATH = '/reporting';
    private readonly LIVE_PML_PATH = '/live-pml';
    private readonly PROJECT_PORTFOLIO_PATH = '/project-portfolio';
    private readonly LABEL_SCOPE = 'medium';
    private readonly MAX_VISIBLE_LIST_ITEMS = 10;
    private readonly SELECTED_PLANT_GROUP_SID = 'selectedPlantGroupSid';

    private userPermissions = <any>[];
    private plantStructure?: Plants = null;
    private data = Data.Instance;

    private get linkItems(): LinkItem[] {
        const linkItems: LinkItem[] = [
            {
                displayName: 'Overview',
                href: this.OVERVIEW_PATH,
                isActive: this.isCurrentPath(this.OVERVIEW_PATH),
            },
            {
                displayName: 'Budget Requests',
                href: this.BUDGET_REQUESTS_PATH,
                isActive: this.isCurrentPath(this.BUDGET_REQUESTS_PATH),
            },
            {
                displayName: 'Change Requests',
                href: this.IN_YEAR_BUDGET_REQUESTS_PATH,
                isActive: this.isCurrentPath(this.IN_YEAR_BUDGET_REQUESTS_PATH),
            },
            {
                displayName: 'Reporting',
                href: this.REPORTING_PATH,
                isActive: this.isCurrentPath(this.REPORTING_PATH),
            },
            {
                displayName: 'Live PML',
                href: this.LIVE_PML_PATH,
                isActive: this.isCurrentPath(this.LIVE_PML_PATH),
            },
        ];

        // if (this.userPermissions.includes('ProjectPortfolioEdit')) {
        if (this.userPermissions.includes('SingleSitePageView')) {
            linkItems.push({
                displayName: 'Project Portfolio',
                href: this.PROJECT_PORTFOLIO_PATH,
                isActive: this.isCurrentPath(this.PROJECT_PORTFOLIO_PATH),
            })
        }

        return linkItems;
    }

    private get shouldDisplayStructureSelect(): boolean {
        return this.isBudgetRequestPath();
    }

    async created(): Promise<void> {
        this.userPermissions = await User.getPerm();
        await this.getPlantsStructure();
        if (localStorage.selectedPlantGroupSid) {
            await this.handleAssetGroupSelection(localStorage.selectedPlantGroupSid);
        } else {
            await this.handleAssetGroupSelection(
                this.plantStructure.config.options[0].options[0].id
            );
        }
    }

    private async handleAssetGroupSelection(id: string): Promise<void> {
        const [fleetSid, assetGroupSid] = id.split(';');
        await this.data.loadPlantsByFleetAndAssetGroupSid(fleetSid, assetGroupSid);
        this.plantStructure.selectedId = id;
        localStorage.setItem(this.SELECTED_PLANT_GROUP_SID, id);
        this.$root.$emit('plant-structure-changed');
    }

    private handleClick(event: PuiNavigationToolbackClickEvent): void {
        if (!this.isCurrentPath(event.href)) {
            this.$router.push(event.href);
        }
    }

    private isCurrentPath(path: string): boolean {
        return path === this.$route.path;
    }

    private isBudgetRequestPath(): boolean {
        return (
            this.$route.path === '/budget-requests' ||
            this.$route.path === '/in-year-budget-requests' ||
            this.$route.path === this.PROJECT_PORTFOLIO_PATH
        );
    }

    private async getPlantsStructure(): Promise<Plants> {
        await this.data.loadFleetHierarchyFromServer();

        let config: Config = {
            placeholder: 'Please select an option',
            listPlaceholders: [
                'Please select a level 1 option',
                'Please select a level 2 option',
            ],
            options: this.data.loadedFleetStructure,
        };

        this.plantStructure = new Plants(
            config,
            this.LABEL_SCOPE,
            config.options[0].options[0].id,
            this.MAX_VISIBLE_LIST_ITEMS
        );

        // Sort plant groups alphabetically for each technology
        this.plantStructure.config.options.forEach((plantGroup) => {
            if (plantGroup.options) {
                plantGroup.options.sort((a, b) => (a.text.toLowerCase() > b.text.toLowerCase()) ? 1 : -1);
            }
        });

        return this.plantStructure;
    }
}
