import { UAMUser } from '~/models/services/uam';
import { Connection } from '~/utils';
import { ApiPaginatedResponse, ApiResponse } from '~/models/services/common';
import Vue from 'vue';

export class UAMService {
    private readonly api = new Connection(Vue.prototype.$sdk.$http.$core.userAccessManagement.request.api);
    private readonly useCaseId = process.env.VUE_APP_USE_CASE_ID;

    public async SearchUsers(data: string): Promise<UAMUser[]> {
        return (await this.api.get<ApiResponse<ApiPaginatedResponse<UAMUser>>>(`users/use-cases/${this.useCaseId}?Term=${data}`)).data.result.items;
    }
}
