






















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { ROWS } from '~/components/in-year-budget-requests/constants/rows';
import { PuiLightbox } from '~/models/libraries/pebble-ui';
import { GlobalId } from '~/models/services/project-portfolio';
import { ProjectPortfolioService } from '~/services/project-portfolio';
import { Data } from '~/utils';
import { PuiFormMultiselectOption, SelectOption } from '~/utils/interfaces';

interface FormData {
    plant: {
        value: string;
    };
    globalIds: {
        loading: boolean;
        value: PuiFormMultiselectOption<string>[];
        queried: GlobalId[];
    };
}

const REF_CONSTANTS = {
    LIGHTBOX: 'lightbox',
} as const;

@Component({})
export default class LinkBudgetRequestsLightboxComponent extends Vue {
    private projectPortfolioService: ProjectPortfolioService = new ProjectPortfolioService();
    private data = Data.Instance;
    
    // Intentionally left empty so the promise will never resolve.
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private infinitePromise = new Promise(() => {});

    private readonly REF_CONSTANTS = REF_CONSTANTS;
    $refs!: {
        [REF_CONSTANTS.LIGHTBOX]: PuiLightbox;
    };

    private readonly formLayout = ROWS;
    private formData = LinkBudgetRequestsLightboxComponent.initialFormData;
    private isFormSubmitting: boolean = false;

    @Prop()
    private lightboxOpen!: boolean;

    @Prop()
    private mtpYear!: number;

    @Watch('lightboxOpen')
    onLightboxOpenChanged(value: boolean): void {
        value ? this.open() : this.close();
    }

    private static get initialFormData(): FormData {
        return {
            plant: {
                value: null,
            },
            globalIds: {
                loading: false,
                value: new Array<PuiFormMultiselectOption<string>>(),
                queried: new Array<GlobalId>(),
            }
        };
    }

    private get isFormDisabled(): boolean {
        return this.formData.globalIds.value.length === 0 || this.isFormSubmitting
    }

    private open(): void {
        this.$refs[REF_CONSTANTS.LIGHTBOX].open();
    }

    private close(): void {
        this.$refs[REF_CONSTANTS.LIGHTBOX].close();
    }

    private emitClose(): void {
        this.$emit('close-lightbox');
        this.resetSelectedPlant();
        this.resetSelectedGlobalIds();
    }

    private resetSelectedGlobalIds(): void {
        this.formData.globalIds = LinkBudgetRequestsLightboxComponent.initialFormData.globalIds;
    }

    private resetSelectedPlant(): void {
        this.formData.plant = LinkBudgetRequestsLightboxComponent.initialFormData.plant;
    }

    private async lightboxConfirm(): Promise<void> {
        this.isFormSubmitting = true;

        if (!this.mtpYear || this.formData.globalIds.value.length === 0) {
            return;
        }

        const globalIds: string[] = this.formData.globalIds.value.map(option => option.value);

        try {
            const response = await this.projectPortfolioService.getCombinedData(globalIds, this.mtpYear);
            this.$router.push({
                name: 'linkedBudgetRequestEdit',
                params: {
                    combinedData: response,
                    id: 'new',
                },
            });
        } catch {
            this.$pui.toast({
                type: 'error',
                title: 'Failed',
                copy: 'Something went wrong.',
            });
        } finally {
            this.isFormSubmitting = false;
        }
    }

    private async handleGlobalIdSearch(globalIdQuery: string): Promise<void> {
        if (!globalIdQuery || !this.formData.plant.value || !this.mtpYear) {
            return;
        }

        const plantSid = this.formData.plant.value;
        const mtpYear = this.mtpYear;

        this.formData.globalIds.loading = true;
        this.formData.globalIds.queried = await this.projectPortfolioService.searchGlobalId(plantSid, mtpYear, globalIdQuery);
        this.formData.globalIds.loading = false;
    }

    private get globalIdOptions(): PuiFormMultiselectOption<string>[] {
        return this.formData.globalIds.queried
            .slice(0, 10)
            .map(this.mapSearchResultToOption);
    }

    private mapSearchResultToOption(searchResult: GlobalId): PuiFormMultiselectOption<string> {
        return {
            label: searchResult.globalId,
            secondaryLabel: searchResult.name,
            value: searchResult.id
        };
    }

    private handlePlantChange() {
        this.resetSelectedGlobalIds();
    }

    private get plantOptions(): SelectOption[] {
        return this.data.loadedPlants.map((plant) => ({
            label: plant.plantName,
            value: plant.plantSid,
        }));
    }
}
