var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "flex-row flex-left"
  }, [_c('pui-tooltip'), _c('pui-filter', {
    attrs: {
      "config": _vm.filterConfig,
      "use-router": false,
      "vuex-namespace": "ns1"
    },
    on: {
      "changed:applied-values": _vm.onFilterValueChanged
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }