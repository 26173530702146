var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('router-link', {
    attrs: {
      "to": {
        path: _vm.href,
        query: _vm.query
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }