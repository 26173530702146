import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { MasterService } from '~/services/master-service';
import FileCardDisplayComponent from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/files-change-request/file-card-display/file-card-display.vue';
import { FILE_EVENTS } from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/files-change-request/files-change-request-utils';
import { FileResult } from '~/models/services/file';
import { PuiFormFile } from '~/models/libraries/pebble-ui';

@Component({
    components: {
        fileCardDisplay: FileCardDisplayComponent,
    },
})
export default class FilesChangeRequestComponent extends Vue {
    @Prop()
    miniFdId: string | null;

    @Prop()
    year: number;

    @Prop()
    isEditPage: boolean;

    @Prop()
    isViewPage: boolean;

    private readonly miniFdService = MasterService.instance.miniFdService;
    private readonly ADD_FILE_BUTTON_REF = 'addFileButton';

    private changeRequestFiles: FileResult[] = [];
    private toBeUploadedFiles: File[] = [];
    private isUploading = false;
    private isDeleting = false;

    mounted(): void {
        this.$on(FILE_EVENTS.UPLOAD_FILES_EVENT, this.uploadFiles);
    }

    public async onAddFilesButtonClick(event: File[]): Promise<void> {
        if (event.length > 0) {
            if (!this.checkIfFileNameAlreadyExists(event[0].name)) {
                if (this.isEditPage) {
                    let file = new FormData();
                    file.append('MiniFdId', this.miniFdId);
                    file.append('File', event[0]);
                    try {
                        this.isUploading = true;
                        await this.miniFdService.addFile(file);
                        this.isUploading = false;
                        await this.populateFilesData();
                    } catch {
                        (
                            this.$refs[this.ADD_FILE_BUTTON_REF] as PuiFormFile
                        ).filesList = [];
                        this.isUploading = false;
                        this.$bvToast.show('toast-error-file-upload');
                    }
                } else if (
                    this.checkFileExtension(
                        '.' + event[0].name.split('.').pop()
                    )
                ) {
                    this.toBeUploadedFiles.push(event[0]);
                    this.changeRequestFiles.push({
                        name: event[0].name,
                        extension: '.' + event[0].name.split('.').pop(),
                    });
                } else {
                    this.$bvToast.show('toast-invalid-file-extension');
                }
            } else {
                this.$bvToast.show(
                    'toast-error-change-request-file-name-already-exists'
                );
            }
            // Reinitialise file input component
            (this.$refs[this.ADD_FILE_BUTTON_REF] as PuiFormFile).filesList = [];
        }
    }

    @Watch('miniFdId')
    private async populateFilesData(): Promise<void> {
        if (!this.miniFdId) {
            return;
        }

        if (!this.isEditPage && this.isViewPage === undefined) {
            await this.uploadFiles(this.miniFdId);
            this.$emit('files-uploaded');
        } else if (this.miniFdId && this.year) {
            const response = await this.miniFdService.getFiles(
                this.miniFdId,
                this.year
            );
            this.changeRequestFiles = response.files;
        }
    }

    private async uploadFiles(miniFdId: string): Promise<void> {
        if (this.toBeUploadedFiles.length > 0) {
            for (const file of this.toBeUploadedFiles) {
                let changeRequestFile = new FormData();
                changeRequestFile.append('MiniFdId', miniFdId);
                changeRequestFile.append('File', file);
                const response = await this.miniFdService.addFile(changeRequestFile);
            }
        }
        this.$off(FILE_EVENTS.UPLOAD_FILES_EVENT, this.uploadFiles);
    }

    private async downloadFile(fileName: string, fileId: string): Promise<void> {
        try {
            const response = await this.miniFdService.downloadFile(fileId);
            this.forceFileDownload(fileName, response);
        } catch {
            this.$bvToast.show('toast-error-download-change-request-file');
        }
    }

    private async deleteFile(fileId: string): Promise<void> {
        try {
            this.isDeleting = true;
            await this.miniFdService.deleteFile(fileId);
            this.changeRequestFiles.forEach((file, index) => {
                if (file.id === fileId)
                    this.changeRequestFiles.splice(index, 1);
            });
            this.isDeleting = false;
            this.$bvToast.show('toast-successful-delete-change-request-file');
        } catch {
            this.$bvToast.show('toast-error-delete-change-request-file');
        }
    }

    private deleteFileLocal(fileName: string): void {
        this.toBeUploadedFiles.forEach((file, index) => {
            if (file.name === fileName) {
                this.toBeUploadedFiles.splice(index, 1);
                this.changeRequestFiles.splice(index, 1);
            }
        });
    }

    private checkIfFileNameAlreadyExists(fileName: string): boolean {
        for (let i = 0; i < this.changeRequestFiles.length; i++) {
            let currentFileName = '';
            if (this.isEditPage) {
                currentFileName =
                    this.changeRequestFiles[i].name +
                    this.changeRequestFiles[i].extension;
            } else {
                currentFileName = this.changeRequestFiles[i].name;
            }
            if (currentFileName === fileName) {
                return true;
            }
        }
        return false;
    }

    private checkFileExtension(fileExtension: string): boolean {
        const validExtensions = ['.pdf', '.doc', '.dot', '.wbk', '.docx',
            '.docm', '.dotx', '.dotm', '.docb', '.odt', '.xls', '.xls',
            '.xlt', '.xlm', '.xlsx', '.xlsm', '.xltx', '.xltm', '.xlsb',
            '.xla', '.xlam', '.xll', '.xlw', '.ppt', '.pot', '.pps', '.pptx',
            '.pptm', '.potx', '.potm', '.ppam', '.ppsx', '.ppsm', '.sldx', '.sldm'];
        return validExtensions.includes(fileExtension);
    }

    private forceFileDownload(name: string, data: BlobPart): void {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
    }
}

