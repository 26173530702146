import Component from 'vue-class-component';
import Vue from 'vue';
import { ROWS } from '~/components/in-year-budget-requests/constants/rows';
import { DepartmentGroups, SelectedStakeholder, Stakeholder } from '~/models/services/minifd';
import { Prop, Watch } from 'vue-property-decorator';
import { EventBus } from '~/utils';
import { DEPARTMENT_GROUP_NAMES } from '~/components/in-year-budget-requests/constants/department-group-names';
import { MasterService } from '~/services/master-service';
import { SelectOptions } from '~/components/in-year-budget-requests/add-in-year-budget-requests-modal/options';
import { UAMUser } from '~/models/services/uam';
import { FORM_EVENTS } from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/iybr-new-project-collapsible/create-iybr-form-utils';

@Component({})
export default class OptionalNotifiableUsersComponent extends Vue {
    @Prop()
    editPageListOfNotifiableUsers: Stakeholder[];

    private readonly FORM_LAYOUT = ROWS;

    private readonly uamService = MasterService.instance.uamService;
    private readonly MAX_NUMBER_OF_RESULTS = 5;

    private searchResults: SelectOptions[] = [];
    private isFormLoading = false;
    private listOfNotifiableUsers: SelectedStakeholder[] = [];
    private nonReviewerDepartmentId: string = '';
    private typeAheadComponentKey: number = Date.now();

    private mounted() {
        EventBus.$on(EventBus.CHANGE_REQUEST.GET_DEPARTMENTS_ID, this.getDepartmentId);
    }

    private async listUsers(input: string): Promise<void> {
        this.clearSearchResults();
        if (input) {
            this.isFormLoading = true;
            const users = await this.uamService.SearchUsers(input);
            this.isFormLoading = false;
            if (users) {
                this.searchResults = users.slice(0, this.MAX_NUMBER_OF_RESULTS).map((user) => ({
                    label: user.name,
                    value: user.kid
                }));
            }
        }
    }

    private addUserToList(selectedUser: SelectOptions[]): void {
        if (selectedUser.length > 0) {
            if (!this.listOfNotifiableUsers.some(user => user.kId === selectedUser[0].value)) {
                const selectedNotifiableUser: SelectedStakeholder = {
                    name: selectedUser[0].label,
                    kId: selectedUser[0].value,
                    departmentId: this.nonReviewerDepartmentId,
                    isReviewer: false
                };
                this.listOfNotifiableUsers.push(selectedNotifiableUser);
                this.$emit(FORM_EVENTS.NOTIFIABLE_USER_LIST_UPDATE_EVENT, this.listOfNotifiableUsers);
                this.typeAheadComponentKey = Date.now();
            }
        }
    }

    private deleteUserFromList (userKid: string): void {
        if (userKid) {
            this.listOfNotifiableUsers.splice(this.listOfNotifiableUsers.findIndex(user => user.kId === userKid), 1);
            this.$emit(FORM_EVENTS.NOTIFIABLE_USER_LIST_UPDATE_EVENT, this.listOfNotifiableUsers);
        }
    }

    private clearSearchResults(): void {
        this.searchResults = [];
    }


    private getDepartmentId(departments: DepartmentGroups[]) {
        const nonReviewerDepartment = departments.find((department) => {
            return department.name === DEPARTMENT_GROUP_NAMES.NON_REVIEWERS;
        });
        if (nonReviewerDepartment && nonReviewerDepartment.departments.length > 0) {
            this.nonReviewerDepartmentId = nonReviewerDepartment.departments[0].id;
        }
    }

    @Watch('editPageListOfNotifiableUsers')
    private populateListFromEdit() {
        if (this.editPageListOfNotifiableUsers) {
            this.listOfNotifiableUsers = this.editPageListOfNotifiableUsers.map((user) => ({
                kId: user.kId,
                name: user.name,
                departmentId: user.departmentId,
                isReviewer: false
            }));
            this.$emit(FORM_EVENTS.NOTIFIABLE_USER_LIST_UPDATE_EVENT, this.listOfNotifiableUsers);
        }
    }

    beforeDestroy() {
        EventBus.$off(EventBus.CHANGE_REQUEST.GET_DEPARTMENTS_ID, this.getDepartmentId);
    }

}