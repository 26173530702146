var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('pui-loader-error', {
    attrs: {
      "buttons": [{
        state: 'secondary',
        label: 'refresh',
        onClick: _vm.retryLoadingMasterData
      }],
      "icon": "error-alert",
      "message": "Failed to pull asset information from Master Data service.",
      "title": "An error has occurred."
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }