import Vue from 'vue';
import Component from 'vue-class-component';
import { Data, Helper, User } from '~/utils';
import { FileService } from '~/services/file-service';
import { Prop, Watch } from 'vue-property-decorator';
import moment from 'moment-timezone';
import { GlobalDocumentService } from '~/services/global-document-service';
import AddFilesModalComponent from '~/components/data-input/add-files-modal/add-files-modal.vue';

@Component({
    components: {
        'files-budget-request': FilesBudgetRequestsComponent,
        'add-files-modal': AddFilesModalComponent
    }
})
export default class FilesBudgetRequestsComponent extends Vue {
    private loading: boolean = false;
    private data: Data = Data.Instance;
    private FileService: FileService = new FileService();
    private GlobalDocumentService: GlobalDocumentService = new GlobalDocumentService();
    private userPermissions = <any>[];
    private errorMessage = null;
    private fileName = null;
    private fileId = null;
    private fileApplicationType = null;
    private objFiles = {
        plantIds: [],
        pageNo: 1
    };
    private files = <any>{
        files: [],
        total: null
    };
    private firstElementTable: number = 1;
    private elementPerPage = null;
    private file = null;
    @Prop()
    private isActive: boolean;
    @Prop()
    private selectedMtpFilter: number;
    @Prop()
    private isOverviewPage: boolean;
    private currentYear = new Date(Date.now()).getFullYear();

    constructor() {
        super();
    }

    async created() {
        this.userPermissions = await User.getPerm();
    }

    @Watch('data.selectedPlant', { immediate: true, deep: true })
    async onSelectedPlantChanged(val: string, oldVal: string) {
        if (val != null && (this.$route.path === '/overview' || this.$route.path === '/budget-requests')) {
            this.objFiles.pageNo = 1;
            await this.getFiles();
        }
    }

    @Watch('selectedMtpFilter')
    async onSelectedMtpPeriod() {
        await this.getFiles();
    }

    public async getFiles() {
        if (this.selectedMtpFilter === null || this.data.selectedPlant === null) {
            return;
        }
        if (this.data.constants) {
            this.elementPerPage = this.data.constants.filesPerPage;
        }
        this.objFiles.plantIds = this.data.selectedPlant.map(plant => plant.plantSid);
        if (this.isOverviewPage) {
            this.files = await this.GlobalDocumentService.getFiles(this.selectedMtpFilter, this.objFiles.pageNo);
            if ($('.btn').hasClass('collapsed')) {
                (this.$refs['toggleButton'] as any).click();
            }
        } else if (parseInt(localStorage.selectedMtp) !== 0) {
            const detailsFiles = {
                plantIds: this.objFiles.plantIds,
                year: parseInt(localStorage.selectedMtp) || this.currentYear,
                pageNo: this.objFiles.pageNo
            };
            this.files = await this.FileService.getFiles(detailsFiles);
        }
    }

    public async changeNavPageBack(): Promise<void> {
        if (this.objFiles.pageNo !== 1) {
            this.objFiles.pageNo = this.objFiles.pageNo - 1;
            this.firstElementTable = this.firstElementTable - this.elementPerPage;
            this.loading = true;
            await this.getFiles();
            let elmnt = document.getElementById('accordion-files');
            elmnt.scrollIntoView({ block: 'center' });
            this.loading = false;
        }
    }

    public async changeNavPageNext(): Promise<void> {
        if (Math.ceil(parseInt(this.files.total) / this.elementPerPage) > this.objFiles.pageNo) {
            this.objFiles.pageNo = this.objFiles.pageNo + 1;
            this.firstElementTable = this.firstElementTable + this.files.files.length;
            this.loading = true;
            await this.getFiles();
            let elmnt = document.getElementById('accordion-files');
            elmnt.scrollIntoView({ block: 'center' });
            this.loading = false;
        }
    }

    private async deleteFile() {
        $('#deleteFileModal').modal('hide');
        this.$bvToast.hide('toast-error-delete-file');
        try {
            this.data.deleteFile = true;
            if (this.files && ((this.files.total - 1) / this.elementPerPage) === this.objFiles.pageNo - 1) {
                if (this.objFiles.pageNo !== 1) {
                    this.objFiles.pageNo = this.objFiles.pageNo - 1;
                }
            }
            if (this.isOverviewPage) {
                this.errorMessage = await this.GlobalDocumentService.deleteFile(this.fileId);
            } else {
                this.errorMessage = await this.FileService.deleteFile(this.fileId);
            }
            await this.getFiles();
            this.data.deleteFile = false;
            this.$bvToast.show('toast-error-delete-file');
        } catch (e) {
            this.data.deleteFile = false;
            this.errorMessage = e.response.data;
            this.$bvToast.show('toast-error-delete-file');
        }
    }

    private async downloadFile(fileId, fileApplicationType, fileName, fileExtension) {
        try {
            this.data.downloadFile = true;
            let file = null;
            if (this.isOverviewPage) {
                file = await this.GlobalDocumentService.downloadFile(fileId);
            } else {
                if (fileApplicationType === 'Mspt')
                    file = await this.FileService.downloadMspFile(fileId, fileExtension);
                else
                    file = await this.FileService.downloadFile(fileId);
            }

            this.forceFileDownload(fileName + fileExtension, file);
            this.data.downloadFile = false;
        } catch (e) {
            this.data.downloadFile = false;
            this.errorMessage = e.response.data;
            this.$bvToast.show('toast-error-delete-file');
        }
    }

    private forceFileDownload(name, data) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
    }

    private openDeleteModal(fileId, fileName) {
        this.$bvToast.hide('toast-error-delete-file');
        this.$bvToast.hide('toast-error-import-file');
        this.fileName = fileName;
        this.fileId = fileId;
        $('#deleteFileModal').modal('show');
    }

    private async addFile(event, plantId?) {
        let data = new FormData();
        if (event.target.files.length !== 0) {
            let file = event.target.files[0];
            data.append('File', file);
            data.append('Year', localStorage.selectedMtp);
            try {
                this.data.addFile = true;
                if (this.isOverviewPage) {
                    this.errorMessage = await this.GlobalDocumentService.addFile(data);
                } else {
                    data.append('PlantSid', plantId);
                    this.errorMessage = await this.FileService.addFile(data);
                }
                await this.getFiles();
                this.fileName = file['name'];
                this.data.addFile = false;
                if ($('.btn').hasClass('collapsed')) {
                    (this.$refs['toggleButton'] as any).click();
                }
                $('#addfile').val('');
                if (!this.isOverviewPage) {
                    $('#addfileFromModal').val('');
                }
                this.$bvToast.show('toast-error-import-file');
            } catch (e) {
                this.data.addFile = false;
                this.errorMessage = e.response.data;
                $('#addfile').val('');
                if (!this.isOverviewPage) {
                    $('#addfileFromModal').val('');
                }
                this.$bvToast.show('toast-error-import-file');
            }
        }
    }

    private openAddFilesModal() {
        this.$bvToast.hide('toast-error-import-file');
        this.$bvToast.hide('toast-error-delete-file');
        if (!this.isOverviewPage) {
            $('#addFilesModal').modal('show');
        }
    }

    private hideToast() {
        this.$bvToast.hide('toast-error-import-file');
        this.$bvToast.hide('toast-error-delete-file');
    }

    private fileDateCreated(createdDate) {
        return moment(Helper.UtcToLocalTime(createdDate)).format('DD/MM/YYYY');
    }
}
