import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { MasterService } from '~/services/master-service';
import { ROWS } from '~/components/in-year-budget-requests/constants/rows';
import { MtpStatus, PuiFormMultiselectOption } from '~/utils/interfaces';
import { BudgetRequestSearchResultEntry } from '~/models/services/budget-request';
import { BudgetCompensationType } from "~/models/services/constants-list"

export interface ChangeModalDataPayload {
    budgetRequestIds: string[];
    mtpYear: number;
}

interface FormData {
    globalIds: {
        loading: boolean;
        value: PuiFormMultiselectOption<BudgetRequestSearchResultEntry>[];
        queried: BudgetRequestSearchResultEntry[];
    };
}

@Component({})
export default class AddCompensationModalComponent extends Vue {
    private readonly formLayout = ROWS;

    @Prop()
    private showModal!: boolean;

    @Prop()
    private mtpYear?: number;

    @Prop()
    private currentPlantSid?: number;

    @Prop()
    private currentTechnologySid?: number;

    @Prop()
    private budgetCompensationType?: BudgetCompensationType;

    private formData = AddCompensationModalComponent.initialFormData;
    private currentErrorMessage = '';

    private static get initialFormData(): FormData {
        return {
            globalIds: {
                loading: false,
                value: new Array<PuiFormMultiselectOption<BudgetRequestSearchResultEntry>>(),
                queried: new Array<BudgetRequestSearchResultEntry>(),
            }
        };
    }

    private get globalIdOptions(): PuiFormMultiselectOption<BudgetRequestSearchResultEntry>[] {
        return this.formData.globalIds.queried.map(this.mapSearchResultToOption);
    }

    private get shouldAllowSubmit(): boolean {
        return this.formData.globalIds.value.length > 0 && this.invalidGlobalIds.length === 0;
    }

    private get invalidGlobalIds(): string[] {
        const mappedEntries = this.formData.globalIds.value.map(entry => {
            const mtpListEntry = entry.value.mtpList.find(e => e.year === this.mtpYear);
            const miniFdListEntry = entry.value.miniFdList.find(e => e.miniFdYear === this.mtpYear);

            const latestEntry = mtpListEntry || miniFdListEntry;

            return {
                globalId: entry.value.globalId,
                doesNotHaveValidMtp: latestEntry === undefined,
                doesNotHaveValidStatus: latestEntry?.status !== MtpStatus.FLAGGED && latestEntry?.status !== MtpStatus.SUBMITTED && latestEntry?.status !== MtpStatus.APPROVED,
                isNotAllowedWithBudgetCompensationType: !this.validateGlobalIdDataWithBudgetCompensationType(entry.value.plantSid, entry.value.technologySid),
            };
        });

        if (mappedEntries.some(e => e.doesNotHaveValidMtp)) {
            const entriesWithoutValidMtp = mappedEntries.filter(e => e.doesNotHaveValidMtp).map(e => e.globalId);
            this.currentErrorMessage = `The following Global IDs (${entriesWithoutValidMtp.join(', ')}) don't have MTP data for year ${this.mtpYear}.`;
            return entriesWithoutValidMtp;
        }

        if (mappedEntries.some(e => e.doesNotHaveValidStatus)) {
            const entriesWithoutValidStatus = mappedEntries.filter(e => e.doesNotHaveValidStatus).map(e => e.globalId);
            this.currentErrorMessage = `The following Global IDs (${entriesWithoutValidStatus.join(', ')}) are not in an Submitted/Flagged/Approved status.`;
            return entriesWithoutValidStatus;
        }

        if (mappedEntries.some(e => e.isNotAllowedWithBudgetCompensationType)) {
            const forbiddenEntries = mappedEntries.filter(e => e.isNotAllowedWithBudgetCompensationType).map(e => e.globalId);
            this.currentErrorMessage = `The following Global IDs (${forbiddenEntries.join(', ')}) are not allowed with the current Budget Compensation Type. `;
            return forbiddenEntries;
        }

        return [];
    }

    private get globalIdErrorMessage(): string {
        return this.invalidGlobalIds.length > 0 ?
            this.currentErrorMessage :
            '';
    }

    private validateGlobalIdDataWithBudgetCompensationType(plantSid: number, technologySid: number): boolean {
        if (!this.currentPlantSid || !this.currentTechnologySid || !this.budgetCompensationType) {
            // Validation is not configured.
            return true;
        }

        switch (this.budgetCompensationType) {
            case BudgetCompensationType.NotApplicable:
                // When NotApplicable, no compensation entries are allowed.
                return false;
            case BudgetCompensationType.LocalCompensation:
                // When LocalCompensation, only compensation entries with same PlantSid are allowed.
                return this.currentPlantSid === plantSid;
            case BudgetCompensationType.FleetCompensation:
                // When FleetCompensation, only compensation entries with same TechnologySid are allowed.
                return this.currentTechnologySid === technologySid;
            case BudgetCompensationType.AssetOperationsCompensation:
                // When AssetOperationsCompensation, all compensation entries are allowed.
                return true;
        }
    }

    private mapSearchResultToOption(searchResult: BudgetRequestSearchResultEntry): PuiFormMultiselectOption<BudgetRequestSearchResultEntry> {
        return {
            label: searchResult.globalId,
            secondaryLabel: searchResult.name,
            value: searchResult
        };
    }

    private async handleGlobalIdSearch(globalIdQuery: string): Promise<void> {
        if (!globalIdQuery) {
            return;
        }

        this.formData.globalIds.loading = true;
        this.formData.globalIds.queried = await MasterService.instance.budgetRequestsService.searchBudgetRequestsByGlobalId(globalIdQuery);
        this.formData.globalIds.loading = false;
    }

    private submitRowAdditions(): void {
        const payload: ChangeModalDataPayload = {
            budgetRequestIds: this.formData.globalIds.value.map(entry => entry.value.budgetRequestId),
            mtpYear: this.mtpYear
        };

        this.$emit('change:modal-data', payload);
        this.closeModal();
        this.clearFormData();
    }

    private closeModal(): void {
        this.$emit('update:show-modal', false);
    }

    private clearFormData(): void {
        this.formData = AddCompensationModalComponent.initialFormData;
    }
}
