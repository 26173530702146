var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal fade show",
    attrs: {
      "id": "removeBrModal",
      "aria-hidden": "true",
      "aria-labelledby": "exampleModalLabel",
      "data-backdrop": "static",
      "role": "dialog",
      "tabindex": "-1"
    }
  }, [_c('div', {
    staticClass: "modal-dialog",
    attrs: {
      "role": "document"
    }
  }, [_c('div', {
    staticClass: "modal-content"
  }, [_c('div', {
    staticClass: "modal-header"
  }, [_vm._m(0), _c('button', {
    staticClass: "close",
    attrs: {
      "aria-label": "Close",
      "data-dismiss": "modal",
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.onClickButton();
      }
    }
  }, [_c('span', {
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("×")])])]), _c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm._m(1), _c('div', {
    staticClass: "col-12 pb-3"
  }, [_c('label', {
    staticClass: "container radio d-inline-flex",
    class: _vm.createdYear !== _vm.currentYear ? 'disabledDelete' : ''
  }, [_vm._v(" Delete "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedStateBr,
      expression: "selectedStateBr"
    }],
    attrs: {
      "disabled": _vm.createdYear !== _vm.currentYear,
      "name": "radio",
      "type": "radio",
      "value": "Delete"
    },
    domProps: {
      "checked": _vm._q(_vm.selectedStateBr, "Delete")
    },
    on: {
      "change": function ($event) {
        _vm.selectedStateBr = "Delete";
      }
    }
  }), _c('span', {
    staticClass: "checkmark"
  })]), _c('label', {
    staticClass: "container radio d-inline-flex"
  }, [_vm._v(" Set to inactive "), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedStateBr,
      expression: "selectedStateBr"
    }],
    attrs: {
      "name": "radio",
      "type": "radio",
      "value": "SetInactive"
    },
    domProps: {
      "checked": _vm._q(_vm.selectedStateBr, "SetInactive")
    },
    on: {
      "change": function ($event) {
        _vm.selectedStateBr = "SetInactive";
      }
    }
  }), _c('span', {
    staticClass: "checkmark"
  })])])])])]), _c('div', {
    staticClass: "modal-footer pt-2"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary",
    attrs: {
      "data-dismiss": "modal",
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.onClickButton();
      }
    }
  }, [_vm._v(" Cancel ")]), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "disabled": _vm.selectedStateBr === '',
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.yesChanges();
      }
    }
  }, [_vm._v(" Remove ")])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('h5', {
    staticClass: "modal-title",
    attrs: {
      "id": "exampleModalLabel"
    }
  }, [_vm._v(" Remove from MTP ")])])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12 pb-3"
  }, [_c('p', [_vm._v("Please define whether the selected items should be retained or deleted.")]), _c('p', [_vm._v("Are you sure you want to proceed?")])]);
}]

export { render, staticRenderFns }