import Vue from 'vue';
import Component from 'vue-class-component';
import { CreateIybrFormCompensationModel } from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/create-iybr-form-model';
import { formatToDecimals } from '~/utils/number-helper';
import { tableCellTextFormat } from '~/utils/table-helper';
import { MasterService } from '~/services/master-service';
import { zeroIfNaN } from '~/utils/math-helper';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { Data } from '~/utils';
import { Prop, Watch } from 'vue-property-decorator';
import { PuiTableVendorOptions } from '~/models/libraries/pebble-ui';
import { LatestBudgetRequestDataResponse } from '~/models/services/budget-request';
import { SelectOptions } from '~/components/in-year-budget-requests/add-in-year-budget-requests-modal/options';

export type PopulateCompensationTableData = {
    budgetRequestId: string;
    selectedMtpYear: number;
    justification: number;
    yearPlus1?: number;
    yearPlus2?: number;
    yearPlus3?: number;
}[];

export type CompensationTableTotals = {
    hasCompensationEntries: boolean;
    mtpYearPlus1Total: number;
    mtpYearPlus2Total: number;
    mtpYearPlus3Total: number;
    totalCompensation: number;
};

enum CUSTOM_FIELD_TYPE {
    INPUT = 'input',
    DROPDOWN = 'dropdown',
    ACTIONS = 'actions',
    NONE = 'none'
}

export type ColumnConfig = {
    [key: string]: {
        name: string,
        hasCustomField: boolean,
        customFieldType?: CUSTOM_FIELD_TYPE,
        inputMask: string
    }
};

@Component({})
export default class IybrCompensationTableComponent extends Vue {
    @Prop()
    private mtpYear?: number;

    @Prop()
    private currencyCode?: string;

    @Prop()
    private miniFdId?: string;

    private data = Data.Instance;

    private customFieldTypes = CUSTOM_FIELD_TYPE;

    private budgetRequestData = new Map<string, LatestBudgetRequestDataResponse>();

    private compensationModel: CreateIybrFormCompensationModel = {};

    private numberInputMask = createNumberMask({
        prefix: '',
        includeThousandsSeparator: false,
        allowDecimal: true,
        decimalLimit: 3,
        allowNegative: true
    });

    private defaultInputMask = this.numberInputMask;
    private tableCellTextFormat = tableCellTextFormat;

    private get shouldDisplayTable(): boolean {
        return Object.values(this.compensationModel).length !== 0;
    }

    private get columnConfig(): ColumnConfig {
        return {
            globalId: {
                name: 'Global ID',
                hasCustomField: true,
                customFieldType: CUSTOM_FIELD_TYPE.ACTIONS,
                inputMask: this.defaultInputMask
            },
            projectName: {
                name: 'Project Name',
                hasCustomField: false,
                inputMask: this.defaultInputMask
            },
            justification: {
                name: 'Justification',
                hasCustomField: true,
                customFieldType: CUSTOM_FIELD_TYPE.DROPDOWN,
                inputMask: ''
            },
            originalMtpPlus1: {
                name: `Original ${this.mtpYear + 1}`,
                hasCustomField: false,
                inputMask: this.defaultInputMask
            },
            compensatedMtpPlus1: {
                name: `Compensated ${this.mtpYear + 1}`,
                hasCustomField: true,
                customFieldType: CUSTOM_FIELD_TYPE.INPUT,
                inputMask: this.defaultInputMask
            },
            newMtpPlus1: {
                name: `New ${this.mtpYear + 1}`,
                hasCustomField: false,
                inputMask: this.defaultInputMask
            },
            originalMtpPlus2: {
                name: `Original ${this.mtpYear + 2}`,
                hasCustomField: false,
                inputMask: this.defaultInputMask
            },
            compensatedMtpPlus2: {
                name: `Compensated ${this.mtpYear + 2}`,
                hasCustomField: true,
                customFieldType: CUSTOM_FIELD_TYPE.INPUT,
                inputMask: this.defaultInputMask
            },
            newMtpPlus2: {
                name: `New ${this.mtpYear + 2}`,
                hasCustomField: false,
                inputMask: this.defaultInputMask
            },
            originalMtpPlus3: {
                name: `Original ${this.mtpYear + 3}`,
                hasCustomField: false,
                inputMask: this.defaultInputMask
            },
            compensatedMtpPlus3: {
                name: `Compensated ${this.mtpYear + 3}`,
                hasCustomField: true,
                customFieldType: CUSTOM_FIELD_TYPE.INPUT,
                inputMask: this.defaultInputMask
            },
            newMtpPlus3: {
                name: `New ${this.mtpYear + 3}`,
                hasCustomField: false,
                inputMask: this.defaultInputMask,
            },
        };
    }

    private get justificationDropdownOptions(): SelectOptions[] {
        const justificationTypes = this.data.constants.justificationTypes;

        return Object.keys(justificationTypes).map(key => ({
            label: justificationTypes[key],
            value: key.toString()
        }));
    }

    private get columns(): Record<string, string> {
        return Object.keys(this.columnConfig).reduce((columns, column) => {
            columns[column] = this.columnConfig[column].name;
            return columns;
        }, {});
    }

    private get columnFieldMapping(): Record<string, string> {
        return {
            [this.columns['justification']]: 'justification',
            [this.columns['compensatedMtpPlus1']]: 'yearPlus1',
            [this.columns['compensatedMtpPlus2']]: 'yearPlus2',
            [this.columns['compensatedMtpPlus3']]: 'yearPlus3'
        };
    }

    private get formTotals(): CompensationTableTotals {
        let mtpYearPlus1Total = 0;
        let mtpYearPlus2Total = 0;
        let mtpYearPlus3Total = 0;

        Object.values(this.compensationModel).forEach(budgetCompensation => {
            mtpYearPlus1Total += zeroIfNaN(parseFloat(budgetCompensation.yearPlus1.value));
            mtpYearPlus2Total += zeroIfNaN(parseFloat(budgetCompensation.yearPlus2.value));
            mtpYearPlus3Total += zeroIfNaN(parseFloat(budgetCompensation.yearPlus3.value));
        });

        const totalCompensation = mtpYearPlus1Total + mtpYearPlus2Total + mtpYearPlus3Total;
        const hasCompensationEntries = Object.values(this.compensationModel).length > 0;

        return {
            hasCompensationEntries,
            mtpYearPlus1Total,
            mtpYearPlus2Total,
            mtpYearPlus3Total,
            totalCompensation
        };
    }

    private get totalRow(): Record<string, string | number> {
        return {
            [this.columns['globalId']]: 'Total budget made available for the project whose approval is required',
            [this.columns['compensatedMtpPlus1']]: this.formTotals.mtpYearPlus1Total,
            [this.columns['compensatedMtpPlus2']]: this.formTotals.mtpYearPlus2Total,
            [this.columns['compensatedMtpPlus3']]: this.formTotals.mtpYearPlus3Total
        };
    }

    private get tableOptions(): PuiTableVendorOptions {
        const insertedBudgetCompensations = Object.values(this.compensationModel);

        const budgetRequestRows = insertedBudgetCompensations.map(budgetCompensation => {
            if (!this.budgetRequestData.has(budgetCompensation.budgetRequestId.value)) {
                return {};
            }

            const budgetRequest = this.budgetRequestData.get(budgetCompensation.budgetRequestId.value);
            const compensationEntry = this.compensationModel[budgetRequest.globalId]

            return {
                [this.columns['globalId']]: budgetRequest.globalId,
                [this.columns['projectName']]: budgetRequest.projectName,
                [this.columns['justification']]: '',
                [this.columns['originalMtpPlus1']]: budgetRequest.new1,
                [this.columns['newMtpPlus1']]: compensationEntry.newYearPlus1.value,
                [this.columns['originalMtpPlus2']]: budgetRequest.new2,
                [this.columns['newMtpPlus2']]: compensationEntry.newYearPlus2.value,
                [this.columns['originalMtpPlus3']]: budgetRequest.new3,
                [this.columns['newMtpPlus3']]: compensationEntry.newYearPlus3.value,
            };
        });

        return {
            data: [...budgetRequestRows, this.totalRow],
            options: {
                sortable: [],
            },
            columns: Object.values(this.columns),
        };
    }

    public async populateCompensationTable(data: PopulateCompensationTableData): Promise<void> {
        for (const entry of data) {
            const globalId = await this.addNewBudgetRequestForCompensationByInternalId(entry.selectedMtpYear.toString(), entry.budgetRequestId);
            const budgetRequest = this.budgetRequestData.get(entry.budgetRequestId);

            this.compensationModel[globalId].justification.value = entry.justification.toString();
            this.compensationModel[globalId].yearPlus1.value = formatToDecimals(budgetRequest.new1 - entry.yearPlus1, 3).toString();
            this.compensationModel[globalId].yearPlus2.value = formatToDecimals(budgetRequest.new2 - entry.yearPlus2, 3).toString();
            this.compensationModel[globalId].yearPlus3.value = formatToDecimals(budgetRequest.new3 - entry.yearPlus3, 3).toString();
        }

        this.computeNewValues()
    }

    public validateCompensationTable(): boolean {
        let isTableValid = true;
        const validatedFields = ['justification'];

        Object.values(this.compensationModel).forEach(entry => {
            validatedFields.forEach(field => {
                entry[field].validation.isValid = entry[field].value !== '';

                if (!entry[field].validation.isValid) {
                    isTableValid = false;
                }
            });
        });

        return isTableValid;
    }

    public getCompensationTableData(): CreateIybrFormCompensationModel {
        return this.compensationModel;
    }

    public async addNewBudgetRequestForCompensationByInternalId(mtpYear: string, budgetRequestId: string): Promise<string> {
        const budgetRequest = await MasterService.instance.budgetRequestsService.getLatestBudgetRequestData(budgetRequestId, mtpYear, this.currencyCode, this.miniFdId);

        await this.insertNewBudgetRequest(mtpYear, budgetRequest);
        return budgetRequest.globalId;
    }

    @Watch('compensationModel', { deep: true, immediate: true })
    private updateCompensationModel(): void {
        this.$emit('update:compensation-model', this.compensationModel);
        this.$emit('update:compensation-totals', this.formTotals);
    }

    private computeNewValues(): void {
        Object.values(this.compensationModel).forEach(budgetCompensation => {
            const budgetRequest = this.budgetRequestData.get(budgetCompensation.budgetRequestId.value);

            budgetCompensation.newYearPlus1.value = (budgetRequest.new1 - zeroIfNaN(parseFloat(budgetCompensation.yearPlus1.value))).toString();
            budgetCompensation.newYearPlus2.value = (budgetRequest.new2 - zeroIfNaN(parseFloat(budgetCompensation.yearPlus2.value))).toString();
            budgetCompensation.newYearPlus3.value = (budgetRequest.new3 - zeroIfNaN(parseFloat(budgetCompensation.yearPlus3.value))).toString();
        });
    }

    private getCustomFieldType(column): CUSTOM_FIELD_TYPE | undefined {
        const foundColumn = Object.values(this.columnConfig).find((c) => c.name === column);

        if (!foundColumn || !foundColumn.hasCustomField) {
            return;
        }

        return foundColumn.customFieldType ?? CUSTOM_FIELD_TYPE.NONE;
    }

    private shouldDisplayCustomField(data, fieldType: CUSTOM_FIELD_TYPE): boolean {
        const isProjectCompensationRow = data.row[this.columns['globalId']] !== this.totalRow[this.columns['globalId']];
        const customFieldType = this.getCustomFieldType(data.column);

        return isProjectCompensationRow && customFieldType && customFieldType === fieldType;
    }

    private removeRowWithGlobalId(globalId: string): void {
        const budgetRequestId = this.compensationModel[globalId].budgetRequestId.value;

        this.$delete(this.compensationModel, globalId);
        this.budgetRequestData.delete(budgetRequestId);
    }

    private async insertNewBudgetRequest(mtpYear: string, budgetRequest: LatestBudgetRequestDataResponse): Promise<void> {
        this.budgetRequestData.set(budgetRequest.budgetRequestId, budgetRequest);

        this.$set(this.compensationModel, budgetRequest.globalId, {
            budgetRequestId: {
                value: budgetRequest.budgetRequestId
            },
            projectGlobalId: {
                value: budgetRequest.globalId
            },
            justification: {
                value: '',
                validation: {
                    isValid: true,
                    error: ''
                },
            },
            yearPlus1: {
                value: '',
                validation: {
                    isValid: true,
                    error: ''
                },
            },
            newYearPlus1: {
                value: '',
            },
            yearPlus2: {
                value: '',
                validation: {
                    isValid: true,
                    error: ''
                },
            },
            newYearPlus2: {
                value: '',
            },
            yearPlus3: {
                value: '',
                validation: {
                    isValid: true,
                    error: ''
                },
            },
            newYearPlus3: {
                value: '',
            },
            selectedMtpYear: {
                value: this.mtpYear?.toString()
            },
            originalYearPlus1: {
                value: budgetRequest.new1,
            },
            originalYearPlus2: {
                value: budgetRequest.new2,
            },
            originalYearPlus3: {
                value: budgetRequest.new3,
            },
        });

        this.computeNewValues()
    }

    private getInputMaskForColumn(column: string): string {
        const foundColumn = Object.values(this.columnConfig).find((c) => c.name === column);
        return foundColumn?.inputMask;
    }
}
