import Component from 'vue-class-component';
import Vue from 'vue';
import { MenuEntry } from 'adam.ui-core/dist/src/lib';
import AppDrawer from 'adam.ui-core/dist/src/lib/components/AppDrawer/AppDrawer';
import { MasterService } from '~/services/master-service';
import { Data } from '~/utils';
import { Application } from '~/models/services/app';

@Component({})
export default class NavbarAppDrawerComponent extends Vue {
    $refs!: {
        appDrawer: AppDrawer
    };
    private appService = MasterService.instance.appService;
    private applications: MenuEntry[] = [];
    private readonly appDrawerLabels = {
        applications: 'Apps with access',
        miscellaneous: 'Apps without access'
    } as const;

    async mounted(): Promise<void> {
        await this.loadApps();
    }

    public toggleDrawer(): void {
        this.$refs.appDrawer.open = !this.$refs.appDrawer.open;
    }

    private async loadApps(): Promise<void> {
        try {
            const apps = await this.appService.getApps();
            Data.Instance.coodeApplications = apps;
            this.applications = this.processAppsIntoMenuEntries(apps);
        } catch (e) {
            console.log(e)
            this.$bvToast.show('toast-data-error');
        }
    }

    private processAppsIntoMenuEntries(apps: Application[]): MenuEntry[] {
        return apps.map(app => ({
            id: app.id.toString(),
            type: app.hasAccess ? 'APPLICATION' : '',
            label: app.name,
            iconId: this.preloadLogoForApp(app.logo),
            url: app.url ? app.url : undefined
        })).sort((a, b) => {
            const nameA = a.label.toLowerCase();
            const nameB = b.label.toLowerCase();

            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }
            return 0;
        });
    }

    private preloadLogoForApp(img: string): string {
        try {
            require(`adam.ui-core/assets/icons/${img}.svg`);
            return img;
        } catch {
            return 'app-generic';
        }
    }

    private handleAppClick(app: Application): void {
        if (app.url) {
            window.open(app.url);
        }
    }
}
