import { render, staticRenderFns } from "./confirm-iybr-delete-modal.vue?vue&type=template&id=68671edc&"
import script from "./confirm-iybr-delete-modal.ts?vue&type=script&lang=ts&"
export * from "./confirm-iybr-delete-modal.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports