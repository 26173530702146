import { format } from 'date-fns';
import moment from 'moment';
import { Helper } from '~/utils/helper';

export function convertBrowserDateToServerDate(date: string): string {
    return format(new Date(date), 'yyyy-MM-dd\'T00:00:00\'');
}

export function convertServerDateToBrowserDate(date: string): string {
    return moment(Helper.UtcToLocalTime(date)).format('MM/DD/YYYY');
}

export function convertServerDateToBrowserDateAndTime(date: string) {
    return moment(Helper.UtcToLocalTime(date)).format('YYYY-MM-DD HH:mm:ss');
}
