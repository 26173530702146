import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import '~/components/home/home.scss';
import { Helper, User } from '~/utils';
import { BudgetRequestsService } from '~/services';
import { BudgetRequestHistory } from '~/utils/interfaces';
import 'vue-awesome/icons/plus';
import 'vue-awesome/icons/minus';
import moment from 'moment-timezone';

@Component({})
export default class HistoryComponent extends Vue {
    public budgetRequestHistory: BudgetRequestHistory = {
        activityTrackings: [],
        total: '',
    };
    public elementsPerPage: number = 10;
    public firstElementTable: number = 1;
    public numberOfElementTable: number = this.elementsPerPage;
    public currentPage: number = 1;
    public BudgetRequestsService: BudgetRequestsService = new BudgetRequestsService();
    private loading: boolean = false;
    private userPermissions = <any>[];

    @Prop()
    private budgetRequestId: string;

    constructor() {
        super();
    }

    async created() {
        this.userPermissions = await User.getPerm();
    }

    async mounted() {
        await this.load();
    }

    @Watch('data.selectedPlant')
    async onSelectedPlantChanged(val: string, oldVal: string) {
        if (val != null && this.$route.path === '/history-page') {
            await this.load();
        }
    }

    public async changeNavPageBack(): Promise<void> {
        this.loading = true;
        if (this.currentPage !== 1) {
            this.currentPage = this.currentPage - 1;
            this.numberOfElementTable = this.numberOfElementTable - this.budgetRequestHistory.activityTrackings.length;
            this.budgetRequestHistory = await this.BudgetRequestsService.getBudgetRequestHistory(this.elementsPerPage, this.currentPage, this.budgetRequestId);
            if (this.budgetRequestHistory.activityTrackings !== null) {
                this.firstElementTable = this.firstElementTable - this.budgetRequestHistory.activityTrackings.length;
            }
            // Format activity tracking dates
            if (this.budgetRequestHistory.activityTrackings !== null) {
                this.formatHistoryDates(this.budgetRequestHistory.activityTrackings);
            }
        }
        this.loading = false;
    }

    public async changeNavPageNext(): Promise<void> {
        this.loading = true;
        if (Math.ceil(parseInt(this.budgetRequestHistory.total) / this.elementsPerPage) > this.currentPage) {
            this.currentPage = this.currentPage + 1;
            this.firstElementTable = this.firstElementTable + this.budgetRequestHistory.activityTrackings.length;
            this.budgetRequestHistory = await this.BudgetRequestsService.getBudgetRequestHistory(this.elementsPerPage, this.currentPage, this.budgetRequestId);
            if (this.budgetRequestHistory.activityTrackings !== null)
                this.numberOfElementTable = this.numberOfElementTable + this.budgetRequestHistory.activityTrackings.length;
            // Format activity tracking dates
            if (this.budgetRequestHistory.activityTrackings !== null) {
                this.formatHistoryDates(this.budgetRequestHistory.activityTrackings);
            }
        }
        this.loading = false;
    }

    private async load() {
        this.loading = true;
        try {
            this.budgetRequestHistory = await this.BudgetRequestsService.getBudgetRequestHistory(this.elementsPerPage, this.currentPage, this.budgetRequestId);
        } catch (error) {
            if (error) {
                this.$router.push({ path: 'budget-requests' });
            }
        }

        // Set numberOfElements for pagination
        if (this.budgetRequestHistory.activityTrackings !== null) {
            this.numberOfElementTable = this.budgetRequestHistory.activityTrackings.length;
        }

        // Format activity tracking dates
        if (this.budgetRequestHistory.activityTrackings !== null) {
            this.formatHistoryDates(this.budgetRequestHistory.activityTrackings);
        }

        this.loading = false;
    }

    private formatHistoryDates(activityTrackings: any) {
        this.budgetRequestHistory.activityTrackings.forEach((a) => {
            a.date = moment(Helper.UtcToLocalTime(a.date)).format('DD/MM/YYYY HH:mm:ss');
        });
    }

    private emitDetails(year, globalId) {
        this.$router.push({ path: 'details-page', query: { year: year, globalId: globalId } });
    }

    private goToBrMtpYear(year) {
        localStorage.setItem('selectedMtp', year);
        this.$router.push('/budget-requests');
    }

    private async reloadTableChangeNumberElement() {
        this.loading = true;
        this.currentPage = 1;
        this.firstElementTable = 1;
        this.budgetRequestHistory = await this.BudgetRequestsService.getBudgetRequestHistory(this.elementsPerPage, this.currentPage, this.budgetRequestId);
        if (this.budgetRequestHistory.activityTrackings !== null)
            this.numberOfElementTable = this.budgetRequestHistory.activityTrackings.length;
        // Format activity tracking dates
        if (this.budgetRequestHistory.activityTrackings !== null) {
            this.formatHistoryDates(this.budgetRequestHistory.activityTrackings);
        }
        this.loading = false;
    }

    private toggleAll() {
        if ($('#CollapseAllButton').hasClass('collapsed')) {

            let elements = document.getElementsByClassName('expand');
            for (let el of Object.keys(elements)) {
                if (elements[el].classList.contains('collapsed')) {
                    elements[el].click();
                }
            }
            $('#CollapseAllButton').removeClass('collapsed');
        } else {
            let elements = document.getElementsByClassName('expand');
            for (let el of Object.keys(elements)) {
                if (!elements[el].classList.contains('collapsed')) {
                    elements[el].click();
                }
            }
            $('#CollapseAllButton').addClass('collapsed');
        }
    }


    private checkIcons(key: string) {
        if ($('#CollapseAllButton').hasClass('collapsed')) {
            let elements = document.getElementsByClassName('expand');
            for (let el of Object.keys(elements)) {
                if ((el !== key.toString() && elements[el].classList.contains('collapsed'))
                    || (el === key.toString() && !elements[el].classList.contains('collapsed'))) {
                    return;
                }

            }
            $('#CollapseAllButton').removeClass('collapsed');

        } else {
            let elements = document.getElementsByClassName('expand');
            for (let el of Object.keys(elements)) {
                if ((el !== key.toString() && !elements[el].classList.contains('collapsed'))
                    || (el === key.toString() && elements[el].classList.contains('collapsed'))) {
                    return;
                }

            }
            $('#CollapseAllButton').addClass('collapsed');

        }
    }
}
