export enum Permission {
    OVERVIEW_PAGE_READ = 'OverviewPageView',
    OVERVIEW_PAGE_WRITE = 'OverviewPageEdit',
    SINGLE_SITE_PAGE_READ = 'SingleSitePageView',
    SINGLE_SITE_PAGE_WRITE = 'SingleSitePageEdit',
    BUDGET_REQUEST_DETAILS_READ = 'BudgetRequestDetailsView',
    BUDGET_REQUEST_DETAILS_WRITE = 'BudgetRequestDetailsEdit',
    BUDGET_REQUEST_DETAILS_SUPER_WRITE = 'BudgetRequestDetailsEditAnytime',
    BUDGET_COMPENSATION_SUPER_WRITE = 'ChangeRequestBudgetCompensationEditAnytime',
    BUDGET_REQUEST_REVIEW_READ = 'BudgetRequestReviewView',
    BUDGET_REQUEST_REVIEW_WRITE = 'BudgetRequestReviewEdit',
    MTP_SITE_DOCUMENTS_READ = 'MtpSiteDocumentsView',
    MTP_SITE_DOCUMENTS_WRITE = 'MtpSiteDocumentsEdit',
    MTP_GLOBAL_DOCUMENTS_READ = 'MtpGlobalDocumentsView',
    MTP_GLOBAL_DOCUMENTS_WRITE = 'MtpGlobalDocumentsEdit',
    REFERENCES_READ = 'ReferencesView',
    REFERENCES_WRITE = 'ReferenecesEdit',
    BUDGET_REQUEST_APPROVE_WRITE = 'BudgetRequestApproveEdit',
    MTP_PERIODS_WRITE = 'MtpPeriodsEdit',
    BUDGET_REQUEST_BULK_MOVE_TO_CURRENT_MTP = 'BulkMoveBudgetRequestsToCurrentMtp',
    BUDGET_REQUEST_BULK_DELETE_INACTIVE = 'BulkDeleteInactiveBudgetRequests',
    BUDGET_REQUEST_PRIORITIZATION_IMPORT = 'BudgetRequestPrioritizationImport',
    DELETE_INACTIVE_CHANGE_REQUEST = 'DeleteInactiveChangeRequest',
    ACCESS_ALL = 'AccessAll',
    FOCUS_TOPICS_EDIT = 'FocusTopicEdit',
    COMBINED_BUDGET_EDIT = 'CombinedBudgetEdit',
    SD_FD_EDIT = 'SdFdEdit',
}

type PermissionInclusionsObject = {
    [key in Permission]?: Permission[];
};

export class PermissionsHelper {
    private static readonly permissionInclusions: PermissionInclusionsObject = {
        [Permission.OVERVIEW_PAGE_WRITE]: [Permission.OVERVIEW_PAGE_READ],
        [Permission.SINGLE_SITE_PAGE_WRITE]: [Permission.SINGLE_SITE_PAGE_READ],
        [Permission.BUDGET_REQUEST_DETAILS_WRITE]: [Permission.BUDGET_REQUEST_DETAILS_READ],
        [Permission.BUDGET_REQUEST_DETAILS_SUPER_WRITE]: [Permission.BUDGET_REQUEST_DETAILS_WRITE],
        [Permission.BUDGET_REQUEST_REVIEW_WRITE]: [Permission.BUDGET_REQUEST_REVIEW_READ],
        [Permission.MTP_SITE_DOCUMENTS_WRITE]: [Permission.MTP_SITE_DOCUMENTS_READ],
        [Permission.MTP_GLOBAL_DOCUMENTS_WRITE]: [Permission.MTP_GLOBAL_DOCUMENTS_READ],
        [Permission.REFERENCES_WRITE]: [Permission.REFERENCES_READ]
    };

    static parsePermissionInclusions(permissions: Permission[]): Permission[] {
        return this.parsePermissionString(permissions.join(','));
    }

    static parsePermissionString(permissionString: string): Permission[] {
        const applicationPermissions: string[] = Object.values(Permission);
        const parsedPermissions: Permission[] = [];

        const pushPermissionWithInclusions = (permission: Permission) => {
            parsedPermissions.push(permission);

            if (Object.keys(this.permissionInclusions).includes(permission)) {
                this.permissionInclusions[permission].forEach(includedPermission => {
                    pushPermissionWithInclusions(includedPermission);
                });
            }
        };

        permissionString.split(',').forEach(permission => {
            if (applicationPermissions.includes(permission)) {
                pushPermissionWithInclusions(permission as Permission);
            }
        });

        return parsedPermissions;
    }
}
