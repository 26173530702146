export enum AdamUiNotificationType {
    NOTIFICATION = 0,
    TASK = 1,
    TASK_WITH_REDIRECT_TO_APP = 2
}

export type AdamUiNotification =
    | AdamUiNotificationProperties
    | AdamUiNotificationTaskProperties
    | AdamUiNotificationTaskWithRedirectProperties;

type AdamUiNotificationCommonProperties = {
    id: number,
    isRead: boolean,
    title: string,
    description: string,
    created: string,
    useCaseName: string | number,
};

type AdamUiNotificationProperties = AdamUiNotificationCommonProperties & {
    type: AdamUiNotificationType.NOTIFICATION
};

type AdamUiNotificationTaskProperties = AdamUiNotificationCommonProperties & {
    type: AdamUiNotificationType.TASK,
    approve: () => void,
    reject: () => void,
};

type AdamUiNotificationTaskWithRedirectProperties = AdamUiNotificationCommonProperties & {
    type: AdamUiNotificationType.TASK_WITH_REDIRECT_TO_APP,
    redirectUrl: string,
    redirect: () => void,
};

export type AdamUiNotificationEvent = CustomEvent<AdamUiNotification>;
