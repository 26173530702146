var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "home-content"
  }, [_c('div', {
    staticClass: "banner-text"
  }, [_c('h1', [_vm._v(" Welcome "), _c('br')])]), _c('div', {
    staticClass: "banner-img"
  })]);
}]

export { render, staticRenderFns }