import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { ROWS } from '~/components/in-year-budget-requests/constants/rows';
import './file-card-display.scss';
import { convertServerDateToBrowserDate } from '~/utils/date-helper';
import { FILE_EVENTS } from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/files-change-request/files-change-request-utils';
import { FileResult } from '~/models/services/file';
import { PuiLightbox } from '~/models/libraries/pebble-ui';

@Component({})

export default class FileCardDisplayComponent extends Vue {
    @Prop()
    isEditPage: boolean;

    @Prop()
    isViewPage: boolean;

    @Prop()
    changeRequestFile: FileResult;

    private readonly PAGE_LAYOUT = ROWS;
    private readonly CONFIRM_DELETE_MODAL_REF = 'confirmDeleteModal';

    private convertDate(date: string): string {
        if (this.isEditPage || this.isViewPage) {
            return convertServerDateToBrowserDate(date);
        }
    }

    private async onDeleteButtonClick(): Promise<void> {
        (this.$refs[this.CONFIRM_DELETE_MODAL_REF] as PuiLightbox).open();
    }

    private onConfirmDeleteButtonClick(): void {
        if (this.isEditPage) {
            this.$emit(FILE_EVENTS.DELETE_FILE_EVENT, this.changeRequestFile.id);
        } else {
            this.$emit(FILE_EVENTS.DELETE_LOCAL_FILE_EVENT, this.changeRequestFile.name);
        }
        (this.$refs[this.CONFIRM_DELETE_MODAL_REF] as PuiLightbox).close();
    }

    private async onDownloadButtonClick(): Promise<void> {
        this.$emit(FILE_EVENTS.DOWNLOAD_FILE_EVENT,
            this.changeRequestFile.name + this.changeRequestFile.extension, this.changeRequestFile.id);
    }
}
