import { Connection } from '~/utils/';
import {
    GetByExternalIdPayload,
    GetByExternalIdResponse,
    GetExternalIdsPayload, GetExternalIdsResponse
} from '~/models/services/cross-app-linking';
import { ApiResponse } from '~/models/services/common';

export class CrossAppLinkingService {
    private readonly api: Connection = new Connection();

    public async getExternalData(externalIdPayload: GetByExternalIdPayload): Promise<GetByExternalIdResponse> {
        const result = (await this.api.post('cross-app-linking/get-by-external-id', externalIdPayload)).data.result;
        return {
            externalId: externalIdPayload.externalId,
            ...result
        };
    }

    public async getExternalIds(externalIdsPayload: GetExternalIdsPayload): Promise<GetExternalIdsResponse> {
        return (await this.api.post<ApiResponse<GetExternalIdsResponse>>('cross-app-linking/get-external-ids', externalIdsPayload)).data.result;
    }
}
