import { addYears, format } from 'date-fns';
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { CombinedDataField, GlobalId } from '~/models/services/project-portfolio';
import { CrossAppLinkingService } from '~/services/cross-app-linking-service';
import { MtpService } from '~/services/mtp-service';
import { ProjectPortfolioService } from '~/services/project-portfolio';
import { Data } from '~/utils';
import { MtpPeriod, PuiFormMultiselectOption, SelectOption } from '~/utils/interfaces';
import { IFormData } from './form';
import { arrayNotEmptyValidationFn, combineValidationFns, dateValidationFn, numberNotEmptyValidationFn, numberNotInRangeValidationFn, numberNotNaNValidationFn, objectNotNullOrUndefinedValidationFn, selectorChangedValidationFn, stringNotEmptyValidationFn } from './form-validation';

type BudgetId = {
    globalId: string;
    id: string;
}

const ROWS = {
    FULL_ROW: { s: 12, l: 12 },
    TWO_THIRDS_ROW: { s: 12, l: 8 },
    HALF_ROW: { s: 12, l: 6 },
    ONE_THIRD_ROW: { s: 12, l: 4 },
    QUARTER_ROW: { s: 6, l: 3 },
    THREE_QUARTER_ROW: { s: 12, l: 9 },
    ONE_SIXTH_ROW: { s: 4, l: 2 },
} as const;

const AERO_TO_PRIMARY_DEPARTMENT_MAP = {
    '1': '2',
    '2': '1',
    '3': '1',
    '4': '2',
    '5': '3',
    '6': '4',
} as const;

const FormKeys = [
    'status',
    'fundingStatus',
    'projectStatus',
    'year',
    'budgetIds',
    'selectedGlobalIds',
    'countrySid',
    'countryName',
    'technologySid',
    'technologyName',
    'plantGroupSid',
    'plantGroupName',
    'plantSid',
    'plantName',
    'name',
    'localLanguageName',
    'projectType',
    'lumpSum',
    'startDate',
    'endDate',
    'aeroClassification',
    'justificationOnClassification',
    'primaryDepartment',
    'maintenanceType',
    'outageType',
    'outageIds',
    'procurementCategory',
    'comment',
    'wbsElement',
    'planningAndControllingClassification',
    'uniperShareOfBudget',
    'usefulEconomicLife',
    'hasPlanningCosts',
    'currencyCode',
    'mainFlag',
    'otherFlags',
    'spendUntilEndOfYear',
    'forecast',
    'new1',
    'new2',
    'new3',
    'after',
    'total',
    'externalIds',
] as const;
type FormKeysType = typeof FormKeys[number];

type FormValuesType = {
    status: string;
    fundingStatus: string;
    projectStatus: string;
    year: number;
    budgetIds: BudgetId[];
    selectedGlobalIds: SelectOption[];
    countrySid: string;
    countryName: string;
    technologySid: string;
    technologyName: string;
    plantGroupSid: string;
    plantGroupName: string;
    plantSid: string;
    plantName: string;
    name: string;
    localLanguageName: string;
    projectType: string;
    lumpSum: string;
    startDate: string;
    endDate: string;
    aeroClassification: string;
    justificationOnClassification: string;
    primaryDepartment: number;
    maintenanceType: number;
    outageType: string;
    outageIds: string;
    procurementCategory: number;
    comment: string;
    wbsElement: string;
    planningAndControllingClassification: number;
    uniperShareOfBudget: number;
    usefulEconomicLife: number;
    hasPlanningCosts: string;
    currencyCode: string;
    mainFlag: string;
    otherFlags: string[];
    spendUntilEndOfYear: number;
    forecast: number;
    new1: number;
    new2: number;
    new3: number;
    after: number;
    total: number;
    externalIds: string[],
};

const FieldsToString = [
    'status',
    'fundingStatus',
    'projectStatus',
    'primaryDepartment',
    'maintenanceType',
    'procurementCategory',
    'mainFlag',
    'planningAndControllingClassification',
    'year',
    'uniperShareOfBudget',
    'usefulEconomicLife',
    'projectType',
    'aeroClassification',
    'outageType',
    'countrySid',
    'technologySid',
    'plantGroupSid',
    'plantSid',
    'spendUntilEndOfYear',
    'forecast',
    'new1',
    'new2',
    'new3',
    'after',
    'total',
] as const;

const FieldsToJoin = [
    'outageIds',
] as const;

const FieldsToSlice = [
    'externalIds',
] as const;

const FieldsToDate = [
    'startDate',
    'endDate',
] as const;

const ArrayFieldsToString = [
    'otherFlags',
] as const;

const FieldsToNumberPayload = [
    'projectType',
    'technologySid',
    'plantSid',
    'plantGroupSid',
    'countrySid',
    'year',
    'spendUntilEndOfYear',
    'forecast',
    'new1',
    'new2',
    'new3',
    'after',
    'total',
    'status',
    'mainFlag',
    'fundingStatus',
    'aeroClassification',
    'maintenanceType',
    'outageType',
    'planningAndControllingClassification',
    'primaryDepartment',
    'procurementCategory',
    'uniperShareOfBudget',
    'usefulEconomicLife',
    'projectStatus',
] as const;

const FieldsToBooleanPayload = [
    'lumpSum',
    'hasPlanningCosts',
] as const;

const INPUT_SELECTOR_INITIAL_VALUE = 'N/A';
const MINIMUM_GLOBALIDS = 2;

@Component({})
export default class LinkedBudgetRequestFormComponent extends Vue {
    public CrossAppLinkingService: CrossAppLinkingService = new CrossAppLinkingService();
    private projectPortfolioService: ProjectPortfolioService = new ProjectPortfolioService();
    private MtpService: MtpService = new MtpService();

    private currentYear = new Date(Date.now()).getFullYear();
    private mtpPeriod: MtpPeriod = <MtpPeriod>{
        year: 0,
        mtpStartDate: null,
        mtpEndDate: null
    };

    private data: Data = Data.Instance;
    private ROWS = ROWS;

    private combinedId = null;
    private isEdit = false;

    private loader = {
        loading: false,
        message: '',
    };
    private initialError = {
        show: false,
        title: '',
    };

    private combinedDataLoading = false;

    private globalIdsLoading = false;
    private globalIdsQuery: GlobalId[] = [];

    private externalId = {
        value: '',
        loading: false,
        isValid: true,
        errorMessage: '',
    }

    private localCombinedData: CombinedDataField[] = [];
    private localCombinedBudget: Record<string, any> = {};

    @Prop()
    private combinedData: CombinedDataField[];

    @Prop()
    private id: string;

    private formData: IFormData<FormKeysType, FormValuesType> = {
        rawValues: {
            status: undefined,
            fundingStatus: undefined,
            projectStatus: undefined,
            year: undefined,
            budgetIds: [],
            selectedGlobalIds: [],
            countrySid: undefined,
            countryName: undefined,
            technologySid: undefined,
            technologyName: undefined,
            plantGroupSid: undefined,
            plantGroupName: undefined,
            plantSid: undefined,
            plantName: undefined,
            name: '',
            localLanguageName: '',
            projectType: INPUT_SELECTOR_INITIAL_VALUE,
            lumpSum: INPUT_SELECTOR_INITIAL_VALUE,
            startDate: undefined,
            endDate: undefined,
            aeroClassification: INPUT_SELECTOR_INITIAL_VALUE,
            justificationOnClassification: '',
            primaryDepartment: undefined,
            maintenanceType: undefined,
            outageType: INPUT_SELECTOR_INITIAL_VALUE,
            outageIds: '',
            procurementCategory: undefined,
            comment: '',
            wbsElement: '',
            planningAndControllingClassification: undefined,
            uniperShareOfBudget: undefined,
            usefulEconomicLife: undefined,
            hasPlanningCosts: INPUT_SELECTOR_INITIAL_VALUE,
            currencyCode: INPUT_SELECTOR_INITIAL_VALUE,
            mainFlag: undefined,
            otherFlags: [],
            spendUntilEndOfYear: undefined,
            forecast: undefined,
            new1: undefined,
            new2: undefined,
            new3: undefined,
            after: undefined,
            total: undefined,
            externalIds: [],
        },
        initialValues: {
            status: undefined,
            fundingStatus: undefined,
            projectStatus: undefined,
            year: undefined,
            budgetIds: [],
            selectedGlobalIds: [],
            countrySid: undefined,
            countryName: undefined,
            technologySid: undefined,
            technologyName: undefined,
            plantGroupSid: undefined,
            plantGroupName: undefined,
            plantSid: undefined,
            plantName: undefined,
            name: '',
            localLanguageName: '',
            projectType: INPUT_SELECTOR_INITIAL_VALUE,
            lumpSum: INPUT_SELECTOR_INITIAL_VALUE,
            startDate: undefined,
            endDate: undefined,
            aeroClassification: INPUT_SELECTOR_INITIAL_VALUE,
            justificationOnClassification: '',
            primaryDepartment: undefined,
            maintenanceType: undefined,
            outageType: INPUT_SELECTOR_INITIAL_VALUE,
            outageIds: '',
            procurementCategory: undefined,
            comment: '',
            wbsElement: '',
            planningAndControllingClassification: undefined,
            uniperShareOfBudget: undefined,
            usefulEconomicLife: undefined,
            hasPlanningCosts: INPUT_SELECTOR_INITIAL_VALUE,
            currencyCode: INPUT_SELECTOR_INITIAL_VALUE,
            mainFlag: undefined,
            otherFlags: [],
            spendUntilEndOfYear: undefined,
            forecast: undefined,
            new1: undefined,
            new2: undefined,
            new3: undefined,
            after: undefined,
            total: undefined,
            externalIds: [],
        },
        values: {
            status: undefined,
            fundingStatus: undefined,
            projectStatus: undefined,
            year: undefined,
            budgetIds: [],
            selectedGlobalIds: [],
            countrySid: undefined,
            countryName: undefined,
            technologySid: undefined,
            technologyName: undefined,
            plantGroupSid: undefined,
            plantGroupName: undefined,
            plantSid: undefined,
            plantName: undefined,
            name: '',
            localLanguageName: '',
            projectType: INPUT_SELECTOR_INITIAL_VALUE,
            lumpSum: INPUT_SELECTOR_INITIAL_VALUE,
            startDate: undefined,
            endDate: undefined,
            aeroClassification: INPUT_SELECTOR_INITIAL_VALUE,
            justificationOnClassification: '',
            primaryDepartment: undefined,
            maintenanceType: undefined,
            outageType: INPUT_SELECTOR_INITIAL_VALUE,
            outageIds: '',
            procurementCategory: undefined,
            comment: '',
            wbsElement: '',
            planningAndControllingClassification: undefined,
            uniperShareOfBudget: undefined,
            usefulEconomicLife: undefined,
            hasPlanningCosts: INPUT_SELECTOR_INITIAL_VALUE,
            currencyCode: INPUT_SELECTOR_INITIAL_VALUE,
            mainFlag: undefined,
            otherFlags: [],
            spendUntilEndOfYear: undefined,
            forecast: undefined,
            new1: undefined,
            new2: undefined,
            new3: undefined,
            after: undefined,
            total: undefined,
            externalIds: [],
        },
        validation: {
            status: true,
            fundingStatus: true,
            projectStatus: true,
            year: true,
            budgetIds: true,
            selectedGlobalIds: true,
            countrySid: true,
            countryName: true,
            technologySid: true,
            technologyName: true,
            plantGroupSid: true,
            plantGroupName: true,
            plantSid: true,
            plantName: true,
            name: true,
            localLanguageName: true,
            projectType: true,
            lumpSum: true,
            startDate: true,
            endDate: true,
            aeroClassification: true,
            justificationOnClassification: true,
            primaryDepartment: true,
            maintenanceType: true,
            outageType: true,
            outageIds: true,
            procurementCategory: true,
            comment: true,
            wbsElement: true,
            planningAndControllingClassification: true,
            uniperShareOfBudget: true,
            usefulEconomicLife: true,
            hasPlanningCosts: true,
            currencyCode: true,
            mainFlag: true,
            otherFlags: true,
            spendUntilEndOfYear: true,
            forecast: true,
            new1: true,
            new2: true,
            new3: true,
            after: true,
            total: true,
            externalIds: true,
        },
        validators: {
            status: objectNotNullOrUndefinedValidationFn,
            fundingStatus: objectNotNullOrUndefinedValidationFn,
            projectStatus: objectNotNullOrUndefinedValidationFn,
            year: () => undefined,
            budgetIds: arrayNotEmptyValidationFn,
            selectedGlobalIds: arrayNotEmptyValidationFn,
            countrySid: () => undefined,
            countryName: () => undefined,
            technologySid: () => undefined,
            technologyName: () => undefined,
            plantGroupSid: () => undefined,
            plantGroupName: () => undefined,
            plantSid: () => undefined,
            plantName: () => undefined,
            name: stringNotEmptyValidationFn,
            localLanguageName: stringNotEmptyValidationFn,
            projectType: selectorChangedValidationFn,
            lumpSum: selectorChangedValidationFn,
            startDate: dateValidationFn,
            endDate: combineValidationFns(
                dateValidationFn,
                (input, context) => {
                    if (context.startDate > input) {
                        return 'End Date must be greater than Start Date.'
                    }
                }
            ),
            aeroClassification: selectorChangedValidationFn,
            justificationOnClassification: (input, context) => {
                if (context.aeroClassification === '4') {
                    const result = stringNotEmptyValidationFn(input);
                    if (result) {
                        return 'Justification is required for Statuatory classification.';
                    }
                }
            },
            primaryDepartment: objectNotNullOrUndefinedValidationFn,
            maintenanceType: objectNotNullOrUndefinedValidationFn,
            outageType: selectorChangedValidationFn,
            outageIds: (input, context) => {
                if (context.outageType === '1') {
                    return stringNotEmptyValidationFn(input);
                }
            },
            procurementCategory: objectNotNullOrUndefinedValidationFn,
            comment: stringNotEmptyValidationFn,
            wbsElement: stringNotEmptyValidationFn,
            planningAndControllingClassification: objectNotNullOrUndefinedValidationFn,
            uniperShareOfBudget: combineValidationFns(
                numberNotNaNValidationFn,
                numberNotEmptyValidationFn,
                numberNotInRangeValidationFn(0, 100),
            ),
            usefulEconomicLife: combineValidationFns(
                numberNotNaNValidationFn,
                numberNotEmptyValidationFn,
            ),
            hasPlanningCosts: selectorChangedValidationFn,
            currencyCode: selectorChangedValidationFn,
            mainFlag: () => undefined,
            otherFlags: () => undefined,
            spendUntilEndOfYear: combineValidationFns(
                numberNotNaNValidationFn,
                numberNotEmptyValidationFn,
            ),
            forecast: combineValidationFns(
                numberNotNaNValidationFn,
                numberNotEmptyValidationFn,
            ),
            new1: combineValidationFns(
                numberNotNaNValidationFn,
                numberNotEmptyValidationFn,
            ),
            new2: combineValidationFns(
                numberNotNaNValidationFn,
                numberNotEmptyValidationFn,
            ),
            new3: combineValidationFns(
                numberNotNaNValidationFn,
                numberNotEmptyValidationFn,
            ),
            after: combineValidationFns(
                numberNotNaNValidationFn,
                numberNotEmptyValidationFn,
            ),
            total: (input) => {
                if (stringNotEmptyValidationFn(input?.toString() ?? '')) {
                    return 'Project type is not selected.';
                }
            },
            externalIds: () => undefined,
        },
        errorMessages: {
            status: undefined,
            fundingStatus: undefined,
            projectStatus: undefined,
            year: undefined,
            budgetIds: undefined,
            selectedGlobalIds: undefined,
            countrySid: undefined,
            countryName: undefined,
            technologySid: undefined,
            technologyName: undefined,
            plantGroupSid: undefined,
            plantGroupName: undefined,
            plantSid: undefined,
            plantName: undefined,
            name: undefined,
            localLanguageName: undefined,
            projectType: undefined,
            lumpSum: undefined,
            startDate: undefined,
            endDate: undefined,
            aeroClassification: undefined,
            justificationOnClassification: undefined,
            primaryDepartment: undefined,
            maintenanceType: undefined,
            outageType: undefined,
            outageIds: undefined,
            procurementCategory: undefined,
            comment: undefined,
            wbsElement: undefined,
            planningAndControllingClassification: undefined,
            uniperShareOfBudget: undefined,
            usefulEconomicLife: undefined,
            hasPlanningCosts: undefined,
            currencyCode: undefined,
            mainFlag: undefined,
            otherFlags: undefined,
            spendUntilEndOfYear: undefined,
            forecast: undefined,
            new1: undefined,
            new2: undefined,
            new3: undefined,
            after: undefined,
            total: undefined,
            externalIds: undefined,
        },
    }

    private async mounted(): Promise<void> {
        this.loader = {
            loading: true,
            message: 'Loading Combined Budget...'
        }

        if (this.id === 'new') {
            this.isEdit = false;
            if (this.combinedData) {
                this.localCombinedData = this.combinedData;
                this.loadFromCombinedData();
            } else {
                this.initialError = {
                    show: true,
                    title: 'Please restart the process!',
                }
            }
        } else {
            try {
                this.isEdit = true;
                const result = await this.projectPortfolioService.getCombinedBudget(this.id);
                if (result) {
                    this.localCombinedBudget = result;
                    this.loadFromCombinedBudget();
                    await this.checkMtpPeriod();
                    if (this.isEditDisabled) {
                        this.onActiveRouteChanged({ href: `/project-portfolio/${this.id}/view` });
                        this.$pui.toast({
                            type: 'warning',
                            title: 'Warning',
                            copy: 'Combined Budget cannot be edited!',
                        });
                    }
                } else {
                    this.initialError = {
                        show: true,
                        title: 'Combined Budget not found!',
                    }
                }
            } catch (error) {
                this.initialError = {
                    show: true,
                    title: 'Combined Budget not found!',
                }
            }
        }

        this.loader = {
            loading: false,
            message: ''
        }
    }

    private onActiveRouteChanged(value): void {
        if (value.href === '') {
            return;
        }

        this.$router.push({ path: value.href });
    }

    private get breadcrumbLinks(): { label: string, href: string }[] {
        if (this.isEdit) {
            return [
                {
                    label: 'Project Portfolio',
                    href: '/project-portfolio'
                },
                {
                    label: this.IdOrName,
                    href: this.combinedId ? `/project-portfolio/${this.id}/view` : ''
                },
                {
                    label: 'Edit Combined Budget Request',
                    href: ''
                }
            ]
        }

        return [
            {
                label: 'Project Portfolio',
                href: '/project-portfolio'
            },
            {
                label: this.IdOrName,
                href: ''
            }
        ]
    }

    private get IdOrName(): string {
        if (!this.isEdit) {
            return 'New Combined Budget Request';
        }

        return this.combinedId ?? 'Combined Budget Request';
    }

    private get pageTitle(): string {
        return this.isEdit ? 'Edit Combined Budget Request' : 'New Combined Budget Request';
    }

    private get year(): number {
        return this.formData.values.year;
    }

    private get globalIdsChanged(): boolean {
        return this.arraysOfObjectsAreEqual(this.formData.values.budgetIds, this.formData.initialValues.budgetIds);
    }

    private arraysOfObjectsAreEqual(a: any[], b: any[]): boolean {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((obj, index) => JSON.stringify(obj) === JSON.stringify(b[index]));
    }

    private get answerOptions(): SelectOption[] {
        return [
            {
                label: 'Yes',
                value: true,
            },
            {
                label: 'No',
                value: false,
            },
        ]
    }

    private get currencyCodeOptions(): SelectOption[] {
        return [
            {
                label: this.formData.values.currencyCode,
                value: this.formData.values.currencyCode,
            },
        ]
    }

    private get numberOfErrors(): number {
        return Object.values(this.formData.validation).reduce((count, value) => count + (value === false ? 1 : 0), 0); 
    }

    private get canRemoveGlobalIds(): boolean {
        return this.formData.values.selectedGlobalIds.length > MINIMUM_GLOBALIDS;
    }

    private get planningTotal(): string {
        const { spendUntilEndOfYear, forecast, new1, new2, new3, after } = this.formData.values;
        const nonProjectTotal = Number(new1) + Number(new2) + Number(new3);
        const projectTotal = Number(spendUntilEndOfYear) + Number(forecast) + nonProjectTotal + Number(after);
        
        if (this.formData.values.projectType === '1') {
            this.formData.values.total = Number(projectTotal.toFixed(3));
        } else if (this.formData.values.projectType === '2') {
            this.formData.values.total = Number(nonProjectTotal.toFixed(3));
        }

        return this.formData.values.total?.toString() ?? '';
    }

    private constantsToOptionsList(object: Record<number | string, string>): SelectOption[] {
        return Object.entries(object).map(([key, value]) => ({
            label: value,
            value: key,
        }));
    }

    private get mtpStatusOptionsList(): SelectOption[] {
        return Object.entries(this.data.constants.mtpStatus).map(([key, value]) => ({
            label: value.name,
            secondaryLabel: value.description,
            value: key,
        }));
    }

    private singleOption(label: string, value: string): SelectOption[] {
        if (label && value) {
            return [
                {
                    label: label,
                    value: value,
                }
            ]
        }

        return [];
    }

    private get isPrimaryDepartmentDefinedbyAero(): boolean {
        return AERO_TO_PRIMARY_DEPARTMENT_MAP[this.formData.values.aeroClassification] === this.formData.values.primaryDepartment;
    }

    private updateGlobalIdValues(): void {
        this.formData.values.budgetIds = this.formData.values.selectedGlobalIds.map(element => ({
            globalId: element.label,
            id: element.value.toString(),
        }));
    }

    private resetGlobalIdValues(): void {
        this.formData.values.selectedGlobalIds = this.formData.initialValues.selectedGlobalIds;
    }

    private fillPrimaryDepartment(index: string): void {
        this.formData.values.primaryDepartment = AERO_TO_PRIMARY_DEPARTMENT_MAP[index];
    }

    private resetMainFlag(): void {
        this.formData.values.mainFlag = this.formData.rawValues.mainFlag as never;
    }

    private validateField(key: FormKeysType): boolean {
        this.formData.errorMessages[key] = this.formData.validators[key](this.formData.values[key] as never, this.formData.values);
        this.formData.validation[key] = !this.formData.errorMessages[key];
        return this.formData.validation[key];
    }

    private get isFormValid(): boolean {
        return FormKeys.every(key => this.formData.validation[key]);
    }

    private clearFieldValidation(key: FormKeysType): void {
        this.formData.errorMessages[key] = undefined;
        this.formData.validation[key] = true;
    }

    private clearFormValidation(): void {
        FormKeys.forEach(this.clearFieldValidation);
    }

    private validateForm(): boolean {
        FormKeys.forEach(key => this.validateField(key));
        return this.isFormValid;
    }

    private async submitForm(): Promise<void> {
        this.clearFormValidation();
        this.validateForm();
        this.validateExternalIdForAero();

        if (!this.isFormValid) {
            return;
        }

        this.loader = {
            loading: true,
            message: this.isEdit ? 'Saving Combined Budget...' : 'Creating Combined Budget...'
        }

        const payload: Record<string, any> = {};
        FormKeys.forEach(key => {
            if (key !== 'selectedGlobalIds') {
                if (key === 'budgetIds') {
                    payload[key] = this.formData.values[key].map(budget => budget.id);
                } else if (key === 'otherFlags') {
                    payload.flags =  this.formData.values[key].map(value => parseInt(value));
                } else if (key === 'outageIds') {
                    payload[key] =  this.formData.values[key].split(', ');
                } else if (FieldsToNumberPayload.find(field => field === key)) {
                    payload[key] = Number(this.formData.values[key]);
                } else if (FieldsToBooleanPayload.find(field => field === key)) {
                    payload[key] = this.formData.values[key].toString() === 'true';
                } else {
                    payload[key] = this.formData.values[key];
                }
            }
        });

        if (this.isEdit) {
            payload.id = this.id;
        }

        try {
            if (this.isEdit) {
                await this.projectPortfolioService.editCombinedBudget(payload);
                this.onActiveRouteChanged({ href: `/project-portfolio/${this.id}/view` });
                this.$pui.toast({
                    type: 'success',
                    title: 'Success',
                    copy: 'Combined Budget saved!',
                });
            } else {
                const result = await this.projectPortfolioService.addCombinedBudget(payload);
                this.onActiveRouteChanged({ href: `/project-portfolio/${result.combinedId}/view` });
                this.$pui.toast({
                    type: 'success',
                    title: 'Success',
                    copy: 'Combined Budget created!',
                });
            }
        } catch (e) {
            this.$pui.toast({
                type: 'error',
                title: 'Failed',
                copy: 'Something went wrong.',
            });
        } finally {
            this.loader = {
                loading: false,
                message: ''
            }
        }
    }

    private async reloadCombinedData(): Promise<void> {
        const { budgetIds, year } = this.formData.values;
        const budgets = budgetIds.map(element => element.id);
        if (!year || budgets.length === 0) {
            return;
        }

        this.combinedDataLoading = true;
        
        try {
            this.setCurrentValuesAsRawValues();
            this.localCombinedData = await this.projectPortfolioService.getCombinedData(budgets, year);
            this.loadFromCombinedData();
            this.$pui.toast({
                type: 'success',
                title: 'Success',
                copy: 'Budgets combined.',
            });
        } catch (e) {
            this.$pui.toast({
                type: 'error',
                title: 'Failed',
                copy: 'Something went wrong.',
            });
        } finally {
            this.combinedDataLoading = false;
        }
    }

    private loadFromCombinedData(): void {
        if (!this.localCombinedData) {
            return;
        }

        FormKeys.forEach(key => {
            const foundData = this.localCombinedData.find(data => data.fieldName === key);
            if (foundData) {
                if (foundData.isCombined && (!!foundData.fieldValue || !isNaN(foundData.fieldValue) || typeof foundData.fieldValue === 'boolean')) {
                    if (FieldsToString.find(field => field === key)) {
                        this.formData.values[key] = foundData.fieldValue.toString() as never;
                    } else if (ArrayFieldsToString.find(field => field === key)) {
                        this.formData.values[key] = foundData.fieldValue.map(data => data.toString()) as never;
                    } else if (FieldsToJoin.find(field => field === key)) {
                        this.formData.values[key] = foundData.fieldValue.join(', ') as never;
                    } else if (FieldsToSlice.find(field => field === key)) {
                        this.formData.values[key] = foundData.fieldValue.slice() as never;
                    } else if (FieldsToDate.find(field => field === key)) {
                        this.formData.values[key] = format(new Date(foundData.fieldValue), 'yyyy-MM-dd') as never;
                    } else {
                        this.formData.values[key] = foundData.fieldValue as never;
                    }
                } else if (!foundData.isCombined) {
                    this.formData.validation[key] = false;
                    this.formData.errorMessages[key] = foundData.validationMessage as never;
                }
            }
        });

        this.formData.values.selectedGlobalIds = [];
        this.formData.values.budgetIds.forEach(value => {
            this.formData.values.selectedGlobalIds.push({
                label: value.globalId,
                value: value.id,
            })
        });

        this.setCurrentValuesAsInitialValues();
    }

    private loadFromCombinedBudget(): void {
        if (!this.localCombinedBudget) {
            return;
        }

        this.combinedId = this.localCombinedBudget.combinedId;

        FormKeys.forEach(key => {
            if (!!this.localCombinedBudget[key] || !isNaN(this.localCombinedBudget[key]) || typeof this.localCombinedBudget[key] === 'boolean') {
                if (FieldsToString.find(field => field === key)) {
                    this.formData.values[key] = this.localCombinedBudget[key].toString() as never;
                } else if (ArrayFieldsToString.find(field => field === key)) {
                    this.formData.values[key] = this.localCombinedBudget[key].map(data => data.toString()) as never;
                } else if (FieldsToJoin.find(field => field === key)) {
                    this.formData.values[key] = this.localCombinedBudget[key].join(', ') as never;
                } else if (FieldsToSlice.find(field => field === key)) {
                    this.formData.values[key] = this.localCombinedBudget[key].slice() as never;
                } else if (FieldsToDate.find(field => field === key)) {
                    this.formData.values[key] = format(new Date(this.localCombinedBudget[key]), 'yyyy-MM-dd') as never;
                } else {
                    this.formData.values[key] = this.localCombinedBudget[key] as never;
                }
            }
        });

        this.formData.values.selectedGlobalIds = [];
        this.formData.values.budgetIds.forEach(value => {
            this.formData.values.selectedGlobalIds.push({
                label: value.globalId,
                value: value.id,
            })
        });

        this.setCurrentValuesAsInitialValues();
    }

    private setCurrentValuesAsInitialValues(): void {
        FormKeys.forEach(key => {
            this.formData.initialValues[key] = this.formData.values[key] as never;
        });
    }

    private setCurrentValuesAsRawValues(): void {
        FormKeys.forEach(key => {
            this.formData.values[key] = this.formData.rawValues[key] as never;
            this.formData.validation[key] = true;
            this.formData.errorMessages[key] = undefined;
        });
    }

    private async handleGlobalIdSearch(globalIdQuery: string): Promise<void> {
        if (!globalIdQuery || !this.formData.values.plantSid || !this.formData.values.year) {
            return;
        }

        const plantSid = this.formData.values.plantSid;
        const mtpYear = this.formData.values.year;

        this.globalIdsLoading = true;
        this.globalIdsQuery = await this.projectPortfolioService.searchGlobalId(plantSid, mtpYear, globalIdQuery);
        this.globalIdsLoading = false;
    }

    private get globalIdOptions(): PuiFormMultiselectOption<string>[] {
        return this.globalIdsQuery
            .slice(0, 10)
            .map(this.mapSearchResultToOption);
    }

    private mapSearchResultToOption(searchResult: GlobalId): PuiFormMultiselectOption<string> {
        return {
            label: searchResult.globalId,
            secondaryLabel: searchResult.name,
            value: searchResult.id
        };
    }

    private resetExternalId() {
        this.externalId = {
            value: '',
            loading: false,
            isValid: true,
            errorMessage: '',
        }
    }

    private async getDataByExternalId() {
        const id = this.externalId.value.trim().toUpperCase();

        if (id.trim().length !== 0) {
            this.externalId.isValid = true;
            this.externalId.errorMessage = '';
            this.externalId.loading = true;
            const payload = {
                externalId: id,
                existingExternalIds: [],
                userSelectionMaintenancePlanningIds: [],
            };
            try {
                this.validateExternalId(this.formData.values.externalIds, this.externalId.value);
                let externalData = await this.CrossAppLinkingService.getExternalData(payload);
                if (externalData.isRequestValid) {
                    this.resetExternalId();
                    this.formData.values.externalIds.push(externalData.externalId);
                } else {
                    this.externalId.isValid = false;
                    this.externalId.errorMessage = externalData.errorMessage;
                }
            } catch (error) {
                this.externalId.isValid = false;
                if (error.response !== undefined && error.response.status === 400) {
                    this.externalId.errorMessage = error.response.data;
                } else {
                    this.externalId.errorMessage = error.message;
                }
            } finally {
                this.externalId.loading = false;
            }
        }
    }

    private handleExternalIdRemove(externalId: string) {
        this.formData.values.externalIds = this.formData.values.externalIds.filter(element => element !== externalId);
    }

    private validateExternalId(externalIds: string[], currentExternalId: any) {
        // Check duplicates
        if (externalIds.find((e: string) => e === currentExternalId)) {
            throw new Error('External id ' + currentExternalId + ' already exists.');
        }
        // Check if external id ends in M/S/R/O
        let currentIdType = currentExternalId.slice(currentExternalId.length - 1);
        if (currentIdType !== 'M' && currentIdType !== 'S' && currentIdType !== 'R' && currentIdType !== 'O') {
            throw new Error('External id ' + currentExternalId + ' is not valid.');
        }
        // Compare external id with previously added ids
        if (externalIds.length > 0 && !this.formData.values.lumpSum) {
            let previousIdsType = externalIds[0].slice(externalIds[0].length - 1);

            if ((currentIdType === 'M' && previousIdsType !== 'M') ||
                (currentIdType === 'S' && previousIdsType !== 'S') ||
                ((currentIdType === 'R' || currentIdType === 'O') && (previousIdsType === 'M' || previousIdsType === 'S'))) {
                throw new Error('Cannot add external id ' + currentExternalId + ' along with previous external ids.');
            }
        }
    }

    // Check risk/maintenance ids for aero classification validation.
    private validateExternalIdForAero(): void {
        if (this.formData.values.externalIds.length > 0) {
            let ids = this.formData.values.externalIds;
            let types = [];
            ids.forEach(id => {
                types.push(id.slice(id.length - 1).toUpperCase());
            });
            this.formData.validation.aeroClassification = false;
            if (types.includes('M')) {
                if (this.formData.values.aeroClassification.toString() === '1' || this.formData.values.aeroClassification.toString() === '5') {
                    this.formData.validation.aeroClassification = true;
                }
            }
            if (types.includes('S')) {
                if (this.formData.values.aeroClassification.toString() === '4' || this.formData.values.aeroClassification.toString() === '5') {
                    this.formData.validation.aeroClassification = true;
                }
            }
            if (types.includes('R')) {
                if (this.formData.values.aeroClassification.toString() === '2' || this.formData.values.aeroClassification.toString() === '5') {
                    this.formData.validation.aeroClassification = true;
                }
            }
            if (types.includes('O')) {
                if (this.formData.values.aeroClassification.toString() === '3' || this.formData.values.aeroClassification.toString() === '2' || this.formData.values.aeroClassification.toString() === '5') {
                    this.formData.validation.aeroClassification = true;
                }
            }
            if (types.includes('O') && types.includes('R')) {
                if (this.formData.values.aeroClassification.toString() === '3' || this.formData.values.aeroClassification.toString() === '5') {
                    this.formData.validation.aeroClassification = false;
                }
            }
            if (types.length > 1 && this.formData.values.lumpSum) {
                this.formData.validation.aeroClassification = true;
            }
            if (types.includes('M') && this.formData.values.lumpSum) {
                if (this.formData.values.aeroClassification.toString() === '1' || this.formData.values.aeroClassification.toString() === '4' || this.formData.values.aeroClassification.toString() === '5') {
                    this.formData.validation.aeroClassification = true;
                }
            }
            if (types.includes('R') && this.formData.values.lumpSum) {
                if (this.formData.values.aeroClassification.toString() === '2' || this.formData.values.aeroClassification.toString() === '3' || this.formData.values.aeroClassification.toString() === '5') {
                    this.formData.validation.aeroClassification = true;
                }
            }
            if (types.includes('S') && this.formData.values.lumpSum) {
                if (this.formData.values.aeroClassification.toString() === '1' || this.formData.values.aeroClassification.toString() === '4' || this.formData.values.aeroClassification.toString() === '5') {
                    this.formData.validation.aeroClassification = true;
                }
            }
            if (types.includes('O') && this.formData.values.lumpSum) {
                if (this.formData.values.aeroClassification.toString() === '2' || this.formData.values.aeroClassification.toString() === '3' || this.formData.values.aeroClassification.toString() === '5') {
                    this.formData.validation.aeroClassification = true;
                }
            }
            if (!this.formData.validation.aeroClassification) {
                this.formData.errorMessages.aeroClassification = 'Please check Risk/Maintenace ID.';
            }
        }
    }

    private get isEditDisabled(): boolean {
        const currentDate = new Date(Date.now());
        const startDate = new Date(this.mtpPeriod.mtpStartDate);
        const endDate = addYears(new Date(this.year, 11, 31), 1);

        if (parseInt(this.year.toString()) === this.currentYear) {
            return !(startDate <= currentDate && currentDate <= endDate);
        }

        return !(currentDate <= endDate);
    }

    private async checkMtpPeriod(): Promise<void> {
        if (localStorage.mtpPeriod) {
            this.mtpPeriod = JSON.parse(localStorage.mtpPeriod);
        } else {
            try {
                this.mtpPeriod = await this.MtpService.GetMtpPeriod(this.currentYear);
                localStorage.setItem('mtpPeriod', JSON.stringify(this.mtpPeriod));
            } catch (error) {
                if (error.response !== undefined && error.response.status === 400) {
                    localStorage.removeItem('mtpPeriod');
                }
            }
        }
    }
}
