import Vue from 'vue';
import Component from 'vue-class-component';
import { EventBus } from '~/utils/eventbus';

@Component({})
export default class DeleteRemainTableModalComponent extends Vue {
    private selectedDeleteBr: boolean = false;

    constructor() {
        super();
    }

    private deleteBr() {
        this.selectedDeleteBr = true;
        EventBus.$emit(EventBus.DELETE_BUDGET_REQUEST.DELETE_MODAL, this.selectedDeleteBr);
        this.$emit('deleteonlydraftandsubmitted', this.selectedDeleteBr);
    }
}
