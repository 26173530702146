import Vue from 'vue';
import Component from 'vue-class-component';
import './second-move-bulk-modal.scss';

@Component({})
export default class SecondMoveBulkModalComponent extends Vue {

    private moveBulk() {
        this.$emit('confirmBulkMove');
        $('#secondMoveBulkModal').modal('hide');
    }
}
