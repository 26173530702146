var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tag-selector"
  }, [_c('pml-type-ahead', {
    key: _vm.hiddenOptions.length,
    ref: _vm.REF_CONSTANTS.PML_TYPE_AHEAD,
    attrs: {
      "placeholder": "Please type",
      "request-promise-generator": _vm.focusTopicsPromiseGenerator
    },
    model: {
      value: _vm.selectedOption,
      callback: function ($$v) {
        _vm.selectedOption = $$v;
      },
      expression: "selectedOption"
    }
  }), _c('div', {
    staticClass: "tag-selector__button"
  }, [_c('pui-button', {
    attrs: {
      "state": "primary",
      "disabled": _vm.isDisabled
    },
    on: {
      "click": _vm.onTriggerImport
    }
  }, [_vm._v(" Assign Focus Topic ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }