var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['global-id-cell', {
      'global-id-cell__warning-label': _vm.showWarning
    }]
  }, [_c('router-link', {
    attrs: {
      "to": {
        path: _vm.href,
        query: _vm.query
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _vm.showWarning ? _c('pui-icon', {
    attrs: {
      "id": ("tooltip-target-warning-" + (_vm.params.data.id)),
      "icon-color": "#fd7e14",
      "icon-name": "warning-circle"
    }
  }) : _vm._e(), _vm.showWarning ? _c('b-tooltip', {
    attrs: {
      "target": ("tooltip-target-warning-" + (_vm.params.data.id)),
      "custom-class": "hover-table",
      "triggers": "hover"
    }
  }, [_vm._v(" Some of the fields in this budget request have different values in MSP/PT Risk. ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }