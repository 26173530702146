var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('pui-lightbox', {
    ref: _vm.REF_CONSTANTS.LIGHTBOX,
    attrs: {
      "fit-content": true,
      "on-confirm-callback": _vm.submitForm,
      "default-footer-confirm-disabled": !_vm.isSubmitButtonEnabled,
      "default-footer-cancel-label": "Cancel",
      "default-footer-confirm-label": "Save Changes",
      "default-header-label": "Edit Approval Workflow"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "edit-approval-modal-container"
        }, [_c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.isFormLoading,
            expression: "!isFormLoading"
          }]
        }, [_vm.hasError ? _c('pui-form-inline-notification', {
          attrs: {
            "type": "error",
            "title": "An error has occurred!",
            "text": "An internal server error has occurred while attempting to edit the approval workflow, please try again!"
          }
        }) : _vm._e(), _c('pui-grid-container', _vm._l(_vm.modalData, function (approver) {
          return _c('pui-grid-row', {
            key: approver.departmentGroupName
          }, [_c('pui-grid-column', {
            attrs: {
              "cols": 12
            }
          }, [_c('pui-form-group', {
            attrs: {
              "label": approver.departmentGroupName
            }
          }, [_c('uam-user-input', {
            attrs: {
              "initial-value": approver,
              "is-valid": approver.label !== '' || approver.value !== '',
              "is-disabled": approver.hasResponse
            },
            on: {
              "update:user": function ($event) {
                return _vm.userSelected(approver.departmentGroupName, $event);
              },
              "clear:user": function ($event) {
                return _vm.userCleared(approver.departmentGroupName);
              }
            }
          })], 1)], 1)], 1);
        }), 1)], 1), _c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.isFormLoading,
            expression: "isFormLoading"
          }]
        }, [_c('pui-loader-spinner', {
          attrs: {
            "message": "Saving your changes...",
            "min-height": "400px",
            "title": "Please wait!"
          }
        })], 1)])];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }