import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import '~/components/home/home.scss';
import { DeleteMiniFdTagsPayload } from '~/models/services/cr-period';
import { DeleteMtpTagsPayload } from '~/models/services/mtp';
import { Tag, TagsConfig, TagsMode } from '~/models/services/tags';
import { CrService } from '~/services/cr-service';
import { FocusTopicsService } from '~/services/focus-topics-service';
import { MtpService } from '~/services/mtp-service';
import TagSelector from './tag-selector/tag-selector.vue';

@Component({
    components: {
        TagSelector,
    }
})
export default class TagsComponent extends Vue {
    public MtpService: MtpService = new MtpService();
    public CrService: CrService = new CrService();
    public FocusTopicService: FocusTopicsService = new FocusTopicsService();

    private tags: Tag[] = [];
    private loading: boolean = false;

    private readonly CONFIG: TagsConfig = {
        [TagsMode.BR]: {
            getFn: (requestId: string) => this.MtpService.getMtpTags(requestId),
            deleteFn: this.removeMtpTag,
        },
        [TagsMode.CR]: {
            getFn: (requestId: string) => this.CrService.getMiniFdTags(requestId),
            deleteFn: this.removeMiniFdTag,
        },
        [TagsMode.EMIT]: {
            getFn: () => {},
            deleteFn: this.removeEmitTag,
        }
    } as const;

    @Prop({ default: () => false })
    private viewOnly: boolean;

    @Prop()
    private currentMode!: TagsMode;

    @Prop()
    private requestId: string;

    @Prop()
    private initialTags: Tag[];

    private mounted() {
        this.tags = this.initialTags;
    }

    private get isEmit(): boolean {
        return this.currentMode === TagsMode.EMIT;
    }

    private async loadTags() {
        this.loading = true;
        this.tags = await this.CONFIG[this.currentMode].getFn(this.requestId);
        this.$emit('tags-list-changed', this.tags);
        this.loading = false;
    }

    private async removeTag(id: number) {
        this.loading = true;
        await this.CONFIG[this.currentMode].deleteFn(id);
        this.loading = false;

        if(this.isEmit) {
            return;
        }

        this.loadTags();
    }

    private async removeMtpTag(id: number) {
        const payload: DeleteMtpTagsPayload = {
            mtpId: this.requestId,
            mtpTagIds: [id],
        };

        await this.MtpService.deleteMtpTags(payload);
    }

    private async removeMiniFdTag(id: number) {
        const payload: DeleteMiniFdTagsPayload = {
            miniFdId: this.requestId,
            miniFdTagIds: [id],
        };

        await this.CrService.deleteMiniFdTags(payload);
    }

    private removeEmitTag(id: number) {
        this.tags = this.tags.filter(tag => tag.focusTopicId !== id.toString());
        this.$emit('tags-list-changed', this.tags);
    }

    private addEmitTag(tag: Tag) {
        this.tags.push(tag);
        this.$emit('tags-list-changed', this.tags);
    }
}
