import { Api } from '@coode/fe-sdk/dist/networking';
import Vue from 'vue';
import { AxiosPromise, AxiosRequestConfig } from 'axios';

export class Connection {
    private api: Api;

    public constructor(api?: Api) {
        this.api = api ?? Vue.prototype.$sdk.$http.$generic.request.api;
    }

    public get<T = any>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
        return this.api.get<T>(url, config);
    }

    public post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
        return this.api.post<T>(url, data, config);
    }

    public put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): AxiosPromise<T> {
        return this.api.put<T>(url, data, config);
    }

    public delete(url: string, config?: AxiosRequestConfig): AxiosPromise {
        return this.api.delete(url, config);
    }
}
