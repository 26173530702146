import { Connection } from '~/utils';
import { CancelToken } from 'axios';
import { DeleteFileResponse, GetFilesResponse, UploadFileResponse } from '~/models/services/file';
import {
    AddMiniFdComment,
    AddMiniFdRequestData,
    BudgetRequestsMiniFinancialDecision, DeleteMiniFdResponse,
    DepartmentGroups,
    EditBudgetCompensationPayload,
    EditBudgetCompensationResponse,
    EditMiniFdRequestData, EditReviewerInApprovalWorkflowPayload, EstimatedPrioritization, GetDepartmentsResponse,
    GetMiniFdByGlobalIdResponse,
    GetMiniFdContributorsResponse,
    GetMiniFdHistoryResponse, MiniFd, MiniFdComment,
    MiniFdReview, MiniFdStatus,
    SearchMiniFdHistoryPayload,
    SearchMiniFdRequestsPayload,
    SearchMiniFdResponse, SetInactiveMiniFdResponse
} from '~/models/services/minifd';
import { ApiResponse, FileResponse } from '~/models/services/common';

export class MiniFdService {
    private readonly api: Connection = new Connection();

    public async addMiniFd(data: AddMiniFdRequestData): Promise<MiniFd> {
        return (await this.api.post<ApiResponse<MiniFd>>('mini-fd', data)).data.result;
    }

    public async editMiniFd(data: EditMiniFdRequestData): Promise<MiniFd> {
        return (await this.api.put<ApiResponse<MiniFd>>('mini-fd', data)).data.result;
    }

    public async getAllMiniFds(data: SearchMiniFdRequestsPayload, cancelToken?: CancelToken): Promise<SearchMiniFdResponse> {
        return (await this.api.post<ApiResponse<SearchMiniFdResponse>>('mini-fd/search', data, { cancelToken })).data.result;
    }

    public async getMiniFdByUniqueId(uniqueId: string, hasMiniFd?: boolean): Promise<GetMiniFdByGlobalIdResponse> {
        let queryString = '';
        if (hasMiniFd) {
            queryString += `?hasMiniFd=${hasMiniFd}`;
        }
        return (await this.api.get<ApiResponse<GetMiniFdByGlobalIdResponse>>(`mini-fd/${uniqueId}${queryString}`)).data.result;
    }

    public async addMiniFdComment(data: AddMiniFdComment): Promise<MiniFdComment> {
        return (await this.api.post<ApiResponse<MiniFdComment>>('mini-fd/comment', data)).data.result;
    }

    public async continueWorkflow(data: MiniFdReview, workflowInstanceId: string, miniFdId: string): Promise<void> {
        return (await this.api.post<ApiResponse<void>>(`mini-fd/workflow/continue/${workflowInstanceId}/${miniFdId}`, data)).data.result;
    }

    public async changeStatus(uniqueId: string, status: MiniFdStatus): Promise<MiniFd> {
        const miniFdData = await this.getMiniFdByUniqueId(uniqueId);

        if (!miniFdData.primaryDepartment) {
            switch (miniFdData.category) {
                case 1:
                    miniFdData.primaryDepartment = 2;
                    break;
                case 2:
                    miniFdData.primaryDepartment = 1;
                    break;
                case 3:
                    miniFdData.primaryDepartment = 1;
                    break;
                case 4:
                    miniFdData.primaryDepartment = 2;
                    break;
                default:
                    miniFdData.primaryDepartment = 2;
            }
        }

        const budgetRequestsMiniFinancialDecisions: BudgetRequestsMiniFinancialDecision[] = miniFdData.budgetRequestsMiniFinancialDecisions.map(e => ({
            ...e,
            budgetRequest: undefined
        }));

        const payload: EditMiniFdRequestData = {
            ...miniFdData,
            miniFdTags: miniFdData.tags.map(tag => tag.focusTopicId),
            budgetRequestsMiniFinancialDecisions,
            budgetRequests: [],
            miniFinancialDecisions: [],
            status,
        };

        return this.editMiniFd(payload);
    }

    public async getDepartments(): Promise<DepartmentGroups[]> {
        return (await this.api.get<ApiResponse<GetDepartmentsResponse>>('mini-fd/department')).data.result.departmentGroups;
    }

    public async getHistory(data: SearchMiniFdHistoryPayload): Promise<GetMiniFdHistoryResponse> {
        return (await this.api.get<ApiResponse<GetMiniFdHistoryResponse>>(`mini-fd/get-history/${data.pageSize}/${data.page}/${data.miniFdId}`)).data.result;
    }

    public async addFile(file: FormData): Promise<ApiResponse<UploadFileResponse>> {
        return (await this.api.post<ApiResponse<UploadFileResponse>>('mini-fd/file', file, { headers: { 'Content-Type': 'multipart/form-data; boundary=${data._boundary}' } })).data;
    }

    public async getFiles(miniFdId: string, year: number): Promise<GetFilesResponse> {
        return (await this.api.get<ApiResponse<GetFilesResponse>>(`file/list?miniFdId=${miniFdId}&year=${year}`)).data.result;
    }

    public async downloadFile(fileId: string): Promise<Blob> {
        return (await this.api.get<Blob>(`mini-fd/file/download/${fileId}`, { responseType: 'blob' })).data;
    }

    public async deleteFile(fileId: string): Promise<DeleteFileResponse> {
        return (await this.api.delete(`mini-fd/file/${fileId}`)).data.result;
    }

    public async exportToPdf(miniFdId: string): Promise<FileResponse | undefined> {
        const content = await this.api.get<Blob>(`mini-fd/export-to-pdf/${miniFdId}`, { responseType: 'blob' });
        const name = content.headers['content-disposition'] ? content.headers['content-disposition'].split('UTF-8\'\'')[1] : 'prioritization-export.xlsx';

        if (content.status === 204) {
            return;
        }

        return {
            name,
            content: content.data
        };
    }

    public async editBudgetCompensation(data: EditBudgetCompensationPayload): Promise<EditBudgetCompensationResponse> {
        return (await this.api.put<ApiResponse<EditBudgetCompensationResponse>>('budget-compensation', data)).data.result;
    }

    public async getMiniFdContributors(miniFdId: string): Promise<GetMiniFdContributorsResponse> {
        return (await this.api.get<ApiResponse<GetMiniFdContributorsResponse>>(`mini-fd/get-contributors/${miniFdId}`)).data.result;
    }

    public async setMiniFdInactive(miniFdId: string): Promise<SetInactiveMiniFdResponse> {
        return (await this.api.post<ApiResponse<SetInactiveMiniFdResponse>>('mini-fd/set-inactive', { miniFdId })).data.result;
    }

    public async deleteMiniFd(miniFdId: string): Promise<DeleteMiniFdResponse> {
        return (await this.api.post<ApiResponse<DeleteMiniFdResponse>>('mini-fd/delete', { miniFdId })).data.result;
    }

    public async editReviewerInApprovalWorkflow(data: EditReviewerInApprovalWorkflowPayload): Promise<void> {
        await this.api.put<ApiResponse<{}>>('mini-fd/workflow', data);
    }

    public async getEstimatedPrioritization(uniqueId: string): Promise<EstimatedPrioritization> {
        return (await this.api.get<ApiResponse<EstimatedPrioritization>>(`mini-fd/estimated-prioritization/${uniqueId}`)).data.result;
    }

    public async runEstimatedPrioritization(uniqueId: string): Promise<void> {
        await this.api.post<ApiResponse<{}>>(`mini-fd/estimated-prioritization?UniqueId=${uniqueId}`);
    }
}
