import Vue from 'vue';
import Component from 'vue-class-component';
import { Data } from '~/utils';
import { Watch } from 'vue-property-decorator';

@Component({})
export default class PlantSelectorComponent extends Vue {
    private data = Data.Instance;
    private selectedOptions = [];

    private get selectionOptions() {
        return this.data.loadedPlants.map((plant) => ({
            label: plant.plantName,
            value: plant.plantSid,
        }));
    }

    private get hasSelectableOptions(): boolean {
        return this.selectionOptions.length > 0;
    }

    private selectedOptionsChanged() {
        this.data.saveSelectedPlantsById(this.selectedOptions);
    }

    @Watch('data.loadedPlants', { immediate: true, deep: true })
    private selectFirstWhenPlantsAreLoaded() {
        if (!this.data.loadedPlants || this.data.loadedPlants.length === 0) {
            return;
        }

        if (localStorage.getItem('selectedPlantsSids')) {
            const existingPlants = this.data.loadedPlants.filter((plant) => JSON.parse(localStorage.getItem('selectedPlantsSids')).includes(plant.plantSid));

            if (existingPlants.length) {
                const selectionOptions = existingPlants.map((plant) => ({
                    label: plant.plantName,
                    value: plant.plantSid,
                }));

                this.selectedOptions = selectionOptions.map(option => option.value);
                return;
            }

            this.selectAllPlants();
            return;
        }

        this.selectAllPlants();
    }

    private selectAllPlants() {
        this.selectedOptions = this.selectionOptions.map(option => option.value);
        this.selectedOptionsChanged();
    }

    private deselectAllPlants() {
        this.selectedOptions = [];
        this.selectedOptionsChanged();
    }
}
