var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal fade",
    attrs: {
      "id": "addBudgetRequestsModal",
      "aria-hidden": "true",
      "aria-labelledby": "exampleModalLabel",
      "data-backdrop": "static",
      "role": "dialog",
      "tabindex": "-1"
    }
  }, [_c('b-toast', {
    attrs: {
      "id": "toast-exception",
      "auto-hide-delay": "20000",
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center max-width-toast-update-mtp"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [_c('div', [_c('icon', {
    staticClass: "icon-toast text-center",
    staticStyle: {
      "color": "#F21100"
    },
    attrs: {
      "name": "times"
    }
  })], 1), _c('div', [_c('span', [_vm._v("Something bad happened.")])]), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideToast();
      }
    }
  }, [_vm._v(" Dismiss ")])])]), _c('b-toast', {
    attrs: {
      "id": "toast-error-import",
      "auto-hide-delay": _vm.errorMessage && _vm.errorMessage.requestIsValid ? 20000 : 600000,
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center toast-error-import"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [_vm.errorMessage ? [_vm.errorMessage.requestIsValid ? _c('div', [_c('icon', {
    staticClass: "icon-toast text-center",
    attrs: {
      "name": "check"
    }
  })], 1) : _vm._e(), !_vm.errorMessage.requestIsValid ? _c('div', [_c('icon', {
    staticClass: "icon-toast text-center",
    staticStyle: {
      "color": "#F21100"
    },
    attrs: {
      "name": "times"
    }
  })], 1) : _vm._e()] : _vm._e(), _vm.errorMessage ? _c('div', [_c('span', [_vm._v(_vm._s(_vm.errorMessage.text))]), _vm.errorMessage.requestIsValid ? _c('span', [_vm._v("You can find them at the top of your budget requests table. ")]) : _vm._e(), !_vm.errorMessage.requestIsValid ? _c('span', {
    staticClass: "pointerLink",
    on: {
      "click": function ($event) {
        return _vm.showModalError();
      }
    }
  }, [_vm._v("Please click here to check all the errors ")]) : _vm._e()]) : _vm._e(), _c('button', {
    staticClass: "btn btn-outline-primary",
    on: {
      "click": function ($event) {
        return _vm.hideToast();
      }
    }
  }, [_vm._v(" Dismiss ")])], 2)]), _c('div', {
    staticClass: "modal-dialog modal-lg",
    attrs: {
      "role": "document"
    }
  }, [_c('div', {
    staticClass: "modal-content"
  }, [_c('div', {
    staticClass: "modal-header"
  }, [_c('h5', {
    staticClass: "modal-title",
    attrs: {
      "id": "exampleModalLabel"
    }
  }, [_vm._v(" Add budget request(s) ")]), _c('button', {
    staticClass: "close",
    attrs: {
      "aria-label": "Close",
      "data-dismiss": "modal",
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.close();
      }
    }
  }, [_c('span', {
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("×")])])]), _c('div', {
    staticClass: "modal-body"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm._m(0), _vm.userPermissions.includes('BudgetRequestDetailsEdit') ? _c('div', {
    staticClass: "col-12 col-md-6 text-center border-right"
  }, [_vm.data.plants ? _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.appliedPlant,
      expression: "appliedPlant"
    }],
    staticClass: "selectPlant",
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.appliedPlant = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, function ($event) {
        return _vm.changeSelectedPlant();
      }]
    }
  }, [_c('option', {
    attrs: {
      "disabled": "",
      "hidden": "",
      "selected": "",
      "value": ""
    }
  }, [_vm._v(" Select plant ")]), _vm._l(_vm.data.plants, function (plant, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": plant.plantSid
      }
    }, [_vm._v(" " + _vm._s(plant.plantName) + " ")]);
  })], 2) : _vm._e(), _c('div', {
    staticClass: "text-center pb-4 pt-3"
  }, [_c('icon', {
    staticClass: "icon-add",
    attrs: {
      "name": "regular/plus-square"
    }
  })], 1), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "disabled": _vm.isInvalid || _vm.appliedPlant === ''
    },
    on: {
      "click": function ($event) {
        return _vm.addBr();
      }
    }
  }, [_vm._v(" Create in PML ")]), _c('p', {
    staticClass: "text-center txt-add"
  }, [_vm._v(" A step by step form for creating a request ")]), _c('a', {
    staticClass: "button-edit",
    on: {
      "click": function ($event) {
        return _vm.addGlobalIdInput();
      }
    }
  }, [_vm._v("Utilize existing budget request ")]), _vm.showGlobalIdInput ? _c('div', {
    staticClass: "form-row justify-content-center duplicate"
  }, [_c('div', {
    staticClass: "form-group col-lg-6 col-md-12"
  }, [_c('label', [_vm._v("Global ID")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.globalId,
      expression: "globalId"
    }],
    staticClass: "form-control ",
    class: [_vm.isInvalid ? 'is-invalid' : ''],
    attrs: {
      "id": "globalId",
      "autocomplete": "none",
      "type": " text"
    },
    domProps: {
      "value": _vm.globalId
    },
    on: {
      "focusout": function ($event) {
        return _vm.validateGlobalId();
      },
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.globalId = $event.target.value;
      }
    }
  }), _c('div', {
    staticClass: "invalid-feedback"
  }, [_vm._v(" Global ID is invalid. ")])]), _c('div', {
    staticClass: "form-group col-lg-6 col-md-12"
  }, [_c('label', [_vm._v("MTP year")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.mtpYear,
      expression: "mtpYear"
    }],
    staticClass: "form-control",
    attrs: {
      "disabled": _vm.mtpList.length < 1
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.mtpYear = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, _vm._l(_vm.mtpList, function (year, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": year
      }
    }, [_vm._v(" " + _vm._s(year) + " ")]);
  }), 0)]), _vm.showGlobalIdInput ? _c('div', {
    staticClass: "col-lg-12 col-md-12"
  }, [_c('span', {
    staticClass: "wrapper-icon",
    attrs: {
      "id": "tooltip-target-1"
    }
  }, [_c('icon', {
    staticClass: "icon-info",
    attrs: {
      "name": "info"
    }
  })], 1), _c('b-tooltip', {
    attrs: {
      "custom-class": "my-tooltip-class",
      "target": "tooltip-target-1",
      "triggers": "hover"
    }
  }, [_c('div', [_vm._v("Utilize existing budget request:")]), _c('div', [_vm._v(" To create a new budget request using data from an existing budget request, you need to enter the Global ID and select the MTP year of an existing budget request and then press 'Create in PML' button. ")])])], 1) : _vm._e()]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "col-12 col-md-6 text-center"
  }, [_vm.userPermissions.includes('BudgetRequestDetailsEdit') ? _c('div', [_c('div', {
    staticClass: "text-center pb-4 pt-5"
  }, [_c('icon', {
    staticClass: "icon-add",
    attrs: {
      "name": "paperclip"
    }
  })], 1), _c('input', {
    staticClass: "btn fileupload btn-primary",
    attrs: {
      "id": "file",
      "accept": ".xlsx, .xls",
      "name": "file",
      "type": "file"
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "change": function ($event) {
        return _vm.upload($event);
      }
    }
  }), _c('label', {
    staticClass: "btn btn-primary pointerLink",
    attrs: {
      "for": "file"
    }
  }, [_vm._v("Upload spreadsheet ")]), _c('p', {
    staticClass: "text-center txt-add"
  }, [_vm._v(" Select a file or simply drag and drop your file here ")])]) : _vm._e(), _vm.userPermissions.includes('MtpSiteDocumentsView') ? _c('a', {
    staticClass: "button-edit",
    on: {
      "click": function ($event) {
        return _vm.downloadTemplate();
      }
    }
  }, [_vm._v(" Download Excel Template ")]) : _vm._e()])])]), _c('div', {
    staticClass: "modal-footer"
  })])])])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-12"
  }, [_c('p', [_vm._v("Please select how you would like to add your request(s)")])]);
}]

export { render, staticRenderFns }