import Vue from 'vue';
import Component from 'vue-class-component';
import { SelectItem } from '~/utils/form-validation';
import { Data, User } from '~/utils';
import { zeroIfNaN } from '~/utils/math-helper';
import { MasterService } from '~/services/master-service';
import { convertBrowserDateToServerDate } from '~/utils/date-helper';
import IybrCostTableComponent
    from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/iybr-cost-table/iybr-cost-table.vue';
import { BudgetRequestPlanningStatus, NewBudgetRequests } from '~/utils/interfaces';
import {
    CompensationRequiredTotals,
    CreateIybrFormCompensationModel,
    CreateIybrFormModel
} from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/create-iybr-form-model';
import moment from 'moment';
import IybrCompensationTableComponentTs, {
    CompensationTableTotals
} from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/iybr-compensation-table/iybr-compensation-table';
import IybrCompensationTableComponent
    from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/iybr-compensation-table/iybr-compensation-table.vue';
import AddCompensationModalComponent
    from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/add-compensation-modal/add-compensation-modal.vue';
import {
    ChangeModalDataPayload
} from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/add-compensation-modal/add-compensation-modal';
import { ROWS } from '~/components/in-year-budget-requests/constants/rows';
import LinkedExternalIdsCollapsibleComponent
    from '~/components/in-year-budget-requests/linked-external-ids-collapsible/linked-external-ids-collapsible.vue';
import FormApprovalWorkflow from './form-approval-workflow/form-approval-workflow.vue';
import FormApprovalWorkflowTs from './form-approval-workflow/form-approval-workflow';
import { Prop } from 'vue-property-decorator';
import IybrGlobalIdsCollapsibleComponentTs, {
    IybrGlobalIdsCollapsibleTableData
} from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/iybr-global-ids-collapsible/iybr-global-ids-collapsible';
import IybrGlobalIdsCollapsibleComponent
    from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/iybr-global-ids-collapsible/iybr-global-ids-collapsible.vue';
import {
    RequestOptions
} from '~/components/in-year-budget-requests/add-in-year-budget-requests-modal/add-in-year-budget-requests-modal';
import IybrNewProjectCollapsibleComponentTs, {
    IybrNewProjectCollapsibleFormData
} from './iybr-new-project-collapsible/iybr-new-project-collapsible';
import IybrNewProjectCollapsibleComponent from './iybr-new-project-collapsible/iybr-new-project-collapsible.vue';
import FilesChangeRequestComponent from './files-change-request/files-change-request.vue';
import {
    FORM_EVENTS
} from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/iybr-new-project-collapsible/create-iybr-form-utils';
import { OutageType } from '~/models/services/budget-request';
import {
    AddMiniFdRequestData,
    BudgetRequestsMiniFinancialDecision,
    EditMiniFdRequestData,
    GetMiniFdByGlobalIdResponse,
    MiniFdStatus,
    SelectedStakeholder,
    Stakeholder
} from '~/models/services/minifd';
import OptionalNotifiableUsersComponent
    from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/optional-notifiable-users/optional-notifiable-users.vue';
import { DEPARTMENT_GROUP_NAMES } from '~/components/in-year-budget-requests/constants/department-group-names';
import { PlantAttributesItem } from '~/models/services/plants';
import Tags from '~/components/focus-topics/tags/tags.vue';
import { Tag, TagsMode } from '~/models/services/tags';
import { Permission } from '~/utils/permission';

const REF_CONSTANTS = {
    COST_TABLE: 'costTable',
    COMPENSATION_TABLE: 'compensationTable',
    FORM_APPROVAL_WORKFLOW: 'formApprovalWorkflow',
    FILES_CHANGE_REQUEST: 'filesChangeRequest',
    GLOBAL_IDS_COLLAPSIBLE: 'globalIdsCollapsible',
    NEW_PROJECT_COLLAPSIBLE: 'newProjectCollapsible'
} as const;

export enum MiniFdType {
    MFD = '1',
    FD = '2',
    SFD = '3',
}

@Component({
    components: {
        iybrCostTable: IybrCostTableComponent,
        iybrCompensationTable: IybrCompensationTableComponent,
        addCompensationModal: AddCompensationModalComponent,
        linkedExternalIdsCollapsible: LinkedExternalIdsCollapsibleComponent,
        formApprovalWorkflow: FormApprovalWorkflow,
        iybrGlobalIdsCollapsible: IybrGlobalIdsCollapsibleComponent,
        iybrNewProjectCollapsible: IybrNewProjectCollapsibleComponent,
        filesChangeRequest: FilesChangeRequestComponent,
        optionalNotifiableUsers: OptionalNotifiableUsersComponent,
        Tags,
    }
})
export default class CreateIybrFormComponent extends Vue {
    private crTagsMode: TagsMode = TagsMode.CR;
    private emitTagsMode: TagsMode = TagsMode.EMIT;
    private tagsList: Tag[] = [];
    private readonly MINI_FD_YEARS = Data.Instance.constants.miniFdYearFilters;
    private data: Data = Data.Instance;
    private MiniFdStatus = MiniFdStatus;

    $refs!: {
        [REF_CONSTANTS.COST_TABLE]: IybrCostTableComponent,
        [REF_CONSTANTS.COMPENSATION_TABLE]: IybrCompensationTableComponentTs,
        [REF_CONSTANTS.FORM_APPROVAL_WORKFLOW]: FormApprovalWorkflowTs,
        [REF_CONSTANTS.FILES_CHANGE_REQUEST]: FilesChangeRequestComponent
        [REF_CONSTANTS.GLOBAL_IDS_COLLAPSIBLE]: IybrGlobalIdsCollapsibleComponentTs,
        [REF_CONSTANTS.NEW_PROJECT_COLLAPSIBLE]: IybrNewProjectCollapsibleComponentTs
    };
    public formMetadata = {
        showAddBudgetCompensationModal: false,
        isLoaded: false,
        currentlyValidating: false,
        hasErrors: false,
        fieldsWithErrors: Array<string>(),
        currencyCode: '',
        currencyId: '',
        areTablesValid: true,
        isApprovalWorkflowFormValid: true,
        isAdditionalInformationValid: true,
        isCompensationAvailableExceedingRequired: false,
        isCompensatedValueValid: true,
    };
    private readonly SWEDEN_COUNTRY_CODE = "NO";
    private readonly REF_CONSTANTS = REF_CONSTANTS;
    private readonly formLayout = ROWS;
    private readonly TWO_MILLION_EUROS = 2000000;
    private readonly NEW_PROJECT = RequestOptions.NewProject.toString();
    private readonly DEFAULT_POPOVER_CONFIGURATION = { hasCloseIcon: false };
    @Prop()
    private editMode: boolean;
    private readonly plantsService = MasterService.instance.plantsService;
    private readonly miniFdService = MasterService.instance.miniFdService;
    private uniqueId = '';
    private linkedBudgetRequests: NewBudgetRequests[] = [];
    private existingChangeRequest: GetMiniFdByGlobalIdResponse = null;
    private plantInfo: PlantAttributesItem | null = null;
    private costTableTotal = 0;
    private isMaintenanceAeroClassification = null;
    private uniqueIdInformationError = false;
    private selectedTab = 0;
    private budgetRequestMtpYear?: number = null;
    private isFlaggedProject = false;
    private isAssetStrategyRequired = false;
    private selectedLocalCompensation = '';
    private isHydroTechnology = false;
    private isSteamTechnology = false;
    private miniFdYear?: number = null;
    private confirmedExternalIds: string[] = [];
    private plantSid?: string = null;
    private unitSid?: string = null;
    private newUniqueId?: string = null;
    private newChangeRequestId?: string = null;
    private compensationModel: CreateIybrFormCompensationModel = {};
    private compensationTotals?: CompensationTableTotals = null;
    private compensationRequired?: CompensationRequiredTotals = null;
    private newProjectFormData: IybrNewProjectCollapsibleFormData = {
        spentYearMinus1: '',
        spentYear: '',
        spentYearPlus1: '',
        spentYearPlus2: '',
        spentYearPlus3: '',
        spentAfterYear: ''
    };
    private globalIdsTableData: IybrGlobalIdsCollapsibleTableData = {};
    private displayNewPlanningAndControllingList = true;
    private formData: CreateIybrFormModel = {
        projectGlobalId: {
            meta: {
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: new Array<string>()
        },
        projectName: {
            meta: {
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: ''
        },
        country: {
            meta: {
                items: Array<SelectItem>(),
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: ''
        },
        technology: {
            meta: {
                items: Array<SelectItem>(),
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: ''
        },
        groupName: {
            meta: {
                items: Array<SelectItem>(),
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: ''
        },
        plant: {
            meta: {
                items: Array<SelectItem>(),
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: ''
        },
        unit: {
            meta: {
                items: Array<SelectItem>(),
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: ''
        },
        mainFlag: {
            meta: {
                items: Array<SelectItem>(),
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: ''
        },
        wbsElement: {
            meta: {
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: ''
        },
        aeroClassification: {
            meta: {
                items: Array<SelectItem>(),
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: ''
        },
        maintenanceType: {
            meta: {
                items: Array<SelectItem>(),
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: ''
        },
        primaryDepartment: {
            meta: {
                items: Array<SelectItem>(),
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: ''
        },
        outageType: {
            meta: {
                items: Array<SelectItem>(),
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: ''
        },
        outageIds: {
            meta: {
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: ''
        },
        procurementCategory: {
            meta: {
                items: Array<SelectItem>(),
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: ''
        },
        projectStartDate: {
            meta: {
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: ''
        },
        projectEndDate: {
            meta: {
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: ''
        },
        riskId: {
            meta: {
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: []
        },
        dateForDecision: {
            meta: {
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: ''
        },
        pcClassification: {
            meta: {
                items: Array<SelectItem>(),
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: ''
        },
        uniperShare: {
            meta: {
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: ''
        },
        compensation: {
            meta: {
                items: Array<SelectItem>(),
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: ''
        },
        requestType: {
            meta: {
                items: Array<SelectItem>(),
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: ''
        },
        summary: {
            meta: {
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: ''
        },
        year: {
            value: ''
        },
        status: {
            value: ''
        },
        furtherFinancialParameters: {
            meta: {
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: ''
        },
        procurementInvolvment: {
            meta: {
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: ''
        },
        projectAnchoring: {
            meta: {
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: ''
        },
        projectSetup: {
            meta: {
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: '',
        },
        riskAndOpportunities: {
            meta: {
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: '',
        },
        id: {
            value: ''
        },
        projectType: {
            meta: {
                items: Array<SelectItem>(),
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: ''
        },
        miniFinancialDecisionId: {
            value: ''
        },
        miniFdType: {
            meta: {
                items: Array<SelectItem>(),
                disabled: false
            },
            validation: {
                isValid: true,
                error: ''
            },
            value: ''
        },
    };
    private listOfNotifiableUsers: SelectedStakeholder[] = [];
    private isNotifiableUsersValid: boolean = true;
    private listOfNotifiableUsersFromEdit: Stakeholder[] = [];

    private get errorTitleMessage(): string {
        const errorCount = this.formMetadata.fieldsWithErrors.length;
        const errorWord = errorCount === 1 ? 'error' : 'errors';

        return `${errorCount} ${errorWord} found`;
    }

    private get changeRequestId(): string | null {
        if (this.editMode && this.existingChangeRequest && this.existingChangeRequest.id) {
            return this.existingChangeRequest.id;
        }

        return this.newChangeRequestId;
    }

    private get budgetCompensationMiniFdId(): string | undefined {
        if (!this.editMode || !this.existingChangeRequest || this.existingChangeRequest.status !== MiniFdStatus.APPROVED) {
            return;
        }

        return this.existingChangeRequest.id;
    }

    async mounted(): Promise<void> {
        this.populateConstants();
        await this.populateForm();
        this.formMetadata.isLoaded = true;
    }

    public handleGlobalIdTableDataChange(data: IybrGlobalIdsCollapsibleTableData): void {
        this.globalIdsTableData = data;
    }

    public handleNewProjectFormDataChange(data: IybrNewProjectCollapsibleFormData): void {
        this.newProjectFormData = data;
    }

    public async submitForm(miniFdStatusType: string | number): Promise<void> {
        this.formData.status.value = miniFdStatusType ? miniFdStatusType.toString() : MiniFdStatus.DRAFT.toString();

        try {
            await this.validateForm();

            if (this.formMetadata.hasErrors) {
                this.scrollToTop();
                return;
            }
        } catch {
            this.$bvToast.show('toast-validation-error');
        }

        try {
            if (this.editMode) {
                const editMiniFdRequestData = this.composeMiniFdRequestData() as EditMiniFdRequestData;
                this.$emit(FORM_EVENTS.CURRENTLY_SUBMITTING_EDIT_EVENT);
                const response = await this.miniFdService.editMiniFd(editMiniFdRequestData);
                this.newUniqueId = response.uniqueId;
                this.emitSuccessfulFormSubmit();
            } else {
                const addMiniFdRequestData = this.composeMiniFdRequestData() as AddMiniFdRequestData;
                this.$emit(FORM_EVENTS.CURRENTLY_SUBMITTING);
                const response = await this.miniFdService.addMiniFd(addMiniFdRequestData);
                this.newUniqueId = response.uniqueId;
                this.newChangeRequestId = response.id;
            }
        } catch {
            this.$emit(FORM_EVENTS.ERROR_SUBMITTING_EVENT);
        }
    }

    private handleCompensationModelUpdate(newCompensationModel: CreateIybrFormCompensationModel): void {
        this.compensationModel = newCompensationModel;
    }

    private handleCompensationTotalsUpdate(newCompensationTotals: CompensationTableTotals): void {
        this.compensationTotals = newCompensationTotals;
    }

    private handleOutageTypeUpdate(outageType: string): void {
        this.formData.outageIds.meta.disabled = outageType === OutageType.NON_OUTAGE.toString();
        if (this.formData.outageIds.meta.disabled) {
            this.formData.outageIds.value = '';
        }
    }

    private handleExternalIdsInvalidEntries(invalidEntries: string[]): void {
        if (invalidEntries.length === 0) {
            this.formData.riskId.validation.isValid = true;
            this.formData.riskId.validation.error = '';
        } else {
            this.formData.riskId.validation.isValid = false;
            this.formData.riskId.validation.error = `The following External Ids are invalid: ${invalidEntries.join(', ')}.`;
        }
    }

    private confirmExternalIdsEntry(): void {
        this.confirmedExternalIds = [...this.formData.riskId.value];
    }

    private addEmptyRiskIdField(): void {
        this.formData.riskId.value.push('');
    }

    private removeRiskIdField(index: number): void {
        if (index === 0) {
            return;
        }

        this.formData.riskId.value.splice(index, 1);
        this.confirmExternalIdsEntry();
    }

    private showAddBudgetCompensationModal(): void {
        this.formMetadata.showAddBudgetCompensationModal = true;
    }

    private updateBudgetCompensationTableWithRows(payload: ChangeModalDataPayload): void {
        payload.budgetRequestIds.forEach(budgetRequestId => {
            this.$refs[REF_CONSTANTS.COMPENSATION_TABLE].addNewBudgetRequestForCompensationByInternalId(payload.mtpYear.toString(), budgetRequestId).then();
        });
    }

    private async validateForm(): Promise<void> {
        this.formMetadata.currentlyValidating = true;
        this.formMetadata.hasErrors = false;
        this.formMetadata.areTablesValid = true;
        this.formMetadata.isApprovalWorkflowFormValid = true;
        this.formMetadata.isAdditionalInformationValid = true;
        this.formMetadata.isCompensationAvailableExceedingRequired = false;
        this.formMetadata.isCompensatedValueValid = true;
        this.formMetadata.fieldsWithErrors = [];

        const checkIfNotEmpty = (value: string): boolean => {
            return value !== '';
        };

        try {
            // Validate form fields
            if (!this.formData.projectName.meta.disabled) {
                this.validateField('projectName', 'Project Name cannot be empty.', checkIfNotEmpty);
            }

            this.validateField('country', 'Country must be selected.', checkIfNotEmpty);
            this.validateField('technology', 'Technology must be selected.', checkIfNotEmpty);
            this.validateField('plant', 'Plant must be selected.', checkIfNotEmpty);
            this.validateField('unit', 'An option must be selected.', checkIfNotEmpty);
            this.validateField('wbsElement', 'WBS (PSP) Element must be linked.', checkIfNotEmpty);
            this.validateField('aeroClassification', 'An AERO Classification must be selected.', checkIfNotEmpty);
            this.validateField('maintenanceType', 'A Maintenance Type must be selected.', checkIfNotEmpty);
            this.validateField('primaryDepartment', 'A Primary Department must be selected.', checkIfNotEmpty);
            this.validateField('procurementCategory', 'A Procurement Category must be selected.', checkIfNotEmpty);
            this.validateField('outageType', 'An outage type must be selected.', checkIfNotEmpty);
            this.validateField('projectStartDate', 'A project start date must be selected.', checkIfNotEmpty);
            this.validateField('projectEndDate', 'A project end date must be selected.', checkIfNotEmpty);
            this.validateField('dateForDecision', 'A latest date for decision must be selected.', checkIfNotEmpty);
            this.validateField('pcClassification', 'A classification must be selected.', checkIfNotEmpty);
            this.validateField('uniperShare', `Uniper's share of budget must be entered.`, checkIfNotEmpty);
            this.validateField('compensation', 'A local budget compensation value must be selected.', checkIfNotEmpty);
            this.validateField('requestType', 'A request type value must be selected.', checkIfNotEmpty);
            this.validateField('summary', 'A summary must be provided.', checkIfNotEmpty);
            this.validateField('projectType', 'A project type must be selected', checkIfNotEmpty);

            if (new Date(this.formData.projectStartDate.value) >= new Date(this.formData.projectEndDate.value))
            {
                this.formData.projectStartDate.validation.isValid = false;
                this.formData.projectStartDate.validation.error = "Project start date can not be greater than end date";
                this.formMetadata.hasErrors = true;
                this.formMetadata.fieldsWithErrors.push('projectStartDate');
            }

            if (!this.formData.riskId.validation.isValid) {
                this.formMetadata.hasErrors = true;
                this.formMetadata.fieldsWithErrors.push('riskId');
            }

            // Validate additional information if total cost is over 2 million
            if (this.costTableTotal > this.TWO_MILLION_EUROS) {
                this.validateAdditionalInformation();
            } else {
                this.formData.projectSetup.validation.isValid = true;
                this.formData.projectAnchoring.validation.isValid = true;
                this.formData.furtherFinancialParameters.validation.isValid = true;
                this.formData.procurementInvolvment.validation.isValid = true;
                this.formData.riskAndOpportunities.validation.isValid = true;
            }

            // Validate compensation table
            if (!this.$refs[REF_CONSTANTS.COMPENSATION_TABLE].validateCompensationTable()) {
                this.formMetadata.hasErrors = true;
                this.formMetadata.areTablesValid = false;
                this.formMetadata.fieldsWithErrors.push('compensationTable');
            }

            // Validate approval workflow form
            if (!this.isSdFd && !this.$refs[REF_CONSTANTS.FORM_APPROVAL_WORKFLOW].validateApprovalWorkflowForm()) {
                this.formMetadata.hasErrors = true;
                this.formMetadata.isApprovalWorkflowFormValid = false;
                this.formMetadata.fieldsWithErrors.push('formApprovalWorkflow');
            }

            // Validate notifiable users form
            if (!this.isNotifiableUsersValid) {
                this.formMetadata.hasErrors = true;
                this.formMetadata.fieldsWithErrors.push('notifiableUsersForm');
            }

            this.validateBudgetCompensation()
        } catch {
            this.$bvToast.show('toast-validation-error');
        }

        this.formMetadata.currentlyValidating = false;
    }

    private validateField(
        fieldName: string,
        errorMessageIfInvalid: string,
        validationFn: (value: string | string[]) => boolean
    ): boolean {
        const isFieldValid = validationFn(this.formData[fieldName].value);

        if (isFieldValid) {
            this.formData[fieldName].validation.isValid = true;
            this.formData[fieldName].validation.error = '';
        } else {
            this.formData[fieldName].validation.isValid = false;
            this.formData[fieldName].validation.error = errorMessageIfInvalid;

            this.formMetadata.hasErrors = true;
            if (!this.formMetadata.fieldsWithErrors.includes(fieldName)) {
                this.formMetadata.fieldsWithErrors.push(fieldName);
            }
        }

        return isFieldValid;
    }

    private validateBudgetCompensation(): void {

        if (!this.compensationTotals || !this.compensationRequired) {
            return;
        }

        const compensationRequiredTotals = this.compensationRequired;
        const compensationAvailableTotals = {
            mtpPlus1Available: this.compensationTotals.mtpYearPlus1Total,
            mtpPlus2Available: this.compensationTotals.mtpYearPlus2Total,
            mtpPlus3Available: this.compensationTotals.mtpYearPlus3Total,
        };

        for (let key in this.compensationModel) {
            if (
                Number(this.compensationModel[key].yearPlus1.value) < 0 ||
                Number(this.compensationModel[key].yearPlus1.value) > Number(this.compensationModel[key].originalYearPlus1.value) ||
                Number(this.compensationModel[key].yearPlus2.value) < 0 ||
                Number(this.compensationModel[key].yearPlus2.value) > Number(this.compensationModel[key].originalYearPlus2.value) ||
                Number(this.compensationModel[key].yearPlus3.value) < 0 ||
                Number(this.compensationModel[key].yearPlus3.value) > Number(this.compensationModel[key].originalYearPlus3.value) 
            ) {
                this.formMetadata.hasErrors = true;
                this.formMetadata.isCompensatedValueValid = false;
                this.formMetadata.fieldsWithErrors.push('compensationValueInvalid');
            }
        }

        if (Object.keys(this.compensationModel).length > 0) {
            if 
            ((compensationAvailableTotals.mtpPlus1Available != 0 && compensationAvailableTotals.mtpPlus1Available > compensationRequiredTotals.mtpPlus1Required) ||
            (compensationAvailableTotals.mtpPlus2Available != 0 && compensationAvailableTotals.mtpPlus2Available > compensationRequiredTotals.mtpPlus2Required) ||
            (compensationAvailableTotals.mtpPlus3Available != 0 && compensationAvailableTotals.mtpPlus3Available > compensationRequiredTotals.mtpPlus3Required)) 
            {
                this.formMetadata.hasErrors = true;
                this.formMetadata.isCompensationAvailableExceedingRequired = true;
                this.formMetadata.fieldsWithErrors.push('compensationRequiredExceeded');
            }
        }
    }

    private validateAdditionalInformation(): void {
        const checkIfNotEmpty = (value: string): boolean => {
            return value !== '';
        };

        const isProjectSetupValid = this.validateField('projectSetup',
            'Field cannot be empty.', checkIfNotEmpty);
        const isProjectAnchoringValid = this.validateField('projectAnchoring',
            'Field cannot be empty', checkIfNotEmpty);
        const isFurtherFinancialParametersValid = this.validateField('furtherFinancialParameters',
            'Field cannot be empty', checkIfNotEmpty);
        const isProcurementInvolvmentValid = this.validateField('procurementInvolvment',
            'Field cannot be empty', checkIfNotEmpty);
        const isRiskAndOpportunitiesValid = this.validateField('riskAndOpportunities',
            'Field cannot be empty', checkIfNotEmpty);
        if (!isProjectSetupValid || !isProjectAnchoringValid
            || !isFurtherFinancialParametersValid || !isProcurementInvolvmentValid || !isRiskAndOpportunitiesValid) {
            this.formMetadata.isAdditionalInformationValid = false;
        }
    }

    private composeMiniFdRequestData(): AddMiniFdRequestData | EditMiniFdRequestData {
        const budgetCompensations = Object.values(this.compensationModel).map(entry => ({
            budgetRequestId: entry.budgetRequestId.value,
            justification: parseInt(entry.justification.value, 10),
            requestedYearPlus1: parseFloat(entry.yearPlus1.value),
            requestedYearPlus2: parseFloat(entry.yearPlus2.value),
            requestedYearPlus3: parseFloat(entry.yearPlus3.value),
            selectedMtpYear: parseInt(entry.selectedMtpYear.value)
        }));

        // Get Stakeholders and match them with existing StakeholderIds if Edit
        let stakeholders = this.$refs[REF_CONSTANTS.FORM_APPROVAL_WORKFLOW].getStakeHolders().filter(stakeholder => stakeholder !== null);
        stakeholders.push(...this.listOfNotifiableUsers);

        // Remove the GlobalId field and add BudgetRequestDto
        // for a new project MiniFD.
        let globalId = this.formData.projectGlobalId.value;
        let budgetRequests = [];
        let budgetRequestsMiniFinancialDecisions: BudgetRequestsMiniFinancialDecision[];

        if (this.formData.requestType.value === BudgetRequestPlanningStatus.NEW_PROJECT.toString()) {
            globalId = [];
            const budgetRequestId = this.linkedBudgetRequests[0] ? this.linkedBudgetRequests[0].id : undefined;

            budgetRequests.push({
                plantSid: parseInt(this.formData.plant.value),
                plantName: this.formData.plant.meta.items.find(i => i.value === this.formData.plant.value).label,
                technologySid: parseInt(this.formData.technology.value),
                technologyName: this.formData.technology.meta.items.find(i => i.value === this.formData.technology.value).label,
                groupName: this.formData.groupName.meta.items.find(i => i.value === this.formData.groupName.value).label,
                unitSid: parseInt(this.formData.unit.value),
                unitName: this.formData.unit.meta.items.find(i => i.value === this.formData.unit.value).label,
                name: this.formData.projectName.value,
                localLanguageName: this.formData.projectName.value,
                countryCode: this.formData.country.value,
                countryName: this.formData.country.meta.items.find(i => i.value === this.formData.country.value).label,
                projectType: 1
            });

            budgetRequestsMiniFinancialDecisions = [{
                budgetRequestId,
                year: parseInt(this.formData.year.value),
                spentYearMinus1: zeroIfNaN(parseFloat(this.newProjectFormData.spentYearMinus1)),
                spentYear: zeroIfNaN(parseFloat(this.newProjectFormData.spentYear)),
                spentYearPlus1: zeroIfNaN(parseFloat(this.newProjectFormData.spentYearPlus1)),
                spentYearPlus2: zeroIfNaN(parseFloat(this.newProjectFormData.spentYearPlus2)),
                spentYearPlus3: zeroIfNaN(parseFloat(this.newProjectFormData.spentYearPlus3)),
                spentAfterYear: zeroIfNaN(parseFloat(this.newProjectFormData.spentAfterYear)),
            }];
        } else {
            budgetRequestsMiniFinancialDecisions = Object.values(this.globalIdsTableData).map(entry => ({
                globalId: entry.budgetRequestData.globalId,
                budgetRequestId: entry.budgetRequestData.id,
                year: entry.budgetRequestData.planning.year,
                spentYearMinus1: zeroIfNaN(parseFloat(entry.budgetData.spentYearMinus1)),
                spentYear: zeroIfNaN(parseFloat(entry.budgetData.spentYear)),
                spentYearPlus1: zeroIfNaN(parseFloat(entry.budgetData.spentYearPlus1)),
                spentYearPlus2: zeroIfNaN(parseFloat(entry.budgetData.spentYearPlus2)),
                spentYearPlus3: zeroIfNaN(parseFloat(entry.budgetData.spentYearPlus3)),
                spentAfterYear: zeroIfNaN(parseFloat(entry.budgetData.spentAfterYear))
            }));
        }

        return {
            id: this.formData.id.value !== '' ? this.formData.id.value : undefined,
            globalId,
            miniFinancialDecisionId: this.formData.miniFinancialDecisionId.value !== '' ? this.formData.miniFinancialDecisionId.value : undefined,
            projectName: this.formData.projectName.value,
            currencyId: this.formMetadata.currencyId,
            projectSapId: this.formData.wbsElement.value,
            planningAndControllingClassification: parseInt(this.formData.pcClassification.value),
            category: parseInt(this.formData.aeroClassification.value),
            primaryDepartment: parseInt(this.formData.primaryDepartment.value),
            startDate: convertBrowserDateToServerDate(this.formData.projectStartDate.value),
            endDate: convertBrowserDateToServerDate(this.formData.projectEndDate.value),
            latestDateForDecision: convertBrowserDateToServerDate(this.formData.dateForDecision.value),
            miniFdSummary: this.formData.summary.value,
            uniperShareOfBudget: parseInt(this.formData.uniperShare.value),
            externalIds: this.formData.riskId.value,
            budgetCompensationType: parseInt(this.formData.compensation.value),
            requestType: parseInt(this.formData.requestType.value),
            year: new Date().getFullYear(),
            outageType: parseInt(this.formData.outageType.value),
            outageIds: this.formData.outageIds.value.split(',').map(e => e.trim()),
            projectType: parseInt(this.formData.projectType.value),
            miniFdStakeholders: this.isSdFd ? [] : stakeholders,
            maintenanceType: parseInt(this.formData.maintenanceType.value),
            procurementCategory: parseInt(this.formData.procurementCategory.value),
            procurementInvolvment: this.formData.procurementInvolvment.value,
            furtherFinancialParameters: this.formData.furtherFinancialParameters.value,
            projectAnchoring: this.formData.projectAnchoring.value,
            projectSetup: this.formData.projectSetup.value,
            riskAndOpportunities: this.formData.riskAndOpportunities.value,
            budgetCompensations,
            budgetRequests,
            budgetRequestsMiniFinancialDecisions,
            status: parseInt(this.formData.status.value),
            miniFdTags: this.tagsList.map(tag => tag.focusTopicId),
            miniFdType: parseInt(this.formData.miniFdType.value),
        };
    }

    private scrollToTop(): void {
        window.scroll(0, 0);
    }

    private formatDate(date: string): string {
        if (date) {
            return moment(date).format('YYYY-MM-DD');
        } else {
            return '';
        }
    }

    private populateConstants(): void {
        const constants = Data.Instance.constants;
        const requestTypeValue = this.$route.query.requestType as string;

        if (requestTypeValue === RequestOptions.NotReleased.toString()) {
            this.isFlaggedProject = true;
        }

        for (const key in constants.aeroClassification) {
            this.formData.aeroClassification.meta.items.push({
                label: constants.aeroClassification[key],
                value: key
            });
        }

        // Remove 'Not available' classification
        this.formData.aeroClassification.meta.items = this.formData.aeroClassification.meta.items.filter(e => e.value !== '5');
            
        for (const key in constants.newPlanningAndControllingClassification) {
            this.formData.pcClassification.meta.items.push({
                label: constants.newPlanningAndControllingClassification[key],
                value: key
            });
        }
    
        for (const key in constants.localBudgetCompensationsTypes) {
            this.formData.compensation.meta.items.push({
                label: constants.localBudgetCompensationsTypes[key],
                value: key
            });
        }

        for (const key in constants.flags) {
            this.formData.mainFlag.meta.items.push({
                label: constants.flags[key],
                value: key
            });
        }

        for (const key in constants.requestTypes) {
            this.formData.requestType.meta.items.push({
                label: constants.requestTypes[key],
                value: key
            });
        }

        this.formData.requestType.value = requestTypeValue;
        this.formData.requestType.meta.disabled = true;

        if (requestTypeValue === BudgetRequestPlanningStatus.NEW_PROJECT.toString()) {
            this.formData.projectGlobalId.meta.disabled = true;
        }

        for (const key in constants.outageType) {
            this.formData.outageType.meta.items.push({
                label: constants.outageType[key],
                value: key
            });
        }

        for (const key in constants.projectTypes) {
            this.formData.projectType.meta.items.push({
                label: constants.projectTypes[key],
                value: key
            });
        }

        for (const key in constants.procurementCategory) {
            this.formData.procurementCategory.meta.items.push({
                label: constants.procurementCategory[key],
                value: key
            });
        }

        for (const key in constants.maintenanceType) {
            this.formData.maintenanceType.meta.items.push({
                label: constants.maintenanceType[key],
                value: key
            });
        }

        for (const key in constants.primaryDepartment) {
            this.formData.primaryDepartment.meta.items.push({
                label: constants.primaryDepartment[key],
                value: key
            });
        }

        for (const key in constants.miniFdType) {
            this.formData.miniFdType.meta.items.push({
                label: constants.miniFdType[key],
                value: key
            });
        }

        // Remove 'Other' Primary Department
        this.formData.primaryDepartment.meta.items = this.formData.primaryDepartment.meta.items.filter(e => e.value !== '3');

    }

    private async populateForm(): Promise<void> {
        if (this.editMode) {
            await this.populateFromEditRequestQuery();
        } else {
            await this.populateFromNewRequestQuery();
        }
    }

    private async populateFromEditRequestQuery(): Promise<void> {
        const uniqueId = this.$route.query.uniqueId as string;

        if (!uniqueId) {
            return;
        }

        try {
            this.existingChangeRequest = await MasterService.instance.miniFdService.getMiniFdByUniqueId(uniqueId);

            this.populateFromChangeRequest();
            await this.populateFromPlantSid();

            await this.$refs[REF_CONSTANTS.COMPENSATION_TABLE].populateCompensationTable(this.existingChangeRequest.budgetCompensations);

            if (this.existingChangeRequest.requestType === parseInt(RequestOptions.NotReleased)) {
                this.isFlaggedProject = true;
                this.formData.mainFlag.value = this.existingChangeRequest.budgetRequests[0].planning.mainFlag.toString();
                this.formData.mainFlag.meta.disabled = true;
            }

            if (this.existingChangeRequest.requestType !== 2) {
                await this.$refs[REF_CONSTANTS.GLOBAL_IDS_COLLAPSIBLE].populateGlobalIdData(this.existingChangeRequest.budgetRequestsMiniFinancialDecisions.map(entry => ({
                    globalId: entry.budgetRequest.globalId,
                    spentYearMinus1: entry.spentYearMinus1,
                    spentYear: entry.spentYear,
                    spentYearPlus1: entry.spentYearPlus1,
                    spentYearPlus2: entry.spentYearPlus2,
                    spentYearPlus3: entry.spentYearPlus3,
                    spentAfterYear: entry.spentAfterYear
                })));
            } else {
                this.$refs[REF_CONSTANTS.NEW_PROJECT_COLLAPSIBLE].populateFormData({
                    spentYearMinus1: this.existingChangeRequest.spentYearMinus1,
                    spentYear: this.existingChangeRequest.spentYear,
                    spentYearPlus1: this.existingChangeRequest.spentYearPlus1,
                    spentYearPlus2: this.existingChangeRequest.spentYearPlus2,
                    spentYearPlus3: this.existingChangeRequest.spentYearPlus3,
                    spentAfterYear: this.existingChangeRequest.spentAfterYear
                });
            }

            if (!this.isSdFd) {
                this.$refs[REF_CONSTANTS.FORM_APPROVAL_WORKFLOW].populateApprovalWorkflow(this.existingChangeRequest.miniFdStakeholders);
                this.filterListOfNotifiableUsersFromEdit(this.existingChangeRequest.miniFdStakeholders);
            }
        } catch {
            this.uniqueIdInformationError = true;
        }
    }

    private async populateFromNewRequestQuery(): Promise<void> {
        const mtpYear = this.$route.query.mtp as string;
        const miniFdYear = this.$route.query.miniFdYear as string | undefined;
        const miniFdId = this.$route.query.miniFdId as string | undefined;

        this.plantSid = this.$route.query.plantSid as string;
        this.miniFdYear = miniFdId ? parseInt(miniFdYear, 10) : undefined;

        if (miniFdId) {
            try {
                this.existingChangeRequest = await MasterService.instance.miniFdService.getMiniFdByUniqueId(miniFdId, true);
                this.populateFromChangeRequest();

                this.formData.miniFinancialDecisionId.value = this.existingChangeRequest.id;
            } catch (e) {
                this.uniqueIdInformationError = true;
            }
        } else {
            const routeGlobalIds = this.$route.query.globalIds;
            if (Array.isArray(routeGlobalIds)) {
                this.formData.projectGlobalId.value.push(...routeGlobalIds);
            } else if (routeGlobalIds) {
                this.formData.projectGlobalId.value.push(routeGlobalIds);
            }

            if (this.formData.projectGlobalId.value.length > 0 && mtpYear) {
                for (const globalId of this.formData.projectGlobalId.value) {
                    try {
                        const budgetRequest = await MasterService.instance.budgetRequestsService.getBudgetRequestWithCompensationDetails(mtpYear, globalId);
                        this.linkedBudgetRequests.push(budgetRequest);
                    } catch {
                        this.uniqueIdInformationError = true;
                    }
                }

                this.populateFromLinkedBudgetRequests();
            } else {
                const currentYear = mtpYear ? parseInt(mtpYear) : new Date().getFullYear();

                this.budgetRequestMtpYear = currentYear;
                this.formData.year.value = currentYear.toString();
            }
        }

        this.formData.miniFdType.value = this.$route.query.miniFdType ? this.$route.query.miniFdType as string | undefined : '1';

        if (this.plantSid) {
            await this.populateFromPlantSid();
        }
    }

    private populateFromLinkedBudgetRequests(): void {
        const outageType = this.$route.query.outageType as string;
        const outageId = this.$route.query.outageId as string;
        const projectName = this.$route.query.projectName as string | undefined;

        const linkedBudgetRequest = this.linkedBudgetRequests[0];

        this.plantSid = linkedBudgetRequest.plantSid.toString();
        this.unitSid = linkedBudgetRequest.unitSid.toString();

        // Populate Global Id
        this.formData.projectGlobalId.meta.disabled = true;

        // Populate Project Name
        this.formData.projectName.value = projectName ? projectName : linkedBudgetRequest.name;

        // Populate MTP Year
        this.budgetRequestMtpYear = linkedBudgetRequest.planning.year;
        this.formData.year.value = new Date().getFullYear().toString();

        // Populate WBS
        this.formData.wbsElement.value = linkedBudgetRequest.planning.wbsElement;

        // Import AERO value
        this.formData.aeroClassification.value = linkedBudgetRequest.planning.aeroClassification.toString();

        // Import Outage Type & Id
        this.formData.outageType.value = outageType;
        this.formData.outageIds.value = outageId ? outageId : '';

        // Import Project Type
        this.formData.projectType.value = linkedBudgetRequest.projectType.toString();

        // Import Start & End Dates
        this.formData.projectStartDate.value = this.formatDate(linkedBudgetRequest.planning.startDate);
        this.formData.projectEndDate.value = this.formatDate(linkedBudgetRequest.planning.endDate);

        // Import Planning & Controlling Classification
        this.formData.pcClassification.value = linkedBudgetRequest.planning.planningAndControllingClassification.toString();

        // Import Uniper Share of Budget
        this.formData.uniperShare.value = linkedBudgetRequest.planning.uniperShareOfBudget.toString();

        // Populate main flag
        this.formData.mainFlag.value = linkedBudgetRequest.planning.mainFlag.toString();
        this.formData.mainFlag.meta.disabled = true;

        // Populate procurement category and maintenance type
        this.formData.maintenanceType.value = linkedBudgetRequest.planning.maintenanceType?.toString() ?? '';
        this.formData.procurementCategory.value = linkedBudgetRequest.planning.procurementCategory?.toString() ?? '';

        // Import Risk IDs
        this.linkedBudgetRequests.forEach(budgetRequest => {
            if (budgetRequest.planning.riskOpportunityId) {
                const riskIds = budgetRequest.planning.riskOpportunityId.split(',');

                riskIds.forEach((riskId) => {
                    this.formData.riskId.value.push(riskId);
                });
            }
        });
        this.confirmExternalIdsEntry();
    }

    private populateFromChangeRequest(): void {
        if (!this.$route.query.requestType) {
            this.formData.requestType.value = this.existingChangeRequest.requestType.toString();
        }
        this.formData.id.value = this.existingChangeRequest.id;
        this.linkedBudgetRequests = this.existingChangeRequest.budgetRequests;

        if (this.linkedBudgetRequests.length > 0) {
            this.displayNewPlanningAndControllingList = this.linkedBudgetRequests[0].isNew;
        }

        this.plantSid = this.existingChangeRequest.budgetRequests[0].plantSid.toString();
        this.unitSid = this.existingChangeRequest.budgetRequests[0].unitSid.toString();

        this.formData.projectGlobalId.value = this.existingChangeRequest.budgetRequestsMiniFinancialDecisions.map(entry => entry.budgetRequest.globalId);
        this.formData.projectGlobalId.meta.disabled = true;

        this.formData.projectName.value = this.existingChangeRequest.projectName;
        this.$emit(FORM_EVENTS.UPDATE_PROJECT_NAME_EVENT, this.existingChangeRequest.projectName);

        this.formData.year.value = this.existingChangeRequest.year.toString();
        this.formData.wbsElement.value = this.existingChangeRequest.projectSapId;
        this.formData.aeroClassification.value = this.existingChangeRequest.category.toString();
        if (this.existingChangeRequest.primaryDepartment) {
            this.formData.primaryDepartment.value = this.existingChangeRequest.primaryDepartment.toString();
        } else {
            this.fillPrimaryDepartment();
        }
        this.formData.outageType.value = this.existingChangeRequest.outageType.toString();
        this.formData.projectType.value = this.existingChangeRequest.projectType.toString();
        this.formData.outageIds.value = this.existingChangeRequest.outageIds?.join(', ');
        this.formData.projectStartDate.value = this.formatDate(this.existingChangeRequest.startDate);
        this.formData.projectEndDate.value = this.formatDate(this.existingChangeRequest.endDate);
        this.formData.dateForDecision.value = this.formatDate(this.existingChangeRequest.latestDateForDecision);
        this.formData.riskId.value = this.existingChangeRequest.externalIds;
        this.confirmExternalIdsEntry();
        this.formData.pcClassification.value = this.existingChangeRequest.planningAndControllingClassification.toString();
        this.formData.uniperShare.value = this.existingChangeRequest.uniperShareOfBudget.toString();
        this.formData.compensation.value = this.existingChangeRequest.budgetCompensationType.toString();
        this.formData.summary.value = this.existingChangeRequest.miniFdSummary;
        this.formData.furtherFinancialParameters.value = this.existingChangeRequest.furtherFinancialParameters;
        this.formData.maintenanceType.value = this.existingChangeRequest.maintenanceType?.toString() ?? '';
        this.formData.procurementCategory.value = this.existingChangeRequest.procurementCategory?.toString() ?? '';
        this.formData.procurementInvolvment.value = this.existingChangeRequest.procurementInvolvment;
        this.formData.projectAnchoring.value = this.existingChangeRequest.projectAnchoring;
        this.formData.projectSetup.value = this.existingChangeRequest.projectSetup;
        this.formData.riskAndOpportunities.value = this.existingChangeRequest.riskAndOpportunities;
        this.formData.year.value = this.existingChangeRequest.budgetRequestsMiniFinancialDecisions[0].year.toString();
        this.budgetRequestMtpYear = this.existingChangeRequest.budgetRequestsMiniFinancialDecisions[0].year;
        this.formData.miniFdType.value = this.existingChangeRequest.miniFdType.toString();

        this.updateAssetOperationsGroups(this.formData.compensation.value);

        this.tagsList = this.existingChangeRequest.tags;
    }

    private async populateFromPlantSid(): Promise<void> {
        const plantInfo = await this.plantsService.getPlants(this.plantSid);

        // Populate country / currencyId
        this.formData.country.meta.items.push({
            label: plantInfo.country.name,
            value: plantInfo.country.code
        });
        this.formData.country.value = plantInfo.country.code;
        this.formData.country.meta.disabled = true;

        // Populate currencyName
        this.formMetadata.currencyCode = plantInfo.country.currency.code;
        this.formMetadata.currencyId = plantInfo.country.currency.id;

        // Populate technology
        this.formData.technology.meta.items.push({
            label: plantInfo.technology,
            value: plantInfo.technologySid
        });
        this.formData.technology.value = plantInfo.technologySid;
        this.formData.technology.meta.disabled = true;

        // Populate group name
        this.formData.groupName.meta.items.push({
            label: plantInfo.plantGroupName,
            value: plantInfo.plantGroupName
        });
        this.formData.groupName.value = plantInfo.plantGroupName;
        this.formData.groupName.meta.disabled = true;


        // Populate plant
        this.formData.plant.meta.items.push({
            label: plantInfo.name,
            value: plantInfo.plantSId
        });
        this.formData.plant.value = plantInfo.plantSId;
        this.formData.plant.meta.disabled = true;

        // Populate units
        this.formData.unit.meta.items.push(
            ...plantInfo.units.map(unit => ({
                label: unit.name,
                value: unit.unitSid.toString()
            }))
        );

        if (this.unitSid) {
            this.formData.unit.value = plantInfo.units
                .map(unit => unit.unitSid.toString())
                .find(unit => unit === this.unitSid);
        }

        this.plantInfo = plantInfo
    }

    private updateAssetOperationsGroups(selectedLocalCompensation: string): void {
        this.selectedLocalCompensation = selectedLocalCompensation;
    }

    private handleTabChange(tabIndex: number): void {
        this.selectedTab = tabIndex;
    }

    private checkIfAssetStrategyIsRequired(totalCost: number): void {
        this.costTableTotal = totalCost;
        if (this.formMetadata.isLoaded) {
            const europeanAssetStrategyFlag = this.formData.mainFlag.meta.items.find((flag) => {
                return flag.label === 'European Asset Strategy flag';
            });
            const hydroTechnology = this.formData.technology.meta.items.find((technology) => {
                return technology.label === 'Hydro';
            });
            const steamTechnology = this.formData.technology.meta.items.find((technology) => {
                return technology.label === 'Steam';
            });

            this.isHydroTechnology = this.formData.technology.value === hydroTechnology?.value;
            this.isSteamTechnology = this.formData.technology.value === steamTechnology?.value;

            this.isAssetStrategyRequired = this.isFlaggedProject
                && this.formData.mainFlag.value.toString() === europeanAssetStrategyFlag?.value;
        }
    }

    private get isSwedenCountry(): boolean {
        return this.plantInfo?.country.code === this.SWEDEN_COUNTRY_CODE;
    }

    private getListOfNotifiableUsers(users: SelectedStakeholder[]) {
        this.listOfNotifiableUsers = users;
    }

    private filterListOfNotifiableUsersFromEdit(stakeholders: Stakeholder[]) {
        this.listOfNotifiableUsersFromEdit = stakeholders.filter((stakeholder) => {
            return stakeholder.departmentGroupName === DEPARTMENT_GROUP_NAMES.NON_REVIEWERS;
        });
    }

    private emitSuccessfulFormSubmit(): void {
        this.$emit(FORM_EVENTS.FORM_SUBMITTED_EVENT, this.newUniqueId);
    }

    private handleTagsListChanged(list: Tag[]): void {
        this.tagsList = list;
    }

    private get isTagsViewOnly(): boolean {
        const isActive = this.editMode ? this.isMiniFdActive && this.isActiveYear : true;
        return !(User.hasPermission(Permission.BUDGET_COMPENSATION_SUPER_WRITE) && isActive);
    }

    private get isMiniFdActive(): boolean {
        return this.existingChangeRequest?.isActive;
    }

    private get isActiveYear(): boolean {
        const selectedYear = this.existingChangeRequest?.budgetRequestsMiniFinancialDecisions[0]?.year ?? 0;
        return !!this.MINI_FD_YEARS.find(year => year.year === selectedYear)?.isActive;
    }

    private get isPrimaryDepartmentDefinedbyAero(): boolean {
        const primaryDepartment = Number(this.formData.primaryDepartment.value);
        const aeroClassification = Number(this.formData.aeroClassification.value);

        if (aeroClassification === 1 && primaryDepartment === 2) {
            return true;
        }

        if (aeroClassification === 2 && primaryDepartment === 1) {
            return true;
        }

        if (aeroClassification === 3 && primaryDepartment === 1) {
            return true;
        }

        if (aeroClassification === 4 && primaryDepartment === 2) {
            return true;
        }

        return false;
    }

    private fillPrimaryDepartment() {
        switch (this.formData.aeroClassification.value) {
            case '1':
                this.formData.primaryDepartment.value = '2';
                break;
            case '2':
                this.formData.primaryDepartment.value = '1';
                break;
            case '3':
                this.formData.primaryDepartment.value = '1';
                break;
            case '4':
                this.formData.primaryDepartment.value = '2';
                break;
            default:
                this.formData.primaryDepartment.value = null;
        }
    }

    private get isSdFd(): boolean {
        return this.formData.miniFdType.value === MiniFdType.FD
            || this.formData.miniFdType.value === MiniFdType.SFD
    }
}
