var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hurdle-rates-table"
  }, [_c('div', {
    staticClass: "hurdle-rates-table__actions"
  }, [_c('div', {
    staticClass: "hurdle-rates-table__actions__button"
  }, [_c('pui-button', {
    attrs: {
      "state": "primary",
      "disabled": _vm.isEditDisabled
    },
    on: {
      "click": _vm.saveChanges
    }
  }, [_vm._v(" Save Changes ")])], 1)]), _c('div', {
    staticClass: "hurdle-rates-table__table"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.loading,
      expression: "loading"
    }]
  }, [_c('pui-loader-spinner')], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.loading,
      expression: "!loading"
    }]
  }, [_c('table-header', {
    attrs: {
      "hide-search": "",
      "page-size": _vm.tableExtraFilters.pageSize
    },
    on: {
      "page-size-changed": _vm.onPageSizeChanged
    }
  }), _c('ag-grid-vue', {
    staticClass: "ag-theme-alpine",
    attrs: {
      "grid-options": _vm.gridOptions,
      "column-defs": _vm.columnDefs
    },
    on: {
      "grid-ready": _vm.onGridReady
    }
  })], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }