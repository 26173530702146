import { Connection } from '~/utils/';
import { ApiResponse } from '~/models/services/common';

type ModifiedValue = {
    id: string;
    rate: number;
}

export class HurdleRateService {
    private readonly api = new Connection();

    public async getHurdleRates(page: number, size: number, term: string): Promise<any> {
        const params = new URLSearchParams();
        params.append('page', page.toString());
        params.append('size', size.toString());
        params.append('Term', term.toString());

        return (await this.api.get<ApiResponse<any>>(`hurdle-rates?${params.toString()}`)).data.result;
    }

    public async editHurdleRates(payload: { hurdleRatesToEdit: ModifiedValue[] }): Promise<any> {
        return (await this.api.put<ApiResponse<any>>('hurdle-rates', payload)).data.result;
    }
}
