import { formatToDecimals } from '~/utils/number-helper';

export function cellValueClass(value: string): Record<string, boolean> {
    const numValue = Number(value);

    if (isNaN(numValue)) {
        return {};
    }

    return {
        'negative-value-cell': numValue < 0,
    };
}

export function tableCellTextFormat(value: string): number | string {
    const numValue = Number(value);

    if (!isNaN(numValue)) {
        return formatToDecimals(numValue, 3);
    }

    return value;
}
