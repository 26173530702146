import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { ROWS } from '~/components/in-year-budget-requests/constants/rows';

interface RouteConfig {
    name: string;
    query: Record<string, string>;
}

@Component({})
export default class IYBRFormSubmitConfirmationComponent extends Vue {
    @Prop()
    private uniqueId: string;

    private readonly PAGE_LAYOUT = ROWS;

    private handleOverviewButtonClick(): void {
        this.$router.push('/in-year-budget-requests');
    }

    private get viewChangeRequestRouteConfig(): RouteConfig {
        return {
            name: 'viewInYearBudgetRequest',
            query: {
                uniqueId: this.uniqueId
            }
        };
    }
}
