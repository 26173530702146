var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "view-cr-page"
  }, [_c('div', {
    staticClass: "view-cr-page__table"
  }, [_c('table-header', {
    attrs: {
      "page-size": _vm.tableExtraFilters.pageSize
    },
    on: {
      "page-size-changed": _vm.onPageSizeChanged,
      "search-triggered": _vm.onTriggerSearch,
      "clear-search-term": _vm.onClearSearchTerm
    }
  }, [_vm.isFilterApplied ? _c('pui-button', {
    attrs: {
      "icon": "reset",
      "state": "primary",
      "small": ""
    },
    on: {
      "click": _vm.resetFilterState
    }
  }, [_vm._v(" Reset All Filters ")]) : _vm._e()], 1), _c('ag-grid-vue', {
    staticClass: "ag-theme-alpine",
    attrs: {
      "grid-options": _vm.gridOptions,
      "column-defs": _vm.columnDefs
    },
    on: {
      "grid-ready": _vm.onGridReady
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }