import Vue from 'vue';
import Component from 'vue-class-component';
import { BudgetRequests, SearchBudgetRequests, SearchBudgetRequestsMtpPlanning, SearchModel } from '~/utils/interfaces';
import 'vue-awesome/icons/chevron-right';
import 'vue-awesome/icons/chevron-left';
import 'vue-awesome/icons/search';
import 'vue-awesome/icons/chevron-down';
import 'vue-awesome/icons/times';
import 'vue-awesome/icons/upload';
import 'vue-awesome/icons/trash-alt';
import 'vue-awesome/icons/regular/file';
import 'vue-awesome/icons/regular/trash-alt';
import 'vue-awesome/icons/redo';
import 'vue-awesome/icons/check';
import 'vue-awesome/icons/plus';
import 'vue-awesome/icons/download';
import 'vue-awesome/icons/sort-down';
import 'vue-awesome/icons/exclamation-triangle';
import { Data, EventBus, Helper, User } from '~/utils';
import { ReportingService } from '~/services';
import { AssetGroupsService } from '~/services/asset-groups-service';
import { ConstantsListService } from '~/services/constants-list-service';
import { PlantsService } from '~/services/plants-service';
import { Watch } from 'vue-property-decorator';
import { FileService } from '~/services/file-service';
import moment from 'moment-timezone';
import ScrollSync from 'vue-scroll-sync';
import { ExportPayload } from '~/models/services/reporting';
import { ChangeRequestStatusColors } from '~/components/in-year-budget-requests/constants/color-constants';
import { MiniFdStatus } from '~/models/services/minifd';
import { AgGridVue } from 'ag-grid-vue';
import { ColDef, ColumnApi, GetContextMenuItemsParams, GridApi, GridOptions, ICellRendererParams, IServerSideGetRowsParams, MenuItemDef, ModelUpdatedEvent, SelectionChangedEvent, SetFilterValuesFuncParams, ValueFormatterParams } from 'ag-grid-community';
import { AgGridCommon } from 'ag-grid-community/dist/lib/interfaces/iCommon';
import TableHeader from '@/components/ag-grid-table/header-types/table-header.vue';
import NoRowsOverlay from '@/components/ag-grid-table/overlay-types/no-rows-overlay.vue';
import StatusCell from '~/components/ag-grid-table/cell-types/status-cell.vue';
import LinkCell from '~/components/ag-grid-table/cell-types/link-cell.vue';
import { format } from 'date-fns';
import { MasterService } from '~/services/master-service'

type BudgetRequestSelectionData = {
    globalId: string,
    mtpStatus: number,
    year: number,
    name: string,
    currentMtpPlanning: SearchBudgetRequestsMtpPlanning,
    id: string,
    created: string,
    changeRequestId: string
};

type ReportingSetFilters = {
    mtpStatusFilters: number[];
    projectTypeFilters: number[];
    aeroClassificationFilters: number[];
    maintenanceTypeFilters: number[];
    outageTypeFilters: number[];
    planningAndControllingClassificationFilters: number[];
    fundingStatusFilters: number[],
    projectStatusTypeFilters: number[],
    primaryDepartmentFilters: number[],
    mainFlagFilters: number[],
    procurementCategoryFilters: number[],
    prioritizationCategoryFilters: number[],
    requestTypeFilters: number[],
    miniFdStatusFilters: number[],
    focusTopicFilters: string[],
};

const STATUS_COLOR_MAP = {
    'Light_grey': '#d6d6d6',
    'Dark_grey': '#607d8b',
    'Magenta_Red': '#ffab00',
    'Amber_Yellow': '#19c142',
    'Green': '#0078dc',
} as const;

const ROWS = {
    FULL_ROW: { s: 12, l: 12 },
    TWO_THIRDS_ROW: { s: 12, l: 8 },
    HALF_ROW: { s: 12, l: 6 },
    ONE_THIRD_ROW: { s: 12, l: 4 },
    QUARTER_ROW: { s: 6, l: 3 },
    THREE_QUARTER_ROW: { s: 12, l: 9 },
    ONE_SIXTH_ROW: { s: 4, l: 2 },
} as const;

const ACCORDION_REPORTING_TABLE_COLUMN_STATE = 'accordion_reporting_table_column_state' as const;
const ACCORDION_REPORTING_TABLE_FILTER_STATE = 'accordion_reporting_table_filter_state' as const;

@Component({
    components: {
        AgGridVue,
        StatusCell,
        LinkCell,
        TableHeader,
        NoRowsOverlay,
        'scroll-sync': ScrollSync
    }
})
export default class AccordionReportingComponent extends Vue {
    private ROWS = ROWS;
    private gridApi: GridApi | null = null;
    private columnApi: ColumnApi | null = null;
    private tableExtraFilters = {
        pageSize: 10,
        searchTerm: '',
    }
    private isFilterApplied: boolean = false;
    public loading: boolean = false;
    public elementsPerPage: number = 10;
    public firstElementTable: number = 1;
    public numberOfElementTable: number = this.elementsPerPage;
    public currentPage: number = 1;
    public reportingService: ReportingService = new ReportingService();
    public PlantsService = MasterService.instance.plantsService;
    public budgetRequests: BudgetRequests = {
        budgetRequests: [],
        total: '',
    };
    public AssetGroupsService = MasterService.instance.assetGroupsService;
    private constantsBudgetRequestService: ConstantsListService = new ConstantsListService();
    private ExportBudgetRequestService: FileService = new FileService();
    private userPermissions = <any>[];
    private isActive: boolean = true;
    private moreFilterActiv: boolean = false;
    private itemsChecked = [];
    private itemsButtons = null;
    private elements = null;
    private checkedAll: boolean = false;
    private selectedMtpFilter = null;
    private mtpYear = null;
    private miniFdYear = null;
    private selectedGlobalId = null;
    private data: Data = Data.Instance;
    private exportBudgetRequests = {
        year: null,
        miniFdYear: null,
        globalIds: [],
        globalIdsMiniFds: {}
    } as ExportPayload;
    private exportAllBudgetRequests = null;
    private selectedPlants = [];
    private selectedPlantsIds = [];
    private selectedTehnology = [];
    private selectedGroup = [];
    private appliedTech = [];
    private appliedPlant = [];
    private appliedGroup = [];
    private selectedMaintenanceTypeFilters = [];
    private selectedMtpStatusFilters = [];
    private selectedMiniFdStatusFilters = [];
    private selectedMiniFdRequestTypeFilters = [];
    private selectedProjectTypeFilters = [];
    private selectedProcurementCategoryFilters = [];
    private selectedFocusTopicFilters = [];
    private selectedaeroClassificationFilters = [];
    private selectedPrioritizationCategoryFilters = [];
    private selectedoutageTypeFilters = [];
    private selectedplanningAndControllingClassificationFilters = [];
    private selectedfundingStatusFilters = [];
    private selectedprojectStatusTypeFilters = [];
    private selectedprimaryDepartmentFilters = [];
    private selectedmainFlagFilters = [];
    private selectedlumpSumFilters = [];
    private selectedIsReadyForApproval = false;
    private selectedIsCrReadyForApproval = false;
    private searchText = '';
    private singleChangeMtp = null;
    private mainFlagFromChangeMtp = null;
    private otherFlagsFromChangeMtp = null;
    private commentFromChangeMtp = null;
    private yearFromChangeMtp = null;
    private brIdChangeMtp = null;
    private globalIdFromChangeMtp = null;
    private keyIdFromChangeMtp = null;
    private activeButtonApplyFilter: boolean = false;
    private dataBrToModal = {};
    private deleteBudgetRequests = {
        globalIds: [],
        isActive: null,
        mtpYear: null
    };
    private setToInactiveBr = {
        globalIds: [],
        mtpYear: null
    };
    private checkStatuses: boolean = false;
    private errorImport: any[] = null;
    private currentYear = new Date(Date.now()).getFullYear();
    private selectedMtpYear = null;
    private createdYear = null;
    private unmodifiedAssetGroupCollection = [];
    private unmodifiedPlantsCollection = [];
    private isAssetDataAvailableOnReporting = true;

    get getLumpSumTitleToolTip() {
        let localName = [''];
        this.searchModel.filters.lumpSumFilters.forEach((e) => {
            if (e === true) {
                localName.push('Yes');
            } else if (e === false) {
                localName.push('No');
            }
        });
        return localName;
    }

    private get searchModel(): SearchModel {
        const isChangeRequestMtp = parseInt(this.miniFdYear) !== 0;
        return {
            plantIds: this.selectedPlantsIds,
            mtpYear: parseInt(this.mtpYear),
            miniFdYear: parseInt(this.miniFdYear),
            itemsPerPage: this.elementsPerPage,
            pageNo: this.currentPage,
            isActive: this.isActive,
            filters: {
                aeroClassificationFilters: this.selectedaeroClassificationFilters,
                prioritizationCategoryFilters: this.selectedPrioritizationCategoryFilters,
                fundingStatusFilters: this.selectedfundingStatusFilters,
                projectStatusTypeFilters: this.selectedprojectStatusTypeFilters,
                primaryDepartmentFilters: this.selectedprimaryDepartmentFilters,
                lumpSumFilters: this.selectedlumpSumFilters,
                mainFlagFilters: this.selectedmainFlagFilters,
                maintenanceTypeFilters: this.selectedMaintenanceTypeFilters,
                mtpStatusFilters: this.selectedMtpStatusFilters,
                miniFdStatusFilters: this.selectedMiniFdStatusFilters,
                requestTypeFilters: this.selectedMiniFdRequestTypeFilters,
                outageTypeFilters: this.selectedoutageTypeFilters,
                planningAndControllingClassificationFilters: this.selectedplanningAndControllingClassificationFilters,
                projectTypeFilters: this.selectedProjectTypeFilters,
                procurementCategoryFilters: this.selectedProcurementCategoryFilters,
                focusTopicFilters: this.selectedFocusTopicFilters,
                isReadyForApproval: this.selectedIsReadyForApproval,
                isCrReadyForApproval: isChangeRequestMtp ? this.selectedIsCrReadyForApproval : undefined,
            },
            searchText: this.searchText
        };
    }

    private get absMtpYear(): number {
        return Math.abs(parseInt(this.mtpYear));
    }

    async created() {
        this.userPermissions = await User.getPerm();
    }

    async mounted() {
        this.setMtpFilter();
        if (this.data.fleet) {
            await this.loadBudget();
        }
    }

    beforeDestroy() {
        EventBus.$off();
    }

    @Watch('data.selectedPlant')
    async onSelectedPlantChanged(val: string, oldVal: string) {
        if (val != null && this.$route.path === '/reporting') {
            this.clearItemsChecked();
            this.resetPageTable();
            await this.loadBudget();
        }
    }

    public async loadBudget(): Promise<void> {
        this.loading = true;
        this.data.navBarRefresh = true;

        if (this.mtpYear === null || this.miniFdYear === null) {
            this.setMtpFilter();
        }

        if (localStorage.elementsPerPageReporting) {
            this.elementsPerPage = parseInt(localStorage.elementsPerPageReporting);
        }

        if (sessionStorage.moreFilterActivReporting) {
            this.moreFilterActiv = JSON.parse(sessionStorage.moreFilterActivReporting);
        }

        if (localStorage.appliedTech && localStorage.appliedPlant && localStorage.appliedGroup) {
            this.clearSelectedTehnology();
            this.clearSelectedPlant();
            this.clearSelectedGroup();
        }

        if (this.data && this.data.fleet) {
            this.appliedTech = [...this.data.fleet];
            this.selectedTehnology = [...this.data.fleet];
            this.setDataForSearch();
            await this.applySelectTehnology();
        }

        this.data.navBarRefresh = false;
    }

    private getBrModelClean() {
        this.selectedMaintenanceTypeFilters = [];
        this.selectedMtpStatusFilters = [];
        this.selectedProjectTypeFilters = [];
        this.selectedaeroClassificationFilters = [];
        this.selectedPrioritizationCategoryFilters = [];
        this.selectedoutageTypeFilters = [];
        this.selectedplanningAndControllingClassificationFilters = [];
        this.selectedfundingStatusFilters = [];
        this.selectedprojectStatusTypeFilters = [];
        this.selectedmainFlagFilters = [];
        this.selectedlumpSumFilters = [];
        this.selectedprimaryDepartmentFilters = [];
        this.selectedMiniFdStatusFilters = [];
        this.selectedMiniFdRequestTypeFilters = [];
        this.selectedProcurementCategoryFilters = [];
        this.selectedFocusTopicFilters = [];
        this.searchText = '';
    }

    private checkIfSelectedAll() {
        let arrayCheck = [];
        this.checkedAll = true;
        this.budgetRequests.budgetRequests.forEach((t) => {
            const flag = this.itemsChecked.find((f) => f.id === t.id);
            if (flag === undefined) {
                this.checkedAll = false;
            }
        });
        this.initScrollPosition();
    }

    private clearItemsChecked() {
        this.itemsChecked = [];
        this.checkedAll = false;
        this.gridApi?.deselectAll();
    }

    private async reloadTableChangeNumberElement() {
        this.loading = true;
        this.resetPageTable();
        localStorage.setItem('elementsPerPageReporting', this.elementsPerPage.toString());
        // await this.getDataBudgetRequest();
        this.checkedAll = false;
        this.checkIfSelectedAll();
        this.loading = false;
    }

    private selectAll() {
        if (!this.checkedAll) {
            this.checkedAll = true;
            const listOfObjects = [];
            this.budgetRequests.budgetRequests.forEach(function (entry) {
                const singleObj = {};
                singleObj['currentMtpPlanning'] = entry.currentMtpPlanning;
                singleObj['globalId'] = entry.globalId;
                singleObj['id'] = entry.id;
                singleObj['mtpStatus'] = entry.currentMtpPlanning.mtpStatus;
                singleObj['year'] = entry.currentMtpPlanning.year;
                singleObj['name'] = entry.name;
                singleObj['created'] = moment(Helper.UtcToLocalTime(entry.created)).format('YYYY');
                singleObj['changeRequestId'] = entry.changeRequestId;
                listOfObjects.push(singleObj);
            });
            listOfObjects.forEach((e) => {
                this.itemsChecked.push(e);
            });
            this.itemsChecked = this.itemsChecked.filter(obj => !listOfObjects[obj.id] && (listOfObjects[obj.id] = true));
        } else {
            this.clearItemsChecked();
        }
    }

    private selectedBudgetGlobalId(globalId: string) {
        this.selectedGlobalId = globalId;
        this.clearItemsChecked();
    }

    private resetPageTable() {
        this.currentPage = 1;
        this.elementsPerPage = Number(this.elementsPerPage);
        this.firstElementTable = 1;
    }

    private async changeMtpPeriod() {
        this.mtpYear = parseInt(this.selectedMtpFilter.split('-')[0]);
        this.miniFdYear = parseInt(this.selectedMtpFilter.split('-')[1]);
        this.clearItemsChecked();
        this.resetPageTable();
        if (sessionStorage['reportingSearchModel' + this.mtpYear]) {
            this.setSearchModelFilters(sessionStorage['reportingSearchModel' + this.mtpYear]);
        } else {
            this.clearAll();
        }
        if (this.mtpYear === 0) {
            this.isActive = false;
        } else {
            this.isActive = true;
        }

        this.selectedMiniFdStatusFilters = [];

        this.data.switchPageView = true;
        // this.budgetRequests = await this.reportingService.getBudgetRequest(this.searchModel);
        // if (this.budgetRequests)
        //     this.numberOfElementTable = this.budgetRequests.budgetRequests.length;
        localStorage.setItem('appliedMtp', this.mtpYear);
        this.data.switchPageView = false;
        this.refreshTable();
    }

    private async exportToExcelTable() {
        this.exportBudgetRequests.globalIds = [];
        this.exportBudgetRequests.globalIdsMiniFds = {};
        this.exportBudgetRequests.year = this.mtpYear;
        this.exportBudgetRequests.miniFdYear = this.miniFdYear;

        const listOfGlobalId = [];
        const globalIdMiniFdIdMap = {};
        this.itemsChecked.forEach((e) => {
            listOfGlobalId.push(e.globalId);

            if (this.isChangeRequestMtp) {
                globalIdMiniFdIdMap[e.globalId] = e.changeRequestId.trim();
            }
        });

        this.exportBudgetRequests.globalIds = listOfGlobalId;
        this.exportBudgetRequests.globalIdsMiniFds = globalIdMiniFdIdMap;

        await this.exportExcel();
    }

    private async exportExcel() {
        this.data.exportBr = true;
        const content = await this.reportingService.exportBudgetRequests(this.exportBudgetRequests);
        this.forceFileDownload(content.name, content.content);
        this.data.exportBr = false;
    }

    private forceFileDownload(name, data) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
    }

    private async searchByMtpStatus() {
        if (this.moreFilterActiv) {
            this.moreFilterActiv = false;
            sessionStorage.setItem('moreFilterActivReporting', JSON.stringify(this.moreFilterActiv));
        } else {
            this.moreFilterActiv = true;
            sessionStorage.setItem('moreFilterActivReporting', JSON.stringify(this.moreFilterActiv));
        }
    }

    private clearFilter(filter) {
        this[filter] = [];
        this.activeButtonApplyFilter = true;
    }

    private async applyFilter(filtersName, selected) {
        sessionStorage.setItem('reportingSearchModel' + this.mtpYear, JSON.stringify(this.searchModel.filters));
        this.resetPageTable();
        // await this.getDataBudgetRequest();
    }

    private async applyFilterLump() {
        this.resetPageTable();
        // await this.getDataBudgetRequest();
    }

    private async getDataBudgetRequest() {
        this.loading = true;
        this.budgetRequests = await this.reportingService.getBudgetRequest(this.searchModel);
        if (this.budgetRequests)
            this.numberOfElementTable = this.budgetRequests.budgetRequests.length;
        this.loading = false;
        this.initScrollPosition();
    }

    private async clearAll() {
        sessionStorage.removeItem('reportingSearchModel' + this.mtpYear);
        this.currentPage = 1;
        this.firstElementTable = 1;
        this.getBrModelClean();
    }

    private async resetFilters() {
        this.clearAll();
        this.moreFilterActiv = false;
        sessionStorage.setItem('moreFilterActivReporting', JSON.stringify(this.moreFilterActiv));
        // await this.getDataBudgetRequest();
    }

    private nameOfFilters(nameFilters: string, nameConstant: string, isChild: string) {
        let localName = [''];
        this.searchModel.filters[nameFilters].forEach((e: number) => {
            if (isChild !== null) {
                localName.push(this.data.constants[nameConstant][e][isChild] + ', ');
            } else {
                localName.push(this.data.constants[nameConstant][e] + ', ');
            }
        });
        if (localName)
            localName[localName.length - 1] = localName[localName.length - 1].split(',')[0];
        return localName;

    }

    private hideToastDelBr() {
        this.$bvToast.hide('example-toast');
        this.$bvToast.hide('toast-mtp');
        this.$bvToast.hide('remove-and-set-inactive');
    }

    private checkIfSelected() {
        this.checkedAll = false;
    }

    private clearFilterSelected(selected, filters) {
        this.activeButtonApplyFilter = false;
        this[selected] = this.searchModel.filters[filters];
    }

    private activeButtonApply() {
        this.activeButtonApplyFilter = true;
    }

    private resetValueImport() {
        this.$bvToast.hide('toast-exception');
        this.$bvToast.hide('toast-error-import');
    }

    private async filteredMtpStatuses(value) {
        if ($('.accordion-budget .btn').hasClass('collapsed')) {
            $('.accordion-budget .btn').click();
        }
        this.getBrModelClean();
        this.resetPageTable();
        this.selectedMtpStatusFilters[0] = value;
        // await this.getDataBudgetRequest();
        let elmnt = document.getElementById('accordion');
        elmnt.scrollIntoView({
            behavior: 'smooth'
        });
    }

    private showLocalDate(date) {
        return moment(Helper.UtcToLocalTime(date)).format('DD/MM/YYYY');
    }

    private checkCreatedDate(date) {
        let formatedDate = moment(Helper.UtcToLocalTime(date)).format('YYYY');
        if (this.currentYear === parseInt(formatedDate)) {
            return true;
        } else {
            return false;
        }
    }

    private itemsCheckedBr(globalId: string, mtpStatus: number, year: number, name: string, currentMtpPlanning, id: string, created: string, changeRequestId: string): BudgetRequestSelectionData {
        return {
            globalId: globalId,
            mtpStatus: mtpStatus,
            year: year,
            name: name,
            currentMtpPlanning: currentMtpPlanning,
            id: id,
            created: moment(Helper.UtcToLocalTime(created)).format('YYYY'),
            changeRequestId: changeRequestId
        };
    }

    private async applyIsCrReadyForApprovalFilter(newValue: boolean) {
        this.selectedIsCrReadyForApproval = newValue;
        this.refreshTable();

        // sessionStorage.setItem('reportingSearchModel' + this.mtpYear, JSON.stringify(this.searchModel.filters));
        // this.resetPageTable();
        // await this.getDataBudgetRequest();
    }

    private async searchByText() {
        this.resetPageTable();
        // await this.getDataBudgetRequest();
    }

    private async applySelectTehnology() {
        this.loading = true;
        this.isAssetDataAvailableOnReporting = true;
        this.selectedGroup = [];
        this.selectedPlants = [];
        this.appliedPlant = [];
        this.appliedGroup = [];
        this.selectedPlantsIds = [];
        this.appliedTech = [];
        this.appliedTech = this.selectedTehnology;
        let fleetIds = [];
        this.appliedTech.forEach(tech => {
            if (!fleetIds.includes(tech.sid)) {
                fleetIds.push(tech.sid);
            }
        });

        if (fleetIds) {
            this.appliedGroup = await this.AssetGroupsService.getAssetGroups(fleetIds);
            this.selectedGroup = this.appliedGroup;
            this.unmodifiedAssetGroupCollection = this.groupBy(this.appliedGroup, 'fleetName');
            localStorage.setItem('appliedTech', JSON.stringify(this.appliedTech));
            localStorage.setItem('appliedGroup', JSON.stringify(this.appliedGroup));
            await this.applySelectGroup();
        }
    }

    private groupBy(items, name) {
        return items.reduce((r, a) => {
            r[a[name]] = [...r[a[name]] || [], a];
            return r;
        }, {});
    }

    private async applySelectGroup() {
        this.loading = true;
        this.isAssetDataAvailableOnReporting = true;
        this.selectedPlants = [];
        this.appliedGroup = [];
        this.appliedPlant = [];
        this.selectedPlantsIds = [];
        let groupsIds = [];
        this.appliedGroup = this.selectedGroup;
        this.appliedGroup.forEach(group => {
            if (!groupsIds.includes(group.sid)) {
                groupsIds.push(group.sid);
            }
        });
        if (groupsIds.length > 0) {
            this.appliedPlant = await this.PlantsService.getPowerPlants(groupsIds);
            this.unmodifiedPlantsCollection = this.groupBy(this.appliedPlant, 'plantGroupName');
            this.selectedPlants = this.appliedPlant;

            localStorage.setItem('appliedGroup', JSON.stringify(this.appliedGroup));
            localStorage.setItem('appliedPlant', JSON.stringify(this.appliedPlant));

            this.applySelectPlants();
        } else {
            this.isAssetDataAvailableOnReporting = false;
            this.loading = false;
        }

    }

    private async setDataForSearch() {
        this.getBrModelClean();
        if (localStorage.appliedPlant) {
            this.appliedPlant = JSON.parse(localStorage.appliedPlant);
            this.appliedPlant.forEach(a => {
                this.selectedPlantsIds.push(a.plantSid);
            });
        }
        if (parseInt(this.mtpYear) === 0) {
            this.isActive = false;
        } else {
            this.isActive = true;
        }
        if (sessionStorage['reportingSearchModel' + this.mtpYear]) {
            this.setSearchModelFilters(sessionStorage['reportingSearchModel' + this.mtpYear]);
        }
    }

    private async applySelectPlants() {
        this.loading = true;
        this.appliedPlant = [];
        this.selectedPlantsIds = [];
        this.selectedPlants.forEach(s => {
            if (!this.selectedPlantsIds.includes(s.plantSid)) {
                this.selectedPlantsIds.push(s.plantSid);
            }
        });
        this.appliedPlant = this.selectedPlants;
        localStorage.setItem('appliedPlant', JSON.stringify(this.appliedPlant));
        this.loading = false;
        this.refreshTable();
        // await this.getDataBudgetRequest();
    }

    private clearSelectedTehnology() {
        this.activeButtonApplyFilter = false;
        if (localStorage.appliedTech) {
            this.appliedTech = JSON.parse(localStorage.appliedTech);
        }
        if (this.appliedTech) {
            this.selectedTehnology = this.appliedTech;
        }
    }

    private clearSelectedGroup() {
        this.activeButtonApplyFilter = false;
        if (localStorage.appliedGroup) {
            this.appliedGroup = JSON.parse(localStorage.appliedGroup);
        }
        if (this.appliedGroup) {
            this.selectedGroup = this.appliedGroup;
        }
    }

    private clearSelectedPlant() {
        this.activeButtonApplyFilter = false;
        if (localStorage.appliedPlant) {
            this.appliedPlant = JSON.parse(localStorage.appliedPlant);
        }
        if (this.appliedPlant) {
            this.selectedPlants = this.appliedPlant;
        }
    }

    private selectAllGroups() {
        this.activeButtonApplyFilter = true;
        this.selectedGroup = [];
        Object.keys(this.unmodifiedAssetGroupCollection).forEach((key) => {
            this.unmodifiedAssetGroupCollection[key].forEach(plantGroup => {
                this.selectedGroup.push(plantGroup);
            });
        });
    }

    private selectAllPlants() {
        this.activeButtonApplyFilter = true;
        this.selectedPlants = [];

        Object.keys(this.unmodifiedPlantsCollection).forEach((key) => {
            this.unmodifiedPlantsCollection[key].forEach(plant => {
                this.selectedPlants.push(plant);
            });
        });
    }

    private parseGroupedKey(key: string) {
        key = key.replace(')', '');
        key = key.split(',')[1];
        return key;
    }

    private async exportAllToExcelTable() {
        this.data.exportBr = true;

        this.exportAllBudgetRequests = {
            plantIds: this.searchModel.plantIds,
            mtpYear: this.mtpYear,
            miniFdYear: this.miniFdYear,
            isActive: this.isActive,
            filters: {
                aeroClassificationFilters: this.searchModel.filters.aeroClassificationFilters,
                prioritizationCategoryFilters: this.searchModel.filters.prioritizationCategoryFilters,
                fundingStatusFilters: this.searchModel.filters.fundingStatusFilters,
                projectStatusTypeFilters: this.searchModel.filters.projectStatusTypeFilters,
                primaryDepartmentFilters: this.searchModel.filters.primaryDepartmentFilters,
                lumpSumFilters: this.searchModel.filters.lumpSumFilters,
                mainFlagFilters: this.searchModel.filters.mainFlagFilters,
                maintenanceTypeFilters: this.searchModel.filters.maintenanceTypeFilters,
                mtpStatusFilters: this.searchModel.filters.mtpStatusFilters,
                miniFdStatusFilters: this.searchModel.filters.miniFdStatusFilters,
                requestTypeFilters: this.searchModel.filters.requestTypeFilters,
                outageTypeFilters: this.searchModel.filters.outageTypeFilters,
                planningAndControllingClassificationFilters: this.searchModel.filters.planningAndControllingClassificationFilters,
                projectTypeFilters: this.searchModel.filters.projectTypeFilters,
                procurementCategoryFilters: this.searchModel.filters.procurementCategoryFilters,
                focusTopicFilters: this.searchModel.filters.focusTopicFilters
            },
            searchText: this.searchModel.searchText

        };
        try {
            const content = await this.reportingService.exportReportingBudgetRequests(this.exportAllBudgetRequests);
            this.forceFileDownload(content.name, content.content);
        } catch (e) {
            this.data.exportBr = false;
        }

        this.data.exportBr = false;
    }

    private async clearSearch() {
        this.searchText = '';
        this.resetPageTable();
        // await this.getDataBudgetRequest();
    }

    private initScrollPosition() {
        let container = document.querySelector('.table-scroll');
        if (container) {
            container.scrollLeft = 0;
        }
    }

    private setMtpFilter() {
        this.mtpYear = Data.Instance.currentMtpPeriod?.year ?? this.currentYear;
        this.miniFdYear = Data.Instance.constants.mtpFilters.find(mtp => mtp.year === this.mtpYear).miniFdYear;
        this.selectedMtpFilter = `${this.mtpYear}-${this.miniFdYear}`;
        localStorage.setItem('appliedMtp', this.mtpYear);
        localStorage.setItem('appliedMiniFd', this.miniFdYear);
    }

    private setSearchModelFilters(filters) {
        const filterValues = JSON.parse(filters);
        this.selectedaeroClassificationFilters = filterValues.aeroClassificationFilters;
        this.selectedPrioritizationCategoryFilters = filterValues.prioritizationCategoryFilters;
        this.selectedfundingStatusFilters = filterValues.fundingStatusFilters;
        this.selectedprojectStatusTypeFilters = filterValues.projectStatusTypeFilters;
        this.selectedprimaryDepartmentFilters = filterValues.primaryDepartmentFilters;
        this.selectedlumpSumFilters = filterValues.lumpSumFilters;
        this.selectedmainFlagFilters = filterValues.mainFlagFilters;
        this.selectedMaintenanceTypeFilters = filterValues.maintenanceTypeFilters;
        this.selectedMtpStatusFilters = filterValues.mtpStatusFilters;
        this.selectedoutageTypeFilters = filterValues.outageTypeFilters;
        this.selectedplanningAndControllingClassificationFilters = filterValues.planningAndControllingClassificationFilters;
        this.selectedProjectTypeFilters = filterValues.projectTypeFilters;
        this.selectedIsReadyForApproval = filterValues.isReadyForApproval;
        this.selectedIsCrReadyForApproval = filterValues.isCrReadyForApproval;
    }

    private getColorClassNameForRow(el: SearchBudgetRequests): string {
        if (this.isChangeRequestMtp) {
            return '';
        }

        const colorName = this.data.constants.mtpStatus[el.currentMtpPlanning.mtpStatus].color;
        return `${colorName}_table`;
    }

    private getColorStyleForRow(el: SearchBudgetRequests): Record<string, string> | undefined {
        if (!this.isChangeRequestMtp || !el.currentMtpPlanning.miniFdStatus) {
            return;
        }

        return {
            boxShadow: `inset 12px 0 0 -5px ${ChangeRequestStatusColors[el.currentMtpPlanning.miniFdStatus]}`
        };
    }

    private get isLoading(): boolean {
        return this.data.navBarRefresh || this.loading;
    }

    private get isChangeRequestMtp(): boolean {
        return this.searchModel.miniFdYear !== 0;
    }

    private get filteredMiniFdStatusTypes(): Record<string, string> {
        const filters: Record<string, string> = {};

        Object.keys(this.data.constants.miniFdStatusesTypes)
            .filter(e => e !== MiniFdStatus.SUBMITTED.toString())
            .forEach(e => {
                filters[e] = this.data.constants.miniFdStatusesTypes[e];
            });

        return filters;
    }

    private sendToBudgetRequestPage(globalId: string, year: string, changeRequestId?: string): void {
        const miniFdYear = changeRequestId ? year : '0';
        this.$router
            .push({
                path: '/details-page',
                query: {
                    globalId,
                    year,
                    miniFdYear,
                },
            })
            .then();
    }

    private get columnDefs(): ColDef[] {
        return [
            {
                headerName: 'Global ID',
                field: 'globalId',
                pinned: 'left',
                headerCheckboxSelection: true,
                checkboxSelection: true,
                showDisabledCheckboxes: true,
                cellRenderer: 'LinkCell',
                cellRendererParams: (params: ICellRendererParams) => ({
                    href: 'details-page',
                    query: {
                        year: params.data?.currentMtpPlanning?.year?.toString(),
                        globalId: params.value,
                        miniFdYear: params.data?.changeRequestId ? params.data?.currentMtpPlanning?.year?.toString() : '0',
                    },
                }),
            },
            { headerName: 'Name', field: 'name', pinned: 'left', tooltipField: 'name' },
            { headerName: 'Name (Local Language)', field: 'localLanguageName' },
            {
                headerName: 'Status',
                field: 'currentMtpPlanning.mtpStatus',
                valueGetter: (params): string => this.data.constants.mtpStatus[params.data.currentMtpPlanning.mtpStatus]?.name,
                cellRenderer: 'StatusCell',
                cellRendererParams: (params: ICellRendererParams) => ({ color: STATUS_COLOR_MAP[this.data.constants.mtpStatus[params.data.currentMtpPlanning.mtpStatus]?.color] }),
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.mtpStatus));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.mtpStatus[params.value].name;
                    }
                },
            },
            {
                headerName: 'CR Status',
                field: 'currentMtpPlanning.miniFdStatus',
                hide: parseInt(this.miniFdYear) === 0,
                suppressColumnsToolPanel: parseInt(this.miniFdYear) === 0,
                valueGetter: (params): string => this.data.constants.miniFdStatusesTypes[params.data.currentMtpPlanning.miniFdStatus],
                cellRenderer: 'StatusCell',
                cellRendererParams: (params: ICellRendererParams) => ({ color: ChangeRequestStatusColors[params.data.currentMtpPlanning.miniFdStatus] }),
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.miniFdStatusesTypes));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.miniFdStatusesTypes[params.value];
                    }
                },
            },
            {
                headerName: 'Change Request ID',
                field: 'changeRequestId',
                hide: parseInt(this.miniFdYear) === 0,
                suppressColumnsToolPanel: parseInt(this.miniFdYear) === 0,
                cellRenderer: 'LinkCell',
                cellRendererParams: (params: ICellRendererParams) => ({
                    href: 'view-in-year-budget-request',
                    query: {
                        uniqueId: params.value.trim(),
                        history: 'false'
                    },
                }),
            },
            {
                headerName: 'CR Request Type',
                field: 'miniFdRequestType',
                hide: parseInt(this.miniFdYear) === 0,
                suppressColumnsToolPanel: parseInt(this.miniFdYear) === 0,
                valueFormatter: (params): string => this.data.constants.requestTypes[params.value],
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.requestTypes));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.requestTypes[params.value];
                    }
                },
            },
            { headerName: 'Combined Id', field: 'currentMtpPlanning.budgetCombinedId' },
            { headerName: 'WBS (PSP) Element', field: 'currentMtpPlanning.wbsElement' },
            {
                headerName: 'Country',
                field: 'countryCodes',
                valueFormatter: (params): string => params.value.join(', '),
            },
            { headerName: 'Plant / River Group', field: 'plantGroupName' },
            { headerName: 'Plant', field: 'plantName' },
            { headerName: 'Unit', field: 'unitName', resizable: true },
            {
                headerName: 'Project Type',
                field: 'projectType',
                valueFormatter: (params): string => this.data.constants.projectTypes[params.value],
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.projectTypes));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.projectTypes[params.value];
                    }
                },
            },
            {
                headerName: 'Lump Sum',
                field: 'currentMtpPlanning.lumpSum',
                valueFormatter: (params): string => params.value ? 'Yes' : 'No',
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: ['yes', 'no'],
                    valueFormatter: (params: ValueFormatterParams): string => params.value === 'yes' ? 'Yes' : 'No'
                },
            },
            {
                headerName: 'AERO Classification',
                field: 'currentMtpPlanning.aeroClassification',
                valueFormatter: (params): string => this.data.constants.aeroClassification[params.value],
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.aeroClassification));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.aeroClassification[params.value];
                    }
                },
            },
            { headerName: 'Justification On Classification', field: 'currentMtpPlanning.justificationOnClassification', resizable: true },
            {
                headerName: 'Maintenance Type',
                field: 'currentMtpPlanning.maintenanceType',
                valueFormatter: (params): string => this.data.constants.maintenanceType[params.value],
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.maintenanceType));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.maintenanceType[params.value];
                    }
                },
            },
            {
                headerName: 'Outage Type',
                field: 'currentMtpPlanning.outageType',
                valueFormatter: (params): string => this.data.constants.outageType[params.value],
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.outageType));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.outageType[params.value];
                    }
                },
            },
            { headerName: 'Outage ID', field: 'currentMtpPlanning.outageId' },
            { headerName: 'Risk / Maintenance ID', field: 'currentMtpPlanning.riskOpportunityId' },
            {
                headerName: 'Procurement Category',
                field: 'currentMtpPlanning.procurementCategory',
                valueFormatter: (params): string => this.data.constants.procurementCategory[params.value],
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.procurementCategory));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.procurementCategory[params.value];
                    }
                },
            },
            { headerName: 'Comment', field: 'currentMtpPlanning.comment', tooltipField: 'currentMtpPlanning.comment' },
            {
                headerName: 'Planning and Controlling Classification',
                field: 'currentMtpPlanning.planningAndControllingClassification',
                valueFormatter: (params): string => this.data.constants.allPlanningAndControllingClassification[params.value],
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.allPlanningAndControllingClassification));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.allPlanningAndControllingClassification[params.value];
                    }
                },
            },
            {
                headerName: 'Useful Economic Life',
                field: 'currentMtpPlanning.usefulEconomicLife',
                valueFormatter: (params): string => params.value ? `${params.value} year(s)` : '-',
            },
            {
                headerName: 'Uniper Share of Budget',
                field: 'currentMtpPlanning.uniperShareOfBudget',
                valueFormatter: (params): string => `${params.value}%`,
            },
            {
                headerName: 'Start Date',
                field: 'currentMtpPlanning.startDate',
                valueFormatter: (params): string => params.value ? format(new Date(params.value), 'MMMM do yyyy') : '',
            },
            {
                headerName: 'End Date',
                field: 'currentMtpPlanning.endDate',
                valueFormatter: (params): string => params.value ? format(new Date(params.value), 'MMMM do yyyy') : '',
            },
            { headerName: 'Currency', field: 'selectedCurrencyCode' },
            { headerName: `Spend until end of ${parseInt(this.mtpYear) - 1}`, field: 'currentMtpPlanning.spendUntilEndOfYear' },
            { headerName: `Previous MTP ${this.mtpYear}`, field: 'currentMtpPlanning.previousMtp1' },
            { headerName: `Previous MTP ${parseInt(this.mtpYear) + 1}`, field: 'currentMtpPlanning.previousMtp2' },
            { headerName: `Previous MTP ${parseInt(this.mtpYear) + 2}`, field: 'currentMtpPlanning.previousMtp3' },
            { headerName: 'Previous MTP Total', field: 'currentMtpPlanning.previousTotal' },
            { headerName: `Forecast MTP ${this.mtpYear}`, field: 'currentMtpPlanning.forecast' },
            { headerName: `New MTP ${parseInt(this.mtpYear) + 1}`, field: 'currentMtpPlanning.new1' },
            { headerName: `New MTP ${parseInt(this.mtpYear) + 2}`, field: 'currentMtpPlanning.new2' },
            { headerName: `New MTP ${parseInt(this.mtpYear) + 3}`, field: 'currentMtpPlanning.new3' },
            { headerName: `After ${parseInt(this.mtpYear) + 3}`, field: 'currentMtpPlanning.after' },
            { headerName: 'Total', field: 'currentMtpPlanning.total' },
            { headerName: `CR ${this.mtpYear}`, field: 'currentMtpPlanning.previousCrYear1' },
            { headerName: `CR ${parseInt(this.mtpYear) + 1}`, field: 'currentMtpPlanning.previousCrYear2' },
            { headerName: `CR ${parseInt(this.mtpYear) + 2}`, field: 'currentMtpPlanning.previousCrYear3' },
            { headerName: 'CR Total', field: 'currentMtpPlanning.previousCrTotal' },
            {
                headerName: 'Funding Status',
                field: 'currentMtpPlanning.fundingStatus',
                valueFormatter: (params): string => this.data.constants.fundingStatus[params.value],
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.fundingStatus));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.fundingStatus[params.value];
                    }
                },
            },
            {
                headerName: 'Project Status',
                field: 'currentMtpPlanning.projectStatus',
                valueFormatter: (params): string => this.data.constants.projectStatusType[params.value] ?? '-',
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.projectStatusType));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.projectStatusType[params.value];
                    }
                },
            },
            {
                headerName: 'Primary Department',
                field: 'currentMtpPlanning.primaryDepartment',
                valueFormatter: (params): string => this.data.constants.primaryDepartment[params.value],
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.primaryDepartment));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.primaryDepartment[params.value];
                    }
                },
            },
            {
                headerName: 'Main Flag',
                field: 'currentMtpPlanning.mainFlag',
                valueFormatter: (params): string => this.data.constants.flags[params.value] ?? '-',
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.flags));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.flags[params.value];
                    }
                },
            },
            {
                headerName: 'Other Flags',
                field: 'currentMtpPlanning.otherFlags',
                valueFormatter: (params): string => {
                    const mappedFlags = params.value.map(flag => this.data.constants.flags[flag]);
                    if (mappedFlags.length === 0) {
                        return '-';
                    }
                    return mappedFlags.join(', ');
                },
                resizable: true,
            },
            {
                headerName: 'Review Comment',
                field: 'currentMtpPlanning.reviewComment',
                valueFormatter: (params): string => {
                    return params.value ? `${params.value.text} (${params.value.userName}, ${format(new Date(params.value.date), 'MMMM do yyyy')})` : '-';
                },
                resizable: true,
            },
            {
                headerName: 'Prioritisation Category',
                field: 'currentMtpPlanning.prioritizationCategory',
                valueFormatter: (params): string => this.data.constants.prioritizationCategory[params.value] ?? '-',
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.prioritizationCategory));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.prioritizationCategory[params.value] ?? '-';
                    }
                },
            },
            { headerName: 'Prioritisation Rank', field: 'currentMtpPlanning.prioritizationRank' },
            {
                headerName: 'Focus Topics',
                field: 'currentMtpPlanning.mtpTags',
                resizable: true,
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.focusTopics));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.focusTopics[params.value] ?? '-';
                    }
                },
            },
            {
                headerName: 'Created',
                field: 'created',
                valueFormatter: (params): string => format(new Date(params.value), 'MMMM do yyyy') ?? '-',
            },
            { headerName: 'Created By', field: 'createdBy' },
            {
                headerName: 'Modified',
                field: 'currentMtpPlanning.modified',
                valueFormatter: (params): string => format(new Date(params.value), 'MMMM do yyyy') ?? '-',
            },
            { headerName: 'Modified By', field: 'currentMtpPlanning.modifiedBy' },
            { headerName: `Forecast MTP ${this.mtpYear}`, field: 'currentMtpPlanning.forecastEur' },
            { headerName: `New MTP ${parseInt(this.mtpYear) + 1}`, field: 'currentMtpPlanning.new1Eur' },
            { headerName: `New MTP ${parseInt(this.mtpYear) + 2}`, field: 'currentMtpPlanning.new2Eur' },
            { headerName: `New MTP ${parseInt(this.mtpYear) + 3}`, field: 'currentMtpPlanning.new3Eur' },
        ];
    }

    private gridOptions: GridOptions = {
        rowModelType: 'serverSide',
        serverSideDatasource: {
            getRows: (params: IServerSideGetRowsParams) => {
                this.fetchData(params);
            }
        },
        defaultColDef: {
            filter: true,
            sortable: false,
            unSortIcon: true,
            menuTabs: [],
            minWidth: 200,
            valueFormatter: (params) => params.value ?? '-',
        },
        domLayout: 'autoHeight',
        rowSelection: 'multiple',
        suppressRowClickSelection: true,
        suppressMenuHide: true,
        pagination: true,
        paginationPageSize: this.tableExtraFilters.pageSize,
        cacheBlockSize: this.tableExtraFilters.pageSize,
        noRowsOverlayComponent: 'NoRowsOverlay',
        serverSideFilterOnServer: true,
        popupParent: document.querySelector('body'),
        tooltipShowDelay: 0,
        tooltipMouseTrack: true,
        sideBar: {
            defaultToolPanel: '',
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
        },
        getRowId: (params): string => `${params.data.globalId}_${params.data.changeRequestId}`,
        getContextMenuItems: (params) => this.customContextMenu(params),
        onSelectionChanged: this.onSelectionChanged,
        onModelUpdated: this.onModelUpdated,
        onColumnMoved: this.setColumnState,
        onColumnVisible: this.setColumnState,
        onColumnPinned: this.setColumnState,
        onFilterChanged: () => {
            this.setFilterState();
            this.refreshTable();
        },
        onToolPanelVisibleChanged: this.sizeColumnsToFit,
        onToolPanelSizeChanged: this.sizeColumnsToFit,
        onGridSizeChanged: this.sizeColumnsToFit,
    };

    private async fetchData(params: IServerSideGetRowsParams): Promise<void> {
        params.api.hideOverlay();

        const {
            mtpStatusFilters,
            projectTypeFilters,
            aeroClassificationFilters,
            maintenanceTypeFilters,
            outageTypeFilters,
            planningAndControllingClassificationFilters,
            fundingStatusFilters,
            projectStatusTypeFilters,
            primaryDepartmentFilters,
            mainFlagFilters,
            procurementCategoryFilters,
            focusTopicFilters,
            prioritizationCategoryFilters,
            requestTypeFilters,
            miniFdStatusFilters,
        } = this.getSelectedFiltersForServerRequestParams(params);
        const lumpSumFilters = this.getYesNoFilter(params.request.filterModel['currentMtpPlanning.lumpSum']?.values);
        const pageNumber = this.pageNumberCalc(params.request.endRow);
        const { pageSize, searchTerm } = this.tableExtraFilters;

        const isChangeRequestMtp = parseInt(this.miniFdYear) !== 0;
        const searchModel: SearchModel = {
            plantIds: this.selectedPlantsIds,
            mtpYear: parseInt(this.mtpYear),
            miniFdYear: parseInt(this.miniFdYear),
            isActive: this.isActive,
            itemsPerPage: pageSize,
            pageNo: pageNumber,
            searchText: searchTerm,
            filters: {
                mtpStatusFilters,
                aeroClassificationFilters,
                prioritizationCategoryFilters,
                planningAndControllingClassificationFilters,
                fundingStatusFilters,
                projectStatusTypeFilters,
                primaryDepartmentFilters,
                mainFlagFilters,
                maintenanceTypeFilters,
                outageTypeFilters,
                projectTypeFilters,
                procurementCategoryFilters,
                lumpSumFilters,
                requestTypeFilters,
                miniFdStatusFilters,
                focusTopicFilters,
                isReadyForApproval: this.selectedIsReadyForApproval,
                isCrReadyForApproval: isChangeRequestMtp ? this.selectedIsCrReadyForApproval : undefined,
            },
        };

        try {
            const response = await this.reportingService.getBudgetRequest(searchModel);
            params.success({ rowData: response.budgetRequests, rowCount: Number(response.total) });
            params.api.hideOverlay();
            if (Number(response.total) === 0) {
                params.api.showNoRowsOverlay();
            }
        } catch (e) {
            params.fail()
        }
    }

    private onGridReady(params: AgGridCommon<any, any>): void {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;

        this.applyColumnState();
        this.applyFilterState();
    }

    private applyColumnState(): void {
        const columnState = JSON.parse(localStorage.getItem(ACCORDION_REPORTING_TABLE_COLUMN_STATE));
        if (columnState) {
            this.columnApi.applyColumnState({ state: columnState, applyOrder: true });
        }
        const isVisible = parseInt(this.miniFdYear) !== 0;
        this.columnApi.setColumnVisible('currentMtpPlanning.miniFdStatus', isVisible);
        this.columnApi.setColumnVisible('changeRequestId', isVisible);
        this.columnApi.setColumnVisible('miniFdRequestType', isVisible);
    }

    private setColumnState(): void {
        const columnState = JSON.stringify(this.columnApi.getColumnState());
        localStorage.setItem(ACCORDION_REPORTING_TABLE_COLUMN_STATE, columnState);
    }

    private applyFilterState(): void {
        const filterState = JSON.parse(localStorage.getItem(ACCORDION_REPORTING_TABLE_FILTER_STATE));
        if (filterState) {
            this.gridApi.setFilterModel(filterState);
            this.refreshTable();
            this.calcIsFilterApplied();
        }
    }

    private setFilterState(): void {
        const filterState = JSON.stringify(this.gridApi.getFilterModel());
        localStorage.setItem(ACCORDION_REPORTING_TABLE_FILTER_STATE, filterState);
        this.calcIsFilterApplied();
    }

    private calcIsFilterApplied(): void {
        const filterState = this.gridApi?.getFilterModel();
        const filterStateLength = Object.keys(filterState).length;
        this.isFilterApplied = filterStateLength > 0;
    }

    private resetFilterState(): void {
        this.gridApi?.setFilterModel(null);
    }

    private refreshTable(): void {
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private sizeColumnsToFit(): void {
        this.gridApi?.sizeColumnsToFit();
    }

    private onTriggerSearch(searchTerm: string): void {
        this.tableExtraFilters.searchTerm = searchTerm;
        this.refreshTable();
    }

    private onClearSearchTerm(): void {
        this.onTriggerSearch('');
    }

    private onPageSizeChanged(pageSize: number): void {
        this.tableExtraFilters.pageSize = pageSize;
        this.gridApi?.paginationSetPageSize(this.tableExtraFilters.pageSize);
        this.gridApi?.setCacheBlockSize(this.tableExtraFilters.pageSize);
    }

    private pageNumberCalc(endRow: number | undefined): number {
        return Math.floor((endRow ?? this.tableExtraFilters.pageSize) / this.tableExtraFilters.pageSize)
    }

    private getYesNoFilter(options: string[]): boolean[] {
        if (!options || !options.length || options.length === 2) {
            return [];
        }
        return [options[0] === 'yes'];
    }

    private getSelectedFiltersForServerRequestParams(params: IServerSideGetRowsParams): ReportingSetFilters {
        return {
            mtpStatusFilters: params.request.filterModel['currentMtpPlanning.mtpStatus']?.values?.map((e: string) => Number(e)) ?? [],
            projectTypeFilters: params.request.filterModel['projectType']?.values?.map((e: string) => Number(e)) ?? [],
            aeroClassificationFilters: params.request.filterModel['currentMtpPlanning.aeroClassification']?.values?.map((e: string) => Number(e)) ?? [],
            maintenanceTypeFilters: params.request.filterModel['currentMtpPlanning.maintenanceType']?.values?.map((e: string) => Number(e)) ?? [],
            outageTypeFilters: params.request.filterModel['currentMtpPlanning.outageType']?.values?.map((e: string) => Number(e)) ?? [],
            procurementCategoryFilters: params.request.filterModel['currentMtpPlanning.procurementCategory']?.values?.map((e: string) => Number(e)) ?? [],
            planningAndControllingClassificationFilters: params.request.filterModel['currentMtpPlanning.planningAndControllingClassification']?.values?.map((e: string) => Number(e)) ?? [],
            fundingStatusFilters: params.request.filterModel['currentMtpPlanning.fundingStatus']?.values?.map((e: string) => Number(e)) ?? [],
            projectStatusTypeFilters: params.request.filterModel['currentMtpPlanning.projectStatus']?.values?.map((e: string) => Number(e)) ?? [],
            primaryDepartmentFilters: params.request.filterModel['currentMtpPlanning.primaryDepartment']?.values?.map((e: string) => Number(e)) ?? [],
            mainFlagFilters: params.request.filterModel['currentMtpPlanning.mainFlag']?.values?.map((e: string) => Number(e)) ?? [],
            prioritizationCategoryFilters: params.request.filterModel['currentMtpPlanning.prioritizationCategory']?.values?.map((e: string) => Number(e)) ?? [],
            requestTypeFilters: params.request.filterModel['miniFdRequestType']?.values?.map((e: string) => Number(e)) ?? [],
            miniFdStatusFilters: params.request.filterModel['currentMtpPlanning.miniFdStatus']?.values?.map((e: string) => Number(e)) ?? [],
            focusTopicFilters: params.request.filterModel['currentMtpPlanning.mtpTags']?.values?.map((e: string) =>e) ?? [],
        };
    }

    private onSelectionChanged(event: SelectionChangedEvent): void {
        event.api.forEachNode(node => {
            if (!node.data) {
                return;
            }

            if (node.isSelected()) {
                if (!this.itemsChecked.find(e => e.globalId === node.data?.globalId && e.changeRequestId === node.data?.changeRequestId)) {
                    this.itemsChecked.push(this.itemsCheckedBr(node.data.globalId, node.data.currentMtpPlanning.mtpStatus, node.data.currentMtpPlanning.year, node.data.name, node.data.currentMtpPlanning, node.data.id, node.data.created, node.data.changeRequestId));
                }
            } else {
                this.itemsChecked = this.itemsChecked.filter(e => !(e.globalId === node.data?.globalId && e.changeRequestId === node.data?.changeRequestId));
            }
        });
    }

    private onModelUpdated(event: ModelUpdatedEvent): void {
        event.api.forEachNode(node => {
            if (!node.data) {
                return;
            }

            if (this.itemsChecked.find(e => e.id === node.data?.id)) {
                node.setSelected(true, false, true);
            } else {
                node.setSelected(false, false, true);
            }
        });
    }

    private customContextMenu (params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
        const el = params.node.data;
        const customContextMenu: MenuItemDef[] = [];

        if (params.column.getColId() === 'changeRequestId') {
            customContextMenu.push({
                name: 'Open in New Tab',
                action: () => {
                    const routeData = this.$router.resolve({
                        path: 'view-in-year-budget-request',
                        query: {
                            uniqueId: el.changeRequestId?.trim(),
                            history: 'false'
                        }
                    });
                    window.open(routeData.href, '_blank');
                },
            });

            return customContextMenu;
        }

        if (params.column.getColId() === 'globalId') {
            customContextMenu.push({
                name: 'Open in New Tab',
                action: () => {
                    const routeData = this.$router.resolve({
                        path: 'details-page',
                        query: {
                            globalId: el.globalId,
                            year: el.currentMtpPlanning.year,
                            miniFdYear: el.changeRequestId ? el.currentMtpPlanning.year : '0',
                        },
                    });
                    window.open(routeData.href, '_blank');
                },
            });

            return customContextMenu;
        }

        return [];
    }
}
