






import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';

interface LinkCell extends ICellRendererParams {
    href: string;
    query: Record<string, string>;
}

@Component({})
export default class LinkCellComponent extends Vue {
    private params?: LinkCell;

    private get title(): string {
        return this.params.value;
    }

    private get href(): string | undefined {
        return this.params.href;
    }
    
    private get query(): Record<string, string> {
        return this.params.query;
    }
}
