import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class SecondDeleteBulkModalComponent extends Vue {

    private deleteBulk() {
        this.$emit('confirmBulkDelete');
        $('#secondDeleteBulkModal').modal('hide');
    }
}
