import Component from 'vue-class-component';
import Vue from 'vue';
import { Emit } from 'vue-property-decorator';

@Component({})
export default class TableSearchComponent extends Vue {
    searchTerm = '';

    @Emit()
    filterByKeyword(): string {
        return this.searchTerm;
    }
}
