import Vue from 'vue';
import Component from 'vue-class-component';
import NavbarUserActionsComponent from './navbar-user-actions/navbar-user-actions.vue';
import NavbarAppDrawerComponent from '~/components/navbar/navbar-app-drawer/navbar-app-drawer.vue';
import NavbarAppDrawerComponentTs from '~/components/navbar/navbar-app-drawer/navbar-app-drawer';
import NavmenuComponent from '~/components/navbar/navmenu/navmenu.vue';
import { MasterService } from '~/services/master-service';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import moment from 'moment';
import { Data } from '~/utils';
import { AdamUiNotification, AdamUiNotificationEvent, AdamUiNotificationType } from '~/models/libraries/adam-ui';
import { Notification } from '~/models/services/notification';

@Component({
    components: {
        'navbar-app-drawer': NavbarAppDrawerComponent,
        'navbar-user-actions': NavbarUserActionsComponent,
        navmenu: NavmenuComponent
    }
})
export default class NavbarComponent extends Vue {
    $refs!: {
        appDrawer: NavbarAppDrawerComponentTs;
    };
    private signedIn = false;
    private loadedNotifications: Notification[] = [];
    private signalRConnection?: HubConnection = null;
    private data = Data.Instance;

    private get currentEnvironment(): string {
        return process.env.VUE_APP_ENVIRONMENT;
    }

    private get headerNotifications(): AdamUiNotification[] {
        let notifications: AdamUiNotification[] = [];

        const getUseCaseNameFromId = (useCaseId: number): string | number => {
            const app = this.data.coodeApplications?.find(app => app.id === useCaseId);
            return app ? app.name : useCaseId;
        };

        this.loadedNotifications.forEach(notification => {
            if (notification.type === 'Notification') {
                notifications.push({
                    id: notification.id,
                    isRead: notification.isRead,
                    title: notification.title,
                    description: notification.description,
                    created: this.formatTimestamp(notification.timestamp),
                    type: AdamUiNotificationType.NOTIFICATION,
                    useCaseName: getUseCaseNameFromId(notification.useCaseId)
                });
            } else if (notification.type === 'Task') {
                notifications.push({
                    id: notification.id,
                    isRead: notification.isRead,
                    title: notification.title,
                    description: notification.description,
                    created: this.formatTimestamp(notification.timestamp),
                    type: AdamUiNotificationType.TASK,
                    useCaseName: getUseCaseNameFromId(notification.useCaseId),
                    approve: () => {},
                    reject: () => {}
                });
            } else if (notification.type === 'TaskWithRedirectToApp') {
                notifications.push({
                    id: notification.id,
                    isRead: notification.isRead,
                    title: notification.title,
                    description: notification.description,
                    created: this.formatTimestamp(notification.timestamp),
                    type: AdamUiNotificationType.TASK_WITH_REDIRECT_TO_APP,
                    useCaseName: getUseCaseNameFromId(notification.useCaseId),
                    redirectUrl: notification.actions[0].redirectUrl,
                    redirect: () => {}
                });
            }
        });

        // Adam Header Bar doesn't display any notifications if there isn't
        // at least one unread notification.
        if (notifications.length !== 0 && !notifications.some(notification => !notification.isRead)) {
            notifications[0].isRead = false;
        }

        return notifications;
    }

    async mounted(): Promise<void> {
        this.signedIn = this.$sdk.$authenticator.isAuthenticated();

        if (this.signedIn) {
            await this.getNotifications();
            await this.initializeSignalRHub();
        }
    }

    private async getNotifications(): Promise<void> {
        const notificationResponse = await MasterService.instance.notificationService.getAllNotifications();
        this.loadedNotifications = notificationResponse.result.items;
    }

    private async handleApprove(event: AdamUiNotificationEvent): Promise<void> {
        const notification = this.loadedNotifications.find(notification => notification.id === event.detail.id);
        await this.handleAction(notification, 0);
    }

    private async handleReject(event: AdamUiNotificationEvent): Promise<void> {
        const notification = this.loadedNotifications.find(notification => notification.id === event.detail.id);
        await this.handleAction(notification, 1);
    }

    private async handleRedirect(event: AdamUiNotificationEvent): Promise<void> {
        await MasterService.instance.notificationService.markNotificationsAsRead(event.detail.id);
        await this.getNotifications();

        if (event.detail.type === AdamUiNotificationType.TASK_WITH_REDIRECT_TO_APP) {
            window.open(event.detail.redirectUrl);
        }
    }

    private async handleAction(notification: Notification, actionId: number): Promise<void> {
        if (notification.actions && actionId === 0) {
            const foundCorrespondingAction = notification.actions.find(action => action.id === actionId);

            if (foundCorrespondingAction.includeColumnIdsInRequest) {
                const content = {};

                foundCorrespondingAction.includeColumnIdsInRequest.forEach(columnId => {
                    const foundDynamicColumn = notification.dynamicColumns.find(column => column.id === columnId);
                    content[columnId.toString()] = foundDynamicColumn.value;
                });

                await MasterService.instance.notificationService.doAction(notification.workflowInstanceId, actionId, content);
                await this.getNotifications();
                return;
            }
        }

        await MasterService.instance.notificationService.doAction(notification.workflowInstanceId, actionId);
        await this.getNotifications();
    }

    private redirectToAppStore(): void {
        window.open(process.env.VUE_APP_EXTERNAL_APP_APPSTORE_URL + '/notifications-overview');
    }

    private formatTimestamp(timestamp: string): string {
        const localTime = moment.utc(timestamp).local();
        return localTime.format('DD/MM/YYYY HH:mm');
    }

    private async initializeSignalRHub(): Promise<void> {
        this.signalRConnection = new HubConnectionBuilder()
            .withUrl(process.env.VUE_APP_NOTIFICATIONS_SIGNALR_HUB_URL, {
                accessTokenFactory: this.getAccessToken,
                withCredentials: true
            })
            .withAutomaticReconnect()
            .build();

        this.signalRConnection.on('Notification', message => {
            this.loadedNotifications.unshift(message);
        });

        await this.signalRConnection.start();
    }

    private async getAccessToken(): Promise<string> {
        return (await Vue.prototype.$sdk.$authenticator.getToken(`api://${process.env.VUE_APP_NOTIFICATIONS_CLIENT_ID}/coode`)) as string;
    }

    private async clearAllNotifications(): Promise<void> {
        await MasterService.instance.notificationService.markNotificationsAsRead();
        await MasterService.instance.notificationService.clearAllNotifications();
        await this.getNotifications();
    }

    private toggleDrawer(): void {
        this.$refs.appDrawer.toggleDrawer();
    }
}
