import Vue from 'vue';
import Component from 'vue-class-component';
import { Data } from '~/utils';

@Component({})
export default class ToastProviderComponent extends Vue {
    private data = Data.Instance;
    private autoHideDelayValue = 20000;

    private hideAllToasts() {
        this.$bvToast.hide('example-toast');
        this.$bvToast.hide('toast-mtp');
        this.$bvToast.hide('inactive');
        this.$bvToast.hide('add-to-current-mtp');
        this.$bvToast.hide('toast-duplicate');
        this.$bvToast.hide('toast-data-error');
        this.$bvToast.hide('toast-validation-error');
        this.$bvToast.hide('toast-approved-minifd');
        this.$bvToast.hide('toast-rejected-minifd');
        this.$bvToast.hide('toast-error-submit-approval-workflow-minifd');
        this.$bvToast.hide('toast-successful-submit-draft-change-request');
        this.$bvToast.hide('toast-error-submit-draft-change-request');
        this.$bvToast.hide('toast-error-file-upload');
        this.$bvToast.hide('toast-error-delete-change-request-file');
        this.$bvToast.hide('toast-successful-delete-change-request-file');
        this.$bvToast.hide('toast-error-download-change-request-file');
        this.$bvToast.hide('toast-error-change-request-file-name-already-exists');
        this.$bvToast.hide('toast-invalid-file-extension');
        this.$bvToast.hide('toast-error-project-prioritization-no-data');
        this.$bvToast.hide('toast-error-submit-change-request');
    }

    private goToDetailsPage() {
        this.$router.push({
            path: 'details-page',
            query: {
                year: this.data.viewDataAddCurrentMtp.year.toString(),
                globalId: this.data.viewDataAddCurrentMtp.globalId
            }
        });
        this.$bvToast.hide('add-to-current-mtp');
    }
}
