import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class ExportPrioritizationModalComponent extends Vue {

    public globalIdsListInput = '';

    private get globalIdList(): string[] {
        return this.globalIdsListInput.split(',').map(id => id.trim());
    }

    private exportGlobalIds() {
        this.$emit('startExport', this.globalIdList);
        this.globalIdsListInput = '';
        $('#exportPrioritizationModal').modal('hide');
    }
}
