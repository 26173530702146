import Vue from 'vue';
import Component from 'vue-class-component';
import { EventBus } from '~/utils/eventbus';

@Component({})
export default class SetInactiveModalComponent extends Vue {
    private selectedStateBr: boolean = false;

    constructor() {
        super();
    }

    private setToInactive() {
        EventBus.$emit(EventBus.REMOVE_BUDGET_REQUEST.SET_INACTIVE_MODAL, this.selectedStateBr);
        this.$emit('clickedcanceleditstate', false);
    }

    private onClickButton() {
        this.$emit('clickedcanceleditstate', false);
    }
}
