import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Data, User } from '~/utils';
import './admin.scss';
import { BudgetRequestsService } from '~/services/budget-request-service';
import FirstMoveBulkModalComponent from '../data-input/first-move-bulk-modal/first-move-bulk-modal.vue';
import SecondMoveBulkModalComponent from '../data-input/second-move-bulk-modal/second-move-bulk-modal.vue';
import FirstDeleteBulkModalComponent from '../data-input/first-delete-bulk-modal/first-delete-bulk-modal.vue';
import SecondDeleteBulkModalComponent from '../data-input/second-delete-bulk-modal/second-delete-bulk-modal.vue';
import ExportPrioritizationModalComponent from '../data-input/export-prioritization-modal/export-prioritization-modal.vue';
import { MtpService } from '~/services/mtp-service';
import { MtpPeriod } from '~/utils/interfaces';
import { format } from 'date-fns';
import { AdminService } from '~/services/admin-service';

@Component({
    components: {
        'first-move-bulk-modal': FirstMoveBulkModalComponent,
        'second-move-bulk-modal': SecondMoveBulkModalComponent,
        'first-delete-bulk-modal': FirstDeleteBulkModalComponent,
        'second-delete-bulk-modal': SecondDeleteBulkModalComponent,
        'export-prioritization-modal': ExportPrioritizationModalComponent
    }
})
export default class AdminComponent extends Vue {
    public MtpService: MtpService = new MtpService();
    public globalIdsListInput: string[];
    private userPermissions = <any>[];
    private loading = false;
    private budgetRequestService: BudgetRequestsService = new BudgetRequestsService();
    private adminService: AdminService = new AdminService();
    private deletedNumber: number = 0;
    private movedNumber: number = 0;
    private isMtpPeriod: boolean = true;
    private mtpPeriod: MtpPeriod = <MtpPeriod>{
        year: 0,
        mtpStartDate: null,
        mtpEndDate: null
    };
    private errorMessage = null;
    private errorImport: any[] = null;
    private currentYear = new Date(Date.now()).getFullYear();
    private isAccessDeniedModalActive: boolean = false;
    private data: Data = Data.Instance;

    async created() {
        this.loading = true;
        this.userPermissions = await User.getPerm();
        if (!this.userPermissions.includes('BulkMoveBudgetRequestsToCurrentMtp') && !this.userPermissions.includes('BulkDeleteInactiveBudgetRequests')) {
            $('#accessDeniedModal').modal('show');
            this.isAccessDeniedModalActive = true;
        }

        // Check current MTP period to enable/disable bulk delete
        if (this.userPermissions.includes('BudgetRequestDetailsEdit')) {
            if (localStorage.mtpPeriod) {
                this.mtpPeriod = JSON.parse(localStorage.mtpPeriod);
            } else {
                try {
                    this.mtpPeriod = await this.MtpService.GetMtpPeriod(this.currentYear);
                    localStorage.setItem('mtpPeriod', JSON.stringify(this.mtpPeriod));
                } catch (error) {
                    if (error.response !== undefined && error.response.status === 400) {
                        localStorage.removeItem('mtpPeriod');
                    }
                }
            }
            if (this.mtpPeriod && this.mtpPeriod.mtpEndDate) {
                let currentDate = format(new Date(Date.now()), 'yyyy-MM-dd\'T00:00:00\'');
                this.mtpPeriod.mtpEndDate = format(new Date(this.mtpPeriod.mtpEndDate), 'yyyy-MM-dd\'T00:00:00\'');

                if (this.mtpPeriod.mtpEndDate < currentDate) {
                    this.isMtpPeriod = false;
                }
            }
        } else {
            this.isMtpPeriod = false;
        }

        this.loading = false;
    }

    async mounted() {
    }

    beforeRouteLeave(to, from, next) {
        if (this.isAccessDeniedModalActive) {
            next(false);
        } else {
            $('#firstMoveBulkModal').modal('hide');
            $('#secondMoveBulkModal').modal('hide');
            $('#firstDeleteBulkModal').modal('hide');
            $('#secondDeleteBulkModal').modal('hide');
            $('#exportPrioritizationModal').modal('hide');
            next(true);
        }
    }

    private goToBRPage(value) {
        this.isAccessDeniedModalActive = false;
        this.$router.push('/budget-requests');

    }

    private async openBulkMoveModal() {
        $('#firstMoveBulkModal').modal('show');
    }

    private async moveBulk() {
        this.loading = true;
        this.movedNumber = await this.budgetRequestService.moveAllPreviousMtpToCurrentMtp();
        this.$bvToast.show('bulk-move-toast');
        this.loading = false;
    }

    private async openBulkDeleteModal() {
        if (!this.isMtpPeriod) {
            $('#firstDeleteBulkModal').modal('show');
        }
    }

    private async deleteBulk() {
        this.loading = true;

        if (!this.isMtpPeriod) {
            let request = await this.budgetRequestService.clearAllInactiveBudgetRequests();
            this.deletedNumber = request.countDeletedCurrentMtps;
            this.$bvToast.show('delete-inactive-toast');
        }

        this.loading = false;
    }

    private hideToast() {
        this.$bvToast.hide('delete-inactive-toast');
        this.$bvToast.hide('bulk-move-toast');
        this.$bvToast.hide('toast-error-import');
    }

    private async upload(event) {
        $('#addBudgetRequestsModal').modal('hide');
        let data = new FormData();
        this.loading = true;
        if (event.target.files.length !== 0) {
            let file = event.target.files[0];
            data.append('file', file);
            try {
                this.errorMessage = await this.adminService.updateBudgetRequestRankings(data);
                this.$bvToast.show('toast-error-import');
                $('#file').val('');
            } catch (e) {
                $('#file').val('');
                this.$bvToast.show('toast-exception');
            }
        }
        this.loading = false;
    }

    private showModalError() {
        this.errorImport = this.errorMessage.items;
        this.$bvToast.hide('toast-error-import');
        $('#errorModal').modal('show');
    }

    private async openExportPrioritizationModal() {
        $('#exportPrioritizationModal').modal('show');
    }

    private async exportPrioritizationToExcel(globalIdsList: string[]) {
        this.data.exportPrioritization = true;
        const content = await this.adminService.exportProjectPrioritization(globalIdsList);

        if (content) {
            this.forceFileDownload(content.name, content.content);
        } else {
            this.$bvToast.show('toast-error-project-prioritization-no-data');
        }

        this.data.exportPrioritization = false;
    }

    private forceFileDownload(name, data) {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name);
        document.body.appendChild(link);
        link.click();
    }
}
