export const FORM_EVENTS = {
    CURRENTLY_SUBMITTING: 'currently-submitting',
    CURRENTLY_SUBMITTING_EDIT_EVENT: 'currently-submitting-edit',
    ERROR_SUBMITTING_EVENT: 'error-submitting',
    UPDATE_PROJECT_NAME_EVENT: 'update:project-name',
    FORM_SUBMITTED_EVENT: 'iybr-form-submitted',
    NOTIFIABLE_USER_LIST_UPDATE_EVENT: 'notifiable-user-update'
};

export const TABLE_KEYS = {
    spentYearMinus1: 'spentYearMinus1',
    spentYear: 'spentYear',
    spentYearPlus1: 'spentYearPlus1',
    spentYearPlus2: 'spentYearPlus2',
    spentYearPlus3: 'spentYearPlus3',
    spentAfterYear: 'spentAfterYear'
};

export const TABLE_FIELDS = {
    [TABLE_KEYS.spentYearMinus1]: 'forecast',
    [TABLE_KEYS.spentYear]: 'forecast',
    [TABLE_KEYS.spentYearPlus1]: 'new1',
    [TABLE_KEYS.spentYearPlus2]: 'new2',
    [TABLE_KEYS.spentYearPlus3]: 'new3',
    [TABLE_KEYS.spentAfterYear]: 'new3'
};

export type CurrencyRate = {
    year: number,
    baseCurrency: string,
    targetCurrency: string,
    forecast: number,
    new1: number,
    new2: number,
    new3: number
};
