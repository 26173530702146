import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import '~/components/home/home.scss';
import AccordionReportingComponent from './accordion-reporting/accordion-reporting.vue';
import { Data, User } from '~/utils';

@Component({
    components: {
        'accordion-reporting': AccordionReportingComponent
    }
})
export default class ReportingComponent extends Vue {
    private userPermissions = <any>[];
    private isAccessDeniedModalActive: boolean = false;
    private data: Data = Data.Instance;

    async created() {
        this.userPermissions = await User.getPerm();
        if (!this.userPermissions.includes('SingleSitePageView')) {
            $('#accessDeniedModal').modal('show');
            this.isAccessDeniedModalActive = true;
        }
    }

    private onClickCancel(value) {
        this.isAccessDeniedModalActive = value;
    }
} 