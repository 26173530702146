var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal fade",
    attrs: {
      "id": "addFilesModal",
      "aria-hidden": "true",
      "aria-labelledby": "exampleModalLabel",
      "data-backdrop": "static",
      "role": "dialog",
      "tabindex": "-1"
    }
  }, [_c('div', {
    staticClass: "modal-dialog",
    attrs: {
      "role": "document"
    }
  }, [_c('div', {
    staticClass: "modal-content"
  }, [_vm._m(0), _c('div', {
    staticClass: "modal-body"
  }, [_c('p', {
    staticClass: "pb-4"
  }, [_vm._v(" Please select plant to be able to add files ")]), _vm.data.plants ? _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.appliedPlant,
      expression: "appliedPlant"
    }],
    staticClass: "selectPlant",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.appliedPlant = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, [_c('option', {
    attrs: {
      "disabled": "",
      "hidden": "",
      "selected": "",
      "value": ""
    }
  }, [_vm._v(" Select plant ")]), _vm._l(_vm.data.plants, function (plant, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": plant.plantSid
      }
    }, [_vm._v(" " + _vm._s(plant.plantName) + " ")]);
  })], 2) : _vm._e(), _vm.userPermissions.includes('MtpSiteDocumentsEdit') ? _c('input', {
    ref: "fileupload",
    attrs: {
      "id": "addfileFromModal",
      "disabled": _vm.appliedPlant === '',
      "hidden": "",
      "name": "addfile",
      "type": "file"
    },
    on: {
      "change": function ($event) {
        return _vm.addFile($event);
      }
    }
  }) : _vm._e(), _vm.userPermissions.includes('MtpSiteDocumentsEdit') ? _c('label', {
    staticClass: "pointerLink btn btn-primary btn-add-files",
    attrs: {
      "for": "addfileFromModal"
    }
  }, [_c('icon', {
    staticClass: "icon-add-files",
    attrs: {
      "name": "plus"
    }
  }), _vm._v(" Add files ")], 1) : _vm._e()]), _c('div', {
    staticClass: "modal-footer"
  })])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal-header"
  }, [_c('h5', {
    staticClass: "modal-title",
    attrs: {
      "id": "exampleModalLabel"
    }
  }, [_vm._v(" Add files ")]), _c('button', {
    staticClass: "close",
    attrs: {
      "aria-label": "Close",
      "data-dismiss": "modal",
      "type": "button"
    }
  }, [_c('span', {
    attrs: {
      "aria-hidden": "true"
    }
  }, [_vm._v("×")])])]);
}]

export { render, staticRenderFns }