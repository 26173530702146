import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class MoveCurrentMtpWarningModalComponent extends Vue {
    @Prop()
    private globalId: string;
    @Prop()
    private selectedMtpYear: number;
    @Prop()
    private budgetRequestId: string;

    constructor() {
        super();
    }

    private onClickButton() {
        this.$emit('clickedcanceleditstate', false);
    }

    private emitDetails() {
        this.$router.push({
            path: 'details-page',
            query: { year: new Date(Date.now()).getFullYear().toString(), globalId: this.globalId }
        });
    }

    private currentMtpDetails() {
        this.$emit('reInitMtp', new Date(Date.now()).getFullYear(), this.budgetRequestId);
    }
}
