var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "reporting"
  }, [_vm.data.changedMtpLoading === true || _vm.data.exportBr === true || _vm.data.switchPageView === true ? _c('loading-spinner') : _vm._e(), _vm.data.constants && _vm.data.fleet ? _c('div', [_c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.QUARTER_ROW
    }
  }, [_vm.isAssetDataAvailableOnReporting ? _c('div', [_c('pui-form-group', {
    attrs: {
      "label": "MTP Period"
    }
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedMtpFilter,
      expression: "selectedMtpFilter"
    }],
    staticClass: "form-control",
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.selectedMtpFilter = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, _vm.changeMtpPeriod]
    }
  }, _vm._l(_vm.data.sortedReportingMtpFilters, function (mtpFilter, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": ((mtpFilter.year) + "-" + (mtpFilter.miniFdYear))
      }
    }, [_vm._v(" " + _vm._s(mtpFilter.description) + " ")]);
  }), 0)])], 1) : _vm._e()]), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.QUARTER_ROW
    }
  }, [_c('div', {
    staticClass: "multiselect-menu"
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Fleet"
    }
  }, [_c('button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "btn btn-filter custom-button",
    attrs: {
      "data-toggle": "dropdown"
    },
    on: {
      "click": function ($event) {
        return _vm.clearSelectedTehnology();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.appliedTech && _vm.appliedTech.length !== 0 ? '' : 'Select') + " "), _c('icon', {
    attrs: {
      "name": "sort-down"
    }
  }), !_vm.appliedTech ? [_vm._v(" - ")] : _vm._e(), _vm._l(_vm.appliedTech, function (tehnology) {
    return [_vm._v(" " + _vm._s(tehnology.name) + " ")];
  })], 2), _c('div', {
    attrs: {
      "id": "#dropdown-mtp-status"
    }
  }, [_c('div', {
    staticClass: "dropdown-menu style-dropdown-filter"
  }, [_vm._l(_vm.data.fleet, function (fleet, key) {
    return _c('label', {
      key: key,
      staticClass: "dropdown-item",
      on: {
        "click": function ($event) {
          $event.stopPropagation();
        }
      }
    }, [_c('label', {
      staticClass: "container"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedTehnology,
        expression: "selectedTehnology"
      }],
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "value": fleet,
        "checked": Array.isArray(_vm.selectedTehnology) ? _vm._i(_vm.selectedTehnology, fleet) > -1 : _vm.selectedTehnology
      },
      on: {
        "change": [function ($event) {
          var $$a = _vm.selectedTehnology,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = fleet,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && (_vm.selectedTehnology = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.selectedTehnology = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.selectedTehnology = $$c;
          }
        }, function ($event) {
          return _vm.activeButtonApply();
        }]
      }
    }), _c('span', {
      staticClass: "checkmark"
    })]), _vm._v(" " + _vm._s(fleet.name) + " ")]);
  }), _c('div', {
    staticClass: "dropdown-divider"
  }), _c('div', {
    staticClass: "section-button"
  }, [_c('button', {
    staticClass: "btn btn-link",
    on: {
      "click": [function ($event) {
        return _vm.clearFilter('selectedTehnology');
      }, function ($event) {
        $event.stopPropagation();
      }]
    }
  }, [_vm._v(" Clear ")]), _c('button', {
    staticClass: "btn btn-link",
    attrs: {
      "disabled": !_vm.activeButtonApplyFilter
    },
    on: {
      "click": function ($event) {
        return _vm.applySelectTehnology();
      }
    }
  }, [_vm._v(" Apply ")])])], 2)])])], 1)]), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.QUARTER_ROW
    }
  }, [Object.keys(_vm.unmodifiedAssetGroupCollection).length > 0 && _vm.isAssetDataAvailableOnReporting ? _c('div', {
    staticClass: "multiselect-menu"
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Plant / River Group"
    }
  }, [_c('button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "btn btn-filter custom-button",
    attrs: {
      "data-toggle": "dropdown"
    },
    on: {
      "click": function ($event) {
        return _vm.clearSelectedGroup();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.searchModel.plantIds && _vm.searchModel.plantIds.length !== 0 ? '' : 'Select') + " "), _c('icon', {
    attrs: {
      "name": "sort-down"
    }
  }), _vm.appliedGroup ? [_vm._l(_vm.appliedGroup, function (assetGroup) {
    return [_vm._v(" " + _vm._s(assetGroup.name) + " ")];
  })] : _vm._e()], 2), _c('div', {
    attrs: {
      "id": "#dropdown-mtp-status"
    }
  }, [_c('div', {
    staticClass: "dropdown-menu style-dropdown-filter"
  }, [_vm._l(Object.keys(_vm.unmodifiedAssetGroupCollection), function (fleet, index) {
    return _c('div', {
      key: index
    }, [_c('label', {
      staticClass: "subtitle-menu"
    }, [_vm._v(" " + _vm._s(fleet) + " ")]), _vm._l(_vm.unmodifiedAssetGroupCollection[fleet], function (assetGroup, key) {
      return _c('label', {
        key: key + 'reportPage' + fleet,
        staticClass: "dropdown-item",
        on: {
          "click": function ($event) {
            $event.stopPropagation();
          }
        }
      }, [_c('label', {
        staticClass: "container"
      }, [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: _vm.selectedGroup,
          expression: "selectedGroup"
        }],
        attrs: {
          "type": "checkbox"
        },
        domProps: {
          "value": assetGroup,
          "checked": Array.isArray(_vm.selectedGroup) ? _vm._i(_vm.selectedGroup, assetGroup) > -1 : _vm.selectedGroup
        },
        on: {
          "change": [function ($event) {
            var $$a = _vm.selectedGroup,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;

            if (Array.isArray($$a)) {
              var $$v = assetGroup,
                  $$i = _vm._i($$a, $$v);

              if ($$el.checked) {
                $$i < 0 && (_vm.selectedGroup = $$a.concat([$$v]));
              } else {
                $$i > -1 && (_vm.selectedGroup = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
              }
            } else {
              _vm.selectedGroup = $$c;
            }
          }, function ($event) {
            return _vm.activeButtonApply();
          }]
        }
      }), _c('span', {
        staticClass: "checkmark"
      })]), _vm._v(" " + _vm._s(assetGroup.name) + " ")]);
    })], 2);
  }), _c('div', {
    staticClass: "sticky-position"
  }, [_c('div', {
    staticClass: "dropdown-divider"
  }), _c('div', {
    staticClass: "section-button"
  }, [_c('button', {
    staticClass: "btn btn-link",
    on: {
      "click": [function ($event) {
        return _vm.selectAllGroups();
      }, function ($event) {
        $event.stopPropagation();
      }]
    }
  }, [_vm._v(" All ")]), _c('button', {
    staticClass: "btn btn-link",
    on: {
      "click": [function ($event) {
        return _vm.clearFilter('selectedGroup');
      }, function ($event) {
        $event.stopPropagation();
      }]
    }
  }, [_vm._v(" Clear ")]), _c('button', {
    staticClass: "btn btn-link",
    attrs: {
      "disabled": !_vm.activeButtonApplyFilter
    },
    on: {
      "click": function ($event) {
        return _vm.applySelectGroup();
      }
    }
  }, [_vm._v(" Apply ")])])])], 2)])])], 1) : _vm._e()]), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.ROWS.QUARTER_ROW
    }
  }, [Object.keys(_vm.unmodifiedPlantsCollection).length > 0 && this.isAssetDataAvailableOnReporting ? _c('div', {
    staticClass: "multiselect-menu"
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Plant"
    }
  }, [_c('button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "btn btn-filter custom-button",
    attrs: {
      "data-toggle": "dropdown"
    },
    on: {
      "click": function ($event) {
        return _vm.clearSelectedPlant();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.searchModel.plantIds && _vm.searchModel.plantIds.length !== 0 ? '' : 'Select') + " "), _c('icon', {
    attrs: {
      "name": "sort-down"
    }
  }), _vm.appliedPlant ? [_vm._l(_vm.appliedPlant, function (plants) {
    return [_vm._v(" " + _vm._s(plants.plantName) + " ")];
  })] : _vm._e()], 2), _c('div', {
    attrs: {
      "id": "#dropdown-mtp-status"
    }
  }, [_c('div', {
    staticClass: "dropdown-menu style-dropdown-filter"
  }, [_vm._l(Object.keys(_vm.unmodifiedPlantsCollection), function (plantGroup, index) {
    return _c('div', {
      key: index
    }, [_c('label', {
      staticClass: "subtitle-menu"
    }, [_vm._v(" " + _vm._s(plantGroup) + " ")]), _vm._l(_vm.unmodifiedPlantsCollection[plantGroup], function (plants, key) {
      return _c('label', {
        key: key,
        staticClass: "dropdown-item",
        on: {
          "click": function ($event) {
            $event.stopPropagation();
          }
        }
      }, [_c('label', {
        staticClass: "container"
      }, [_c('input', {
        directives: [{
          name: "model",
          rawName: "v-model",
          value: _vm.selectedPlants,
          expression: "selectedPlants"
        }],
        attrs: {
          "type": "checkbox"
        },
        domProps: {
          "value": plants,
          "checked": Array.isArray(_vm.selectedPlants) ? _vm._i(_vm.selectedPlants, plants) > -1 : _vm.selectedPlants
        },
        on: {
          "change": [function ($event) {
            var $$a = _vm.selectedPlants,
                $$el = $event.target,
                $$c = $$el.checked ? true : false;

            if (Array.isArray($$a)) {
              var $$v = plants,
                  $$i = _vm._i($$a, $$v);

              if ($$el.checked) {
                $$i < 0 && (_vm.selectedPlants = $$a.concat([$$v]));
              } else {
                $$i > -1 && (_vm.selectedPlants = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
              }
            } else {
              _vm.selectedPlants = $$c;
            }
          }, function ($event) {
            return _vm.activeButtonApply();
          }]
        }
      }), _c('span', {
        staticClass: "checkmark"
      })]), _vm._v(" " + _vm._s(plants.plantName) + " ")]);
    })], 2);
  }), _c('div', {
    staticClass: "sticky-position"
  }, [_c('div', {
    staticClass: "dropdown-divider"
  }), _c('div', {
    staticClass: "section-button"
  }, [_c('button', {
    staticClass: "btn btn-link",
    on: {
      "click": [function ($event) {
        return _vm.selectAllPlants();
      }, function ($event) {
        $event.stopPropagation();
      }]
    }
  }, [_vm._v(" All ")]), _c('button', {
    staticClass: "btn btn-link",
    on: {
      "click": [function ($event) {
        return _vm.clearFilter('selectedPlants');
      }, function ($event) {
        $event.stopPropagation();
      }]
    }
  }, [_vm._v(" Clear ")]), _c('button', {
    staticClass: "btn btn-link",
    attrs: {
      "disabled": !_vm.activeButtonApplyFilter
    },
    on: {
      "click": function ($event) {
        return _vm.applySelectPlants();
      }
    }
  }, [_vm._v(" Apply ")])])])], 2)])])], 1) : _vm._e()])], 1), !_vm.isAssetDataAvailableOnReporting ? _c('div', [_c('div', {
    staticClass: "load-card"
  }, [_c('pui-loader-error', {
    attrs: {
      "title": "Oh no!",
      "message": "Master data assets not available.",
      "icon": "error-empty-data"
    }
  })], 1)]) : !_vm.isLoading ? _c('div', {
    staticClass: "reporting__table"
  }, [_c('div', {
    staticClass: "reporting__table__extra-header"
  }, [_c('div', {
    staticClass: "reporting__table__extra-header__title"
  }, [_c('pui-headline', {
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" Budget Requests ")])], 1), _c('div', {
    staticClass: "reporting__table__extra-header__actions"
  }, [_vm.userPermissions.includes('MtpSiteDocumentsView') && _vm.isAssetDataAvailableOnReporting ? _c('pui-button', {
    attrs: {
      "icon": "exported",
      "state": "primary",
      "small": ""
    },
    on: {
      "click": _vm.exportAllToExcelTable
    }
  }, [_vm._v(" Export all to Excel ")]) : _vm._e()], 1)]), _vm.itemsChecked.length > 0 ? _c('div', {
    staticClass: "reporting__table__extra-header"
  }, [_c('div', {
    staticClass: "reporting__table__extra-header__title"
  }, [_c('pui-headline', {
    attrs: {
      "type": "h6"
    }
  }, [_vm._v(" " + _vm._s(_vm.itemsChecked.length) + " item(s) selected ")])], 1), _c('div', {
    staticClass: "reporting__table__extra-header__actions"
  }, [(Object.keys(_vm.itemsChecked).length === 1 || parseInt(_vm.mtpYear) !== 0) && _vm.userPermissions.includes('MtpSiteDocumentsView') ? _c('pui-button', {
    attrs: {
      "icon": "exported",
      "state": "primary",
      "small": ""
    },
    on: {
      "click": _vm.exportToExcelTable
    }
  }, [_vm._v(" Export selected to Excel ")]) : _vm._e(), _c('pui-button', {
    attrs: {
      "icon": "close",
      "state": "primary",
      "small": ""
    },
    on: {
      "click": _vm.clearItemsChecked
    }
  }, [_vm._v(" Cancel ")])], 1)]) : _vm._e(), _c('table-header', {
    attrs: {
      "page-size": _vm.tableExtraFilters.pageSize
    },
    on: {
      "page-size-changed": _vm.onPageSizeChanged,
      "search-triggered": _vm.onTriggerSearch,
      "clear-search-term": _vm.onClearSearchTerm
    }
  }, [_vm.isFilterApplied ? _c('pui-button', {
    attrs: {
      "icon": "reset",
      "state": "primary",
      "small": ""
    },
    on: {
      "click": _vm.resetFilterState
    }
  }, [_vm._v(" Reset All Filters ")]) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: parseInt(this.miniFdYear) !== 0,
      expression: "parseInt(this.miniFdYear) !== 0"
    }],
    staticClass: "reporting__table__extra-header__toggle"
  }, [_c('pui-toggle', {
    attrs: {
      "value": _vm.selectedIsCrReadyForApproval
    },
    on: {
      "change": _vm.applyIsCrReadyForApprovalFilter
    },
    scopedSlots: _vm._u([{
      key: "puiToggleLeft",
      fn: function () {
        return [_vm._v(" Ready for approval ")];
      },
      proxy: true
    }], null, false, 3010119407)
  }), _c('div', {
    staticClass: "reporting__table__extra-header__toggle__info"
  }, [_c('pui-icon', {
    attrs: {
      "icon-color": "#adadad",
      "icon-name": "info-solid",
      "size": "14px"
    }
  }), _vm._v(" Click 'Change Request ID' to access Change Request for Approval. ")], 1)], 1)], 1), _c('ag-grid-vue', {
    staticClass: "ag-theme-alpine",
    attrs: {
      "grid-options": _vm.gridOptions,
      "column-defs": _vm.columnDefs
    },
    on: {
      "grid-ready": _vm.onGridReady
    }
  })], 1) : [_c('div', {
    staticClass: "load-card"
  }, [_c('pui-loader-spinner', {
    attrs: {
      "message": "Currently loading the table...",
      "title": "Please wait!"
    }
  })], 1)]], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }