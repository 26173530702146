var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-pagination"
  }, [_c('div', {
    staticClass: "page-size-selector"
  }, [_c('label', [_vm._v("Showing")]), _c('pui-form-select', {
    attrs: {
      "options": _vm.paginationOptions,
      "value": _vm.selectedPageSize.toString(),
      "label": "",
      "search-input-placeholder": " "
    },
    on: {
      "change": _vm.onPageSizeChange
    }
  }), _c('label', [_vm._v("out of " + _vm._s(_vm.totalCount) + " results.")])], 1), _c('div', {
    staticClass: "flex-row flex-center"
  }, [_vm.selectedPageNumber !== 1 ? _c('span', {
    staticClass: "firstpage pr-2",
    on: {
      "click": _vm.goToFirstPage
    }
  }, [_vm._v("First ")]) : _vm._e(), _c('pui-pagination', {
    attrs: {
      "range-of-pages": 9,
      "total-pages": _vm.totalPages
    },
    on: {
      "changed:page": _vm.pageNumberChanged
    },
    model: {
      value: _vm.selectedPageNumber,
      callback: function ($$v) {
        _vm.selectedPageNumber = $$v;
      },
      expression: "selectedPageNumber"
    }
  }), _vm.selectedPageNumber !== _vm.totalPages ? _c('span', {
    staticClass: "lastpage pl-2 mr-2",
    on: {
      "click": _vm.goToLastPage
    }
  }, [_vm._v("Last ")]) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }