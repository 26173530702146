import deepEqual from 'deep-equal'

export class CacheContainer<T, V> {
    private cacheMap: { key: T, value: V }[] = []

    public hasCachedValue(key: T): boolean {
        return this.cacheMap.some(e => deepEqual(e.key, key));
    }

    public getCachedValue(key: T): V | undefined {
        return this.cacheMap.find(e => deepEqual(e.key, key))?.value;
    }

    public invalidateKey(key: T): void {
        this.cacheMap = this.cacheMap.filter(e => !deepEqual(e.key, key));
    }

    public storeCachedValue(key: T, value: V): void {
        this.invalidateKey(key);
        this.cacheMap.push({ key, value });
    }
}
