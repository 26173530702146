import Vue from 'vue';
import Component from 'vue-class-component';
import SearchApproverComponent from './search-approver/search-approver.vue';
import SearchApproverComponentTs from './search-approver/search-approver';
import { DEPARTMENT_GROUP_NAMES } from '~/components/in-year-budget-requests/constants/department-group-names';
import { Prop, Watch } from 'vue-property-decorator';
import { ROWS } from '~/components/in-year-budget-requests/constants/rows';
import { MasterService } from '~/services/master-service';
import { Department, DepartmentGroups, SelectedStakeholder, Stakeholder } from '~/models/services/minifd';
import { EventBus } from '~/utils';

const FORM_APPROVAL_WORKFLOW_REFS = {
    PLANT_MANAGER_REF: 'plantManager',
    PRIMARY_DEPARTMENT_REF: 'primaryDepartment',
    ASSET_STRATEGY_REF: 'assetStrategy',
    ASSET_OPERATIONS_REF: 'assetOperations',
    ASSET_MANAGEMENT_REF: 'assetManagement',
    BUSINESS_CONTROLLING_REF: 'businessControlling'
} as const;

@Component({
    components: {
        searchApprover: SearchApproverComponent,
    },
})
export default class FormApprovalWorkflow extends Vue {
    private readonly SWEDEN_HYDRO_ASSET_OPERATIONS_NAME = "Operations Director";
    private readonly SWEDEN_HYDRO_BUSINESS_CONTROLLING_NAME = "SVP Business Controlling";

    @Prop()
    totalCost: number;

    @Prop()
    selectedLocalCompensation: string;

    @Prop()
    aeroClassification?: string;

    @Prop()
    isAssetStrategyRequired: boolean;

    @Prop()
    isHydroTechnology: boolean;

    @Prop()
    isSwedenCountry: boolean;

    @Prop()
    isSteamTechnology: boolean;

    @Prop()
    primaryDepartmentName?: string;

    $refs: {
        [FORM_APPROVAL_WORKFLOW_REFS.PLANT_MANAGER_REF]: SearchApproverComponentTs;
        [FORM_APPROVAL_WORKFLOW_REFS.PRIMARY_DEPARTMENT_REF]: SearchApproverComponentTs;
        [FORM_APPROVAL_WORKFLOW_REFS.ASSET_STRATEGY_REF]: SearchApproverComponentTs;
        [FORM_APPROVAL_WORKFLOW_REFS.ASSET_OPERATIONS_REF]: SearchApproverComponentTs;
        [FORM_APPROVAL_WORKFLOW_REFS.ASSET_MANAGEMENT_REF]: SearchApproverComponentTs;
        [FORM_APPROVAL_WORKFLOW_REFS.BUSINESS_CONTROLLING_REF]: SearchApproverComponentTs;
    };
    private readonly formLayout = ROWS;
    private readonly departmentGroupNames = DEPARTMENT_GROUP_NAMES;
    private readonly TWO_MILLION_EUROS = 2000000;
    private readonly FORM_APPROVAL_WORKFLOW_REFS = FORM_APPROVAL_WORKFLOW_REFS;
    private readonly MINI_FD_SERVICE = MasterService.instance.miniFdService;
    private departmentGroups: DepartmentGroups[] = null;
    private isAssetOperationsRequired: boolean = false;
    private plantManagerDepartment: Department = null;
    private assetStrategyDepartment: Department = null;
    private assetOperationsDepartment: Department = null;
    private assetManagementDepartment: Department = null;
    private businessControllingDepartment: Department = null;

    private get isPlantManagerRequired(): boolean {
        return !(this.isHydroTechnology && this.isSwedenCountry);
    }

    private get shouldDisplayDepartmentsNotSetNotification(): boolean {
        return (this.plantManagerDepartment && !this.plantManagerDepartment) ||
            !this.primaryDepartment ||
            (this.isAssetStrategyRequired && !this.assetStrategyDepartment) ||
            (this.isAssetOperationsRequired && !this.assetOperationsDepartment) ||
            !this.assetManagementDepartment ||
            !this.businessControllingDepartment;
    }

    public getStakeholderRefs(): SearchApproverComponentTs[] {
        const stakeholderRefs: SearchApproverComponentTs[] = [];

        const primaryDepartmentStakeHolder = this.$refs[FORM_APPROVAL_WORKFLOW_REFS.PRIMARY_DEPARTMENT_REF];
        const assetManagementStakeholder = this.$refs[FORM_APPROVAL_WORKFLOW_REFS.ASSET_MANAGEMENT_REF];
        const businessControllingStakeholder = this.$refs[FORM_APPROVAL_WORKFLOW_REFS.BUSINESS_CONTROLLING_REF];

        stakeholderRefs.push(primaryDepartmentStakeHolder, assetManagementStakeholder, businessControllingStakeholder)

        if (this.isPlantManagerRequired) {
            stakeholderRefs.push(this.$refs[FORM_APPROVAL_WORKFLOW_REFS.PLANT_MANAGER_REF]);
        }

        if (this.isAssetOperationsRequired) {
            stakeholderRefs.push(this.$refs[FORM_APPROVAL_WORKFLOW_REFS.ASSET_OPERATIONS_REF]);
        }

        if (this.isAssetStrategyRequired) {
            stakeholderRefs.push(this.$refs[FORM_APPROVAL_WORKFLOW_REFS.ASSET_STRATEGY_REF]);
        }

        return stakeholderRefs;
    }

    public getStakeHolders(): SelectedStakeholder[] {
        return this.getStakeholderRefs().map(ref => ref.getSelectedUser());
    }

    public validateApprovalWorkflowForm(): boolean {
        // Return true if no stakeholder is falsy
        if (!this.getStakeHolders().some(stakeholder => !stakeholder)) {
            return true;
        }

        this.getStakeholderRefs()
            .filter(ref => !ref.getSelectedUser())
            .forEach(ref => ref.highlightInvalidUserInput());

        return false;
    }

    public populateApprovalWorkflow(stakeholders: Stakeholder[]): void {
        const primaryDepartmentStakeholder = stakeholders.find((stakeholder) =>
            stakeholder.departmentGroupName === DEPARTMENT_GROUP_NAMES.PRIMARY_DEPARTMENT);
        this.$refs[FORM_APPROVAL_WORKFLOW_REFS.PRIMARY_DEPARTMENT_REF].populateStakeholderData(primaryDepartmentStakeholder);

        const assetManagementStakeholder = stakeholders.find((stakeholder) =>
            stakeholder.departmentGroupName === DEPARTMENT_GROUP_NAMES.ASSET_MANAGEMENT);
        this.$refs[FORM_APPROVAL_WORKFLOW_REFS.ASSET_MANAGEMENT_REF].populateStakeholderData(assetManagementStakeholder);

        const businessControllingStakeholder = stakeholders.find((stakeholder) =>
            stakeholder.departmentGroupName === DEPARTMENT_GROUP_NAMES.BUSINESS_CONTROLLING);
        this.$refs[FORM_APPROVAL_WORKFLOW_REFS.BUSINESS_CONTROLLING_REF].populateStakeholderData(businessControllingStakeholder);

        const plantManagerStakeholder = stakeholders.find((stakeholder) =>
            stakeholder.departmentGroupName === DEPARTMENT_GROUP_NAMES.PLANT_MANAGER);
        if (plantManagerStakeholder) {
            this.$refs[FORM_APPROVAL_WORKFLOW_REFS.PLANT_MANAGER_REF].populateStakeholderData(plantManagerStakeholder);
        }

        const assetStrategyStakeholder = stakeholders.find((stakeholder) =>
            stakeholder.departmentGroupName === DEPARTMENT_GROUP_NAMES.ASSET_STRATEGY);
        if (assetStrategyStakeholder) {
            this.$refs[FORM_APPROVAL_WORKFLOW_REFS.ASSET_STRATEGY_REF].populateStakeholderData(assetStrategyStakeholder);
        }

        const assetOperationsStakeholder = stakeholders.find((stakeholder) =>
            stakeholder.departmentGroupName === DEPARTMENT_GROUP_NAMES.ASSET_OPERATIONS);
        if (assetOperationsStakeholder) {
            this.$refs[FORM_APPROVAL_WORKFLOW_REFS.ASSET_OPERATIONS_REF].populateStakeholderData(assetOperationsStakeholder);
        }
    }

    @Watch('totalCost', { immediate: true, deep: true })
    private async setDepartments(): Promise<void> {
        if (this.departmentGroups) {
            this.filterDepartmentsByTotalCost(this.departmentGroups, this.totalCost);
            this.checkIfAssetOperationsIsRequired();
        } else {
            this.departmentGroups = await this.MINI_FD_SERVICE.getDepartments();
            EventBus.$emit(EventBus.CHANGE_REQUEST.GET_DEPARTMENTS_ID, this.departmentGroups);
            this.filterDepartmentsByTotalCost(this.departmentGroups, this.totalCost);
            this.checkIfAssetOperationsIsRequired();
        }

        this.applySwedenHydroFixedDepartmentNames();
    }

    private get primaryDepartment(): Department {
        const primaryDepartments = this.loadSpecificApprovalGroupDepartments(this.departmentGroups, this.departmentGroupNames.PRIMARY_DEPARTMENT);
        return primaryDepartments?.find((primaryDepartment) => primaryDepartment.name === this.primaryDepartmentName);
    }

    @Watch('selectedLocalCompensation', { immediate: true, deep: true })
    private checkIfAssetOperationsIsRequired(): void {
        const fleetLocalCompensation = '2';
        const assetOperationsLocalCompensation = '3';
        const cooAssetOperationDepartment = 'COO Asset Operation';
        const headOfPerformanceTeamDepartment = 'Head of Performance Team'

        const isFleetLocalCompensationSelected = fleetLocalCompensation === this.selectedLocalCompensation;
        const isAssetOperationsLocalCompensationSelected = assetOperationsLocalCompensation === this.selectedLocalCompensation;

        const assetOperationsDepartments = this.loadSpecificApprovalGroupDepartments(
            this.departmentGroups,
            this.departmentGroupNames.ASSET_OPERATIONS
        );

        if (this.totalCost < this.TWO_MILLION_EUROS && this.isSteamTechnology) {
            this.isAssetOperationsRequired = true;
            this.assetOperationsDepartment = assetOperationsDepartments.find(department => department.name === headOfPerformanceTeamDepartment);
        } else if(this.isSteamTechnology) {
            this.isAssetOperationsRequired = true;
            this.assetOperationsDepartment = this.filterDepartmentByTotalCost(assetOperationsDepartments, this.totalCost);
        } else if (isAssetOperationsLocalCompensationSelected) {
            this.isAssetOperationsRequired = true;
            this.assetOperationsDepartment = assetOperationsDepartments.find(department => department.name === cooAssetOperationDepartment);
        } else if (this.totalCost <= this.TWO_MILLION_EUROS && (isFleetLocalCompensationSelected || this.isHydroTechnology)) {
            this.isAssetOperationsRequired = true;
            this.assetOperationsDepartment = this.filterDepartmentByTotalCost(assetOperationsDepartments, this.totalCost);
        } else {
            this.assetOperationsDepartment = this.filterDepartmentByTotalCost(assetOperationsDepartments, this.totalCost);
            this.isAssetOperationsRequired = this.totalCost > this.TWO_MILLION_EUROS;
        }

        this.applySwedenHydroFixedDepartmentNames();
    }

    @Watch('isHydroTechnology')
    @Watch('isSteamTechnology')
    private addAssetOperationsToWorkflow(): void {
        this.checkIfAssetOperationsIsRequired();
    }

    private filterDepartmentsByTotalCost(departments: DepartmentGroups[], totalCost: number): void {
        let plantManagerDepartments = this.loadSpecificApprovalGroupDepartments(
            departments,
            this.departmentGroupNames.PLANT_MANAGER
        );

        const assetStrategyDepartments = this.loadSpecificApprovalGroupDepartments(
            departments,
            this.departmentGroupNames.ASSET_STRATEGY
        );

        const assetOperationsDepartments = this.loadSpecificApprovalGroupDepartments(
            departments,
            this.departmentGroupNames.ASSET_OPERATIONS
        );
        const assetManagementDepartments = this.loadSpecificApprovalGroupDepartments(
            departments,
            this.departmentGroupNames.ASSET_MANAGEMENT
        );
        const businessControllingDepartments = this.loadSpecificApprovalGroupDepartments(
            departments,
            this.departmentGroupNames.BUSINESS_CONTROLLING
        );

        this.plantManagerDepartment = this.filterDepartmentByTotalCost(
            plantManagerDepartments,
            totalCost
        );

        this.assetStrategyDepartment = this.filterDepartmentByTotalCost(
            assetStrategyDepartments,
            totalCost
        );

        this.assetOperationsDepartment = this.filterDepartmentByTotalCost(
            assetOperationsDepartments,
            totalCost
        );
        this.assetManagementDepartment = this.filterDepartmentByTotalCost(
            assetManagementDepartments,
            totalCost
        );
        this.businessControllingDepartment = this.filterDepartmentByTotalCost(
            businessControllingDepartments,
            totalCost
        );

        this.applySwedenHydroFixedDepartmentNames();
    }

    private applySwedenHydroFixedDepartmentNames(): void {
        if (this.isSwedenCountry && this.isHydroTechnology) {
            if (this.assetOperationsDepartment) {
                this.assetOperationsDepartment = {
                    ...this.assetOperationsDepartment,
                    name: this.SWEDEN_HYDRO_ASSET_OPERATIONS_NAME,
                };
            }

            if (this.businessControllingDepartment) {
                this.businessControllingDepartment = {
                    ...this.businessControllingDepartment,
                    name: this.SWEDEN_HYDRO_BUSINESS_CONTROLLING_NAME,
                };
            }
        }
    }

    private loadSpecificApprovalGroupDepartments(departments: DepartmentGroups[], departmentName: string): Department[] {
        const approvalGroupDepartments = departments?.find(department => department.name === departmentName)?.departments;
        return approvalGroupDepartments ? approvalGroupDepartments : [];
    }

    private filterDepartmentByTotalCost(departments: Department[], totalCost: number): Department {
        const filteredDepartment = departments.find(e => totalCost >= e.from && totalCost < e.to);

        if (!filteredDepartment && departments.length > 0) {
            const maxDepartment = departments.reduce((previous, current) => {
                return (previous.to > current.to) ? previous : current;
            });

            const minDepartment = departments.reduce((previous, current) => {
                return (previous.from < current.from) ? previous : current;
            });

            return (totalCost <= minDepartment.from) ? minDepartment : maxDepartment;
        }

        return filteredDepartment;
    }
}
