import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import type { ExternalDataRiskInformation } from '~/utils/interfaces';
import { PuiTableVendorOptions } from '~/models/libraries/pebble-ui';

@Component({})
export default class LinkedRiskCollapsibleComponent extends Vue {
    @Prop()
    private data!: ExternalDataRiskInformation;

    private get columns(): Record<string, string> {
        return {
            category: 'Category',
            currentRisk: 'Current Risk',
            afterScenario: 'After Scenario',
            mitigatedIrr: 'MIRR'
        };
    }

    private get tableData(): PuiTableVendorOptions {
        return {
            data: [
                this.createRow('Cost', this.data.costRiskBeforeTotalScore, this.data.costRiskAfterTotalScore, this.data.mitigatedIrr),
                this.createRow('Safety', this.data.safetyRiskBeforeTotalScore, this.data.safetyRiskAfterTotalScore),
                this.createRow('Environment', this.data.environmentRiskBeforeTotalScore, this.data.environmentRiskAfterTotalScore),
                this.createRow('Regulatory', this.data.regulatoryRiskBeforeTotalScore, this.data.regulatoryRiskAfterTotalScore),
                this.createRow('Reputation', this.data.reputationRiskBeforeTotalScore, this.data.reputationRiskAfterTotalScore)
            ],
            columns: Object.values(this.columns)
        };
    }

    private createRow(rowTitle: string, currentRisk: number, afterScenario: number, mitigatedIrr?: number): Record<string, string> {
        return {
            [this.columns.category]: rowTitle,
            [this.columns.currentRisk]: currentRisk.toString(),
            [this.columns.afterScenario]: afterScenario.toString(),
            [this.columns.mitigatedIrr]: mitigatedIrr ? mitigatedIrr.toString() : ''
        };
    }
}
