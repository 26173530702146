import Vue from 'vue';
import Component from 'vue-class-component';
import { MasterService } from '~/services/master-service';
import { Data, Helper } from '~/utils';
import { DEPARTMENT_GROUP_NAMES } from '~/components/in-year-budget-requests/constants/department-group-names';
import TableSearchComponent from '~/components/shared/table/table-search/table-search.vue';
import { Prop, Watch } from 'vue-property-decorator';
import { TablePaginationDefaults } from '~/components/shared/table/table-pagination/table-pagination';
import TablePaginationComponent from '~/components/shared/table/table-pagination/table-pagination.vue';
import TablePaginationComponentTs from '~/components/shared/table/table-pagination/table-pagination';
import TableAttachmentComponent from '~/components/shared/table/table-attachment/table-attachment.vue';
import { convertServerDateToBrowserDate } from '~/utils/date-helper';
import InYearBudgetRequestsTableFiltersComponent from '~/components/in-year-budget-requests/in-year-budget-requests-table/in-year-budget-requests-table-filters/in-year-budget-requests-table-filters.vue';
import { ChangeRequestStatusColors, StatusColors } from '~/components/in-year-budget-requests/constants/color-constants';
import { TABLE_LOADER_CONSTANT_SIZES } from '~/components/in-year-budget-requests/constants/table-loader-sizes';
import { CancelTokenSource } from 'axios';
import { PuiTableVendorOptions } from '~/models/libraries/pebble-ui';
import {
    MiniFdFilters,
    SearchMiniFdRequestItem,
    SearchMiniFdRequestItemStakeholder,
    SearchMiniFdRequestsPayload
} from '~/models/services/minifd';
import { AgGridVue } from 'ag-grid-vue';
import { CellClassParams, ColDef, ColumnApi, ColumnPinnedEvent, GetContextMenuItemsParams, GridApi, GridOptions, ICellRendererParams, IServerSideGetRowsParams, MenuItemDef, SetFilterValuesFuncParams, ValueFormatterParams } from 'ag-grid-community';
import { AgGridCommon } from 'ag-grid-community/dist/lib/interfaces/iCommon';
import TableHeader from '@/components/ag-grid-table/header-types/table-header.vue';
import NoRowsOverlay from '@/components/ag-grid-table/overlay-types/no-rows-overlay.vue';
import StatusCell from '~/components/ag-grid-table/cell-types/status-cell.vue';
import CheckmarkCell from '~/components/in-year-budget-requests/in-year-budget-requests-table/cells/checkmark-cell.vue';
import LinkCell from '~/components/ag-grid-table/cell-types/link-cell.vue';
import BRLinkCell from '~/components/in-year-budget-requests/in-year-budget-requests-table/cells/br-link-cell.vue';
import { format } from 'date-fns';

const STATUS_COLOR_MAP = {
    'Light_grey': '#d6d6d6',
    'Dark_grey': '#607d8b',
    'Magenta_Red': '#ffab00',
    'Amber_Yellow': '#19c142',
    'Green': '#0078dc',
} as const;

type MiniFdSetFilters = {
    statusFilter: number[],
    requestTypeFilters: number[],
    categoryFilters: number[],
    controllingCategoryFilters: number[],
    localBudgetCompensationFilter: number[],
    outageTypeFilter: number[],
};

const IYBR_TABLE_COLUMN_STATE = 'iybr_table_column_state' as const;

@Component({
    components: {
        AgGridVue,
        StatusCell,
        CheckmarkCell,
        LinkCell,
        BRLinkCell,
        TableHeader,
        NoRowsOverlay,
        tableSearch: TableSearchComponent,
        tablePagination: TablePaginationComponent,
        tableAttachment: TableAttachmentComponent,
        iybrTableFilters: InYearBudgetRequestsTableFiltersComponent
    },
})
export default class InYearBudgetRequestsTableComponent extends Vue {
    private gridApi: GridApi | null = null;
    private columnApi: ColumnApi | null = null;
    private tableExtraFilters = {
        pageSize: 20,
        searchTerm: '',
    }
    private isFilterApplied: boolean = false;

    $refs!: {
        tablePagination?: TablePaginationComponentTs
    };
    private readonly axios = require('axios');
    private readonly CONSTANT_SIZES = TABLE_LOADER_CONSTANT_SIZES;
    private readonly STATUS_COLORS = StatusColors;
    private readonly MINIFD_STATUS = {
        DRAFT_MINIFD: Data.Instance.constants.miniFdStatusesTypes[1],
        SUBMITTED_MINIFD: Data.Instance.constants.miniFdStatusesTypes[2],
        IN_REVIEW_MINIFD: Data.Instance.constants.miniFdStatusesTypes[3],
        APPROVED_MINIFD: Data.Instance.constants.miniFdStatusesTypes[4],
        REJECTED_MINIFD: Data.Instance.constants.miniFdStatusesTypes[5]
    };
    private readonly departmentGroupNames = DEPARTMENT_GROUP_NAMES;
    private data = Data.Instance;
    private tableEntryRows = [];
    private totalCount = 0;
    private itemsPerPage = TablePaginationDefaults.INITIAL_PAGE_SIZE;
    private pageNumber = TablePaginationDefaults.INITIAL_PAGE_NUMBER;
    private filters: MiniFdFilters = {
        unitSidFilters: [],
        requestTypeFilters: [],
        categoryFilters: [],
        controllingCategoryFilters: [],
        localBudgetCompensationFilter: [],
        outageTypeFilter: [],
        statusFilter: []
    };
    private filterText = '';
    private selectedPlantSids: number[] = [];
    private hasFinishedFirstRequest = false;
    private isLoadingNewInformation = false;
    private showNoRecordsFoundCard = false;
    private miniFdResults = [] as SearchMiniFdRequestItem[];
    private source = null as CancelTokenSource;
    @Prop()
    private selectedYear: number | null;

    private get maxPageSize(): number {
        return Math.max(...TablePaginationDefaults.PAGE_SIZE_SELECTION_OPTIONS);
    }

    private get searchFilters(): SearchMiniFdRequestsPayload {
        return {
            plantIds: this.data.selectedPlant?.map((plant) => plant.plantSid) ?? [],
            itemsPerPage: this.itemsPerPage,
            pageNo: this.pageNumber,
            filters: {
                ...this.filters,
                yearFilter: this.selectedYear
            },
            searchText: this.filterText,
        };
    }

    private get columns(): Record<string, string> {
        return {
            changeRequestId: 'Change request ID',
            globalProjectIds: 'Global ID(s)',
            status: 'Status',
            name: 'Name',
            country: 'Country',
            plantName: 'Plant Name / River group',
            unit: 'Unit',
            aeroClassification: 'AERO Classification',
            technologyName: 'Technology',
            requestType: 'Request Type',
            pcClassification: 'Planning & Controlling Classification',
            startDate: 'Project Start Date',
            endDate: 'Project End Date',
            uniperShare: 'Uniper Share of Budget',
            localBudgetCompensation: 'Budget Compensation',
            projectPrioritizationRank: 'Project Prioritization Rank',
            mainFlag: 'Main Flag',
            totalBudgetRequested: 'Total Budget requested',
            compensationPlanGlobalIds: 'Compensation Plan Global IDs',
            createdBy: 'Created by',
            dateCreated: 'Date Created',
            dateApproved: 'Date Approved',
            plantManager: this.departmentGroupNames.PLANT_MANAGER,
            primaryDepartment: this.departmentGroupNames.PRIMARY_DEPARTMENT,
            assetStrategy: this.departmentGroupNames.ASSET_STRATEGY,
            assetOperations: this.departmentGroupNames.ASSET_OPERATIONS,
            assetManagement: this.departmentGroupNames.ASSET_MANAGEMENT,
            businessControlling: this.departmentGroupNames.BUSINESS_CONTROLLING,
            mtpYear: 'Global IDs MTP Year'
        };
    }

    private get tableOptions(): PuiTableVendorOptions {
        return {
            options: {
                sortable: [],
                perPage: this.maxPageSize
            },
            columns: Object.values(this.columns),
            data: this.tableEntryRows
        };
    }

    beforeDestroy(): void {
        if (this.source !== null) {
            this.source.cancel('cancel search');
        }
    }

    @Watch('data.selectedPlant')
    private onSelectedPlantChanges(): void {
        this.selectedPlantSids = this.data.selectedPlant?.map((plant) => plant.plantSid) ?? [];
        this.refreshTable();
    }

    @Watch('selectedYear')
    private async onSelectedYearChange(): Promise<void> {
        this.refreshTable();
    }

    private mapTableData(miniFdRequests: SearchMiniFdRequestItem[]): Record<string, string>[] {
        const data = [];

        miniFdRequests.forEach((miniFd) => {
            data.push({
                [this.columns.changeRequestId]: miniFd.uniqueId,
                [this.columns.name]: miniFd.projectName,
                [this.columns.country]: miniFd.countryName,
                [this.columns.technologyName]: miniFd.technologyName,
                [this.columns.plantName]: miniFd.plantName,
                [this.columns.unit]: miniFd.unitName,
                [this.columns.requestType]: this.getConstantValue('requestTypes', miniFd.requestType),
                [this.columns.globalProjectIds]: miniFd.globalIds,
                [this.columns.aeroClassification]: this.getConstantValue('aeroClassification', miniFd.category),
                [this.columns.pcClassification]: this.getConstantValue('allPlanningAndControllingClassification', miniFd.planningAndControllingClassification),
                [this.columns.startDate]: convertServerDateToBrowserDate(miniFd.startDate),
                [this.columns.endDate]: convertServerDateToBrowserDate(miniFd.endDate),
                [this.columns.uniperShare]: miniFd.uniperShareOfBudget + '%',
                [this.columns.localBudgetCompensation]: this.getConstantValue('localBudgetCompensationsTypes', miniFd.budgetCompensation),
                [this.columns.mainFlag]: this.getConstantValue('flags', miniFd.mainFlag),
                [this.columns.totalBudgetRequested]: miniFd.totalBudget.toFixed(3),
                [this.columns.createdBy]: miniFd.createdBy,
                [this.columns.dateCreated]: convertServerDateToBrowserDate(miniFd.dateCreated),
                [this.columns.dateApproved]: miniFd.dateApproved && this.getConstantValue('miniFdStatusesTypes', miniFd.status) === this.MINIFD_STATUS.APPROVED_MINIFD ? convertServerDateToBrowserDate(miniFd.dateApproved) : '-',
                [this.columns.status]: this.getConstantValue('miniFdStatusesTypes', miniFd.status),
                [this.columns.compensationPlanGlobalIds]: miniFd.compensationPlanGlobalIds?.length > 0 ? miniFd.compensationPlanGlobalIds.join(', ') : '-',
                [this.columns.plantManager]: this.filterStakeholderByDepartmentGroup(
                    miniFd.stakeholders,
                    this.departmentGroupNames.PLANT_MANAGER
                ),
                [this.columns.primaryDepartment]: this.filterStakeholderByDepartmentGroup(
                    miniFd.stakeholders,
                    this.departmentGroupNames.PRIMARY_DEPARTMENT
                ),
                [this.columns.assetStrategy]: this.filterStakeholderByDepartmentGroup(
                    miniFd.stakeholders,
                    this.departmentGroupNames.ASSET_STRATEGY
                ),
                [this.columns.assetManagement]: this.filterStakeholderByDepartmentGroup(
                    miniFd.stakeholders,
                    this.departmentGroupNames.ASSET_MANAGEMENT
                ),
                [this.columns.assetOperations]: this.filterStakeholderByDepartmentGroup(
                    miniFd.stakeholders,
                    this.departmentGroupNames.ASSET_OPERATIONS
                ),
                [this.columns.businessControlling]: this.filterStakeholderByDepartmentGroup(
                    miniFd.stakeholders,
                    this.departmentGroupNames.BUSINESS_CONTROLLING
                ),
                [this.columns.projectPrioritizationRank]: miniFd.projectPrioritizationRank,
                [this.columns.mtpYear]: Helper.getYearsForLabel(miniFd.mtpYear),
            });
        });

        return data;
    }

    private filterStakeholderByDepartmentGroup(stakeholders: SearchMiniFdRequestItemStakeholder[], departmentGroup): SearchMiniFdRequestItemStakeholder {
        const stakeholder = stakeholders.find((stakeholder) => stakeholder.departmentGroupName === departmentGroup);
        if (stakeholder) {
            return stakeholder;
        }
    }

    private getConstantValue(constantType: string, constantKey: string | number): string {
        const constantValue = this.data.constants[constantType][constantKey];
        return constantValue ? constantValue : 'N/A';
    }

    private checkmarkColor(data: SearchMiniFdRequestItemStakeholder): string {
        if (data?.approvalDate) {
            return this.STATUS_COLORS.approved;
        }
        
        if (data?.rejectionDate) {
            return this.STATUS_COLORS.rejected;
        }

        return this.STATUS_COLORS.draft;
    }
    
    private get columnDefs(): ColDef[] {
        return [
            {
                headerName: 'Change Request ID',
                field: 'uniqueId',
                pinned: 'left',
                cellRenderer: 'LinkCell',
                cellRendererParams: (params: ICellRendererParams) => ({
                    href: 'view-in-year-budget-request',
                    query: {
                        uniqueId: params.value?.trim(),
                        history: 'false'
                    },
                }),
            },
            {
                headerName: 'Global ID(s)',
                field: 'globalIds',
                pinned: 'left',
                cellRenderer: 'BRLinkCell',
                resizable: true,
            },
            {
                headerName: 'Name',
                field: 'projectName',
                pinned: 'left',
            },
            {
                headerName: 'Status',
                field: 'status',
                valueGetter: (params): string => this.getConstantValue('miniFdStatusesTypes', params.data.status),
                cellRenderer: 'StatusCell',
                cellRendererParams: (params: ICellRendererParams) => ({ color: ChangeRequestStatusColors[params.data.status] }),
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.miniFdStatusesTypes));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.miniFdStatusesTypes[params.value];
                    }
                },
            },
            { headerName: 'Country', field: 'countryName' },
            { headerName: 'Plant Name / River Group', field: 'plantName' },
            { headerName: 'Unit', field: 'unitName' },
            {
                headerName: 'AERO Classification',
                field: 'category',
                valueFormatter: (params): string => this.getConstantValue('aeroClassification', params.value),
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.aeroClassification));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.aeroClassification[params.value];
                    }
                },
            },
            { headerName: 'Technology', field: 'technologyName' },
            {
                headerName: 'Request Type',
                field: 'requestType',
                valueFormatter: (params): string => this.getConstantValue('requestTypes', params.value),
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.requestTypes));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.requestTypes[params.value];
                    }
                },
            },
            {
                headerName: 'Outage Type',
                field: 'outageType',
                valueFormatter: (params): string => this.getConstantValue('outageType', params.value),
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.outageType));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.outageType[params.value];
                    }
                },
            },
            {
                headerName: 'Planning & Controlling Classification',
                field: 'planningAndControllingClassification',
                valueFormatter: (params): string => this.getConstantValue('planningAndControllingClassification', params.value),
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.allPlanningAndControllingClassification));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.allPlanningAndControllingClassification[params.value];
                    }
                },
            },
            {
                headerName: 'Project Start Date',
                field: 'startDate',
                valueFormatter: (params): string => format(new Date(params.value), 'MMMM do yyyy'),
            },
            {
                headerName: 'Project End Date',
                field: 'endDate',
                valueFormatter: (params): string => format(new Date(params.value), 'MMMM do yyyy'),
            },
            {
                headerName: 'Uniper Share of Budget',
                field: 'uniperShareOfBudget',
                valueFormatter: (params): string => params.value ? `${params.value}%` : '-',
            },
            {
                headerName: 'Budget Compensation',
                field: 'budgetCompensation',
                valueFormatter: (params): string => this.getConstantValue('localBudgetCompensationsTypes', params.value),
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.localBudgetCompensationsTypes));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.localBudgetCompensationsTypes[params.value];
                    }
                },
            },
            {
                headerName: 'Project Prioritization Rank',
                field: 'projectPrioritizationRank',
                valueFormatter: (params): string => params.value ?? '-',
            },
            {
                headerName: 'Main Flag',
                field: 'mainFlag',
                valueFormatter: (params): string => this.getConstantValue('flags', params.value),
            },
            {
                headerName: 'Total Budget Requested',
                field: 'totalBudget',
                valueFormatter: (params): string => params.value.toFixed(3),
            },
            { 
                headerName: 'Compensation Plan Global IDs',
                field: 'compensationPlanGlobalIds',
                valueFormatter: (params): string => params.value?.length > 0 ? params.value.join(', ') : '-',
            },
            { headerName: 'Created By', field: 'createdBy' },
            {
                headerName: 'Date Created',
                field: 'dateCreated',
                valueFormatter: (params): string => format(new Date(params.value), 'MMMM do yyyy'),
            },
            {
                headerName: 'Date Approved',
                field: 'dateApproved',
                valueFormatter: (params): string => params.value && this.getConstantValue('miniFdStatusesTypes', params.data.status) === this.MINIFD_STATUS.APPROVED_MINIFD ? format(new Date(params.value), 'MMMM do yyyy') : '-',
            },
            {
                headerName: this.departmentGroupNames.PLANT_MANAGER,
                field: 'stakeholders',
                valueGetter: (params): string => this.filterStakeholderByDepartmentGroup(params.data.stakeholders, this.departmentGroupNames.PLANT_MANAGER)?.name ?? 'None',
                cellRenderer: 'CheckmarkCell',
                cellRendererParams: (params: ICellRendererParams) => ({ color: this.checkmarkColor(this.filterStakeholderByDepartmentGroup(params.data.stakeholders, this.departmentGroupNames.PLANT_MANAGER)) }),
            },
            {
                headerName: this.departmentGroupNames.PRIMARY_DEPARTMENT,
                field: 'stakeholders',
                valueGetter: (params): string => this.filterStakeholderByDepartmentGroup(params.data.stakeholders, this.departmentGroupNames.PRIMARY_DEPARTMENT)?.name ?? 'None',
                cellRenderer: 'CheckmarkCell',
                cellRendererParams: (params: ICellRendererParams) => ({ color: this.checkmarkColor(this.filterStakeholderByDepartmentGroup(params.data.stakeholders, this.departmentGroupNames.PRIMARY_DEPARTMENT)) }),
            },
            {
                headerName: this.departmentGroupNames.ASSET_STRATEGY,
                field: 'stakeholders',
                valueGetter: (params): string => this.filterStakeholderByDepartmentGroup(params.data.stakeholders, this.departmentGroupNames.ASSET_STRATEGY)?.name ?? 'None',
                cellRenderer: 'CheckmarkCell',
                cellRendererParams: (params: ICellRendererParams) => ({ color: this.checkmarkColor(this.filterStakeholderByDepartmentGroup(params.data.stakeholders, this.departmentGroupNames.ASSET_STRATEGY)) }),
            },
            {
                headerName: this.departmentGroupNames.ASSET_OPERATIONS,
                field: 'stakeholders',
                valueGetter: (params): string => this.filterStakeholderByDepartmentGroup(params.data.stakeholders, this.departmentGroupNames.ASSET_OPERATIONS)?.name ?? 'None',
                cellRenderer: 'CheckmarkCell',
                cellRendererParams: (params: ICellRendererParams) => ({ color: this.checkmarkColor(this.filterStakeholderByDepartmentGroup(params.data.stakeholders, this.departmentGroupNames.ASSET_OPERATIONS)) }),
            },
            {
                headerName: this.departmentGroupNames.ASSET_MANAGEMENT,
                field: 'stakeholders',
                valueGetter: (params): string => this.filterStakeholderByDepartmentGroup(params.data.stakeholders, this.departmentGroupNames.ASSET_MANAGEMENT)?.name ?? 'None',
                cellRenderer: 'CheckmarkCell',
                cellRendererParams: (params: ICellRendererParams) => ({ color: this.checkmarkColor(this.filterStakeholderByDepartmentGroup(params.data.stakeholders, this.departmentGroupNames.ASSET_MANAGEMENT)) }),
            },
            {
                headerName: this.departmentGroupNames.BUSINESS_CONTROLLING,
                field: 'stakeholders',
                valueGetter: (params): string => this.filterStakeholderByDepartmentGroup(params.data.stakeholders, this.departmentGroupNames.BUSINESS_CONTROLLING)?.name ?? 'None',
                cellRenderer: 'CheckmarkCell',
                cellRendererParams: (params: ICellRendererParams) => ({ color: this.checkmarkColor(this.filterStakeholderByDepartmentGroup(params.data.stakeholders, this.departmentGroupNames.BUSINESS_CONTROLLING)) }),
            },
            {
                headerName: 'Global IDs MTP Year',
                field: 'mtpYear',
                valueFormatter: (params): string => Helper.getYearsForLabel(params.value),
            }
        ];
    }

    private gridOptions: GridOptions = {
        rowModelType: 'serverSide',
        serverSideDatasource: {
            getRows: (params: IServerSideGetRowsParams) => {
                this.fetchData(params);
            }
        },
        defaultColDef: {
            filter: true,
            sortable: false,
            unSortIcon: true,
            menuTabs: [],
            minWidth: 200,
        },
        domLayout: 'autoHeight',
        suppressMenuHide: true,
        pagination: true,
        paginationPageSize: this.tableExtraFilters.pageSize,
        cacheBlockSize: this.tableExtraFilters.pageSize,
        noRowsOverlayComponent: 'NoRowsOverlay',
        serverSideFilterOnServer: true,
        popupParent: document.querySelector('body'),
        sideBar: {
            defaultToolPanel: '',
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
        },
        getContextMenuItems: (params) => this.customContextMenu(params),
        onColumnMoved: this.setColumnState,
        onColumnVisible: this.setColumnState,
        onColumnPinned: this.setColumnState,
        onFilterChanged: () => {
            this.calcIsFilterApplied();
            this.refreshTable();
        },
        onToolPanelVisibleChanged: this.sizeColumnsToFit,
        onToolPanelSizeChanged: this.sizeColumnsToFit,
        onGridSizeChanged: this.sizeColumnsToFit,
    };

    private async fetchData(params: IServerSideGetRowsParams): Promise<void> {
        params.api.hideOverlay();

        const {
            statusFilter,
            requestTypeFilters,
            categoryFilters,
            controllingCategoryFilters,
            localBudgetCompensationFilter,
            outageTypeFilter,
        } = this.getSelectedFiltersForServerRequestParams(params);
        const pageNumber = this.pageNumberCalc(params.request.endRow);
        const { pageSize, searchTerm } = this.tableExtraFilters;

        const searchFilters: SearchMiniFdRequestsPayload = {
            plantIds: this.data.selectedPlant?.map((plant) => plant.plantSid) ?? [],
            itemsPerPage: pageSize,
            pageNo: pageNumber,
            searchText: searchTerm,
            filters: {
                ...this.filters,
                statusFilter,
                requestTypeFilters,
                categoryFilters,
                controllingCategoryFilters,
                localBudgetCompensationFilter,
                outageTypeFilter,
                yearFilter: this.selectedYear
            },
        };

        try {
            const response = await MasterService.instance.miniFdService.getAllMiniFds(searchFilters);
            this.miniFdResults = response.miniFdRequests;
            params.success({ rowData: response.miniFdRequests, rowCount: Number(response.total) });
            if (Number(response.total) === 0) {
                params.api.showNoRowsOverlay();
            }
        } catch (e) {
            params.fail()
        }
    }

    private onGridReady(params: AgGridCommon<any, any>): void {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;

        this.applyColumnState();
    }

    private applyColumnState(): void {
        let columnState = JSON.parse(localStorage.getItem(IYBR_TABLE_COLUMN_STATE));
        if (columnState) {
            this.columnApi.applyColumnState({ state: columnState, applyOrder: true });
        }
    }

    private setColumnState(): void {
        let columnState = JSON.stringify(this.columnApi.getColumnState());
        localStorage.setItem(IYBR_TABLE_COLUMN_STATE, columnState);
    }

    private calcIsFilterApplied(): void {
        const filterState = this.gridApi?.getFilterModel();
        const filterStateLength = Object.keys(filterState).length;
        this.isFilterApplied = filterStateLength > 0;
    }

    private resetFilterState(): void {
        this.gridApi?.setFilterModel(null);
    }

    private refreshTable(): void {
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private sizeColumnsToFit(): void {
        this.gridApi?.sizeColumnsToFit();
    }

    private onTriggerSearch(searchTerm: string): void {
        this.tableExtraFilters.searchTerm = searchTerm;
        this.refreshTable();
    }
    
    private onClearSearchTerm(): void {
        this.onTriggerSearch('');
    }

    private onPageSizeChanged(pageSize: number): void {
        this.tableExtraFilters.pageSize = pageSize;
        this.gridApi?.paginationSetPageSize(this.tableExtraFilters.pageSize);
        this.gridApi?.setCacheBlockSize(this.tableExtraFilters.pageSize);
    }

    private pageNumberCalc(endRow: number | undefined): number {
        return Math.floor((endRow ?? this.tableExtraFilters.pageSize) / this.tableExtraFilters.pageSize)
    }

    private getSelectedFiltersForServerRequestParams(params: IServerSideGetRowsParams): MiniFdSetFilters {
        return {
            statusFilter: params.request.filterModel.status?.values?.map((e: string) => Number(e)) ?? [],
            requestTypeFilters: params.request.filterModel.requestType?.values?.map((e: string) => Number(e)) ?? [],
            categoryFilters: params.request.filterModel.category?.values?.map((e: string) => Number(e)) ?? [],
            controllingCategoryFilters: params.request.filterModel.planningAndControllingClassification?.values?.map((e: string) => Number(e)) ?? [],
            localBudgetCompensationFilter: params.request.filterModel.budgetCompensation?.values?.map((e: string) => Number(e)) ?? [],
            outageTypeFilter: params.request.filterModel.outageType?.values?.map((e: string) => Number(e)) ?? [],
        };
    }

    private customContextMenu (params: GetContextMenuItemsParams): (string | MenuItemDef)[] {
        const el = params.node.data;
        const customContextMenu: MenuItemDef[] = [];

        if (params.column.getColId() === 'uniqueId') {
            customContextMenu.push({
                name: 'Open in New Tab',
                action: () => {
                    const routeData = this.$router.resolve({
                        path: 'view-in-year-budget-request',
                        query: {
                            uniqueId: el.uniqueId?.trim(),
                            history: 'false'
                        }
                    });
                    window.open(routeData.href, '_blank');
                },
            });

            return customContextMenu;
        }

        return [];
    }
}
