var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('pui-collapsible', {
    attrs: {
      "expandable": true,
      "headline": _vm.HEADER
    }
  }, [_c('pui-grid-container', {
    staticClass: "elements-center"
  }, [_c('pui-grid-row', [_c('pui-grid-column', {
    staticClass: "review-comment",
    attrs: {
      "cols": _vm.PAGE_LAYOUT.FULL_ROW
    }
  }, [_c('pui-form-group', {
    attrs: {
      "is-valid": _vm.isReviewValid,
      "label": "Review comment",
      "label-for": "reviewComment"
    }
  }, [_c('pui-form-textarea', {
    attrs: {
      "id": "reviewComment",
      "grow": true,
      "is-valid": _vm.isReviewValid,
      "placeholder-text": "Please enter text",
      "type": "text"
    },
    model: {
      value: _vm.reviewComment,
      callback: function ($$v) {
        _vm.reviewComment = $$v;
      },
      expression: "reviewComment"
    }
  }), _c('span', {
    attrs: {
      "slot": "error-message"
    },
    slot: "error-message"
  }, [_vm._v(" " + _vm._s(_vm.INVALID_COMMENT) + " ")])], 1)], 1), _c('pui-grid-column', {
    staticClass: "review-buttons",
    attrs: {
      "cols": _vm.PAGE_LAYOUT.FULL_ROW
    }
  }, [_c('pui-button', {
    staticClass: "approve-button",
    attrs: {
      "disabled": _vm.isSubmittingReview
    },
    on: {
      "click": function ($event) {
        return _vm.reviewMiniFd(_vm.WORKFLOW_ACTIONS.APPROVE);
      }
    }
  }, [_vm._v(" APPROVE ")]), _c('pui-button', {
    staticClass: "reject-button",
    attrs: {
      "disabled": _vm.isSubmittingReview,
      "state": "secondary"
    },
    on: {
      "click": function ($event) {
        return _vm.reviewMiniFd(_vm.WORKFLOW_ACTIONS.REJECT);
      }
    }
  }, [_vm._v(" REJECT ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }