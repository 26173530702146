var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.selectedMtpFilter !== 0 && _vm.userPermissions.includes('SingleSitePageView') ? _c('div', {
    staticClass: "pt-4"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "chart_wrapper"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-7"
  }, [_vm.data.selectedPlantGroup ? _c('h3', [_vm._v(" " + _vm._s(_vm.data.selectedPlantGroup.name) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "col-md-5 text-right lastestChange"
  }, [_vm.latestChangesChart && _vm.userPermissions.includes('SingleSitePageView') ? _c('h5', [_vm._v(" Updated by " + _vm._s(_vm.latestChangesChart.kId) + " on " + _vm._s(_vm.latestChangesChart.dateTime) + " ")]) : _vm._e(), _vm.userPermissions.includes('SingleSitePageView') ? _c('a', {
    staticClass: "button-edit",
    on: {
      "click": function ($event) {
        return _vm.goToHistory();
      }
    }
  }, [_vm._v("History ")]) : _vm._e()])]), _c('div', {
    staticClass: "wrapper_date"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm.countries ? _c('div', {
    staticClass: "col-lg-4 col-md-5 mb-2"
  }, [_c('h5', [_vm._v("Country")]), _vm._l(_vm.countryNames, function (countryName) {
    return [_c('pui-chip', {
      key: countryName,
      staticClass: "pui-chip-country",
      attrs: {
        "label": countryName
      }
    })];
  })], 2) : _vm._e(), _c('div', {
    staticClass: "col-lg-8 col-md-7"
  }, [_c('h5', [_vm._v("Technology")]), _vm.data.selectedTechnology ? _c('p', [_vm._v(" " + _vm._s(_vm.data.selectedTechnology.name) + " ")]) : _vm._e()])])]), _c('div', {
    staticClass: "row pt-4"
  }, [_c('div', {
    staticClass: "col-xl-4 col-lg-12 col-12"
  }, [_c('h5', [_vm._v("Budget requests")]), _c('div', [_vm.series.length > 0 ? _c('apexchart', {
    staticClass: "size-legend-pie",
    attrs: {
      "options": _vm.optionsChart,
      "series": _vm.series,
      "type": "donut"
    },
    on: {
      "dataPointSelection": _vm.dataPointSelectionHandler
    }
  }) : _vm._e(), _c('h6', [_vm._v("Select a status above to filter the table below")])], 1), _c('div', {
    staticClass: "vertical-bar"
  })]), _c('div', {
    staticClass: "col-xl-8 col-12"
  }, [_vm._m(0), _vm.showCurrencyToggle && _vm.countries ? _c('div', {
    staticClass: "currency-wrapper"
  }, [_c('div', {
    staticClass: "d-inline-block pr-1"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currencyCode,
      expression: "currencyCode"
    }],
    attrs: {
      "id": "mEUR",
      "checked": "",
      "type": "radio",
      "value": "mEUR"
    },
    domProps: {
      "checked": _vm._q(_vm.currencyCode, "mEUR")
    },
    on: {
      "change": [function ($event) {
        _vm.currencyCode = "mEUR";
      }, _vm.changedCurrencyCode]
    }
  }), _c('label', {
    attrs: {
      "for": "mEUR"
    }
  }, [_vm._v("mEUR")])]), _vm.selectedPlantCurrency ? _c('div', {
    staticClass: "d-inline-block pr-2"
  }, [_vm.selectedPlantCurrency.code !== 'mEUR' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.currencyCode,
      expression: "currencyCode"
    }],
    attrs: {
      "id": _vm.selectedPlantCurrency.code,
      "type": "radio"
    },
    domProps: {
      "value": _vm.selectedPlantCurrency.code,
      "checked": _vm._q(_vm.currencyCode, _vm.selectedPlantCurrency.code)
    },
    on: {
      "change": [function ($event) {
        _vm.currencyCode = _vm.selectedPlantCurrency.code;
      }, _vm.changedCurrencyCode]
    }
  }) : _vm._e(), _vm.selectedPlantCurrency.code !== 'mEUR' ? _c('label', [_vm._v(_vm._s(_vm.selectedPlantCurrency.code))]) : _vm._e()]) : _vm._e()]) : _vm._e(), _c('div', {
    staticStyle: {
      "max-width": "95%"
    },
    attrs: {
      "id": "content"
    }
  }, [_c('canvas', {
    ref: "chart",
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "height": "380"
    }
  })])])])])])]) : _vm._e();
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "legend-title"
  }, [_c('h5', [_vm._v("Budget distribution")]), _c('div', {
    staticClass: "legend-budget-distribution"
  }, [_c('div', {
    staticClass: "box-legend"
  }, [_c('span', {
    staticClass: "dot-legend-budget-distribution"
  }), _c('span', {
    staticClass: "text-legend"
  }, [_vm._v("OPEX")])]), _c('div', {
    staticClass: "box-legend capex-color"
  }, [_c('span', {
    staticClass: "dot-legend-budget-distribution capex-color"
  }), _c('span', {
    staticClass: "text-legend"
  }, [_vm._v("CAPEX")])])])]);
}]

export { render, staticRenderFns }