var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('pui-navigation-toolbar', {
    attrs: {
      "link-items": _vm.linkItems,
      "structure-select": _vm.shouldDisplayStructureSelect ? _vm.plantStructure : null
    },
    on: {
      "clicked:tab": _vm.handleClick,
      "changed:structure-select-id": _vm.handleAssetGroupSelection
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }