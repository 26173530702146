var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('transition', {
    attrs: {
      "name": "fade"
    }
  }, [_vm.showModal ? _c('div', {
    attrs: {
      "id": "inYearBudgetRequestsModal"
    }
  }, [_c('style', {
    tag: "component"
  }, [_vm._v(" body { overflow: hidden; } ")]), _c('div', {
    staticClass: "modal-mask"
  }, [_c('div', {
    staticClass: "modal-wrapper"
  }, [_c('div', {
    staticClass: "modal-dialog modal-lg",
    attrs: {
      "role": "document"
    }
  }, [_c('div', {
    staticClass: "modal-content"
  }, [_c('div', {
    staticClass: "modal-body"
  }, [_c('pui-grid-container', [_c('pui-grid-row', [_c('pui-grid-column', {
    staticStyle: {
      "margin": "20px 0"
    },
    attrs: {
      "cols": _vm.formLayout.FULL_ROW
    }
  }, [_c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('div', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.FULL_ROW,
      expression: "formLayout.FULL_ROW"
    }]
  }, [_c('pui-headline', {
    attrs: {
      "looks-like": "form-sheet-title",
      "type": "h1"
    }
  }, [_vm._v(" Budget Compensation Plan "), _c('button', {
    staticClass: "close",
    attrs: {
      "id": "inYearBudReqModalCloseBtn",
      "aria-label": "Close",
      "data-dismiss": "modal",
      "type": "button"
    }
  }, [_c('span', {
    attrs: {
      "aria-hidden": "true"
    },
    on: {
      "click": _vm.closeModal
    }
  }, [_vm._v("× ")])])])], 1)]), _c('pui-form', {
    attrs: {
      "aria-label": "Form"
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submitRowAdditions.apply(null, arguments);
      }
    }
  }, [_c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('p', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.FULL_ROW,
      expression: "formLayout.FULL_ROW"
    }],
    attrs: {
      "id": "in-year-bud-req-modal-p"
    }
  }, [_vm._v(" This is needed only for projects where budget compensation is total or partially possible. Please add budget compensation plan ")])]), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.HALF_ROW,
      expression: "formLayout.HALF_ROW"
    }],
    attrs: {
      "is-valid": _vm.invalidGlobalIds.length === 0,
      "label": "Global IDs",
      "label-for": "globalIdInput"
    }
  }, [_c('pui-form-type-ahead', {
    attrs: {
      "options": _vm.globalIdOptions,
      "use-local-cache": false,
      "label": "Global IDs",
      "multiple": "",
      "search-input-id": "globalIdInput"
    },
    on: {
      "search": _vm.handleGlobalIdSearch
    },
    model: {
      value: _vm.formData.globalIds.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.globalIds, "value", $$v);
      },
      expression: "formData.globalIds.value"
    }
  }), _c('span', {
    attrs: {
      "slot": "error-message"
    },
    slot: "error-message"
  }, [_vm._v(" " + _vm._s(_vm.globalIdErrorMessage) + " ")])], 1)], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('div', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.FULL_ROW,
      expression: "formLayout.FULL_ROW"
    }],
    staticClass: "pui-comment-form__footer"
  }, [_c('pui-button', {
    attrs: {
      "state": "secondary",
      "type": "button"
    },
    on: {
      "click": _vm.closeModal
    }
  }, [_vm._v(" Cancel ")]), _c('pui-button', {
    attrs: {
      "disabled": !_vm.shouldAllowSubmit,
      "type": "submit"
    }
  }, [_vm._v(" Create ")])], 1)])])], 1)], 1)], 1)], 1)])])])])], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }