var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "plant-selector"
  }, [_c('pui-form-group', {
    attrs: {
      "cta-callback": _vm.selectAllPlants,
      "has-cta": _vm.hasSelectableOptions,
      "cta-label": "Select all",
      "label": "Power plant selection"
    }
  }, [_c('pui-form-multi-select', {
    staticClass: "plant-selector__multi-select",
    attrs: {
      "is-disabled": !_vm.hasSelectableOptions,
      "options": _vm.selectionOptions,
      "label": "Power plant selection"
    },
    on: {
      "change": _vm.selectedOptionsChanged
    },
    model: {
      value: _vm.selectedOptions,
      callback: function ($$v) {
        _vm.selectedOptions = $$v;
      },
      expression: "selectedOptions"
    }
  }), _vm.hasSelectableOptions ? _c('span', {
    staticClass: "pui-form-group__cta float-right",
    on: {
      "click": _vm.deselectAllPlants
    }
  }, [_vm._v(" Clear all ")]) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }