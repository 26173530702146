var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('pui-collapsible', {
    staticClass: "collapsible-pb collapsible-bg",
    attrs: {
      "expandable": false,
      "headline": "FUNCTIONAL APPROVAL WORKFLOW"
    }
  }, [_c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_vm.shouldDisplayDepartmentsNotSetNotification ? _c('pui-form-inline-notification', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.FULL_ROW,
      expression: "formLayout.FULL_ROW"
    }],
    staticClass: "warning-inline-notification",
    attrs: {
      "text": "The values for the departments will populate as you fill out the form.",
      "title": "Departments are not yet populated",
      "type": "warning"
    }
  }) : _vm._e()], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('search-approver', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isPlantManagerRequired,
      expression: "isPlantManagerRequired"
    }],
    ref: _vm.FORM_APPROVAL_WORKFLOW_REFS.PLANT_MANAGER_REF,
    attrs: {
      "department": _vm.plantManagerDepartment,
      "label": _vm.departmentGroupNames.PLANT_MANAGER
    }
  })], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('search-approver', {
    ref: "primaryDepartment",
    attrs: {
      "department": _vm.primaryDepartment,
      "label": _vm.departmentGroupNames.PRIMARY_DEPARTMENT
    }
  })], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('search-approver', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isAssetStrategyRequired,
      expression: "isAssetStrategyRequired"
    }],
    ref: _vm.FORM_APPROVAL_WORKFLOW_REFS.ASSET_STRATEGY_REF,
    attrs: {
      "department": _vm.assetStrategyDepartment,
      "label": _vm.departmentGroupNames.ASSET_STRATEGY
    }
  })], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('search-approver', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isAssetOperationsRequired,
      expression: "isAssetOperationsRequired"
    }],
    ref: _vm.FORM_APPROVAL_WORKFLOW_REFS.ASSET_OPERATIONS_REF,
    attrs: {
      "department": _vm.assetOperationsDepartment,
      "label": _vm.departmentGroupNames.ASSET_OPERATIONS
    }
  })], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('search-approver', {
    ref: _vm.FORM_APPROVAL_WORKFLOW_REFS.ASSET_MANAGEMENT_REF,
    attrs: {
      "department": _vm.assetManagementDepartment,
      "label": _vm.departmentGroupNames.ASSET_MANAGEMENT
    }
  })], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('search-approver', {
    ref: _vm.FORM_APPROVAL_WORKFLOW_REFS.BUSINESS_CONTROLLING_REF,
    attrs: {
      "department": _vm.businessControllingDepartment,
      "label": _vm.departmentGroupNames.BUSINESS_CONTROLLING
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }