var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal d-block",
    attrs: {
      "id": "myModal"
    }
  }, [_c('div', {
    staticClass: "modal-dialog"
  }, [_c('div', {
    staticClass: "modal-content"
  }, [_vm._m(0), _vm.data.loadingSaveBudgetRequest ? _c('div', {
    staticClass: "modal-footer text-center ml-auto mr-auto pb-5"
  }, [_vm._v(" Saving budget request... ")]) : _vm._e(), _vm.data.switchingEditMode ? _c('div', {
    staticClass: "modal-footer text-center ml-auto mr-auto pb-5 d-block"
  }, [_c('h4', {
    staticClass: "d-block pt-2"
  }, [_vm._v(" Edit budget request ")]), _vm._v(" Switching to edit mode... ")]) : _vm._e(), _vm.data.changedMtpLoading ? _c('div', {
    staticClass: "modal-footer text-center ml-auto mr-auto pb-5 d-block"
  }, [_c('h4', {
    staticClass: "d-block pt-2"
  }, [_vm._v(" Edit budget request ")]), _vm._v(" Updating budget request(s)... ")]) : _vm._e(), _vm.data.removeFromMtp ? _c('div', {
    staticClass: "modal-footer text-center ml-auto mr-auto pb-5 d-block"
  }, [_c('h4', {
    staticClass: "d-block pt-2"
  }, [_vm._v(" Remove from MTP ")]), _vm._v(" Removing budget request(s)... ")]) : _vm._e(), _vm.data.deleteBr ? _c('div', {
    staticClass: "modal-footer text-center ml-auto mr-auto pb-5 d-block"
  }, [_c('h4', {
    staticClass: "d-block pt-2"
  }, [_vm._v(" Delete budget request(s) ")]), _vm._v(" Deleting budget request(s)... ")]) : _vm._e(), _vm.data.exportBr ? _c('div', {
    staticClass: "modal-footer text-center ml-auto mr-auto pb-5 d-block"
  }, [_c('h4', {
    staticClass: "d-block pt-2"
  }, [_vm._v(" Export budget request(s) ")]), _vm._v(" Exporting budget request(s)... ")]) : _vm._e(), _vm.data.addToCurrentMtp ? _c('div', {
    staticClass: "modal-footer text-center ml-auto mr-auto pb-5 d-block"
  }, [_c('h4', {
    staticClass: "d-block pt-2"
  }, [_vm._v(" Add to current MTP ")]), _vm._v(" Activating budget request... ")]) : _vm._e(), _vm.data.switchPageView ? _c('div', {
    staticClass: "modal-footer text-center ml-auto mr-auto pb-5 d-block"
  }, [_c('h4', {
    staticClass: "d-block pt-2"
  }, [_vm._v(" Switching page views ")]), _vm._v(" Loading budget requests... ")]) : _vm._e(), _vm.data.uploadingSpreadSheet ? _c('div', {
    staticClass: "modal-footer text-center ml-auto mr-auto pb-5 d-block"
  }, [_c('h4', {
    staticClass: "d-block pt-2"
  }, [_vm._v(" Add budget request(s) ")]), _vm._v(" Uploading spreadsheet... ")]) : _vm._e(), _vm.data.addFile ? _c('div', {
    staticClass: "modal-footer text-center ml-auto mr-auto pb-5 d-block"
  }, [_c('h4', {
    staticClass: "d-block pt-2"
  }, [_vm._v(" Add file ")]), _vm._v(" Uploading file... ")]) : _vm._e(), _vm.data.deleteFile ? _c('div', {
    staticClass: "modal-footer text-center ml-auto mr-auto pb-5 d-block"
  }, [_c('h4', {
    staticClass: "d-block pt-2"
  }, [_vm._v(" Delete file ")]), _vm._v(" Deleting file... ")]) : _vm._e(), _vm.data.downloadFile ? _c('div', {
    staticClass: "modal-footer text-center ml-auto mr-auto pb-5 d-block"
  }, [_c('h4', {
    staticClass: "d-block pt-2"
  }, [_vm._v(" Download file ")]), _vm._v(" Downloading file... ")]) : _vm._e(), _vm.data.exportPrioritization ? _c('div', {
    staticClass: "modal-footer text-center ml-auto mr-auto pb-5 d-block"
  }, [_c('h4', {
    staticClass: "d-block pt-2"
  }, [_vm._v(" Export Project Prioritization ")]), _vm._v(" Export Project Prioritization... ")]) : _vm._e()])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "modal-body centered ml-auto mr-auto pt-5"
  }, [_c('div', {
    staticClass: "loader"
  })]);
}]

export { render, staticRenderFns }