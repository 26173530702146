import { DepartmentGroup } from '~/models/services/minifd';

export const STRING_DEPARTMENT_GROUP_MAP = {
    "Plant Manager": DepartmentGroup.PLANT_MANAGER,
    "Primary Department": DepartmentGroup.PRIMARY_DEPARTMENT,
    "Asset Strategy": DepartmentGroup.ASSET_STRATEGY,
    "Asset Operations": DepartmentGroup.ASSET_OPERATIONS,
    "Asset Management": DepartmentGroup.ASSET_MANAGEMENT,
    "Business Controlling": DepartmentGroup.BUSINESS_CONTROLLING
} as const;
