var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.shouldDisplayTable ? _c('div', {
    staticClass: "iybr-compensation-table"
  }, [_c('pui-table', {
    attrs: {
      "columns-are-center-aligned": true,
      "first-column-is-left-aligned": true,
      "vendor-options": _vm.tableOptions
    },
    scopedSlots: _vm._u([_vm._l(Object.values(_vm.columns), function (columnName) {
      return {
        key: columnName,
        fn: function (data) {
          return [_c('div', {
            key: columnName
          }, [_vm.shouldDisplayCustomField(data, _vm.customFieldTypes.INPUT) ? _c('div', [_vm.compensationModel && _vm.compensationModel[data.row[_vm.columns.globalId]] && _vm.compensationModel[data.row[_vm.columns.globalId]][_vm.columnFieldMapping[data.column]] ? [_c('input', {
            directives: [{
              name: "model",
              rawName: "v-model",
              value: _vm.compensationModel[data.row[_vm.columns.globalId]][_vm.columnFieldMapping[data.column]].value,
              expression: "compensationModel[data.row[columns.globalId]][columnFieldMapping[data.column]].value"
            }, {
              name: "mask",
              rawName: "v-mask",
              value: _vm.getInputMaskForColumn(data.column),
              expression: "getInputMaskForColumn(data.column)"
            }],
            class: ['input-field', {
              'validation-error': !_vm.compensationModel[data.row[_vm.columns.globalId]][_vm.columnFieldMapping[data.column]].validation.isValid
            }],
            attrs: {
              "type": "text"
            },
            domProps: {
              "value": _vm.compensationModel[data.row[_vm.columns.globalId]][_vm.columnFieldMapping[data.column]].value
            },
            on: {
              "input": [function ($event) {
                if ($event.target.composing) { return; }

                _vm.$set(_vm.compensationModel[data.row[_vm.columns.globalId]][_vm.columnFieldMapping[data.column]], "value", $event.target.value);
              }, _vm.computeNewValues]
            }
          })] : _vm._e()], 2) : _vm.shouldDisplayCustomField(data, _vm.customFieldTypes.DROPDOWN) ? _c('div', [_vm.compensationModel && _vm.compensationModel[data.row[_vm.columns.globalId]] && _vm.compensationModel[data.row[_vm.columns.globalId]][_vm.columnFieldMapping[data.column]] ? [_c('pui-form-select', {
            staticClass: "justification-dropdown",
            attrs: {
              "is-valid": _vm.compensationModel[data.row[_vm.columns.globalId]][_vm.columnFieldMapping[data.column]].validation.isValid,
              "label": data.column,
              "options": _vm.justificationDropdownOptions
            },
            model: {
              value: _vm.compensationModel[data.row[_vm.columns.globalId]][_vm.columnFieldMapping[data.column]].value,
              callback: function ($$v) {
                _vm.$set(_vm.compensationModel[data.row[_vm.columns.globalId]][_vm.columnFieldMapping[data.column]], "value", $$v);
              },
              expression: "compensationModel[data.row[columns.globalId]][columnFieldMapping[data.column]].value"
            }
          })] : _vm._e()], 2) : _vm.shouldDisplayCustomField(data, _vm.customFieldTypes.ACTIONS) ? _c('div', {
            staticClass: "global-id-actions"
          }, [_vm._v(" " + _vm._s(_vm.tableCellTextFormat(data.row[data.column])) + " "), _c('pui-button', {
            attrs: {
              "icon-only": false,
              "small": true,
              "icon": "delete"
            },
            on: {
              "click": function ($event) {
                return _vm.removeRowWithGlobalId(data.row[_vm.columns.globalId]);
              }
            }
          })], 1) : _c('div', [_vm._v(" " + _vm._s(_vm.tableCellTextFormat(data.row[data.column])) + " ")])])];
        }
      };
    })], null, true)
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }