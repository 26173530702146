import '../web.config';
import Vue from 'vue';
import PebbleUI from '@enerlytics/pebble-ui/dist/pebble-ui';
import IconComponent from 'vue-awesome/components/Icon.vue';
import VueLoading from 'vue-loading-overlay';
import Multiselect from 'vue-multiselect';
import BootstrapVue from 'bootstrap-vue';
import VueMask from 'v-mask';
import 'adam.ui-core/dist/umd';
import { Data, Router, User } from './utils';
import { ServerTable } from 'vue-tables-2';
import store from './store';

import VueI18n from 'vue-i18n';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import './main.scss';
import 'bootstrap';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-loading-overlay/dist/vue-loading.min.css';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import ConfirmationModalComponent from './components/data-input/confirmation-modal/confirmation-modal.vue';
import LoadingSpinnerComponent from './components/loading-spinner/loading-spinner.vue';
import StateEditModalComponent from './components/data-input/state-edit-modal/state-edit-modal.vue';
import MtpStatusFlaggedModalComponent from './components/data-input/mtp-status-flagged-modal/mtp-status-flagged-modal.vue';
import MtpStatusReviewedModalComponent from './components/data-input/mtp-status-reviewed-modal/mtp-status-reviewed-modal.vue';
import ConfirmationReviewModalComponent from './components/data-input/confirmation-review-modal/confirmation-review-modal.vue';
import RemoveBrModalComponent from './components/data-input/remove-br-modal/remove-br-modal.vue';
import SetInactiveModalComponent from './components/data-input/set-inactive-modal/set-inactive-modal.vue';
import DeleteBrModalComponent from './components/data-input/delete-br-modal/delete-br-modal.vue';
import ConfirmDeleteModalComponent from './components/data-input/confirm-delete-modal/confirm-delete-modal.vue';
import UpdateMtpModalComponent from './components/data-input/update-mtp-modal/update-mtp-modal.vue';
import ChangeReviewModalComponent from './components/data-input/change-review-modal/change-review-modal.vue';
import RemoveFromMtpTableModalComponent from './components/data-input/remove-from-mtp-table-modal/remove-from-mtp-table-modal.vue';
import DeleteRemainTableModalComponent from './components/data-input/delete-remain-table-modal/delete-remain-table-modal.vue';
import AddBudgetRequestsModalComponent from './components/data-input/add-budget-requests-modal/add-budget-requests-modal.vue';
import ErrorModalComponent from './components/data-input/error-modal/error-modal.vue';
import VueApexCharts from 'vue-apexcharts';
import DeleteFileModalComponent from './components/data-input/delete-file-modal/delete-file-modal.vue';
import MoveCurrentMtpWarningModalComponent from './components/data-input/move-current-mtp-warning-modal/move-current-mtp-warning-modal.vue';
import AccessDeniedModalComponent from './components/data-input/access-denied-modal/acces-denied-modal.vue';
import ToastErrorMessageComponent from './components/data-input/toast-error-message/toast-error-message.vue';
import BulkApproveModalComponent from './components/data-input/bulk-approve-modal/bulk-approve-modal.vue';
import { ClickOutside } from './utils/click-outside';
import ToastProviderComponent from './components/toast-provider/toast-provider.vue';
import NavbarComponent from '~/components/navbar/navbar.vue';
import AddInYearBudgetRequestsModalComponent from './components/in-year-budget-requests/add-in-year-budget-requests-modal/add-in-year-budget-requests-modal.vue';
import InYearBudgetRequestsTableComponent from './components/in-year-budget-requests/in-year-budget-requests-table/in-year-budget-requests-table.vue';
import { APP_SCOPE, coodeSdkConfig, coodeSdkPlugin } from '~/utils/coode-sdk-config';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '@/assets/stylesheets/ag-grid-table.scss';
import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey('');

Vue.component('icon', IconComponent);
Vue.component('apexchart', VueApexCharts);
Vue.component('loading-spinner', LoadingSpinnerComponent);
Vue.component('loading', VueLoading);
Vue.component('confirmation-modal', ConfirmationModalComponent);
Vue.component('confirmation-review-modal', ConfirmationReviewModalComponent);
Vue.component('state-edit-modal', StateEditModalComponent);
Vue.component('remove-br-modal', RemoveBrModalComponent);
Vue.component('delete-br-modal', DeleteBrModalComponent);
Vue.component('mtp-status-flagged-modal', MtpStatusFlaggedModalComponent);
Vue.component('set-inactive-modal', SetInactiveModalComponent);
Vue.component('multiselect', Multiselect);
Vue.component('datepicker', DatePicker);
Vue.component('mtp-status-reviewed-modal', MtpStatusReviewedModalComponent);
Vue.component('confirm-delete-modal', ConfirmDeleteModalComponent);
Vue.component('update-mtp-modal', UpdateMtpModalComponent);
Vue.component('change-review-modal', ChangeReviewModalComponent);
Vue.component('remove-from-mtp-table-modal', RemoveFromMtpTableModalComponent);
Vue.component('delete-remain-table-modal', DeleteRemainTableModalComponent);
Vue.component('add-budget-requests-modal', AddBudgetRequestsModalComponent);
Vue.component('add-in-year-budget-requests-modal', AddInYearBudgetRequestsModalComponent);
Vue.component('error-modal', ErrorModalComponent);
Vue.component('delete-file-modal', DeleteFileModalComponent);
Vue.component('move-current-mtp-warning-modal', MoveCurrentMtpWarningModalComponent);
Vue.component('access-denied-modal', AccessDeniedModalComponent);
Vue.component('toast-error-message', ToastErrorMessageComponent);
Vue.component('bulk-approve-modal', BulkApproveModalComponent);
Vue.component('in-year-budget-requests-table', InYearBudgetRequestsTableComponent);
Vue.directive('clickoutside', ClickOutside);
// internationalization

Vue.use(VueI18n);
Vue.use(PebbleUI);
Vue.use(ServerTable);
Vue.use(VueMask);
Vue.use(BootstrapVue);
Vue.use(require('vue-moment'));
Vue.use(coodeSdkPlugin, coodeSdkConfig);

new Vue({
    el: '#app-main',
    router: Router,
    store,
    components: {
        toastProvider: ToastProviderComponent,
        navbar: NavbarComponent,
    },
    data: {
        user: User,
        signedIn: true,
        hasLoadedInitialData: false
    },
    methods: {
        async setupCoodeSdk(): Promise<void> {
            await this.$sdk.$authenticator?.loadAuthModule();
            await this.$sdk.$authenticator?.attemptSsoSilent('loginRedirect');

            await this.$sdk.$http?.$generic.request.api.setBaseUrl(process.env.VUE_APP_VUE_UI_API_PML_URL);

            await this.$sdk.$http?.$generic.request.api.setTokenCallback(
                async () => this.$sdk.$authenticator?.getToken(APP_SCOPE)
            );

            const isSignedIn = await this.$sdk.$authenticator?.isAuthenticated() as boolean;

            if (isSignedIn) {
                const useCaseId = Number(process.env.VUE_APP_USE_CASE_ID);
                const hasPermissionsSet = await this.$sdk.$permissions?.setPermissions(useCaseId);

                if (hasPermissionsSet) {
                    await User.loadUserPermissionsIntoData();
                }

                this.signedIn = true;
            }
        }
    },
    async created() {
        try {
            localStorage.removeItem('mtpPeriod');

            await this.setupCoodeSdk();

            if (Object.keys(this.$route.query).length > 0) {
                sessionStorage.setItem('redirectUrl', this.$route.fullPath);
            }

            await Data.Instance.loadConstantsFromServer();
            await Data.Instance.loadMtpPeriods();
            await Data.Instance.loadCrPeriods();
            this.hasLoadedInitialData = true;
        } catch (e) {
            this.hasLoadedInitialData = true;
            this.$bvToast.show('toast-data-error');
        }
    }
});
