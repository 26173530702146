import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import { ColDef, ColumnApi, GridApi, GridOptions, IServerSideGetRowsParams } from 'ag-grid-community';
import TableHeader from '@/components/ag-grid-table/header-types/table-header.vue';
import NoRowsOverlay from '@/components/ag-grid-table/overlay-types/no-rows-overlay.vue';
import { AgGridCommon } from 'ag-grid-community/dist/lib/interfaces/iCommon';
import { HurdleRateService } from '~/services/hurdle-rates';

const HURDLE_RATES_TABLE_COLUMN_STATE = 'hurdle_rates_table_column_state' as const;

type ModifiedValue = {
    id: string;
    rate: number;
}

@Component({
    components: {
        AgGridVue,
        TableHeader,
        NoRowsOverlay,
    }
})
export default class HurdleRatesTableComponent extends Vue {
    private HurdleRateService: HurdleRateService = new HurdleRateService();
    private gridApi: GridApi | null = null;
    private columnApi: ColumnApi | null = null;
    private localRates: any = null;
    private modifiedValues = new Map<string, ModifiedValue>();
    private isEdited = false;
    private loading = false;

    private tableExtraFilters = {
        pageSize: 20,
        searchTerm: '',
    }

    private get columnDefs(): ColDef[] {
        return [
            { headerName: 'Country', field: 'country' },
            { headerName: 'Technology', field: 'technology' },
            {
                headerName: 'Rate',
                field: 'rate',
                editable: true,
                valueSetter: (params): boolean => {
                    if (params.oldValue.toString() === params.newValue || params.newValue.trim() === '' || !params.data) {
                        return false;
                    }

                    const id = params.data.id;
                    const rate = this.parseValue(params.newValue);

                    // Update modified value in this.modifiedValues
                    if (rate) {
                        const modifiedValue: ModifiedValue = { id, rate };
                        this.modifiedValues.set(id, modifiedValue);
                        this.isEdited = true;
                        
                        // Update modified value in table data
                        params.data[params.column.getColId()] = rate;
                        return true;
                    }

                    return false;
                }
            },
        ];
    }

    private gridOptions: GridOptions = {
        rowModelType: 'serverSide',
        serverSideDatasource: {
            getRows: (params: IServerSideGetRowsParams) => {
                this.fetchData(params);
            }
        },
        defaultColDef: {
            filter: true,
            sortable: false,
            unSortIcon: true,
            menuTabs: [],
            minWidth: 150,
            cellClass: (params) => params.colDef.editable ? '' : 'hurdle-rates-table__table__cell--non-editable',
        },
        domLayout: 'autoHeight',
        suppressMenuHide: true,
        pagination: true,
        paginationPageSize: this.tableExtraFilters.pageSize,
        cacheBlockSize: this.tableExtraFilters.pageSize,
        noRowsOverlayComponent: 'NoRowsOverlay',
        serverSideFilterOnServer: true,
        popupParent: document.querySelector('body'),
        tooltipShowDelay: 0,
        tooltipMouseTrack: true,
        sideBar: {
            defaultToolPanel: '',
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
        },
        onColumnMoved: this.setColumnState,
        onColumnVisible: this.setColumnState,
        onColumnPinned: this.setColumnState,
        onToolPanelVisibleChanged: this.sizeColumnsToFit,
        onToolPanelSizeChanged: this.sizeColumnsToFit,
        onGridSizeChanged: this.sizeColumnsToFit,
    };

    private onGridReady(params: AgGridCommon<any, any>): void {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;

        this.applyColumnState();
    }

    private applyColumnState(): void {
        let columnState = JSON.parse(localStorage.getItem(HURDLE_RATES_TABLE_COLUMN_STATE));
        if (columnState) {
            this.columnApi.applyColumnState({ state: columnState, applyOrder: true });
        }
    }

    private setColumnState(): void {
        let columnState = JSON.stringify(this.columnApi.getColumnState());
        localStorage.setItem(HURDLE_RATES_TABLE_COLUMN_STATE, columnState);
    }

    private resetFilterState(): void {
        this.gridApi?.setFilterModel(null);
    }

    private refreshTable(): void {
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private sizeColumnsToFit(): void {
        this.gridApi?.sizeColumnsToFit();
    }

    private onTriggerSearch(searchTerm: string): void {
        this.tableExtraFilters.searchTerm = searchTerm;
        this.refreshTable();
    }
    
    private onClearSearchTerm(): void {
        this.onTriggerSearch('');
    }

    private onPageSizeChanged(pageSize: number): void {
        this.tableExtraFilters.pageSize = pageSize;
        this.gridApi?.paginationSetPageSize(this.tableExtraFilters.pageSize);
        this.gridApi?.setCacheBlockSize(this.tableExtraFilters.pageSize);
    }
    
    private pageNumber(endRow: number | undefined): number {
        return Math.floor((endRow ?? this.tableExtraFilters.pageSize) / this.tableExtraFilters.pageSize)
    }

    private async fetchData(params: IServerSideGetRowsParams): Promise<void> {
        params.api.hideOverlay();

        const pageNumber = this.pageNumber(params.request.endRow);
        const { pageSize, searchTerm } = this.tableExtraFilters;

        try {
            this.localRates = await this.HurdleRateService.getHurdleRates(pageNumber, pageSize, searchTerm);
            params.success({ rowData: this.localRates.hurdleRates.hurdleRates, rowCount: this.localRates.hurdleRates.total });
            if (this.localRates.hurdleRates.total === 0) {
                params.api.showNoRowsOverlay()
            }
        } catch (e) {
            params.fail()
        }
    }

    private parseValue(value: string): number | null {
        const parsedValue = parseFloat(value);
        return isNaN(parsedValue) ? null : parsedValue;
    }

    private get isEditDisabled(): boolean {
        return !this.isEdited || this.loading;
    }

    private async saveChanges(): Promise<void> {
        if (this.modifiedValues.size === 0) {
            return;
        }

        this.loading = true;

        const hurdleRatesToEdit: ModifiedValue[] = [];
        this.modifiedValues.forEach(value => hurdleRatesToEdit.push(value));

        const payload: { hurdleRatesToEdit: ModifiedValue[] } = {
            hurdleRatesToEdit,
        }

        try {
            await this.HurdleRateService.editHurdleRates(payload);
            this.modifiedValues.clear();
            this.isEdited = false;
            this.gridApi?.refreshServerSide({ purge: true });
            this.$pui.toast({
                type: 'success',
                title: 'Success',
                copy: 'Hurdle Rates saved.',
            });
        } catch (err) {
            this.$pui.toast({
                type: 'error',
                title: 'Failed',
                copy: 'Hurdle Rates failed to save.',
            });
        } finally {
            this.loading = false;
        }
    }
}
