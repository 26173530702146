import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop, Watch } from 'vue-property-decorator';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
import { zeroIfNaN } from '~/utils/math-helper';
import { cellValueClass, tableCellTextFormat } from '~/utils/table-helper';
import { formatToDecimals } from '~/utils/number-helper';
import { ProjectTypes } from '~/components/in-year-budget-requests/create-in-year-budget-request/create-iybr-form/iybr-global-ids-collapsible/iybr-global-ids-collapsible';
import { PuiTableCellData, PuiTableVendorOptions } from '~/models/libraries/pebble-ui';

export interface PopulateNewProjectCollapsibleFormData {
    spentYearMinus1: number;
    spentYear: number;
    spentYearPlus1: number;
    spentYearPlus2: number;
    spentYearPlus3: number;
    spentAfterYear: number;
}

export interface IybrNewProjectCollapsibleFormData {
    spentYearMinus1: string;
    spentYear: string;
    spentYearPlus1: string;
    spentYearPlus2: string;
    spentYearPlus3: string;
    spentAfterYear: string;
}

@Component({})
export default class IybrNewProjectCollapsibleComponent extends Vue {
    @Prop()
    private mtpYear: number;

    @Prop()
    private currencyName: string;

    @Prop()
    private projectType: string;

    private formData: IybrNewProjectCollapsibleFormData = {
        spentYearMinus1: '',
        spentYear: '',
        spentYearPlus1: '',
        spentYearPlus2: '',
        spentYearPlus3: '',
        spentAfterYear: ''
    };

    private numberInputMask = createNumberMask({
        prefix: '',
        includeThousandsSeparator: false,
        allowDecimal: true,
        decimalLimit: 3,
        allowNegative: true
    });
    private cellValueClass = cellValueClass;
    private tableCellTextFormat = tableCellTextFormat;

    private get columns(): Record<string, string> {
        return {
            'title': `Currency: ${this.currencyName}`,
            'MTP-1': `${this.mtpYear - 1}`,
            'MTP': `Before ${this.mtpYear + 1}`,
            'MTP+1': `${this.mtpYear + 1}`,
            'MTP+2': `${this.mtpYear + 2}`,
            'MTP+3': `${this.mtpYear + 3}`,
            'ff': 'FF',
            'total': 'Total'
        };
    }

    private get shownColumns(): string[] {
        const hiddenColumns = ['MTP-1'];

        if (this.projectType === ProjectTypes.NonProject) {
            hiddenColumns.push('MTP');
        }

        return Object.keys(this.columns)
            .filter(key => !hiddenColumns.includes(key))
            .map(key => this.columns[key]);
    }

    private get formDataMapping(): Record<string, string> {
        return {
            [this.columns['MTP-1']]: 'spentYearMinus1',
            [this.columns['MTP']]: 'spentYear',
            [this.columns['MTP+1']]: 'spentYearPlus1',
            [this.columns['MTP+2']]: 'spentYearPlus2',
            [this.columns['MTP+3']]: 'spentYearPlus3',
            [this.columns['ff']]: 'spentAfterYear',
        };
    }

    private get totalCost(): number {
        const projectFields = ['spentYearMinus1', 'spentYear', 'spentAfterYear'];

        return Object.values(this.formDataMapping)
            .map(field => {
                if (this.projectType === ProjectTypes.NonProject && projectFields.includes(field)) {
                    return 0;
                }
                return zeroIfNaN(parseFloat(this.formData[field]));
            })
            .reduce((previous, current) => previous + current);
    }

    private get tableOptions(): PuiTableVendorOptions {
        const totalBudgetRequired = {
            [this.columns['title']]: 'Total Budget Required',
            [this.columns['total']]: this.totalCost
        };

        return {
            data: [
                totalBudgetRequired
            ],
            options: {
                sortable: []
            },
            columns: Object.values(this.shownColumns)
        };
    }

    public populateFormData(data: PopulateNewProjectCollapsibleFormData): void {
        this.formData = {
            spentYearMinus1: formatToDecimals(data.spentYearMinus1, 3).toString(),
            spentYear: formatToDecimals(data.spentYear, 3).toString(),
            spentYearPlus1: formatToDecimals(data.spentYearPlus1, 3).toString(),
            spentYearPlus2: formatToDecimals(data.spentYearPlus2, 3).toString(),
            spentYearPlus3: formatToDecimals(data.spentYearPlus3, 3).toString(),
            spentAfterYear: formatToDecimals(data.spentAfterYear, 3).toString()
        };
    }

    @Watch('formData', { deep: true })
    private onFormDataChange(): void {
        this.$emit('changed:table-data', this.formData);
    }

    private shouldDisplayInputField(data: PuiTableCellData): boolean {
        return data.column !== this.columns.title && data.column !== this.columns.total;
    }
}
