import Vue from 'vue';
import Component from 'vue-class-component';
import { EventBus } from '~/utils/eventbus';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class RemoveBrModalComponent extends Vue {
    private selectedStateBr: string = '';
    private currentYear = new Date(Date.now()).getFullYear();
    @Prop()
    private createdYear: number;

    constructor() {
        super();
    }

    public clear() {
        this.selectedStateBr = '';
    }

    private yesChanges() {
        EventBus.$emit(EventBus.REMOVE_BUDGET_REQUEST.REMOVE_MODAL, this.selectedStateBr);
    }

    private onClickButton() {
        this.$emit('clickedcanceleditstate', false);
    }
}
