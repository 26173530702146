import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { AgGridVue } from 'ag-grid-vue';
import { CellClassParams, ColDef, ColumnApi, ColumnPinnedEvent, GridApi, GridOptions, ICellRendererParams, IServerSideGetRowsParams, SetFilterValuesFuncParams, ValueFormatterParams } from 'ag-grid-community';
import TableHeader from '@/components/ag-grid-table/header-types/table-header.vue';
import NoRowsOverlay from '@/components/ag-grid-table/overlay-types/no-rows-overlay.vue';
import { AgGridCommon } from 'ag-grid-community/dist/lib/interfaces/iCommon';
import { Data } from '~/utils';
import { MtpPeriod, SelectOption } from '~/utils/interfaces';
import LinkBudgetRequestsLightbox from '@/components/project-portfolio/project-portfolio-table/link-budget-requests-lightbox/link-budget-requests-lightbox.vue';
import { ProjectPortfolioService } from '~/services/project-portfolio';
import { CombinedBudgets } from '~/models/services/project-portfolio';
import { addYears, endOfYear, format } from 'date-fns';
import LinkCell from '~/components/project-portfolio/project-portfolio-table/cells/link-cell.vue';
import StatusCell from '~/components/project-portfolio/project-portfolio-table/cells/status-cell.vue';
import { MtpService } from '~/services/mtp-service';

type FetchParams = {
    mtpYear: string;
    plantSids: number[];
    statuses: number[];
    searchTerm: string;
    pageNumber: number;
    pageSize: number;
}

const StatusColorMap = {
    'Light_grey': '#d6d6d6',
    'Dark_grey': '#607d8b',
    'Magenta_Red': '#ffab00',
    'Amber_Yellow': '#19c142',
    'Green': '#0078dc',
} as const;

type ProjectPortfolioSetFilters = {
    statuses: number[];
};

const PROJECT_PORTFOLIO_TABLE_COLUMN_STATE = 'project_portfolio_table_column_state' as const;

@Component({
    components: {
        AgGridVue,
        TableHeader,
        NoRowsOverlay,
        LinkCell,
        StatusCell,
        LinkBudgetRequestsLightbox,
    }
})
export default class ProjectPortfolioTableComponent extends Vue {
    private projectPortfolioService: ProjectPortfolioService = new ProjectPortfolioService();
    private MtpService: MtpService = new MtpService();

    private data: Data = Data.Instance;
    private gridApi: GridApi | null = null;
    private columnApi: ColumnApi | null = null;
    private isFilterApplied: boolean = false;

    private tableExtraFilters = {
        pageSize: 20,
        searchTerm: '',
    }
    
    private readonly PROJECT_PORTFOLIO_PATH = '/project-portfolio';
    private currentYear = new Date(Date.now()).getFullYear().toString();
    private selectedMtpFilter = Data.Instance.currentMtpPeriod?.year?.toString() ?? this.currentYear;
    private isLightboxOpen = false;
    private loading = false;

    private localCombinedBudgets: CombinedBudgets = null;

    private mtpPeriod: MtpPeriod = <MtpPeriod>{
        year: 0,
        mtpStartDate: null,
        mtpEndDate: null
    };

    @Watch('data.selectedPlant', { immediate: true, deep: true })
    async onSelectedPlantsChanged() {
        if (this.$route.path === this.PROJECT_PORTFOLIO_PATH) {
            this.refreshTable();
            this.handleLightboxClose();
        }
    }

    private async created(): Promise<void> {
        await this.checkMtpPeriod();
    }

    private get columnDefs(): ColDef[] {
        return [
            {
                headerName: 'Global Id',
                field: 'combinedId',
                rowGroup: true,
                hide: true,
                suppressColumnsToolPanel: true,
                valueFormatter: (params): string => params.data.combinedId,
                cellRenderer: 'LinkCell',
                cellRendererParams: (params: ICellRendererParams) => ({ href: `/project-portfolio/${params.data.id}/view` }),
            },
            { headerName: 'Name', field: 'name', pinned: 'left' },
            { headerName: 'Name (Local Language)', field: 'localLanguageName' },
            {
                headerName: 'Status',
                field: 'status',
                valueGetter: (params): string => this.data.constants.mtpStatus[params.data.status]?.name,
                cellRenderer: 'StatusCell',
                cellRendererParams: (params: ICellRendererParams) => ({ color: StatusColorMap[this.data.constants.mtpStatus[params.data.status]?.color] }),
                menuTabs: ['filterMenuTab'],
                filter: 'agSetColumnFilter',
                filterParams: {
                    buttons: ['reset', 'apply'],
                    closeOnApply: true,
                    values: (params: SetFilterValuesFuncParams): void => {
                        params.success(Object.keys(this.data.constants.mtpStatus));
                    },
                    valueFormatter: (params: ValueFormatterParams): string => {
                        return this.data.constants.mtpStatus[params.value].name;
                    }
                },
            },
            { headerName: 'WBS Element', field: 'wbsElement' },
            { headerName: 'Country', field: 'countryName' },
            { headerName: 'Technology', field: 'technologyName' },
            { headerName: 'Plant / River Group', field: 'plantGroupName' },
            { headerName: 'Plant', field: 'plantName' },
            {
                headerName: 'Project Type',
                field: 'projectType',
                valueFormatter: (params): string => this.data.constants.projectTypes[params.value],
            },
            {
                headerName: 'Lump Sum',
                field: 'lumpSum',
                valueFormatter: (params): string => params.value ? 'Yes' : 'No',
            },
            {
                headerName: 'AERO Classification',
                field: 'aeroClassification',
                valueFormatter: (params): string => this.data.constants.aeroClassification[params.value],
            },
            { headerName: 'Justification On Classification', field: 'justificationOnClassification', resizable: true },
            {
                headerName: 'Maintenance Type',
                field: 'maintenanceType',
                valueFormatter: (params): string => this.data.constants.maintenanceType[params.value],
            },
            {
                headerName: 'Outage Type',
                field: 'outageType',
                valueFormatter: (params): string => this.data.constants.outageType[params.value],
            },
            { headerName: 'Outage IDs', field: 'outageIds' },
            { headerName: 'Risk / Maintenance IDs', field: 'externalIds' },
            {
                headerName: 'Procurement Category',
                field: 'procurementCategory',
                valueFormatter: (params): string => this.data.constants.procurementCategory[params.value],
            },
            { headerName: 'Comment', field: 'comment' },
            {
                headerName: 'Planning and Controlling Classification',
                field: 'planningAndControllingClassification',
                valueFormatter: (params): string => this.data.constants.allPlanningAndControllingClassification[params.value],
            },
            {
                headerName: 'Useful Economic Life',
                field: 'usefulEconomicLife',
                valueFormatter: (params): string => `${params.value} year(s)`,
            },
            {
                headerName: 'Uniper Share of Budget',
                field: 'uniperShareOfBudget',
                valueFormatter: (params): string => `${params.value}%`,
            },
            {
                headerName: 'Start Date',
                field: 'startDate',
                valueFormatter: (params): string => format(new Date(params.value), 'MMMM do yyyy'),
            },
            {
                headerName: 'End Date',
                field: 'endDate',
                valueFormatter: (params): string => format(new Date(params.value), 'MMMM do yyyy'),
            },
            { headerName: 'Currency', field: 'currencyCode' },
            { headerName: `Spend until end of ${this.selectedMtpFilter}`, field: 'spendUntilEndOfYear' },
            { headerName: `Forecast MTP ${this.selectedMtpFilter}`, field: 'forecast' },
            { headerName: `New MTP ${parseInt(this.selectedMtpFilter) + 1}`, field: 'new1' },
            { headerName: `New MTP ${parseInt(this.selectedMtpFilter) + 2}`, field: 'new2' },
            { headerName: `New MTP ${parseInt(this.selectedMtpFilter) + 3}`, field: 'new3' },
            { headerName: `After ${parseInt(this.selectedMtpFilter) + 3}`, field: 'after' },
            { headerName: 'Total', field: 'total' },
            {
                headerName: 'Funding Status',
                field: 'fundingStatus',
                valueFormatter: (params): string => this.data.constants.fundingStatus[params.value],
            },
            {
                headerName: 'Project Status',
                field: 'projectStatus',
                valueFormatter: (params): string => this.data.constants.projectStatusType[params.value],
            },
            {
                headerName: 'Primary Department',
                field: 'primaryDepartment',
                valueFormatter: (params): string => this.data.constants.primaryDepartment[params.value],
            },
            {
                headerName: 'Main Flag',
                field: 'mainFlag',
                valueFormatter: (params): string => this.data.constants.flags[params.value] ?? '',
            },
            {
                headerName: 'Other Flags',
                field: 'flags',
                valueFormatter: (params): string => {
                    const mappedFlags = params.value.map(flag => this.data.constants.flags[flag]);
                    return mappedFlags.join(', ');
                },
                resizable: true,
            },
        ];
    }

    private gridOptions: GridOptions = {
        rowModelType: 'serverSide',
        serverSideDatasource: {
            getRows: (params: IServerSideGetRowsParams) => {
                if (params.request.groupKeys.length === 0) {
                    this.fetchData(params);
                } else {
                    this.extractGroup(params);
                }
            }
        },
        defaultColDef: {
            filter: true,
            sortable: false,
            unSortIcon: true,
            menuTabs: [],
            minWidth: 200,
            cellClassRules: {
                'is-subgroup-cell': this.isCellExpanded,
            },
        },
        autoGroupColumnDef: {
            headerName: 'Global Id',
            pinned: 'left',
            field: 'combinedId'
        },
        domLayout: 'autoHeight',
        suppressMenuHide: true,
        pagination: true,
        paginationPageSize: this.tableExtraFilters.pageSize,
        cacheBlockSize: this.tableExtraFilters.pageSize,
        noRowsOverlayComponent: 'NoRowsOverlay',
        serverSideFilterOnServer: true,
        popupParent: document.querySelector('body'),
        sideBar: {
            defaultToolPanel: '',
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                    toolPanelParams: {
                        suppressRowGroups: true,
                        suppressValues: true,
                        suppressPivots: true,
                        suppressPivotMode: true,
                        suppressColumnFilter: true,
                        suppressColumnSelectAll: true,
                        suppressColumnExpandAll: true,
                    },
                },
            ],
        },
        onColumnMoved: this.setColumnState,
        onColumnVisible: this.setColumnState,
        onColumnPinned: this.setColumnState,
        onFilterChanged: () => {
            this.calcIsFilterApplied();
            this.refreshTable();
        },
        onToolPanelVisibleChanged: this.sizeColumnsToFit,
        onToolPanelSizeChanged: this.sizeColumnsToFit,
        onGridSizeChanged: this.sizeColumnsToFit,
    };

    private onGridReady(params: AgGridCommon<any, any>): void {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;

        this.applyColumnState();
    }

    private applyColumnState(): void {
        let columnState = JSON.parse(localStorage.getItem(PROJECT_PORTFOLIO_TABLE_COLUMN_STATE));
        if (columnState) {
            this.columnApi.applyColumnState({ state: columnState, applyOrder: true });
        }
    }

    private setColumnState(): void {
        let columnState = JSON.stringify(this.columnApi.getColumnState());
        localStorage.setItem(PROJECT_PORTFOLIO_TABLE_COLUMN_STATE, columnState);
    }

    private calcIsFilterApplied(): void {
        const filterState = this.gridApi?.getFilterModel();
        const filterStateLength = Object.keys(filterState).length;
        this.isFilterApplied = filterStateLength > 0;
    }

    private resetFilterState(): void {
        this.gridApi?.setFilterModel(null);
    }

    private refreshTable(): void {
        this.gridApi?.refreshServerSide({ purge: true });
    }

    private sizeColumnsToFit(): void {
        this.gridApi?.sizeColumnsToFit();
    }

    private onTriggerSearch(searchTerm: string): void {
        this.tableExtraFilters.searchTerm = searchTerm;
        this.refreshTable();
    }
    
    private onClearSearchTerm(): void {
        this.onTriggerSearch('');
    }

    private onPageSizeChanged(pageSize: number): void {
        this.tableExtraFilters.pageSize = pageSize;
        this.gridApi?.paginationSetPageSize(this.tableExtraFilters.pageSize);
        this.gridApi?.setCacheBlockSize(this.tableExtraFilters.pageSize);
    }
    
    private isCellExpanded(params: CellClassParams<any>): boolean {
        return params.node.level !== 0;
    }

    private get mtpOptions(): SelectOption[] {
        return this.data.sortedLivePmlFilters.map(e => ({
            value: e.year.toString(),
            label: e.description,
        }))
    }

    private get isLinkDisabled(): boolean {
        const currentDate = new Date(Date.now());
        const startDate = new Date(this.mtpPeriod.mtpStartDate);
        const endDate = addYears(new Date(parseInt(this.selectedMtpFilter), 11, 31), 1);

        if (this.selectedMtpFilter === this.currentYear) {
            return !(startDate <= currentDate && currentDate <= endDate);
        }

        return !(currentDate <= endDate);
    }

    private get plantSids(): number[] {
        return this.data.plants?.map(plant => plant.plantSid) ?? [];
    }

    private async fetchData(params: IServerSideGetRowsParams): Promise<void> {
        params.api.hideOverlay();

        const { statuses } = this.getSelectedFiltersForServerRequestParams(params);
        const pageNumber = this.pageNumber(params.request.endRow);
        const { pageSize, searchTerm } = this.tableExtraFilters;
        const mtpYear = this.selectedMtpFilter;
        const plantSids = this.plantSids;

        try {
            this.localCombinedBudgets = await this.projectPortfolioService.getCombinedBudgets(mtpYear, plantSids, statuses, searchTerm, pageNumber, pageSize);
            params.success({ rowData: this.localCombinedBudgets.budgets, rowCount: this.localCombinedBudgets.count });
            if (this.localCombinedBudgets.count === 0) {
                params.api.showNoRowsOverlay()
            }
        } catch (e) {
            params.fail()
        }
    }

    private extractGroup(params: IServerSideGetRowsParams): void {
        const projectPortfolioDetails = this.localCombinedBudgets.budgets.find(e => e.combinedId === params.request.groupKeys[0]);
        const combinedBudgets = projectPortfolioDetails?.combinedBudgets ?? [];
        params.success({ rowData: combinedBudgets, rowCount: combinedBudgets.length });
    }

    private pageNumber(endRow: number | undefined): number {
        return Math.floor((endRow ?? this.tableExtraFilters.pageSize) / this.tableExtraFilters.pageSize)
    }

    private getSelectedFiltersForServerRequestParams(params: IServerSideGetRowsParams): ProjectPortfolioSetFilters {
        return {
            statuses: params.request.filterModel?.status?.values?.map((e: string) => Number(e)) ?? [],
        };
    }

    private mtpChanged(): void {
        this.refreshTable();
    }

    private handleLightboxOpen(): void {
        this.isLightboxOpen = true;
    }

    private handleLightboxClose(): void {
        this.isLightboxOpen = false;
    }

    private async checkMtpPeriod(): Promise<void> {
        if (localStorage.mtpPeriod) {
            this.mtpPeriod = JSON.parse(localStorage.mtpPeriod);
        } else {
            try {
                this.mtpPeriod = await this.MtpService.GetMtpPeriod(this.currentYear);
                localStorage.setItem('mtpPeriod', JSON.stringify(this.mtpPeriod));
            } catch (error) {
                if (error.response !== undefined && error.response.status === 400) {
                    localStorage.removeItem('mtpPeriod');
                }
            }
        }
    }
}
