import Vue from 'vue';
import Component from 'vue-class-component';
import './change-review-modal.scss';
import { EventBus } from '~/utils/eventbus';
import { Prop, Watch } from 'vue-property-decorator';
import { ReviewBudgetRequest } from '~/utils/interfaces';
import { BudgetRequestsService } from '~/services/budget-request-service';

@Component({})
export default class ChangeReviewModalComponent extends Vue {
    public BudgetRequestsService: BudgetRequestsService = new BudgetRequestsService();
    private selectedDeleteBr: boolean = false;
    @Prop()
    private mainFlagFromChangeMtp: number;
    @Prop()
    private otherFlagsFromChangeMtp: Array<any>;
    @Prop()
    private commentFromChangeMtp: any;
    @Prop()
    private brIdChangeMtp: string;
    @Prop()
    private yearFromChangeMtp: number;
    @Prop()
    private globalIdFromChangeMtp: string;
    @Prop()
    private data: any;
    @Prop()
    private keyIdFromChangeMtp: string;
    private mainFlag: Array<any> = [];
    private otherFlags: Array<any> = [];
    private othersFlagsIds: Array<any> = [];
    private mainFlagId = null;
    private validateReviewComment: boolean = true;
    private reviewBudgetRequest: ReviewBudgetRequest = <ReviewBudgetRequest>{
        budgetRequestId: '',
        mtpYear: 0,
        mainFlag: 0,
        otherFlags: [],
        comment: {
            date: new Date(Date.now()),
            text: ''
        }
    };

    private selectedOtherFlags = [];
    private selectedMainFlag = [];
    private validateMainFlag: boolean = true;
    private flagsArray: any[] = [];
    private allFlags: any[] = [];
    private otherFlagsArray: any[] = [];
    private succesChangeReview: any = {
        countUpdates: 0,
        items: [{
            error: ''
        }],
        requestIsValid: false,
        text: ''
    };

    constructor() {
        super();

    }

    @Watch('commentFromChangeMtp')
    onCommentChanged(val: any) {
        if (val != null && this.data) {
            this.reviewBudgetRequest.comment.text = val.text;
        }
    }

    @Watch('mainFlagFromChangeMtp')
    onMainFlagChanged(val: number) {
        if (val != null && this.data) {
            this.mainFlag = [];
            if (val !== 0) {
                this.mainFlag.push(this.data.constants.flags[val]);
            }
            this.mainFlagId = val;
        }
    }

    @Watch('otherFlagsFromChangeMtp')
    onOthersFlagsChanged(val: Array<any>) {
        if (val != null && this.data) {
            this.otherFlags = [];
            val.forEach((e) => {
                this.otherFlags.push(this.data.constants.flags[e]);
            });
            this.othersFlagsIds = val;
        }
    }

    created() {
        this.clear();
    }

    public clear() {
        this.validateReviewComment = true;
        this.mainFlag = [];

        this.flagsArray = Object.entries(this.data.constants.flags).map(([key, title]) => ({ key, title }));
        this.allFlags = this.flagsArray.slice(0);
        this.otherFlagsArray = this.flagsArray.slice(0);
        this.selectedOtherFlags = [];
        this.selectedMainFlag = [];
        this.$nextTick(() => {

            if (this.mainFlagFromChangeMtp === null || this.mainFlagFromChangeMtp === 0) {
                this.selectedMainFlag = [];
                this.reviewBudgetRequest.mainFlag = 0;
            } else {
                const singleObj = {};
                singleObj['key'] = this.mainFlagFromChangeMtp.toString();
                singleObj['title'] = this.data.constants.flags[this.mainFlagFromChangeMtp];
                this.selectedMainFlag.push(singleObj);
                this.disableFromOtherFlags();
            }
            if (this.selectedMainFlag === null || this.selectedMainFlag === undefined) {
                this.selectedMainFlag = null;
            }
            if (this.otherFlagsFromChangeMtp && this.data) {
                const listOfObjects = [];
                this.otherFlagsFromChangeMtp.forEach((entry) => {
                    const oFlags = {};
                    oFlags['key'] = entry.toString();
                    oFlags['title'] = this.data.constants.flags[parseInt(entry)];
                    listOfObjects.push(oFlags);
                });
                this.selectedOtherFlags = listOfObjects;
                this.disableFromFlags();

            }

            this.reviewBudgetRequest.comment.text = '';

        });


    }

    private async update() {
        this.reviewBudgetRequest.budgetRequestId = this.brIdChangeMtp;
        this.reviewBudgetRequest.mtpYear = this.yearFromChangeMtp;
        this.reviewBudgetRequest.mtpStatus = parseInt(this.keyIdFromChangeMtp);
        await this.BudgetRequestsService.reviewBudgetRequest(this.reviewBudgetRequest).then((e) => {
            this.succesChangeReview = e;
        });

        if (this.succesChangeReview['requestIsValid']) {
            if (this.keyIdFromChangeMtp === '4') {
                this.selectedDeleteBr = true;
                EventBus.$emit(EventBus.UPDATE_MTP_STATUS.UPDATE_MTP_REVIEWED_MODAL, this.selectedDeleteBr);
            } else if (this.keyIdFromChangeMtp === '3') {
                this.selectedDeleteBr = true;
                EventBus.$emit(EventBus.UPDATE_MTP_STATUS.UPDATE_MTP_FLAGGED_MODAL, this.selectedDeleteBr);
            }
            $('#changeReviewModal').modal('hide');
        } else {
            console.log(this.succesChangeReview);
            this.$bvToast.show('toast-error');
        }


    }

    private checkempty() {
        if (this.reviewBudgetRequest.comment.text.length !== 0 && this.reviewBudgetRequest.comment.text.trim().length !== 0 && this.selectedOtherFlags.length === 0 && this.selectedMainFlag.length === 0) {
            this.validateReviewComment = false;
        } else {
            this.validateReviewComment = true;
        }
    }

    private checkemptyToFlagged() {
        if (this.reviewBudgetRequest.comment.text && this.reviewBudgetRequest.comment.text.trim().length !== 0 && (this.reviewBudgetRequest.otherFlags.length !== 0 || this.reviewBudgetRequest.mainFlag !== 0)) {
            this.validateReviewComment = false;
        } else {
            this.validateReviewComment = true;
        }
    }

    private goToDetails(year, globalId) {
        year = this.yearFromChangeMtp;
        globalId = this.globalIdFromChangeMtp;
        let link = this.$router.resolve({ path: 'details-page', query: { year: year, globalId: globalId } });
        window.open(link.href, '_blank');
    }

    private disableFromOtherFlags() {
        if (this.selectedMainFlag.length !== 0) {
            this.otherFlagsArray = this.allFlags.filter(key => key.key !== this.selectedMainFlag[0].key);
            this.reviewBudgetRequest.mainFlag = parseInt(this.selectedMainFlag[0].key);

            this.validateMainFlag = false;
        } else {
            this.otherFlagsArray = this.allFlags.slice(0);
            this.reviewBudgetRequest.mainFlag = 0;

            this.validateMainFlag = true;
            this.flagsArray = Object.entries(this.data.constants.flags).map(([key, title]) => ({ key, title }));
            this.selectedOtherFlags = [];
            this.reviewBudgetRequest.otherFlags = [];
        }
    }

    private disableFromFlags() {
        const flags = [];
        this.allFlags.forEach((el) => {
            const flag = this.selectedOtherFlags.find((f) => f.key === el.key);
            if (!flag) {
                flags.push(el);
            }
        });
        this.flagsArray = flags;
        const keyFlag = [];
        this.selectedOtherFlags.forEach((e) => {
            keyFlag.push(parseInt(e.key));
        });
        this.reviewBudgetRequest.otherFlags = keyFlag;
    }

    private hideToast() {
        this.$bvToast.hide('toast-error');
    }
}
