import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import '~/components/home/home.scss';
import 'vue-awesome/icons/chevron-down';
import 'vue-awesome/icons/times';
import 'vue-awesome/icons/save';
import 'vue-awesome/icons/check';
import 'vue-awesome/icons/calendar-alt';
import 'vue-awesome/icons/info';
import { ConstantsListService } from '~/services/constants-list-service';
import { PlantsService } from '~/services/plants-service';
import { BudgetRequestsService } from '~/services/budget-request-service';
import { MtpPeriod, NewBudgetRequests, Plant, ValidateBudgetRequests, ValidateMappings } from '~/utils/interfaces';
import { Data, EventBus, Helper, User } from '~/utils';
import { format } from 'date-fns';
import MaintenanceBudgetRequestComponent from './maintenance-budget-request/maintenance-budget-request.vue';
import RiskBudgetRequestComponent from './risk-budget-request/risk-budget-request.vue';
import OpportunityBudgetRequestComponent from './opportunity-budget-request/opportunity-budget-request.vue';
import { CrossAppLinkingService } from '~/services/cross-app-linking-service';
import moment from 'moment-timezone';
import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { MtpService } from '~/services/mtp-service';
import { PlantAttributesItem } from '~/models/services/plants';
import { nullIfNaN } from "~/utils/math-helper"
import Tags from '~/components/focus-topics/tags/tags.vue';
import { Tag, TagsMode } from '~/models/services/tags';
import { MasterService } from '~/services/master-service';

@Component({
    components: {
        'maintenance-budget-request': MaintenanceBudgetRequestComponent,
        'risk-budget-request': RiskBudgetRequestComponent,
        'opportunity-budget-request': OpportunityBudgetRequestComponent,
        Slick,
        Tags,
    }
})
export default class BudgetRequestComponent extends Vue {
    private brTagsMode: TagsMode = TagsMode.BR;
    private emitTagsMode: TagsMode = TagsMode.EMIT;
    private tagsList: Tag[] = [];
    private lastActiveMtpPeriodYear: number = 0;

    public ConstantsListService: ConstantsListService = new ConstantsListService();
    public BudgetRequestsService: BudgetRequestsService = new BudgetRequestsService();
    public PlantsService = MasterService.instance.plantsService;
    public CrossAppLinkingService: CrossAppLinkingService = new CrossAppLinkingService();
    private loading: boolean = false;
    private loadingSlick: boolean = false;
    private MtpService: MtpService = new MtpService();
    private validateBudgetRequests: ValidateBudgetRequests = <ValidateBudgetRequests>{
        aero: null,
        currency: null,
        justification: null,
        lumpSum: null,
        maintenanceType: null,
        name: null,
        outageId: null,
        outageType: null,
        planning: null,
        primaryDepartment: null,
        procurementCategory: null,
        projectType: null,
        startDate: null,
        uniperShareOfBudget: null,
        unit: null,
        wbs: null,
        riskId: null
    };
    private preventLeavePage: any = <any>{
        aero: null,
        comment: null,
        currency: null,
        justification: null,
        lumpSum: null,
        maintenanceType: null,
        name: null,
        outageId: null,
        outageType: null,
        planning: null,
        primaryDepartment: null,
        procurementCategory: null,
        projectType: null,
        startDate: null,
        uniperShareOfBudget: null,
        unit: null,
        wbs: null,
        riskId: null,
        endDate: null,
        localLanguageName: null,
        checkPreventLeavePage: null
    };
    private numberOfError = 0;
    private newBudgetRequest: NewBudgetRequests = <NewBudgetRequests>{};
    private selectedPlant = null;
    private plant: PlantAttributesItem = {
        plantSId: '',
        name: '',
        technology: '',
        technologySid: '',
        plantGroupName: '',
        country: {
            currency: {
                code: '',
                id: ''
            },
            name: '',
            code: ''
        },
        units: [{
            name: '',
            unitSid: '',
        }],
    };
    private editPage: boolean = false;
    private duplicatePage: boolean = false;
    private editBudgetRequest: NewBudgetRequests = null;
    private selectedSaveChanges: boolean = false;
    private selectedMtp: number = null;
    private selectedPrimaryDepartment: number = null;
    private selectedCancel = null;
    private changedSelection: boolean = false;
    private selectedMaintenanceType: number = null;
    private selectedProcurementCategory: number = null;
    private selectedPlanningAndControllingClassification: number = null;
    private capexPlanningAndControllingClassificationValues: number[] = [1, 2, 3, 4, 7, 8, 9, 10, 11];
    private notCapexPlanningAndControllingClassificationValues: number[] = [5, 6];
    private selectedUnitId = null;
    private data: Data = Data.Instance;
    private formatDateReview = null;
    private validateGreatherStartDate: boolean = false;
    private currentYear = new Date(Date.now()).getFullYear();
    private hasPlanningItems = false;
    private externalIds = {
        externalId: '',
        existingExternalIds: new Array<string>(),
        userSelectionMaintenancePlanningIds: new Array<number>()
    };
    private externalIdsEdit = {
        budgetRequestId: '',
        externalIds: new Array<string>(),
        userSelectionMaintenancePlanningIds: new Array<number>(),
        mtpYear: 0
    };
    private externalList = [];
    private externalInputs = [{
        id: '',
        isInvalid: false,
        errorMessage: '',
        type: ''
    }];
    private currentExternalApplicationType = '';
    private validateMappings: ValidateMappings = <ValidateMappings>{
        namesAreEqual: true,
        nameLocalLanguagesAreEqual: true,
        projectTypesAreEqual: true,
        maintenanceTypesAreEqual: true,
        aeroClassificationsAreEqual: true,
        justificationOnClassificationsAreEqual: true,
        lumpSumsAreEqual: true,
        planningAndControllingClassificationsAreEqual: true,
        outageIdsAreEqual: true,
        outageTypesAreEqual: true,
        forecastsAreEqual: true,
        spentUntilsAreEqual: true,
        nextOneYearsAreEqual: true,
        nextTwoYearsAreEqual: true,
        nextThreeYearsAreEqual: true,
        aftersAreEqual: true,
        totalsAreEqual: true,
        startDatesAreEqual: true,
        endDatesAreEqual: true
    };
    private mappingWarning: string = '';
    private showWarning = false;
    private slickOptions = {
        slidesToShow: 1,
        infinite: false,
        accessibility: true,
        adaptiveHeight: false,
        arrows: true,
        dots: false,
        draggable: false,
        edgeFriction: 0.30,
        swipe: false
    };
    private userPermissions = <any>[];
    private isAccessDeniedModalActive: boolean = false;
    private isMtpPeriod: boolean = false;
    private mtpPeriod: MtpPeriod = <MtpPeriod>{
        year: 0,
        mtpStartDate: null,
        mtpEndDate: null
    };
    private existPlantId: boolean = false;
    private currentDate = new Date(Date.now());
    private noValidationWarning = false;

    constructor() {
        super();
        this.selectedMtp = parseInt(localStorage.getItem('selectedMtp'));
    }

    created() {
        this.initBudgetRequest();
        this.selectedPrimaryDepartment = null;
    }

    beforeRouteLeave(to, from, next) {
        this.$bvToast.hide('toast-duplicate');
        sessionStorage.removeItem('redirectUrl');
        localStorage.removeItem('selectedPlantId');
        if (this.isAccessDeniedModalActive) {
            $('#accessDeniedModal').modal('hide');
            next();
        } else {
            if (!this.selectedSaveChanges && this.preventLeavePage.checkPreventLeavePage) {
                $('#confirmationModal').modal('show');
                next(false);
                EventBus.$on(EventBus.BUDGET_REQUEST.DISCARD_CHANGES_MODAL, () => {
                    $('#confirmationModal').modal('hide');
                    next();
                });
                EventBus.$on(EventBus.BUDGET_REQUEST.SAVE_CHANGES_MODAL, async () => {
                    $('#confirmationModal').modal('hide');
                    await this.saveChanges();
                    next();
                });
            } else {
                next();
            }
        }
    }

    async mounted() {
        if (this.data.constants) {
            await this.loadPlants();
        }

        this.userPermissions = await User.getPerm();
        if (this.userPermissions.includes('BudgetRequestDetailsEdit')) {
            await this.checkMtpPeriod();
        } else {
            this.isMtpPeriod = false;
        }

        if ((!this.userPermissions.includes('BudgetRequestDetailsEdit') || !this.isMtpPeriod) && !this.userPermissions.includes('BudgetRequestDetailsEditAnytime')) {
            $('#accessDeniedModal').modal('show');
            this.isAccessDeniedModalActive = true;
        }

        const mtpPeriodsList = await this.MtpService.getMtpPeriods();
        if (mtpPeriodsList.length > 0) {
            const currentDate = new Date();
            this.lastActiveMtpPeriodYear = mtpPeriodsList
                .sort((a, b) => new Date(b.mtpEndDate).getTime() - new Date(a.mtpEndDate).getTime())
                .find((obj: MtpPeriod) => new Date(obj.mtpEndDate) < currentDate)?.year;
        }
    }

    @Watch('data.constants')
    async onSelectedPlantChanged(val: string) {
        if (val != null) {
            this.changedSelection = true;
            await this.loadPlants();
        }
    }

    @Watch('newBudgetRequest.projectType')
    async onSelectedProjectType(val: string) {
        if (val != null) {
            this.newBudgetRequest.projectType = parseInt(val);
            this.calculateTotal();
        }
    }

    @Watch('externalList')
    async onChangeExternalList() {
        if ((this.$refs.slick as any) && this.externalList && this.externalList.length > 0 && this.externalList[0].data != null) {
            (this.$refs.slick as any).destroy();
            this.$nextTick(() => {
                (this.$refs.slick as any).create();
            });
        }
    }

    private notBeforeStartDate(date) {
        return date <= this.newBudgetRequest.planning.startDate;
    }

    private notAfterEndDate(date) {
        if (this.newBudgetRequest.planning.endDate) {
            return date >= this.newBudgetRequest.planning.endDate;
        } else {
            return false;
        }
    }

    private async checkMtpPeriod() {
        if (localStorage.mtpPeriod) {
            this.mtpPeriod = JSON.parse(localStorage.mtpPeriod);
        } else {
            try {
                this.mtpPeriod = await this.MtpService.GetMtpPeriod(this.currentYear);
                localStorage.setItem('mtpPeriod', JSON.stringify(this.mtpPeriod));
            } catch (error) {
                if (error.response !== undefined && error.response.status === 400) {
                    localStorage.removeItem('mtpPeriod');
                }
            }
        }

        if (this.mtpPeriod && this.mtpPeriod.mtpEndDate && this.mtpPeriod.mtpStartDate) {
            let currentDate = format(new Date(Date.now()), 'yyyy-MM-dd\'T00:00:00\'');
            this.mtpPeriod.mtpEndDate = format(new Date(this.mtpPeriod.mtpEndDate), 'yyyy-MM-dd\'T00:00:00\'');

            if (this.mtpPeriod.mtpStartDate <= currentDate && this.mtpPeriod.mtpEndDate >= currentDate) {
                this.isMtpPeriod = true;
            }
        }
    }

    private async loadPlants() {
        this.loading = true;
        if (localStorage.selectedPlantId && !this.$route.query.isDuplicate) {
            await this.getPlant(localStorage.selectedPlantId);
        }
        if (this.$route.query.isDuplicate != null) {
            await this.initDuplicateBudgetRequest();
        } else if (this.$route.query.globalId != null) {
            await this.initEditBudgetRequest();
        } else {
            this.initCreateBudgetRequest();
        }
        this.loading = false;
    }

    private initBudgetRequest() {
        this.newBudgetRequest =
            <NewBudgetRequests>{
                id: '',
                globalId: '',
                plantId: '',
                unitId: '',
                createdBy: '',
                name: '',
                localLanguageName: '',
                unitName: '',
                plantName: '',
                groupName: '',
                technologyName: '',
                countryCode: '',
                countryName: '',
                projectType: 1,
                created: new Date(Date.now()),
                isActive: true,
                isNew: true,
                plantSid: 0,
                unitSid: 0,
                technologySid: 0,
                planning: {
                    year: 0,
                    selectedCurrencyId: null,
                    wbsElement: '',
                    lumpSum: false,
                    aeroClassification: 0,
                    justificationOnClassification: '',
                    maintenanceType: null,
                    outageType: 0,
                    outageId: '',
                    riskOpportunityId: '',
                    procurementCategory: 1,
                    planningAndControllingClassification: 1,
                    uniperShareOfBudget: 100,
                    startDate: null,
                    endDate: null,
                    primaryDepartment: 0,
                    prioritizationCategory: 1,
                    prioritizationRank: 0,
                    modified: new Date(Date.now()),
                    modifiedBy: '',
                    mtpStatus: 1,
                    mainFlag: undefined,
                    otherFlags: [],
                    fundingStatus: 0,
                    spendUntilEndOfYear: 0,
                    forecast: 0,
                    new1: 0,
                    new2: 0,
                    new3: 0,
                    after: 0,
                    total: 0,
                    projectType: 1,
                    comment: '',
                    maintenancePlanningIds: '',
                    attachedExternalId: '',
                    hasDifferences: null,
                    hasPlanningCosts: false,
                    mtpTags: [],
                }
            };
    }

    private async initEditBudgetRequest() {
        this.loading = true;
        this.initBudgetRequest();

        const mtpYear = nullIfNaN(parseInt(this.$route.query.year as string));
        const miniFdYear = nullIfNaN(parseInt(this.$route.query.miniFdYear as string));

        if (!this.changedSelection)
            this.data.switchingEditMode = true;
        this.editPage = true;


        this.newBudgetRequest = await this.BudgetRequestsService.getBudgetRequestEdit(mtpYear, (this.$route.query.globalId as any), miniFdYear);
        if (mtpYear !== this.currentYear)
        {
            this.newBudgetRequest.planning.spendUntilEndOfYear = this.newBudgetRequest.planning.forecast;
            this.newBudgetRequest.planning.forecast = this.newBudgetRequest.planning.new1;
            this.newBudgetRequest.planning.new1 =  this.newBudgetRequest.planning.new2;
            this.newBudgetRequest.planning.new2 =  this.newBudgetRequest.planning.new3;
            this.newBudgetRequest.planning.new3 =  this.newBudgetRequest.planning.after;
            this.newBudgetRequest.planning.after = 0;
        }

        await this.getPlant(this.newBudgetRequest.plantSid);
        if (this.newBudgetRequest.planning.mtpStatus !== 1) {
            this.newBudgetRequest.planning.mtpStatus = 1;
        }

        // Get external data to populate external inputs and panels
        if (this.newBudgetRequest.planning.riskOpportunityId && this.newBudgetRequest.planning.riskOpportunityId.trim().length > 0) {
            this.getExternalDataForEdit();
        }

        this.selectedMaintenanceType = this.newBudgetRequest.planning.maintenanceType;
        this.selectedUnitId = this.plant.units.find(e => {
            return Number(e.unitSid) === this.newBudgetRequest.unitSid;
        });
        this.selectedPrimaryDepartment = this.newBudgetRequest.planning.primaryDepartment;
        this.selectedProcurementCategory = this.newBudgetRequest.planning.procurementCategory;
        this.selectedPlanningAndControllingClassification = this.newBudgetRequest.planning.planningAndControllingClassification;
        if (this.newBudgetRequest.planning.startDate) {
            this.newBudgetRequest.planning.startDate = new Date(this.newBudgetRequest.planning.startDate);
        }
        if (this.newBudgetRequest.planning.endDate) {
            this.newBudgetRequest.planning.endDate = new Date(this.newBudgetRequest.planning.endDate);
        }
        if (!this.newBudgetRequest.planning.outageId) {
            this.newBudgetRequest.planning.outageId = '';
        }
        if (this.newBudgetRequest.planning.reviewComment) {
            this.formatDateReview = format(new Date(this.newBudgetRequest.planning.reviewComment.date), 'dd-MM-yyyy');
        }
        if (!this.changedSelection) {
            this.data.switchingEditMode = false;
        }

        if (!this.selectedPrimaryDepartment) {
            this.fillPrimaryDepartment(this.newBudgetRequest.planning.aeroClassification.toString());
        }

        this.tagsList = this.newBudgetRequest.planning.mtpTags;

        this.loading = false;
        
    }

    private async initDuplicateBudgetRequest() {
        this.initBudgetRequest();
        this.loading = true;
        this.duplicatePage = true;
        const mtpYear = nullIfNaN(parseInt(this.$route.query.year as string));
        const miniFdYear = nullIfNaN(parseInt(this.$route.query.miniFdYear as string));

        this.newBudgetRequest = await this.BudgetRequestsService.getBudgetRequestEdit(mtpYear, (this.$route.query.globalId as any), miniFdYear);
        if (localStorage.selectedPlantId) {
            await this.getPlant(localStorage.selectedPlantId);
        } else {
            await this.getPlant(this.newBudgetRequest.plantSid);
        }

        this.newBudgetRequest.plantSid = parseInt(this.plant.plantSId);
        this.newBudgetRequest.plantName = this.plant.name;
        this.newBudgetRequest.groupName = this.plant.plantGroupName;
        this.newBudgetRequest.technologyName = this.plant.technology;
        this.newBudgetRequest.technologySid = parseInt(this.plant.technologySid);
        this.newBudgetRequest.countryCode = this.plant.country.code;
        this.newBudgetRequest.countryName = this.plant.country.name;

        if (this.plant.country) {
            this.newBudgetRequest.planning.selectedCurrencyId = this.plant.country.currency.id;
        }

        this.newBudgetRequest.id = null;
        this.newBudgetRequest.globalId = null;
        this.newBudgetRequest.isActive = true;
        this.newBudgetRequest.created = new Date(Date.now());
        this.newBudgetRequest.createdBy = '';
        this.newBudgetRequest.planning.riskOpportunityId = '';
        this.newBudgetRequest.planning.maintenancePlanningIds = '';
        this.newBudgetRequest.planning.year = this.currentYear;
        this.newBudgetRequest.planning.modified = new Date(Date.now());
        this.newBudgetRequest.planning.modifiedBy = '';
        this.newBudgetRequest.planning.mtpStatus = 1;
        this.newBudgetRequest.planning.mainFlag = undefined;
        this.newBudgetRequest.planning.otherFlags = [];

        this.selectedMaintenanceType = this.newBudgetRequest.planning.maintenanceType;
        this.selectedUnitId = this.plant.units.find(e => {
            return Number(e.unitSid) === this.newBudgetRequest.unitSid;
        });
        this.selectedPrimaryDepartment = this.newBudgetRequest.planning.primaryDepartment;
        this.selectedProcurementCategory = this.newBudgetRequest.planning.procurementCategory;
        this.selectedPlanningAndControllingClassification = this.newBudgetRequest.planning.planningAndControllingClassification;
        if (this.newBudgetRequest.planning.startDate) {
            this.newBudgetRequest.planning.startDate = new Date(this.newBudgetRequest.planning.startDate);
        }
        if (this.newBudgetRequest.planning.endDate) {
            this.newBudgetRequest.planning.endDate = new Date(this.newBudgetRequest.planning.endDate);
        }
        if (!this.newBudgetRequest.planning.outageId) {
            this.newBudgetRequest.planning.outageId = '';
        }
        if (this.newBudgetRequest.planning.reviewComment) {
            this.formatDateReview = format(new Date(this.newBudgetRequest.planning.reviewComment.date), 'dd-MM-yyyy');
        }
        this.loading = false;
        this.preventLeavePage.checkPreventLeavePage = true;
        this.$bvToast.show('toast-duplicate');
    }

    private initCreateBudgetRequest() {
        this.loading = true;
        this.editPage = false;
        this.initBudgetRequest();
        this.newBudgetRequest.planning.planningAndControllingClassification = 0;
        this.newBudgetRequest.id = null;
        this.newBudgetRequest.plantSid = parseInt(this.plant.plantSId);
        this.newBudgetRequest.plantName = this.plant.name;
        this.newBudgetRequest.groupName = this.plant.plantGroupName;
        this.newBudgetRequest.technologyName = this.plant.technology;
        this.newBudgetRequest.technologySid = parseInt(this.plant.technologySid);
        this.newBudgetRequest.countryCode = this.plant.country.code;
        this.newBudgetRequest.countryName = this.plant.country.name;
        this.newBudgetRequest.planning.selectedCurrencyId = this.plant.country.currency.id;

        this.newBudgetRequest.planning.year = new Date(Date.now()).getFullYear();
        this.loading = false;
    }

    private removeDuplicateIds(originalArray) {
        let newArray = [];
        let lookupObject = {};
        let i;
        for (i in originalArray) {
            lookupObject[originalArray[i].currency.code] = originalArray[i];
        }
        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    }

    private calculateTotal() {
        if (this.newBudgetRequest.projectType.toString() === '1') {
            this.newBudgetRequest.planning.total = this.newBudgetRequest.planning.spendUntilEndOfYear + this.newBudgetRequest.planning.forecast + this.newBudgetRequest.planning.new1 + this.newBudgetRequest.planning.new2 + this.newBudgetRequest.planning.new3 + this.newBudgetRequest.planning.after;
        } else if (this.newBudgetRequest.projectType.toString() === '2') {
            this.newBudgetRequest.planning.total = this.newBudgetRequest.planning.new1 + this.newBudgetRequest.planning.new2 + this.newBudgetRequest.planning.new3;
        }

    }

    private checkEmpty(model) {

        if (this.newBudgetRequest.planning[model] === '') {
            this.$set(this.newBudgetRequest.planning, model, 0);
        }
        this.clearError(model, '');
        this.calculateTotal();
    }

    private get isPrimaryDepartmentDefinedbyAero(): boolean {
        const selectedPd = Number(this.selectedPrimaryDepartment);
        const aeroClassification = Number(this.newBudgetRequest.planning.aeroClassification);

        if (aeroClassification === 1 && selectedPd === 2) {
            return true;
        }

        if (aeroClassification === 2 && selectedPd === 1) {
            return true;
        }

        if (aeroClassification === 3 && selectedPd === 1) {
            return true;
        }

        if (aeroClassification === 4 && selectedPd === 2) {
            return true;
        }

        if (aeroClassification === 5 && selectedPd === 3) {
            return true;
        }

        if (aeroClassification === 6 && selectedPd === 4) {
            return true;
        }

        return false;
    }

    private fillPrimaryDepartment(index: any) {
        this.clearError('aero', '');
        switch (index) {
            case '1':
                this.selectedPrimaryDepartment = 2;
                this.newBudgetRequest.planning.primaryDepartment = 2;
                this.validateBudgetRequests.primaryDepartment = false;
                break;
            case '2':
                this.selectedPrimaryDepartment = 1;
                this.newBudgetRequest.planning.primaryDepartment = 1;
                this.validateBudgetRequests.primaryDepartment = false;
                break;
            case '3':
                this.selectedPrimaryDepartment = 1;
                this.newBudgetRequest.planning.primaryDepartment = 1;
                this.validateBudgetRequests.primaryDepartment = false;
                break;
            case '4':
                this.selectedPrimaryDepartment = 2;
                this.newBudgetRequest.planning.primaryDepartment = 2;
                this.validateBudgetRequests.primaryDepartment = false;
                break;
            case '5':
                this.selectedPrimaryDepartment = 3;
                this.newBudgetRequest.planning.primaryDepartment = 3;
                this.validateBudgetRequests.primaryDepartment = false;
                break;
            case '6':
                this.selectedPrimaryDepartment = 4;
                this.newBudgetRequest.planning.primaryDepartment = 4;
                this.validateBudgetRequests.primaryDepartment = false;
                break;
            default:
                this.selectedPrimaryDepartment = null;
        }
    }

    private cancel() {
        this.selectedSaveChanges = false;
        this.$router.go(-1);
    }

    private async saveChanges() {
        await this.clearError('lumpSum', '');
        this.validateBudgetRequest();
        this.selectedOption();
        this.errorNumber();

        if (this.numberOfError === 0) {
            // Check if copied external id was removed to clear attachedExternalId and maintenancePlanningIds fieds
            this.checkCopiedExternalId();
            // Compare budget request properties and external data to set BR's hasDifferences
            this.newBudgetRequest.planning.hasDifferences = this.checkExternalMappedData();

            this.newBudgetRequest.planning.mtpStatus = 1;
            await this.saveBr();

        }
    }

    private async saveAndSubmit() {
        await this.clearError('lumpSum', '');
        this.validateBudgetRequest();
        this.selectedOption();
        this.errorNumber();

        if (this.numberOfError === 0) {
            // Check if copied external id was removed to clear attachedExternalId and maintenancePlanningIds fieds
            this.checkCopiedExternalId();
            // Compare budget request properties and external data to set BR's hasDifferences
            this.newBudgetRequest.planning.hasDifferences = this.checkExternalMappedData();

            this.newBudgetRequest.planning.mtpStatus = 2;
            await this.saveBr();
        }
    }

    private checkExternalMappedData(): boolean {
        if (this.externalList.length === 1) {
            // Compare for maintenance type
            if (this.externalList[0].type === 'M') {
                if (this.newBudgetRequest.name !== this.externalList[0].data.dataToBeMappedInPml.name) {
                    if (!this.externalList[0].data.dataToBeMappedInPml.name) {
                        if (this.newBudgetRequest.name) {
                            return true;
                        }
                    } else {
                        return true;
                    }
                }
                if (this.newBudgetRequest.localLanguageName !== this.externalList[0].data.dataToBeMappedInPml.localLanguageName) {
                    if (!this.externalList[0].data.dataToBeMappedInPml.localLanguageName) {
                        if (this.newBudgetRequest.localLanguageName) {
                            return true;
                        }
                    } else {
                        return true;
                    }
                }
                if (this.newBudgetRequest.projectType !== this.externalList[0].data.dataToBeMappedInPml.projectType) {
                    return true;
                }
                if (this.newBudgetRequest.planning.aeroClassification !== this.externalList[0].data.dataToBeMappedInPml.aeroClassification) {
                    return true;
                }
                if (this.newBudgetRequest.planning.maintenanceType !== this.externalList[0].data.dataToBeMappedInPml.maintenanceType) {
                    return true;
                }
                if (this.newBudgetRequest.planning.justificationOnClassification !== this.externalList[0].data.dataToBeMappedInPml.justificationOnClassification) {
                    return true;
                }
                if (this.newBudgetRequest.planning.lumpSum !== this.externalList[0].data.dataToBeMappedInPml.lumpSum) {
                    return true;
                }
                if (this.newBudgetRequest.planning.planningAndControllingClassification !== this.externalList[0].data.dataToBeMappedInPml.planningAndControllingClassification) {
                    return true;
                }
                if (this.newBudgetRequest.planning.outageType !== this.externalList[0].data.dataToBeMappedInPml.outageType) {
                    return true;
                }

                if (this.externalList[0].data.dataToBeMappedInPml.startDate && this.newBudgetRequest.planning.startDate.getTime() !== new Date(this.externalList[0].data.dataToBeMappedInPml.startDate).getTime()) {
                    return true;
                }
                if (this.externalList[0].data.dataToBeMappedInPml.endDate && this.newBudgetRequest.planning.endDate.getTime() !== new Date(this.externalList[0].data.dataToBeMappedInPml.endDate).getTime()) {
                    return true;
                }

                // If external data has maintenancePlannings and there is at least one maintenance planning checked we compare the rest of the fields
                if (this.externalList[0].data.maintenancePlanning && this.externalList[0].data.maintenancePlanning.length > 0 && this.newBudgetRequest.planning.maintenancePlanningIds !== '-1') {
                    if (this.newBudgetRequest.planning.outageId !== this.externalList[0].data.dataToBeMappedInPml.outageIds) {
                        return true;
                    }
                    if (this.newBudgetRequest.planning.forecast.toFixed(3) !== this.externalList[0].data.dataToBeMappedInPml.forecast.toFixed(3)) {
                        return true;
                    }
                    if (this.newBudgetRequest.planning.spendUntilEndOfYear.toFixed(3) !== this.externalList[0].data.dataToBeMappedInPml.spentUntil.toFixed(3)) {
                        return true;
                    }
                    if (this.newBudgetRequest.planning.new1.toFixed(3) !== this.externalList[0].data.dataToBeMappedInPml.nextOneYear.toFixed(3)) {
                        return true;
                    }
                    if (this.newBudgetRequest.planning.new2.toFixed(3) !== this.externalList[0].data.dataToBeMappedInPml.nextTwoYears.toFixed(3)) {
                        return true;
                    }
                    if (this.newBudgetRequest.planning.new3.toFixed(3) !== this.externalList[0].data.dataToBeMappedInPml.nextThreeYears.toFixed(3)) {
                        return true;
                    }
                    if (this.newBudgetRequest.planning.after.toFixed(3) !== this.externalList[0].data.dataToBeMappedInPml.after.toFixed(3)) {
                        return true;
                    }
                }

                return false;
            } else if (this.externalList[0].type === 'R') {
                if (this.newBudgetRequest.name !== this.externalList[0].data.dataToBeMappedInPml.name) {
                    if (!this.externalList[0].data.dataToBeMappedInPml.name) {
                        if (this.newBudgetRequest.name) {
                            return true;
                        }
                    } else {
                        return true;
                    }
                }
                if (this.newBudgetRequest.localLanguageName !== this.externalList[0].data.dataToBeMappedInPml.nameLocalLanguage) {
                    if (!this.externalList[0].data.dataToBeMappedInPml.nameLocalLanguage) {
                        if (this.newBudgetRequest.localLanguageName) {
                            return true;
                        }
                    } else {
                        return true;
                    }
                }
                if (this.newBudgetRequest.planning.total.toFixed(3) !== this.externalList[0].data.dataToBeMappedInPml.nextMtpYearCost.toFixed(3)) {
                    return true;
                }
                if (this.newBudgetRequest.planning.aeroClassification !== this.externalList[0].data.dataToBeMappedInPml.aeroClassification) {
                    return true;
                }
                return false;
            } else if (this.externalList[0].type === 'O') {
                if (this.newBudgetRequest.name !== this.externalList[0].data.dataToBeMappedInPml.name) {
                    if (!this.externalList[0].data.dataToBeMappedInPml.name) {
                        if (this.newBudgetRequest.name) {
                            return true;
                        }
                    } else {
                        return true;
                    }
                }
                if (this.newBudgetRequest.localLanguageName !== this.externalList[0].data.dataToBeMappedInPml.nameLocalLanguage) {
                    if (!this.externalList[0].data.dataToBeMappedInPml.nameLocalLanguage) {
                        if (this.newBudgetRequest.localLanguageName) {
                            return true;
                        }
                    } else {
                        return true;
                    }
                }
                if (this.newBudgetRequest.planning.new1.toFixed(3) !== this.externalList[0].data.dataToBeMappedInPml.nextMtpYearCost.toFixed(3)) {
                    return true;
                }
                if (this.newBudgetRequest.planning.aeroClassification !== this.externalList[0].data.dataToBeMappedInPml.aeroClassification) {
                    return true;
                }
                return false;
            } else {
                return false;
            }
        }
    }

    private checkCopiedExternalId() {
        if (this.newBudgetRequest.planning.attachedExternalId) {
            if (!this.externalInputs.find(e => e.id.toUpperCase() === this.newBudgetRequest.planning.attachedExternalId.toUpperCase())) {
                this.newBudgetRequest.planning.attachedExternalId = '';
                this.newBudgetRequest.planning.maintenancePlanningIds = '';
            }
        }
    }

    private async saveBr() {
        localStorage.setItem('selectedMtp', new Date(Date.now()).getFullYear().toString());
        if (this.newBudgetRequest.planning.startDate) {
            this.newBudgetRequest.planning.startDate = format(new Date(this.newBudgetRequest.planning.startDate), 'yyyy-MM-dd\'T00:00:00\'');
        }
        if (this.newBudgetRequest.planning.endDate) {
            this.newBudgetRequest.planning.endDate = format(new Date(this.newBudgetRequest.planning.endDate), 'yyyy-MM-dd\'T00:00:00\'');
        }
        this.data.loadingSaveBudgetRequest = true;
        try {
            this.newBudgetRequest.planning.aeroClassification = parseInt(this.newBudgetRequest.planning.aeroClassification.toString());
            this.newBudgetRequest.planning.planningAndControllingClassification = parseInt(this.newBudgetRequest.planning.planningAndControllingClassification.toString());
            this.newBudgetRequest.planning.procurementCategory = parseInt(this.newBudgetRequest.planning.procurementCategory.toString());
            this.newBudgetRequest.planning.projectType = parseInt(this.newBudgetRequest.planning.projectType.toString());
            this.newBudgetRequest.planning.maintenanceType = parseInt(this.newBudgetRequest.planning.maintenanceType.toString());
            this.newBudgetRequest.planning.prioritizationRank = parseInt(this.newBudgetRequest.planning.prioritizationRank.toString());
            this.newBudgetRequest.planning.usefulEconomicLife = this.newBudgetRequest.planning.usefulEconomicLife !== '' ? this.newBudgetRequest.planning.usefulEconomicLife : undefined;

            this.newBudgetRequest['miniFinancialDecision'] = undefined;
            this.newBudgetRequest['budgetCompensation'] = undefined;

            let response = await this.BudgetRequestsService.addBudgetRequest(this.newBudgetRequest);
            this.data.loadingSaveBudgetRequest = false;
            this.selectedSaveChanges = true;
            if (this.editPage) {
                this.$router.push({
                    path: 'details-page',
                    query: { year: this.$route.query.year, globalId: this.$route.query.globalId }
                });
            } else {
                this.$router.push({ path: 'details-page', query: { year: this.currentYear.toString(), id: response } });
            }
            this.loading = false;
        } catch {
            this.data.loadingSaveBudgetRequest = false;
            this.loading = false;
            this.$bvToast.show('toast-exception');
        }
    }

    private async clearError(field, check) {
        this.newBudgetRequest.planning.projectType = this.newBudgetRequest.projectType;
        if (!this.newBudgetRequest.planning.startDate && !this.newBudgetRequest.planning.endDate) {
            this.validateGreatherStartDate = false;
        }
        if (check === 'prevent') {
            this.preventLeavePage[field] = true;
        } else {
            this.preventLeavePage[field] = true;
            this.validateBudgetRequests[field] = false;
            if (this.newBudgetRequest.planning.outageType.toString() === '2') {
                this.validateBudgetRequests.outageId = false;
            }
            if (this.newBudgetRequest.planning.aeroClassification.toString() === '5') {
                this.validateBudgetRequests.primaryDepartment = false;
            }
            if (this.newBudgetRequest.planning.aeroClassification.toString() !== '4') {
                this.validateBudgetRequests.justification = false;
            }
        }
        if (field === 'lumpSum') {
            this.externalList = [];
            for (let index = 0; index < this.externalInputs.length; index++) {
                this.loadingSlick = true;
                await this.getDataByExternalId(this.externalInputs[index].id, index);
                this.loadingSlick = false;
            }
        }
        let numberOfPreventLeave = Object.values(this.preventLeavePage);
        for (let i = 0; i <= numberOfPreventLeave.length; i++) {
            if (numberOfPreventLeave[i] === true) {
                this.preventLeavePage.checkPreventLeavePage = true;
            }
        }
        this.errorNumber();
    }

    private errorNumber() {
        this.numberOfError = 0;
        let err = 0;
        let numberOfProp = Object.values(this.validateBudgetRequests);
        for (let i = 0; i <= numberOfProp.length; i++) {
            if (numberOfProp[i] === true) {
                err = err + 1;
            }
        }

        let externalError = 0;
        if (this.externalInputs.length > 0) {
            this.externalInputs.forEach((e) => {
                if (e.isInvalid) {
                    externalError = externalError + 1;
                }
            });
        }

        this.numberOfError = err + externalError;
    }

    private selectedOption() {
        this.newBudgetRequest.planning.primaryDepartment = Number(this.selectedPrimaryDepartment);
        this.newBudgetRequest.planning.maintenanceType = this.selectedMaintenanceType;
        if (this.selectedUnitId) {
            this.newBudgetRequest.unitId = this.selectedUnitId['id'];
            this.newBudgetRequest.unitName = this.selectedUnitId['name'];
            this.newBudgetRequest.unitSid = this.selectedUnitId['unitSid'];
        }
        this.newBudgetRequest.planning.planningAndControllingClassification = this.selectedPlanningAndControllingClassification;
        this.newBudgetRequest.planning.procurementCategory = this.selectedProcurementCategory;
        if (this.newBudgetRequest.planning.outageType.toString() === '2') {
            this.newBudgetRequest.planning.outageId = '';
        }
    }

    private validateBudgetRequest() {

        if (!this.newBudgetRequest.name || this.newBudgetRequest.name.trim().length === 0) {
            this.validateBudgetRequests.name = true;
        } else {
            this.validateBudgetRequests.name = false;
        }
        if (!this.selectedUnitId) {
            this.validateBudgetRequests.unit = true;
        } else {
            this.validateBudgetRequests.unit = false;
        }
        if (this.newBudgetRequest.projectType === 0) {
            this.validateBudgetRequests.projectType = true;
        } else {
            this.validateBudgetRequests.projectType = false;
        }
        if (this.newBudgetRequest.planning.lumpSum === null) {
            this.validateBudgetRequests.lumpSum = true;
        } else {
            this.validateBudgetRequests.lumpSum = false;
        }
        if (this.newBudgetRequest.projectType.toString() === '1' || this.validateBudgetRequests.projectType) {
            if (!this.newBudgetRequest.planning.startDate) {
                this.validateBudgetRequests.startDate = true;
            }
        }
        if (this.newBudgetRequest.projectType.toString() === '2' || this.validateBudgetRequests.projectType) {
            if (!this.newBudgetRequest.planning.startDate) {
                this.validateBudgetRequests.startDate = false;
            }
        }
        if (this.newBudgetRequest.planning.startDate && this.newBudgetRequest.planning.endDate) {
            if (this.newBudgetRequest.planning.startDate >= this.newBudgetRequest.planning.endDate) {
                this.validateBudgetRequests.startDate = true;
                this.validateGreatherStartDate = true;
            } else {
                this.validateBudgetRequests.startDate = false;
                this.validateGreatherStartDate = false;
            }
        }

        if (this.newBudgetRequest.planning.aeroClassification === 0) {
            this.validateBudgetRequests.aero = true;
        } else {
            this.validateBudgetRequests.aero = false;
            this.validateBudgetRequests.primaryDepartment = false;
        }

        if ((!this.newBudgetRequest.planning.justificationOnClassification || this.newBudgetRequest.planning.justificationOnClassification.trim().length === 0) && this.newBudgetRequest.planning.aeroClassification.toString() === '4') {
            this.validateBudgetRequests.justification = true;
        } else {
            this.validateBudgetRequests.justification = false;
        }

        if (this.newBudgetRequest.planning.primaryDepartment.toString() === '0') {
            this.validateBudgetRequests.primaryDepartment = true;
        } else {
            this.validateBudgetRequests.primaryDepartment = false;
        }
        if (!this.selectedMaintenanceType) {
            this.validateBudgetRequests.maintenanceType = true;
        } else {
            this.validateBudgetRequests.maintenanceType = false;
        }
        if (this.newBudgetRequest.planning.outageType === 0) {
            this.validateBudgetRequests.outageType = true;
        } else {
            this.validateBudgetRequests.outageType = false;
        }
        if (this.newBudgetRequest.planning.outageId) {
            if ((this.newBudgetRequest.planning.outageId.trim().length === 0) && this.newBudgetRequest.planning.outageType.toString() === '1') {
                this.validateBudgetRequests.outageId = true;
            } else {
                this.validateBudgetRequests.outageId = false;
            }
        } else {
            if (!this.newBudgetRequest.planning.outageId && this.newBudgetRequest.planning.outageType.toString() === '1') {
                this.validateBudgetRequests.outageId = true;
            } else {
                this.validateBudgetRequests.outageId = false;
            }
        }

        // Check risk/maintenance ids for aero classification validation
        if (this.newBudgetRequest.planning.riskOpportunityId) {
            this.newBudgetRequest.planning.riskOpportunityId = this.newBudgetRequest.planning.riskOpportunityId.replace(/\s/g, '');
            let ids = this.newBudgetRequest.planning.riskOpportunityId.split(',');
            let types = [];
            ids.forEach(id => {
                types.push(id.slice(id.length - 1).toUpperCase());
            });
            let type = ids[0].slice(ids[0].length - 1).toUpperCase();
            this.validateBudgetRequests.aero = true;
            if (types.includes('M')) {
                if (this.newBudgetRequest.planning.aeroClassification.toString() === '1' || this.newBudgetRequest.planning.aeroClassification.toString() === '5') {
                    this.validateBudgetRequests.aero = false;
                }
            }
            if (types.includes('S')) {
                if (this.newBudgetRequest.planning.aeroClassification.toString() === '4' || this.newBudgetRequest.planning.aeroClassification.toString() === '5') {
                    this.validateBudgetRequests.aero = false;
                }
            }
            if (types.includes('R')) {
                if (this.newBudgetRequest.planning.aeroClassification.toString() === '2' || this.newBudgetRequest.planning.aeroClassification.toString() === '5') {
                    this.validateBudgetRequests.aero = false;
                }
            }
            if (types.includes('O')) {
                if (this.newBudgetRequest.planning.aeroClassification.toString() === '3' || this.newBudgetRequest.planning.aeroClassification.toString() === '2' || this.newBudgetRequest.planning.aeroClassification.toString() === '5') {
                    this.validateBudgetRequests.aero = false;
                }
            }
            if (types.includes('O') && types.includes('R')) {
                if (this.newBudgetRequest.planning.aeroClassification.toString() === '3' || this.newBudgetRequest.planning.aeroClassification.toString() === '5') {
                    this.validateBudgetRequests.aero = true;
                }
            }
            if (types.length > 1 && this.newBudgetRequest.planning.lumpSum) {
                this.validateBudgetRequests.aero = false;
            }
            if (types.includes('M') && this.newBudgetRequest.planning.lumpSum) {
                if (this.newBudgetRequest.planning.aeroClassification.toString() === '1' || this.newBudgetRequest.planning.aeroClassification.toString() === '4' || this.newBudgetRequest.planning.aeroClassification.toString() === '5') {
                    this.validateBudgetRequests.aero = false;
                }
            }
            if (types.includes('R') && this.newBudgetRequest.planning.lumpSum) {
                if (this.newBudgetRequest.planning.aeroClassification.toString() === '2' || this.newBudgetRequest.planning.aeroClassification.toString() === '3' || this.newBudgetRequest.planning.aeroClassification.toString() === '5') {
                    this.validateBudgetRequests.aero = false;
                }
            }
            if (types.includes('S') && this.newBudgetRequest.planning.lumpSum) {
                if (this.newBudgetRequest.planning.aeroClassification.toString() === '1' || this.newBudgetRequest.planning.aeroClassification.toString() === '4' || this.newBudgetRequest.planning.aeroClassification.toString() === '5') {
                    this.validateBudgetRequests.aero = false;
                }
            }
            if (types.includes('O') && this.newBudgetRequest.planning.lumpSum) {
                if (this.newBudgetRequest.planning.aeroClassification.toString() === '2' || this.newBudgetRequest.planning.aeroClassification.toString() === '3' || this.newBudgetRequest.planning.aeroClassification.toString() === '5') {
                    this.validateBudgetRequests.aero = false;
                }
            }
        }

        if (!this.selectedProcurementCategory) {
            this.validateBudgetRequests.procurementCategory = true;
        } else {
            this.validateBudgetRequests.procurementCategory = false;
        }
        if (!this.newBudgetRequest.planning.wbsElement || this.newBudgetRequest.planning.wbsElement.trim().length === 0) {
            this.validateBudgetRequests.wbs = true;
        } else {
            this.validateBudgetRequests.wbs = false;
        }
        if (!this.selectedPlanningAndControllingClassification) {
            this.validateBudgetRequests.planning = true;
        } else {
            this.validateBudgetRequests.planning = false;
        }
        if ((this.newBudgetRequest.planning.uniperShareOfBudget >= 0 && this.newBudgetRequest.planning.uniperShareOfBudget <= 100) && this.newBudgetRequest.planning.uniperShareOfBudget.toString().length !== 0) {
            this.validateBudgetRequests.uniperShareOfBudget = false;
        } else {
            this.validateBudgetRequests.uniperShareOfBudget = true;
        }

        if (!this.newBudgetRequest.planning.selectedCurrencyId) {
            this.validateBudgetRequests.currency = true;
        } else {
            this.validateBudgetRequests.currency = false;
        }
    }

    private validateDateLength() {
        if (this.newBudgetRequest.planning.startDate) {
            if (this.newBudgetRequest.planning.startDate.toString().length > 10) {
                this.newBudgetRequest.planning.startDate = null;
            }
        }
        if (this.newBudgetRequest.planning.endDate) {
            if (this.newBudgetRequest.planning.endDate.toString().length > 10) {
                this.newBudgetRequest.planning.endDate = null;
            }
        }
    }

    private async addToCurrentMtp() {
        this.validateBudgetRequest();
        this.selectedOption();
        this.errorNumber();
        if (this.numberOfError === 0) {
            this.data.addToCurrentMtp = true;
            this.newBudgetRequest.planning.year = new Date(Date.now()).getFullYear();
            if (this.newBudgetRequest.planning.startDate) {
                this.newBudgetRequest.planning.startDate = format(new Date(this.newBudgetRequest.planning.startDate), 'yyyy-MM-dd\'T00:00:00\'');
            }
            if (this.newBudgetRequest.planning.endDate) {
                this.newBudgetRequest.planning.endDate = format(new Date(this.newBudgetRequest.planning.endDate), 'yyyy-MM-dd\'T00:00:00\'');
            }
            this.newBudgetRequest.planning.aeroClassification = parseInt(this.newBudgetRequest.planning.aeroClassification.toString());
            this.newBudgetRequest.planning.planningAndControllingClassification = parseInt(this.newBudgetRequest.planning.planningAndControllingClassification.toString());
            this.newBudgetRequest.planning.procurementCategory = parseInt(this.newBudgetRequest.planning.procurementCategory.toString());
            this.newBudgetRequest.planning.projectType = parseInt(this.newBudgetRequest.planning.projectType.toString());
            this.newBudgetRequest.planning.maintenanceType = parseInt(this.newBudgetRequest.planning.maintenanceType.toString());
            this.newBudgetRequest.planning.prioritizationRank = parseInt(this.newBudgetRequest.planning.prioritizationRank.toString());
            this.newBudgetRequest.planning.usefulEconomicLife = this.newBudgetRequest.planning.usefulEconomicLife !== '' ? this.newBudgetRequest.planning.usefulEconomicLife : undefined;

            this.newBudgetRequest['miniFinancialDecision'] = undefined;
            this.newBudgetRequest['budgetCompensation'] = undefined;
            this.newBudgetRequest['budgetCompensationPlanning'] = undefined;

            await this.BudgetRequestsService.addToCurrentMtp(this.newBudgetRequest);
            this.selectedSaveChanges = true;
            this.$router.push('/budget-requests');
            localStorage.setItem('selectedMtp', this.currentYear.toString());
            this.data.viewDataAddCurrentMtp = {
                year: this.newBudgetRequest.planning.year,
                globalId: this.newBudgetRequest.globalId
            };
            this.$bvToast.show('add-to-current-mtp');
            this.data.addToCurrentMtp = false;
        }
    }

    private hideToast() {
        this.$bvToast.hide('toast-exception');
        this.$bvToast.hide('external-data-copy-toast');
        this.$bvToast.hide('toast-duplicate');
    }

    private async getDataByExternalId(id: string, index: number) {
        id = id.trim().toUpperCase();

        if (id.trim().length !== 0) {
            try {
                // Set externalIds object for api call
                this.setExternalIds(id, index);

                // Validate current id
                this.validateExternalId(this.externalIds, index);

                let externalData = await this.CrossAppLinkingService.getExternalData(this.externalIds);

                if (externalData.isRequestValid) {

                    // Format dates
                    if (externalData && this.externalInputs[index].type === 'M') {
                        this.formatDates(externalData.data);
                    }

                    // reset maintenancePlanningIds when id changes for the first external input
                    if (this.externalInputs[index].type === 'M' && this.externalList.length > 0 && index === 0) {
                        if (this.externalList[index].id !== id) {
                            this.newBudgetRequest.planning.maintenancePlanningIds = '';
                        }
                    }
                    // Set externalList item
                    this.externalList.splice(index, 1, {
                        id: id,
                        type: this.externalInputs[index].type,
                        data: externalData.data
                    });

                    this.externalInputs[index].isInvalid = false;
                    this.externalInputs[index].errorMessage = '';
                    this.preventLeavePage.checkPreventLeavePage = true;
                } else {
                    this.externalInputs[index].isInvalid = true;
                    this.externalInputs[index].errorMessage = externalData.errorMessage;
                }
            } catch (error) {
                if (error.response !== undefined && error.response.status === 400) {
                    this.externalInputs[index].isInvalid = true;
                    this.externalInputs[index].errorMessage = error.response.data;
                } else {
                    this.externalInputs[index].isInvalid = true;
                    this.externalInputs[index].errorMessage = error.message;
                }

                this.externalList.splice(index, 1, { id: '', type: '', data: null });
            }
        } else {
            this.externalList.splice(index, 1, { id: '', type: '', data: null });
            this.externalInputs[index].isInvalid = false;
            this.externalInputs[index].errorMessage = '';
            this.externalInputs[index].type = '';
        }

        // Set budget request model riskOpportunityId
        this.setBudgetRequestRiskMaintenanceIds();
    }

    private async getExternalDataForEdit() {
        // Set external ids for edit api call
        this.externalIdsEdit.budgetRequestId = this.newBudgetRequest.id;
        this.newBudgetRequest.planning.riskOpportunityId = this.newBudgetRequest.planning.riskOpportunityId.replace(/\s/g, '');
        this.externalIdsEdit.externalIds = this.newBudgetRequest.planning.riskOpportunityId.split(',');
        if (this.newBudgetRequest.planning.maintenancePlanningIds && this.newBudgetRequest.planning.maintenancePlanningIds.trim().length > 0) {
            this.newBudgetRequest.planning.maintenancePlanningIds = this.newBudgetRequest.planning.maintenancePlanningIds.replace(/\s/g, '');
            this.externalIdsEdit.userSelectionMaintenancePlanningIds = this.newBudgetRequest.planning.maintenancePlanningIds.split(',').map(e => parseInt(e));
        }
        this.externalIdsEdit.mtpYear = this.newBudgetRequest.planning.year;

        let externalData = null;

        try {
            externalData = await this.CrossAppLinkingService.getExternalIds(this.externalIdsEdit);
        } catch (error) {
            externalData = error.response.data;
        }

        if (this.externalIdsEdit.externalIds.length === 1) {
            let errorMsg = externalData.errorMessage;

            this.externalInputs[0].id = this.externalIdsEdit.externalIds[0];

            // Check if id was deleted
            if (errorMsg && errorMsg.indexOf(this.externalIdsEdit.externalIds[0]) !== -1) {
                this.externalInputs[0].isInvalid = true;
                this.externalInputs[0].errorMessage = 'External id ' + this.externalIdsEdit.externalIds[0] + ' is not valid or has been deleted.';
                this.externalList.splice(0, 1, { id: '', type: '', data: null });
            } else {
                this.setExternalIds(this.externalInputs[0].id, 0);
                this.validateExternalId(this.externalIds, 0);

                // Format dates
                if (externalData && this.externalInputs[0].type === 'M') {
                    this.formatDates(externalData.data);
                }

                this.externalList.splice(0, 1, {
                    id: this.externalIdsEdit.externalIds[0],
                    type: this.externalInputs[0].type,
                    data: externalData.data
                });
            }
        } else if (this.externalIdsEdit.externalIds.length > 1 && externalData.data) {

            this.externalIdsEdit.externalIds.forEach((e, index) => {

                let matchData = externalData.data.find((d: any) => d[Object.keys(d)[0]] === e);
                if (matchData) {
                    if (index === 0) {
                        this.externalInputs[index].id = this.externalIdsEdit.externalIds[index];
                    } else {
                        this.externalInputs.push({
                            id: this.externalIdsEdit.externalIds[index],
                            isInvalid: false,
                            errorMessage: '',
                            type: this.externalInputs[0].type
                        });
                    }

                    this.setExternalIds(this.externalInputs[index].id, index);
                    this.validateExternalId(this.externalIds, index);

                    // Format dates
                    if (this.externalInputs[index].type === 'M') {
                        this.formatDates(matchData);
                    }

                    this.externalList.splice(index, 1, {
                        id: this.externalIdsEdit.externalIds[index],
                        type: this.externalInputs[index].type,
                        data: matchData
                    });
                } else {
                    if (index === 0) {
                        this.externalInputs[index].id = this.externalIdsEdit.externalIds[index];
                        this.externalInputs[index].isInvalid = true;
                        this.externalInputs[index].errorMessage = 'External id ' + this.externalIdsEdit.externalIds[index] + ' is not valid or has been deleted.';
                    } else {
                        this.externalInputs.push({
                            id: this.externalIdsEdit.externalIds[index],
                            isInvalid: true,
                            errorMessage: 'External id ' + this.externalIdsEdit.externalIds[index] + ' is not valid or has been deleted.',
                            type: this.externalInputs[0].type
                        });
                    }

                    this.externalList.splice(index, 1, { id: '', type: '', data: null });
                }
            });
        }

        // Set warning messages
        if (externalData.validationData) {
            this.checkMappings(externalData.validationData);
        } else {
            this.noValidationWarning = true;
        }
    }

    private checkMappings(validationData: any) {
        this.mappingWarning = '';

        if (validationData.namesAreEqual !== undefined) {
            if (!validationData.namesAreEqual) {
                this.validateMappings.namesAreEqual = validationData.namesAreEqual;
                this.mappingWarning = this.mappingWarning + ' Name';
                this.showWarning = true;
            }
        }
        if (validationData.nameLocalLanguagesAreEqual !== undefined) {
            if (!validationData.nameLocalLanguagesAreEqual) {
                this.validateMappings.nameLocalLanguagesAreEqual = validationData.nameLocalLanguagesAreEqual;
                this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'Name (local language)' : this.mappingWarning + ', Name (local language)';
                this.showWarning = true;
            }
        }
        if (validationData.localLanguageNamesAreEqual !== undefined) {
            if (!validationData.localLanguageNamesAreEqual) {
                this.validateMappings.nameLocalLanguagesAreEqual = validationData.localLanguageNamesAreEqual;
                this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'Name (local language)' : this.mappingWarning + ', Name (local language)';
                this.showWarning = true;
            }
        }
        if (validationData.projectTypesAreEqual !== undefined) {
            this.validateMappings.projectTypesAreEqual = validationData.projectTypesAreEqual;
            if (!validationData.projectTypesAreEqual) {
                this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'Project Type' : this.mappingWarning + ', Project Type';
                this.showWarning = true;
            }
        }
        if (validationData.maintenanceTypesAreEqual !== undefined) {
            this.validateMappings.maintenanceTypesAreEqual = validationData.maintenanceTypesAreEqual;
            if (!validationData.maintenanceTypesAreEqual) {
                this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'Maintenance Type' : this.mappingWarning + ', Maintenance Type';
                this.showWarning = true;
            }
        }
        if (validationData.aeroClassificationsAreEqual !== undefined) {
            this.validateMappings.aeroClassificationsAreEqual = validationData.aeroClassificationsAreEqual;
            if (!validationData.aeroClassificationsAreEqual) {
                this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'AERO Classification' : this.mappingWarning + ', AERO Classification';
                this.showWarning = true;
            }
        }
        if (validationData.aeroClassificationAreEqual !== undefined) {
            this.validateMappings.aeroClassificationsAreEqual = validationData.aeroClassificationAreEqual;
            if (!validationData.aeroClassificationAreEqual) {
                this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'AERO Classification' : this.mappingWarning + ', AERO Classification';
                this.showWarning = true;
            }
        }
        if (validationData.justificationOnClassificationsAreEqual !== undefined) {
            this.validateMappings.justificationOnClassificationsAreEqual = validationData.justificationOnClassificationsAreEqual;
            if (!validationData.justificationOnClassificationsAreEqual) {
                this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'Justification on Classification' : this.mappingWarning + ', Justification on Classification';
                this.showWarning = true;
            }
        }
        if (validationData.lumpSumsAreEqual !== undefined) {
            this.validateMappings.lumpSumsAreEqual = validationData.lumpSumsAreEqual;
            if (!validationData.lumpSumsAreEqual) {
                this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'Lump Sum' : this.mappingWarning + ', Lump Sum';
                this.showWarning = true;
            }
        }
        if (validationData.planningAndControllingClassificationsAreEqual !== undefined) {
            this.validateMappings.planningAndControllingClassificationsAreEqual = validationData.planningAndControllingClassificationsAreEqual;
            if (!validationData.planningAndControllingClassificationsAreEqual) {
                this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'Planning & Controlling Classification' : this.mappingWarning + ', Planning & Controlling Classification';
                this.showWarning = true;
            }
        }
        if (validationData.outageIdsAreEqual !== undefined) {
            this.validateMappings.outageIdsAreEqual = validationData.outageIdsAreEqual;
            if (!validationData.outageIdsAreEqual) {
                this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'Outage ID' : this.mappingWarning + ', Outage ID';
                this.showWarning = true;
            }
        }
        if (validationData.outageTypesAreEqual !== undefined) {
            this.validateMappings.outageTypesAreEqual = validationData.outageTypesAreEqual;
            if (!validationData.outageTypesAreEqual) {
                this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'Outage Type' : this.mappingWarning + ', Outage Type';
                this.showWarning = true;
            }
        }
        // For Risk/Opportunity id check data forecastsAreEqual but validation and highlighting is done for the Total value of the BR
        if (this.externalInputs[0].type === 'R' || this.externalInputs[0].type === 'O') {
            if (validationData.pmlTotalsAndRiskStrategyCostAreEqual !== undefined) {
                this.validateMappings.totalsAreEqual = validationData.pmlTotalsAndRiskStrategyCostAreEqual;
                if (!validationData.pmlTotalsAndRiskStrategyCostAreEqual) {
                    this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'Total ' : this.mappingWarning + ', Total ';
                    this.showWarning = true;
                }
            }
        }
        if (this.externalInputs[0].type === 'M') {
            if (validationData.forecastsAreEqual !== undefined) {
                this.validateMappings.forecastsAreEqual = validationData.forecastsAreEqual;
                if (!validationData.forecastsAreEqual) {
                    this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'Forecast MTP ' + this.newBudgetRequest.planning.year : this.mappingWarning + ', Forecast MTP ' + this.newBudgetRequest.planning.year;
                    this.showWarning = true;
                }
            }
        }
        if (validationData.spentUntilsAreEqual !== undefined) {
            this.validateMappings.spentUntilsAreEqual = validationData.spentUntilsAreEqual;
            if (!validationData.spentUntilsAreEqual) {
                this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'Spent until end of ' + (this.newBudgetRequest.planning.year - 1) : this.mappingWarning + ', Spent until end of ' + (this.newBudgetRequest.planning.year - 1);
                this.showWarning = true;
            }
        }
        if (validationData.nextOneYearsAreEqual !== undefined) {
            this.validateMappings.nextOneYearsAreEqual = validationData.nextOneYearsAreEqual;
            if (!validationData.nextOneYearsAreEqual) {
                this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'New MTP ' + (this.newBudgetRequest.planning.year + 1) : this.mappingWarning + ', New MTP ' + (this.newBudgetRequest.planning.year + 1);
                this.showWarning = true;
            }
        }
        if (validationData.nextTwoYearsAreEqual !== undefined) {
            this.validateMappings.nextTwoYearsAreEqual = validationData.nextTwoYearsAreEqual;
            if (!validationData.nextTwoYearsAreEqual) {
                this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'New MTP ' + (this.newBudgetRequest.planning.year + 2) : this.mappingWarning + ', New MTP ' + (this.newBudgetRequest.planning.year + 2);
                this.showWarning = true;
            }
        }
        if (validationData.nextThreeYearsAreEqual !== undefined) {
            this.validateMappings.nextThreeYearsAreEqual = validationData.nextThreeYearsAreEqual;
            if (!validationData.nextThreeYearsAreEqual) {
                this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'New MTP ' + (this.newBudgetRequest.planning.year + 3) : this.mappingWarning + ', New MTP ' + (this.newBudgetRequest.planning.year + 3);
                this.showWarning = true;
            }
        }
        if (validationData.aftersAreEqual !== undefined) {
            this.validateMappings.aftersAreEqual = validationData.aftersAreEqual;
            if (!validationData.aftersAreEqual) {
                this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'After ' + (this.newBudgetRequest.planning.year + 3) : this.mappingWarning + ', After ' + (this.newBudgetRequest.planning.year + 3);
                this.showWarning = true;
            }
        }
        if (validationData.startDatesAreEqual !== undefined) {
            if (!validationData.startDatesAreEqual && this.externalList[0].data.dataToBeMappedInPml.startDate) {
                this.validateMappings.startDatesAreEqual = validationData.startDatesAreEqual;
                this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'Start Date' : this.mappingWarning + ', Start Date';
                this.showWarning = true;
            }
        }
        if (validationData.endDatesAreEqual !== undefined) {
            if (!validationData.endDatesAreEqual && this.externalList[0].data.dataToBeMappedInPml.endDate) {
                this.validateMappings.endDatesAreEqual = validationData.endDatesAreEqual;
                this.mappingWarning = this.mappingWarning === '' ? this.mappingWarning + 'End Date' : this.mappingWarning + ', End Date';
                this.showWarning = true;
            }
        }
    }

    private setExternalIds(id: string, index: number) {
        this.externalIds = {
            externalId: '',
            existingExternalIds: [],
            userSelectionMaintenancePlanningIds: []
        };
        this.externalIds.existingExternalIds = [];
        this.externalIds.externalId = id;
        this.externalList.forEach((e, idx) => {
            if (idx !== index && e.id !== '')
                this.externalIds.existingExternalIds.push(e.id);
        });
        this.externalIds.userSelectionMaintenancePlanningIds = [];
    }

    private setBudgetRequestRiskMaintenanceIds() {
        this.newBudgetRequest.planning.riskOpportunityId = '';
        this.externalList.forEach((e, ind) => {
            if (e.id !== '') {
                if (this.newBudgetRequest.planning.riskOpportunityId.trim().length < 1) {
                    this.newBudgetRequest.planning.riskOpportunityId = e.id;
                } else {
                    this.newBudgetRequest.planning.riskOpportunityId = this.newBudgetRequest.planning.riskOpportunityId + ',' + e.id;
                }
            }
        });
    }

    private async applyCheckbox(index: number) {
        this.externalIds.userSelectionMaintenancePlanningIds = [];
        this.externalList[index].data.maintenancePlanning.forEach((e: any) => {
            if (e.isSelected) {
                this.externalIds.userSelectionMaintenancePlanningIds.push(parseInt(e.id));
            }
        });

        // If all inputs are unchecked we need to set userSelectionMaintenancePlanningIds to -1
        if (this.externalIds.userSelectionMaintenancePlanningIds.length === 0) {
            this.externalIds.userSelectionMaintenancePlanningIds.push(-1);
        }

        try {
            // Set externalIds object for api call
            this.externalIds.existingExternalIds = [];
            this.externalIds.externalId = this.externalInputs[index].id.trim().toUpperCase();
            this.externalList.forEach((e, idx) => {
                if (idx !== index && e !== '')
                    this.externalIds.existingExternalIds.push(e.id);
            });

            // Validate current id
            this.validateExternalId(this.externalIds, index);

            // @ts-ignore
            let externalData = await this.CrossAppLinkingService.getExternalData(this.externalIds);

            // Format dates
            if (externalData && this.externalInputs[index].type === 'M') {
                this.formatDates(externalData.data);
            }

            this.externalList.splice(index, 1, {
                id: this.externalIds.externalId,
                type: this.externalInputs[index].type,
                data: externalData.data
            });

            this.externalInputs[index].isInvalid = false;
            this.externalInputs[index].errorMessage = '';
        } catch (error) {
            if (error.response !== undefined && error.response.status === 400) {
                this.externalInputs[index].isInvalid = true;
                this.externalInputs[index].errorMessage = error.response.data;
            } else {
                this.externalInputs[index].isInvalid = true;
                this.externalInputs[index].errorMessage = error.message;
            }

            this.externalList.splice(index, 1, { id: '', type: '', data: null });
        }
    }

    private validateExternalId(externalIds: any, index: number) {
        // Check duplicates
        if (externalIds.existingExternalIds.find((e: string) => e === externalIds.externalId)) {
            throw new Error('External id ' + externalIds.externalId + ' already exists.');
        }
        // Check if external id ends 'M'/'S'/'R'/'O' and set external application type
        let currentIdType = externalIds.externalId.slice(externalIds.externalId.length - 1);
        if (currentIdType !== 'M' && currentIdType !== 'S' && currentIdType !== 'R' && currentIdType !== 'O') {
            throw new Error('External id ' + externalIds.externalId + ' is not valid.');
        } else if (currentIdType === 'M' || currentIdType === 'S') {
            this.externalInputs[index].type = 'M'; // for Maintenance and Statutory
        } else if (currentIdType === 'R') {
            this.externalInputs[index].type = 'R'; // for Risk
        } else {
            this.externalInputs[index].type = 'O'; // for Opportunity
        }
        // Compare external id with previously added ids
        if (externalIds.existingExternalIds.length > 0 && !this.newBudgetRequest.planning.lumpSum) {
            let previousIdsType = externalIds.existingExternalIds[0].slice(externalIds.existingExternalIds[0].length - 1);

            if ((currentIdType === 'M' && previousIdsType !== 'M') ||
                (currentIdType === 'S' && previousIdsType !== 'S') ||
                ((currentIdType === 'R' || currentIdType === 'O') && (previousIdsType === 'M' || previousIdsType === 'S'))) {
                throw new Error('Cannot add external id ' + externalIds.externalId + ' along with previous external ids.');
            }
        }
    }

    private addExternalInput() {
        this.externalIds.userSelectionMaintenancePlanningIds = [];
        this.externalInputs.push({ id: '', isInvalid: false, errorMessage: '', type: '' });
        this.externalList.push({ id: '', type: '', data: null });
    }

    private async removeExternalInput(index: number) {
        if (index === 0 && this.externalInputs.length === 1) {
            this.externalList.splice(index, 1, { id: '', type: '', data: null });
            this.externalInputs[index] = { id: '', isInvalid: false, errorMessage: '', type: '' };
            this.externalIds.userSelectionMaintenancePlanningIds = [];
            this.newBudgetRequest.planning.maintenancePlanningIds = '';
        } else {
            this.externalInputs.splice(index, 1);
            this.externalList.splice(index, 1);
        }
        // Set budget request model riskOpportunityId
        this.setBudgetRequestRiskMaintenanceIds();

        if (this.editPage && this.externalList.length === 1 && this.externalList[0].data && !this.externalList[0].data.dataToBeMappedInPml) {
            await this.getDataByExternalId(this.externalInputs[0].id, 0);
        }

        this.preventLeavePage.checkPreventLeavePage = true;
    }

    private formatDates(externalData: any) {
        if (externalData.nextPlannedStartDate)
            externalData.nextPlannedStartDate = moment(Helper.UtcToLocalTime(externalData.nextPlannedStartDate)).format('DD/MM/YYYY');
        if (externalData.lastMaintenanceDate)
            externalData.lastMaintenanceDate = moment(Helper.UtcToLocalTime(externalData.lastMaintenanceDate)).format('DD/MM/YYYY');

        if (externalData.maintenancePlanning) {
            externalData.maintenancePlanning.forEach((a) => {
                if (a.planningDate) {
                    a.planningDate = moment(Helper.UtcToLocalTime(a.planningDate)).format('DD/MM/YYYY');
                }
            });
        }
    }

    private maintenanceCopy(index: number) {
        // If external data has maintenance plannings and at least one planning item is checked then map all mapping data
        // Else map just a part of the mapping data
        this.currentExternalApplicationType = this.externalInputs[index].type;
        this.newBudgetRequest.planning.maintenancePlanningIds = '';
        this.newBudgetRequest.planning.attachedExternalId = this.externalList[index].data.maintenanceId;

        if (this.externalList[index].data.maintenancePlanning && this.externalList[index].data.maintenancePlanning.length > 0) {

            this.externalList[index].data.maintenancePlanning.forEach((e: any) => {
                if (e.isSelected) {
                    if (this.newBudgetRequest.planning.maintenancePlanningIds === '') {
                        this.newBudgetRequest.planning.maintenancePlanningIds = e.id?.toString() ?? '';
                    } else {
                        this.newBudgetRequest.planning.maintenancePlanningIds = this.newBudgetRequest.planning.maintenancePlanningIds + ',' + e.id;
                    }
                }
            });

            // If all inputs are unchecked we need to set maintenancePlanningIds to -1
            if (this.newBudgetRequest.planning.maintenancePlanningIds === '') {
                this.hasPlanningItems = false;
                this.newBudgetRequest.planning.maintenancePlanningIds = '-1';
            } else {
                this.hasPlanningItems = true;
            }
        } else {
            this.hasPlanningItems = false;
        }

        if (this.hasPlanningItems) {
            if (this.externalList[index].data.dataToBeMappedInPml.name) {
                this.newBudgetRequest.name = this.externalList[index].data.dataToBeMappedInPml.name;
                this.validateBudgetRequests.name = false;
            } else {
                this.newBudgetRequest.name = '';
            }

            if (this.externalList[index].data.dataToBeMappedInPml.localLanguageName) {
                this.newBudgetRequest.localLanguageName = this.externalList[index].data.dataToBeMappedInPml.localLanguageName;
            } else {
                this.newBudgetRequest.localLanguageName = '';
            }

            this.newBudgetRequest.projectType = this.externalList[index].data.dataToBeMappedInPml.projectType;
            this.validateBudgetRequests.projectType = false;

            this.newBudgetRequest.planning.aeroClassification = this.externalList[index].data.dataToBeMappedInPml.aeroClassification;
            this.validateBudgetRequests.aero = false;

            this.selectedMaintenanceType = this.externalList[index].data.dataToBeMappedInPml.maintenanceType;
            this.validateBudgetRequests.maintenanceType = false;
            this.fillPrimaryDepartment(this.externalList[index].data.dataToBeMappedInPml.aeroClassification.toString());

            if (this.externalList[index].data.dataToBeMappedInPml.justificationOnClassification) {
                this.newBudgetRequest.planning.justificationOnClassification = this.externalList[index].data.dataToBeMappedInPml.justificationOnClassification;
                this.validateBudgetRequests.justification = false;
            } else {
                this.newBudgetRequest.planning.justificationOnClassification = '';
                this.validateBudgetRequests.justification = false;
            }

            this.newBudgetRequest.planning.lumpSum = this.externalList[index].data.dataToBeMappedInPml.lumpSum;
            this.validateBudgetRequests.lumpSum = false;

            this.selectedPlanningAndControllingClassification = this.externalList[index].data.dataToBeMappedInPml.planningAndControllingClassification;
            this.validateBudgetRequests.planning = false;

            this.newBudgetRequest.planning.outageType = this.externalList[index].data.dataToBeMappedInPml.outageType;
            this.validateBudgetRequests.outageType = false;

            this.newBudgetRequest.planning.outageId = this.externalList[index].data.dataToBeMappedInPml.outageIds;
            this.validateBudgetRequests.outageId = false;

            this.newBudgetRequest.planning.forecast = this.externalList[index].data.dataToBeMappedInPml.forecast;
            this.newBudgetRequest.planning.spendUntilEndOfYear = this.externalList[index].data.dataToBeMappedInPml.spentUntil;
            this.newBudgetRequest.planning.new1 = this.externalList[index].data.dataToBeMappedInPml.nextOneYear;
            this.newBudgetRequest.planning.new2 = this.externalList[index].data.dataToBeMappedInPml.nextTwoYears;
            this.newBudgetRequest.planning.new3 = this.externalList[index].data.dataToBeMappedInPml.nextThreeYears;
            this.newBudgetRequest.planning.after = this.externalList[index].data.dataToBeMappedInPml.after;

            if (this.externalList[index].data.dataToBeMappedInPml.startDate) {
                this.newBudgetRequest.planning.startDate = new Date(this.externalList[index].data.dataToBeMappedInPml.startDate);
                this.validateBudgetRequests.startDate = false;
            }
            if (this.externalList[index].data.dataToBeMappedInPml.endDate) {
                this.newBudgetRequest.planning.endDate = new Date(this.externalList[index].data.dataToBeMappedInPml.endDate);
            }
        } else {
            if (this.externalList[index].data.dataToBeMappedInPml.name) {
                this.newBudgetRequest.name = this.externalList[index].data.dataToBeMappedInPml.name;
                this.validateBudgetRequests.name = false;
            } else {
                this.newBudgetRequest.name = '';
            }

            if (this.externalList[index].data.dataToBeMappedInPml.localLanguageName) {
                this.newBudgetRequest.localLanguageName = this.externalList[index].data.dataToBeMappedInPml.localLanguageName;
            } else {
                this.newBudgetRequest.localLanguageName = '';
            }

            this.newBudgetRequest.projectType = this.externalList[index].data.dataToBeMappedInPml.projectType;
            this.validateBudgetRequests.projectType = false;

            this.newBudgetRequest.planning.aeroClassification = this.externalList[index].data.dataToBeMappedInPml.aeroClassification;
            this.validateBudgetRequests.aero = false;

            this.selectedMaintenanceType = this.externalList[index].data.dataToBeMappedInPml.maintenanceType;
            this.validateBudgetRequests.maintenanceType = false;
            this.fillPrimaryDepartment(this.externalList[index].data.dataToBeMappedInPml.aeroClassification.toString());

            if (this.externalList[index].data.dataToBeMappedInPml.justificationOnClassification) {
                this.newBudgetRequest.planning.justificationOnClassification = this.externalList[index].data.dataToBeMappedInPml.justificationOnClassification;
                this.validateBudgetRequests.justification = false;
            } else {
                this.newBudgetRequest.planning.justificationOnClassification = '';
                this.validateBudgetRequests.justification = false;
            }

            this.newBudgetRequest.planning.lumpSum = this.externalList[index].data.dataToBeMappedInPml.lumpSum;
            this.validateBudgetRequests.lumpSum = false;

            this.selectedPlanningAndControllingClassification = this.externalList[index].data.dataToBeMappedInPml.planningAndControllingClassification;
            this.validateBudgetRequests.planning = false;

            this.newBudgetRequest.planning.outageType = this.externalList[index].data.dataToBeMappedInPml.outageType;
            this.validateBudgetRequests.outageType = false;

            if (this.externalList[index].data.dataToBeMappedInPml.startDate) {
                this.newBudgetRequest.planning.startDate = new Date(this.externalList[index].data.dataToBeMappedInPml.startDate);
                this.validateBudgetRequests.startDate = false;
            }
            if (this.externalList[index].data.dataToBeMappedInPml.endDate) {
                this.newBudgetRequest.planning.endDate = new Date(this.externalList[index].data.dataToBeMappedInPml.endDate);
            }
        }

        this.newBudgetRequest.planning.usefulEconomicLife = this.externalList[index].data.uEL ?? 0;

        this.calculateTotal();
        this.clearMappingsWarning();
        this.errorNumber();
        this.$bvToast.show('external-data-copy-toast');
    }

    private riskCopy(index: number) {
        this.currentExternalApplicationType = this.externalInputs[index].type;
        this.newBudgetRequest.planning.maintenancePlanningIds = '';
        this.newBudgetRequest.planning.attachedExternalId = this.externalList[index].data.riskId;

        if (this.externalList[index].data.dataToBeMappedInPml.name) {
            this.newBudgetRequest.name = this.externalList[index].data.dataToBeMappedInPml.name;
            this.validateBudgetRequests.name = false;
        } else {
            this.newBudgetRequest.name = '';
        }

        this.newBudgetRequest.localLanguageName = this.externalList[index].data.dataToBeMappedInPml.nameLocalLanguage;

        this.newBudgetRequest.planning.usefulEconomicLife = this.externalList[index].data.uEL ?? 0;

        this.newBudgetRequest.planning.new1 = this.externalList[index].data.dataToBeMappedInPml.nextMtpYearCost;
        this.newBudgetRequest.planning.aeroClassification = this.externalList[index].data.dataToBeMappedInPml.aeroClassification;
        this.validateBudgetRequests.aero = false;
        this.fillPrimaryDepartment(this.externalList[index].data.dataToBeMappedInPml.aeroClassification.toString());

        this.calculateTotal();
        this.clearMappingsWarning();
        this.errorNumber();
        this.$bvToast.show('external-data-copy-toast');
    }

    private opportunityCopy(index: number) {
        this.currentExternalApplicationType = this.externalInputs[index].type;
        this.newBudgetRequest.planning.maintenancePlanningIds = '';
        this.newBudgetRequest.planning.attachedExternalId = this.externalList[index].data.opportunityId;

        if (this.externalList[index].data.dataToBeMappedInPml.name) {
            this.newBudgetRequest.name = this.externalList[index].data.dataToBeMappedInPml.name;
            this.validateBudgetRequests.name = false;
        } else {
            this.newBudgetRequest.name = '';
        }

        this.newBudgetRequest.localLanguageName = this.externalList[index].data.dataToBeMappedInPml.nameLocalLanguage;

        this.newBudgetRequest.planning.usefulEconomicLife = this.externalList[index].data.uEL ?? 0;

        this.newBudgetRequest.planning.new1 = this.externalList[index].data.dataToBeMappedInPml.nextMtpYearCost;
        this.newBudgetRequest.planning.aeroClassification = this.externalList[index].data.dataToBeMappedInPml.aeroClassification;
        this.validateBudgetRequests.aero = false;
        this.fillPrimaryDepartment(this.externalList[index].data.dataToBeMappedInPml.aeroClassification.toString());

        this.calculateTotal();
        this.clearMappingsWarning();
        this.errorNumber();
        this.$bvToast.show('external-data-copy-toast');
    }

    private clearMappingsWarning() {
        this.validateMappings = {
            namesAreEqual: true,
            nameLocalLanguagesAreEqual: true,
            projectTypesAreEqual: true,
            maintenanceTypesAreEqual: true,
            aeroClassificationsAreEqual: true,
            justificationOnClassificationsAreEqual: true,
            lumpSumsAreEqual: true,
            planningAndControllingClassificationsAreEqual: true,
            outageIdsAreEqual: true,
            outageTypesAreEqual: true,
            forecastsAreEqual: true,
            spentUntilsAreEqual: true,
            nextOneYearsAreEqual: true,
            nextTwoYearsAreEqual: true,
            nextThreeYearsAreEqual: true,
            aftersAreEqual: true,
            totalsAreEqual: true,
            startDatesAreEqual: true,
            endDatesAreEqual: true
        };
        this.mappingWarning = '';
        this.showWarning = false;
    }

    private onClickCancel(value) {
        this.isAccessDeniedModalActive = value;
    }

    private async getPlant(id) {
        this.existPlantId = true;
        this.plant = await this.PlantsService.getPlants(id);
    }

    private get newBrPlanningTotal(): string {
        return this.newBudgetRequest.planning.total.toFixed(3);
    }

    private get planningAndControllingClassificationSortedOptions(): string[] {
        const trailingEntries = ['5', '6'];
        
        const keys = Object.keys(this.data.constants.newPlanningAndControllingClassification).filter(k => !trailingEntries.includes(k));
        return [...keys, ...trailingEntries];
    }

    private get aeroClassificationSortedOptions(): string[] {
        const trailingEntries = ['5'];
        const keys = Object.keys(this.data.constants.aeroClassification)
            .filter(k => !trailingEntries.includes(k));

        return [...keys, ...trailingEntries];
    }

    private handleTagsListChanged(list: Tag[]): void {
        this.tagsList = list;
        this.newBudgetRequest.planning.mtpTags = list;
        this.preventLeavePage.checkPreventLeavePage = true;
    }

    private get isCurrentMtp(): boolean {
        return this.newBudgetRequest?.isActive && this.newBudgetRequest?.planning.year === this.currentYear;
    }

    private get isLastActivePeriod(): boolean {
        return this.lastActiveMtpPeriodYear === this.newBudgetRequest.planning.year;
    }

    private get isTagsViewOnly(): boolean {
        return !((this.userPermissions.includes('BudgetRequestDetailsEdit') && ((this.isCurrentMtp && this.isMtpPeriod) || (this.isLastActivePeriod && this.lastActiveMtpPeriodYear === this.currentYear))) || (this.userPermissions.includes('FocusTopicEdit') && this.isLastActivePeriod && !this.isMtpPeriod));
    }
}
