import { render, staticRenderFns } from "./iybr-new-project-collapsible.vue?vue&type=template&id=1e9f8076&"
import script from "./iybr-new-project-collapsible.ts?vue&type=script&lang=ts&"
export * from "./iybr-new-project-collapsible.ts?vue&type=script&lang=ts&"
import style0 from "./iybr-new-project-collapsible.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports