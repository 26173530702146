var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.data.hasNavigationStructureError ? _c('div', [_vm.canReadSingleSitePage ? _c('div', {
    staticClass: "in-year-budget-requests"
  }, [_c('add-in-year-budget-requests-modal', {
    ref: "inYearBudgetRequestsModal",
    attrs: {
      "selected-year": _vm.selectedYear,
      "show-modal": _vm.showModal
    },
    on: {
      "close-modal": _vm.toggleAddModal
    }
  }), _c('div', {
    staticClass: "in-year-budget-requests__actions"
  }, [_c('div', {
    staticClass: "in-year-budget-requests__actions__filter"
  }, [_c('div', {
    staticClass: "in-year-budget-requests__actions__filter__input"
  }, [_c('plant-selector')], 1), _c('div', {
    staticClass: "in-year-budget-requests__actions__filter__input"
  }, [_c('pui-form-group', {
    attrs: {
      "label": "MTP"
    }
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.selectedYear,
      expression: "selectedYear"
    }],
    staticClass: "form-control",
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.selectedYear = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, _vm.changeMtpPeriod]
    }
  }, _vm._l(_vm.data.sortedMiniFdYearFilters, function (miniFdYearFilter) {
    return _c('option', {
      key: miniFdYearFilter.description,
      domProps: {
        "value": miniFdYearFilter.year
      }
    }, [_vm._v(" " + _vm._s(miniFdYearFilter.description) + " ")]);
  }), 0)])], 1)]), _c('div', {
    staticClass: "in-year-budget-requests__actions__button"
  }, [_c('pui-button', {
    attrs: {
      "state": "primary",
      "icon": "add",
      "disabled": !_vm.isActiveYear
    },
    on: {
      "click": _vm.toggleAddModal
    }
  }, [_vm._v(" Add Change Request ")])], 1)]), !!_vm.data.selectedPlant && (_vm.selectedYear || _vm.selectedYear === 0) ? [_c('in-year-budget-requests-table', {
    attrs: {
      "selected-year": _vm.selectedYear
    }
  })] : [_c('div', {
    staticClass: "load-card"
  }, [_c('pui-loader-spinner', {
    attrs: {
      "message": "Currently loading the table...",
      "title": "Please wait!"
    }
  })], 1)]], 2) : _c('div', [_c('div', {
    staticClass: "container-fluid pt-3"
  }, [_c('access-denied-modal', {
    on: {
      "clickedcanceleditstate": _vm.onAccessDeniedModalClose
    }
  })], 1)])]) : _c('div', [_c('master-data-loader-error')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }