var render = function () {
  var _vm$newFocusTopic$nam;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "accordion-edit mb-4 add-new-focus-topic",
    attrs: {
      "id": "accordionEdit"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header",
    attrs: {
      "id": "heading_accordion_edit"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_c('button', {
    staticClass: "btn",
    attrs: {
      "aria-controls": "collapseEdit",
      "aria-expanded": "true",
      "data-target": "#collapseEdit",
      "data-toggle": "collapse"
    }
  }, [!_vm.isEdit ? [_c('b', [_vm._v(" Add New Focus Topic ")])] : [_c('b', [_vm._v(" " + _vm._s(_vm.editLabel) + " ")])], _c('icon', {
    attrs: {
      "name": "chevron-down"
    }
  })], 2)])])]), _c('div', {
    staticClass: "collapse show",
    attrs: {
      "id": "collapseEdit",
      "aria-labelledby": "heading_accordion_edit",
      "data-parent": "#accordionEdit"
    }
  }, [_c('div', {
    staticClass: "card-body pt-3"
  }, [_c('div', {
    staticClass: "form-row new-focus-topic"
  }, [_c('div', {
    staticClass: "form-group col-lg-12 col-md-12 "
  }, [_c('label', {
    class: '',
    attrs: {
      "for": "name"
    }
  }, [_vm._v(" Name ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.newFocusTopic.name,
      expression: "newFocusTopic.name"
    }],
    staticClass: "form-control",
    attrs: {
      "default-value": (_vm$newFocusTopic$nam = _vm.newFocusTopic.name) !== null && _vm$newFocusTopic$nam !== void 0 ? _vm$newFocusTopic$nam : ''
    },
    domProps: {
      "value": _vm.newFocusTopic.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.newFocusTopic, "name", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "form-group text-center col-lg-12 col-md-12 mb-0 mt-2"
  }, [_c('button', {
    staticClass: "btn btn-outline-primary",
    attrs: {
      "disabled": !_vm.isNameValid,
      "data-dismiss": "modal",
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.clearData();
      }
    }
  }, [_vm._v(" Cancel ")]), _vm.hasPermissionToEdit ? _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "disabled": _vm.isSaveDisabled
    },
    on: {
      "click": function ($event) {
        return _vm.saveFocusTopic();
      }
    }
  }, [_vm._v(" Save ")]) : _vm._e()])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }