import { Connection } from '~/utils/';
import { ExportProjectPrioritizationPayload, UpdateBudgetRequestRankingsResponse } from '~/models/services/admin';
import { ApiResponse, FileResponse } from '~/models/services/common';

export class AdminService {
    private readonly api: Connection = new Connection();

    public async updateBudgetRequestRankings(updateBudgetRequestRankings: FormData): Promise<UpdateBudgetRequestRankingsResponse> {
        return (await this.api.post<ApiResponse<UpdateBudgetRequestRankingsResponse>>('admin/update-budget-ranks', updateBudgetRequestRankings, {
            headers: {
                'Content-Type': 'multipart/form-data; boundary=${data._boundary}'
            }
        })).data.result;
    }

    public async exportProjectPrioritization(excludedGlobalIds: string[]): Promise<FileResponse | undefined> {
        const payload: ExportProjectPrioritizationPayload = { excludedGlobalIds };
        const content = await this.api.post<Blob>('project-prioritization', payload, { responseType: 'blob' });
        const name = content.headers['content-disposition'] ? content.headers['content-disposition'].split('UTF-8\'\'')[1] : 'prioritization-export.xlsx';

        if (content.status === 204) {
            return;
        }

        return {
            name,
            content: content.data
        };
    }
}
