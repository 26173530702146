import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class RemoveFromMtpTableModalComponent extends Vue {
    private selectedUpdate: boolean = false;

    @Prop()
    private data: any;

    @Prop()
    private itemsChecked: any[];

    @Prop()
    private dataBrToModal: any;

    private selectedBR: any[] = [];
    private selectedBRinactive: any[] = [];
    private globalIdsActive: any[] = [];
    private globalIdsInactive: any[] = [];
    private flagActiveRemoveButton: boolean = false;
    private currentYear = new Date(Date.now()).getFullYear();

    constructor() {
        super();
    }

    mounted() {

    }

    public clear() {
        this.selectedBR = [];
        this.selectedBRinactive = [];
        for (let i = 0; i < this.itemsChecked.length; i++) {
            this.selectedBR[i] = false;
            this.selectedBRinactive[i] = true;
        }
        this.$forceUpdate();
    }

    private updateMtp() {
        this.globalIdsActive = [];
        this.globalIdsInactive = [];
        this.selectedUpdate = true;
        this.$forceUpdate();
        if (this.itemsChecked) {
            for (let i = 0; i < this.selectedBR.length; i++) {
                if (this.selectedBR[i] === true) {
                    this.globalIdsActive.push(this.itemsChecked[i].globalId);
                }
            }
            for (let j = 0; j < this.selectedBRinactive.length; j++) {
                if (this.selectedBRinactive[j] === true) {
                    this.globalIdsInactive.push(this.itemsChecked[j].globalId);
                }
            }
            this.$emit('update', { delete: this.globalIdsActive, setToInactive: this.globalIdsInactive });
        }

    }

    private a(items, index) {
        if (this.selectedBRinactive[index]) {
            this.selectedBRinactive[index] = false;
        }
    }

    private b(items, index) {
        if (this.selectedBR[index]) {
            this.selectedBR[index] = false;
        }
    }

    private checkIfSelectedOptions() {
        if (this.selectedBRinactive.includes(true) || this.selectedBR.includes(true)) {
            this.flagActiveRemoveButton = false;
        } else {
            this.flagActiveRemoveButton = true;
        }
    }

}
