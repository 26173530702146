import { Connection } from '~/utils/';
import { ApiResponse } from '~/models/services/common';
import { ActivateFocusTopicRequest, AddFocusTopicRequest, DeleteFocusTopicRequest, EditFocusTopicRequest, FocusTopic, GetFocusTopicResponse, PayloadWithSignal } from '~/models/services/focus-topics';
export class FocusTopicsService {
    private readonly api = new Connection();

    public async getFocusTopics({ searchTerm, isMtp = false, signal }: PayloadWithSignal<{ searchTerm?: string, isMtp?: boolean }>): Promise<FocusTopic[]> {
        const params = new URLSearchParams();

        if (searchTerm !== undefined) {
            params.append('SearchTerm', searchTerm);
        }

        if (isMtp) {
            params.append('IsMtp', isMtp.toString());
        }

        return (await this.api.get<ApiResponse<GetFocusTopicResponse>>(`focus-topic?${params.toString()}`, { signal })).data.result.focusTopicItems.focusTopics;
    }

    public async addFocusTopic(newFocusTopic: AddFocusTopicRequest): Promise<any> {
        return (await this.api.post<ApiResponse<any>>('focus-topic', newFocusTopic)).data.result;
    }

    public async editFocusTopic(newFocusTopic: EditFocusTopicRequest): Promise<any> {
        return (await this.api.put<ApiResponse<any>>('focus-topic', newFocusTopic)).data.result;
    }

    public async deleteFocusTopic(deleteFocusTopic: DeleteFocusTopicRequest): Promise<any> {
        return (await this.api.delete('focus-topic', { data: deleteFocusTopic })).data.result;
    }

    public async activateFocusTopic(activateFocusTopic: ActivateFocusTopicRequest): Promise<any> {
        return (await this.api.post('focus-topic/activate-deactivate', activateFocusTopic)).data.result;
    }
}
