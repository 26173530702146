






import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';

interface LinkCell extends ICellRendererParams {
    href: string,
}

@Component({})
export default class LinkCellComponent extends Vue {
    private params?: LinkCell;

    private get title(): string {
        return this.params.value;
    }

    private get href(): string | undefined {
        return this.params?.href;
    }

    private sendToLink(): void {
        if (!this.href) {
            return;
        }

        this.$router.push({ path: this.href });
    }
}
