var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('pui-lightbox', {
    ref: _vm.REF_CONSTANTS.LIGHTBOX,
    attrs: {
      "on-confirm-callback": _vm.onLightboxConfirm,
      "default-footer-cancel-label": "Cancel",
      "default-footer-confirm-label": "Save Changes",
      "default-header-label": "Edit Budget Compensation"
    },
    on: {
      "puilightbox:open": _vm.onLightboxOpen,
      "puilightbox:before-close": _vm.onLightboxBeforeClose
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function () {
        return [_c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: !_vm.showLoadingSpinner,
            expression: "!showLoadingSpinner"
          }]
        }, [_c('add-compensation-modal', {
          attrs: {
            "current-plant-sid": _vm.currentPlantSid,
            "current-technology-sid": _vm.currentTechnologySid,
            "budget-compensation-type": _vm.selectedBudgetCompensationType,
            "mtp-year": _vm.budgetCompensationMtpYear,
            "show-modal": _vm.showAddCompensationModal
          },
          on: {
            "update:showModal": function ($event) {
              _vm.showAddCompensationModal = $event;
            },
            "update:show-modal": function ($event) {
              _vm.showAddCompensationModal = $event;
            },
            "change:modal-data": _vm.updateBudgetCompensationTableWithRows
          }
        }), _c('pui-form-group', {
          staticClass: "mb-2",
          attrs: {
            "label": "Budget Compensation"
          }
        }, [_c('pui-form-select', {
          attrs: {
            "options": _vm.budgetCompensationTypeValues,
            "label": "Budget Compensation"
          },
          model: {
            value: _vm.selectedBudgetCompensationType,
            callback: function ($$v) {
              _vm.selectedBudgetCompensationType = $$v;
            },
            expression: "selectedBudgetCompensationType"
          }
        })], 1), _c('pui-button', {
          staticClass: "pb-4",
          attrs: {
            "icon": "add",
            "small": ""
          },
          on: {
            "click": _vm.handleNewBudgetCompensationPlanClick
          }
        }, [_vm._v(" Add Budget Compensation Plan ")]), !_vm.budgetCompensationMetadata.isCompensationAvailableExceedingRequired ? _c('pui-form-inline-notification', {
          directives: [{
            name: "pui-form-grid-column",
            rawName: "v-pui-form-grid-column",
            value: _vm.formLayout.FULL_ROW,
            expression: "formLayout.FULL_ROW"
          }],
          staticClass: "error-inline-notification mb-2",
          attrs: {
            "text": "Please adjust your change request such that the compensation available does not exceed the required compensation.",
            "title": "Compensation Available exceeds Compensation Required"
          }
        }) : _vm._e(), !_vm.budgetCompensationMetadata.isCompensatedValueValid ? _c('pui-form-inline-notification', {
          directives: [{
            name: "pui-form-grid-column",
            rawName: "v-pui-form-grid-column",
            value: _vm.formLayout.FULL_ROW,
            expression: "formLayout.FULL_ROW"
          }],
          staticClass: "error-inline-notification mb-2",
          attrs: {
            "text": "The compensation amount should be positive and less or equal to the original value.",
            "title": "Invalid compensation amount"
          }
        }) : _vm._e(), _c('iybr-compensation-table', {
          ref: _vm.REF_CONSTANTS.COMPENSATION_TABLE,
          attrs: {
            "mtp-year": _vm.budgetCompensationMtpYear,
            "currency-code": _vm.plantData.country.currency.code,
            "mini-fd-id": _vm.budgetCompensationMiniFdId
          },
          on: {
            "update:compensation-model": _vm.handleCompensationModelUpdate,
            "update:compensation-totals": _vm.handleCompensationTotalsUpdate
          }
        })], 1), _c('div', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.showLoadingSpinner,
            expression: "showLoadingSpinner"
          }]
        }, [_c('pui-loader-spinner', {
          attrs: {
            "message": "Saving your changes...",
            "min-height": "400px",
            "size": "80px",
            "title": "Please wait!"
          }
        })], 1)];
      },
      proxy: true
    }])
  });
}
var staticRenderFns = []

export { render, staticRenderFns }