import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class DeleteFileModalComponent extends Vue {

    @Prop()
    private fileName: '';

    constructor() {
        super();
    }

    private deleteFile() {
        this.$emit('deletefile', true);
    }
}
