var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('pui-collapsible', {
    staticClass: "iybr-global-ids-collapsible",
    attrs: {
      "expandable": false,
      "headline": "Global IDs",
      "open": ""
    }
  }, [_vm.globalIds.length !== 0 ? _c('pui-tabs', {
    class: [{
      'pt-3': _vm.isMoreThanOneGlobalId
    }, 'px-3'],
    on: {
      "changed": _vm.handleTabChange
    }
  }, _vm._l(_vm.globalIds, function (globalId) {
    return _c('pui-tab', {
      key: globalId,
      attrs: {
        "title": globalId
      }
    });
  }), 1) : _vm._e(), _vm._l(_vm.globalIds, function (globalId) {
    var _vm$getLoadedBudgetRe, _vm$getLoadedBudgetRe2, _vm$getLoadedBudgetRe3, _vm$getLoadedBudgetRe4, _vm$getLoadedBudgetRe5, _vm$getLoadedBudgetRe6, _vm$getLoadedBudgetRe7, _vm$getLoadedBudgetRe8;

    return [_c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.globalIds[_vm.currentTab] === globalId,
        expression: "globalIds[currentTab] === globalId"
      }],
      key: globalId
    }, [_vm.getLoadedBudgetRequest(globalId) ? [_vm.isMoreThanOneGlobalId ? [_c('div', {
      directives: [{
        name: "pui-form-grid-row",
        rawName: "v-pui-form-grid-row"
      }]
    }, [_c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: _vm.formLayout.FULL_ROW,
        expression: "formLayout.FULL_ROW"
      }],
      attrs: {
        "label": "Project Name"
      }
    }, [_c('pui-form-input-field', {
      attrs: {
        "value": _vm.getLoadedBudgetRequest(globalId).name,
        "is-disabled": "",
        "label": "Project Name",
        "type": "text"
      }
    })], 1)], 1), _c('div', {
      directives: [{
        name: "pui-form-grid-row",
        rawName: "v-pui-form-grid-row"
      }]
    }, [_c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: _vm.formLayout.HALF_ROW,
        expression: "formLayout.HALF_ROW"
      }],
      attrs: {
        "label": "WBS (PSP) Element"
      }
    }, [_c('pui-form-input-field', {
      attrs: {
        "value": (_vm$getLoadedBudgetRe = _vm.getLoadedBudgetRequest(globalId).planning) === null || _vm$getLoadedBudgetRe === void 0 ? void 0 : _vm$getLoadedBudgetRe.wbsElement,
        "is-disabled": "",
        "label": "WBS (PSP) Element",
        "type": "text"
      }
    })], 1), _c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: _vm.formLayout.HALF_ROW,
        expression: "formLayout.HALF_ROW"
      }],
      attrs: {
        "label": "AERO Classification"
      }
    }, [_c('pui-form-input-field', {
      attrs: {
        "value": _vm.getValueForConstant('aeroClassification', (_vm$getLoadedBudgetRe2 = _vm.getLoadedBudgetRequest(globalId).planning) === null || _vm$getLoadedBudgetRe2 === void 0 ? void 0 : _vm$getLoadedBudgetRe2.aeroClassification),
        "is-disabled": "",
        "label": "AERO Classification",
        "type": "text"
      }
    })], 1)], 1), _c('div', {
      directives: [{
        name: "pui-form-grid-row",
        rawName: "v-pui-form-grid-row"
      }]
    }, [_c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: _vm.formLayout.HALF_ROW,
        expression: "formLayout.HALF_ROW"
      }],
      attrs: {
        "label": "Risk / Maintenance IDs"
      }
    }, [_c('pui-form-input-field', {
      attrs: {
        "value": (_vm$getLoadedBudgetRe3 = _vm.getLoadedBudgetRequest(globalId).planning) === null || _vm$getLoadedBudgetRe3 === void 0 ? void 0 : _vm$getLoadedBudgetRe3.riskOpportunityId,
        "is-disabled": "",
        "label": "Risk / Maintenance IDs",
        "type": "text"
      }
    })], 1), _c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: _vm.formLayout.QUARTER_ROW,
        expression: "formLayout.QUARTER_ROW"
      }],
      attrs: {
        "label": "Project Start Date"
      }
    }, [_c('pui-form-input-field', {
      attrs: {
        "value": _vm.formatDate((_vm$getLoadedBudgetRe4 = _vm.getLoadedBudgetRequest(globalId).planning) === null || _vm$getLoadedBudgetRe4 === void 0 ? void 0 : _vm$getLoadedBudgetRe4.startDate),
        "is-disabled": "",
        "label": "Project Start Date",
        "type": "date"
      }
    })], 1), _c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: _vm.formLayout.QUARTER_ROW,
        expression: "formLayout.QUARTER_ROW"
      }],
      attrs: {
        "label": "Project End Date"
      }
    }, [_c('pui-form-input-field', {
      attrs: {
        "value": _vm.formatDate((_vm$getLoadedBudgetRe5 = _vm.getLoadedBudgetRequest(globalId).planning) === null || _vm$getLoadedBudgetRe5 === void 0 ? void 0 : _vm$getLoadedBudgetRe5.endDate),
        "is-disabled": "",
        "label": "Project End Date",
        "type": "date"
      }
    })], 1)], 1), _c('div', {
      directives: [{
        name: "pui-form-grid-row",
        rawName: "v-pui-form-grid-row"
      }]
    }, [_c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: _vm.formLayout.HALF_ROW,
        expression: "formLayout.HALF_ROW"
      }],
      attrs: {
        "label": "Planning & Controlling Classification"
      }
    }, [_c('pui-form-input-field', {
      attrs: {
        "value": _vm.getValueForConstant('allPlanningAndControllingClassification', (_vm$getLoadedBudgetRe6 = _vm.getLoadedBudgetRequest(globalId).planning) === null || _vm$getLoadedBudgetRe6 === void 0 ? void 0 : _vm$getLoadedBudgetRe6.planningAndControllingClassification),
        "is-disabled": "",
        "label": "Planning & Controlling Classification",
        "type": "text"
      }
    })], 1), _c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: _vm.formLayout.QUARTER_ROW,
        expression: "formLayout.QUARTER_ROW"
      }],
      attrs: {
        "label": "Uniper Share of Budget"
      }
    }, [_c('pui-form-input-field', {
      attrs: {
        "value": ((_vm$getLoadedBudgetRe7 = _vm.getLoadedBudgetRequest(globalId).planning) === null || _vm$getLoadedBudgetRe7 === void 0 ? void 0 : _vm$getLoadedBudgetRe7.uniperShareOfBudget) + '%',
        "is-disabled": "",
        "label": "Uniper Share of Budget",
        "type": "text"
      }
    })], 1), _c('pui-form-group', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: _vm.formLayout.QUARTER_ROW,
        expression: "formLayout.QUARTER_ROW"
      }],
      attrs: {
        "label": "Status"
      }
    }, [_c('pui-form-input-field', {
      attrs: {
        "value": _vm.getValueForConstant('mtpStatus', (_vm$getLoadedBudgetRe8 = _vm.getLoadedBudgetRequest(globalId).planning) === null || _vm$getLoadedBudgetRe8 === void 0 ? void 0 : _vm$getLoadedBudgetRe8.mtpStatus).name,
        "is-disabled": "",
        "label": "Status",
        "type": "text"
      }
    })], 1)], 1)] : _vm._e(), _c('pui-table', {
      class: {
        'pt-4': _vm.isMoreThanOneGlobalId
      },
      attrs: {
        "vendor-options": _vm.tableOptions[globalId],
        "columns-are-center-aligned": "",
        "first-column-is-left-aligned": ""
      },
      scopedSlots: _vm._u([_vm._l(Object.values(_vm.columns), function (columnName) {
        return {
          key: columnName,
          fn: function (data) {
            return [_c('div', {
              key: columnName
            }, [_vm.shouldDisplayInputField(data) ? [_c('input', {
              directives: [{
                name: "model",
                rawName: "v-model",
                value: _vm.formData[globalId][_vm.formDataMapping[columnName]],
                expression: "formData[globalId][formDataMapping[columnName]]"
              }, {
                name: "mask",
                rawName: "v-mask",
                value: _vm.numberInputMask,
                expression: "numberInputMask"
              }],
              class: _vm.cellValueClass(_vm.formData[globalId][_vm.formDataMapping[columnName]]),
              attrs: {
                "type": "text"
              },
              domProps: {
                "value": _vm.formData[globalId][_vm.formDataMapping[columnName]]
              },
              on: {
                "input": function ($event) {
                  if ($event.target.composing) { return; }

                  _vm.$set(_vm.formData[globalId], _vm.formDataMapping[columnName], $event.target.value);
                }
              }
            })] : [_c('span', {
              class: _vm.cellValueClass(data.row[columnName])
            }, [_vm._v(" " + _vm._s(_vm.tableCellTextFormat(data.row[columnName])) + " ")])]], 2)];
          }
        };
      })], null, true)
    })] : [_c('pui-loader-spinner', {
      attrs: {
        "message": "Currently loading this Budget Request...",
        "min-height": "400px",
        "size": "80px",
        "title": "Please wait!"
      }
    })]], 2)];
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }