var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('pui-carousel', {
    attrs: {
      "has-shadow": false,
      "items-per-slide": 1,
      "show-navigation": false,
      "visible-items": 1,
      "pagination-position": "bottom"
    }
  }, _vm._l(_vm.externalData, function (data) {
    return _c('div', {
      key: (data === null || data === void 0 ? void 0 : data.riskId) || (data === null || data === void 0 ? void 0 : data.opportunityId) || (data === null || data === void 0 ? void 0 : data.maintenanceId),
      attrs: {
        "slot": "items"
      },
      slot: "items"
    }, [(data === null || data === void 0 ? void 0 : data.riskId) !== undefined ? _c('linked-risk-collapsible', {
      attrs: {
        "data": data
      }
    }) : (data === null || data === void 0 ? void 0 : data.opportunityId) !== undefined ? _c('linked-opportunity-collapsible', {
      attrs: {
        "data": data
      }
    }) : (data === null || data === void 0 ? void 0 : data.maintenanceId) !== undefined ? _c('linked-maintenance-collapsible', {
      attrs: {
        "data": data
      }
    }) : _vm._e()], 1);
  }), 0);
}
var staticRenderFns = []

export { render, staticRenderFns }