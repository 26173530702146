import { Permission, PermissionsHelper } from '~/utils/permission';
import { Data } from '~/utils/data';
import { LocalStorageHelper } from '@coode/fe-sdk';
import { ExtendedUserPermissions } from '~/utils/interfaces';
import Vue from "vue"

export const ROLES = {
    ADMIN: 'Admin',
    SITEUSER: 'SiteUser',
    USER: 'User'
} as const;

export class User {
    public static get canReadSingleSitePage(): boolean {
        return this.hasPermission(Permission.SINGLE_SITE_PAGE_READ);
    }

    public static async getUserData(): Promise<ExtendedUserPermissions> {
        return await LocalStorageHelper.getPermissionsLocalStorage() as ExtendedUserPermissions;
    }

    public static async getPerm(): Promise<Permission[]> {
        const userData = await this.getUserData();
        return PermissionsHelper.parsePermissionInclusions(userData.permissions.map(p => p.name) as Permission[]);
    }

    public static hasPermission(permission: Permission): boolean {
        const userPermissions = Data.Instance.userPermissions;

        if (userPermissions.includes(Permission.ACCESS_ALL)) {
            return true;
        }

        return userPermissions.includes(permission);
    }

    public static async loadUserPermissionsIntoData(): Promise<void> {
        Data.Instance.userPermissions = await this.getPerm();
    }

    public static async signOut(): Promise<void> {
        return Vue.prototype.$sdk.$authenticator.logout();
    }
}
