var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('pui-form-type-ahead', {
    attrs: {
      "is-loading": _vm.isLoading,
      "is-valid": _vm.isValid,
      "is-disabled": _vm.isDisabled,
      "options": _vm.searchResults,
      "use-local-cache": false,
      "label": "user-input"
    },
    on: {
      "abort": _vm.clearSearchResults,
      "change": _vm.userChanged,
      "search": _vm.fetchUsers
    },
    model: {
      value: _vm.userInput,
      callback: function ($$v) {
        _vm.userInput = $$v;
      },
      expression: "userInput"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }