import { FormValidationFn, NoContextFormValidationFn } from './form';

const INPUT_SELECTOR_INITIAL_VALUE = 'N/A';

export const selectorChangedValidationFn: NoContextFormValidationFn<any> = (input): string | undefined => {
    if (input === INPUT_SELECTOR_INITIAL_VALUE) {
        return 'Please select an option.'
    }
};

export const dateValidationFn: NoContextFormValidationFn<any> = (input): string | undefined => {
    const date = new Date(input);
    if (!(date instanceof Date && !isNaN(date.getTime()))) {
        return 'Invalid Date.'
    }
};

export const objectNotNullOrUndefinedValidationFn: NoContextFormValidationFn<any> = (input): string | undefined => {
    if (input === null || input === undefined) {
        return 'Please select an option.'
    }
};

export const stringNotEmptyValidationFn: NoContextFormValidationFn<string | undefined> = (input): string | undefined => {
    if (input === null || input === undefined || input.trim() === '') {
        return 'Field cannot be empty.'
    }
};

export const numberNotNaNValidationFn: NoContextFormValidationFn<number | undefined> = (input): string | undefined => {
    if (input && isNaN(input)) {
        return 'Please enter a number.';
    }
};

export const numberNotEmptyValidationFn: NoContextFormValidationFn<number | undefined> = (input): string | undefined => {
    if (input === null || input === undefined || (typeof input === 'string' && (input as string).trim() === '')) {
        return 'Please enter a number.';
    }
};

export const numberNotInRangeValidationFn: (lowerBound: number, upperBound: number) => NoContextFormValidationFn<number> = (lowerBound: number, upperBound: number) => {
    return (input): string | undefined => {
        if (input < lowerBound || input > upperBound) {
            return `Value has to be greater than ${lowerBound} and less than ${upperBound}.`;
        }
    };
};

export const createStringLengthValidationFn: (lowerBound: number, upperBound: number) => NoContextFormValidationFn<string> = (lowerBound: number, upperBound: number) => {
    return (input): string | undefined => {
        if (input.length > upperBound) {
            return `Please enter less than ${upperBound} characters.`;
        }

        if (input.length < lowerBound) {
            return `Please enter more than ${lowerBound} characters.`;
        }
    };
};

export const arrayNotEmptyValidationFn: NoContextFormValidationFn<any[]> = (input): string | undefined => {
    if (input.length === 0) {
        return 'Please select an option.';
    }
};

export const combineValidationFns = <T, C>(...validationFns: FormValidationFn<T, C>[]): FormValidationFn<T, C> => {
    return (input, context) => {
        for (const validationFn of validationFns) {
            const result = validationFn(input, context);

            if (result) {
                return result;
            }
        }
    };
};
