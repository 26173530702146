import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { Data, User } from '~/utils';
import CurrencyRatesTable from './currency-rates/currency-rates.vue';
import HurdleRatesTable from './hurdle-rates/hurdle-rates.vue';

enum RATES_DASHBOARD_TAB {
    CURRENCY_RATES = 0,
    HURDLE_RATES = 1,
}

@Component({
    components: {
        CurrencyRatesTable,
        HurdleRatesTable,
    }
})
export default class RatesDashboardComponent extends Vue {
    private userPermissions = <any>[];
    private isAccessDeniedModalActive: boolean = false;
    private data: Data = Data.Instance;

    private RATES_DASHBOARD_TAB = RATES_DASHBOARD_TAB;
    private selectedTabIndex: RATES_DASHBOARD_TAB = RATES_DASHBOARD_TAB.CURRENCY_RATES;

    async created() {
        this.userPermissions = await User.getPerm();
        if (!this.hasPermission) {
            $('#accessDeniedModal').modal('show');
            this.isAccessDeniedModalActive = true;
        }
    }

    private get hasPermission(): boolean {
        return this.userPermissions.includes('MtpPeriodsEdit');
    }

    private onClickCancel(value) {
        this.isAccessDeniedModalActive = value;
    }

    private onTabChanged(tabIndex: number): void {
        this.selectedTabIndex = tabIndex;
    }
}
