import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { ROWS } from '~/components/in-year-budget-requests/constants/rows';
import { Data } from '~/utils';
import moment from 'moment';
import { MtpStatus } from '~/utils/interfaces';
import { PuiTableVendorOptions } from '~/models/libraries/pebble-ui';
import { BudgetRequestMiniFinancialDecision } from '~/models/services/minifd';

@Component({})
export default class ViewGlobalIdsCollapsibleComponent extends Vue {
    @Prop()
    linkedBudgetRequests?: BudgetRequestMiniFinancialDecision[];

    @Prop()
    currencyName?: string;

    private readonly formLayout = ROWS;

    private currentTab = 0;

    private get columns(): Record<string, string> {
        return {
            title: `Currency: ${this.currencyName}`,
            'MTP-1': `${this.linkedBudgetRequests[this.currentTab].year - 1}`,
            MTP: `${this.linkedBudgetRequests[this.currentTab].year}`,
            previousYears: `Before ${this.linkedBudgetRequests[this.currentTab].year + 1}`,
            'MTP+1': `${this.linkedBudgetRequests[this.currentTab].year + 1}`,
            'MTP+2': `${this.linkedBudgetRequests[this.currentTab].year + 2}`,
            'MTP+3': `${this.linkedBudgetRequests[this.currentTab].year + 3}`,
            ff: 'FF',
            total: 'Total',
        };
    }

    private get shownColumns(): string[] {
        const hiddenColumns = ['MTP-1', 'MTP'];

        return Object.keys(this.columns)
            .filter(key => !hiddenColumns.includes(key))
            .map(key => this.columns[key]);
    }

    private get tableOptions(): PuiTableVendorOptions {
        const mtpData = this.linkedBudgetRequests[this.currentTab];

        let budgetInMtpStatus;

        switch (mtpData.budgetRequest?.planning?.mtpStatus) {
            case MtpStatus.APPROVED:
                budgetInMtpStatus = '(Approved)';
                break;
            case MtpStatus.FLAGGED:
                budgetInMtpStatus = '(Flagged)';
                break;
            default:
                budgetInMtpStatus = '';
        }

        const previousYearsBudgetInMtpSum = mtpData?.budgetRequest?.planning
            ? mtpData.budgetRequest.planning.spendUntilEndOfYear + mtpData.budgetRequest.planning.forecast
            : 0;

        const budgetInMtpRow = {
            [this.columns['title']]: `Budget in MTP ${budgetInMtpStatus}`,
            [this.columns['MTP-1']]: mtpData?.budgetRequest?.planning?.spendUntilEndOfYear ?? 0,
            [this.columns['MTP']]: mtpData?.budgetRequest?.planning?.forecast ?? 0,
            [this.columns['previousYears']]: previousYearsBudgetInMtpSum,
            [this.columns['MTP+1']]: mtpData?.budgetRequest?.planning?.new1 ?? 0,
            [this.columns['MTP+2']]: mtpData?.budgetRequest?.planning?.new2 ?? 0,
            [this.columns['MTP+3']]: mtpData?.budgetRequest?.planning?.new3 ?? 0,
            [this.columns['ff']]: mtpData?.budgetRequest?.planning?.after ?? 0,
            [this.columns['total']]: mtpData?.budgetRequest?.planning?.total ?? 0,
        };

        const previousYearsTotalBudgetSum = mtpData ? mtpData?.spentYearMinus1 + mtpData?.spentYear : 0;

        const totalBudgetRequired = {
            [this.columns['title']]: 'Total Budget Required',
            [this.columns['MTP-1']]: mtpData?.spentYearMinus1 ?? 0,
            [this.columns['MTP']]: mtpData?.spentYear ?? 0,
            [this.columns['previousYears']]: previousYearsTotalBudgetSum,
            [this.columns['MTP+1']]: mtpData?.spentYearPlus1 ?? 0,
            [this.columns['MTP+2']]: mtpData?.spentYearPlus2 ?? 0,
            [this.columns['MTP+3']]: mtpData?.spentYearPlus3 ?? 0,
            [this.columns['ff']]: mtpData?.spentAfterYear ?? 0,
            [this.columns['total']]: mtpData?.total ?? 0,
        };

        return {
            data: [budgetInMtpRow, totalBudgetRequired],
            options: {
                sortable: [],
            },
            columns: Object.values(this.shownColumns),
        };
    }

    private get budgetRequestHasPlanning(): boolean {
        if (this.linkedBudgetRequests && this.linkedBudgetRequests[0].budgetRequest.planning) {
            return true;
        }

        return false;
    }

    private handleTabChange(newTabIndex: number): void {
        this.currentTab = newTabIndex;
    }

    private getValueForConstant(constantType: string, index?: string | number): string {
        if (!index) {
            return 'N/A';
        }

        const constantValue = Data.Instance.constants[constantType][index];

        if (!constantValue) {
            return 'Unknown';
        }

        return constantValue;
    }

    private formatDate(date: string): string {
        if (date) {
            return moment(date).format('YYYY-MM-DD');
        } else {
            return '';
        }
    }
}
