import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { MasterService } from '~/services/master-service';
import { ROWS } from '~/components/in-year-budget-requests/constants/rows';
import { WorkflowActionIds } from './workflow-action-ids';
import { MiniFdReview } from '~/models/services/minifd';

@Component({})

export default class ApprovalCollapsibleComponent extends Vue {
    @Prop()
    viewIybrId: string;
    @Prop()
    workflowInstanceId: string;

    private readonly PAGE_LAYOUT = ROWS;
    private readonly HEADER = 'Review change request';
    private readonly WORKFLOW_ACTIONS = WorkflowActionIds;
    private readonly miniFdService = MasterService.instance.miniFdService;
    private readonly INVALID_COMMENT = 'You must leave a commment for this decision.';

    private miniFdReview: MiniFdReview = null;
    private reviewComment: string = null;
    private isReviewValid = true;
    private isSubmittingReview = false;

    private reviewMiniFd(actionId: number): void {
        this.isSubmittingReview = true;
        this.miniFdReview = {
            workflowActionId: actionId,
            content: {
                UserComment: this.reviewComment
            }
        };
        if (this.miniFdReview.workflowActionId === WorkflowActionIds.REJECT
            && !this.reviewComment) {
            this.isReviewValid = false;
            this.isSubmittingReview = false;
        } else {
            this.miniFdService.continueWorkflow(
                this.miniFdReview, this.workflowInstanceId, this.viewIybrId).then(() => {
                if (this.miniFdReview.workflowActionId === WorkflowActionIds.APPROVE) {
                    this.$bvToast.show('toast-approved-minifd');
                } else {
                    this.$bvToast.show('toast-rejected-minifd');
                }
                this.$emit('has-reviewed-mini-fd');
                this.reviewComment = '';
            }).catch(() => {
                this.isSubmittingReview = false;
                this.$bvToast.show('toast-error-submit-approval-workflow-minifd');
            });
        }
    }
}
