var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.hasPermissionToEdit ? _c('div', {
    staticClass: "focus-topics"
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "can-cancel": true
    },
    on: {
      "update:active": function ($event) {
        _vm.loading = $event;
      }
    }
  }), _c('b-toast', {
    attrs: {
      "id": "toast-focus-topic-success",
      "auto-hide-delay": "20000",
      "no-close-button": "",
      "toaster": "b-toaster-bottom-center max-width-toast-update-mtp"
    }
  }, [_c('div', {
    staticClass: "toast-align"
  }, [_c('div', [_c('icon', {
    staticClass: "icon-toast text-center",
    staticStyle: {
      "min-width": "40px"
    },
    attrs: {
      "name": "check"
    }
  })], 1), _c('div', [_c('span', [_vm._v("Focus Topic saved successfully.")])]), _c('button', {
    staticClass: "btn btn-outline-primary ml-3",
    on: {
      "click": function ($event) {
        return _vm.hideToast();
      }
    }
  }, [_vm._v(" Dismiss ")])])]), _c('div', {
    staticClass: "container-fluid pt-3 pb-3 pl-lg-5"
  }, [_c('div', {
    staticClass: "row justify-content-start"
  }, [_c('div', {
    staticClass: "col-lg-10 col-12 offset-lg-1 pt-3"
  }, [_c('h3', [_vm._v("Focus Topics")]), _vm.error.length > 0 ? _c('p', {
    staticStyle: {
      "color": "#dc3545"
    }
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")]) : _vm._e()])]), _c('div', {
    staticClass: "row justify-content-start mb-2"
  }, [_c('div', {
    staticClass: "col-xl-5 col-md-6 offset-lg-1 col-12 order-md-1 order-2 pt-3"
  }, [_c('add-new-focus-topic', {
    ref: _vm.REF_CONSTANTS.ADD_NEW_FOCUS_TOPIC,
    attrs: {
      "edit-f-t": _vm.editFT,
      "has-permission-to-edit": _vm.hasPermissionToEdit
    },
    on: {
      "update:editFT": [function ($event) {
        _vm.editFT = $event;
      }, _vm.clearEditFT],
      "update:edit-f-t": function ($event) {
        _vm.editFT = $event;
      },
      "errorChanged": _vm.handleErrorChanged,
      "loadingChanged": _vm.handleLoadingChanged,
      "focusTopicSaved": _vm.handleFocusTopicSaved
    }
  })], 1)]), _c('div', {
    staticClass: "row justify-content-start mb-4"
  }, [_c('div', {
    staticClass: "col-lg-10 offset-lg-1"
  }, [_c('view-focus-topics', {
    attrs: {
      "has-permission-to-edit": _vm.hasPermissionToEdit,
      "loading": _vm.loading,
      "focus-topics": _vm.focusTopics
    },
    on: {
      "editFTChanged": _vm.handleIdChanged,
      "loadingChanged": _vm.handleLoadingChanged,
      "reloadData": _vm.handleReloadData,
      "errorChanged": _vm.handleErrorChanged
    }
  })], 1)])])], 1) : _c('div', [_c('div', {
    staticClass: "container-fluid pt-3"
  }, [_c('access-denied-modal', {
    on: {
      "clickedcanceleditstate": _vm.onClickCancel
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }