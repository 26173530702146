var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.userPermissions.includes('SingleSitePageView') ? _c('div', {
    staticClass: "activity-tracking"
  }, [_c('loading', {
    attrs: {
      "active": _vm.loading,
      "can-cancel": true
    },
    on: {
      "update:active": function ($event) {
        _vm.loading = $event;
      }
    }
  }), _vm.data.navBarRefresh === false ? _c('div', {
    staticClass: "container-fluid pt-3 pl-lg-5"
  }, [_vm.data ? _c('div', {
    staticClass: "row justify-content-center mb-4"
  }, [_c('div', {
    staticClass: "col-10 breadcrumbitem pb-4"
  }, [_c('p', [_c('span', {
    staticClass: "mtp-color pointerLink",
    on: {
      "click": function ($event) {
        return _vm.goToBrMtpYear(_vm.selectedMtp);
      }
    }
  }, [_vm._v("MTP " + _vm._s(_vm.selectedMtp) + " ")]), _c('icon', {
    staticClass: "icon-right",
    attrs: {
      "name": "chevron-right"
    }
  }), _vm._v(" Activity tracking ")], 1)])]) : _vm._e(), _c('div', {
    staticClass: "row justify-content-center mb-3"
  }, [_c('div', {
    staticClass: "col-lg-10"
  }, [_vm.searchModel.filters ? _c('div', {
    staticClass: "history-filters"
  }, [_c('div', {
    directives: [{
      name: "clickoutside",
      rawName: "v-clickoutside",
      value: _vm.hide,
      expression: "hide"
    }],
    staticClass: "filters mr-2"
  }, [_c('button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "btn btn-primary btn-filter",
    class: [_vm.searchModel.filters.date !== null || _vm.searchModel.filters.range ? 'active-filter' : '', _vm.searchModel.filters.range ? 'filterDate' : ''],
    attrs: {
      "title": _vm.searchModel.filters.date !== null ? _vm.nameOfDateFilter('date', 'activityTrackingDateFilterTypes') : '',
      "data-toggle": "dropdown"
    },
    on: {
      "click": function ($event) {
        return _vm.clearFilterSelected('selectedDate', 'date', 'true');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.searchModel.filters.date !== null || _vm.searchModel.filters.range ? '' : 'Date') + " "), _vm.searchModel.filters.date !== null ? [_vm._v(" " + _vm._s(_vm.data.constants.activityTrackingDateFilterTypes[_vm.searchModel.filters.date]) + " ")] : _vm._e(), _vm.searchModel.filters.range && _vm.searchModel.filters.range.startDate ? [_vm._v(" " + _vm._s(_vm.displayStartDate()) + " "), _vm.searchModel.filters.range && _vm.searchModel.filters.range.endDate ? [_vm._v(" ~ " + _vm._s(_vm.displayEndDate()) + " ")] : _vm._e()] : _vm._e()], 2), _c('div', {
    attrs: {
      "id": "dropdown-activity-types"
    }
  }, [_vm.showDropdown ? _c('div', {
    class: ['dropdown-menu', 'style-dropdown-filter', 'show']
  }, [_c('label', {
    staticClass: "label-date",
    attrs: {
      "for": "start_date"
    }
  }, [_vm._v("Start Date ")]), _c('datepicker', {
    attrs: {
      "disabled": _vm.disabledDates,
      "disabled-date": _vm.notAfterEndDate,
      "open": _vm.openTimePanelStartDate,
      "show-time-panel": _vm.showTimePanel,
      "format": "DD/MM/YYYY HH:mm:ss",
      "input-class": "form-control",
      "placeholder": "dd/mm/yyyy",
      "type": "datetime"
    },
    on: {
      "change": _vm.handleCloseDate,
      "close": _vm.handleCloseDate,
      "focus": function ($event) {
        return _vm.toggleDatePanelStartDate();
      }
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('button', {
          staticClass: "mx-btn mx-btn-text",
          on: {
            "click": _vm.toggleTimePanel
          }
        }, [_vm._v(" " + _vm._s(_vm.showTimePanel ? 'select date' : 'select time') + " ")]), _c('button', {
          staticClass: "mx-btn mx-btn-text btn-date",
          on: {
            "click": _vm.toggleDatePanelStartDate
          }
        }, [_vm._v(" OK ")])];
      },
      proxy: true
    }], null, false, 580174757),
    model: {
      value: _vm.startDate,
      callback: function ($$v) {
        _vm.startDate = $$v;
      },
      expression: "startDate"
    }
  }), _c('label', {
    staticClass: "label-date",
    attrs: {
      "for": "end_date"
    }
  }, [_vm._v("End Date ")]), _c('datepicker', {
    attrs: {
      "disabled": _vm.disabledDates,
      "disabled-date": _vm.notBeforeStartDate,
      "open": _vm.openTimePanelEndDate,
      "show-time-panel": _vm.showTimePanel,
      "format": "DD/MM/YYYY HH:mm:ss",
      "input-class": "form-control",
      "placeholder": "dd/mm/yyyy",
      "type": "datetime"
    },
    on: {
      "change": _vm.handleCloseDate,
      "close": _vm.handleCloseDate,
      "focus": function ($event) {
        return _vm.toggleDatePanelEndDate();
      }
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function () {
        return [_c('button', {
          staticClass: "mx-btn mx-btn-text",
          on: {
            "click": _vm.toggleTimePanel
          }
        }, [_vm._v(" " + _vm._s(_vm.showTimePanel ? 'select date' : 'select time') + " ")]), _c('button', {
          staticClass: "mx-btn mx-btn-text btn-date",
          on: {
            "click": _vm.toggleDatePanelEndDate
          }
        }, [_vm._v(" OK ")])];
      },
      proxy: true
    }], null, false, 3041381706),
    model: {
      value: _vm.endDate,
      callback: function ($$v) {
        _vm.endDate = $$v;
      },
      expression: "endDate"
    }
  }), _vm._l(_vm.data.constants.activityTrackingDateFilterTypes, function (obj, key) {
    return _c('label', {
      key: key,
      staticClass: "dropdown-item",
      on: {
        "click": function ($event) {
          $event.stopPropagation();
        }
      }
    }, [_c('label', {
      staticClass: "container"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedDate,
        expression: "selectedDate"
      }],
      attrs: {
        "type": "radio"
      },
      domProps: {
        "value": key,
        "checked": _vm._q(_vm.selectedDate, key)
      },
      on: {
        "change": [function ($event) {
          _vm.selectedDate = key;
        }, function ($event) {
          return _vm.activeButtonApply();
        }]
      }
    }), _c('span', {
      staticClass: "checkmark"
    })]), _vm._v(" " + _vm._s(obj) + " ")]);
  }), _c('div', {
    staticClass: "dropdown-divider"
  }), _c('div', {
    staticClass: "section-button"
  }, [_c('button', {
    staticClass: "btn btn-link",
    on: {
      "click": [function ($event) {
        return _vm.clearDateFilter('selectedDate');
      }, function ($event) {
        $event.stopPropagation();
      }]
    }
  }, [_vm._v(" Clear ")]), _c('button', {
    staticClass: "btn btn-link",
    attrs: {
      "disabled": !_vm.activeButtonApplyFilter
    },
    on: {
      "click": function ($event) {
        return _vm.applyDateFilter();
      }
    }
  }, [_vm._v(" Apply ")])])], 2) : _vm._e()])]), _c('div', {
    staticClass: "filters"
  }, [_c('button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "btn btn-primary btn-filter",
    class: _vm.searchModel.filters.activityTypes && _vm.searchModel.filters.activityTypes.length !== 0 ? 'active-filter' : '',
    attrs: {
      "title": _vm.searchModel.filters.activityTypes.length !== 0 ? _vm.nameOfFilters('activityTypes', 'activityTypes', null) : '',
      "data-toggle": "dropdown"
    },
    on: {
      "click": function ($event) {
        return _vm.clearFilterSelected('selectedActivityTypes', 'activityTypes');
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.searchModel.filters.activityTypes && _vm.searchModel.filters.activityTypes.length !== 0 ? '' : 'Activity type') + " "), _vm.searchModel.filters.activityTypes ? [_vm._l(_vm.searchModel.filters.activityTypes, function (name) {
    return [_vm._v(" " + _vm._s(_vm.data.constants.activityTypes[name]) + " ")];
  })] : _vm._e()], 2), _c('div', {
    attrs: {
      "id": "#dropdown-activity-types"
    }
  }, [_c('div', {
    staticClass: "dropdown-menu style-dropdown-filter"
  }, [_vm._l(_vm.data.constants.activityTypes, function (obj, key) {
    return _c('label', {
      key: key,
      staticClass: "dropdown-item",
      on: {
        "click": function ($event) {
          $event.stopPropagation();
        }
      }
    }, [_c('label', {
      staticClass: "container"
    }, [_c('input', {
      directives: [{
        name: "model",
        rawName: "v-model",
        value: _vm.selectedActivityTypes,
        expression: "selectedActivityTypes"
      }],
      attrs: {
        "type": "checkbox"
      },
      domProps: {
        "value": key,
        "checked": Array.isArray(_vm.selectedActivityTypes) ? _vm._i(_vm.selectedActivityTypes, key) > -1 : _vm.selectedActivityTypes
      },
      on: {
        "change": [function ($event) {
          var $$a = _vm.selectedActivityTypes,
              $$el = $event.target,
              $$c = $$el.checked ? true : false;

          if (Array.isArray($$a)) {
            var $$v = key,
                $$i = _vm._i($$a, $$v);

            if ($$el.checked) {
              $$i < 0 && (_vm.selectedActivityTypes = $$a.concat([$$v]));
            } else {
              $$i > -1 && (_vm.selectedActivityTypes = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
            }
          } else {
            _vm.selectedActivityTypes = $$c;
          }
        }, function ($event) {
          return _vm.activeButtonApply();
        }]
      }
    }), _c('span', {
      staticClass: "checkmark"
    })]), _vm._v(" " + _vm._s(obj) + " ")]);
  }), _c('div', {
    staticClass: "dropdown-divider"
  }), _c('div', {
    staticClass: "section-button"
  }, [_c('button', {
    staticClass: "btn btn-link",
    on: {
      "click": [function ($event) {
        return _vm.clearFilter('selectedActivityTypes');
      }, function ($event) {
        $event.stopPropagation();
      }]
    }
  }, [_vm._v(" Clear ")]), _c('button', {
    staticClass: "btn btn-link",
    attrs: {
      "disabled": !_vm.activeButtonApplyFilter
    },
    on: {
      "click": function ($event) {
        return _vm.applyActivityFilter();
      }
    }
  }, [_vm._v(" Apply ")])])], 2)])]), _c('div', {
    staticClass: "filters"
  }, [_c('button', {
    staticClass: "btn",
    on: {
      "click": function ($event) {
        return _vm.resetFilters();
      }
    }
  }, [_vm._v(" Clear all ")])]), _c('div', {
    staticClass: "filters filters-search"
  }, [_c('div', {
    staticClass: "input-group"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.globalIdSearch,
      expression: "globalIdSearch"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.globalIdSearch
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.searchByGlobalId();
      },
      "input": function ($event) {
        if ($event.target.composing) { return; }
        _vm.globalIdSearch = $event.target.value;
      }
    }
  }), _c('div', {
    staticClass: "input-group-append"
  }, [_c('button', {
    staticClass: "btn btn-outline-secondary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.searchByGlobalId();
      }
    }
  }, [_c('icon', {
    attrs: {
      "name": "search"
    }
  })], 1)])])])]) : _vm._e()])]), _c('div', {
    staticClass: "row justify-content-center mb-3"
  }, [_c('div', {
    staticClass: "col-lg-10"
  }, [_c('div', {
    staticClass: "accordion-history mb-4",
    attrs: {
      "id": "accordion"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "budget-request-wrapper"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "row"
  }, [_vm.history ? _c('table', {
    staticClass: "table"
  }, [!_vm.loading ? _c('thead', [_vm._m(1)]) : _vm._e(), !_vm.loading ? _c('tbody', _vm._l(_vm.history.plantActivityTrackings, function (el, key) {
    return _c('tr', {
      key: key
    }, [_c('td', [_vm._v(_vm._s(_vm.firstElementTable + key))]), _c('td', [_vm.userPermissions.includes('BudgetRequestDetailsView') ? _c('router-link', {
      attrs: {
        "disabled": _vm.userPermissions.includes('BudgetRequestDetailsView'),
        "to": {
          path: 'details-page',
          query: {
            year: parseInt(el.year),
            globalId: el.globalId
          }
        }
      }
    }, [_vm._v(" " + _vm._s(el.globalId) + " ")]) : _c('a', [_vm._v(_vm._s(el.globalId))])], 1), _c('td', [_vm._v(_vm._s(el.date))]), _c('td', [_vm._v(_vm._s(el.kId))]), _c('td', [_vm._v(_vm._s(el.activityType))])]);
  }), 0) : _vm._e()]) : _vm._e(), !_vm.history.plantActivityTrackings ? _c('div', {
    staticClass: "table pl-3 pt-3 pb-3"
  }, [_vm._v(" No activity trackings found ")]) : _vm._e(), _vm.history.plantActivityTrackings ? _c('div', {
    staticClass: "mr-auto pl-3 pt-2"
  }, [_vm._v(" Show "), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.elementsPerPage,
      expression: "elementsPerPage",
      modifiers: {
        "number": true
      }
    }],
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return _vm._n(val);
        });
        _vm.elementsPerPage = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, function ($event) {
        return _vm.reloadTableChangeNumberElement();
      }]
    }
  }, [_c('option', {
    attrs: {
      "selected": ""
    }
  }, [_vm._v(" 10 ")]), _c('option', [_vm._v("15")]), _c('option', [_vm._v("30")]), _c('option', [_vm._v("50")])]), _c('div', {
    staticClass: "show-elements"
  }, [_vm._v(" Showing " + _vm._s(_vm.firstElementTable) + " to " + _vm._s(_vm.numberOfElementTable) + " of " + _vm._s(_vm.history.total) + " activity trackings ")])]) : _vm._e(), _vm.history.plantActivityTrackings ? _c('div', {
    staticClass: "pagination ml-auto pr-3 pt-2"
  }, [_vm.currentPage != 1 ? _c('div', {
    staticClass: "arrow mr-2",
    on: {
      "click": function ($event) {
        return _vm.changeNavPageBack();
      }
    }
  }, [_c('icon', {
    attrs: {
      "name": "chevron-left"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "number active",
    on: {
      "click": function ($event) {
        return _vm.changeNavPageBack();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.currentPage) + " ")]), Math.ceil(_vm.history.total / _vm.elementsPerPage) > _vm.currentPage ? _c('div', {
    staticClass: "number",
    on: {
      "click": function ($event) {
        return _vm.changeNavPageNext();
      }
    }
  }, [_vm._v(" | " + _vm._s(_vm.currentPage + 1) + " ")]) : _vm._e(), Math.ceil(_vm.history.total / _vm.elementsPerPage) > _vm.currentPage ? _c('div', {
    staticClass: "arrow ml-2",
    on: {
      "click": function ($event) {
        return _vm.changeNavPageNext();
      }
    }
  }, [_c('icon', {
    attrs: {
      "name": "chevron-right"
    }
  })], 1) : _vm._e()]) : _vm._e()])])])])])])])]) : _vm._e()], 1) : _c('div', [_c('div', {
    staticClass: "container-fluid pt-3"
  }, [_c('access-denied-modal', {
    on: {
      "clickedcanceleditstate": _vm.onClickCancel
    }
  })], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-header",
    attrs: {
      "id": "heading_accordion_budget"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_c('button', {
    staticClass: "btn"
  }, [_vm._v(" HISTORY ")])])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tr', [_c('th', [_vm._v("No")]), _c('th', [_vm._v("Global ID")]), _c('th', [_vm._v("Date & Time")]), _c('th', [_vm._v("User(KID)")]), _c('th', [_vm._v("Type")])]);
}]

export { render, staticRenderFns }