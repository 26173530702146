import Component from 'vue-class-component';
import Vue from 'vue';
import { Emit } from 'vue-property-decorator';
import { Data } from '~/utils';
import { PuiFilterConfig, PuiFilterConfigOption } from '~/models/libraries/pebble-ui';
import { MiniFdFilters } from '~/models/services/minifd';

@Component({})
export default class InYearBudgetRequestsTableFiltersComponent extends Vue {
    private filterValues: MiniFdFilters = {
        unitSidFilters: [],
        requestTypeFilters: [],
        categoryFilters: [],
        controllingCategoryFilters: [],
        localBudgetCompensationFilter: [],
        outageTypeFilter: [],
        statusFilter: []
    };

    private get filterConfig(): PuiFilterConfig {
        const getOptionsFromConstant = (constantName: string): PuiFilterConfigOption[] => {
            if (Data.Instance.constants[constantName]) {
                return Object.keys(Data.Instance.constants[constantName])
                    .map(constantKey => {
                        return {
                            value: parseInt(constantKey),
                            displayName: Data.Instance.constants[constantName][constantKey]
                        };
                    });
            } else {
                return [];
            }
        };

        const requestTypeFilters = {
            name: 'requestTypeFilters',
            displayName: 'Request Type',
            type: 'multiselect',
            config: {
                hasSearchInput: true,
                searchInputPlaceholder: 'Request Type',
                options: getOptionsFromConstant('requestTypes')
            },
            selectedValues: {
                options: []
            },
            appliedValues: {
                options: []
            }
        };

        const categoryFilters = {
            name: 'categoryFilters',
            displayName: 'Aero Classification',
            type: 'multiselect',
            config: {
                hasSearchInput: true,
                searchInputPlaceholder: 'Aero Classification',
                options: getOptionsFromConstant('aeroClassification')
            },
            selectedValues: {
                options: []
            },
            appliedValues: {
                options: []
            }
        };

        const controllingCategoryFilters = {
            name: 'controllingCategoryFilters',
            displayName: 'Controlling Category',
            type: 'multiselect',
            config: {
                hasSearchInput: true,
                searchInputPlaceholder: 'Controlling Category',
                options: getOptionsFromConstant('planningAndControllingClassification')
            },
            selectedValues: {
                options: []
            },
            appliedValues: {
                options: []
            }
        };

        const localBudgetCompensationFilter = {
            name: 'localBudgetCompensationFilter',
            displayName: 'Budget Compensation',
            type: 'multiselect',
            config: {
                hasSearchInput: true,
                searchInputPlaceholder: 'Budget Compensation',
                options: getOptionsFromConstant('localBudgetCompensationsTypes')
            },
            selectedValues: {
                options: []
            },
            appliedValues: {
                options: []
            }
        };

        const outageTypeFilter = {
            name: 'outageTypeFilter',
            displayName: 'Outage Type',
            type: 'multiselect',
            config: {
                hasSearchInput: true,
                searchInputPlaceholder: 'Outage Type',
                options: getOptionsFromConstant('outageType')
            },
            selectedValues: {
                options: []
            },
            appliedValues: {
                options: []
            }
        };

        const statusFilter = {
            name: 'statusFilter',
            displayName: 'Status',
            type: 'multiselect',
            config: {
                hasSearchInput: true,
                searchInputPlaceholder: 'Status',
                options: getOptionsFromConstant('miniFdStatusesTypes')
            },
            selectedValues: {
                options: []
            },
            appliedValues: {
                options: []
            }
        };

        return {
            filters: [
                statusFilter,
                requestTypeFilters,
                categoryFilters,
                controllingCategoryFilters,
                localBudgetCompensationFilter,
                outageTypeFilter
            ]
        };
    }

    created(): void {
        this.filterValueHasChanged();
    }

    @Emit('changed:filter-value')
    private filterValueHasChanged(): MiniFdFilters {
        return this.filterValues;
    }

    private onFilterValueChanged(payload: Record<keyof MiniFdFilters, number[] | number | undefined>): void {
        Object.keys(payload).forEach(filterKey => {
            this.filterValues[filterKey] = payload[filterKey] ? payload[filterKey] : undefined;
        });

        this.filterValueHasChanged();
    }
}
