import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import type { ExternalDataOpportunityInformation } from '~/utils/interfaces';

@Component({})
export default class LinkedOpportunityCollapsibleComponent extends Vue {
    @Prop()
    private data!: ExternalDataOpportunityInformation;
}
