import { Connection } from '~/utils/';
import { ConstantsList } from '~/models/services/constants-list';
import { ApiResponse } from '~/models/services/common';

export class ConstantsListService {
    private readonly api: Connection = new Connection();

    public async getAll(): Promise<ConstantsList> {
        return (await this.api.get<ApiResponse<ConstantsList>>('budget-request/constants')).data.result;
    }
}
