import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import 'vue-awesome/icons/chevron-down';
import './add-new-period.scss';
import { Period, PeriodConfig, PeriodTypes, SelectOption } from '~/utils/interfaces';
import { MtpService } from '~/services/mtp-service';
import { CrService } from '~/services/cr-service';
import { format } from 'date-fns';
import moment from 'moment-timezone';
import { Data } from '~/utils';

const VALIDATION_TYPE = {
    NONE: 0,
    OVERLAPPING_PERIODS: 1,
    SAME_YEAR: 2
};

@Component({})

export default class AddNewPeriodComponent extends Vue {
    // Intentionally left empty so the promise will never resolve.
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    private infinitePromise = new Promise(() => {});
    private loading = false;
    private data: Data = Data.Instance;
    public MtpService: MtpService = new MtpService();
    public CrService: CrService = new CrService();
    private newPeriod: Period = <Period>{
        year: 0,
        startDate: null,
        endDate: null
    };
    private currentDate = new Date(Date.now());
    private isEdit: boolean = false;
    private startDateInputDirty = false;
    private endDateInputDirty = false;
    private datepickerDateFormat = 'DD/MM/YYYY';
    private currentEditId = null;
    private mtpPeriods = [] as SelectOption[];
    private mtpYear = null;
    private defaultStartDate = this.currentDate;
    private defaultEndDate = this.currentDate;

    @Prop()
    private periodType: PeriodTypes;

    @Prop()
    private hasPermissionToEdit: boolean;

    @Prop()
    private editId: string | number;

    @Prop()
    private periods: Period[];

    private get periodTypeConfig(): PeriodConfig {
        return {
            [PeriodTypes.MTP]: {
                addNew: 'Add new MTP period',
                edit: `Edit ${this.newPeriod.year} MTP Period`,
                path: '/mtp-periods',
                startDateProperty: 'mtpStartDate',
                endDateProperty: 'mtpEndDate',
                getAction: (year: number) => this.MtpService.GetMtpPeriod(year),
                addAction: (period: any) => this.MtpService.addMtpPeriod(period),
                editAction: (period: any) => this.MtpService.editMtpPeriod(period),
                validationType: VALIDATION_TYPE.SAME_YEAR,
                hasIdInPayload: false,
            },
            [PeriodTypes.CR]: {
                addNew: 'Add new Change request period',
                edit: `Edit ${this.newPeriod.year} Change Request Period`,
                path: '/cr-periods',
                startDateProperty: 'startDate',
                endDateProperty: 'endDate',
                getAction: (year: number) => this.CrService.GetCrPeriod(year),
                addAction: (period: any) => this.CrService.addCrPeriod(period),
                editAction: (period: any) => this.CrService.editCrPeriod(period),
                validationType: VALIDATION_TYPE.OVERLAPPING_PERIODS,
                hasIdInPayload: true,
            }
        };
    }

    private get periodConfig() {
        return this.periodTypeConfig[this.periodType];
    }

    private get isStartDateValid(): boolean {
        return (this.newPeriod.startDate === null && !this.startDateInputDirty) || (!this.areDatesSet && this.newPeriod.startDate && this.startDateInputDirty) || (this.areDatesSet && this.isEndDateGreater);
    }

    private get isEndDateValid(): boolean {
        return (this.newPeriod.endDate === null && !this.endDateInputDirty) || (this.newPeriod.endDate && this.endDateInputDirty);
    }

    private get areDatesSet(): boolean {
        return this.newPeriod.startDate && this.newPeriod.endDate;
    }

    private get isEndDateGreater(): boolean {
        return this.newPeriod.startDate <= this.newPeriod.endDate;
    }

    private get showMtpPeriodDropdown(): boolean {
        return this.periodType === PeriodTypes.CR;
    }

    private get isSaveDisabled(): boolean {
        return !this.isStartDateValid || !this.isEndDateValid || !this.areDatesSet || !this.isEndDateGreater || (this.showMtpPeriodDropdown && !this.mtpYear);
    }

    @Watch('editId')
    async onPeriodChanged() {
        if (!this.editId) {
            return;
        }
        this.$emit('loadingChanged', true);
        this.clearData();
        try {
            const editPeriod = await this.getPeriodConfigPropertyValue('getAction')(this.editId);
            this.currentEditId = this.editId;

            this.newPeriod.year = editPeriod.year;
            if (this.showMtpPeriodDropdown) {
                this.mtpYear = this.newPeriod.year;
                this.changeMtpYear();
            }

            if (editPeriod[this.getPeriodConfigPropertyValue('startDateProperty')]) {
                this.newPeriod.startDate = new Date(editPeriod[this.getPeriodConfigPropertyValue('startDateProperty')]);
                this.startDateInputDirty = true;
            }
            if (editPeriod[this.getPeriodConfigPropertyValue('endDateProperty')]) {
                this.newPeriod.endDate = new Date(editPeriod[this.getPeriodConfigPropertyValue('endDateProperty')]);
                this.endDateInputDirty = true;
            }

            this.isEdit = true;
        } catch (error) {
            if (error && this.$route.path !== this.getPeriodConfigPropertyValue('path')) {
                await this.$router.push({ path: this.getPeriodConfigPropertyValue('path') });
            }
        }
        this.$emit('update:editId', null);
        this.$emit('loadingChanged', false);
    }

    async created() {
        if (this.showMtpPeriodDropdown) {
            const periods = await this.MtpService.getMtpYears();
            this.mtpPeriods = periods.map((period) => ({
                label: period.toString(),
                value: period
            }));
            if (this.mtpPeriods.length && !this.mtpYear) {
                this.mtpYear = this.mtpPeriods[0].value;
                this.changeMtpYear();
            }
        }
    }

    private notBeforeStartDate(date): boolean {
        return date <= this.newPeriod.startDate;
    }

    private notAfterEndDate(date): boolean {
        if (this.newPeriod.endDate !== null) {
            return date >= this.newPeriod.endDate;
        } else {
            return false;
        }
    }

    private startDateChanged() {
        this.startDateInputDirty = true;
    }

    private endDateChanged() {
        this.endDateInputDirty = true;
    }

    private async savePeriod() {
        this.loading = true;
        try {
            const newPeriod = {
                year: this.showMtpPeriodDropdown ? this.mtpYear : new Date(this.newPeriod.startDate).getFullYear(),
                [this.getPeriodConfigPropertyValue('startDateProperty')]: this.newPeriod.startDate,
                [this.getPeriodConfigPropertyValue('endDateProperty')]: this.newPeriod.endDate,
            } as any;

            if (newPeriod[this.getPeriodConfigPropertyValue('startDateProperty')]) {
                newPeriod[this.getPeriodConfigPropertyValue('startDateProperty')] = format(new Date(this.newPeriod.startDate), 'yyyy-MM-dd\'T00:00:00\'');
            }
            if (newPeriod[this.getPeriodConfigPropertyValue('endDateProperty')]) {
                newPeriod[this.getPeriodConfigPropertyValue('endDateProperty')] = format(new Date(this.newPeriod.endDate), 'yyyy-MM-dd\'T00:00:00\'');
            }
            if (this.getPeriodConfigPropertyValue('hasIdInPayload')) {
                newPeriod.id = this.isEdit ? this.currentEditId : null;
            }

            const validationType = this.getPeriodConfigPropertyValue('validationType');

            if (validationType !== VALIDATION_TYPE.NONE && !this.isNewPeriodValid(newPeriod, validationType)) {
                let errorMessage = '';
                if (validationType === VALIDATION_TYPE.OVERLAPPING_PERIODS) {
                    errorMessage = `Selected period overlaps with another period for the ${newPeriod.year} year.`;
                } else if (validationType === VALIDATION_TYPE.SAME_YEAR) {
                    errorMessage = `There is already a mtp period for ${newPeriod.year} year.`;
                }
                this.$emit('errorChanged', errorMessage);
            } else if (!this.isEdit) {
                await this.getPeriodConfigPropertyValue('addAction')(newPeriod);
                await Data.Instance.loadMtpPeriods();
                await Data.Instance.loadCrPeriods();
                this.handleSave();
            } else {
                await this.getPeriodConfigPropertyValue('editAction')(newPeriod);
                await Data.Instance.loadMtpPeriods();
                await Data.Instance.loadCrPeriods();
                this.handleSave();
            }
        } catch (error) {
            if (error.response !== undefined && error.response.status === 400) {
                this.$emit('errorChanged', error.response.data.errors[0]);
            }

            if (this.newPeriod.startDate) {
                this.newPeriod.startDate = new Date(this.newPeriod.startDate);
            }
            if (this.newPeriod.endDate) {
                this.newPeriod.endDate = new Date(this.newPeriod.endDate);
            }
        } finally {
            this.loading = false;
        }

    }

    private handleSave() {
        localStorage.removeItem('mtpPeriod');
        this.clearData();
        this.$emit('periodSaved');

        this.startDateInputDirty = false;
        this.endDateInputDirty = false;

        this.$bvToast.show('toast-success');
    }

    private clearData() {
        this.isEdit = false;
        this.newPeriod = {
            year: 0,
            startDate: null,
            endDate: null
        };
        this.$emit('errorChanged', '');
    }

    private isNewPeriodValid(period: Period, validationType: number): boolean {
        let periodValid = false;
        if (validationType === VALIDATION_TYPE.OVERLAPPING_PERIODS) {
            // checks whether there is an existing period with same year as the new period and overlapping start and end date
            const overlappingPeriods = this.periods.filter((p) =>
                (moment(new Date(period.startDate)).isBetween(moment(p.startDate, this.datepickerDateFormat), moment(p.endDate, this.datepickerDateFormat), undefined, '[]') ||
                    moment(new Date(period.endDate)).isBetween(moment(p.startDate, this.datepickerDateFormat), moment(p.endDate, this.datepickerDateFormat), undefined, '[]')) &&
                (period.year !== this.currentEditId ? p.id !== this.currentEditId : true) && p.year === period.year);
            periodValid = overlappingPeriods.length === 0;
        } else if (validationType === VALIDATION_TYPE.SAME_YEAR) {
            const periodWithSameYear = this.periods.filter((p) => !this.isEdit && p.year === period.year);
            periodValid = periodWithSameYear.length === 0;
        }
        return periodValid;
    }

    private getPeriodConfigPropertyValue(property: string): any {
        return this.periodConfig && this.periodConfig[property] ? this.periodConfig[property] : '';
    }

    private changeMtpYear() {
        this.defaultStartDate = new Date(this.mtpYear, new Date().getMonth(), new Date().getDate());
        this.defaultEndDate = new Date(this.mtpYear, new Date().getMonth(), new Date().getDate());
    }
}
