




















import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { PuiLightbox } from '~/models/libraries/pebble-ui';

const REF_CONSTANTS = {
    LIGHTBOX: 'delete-lightbox',
} as const;

@Component({})
export default class DeleteConfirmationComponent extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;

    $refs!: {
        [REF_CONSTANTS.LIGHTBOX]: PuiLightbox;
    };

    @Prop()
    private lightboxOpen!: boolean;

    @Prop({ default: false })
    private loading: boolean;

    @Prop({ default: 'Are you sure you want to delete this item?' })
    private message!: string;

    @Watch('lightboxOpen')
    onLightboxOpenChanged(value: boolean): void {
        value ? this.open() : this.close();
    }

    private open(): void {
        this.$refs[REF_CONSTANTS.LIGHTBOX].open();
    }
    
    private close(): void {
        this.$refs[REF_CONSTANTS.LIGHTBOX].close();
    }

    private emitClose(): void {
        this.$emit('close-lightbox');
    }

    private triggerDelete(): void {
        this.$emit('trigger-delete');
    }
}
