import { render, staticRenderFns } from "./risk-budget-request.vue?vue&type=template&id=0abe4329&"
import script from "./risk-budget-request.ts?vue&type=script&lang=ts&"
export * from "./risk-budget-request.ts?vue&type=script&lang=ts&"
import style0 from "./risk-budget-request.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports