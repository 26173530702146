import Component from 'vue-class-component';
import Vue from 'vue';
import { PuiLightbox } from '~/models/libraries/pebble-ui';
import { Prop } from 'vue-property-decorator';
import { DepartmentGroup, GetMiniFdByGlobalIdResponse } from '~/models/services/minifd';
import UamUserInputComponent from '~/components/shared/input/uam-user-input/uam-user-input.vue';
import { STRING_DEPARTMENT_GROUP_MAP } from '~/utils/department-group-utils';
import { compareNumberAsc } from '~/utils/math-helper';
import { MasterService } from '~/services/master-service';
import { SelectOptions } from '~/components/in-year-budget-requests/add-in-year-budget-requests-modal/options';

type ModalDataItem = SelectOptions
    & {
        departmentGroup: DepartmentGroup,
        departmentGroupName: string,
        hasResponse: boolean,
    };

const REF_CONSTANTS = {
    LIGHTBOX: 'lightbox',
} as const;

@Component({
    components: {
        uamUserInput: UamUserInputComponent,
    },
})
export default class EditApprovalModalComponent extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;

    @Prop()
    private iybrData!: GetMiniFdByGlobalIdResponse;

    private isFormLoading = false;
    private hasError = false;
    private modalData: ModalDataItem[] = [];

    $refs!: {
        [REF_CONSTANTS.LIGHTBOX]: PuiLightbox,
    };

    private get isFormValid(): boolean {
        return this.modalData.reduce((previousValue, currentValue) => previousValue && (currentValue.label !== '' && currentValue.value !== ''), true);
    }

    private get isSubmitButtonEnabled(): boolean {
        return this.isFormValid
            && !this.isFormLoading
            && this.modifiedItems.length !== 0;
    }

    private get modifiedItems(): ModalDataItem[] {
        return this.modalData.filter(item => {
            if (!item.value) {
                return false;
            }

            const initialStakeholder = this.iybrData.miniFdStakeholders
                .filter(e => e.isReviewer)
                .find(e => e.departmentGroupName === item.departmentGroupName);
            return initialStakeholder.kId !== item.value;
        });
    }

    public openLightbox(): void {
        this.resetForm();
        this.prepareModalData();
        this.$refs[REF_CONSTANTS.LIGHTBOX].open();
    }

    private userSelected(departmentGroupName: string, userSelection: SelectOptions): void {
        const approver = this.modalData.find(e => e.departmentGroupName === departmentGroupName);

        if (!approver) {
            return;
        }

        approver.label = userSelection.label;
        approver.value = userSelection.value;
    }

    private userCleared(departmentGroupName: string): void {
        const approver = this.modalData.find(e => e.departmentGroupName === departmentGroupName);

        if (!approver) {
            return;
        }

        approver.label = '';
        approver.value = '';
    }

    private prepareModalData(): void {
        this.modalData = this.iybrData.miniFdStakeholders
            .filter(e => e.isReviewer)
            .map(e => ({
                label: e.name,
                value: e.kId,
                departmentGroup: STRING_DEPARTMENT_GROUP_MAP[e.departmentGroupName],
                departmentGroupName: e.departmentGroupName,
                hasResponse: !!e.approvalDate || !!e.rejectionDate,
            }))
            .sort((e1, e2) => compareNumberAsc(e1.departmentGroup, e2.departmentGroup));
    }

    private resetForm(): void {
        this.isFormLoading = false;
        this.hasError = false;
    }

    private async submitForm(): Promise<void> {
        this.hasError = false;
        this.isFormLoading = true;

        try {
            const stakeholders = this.modifiedItems.map(e => ({
                departmentGroup: e.departmentGroup,
                stakeholderKid: e.value,
                stakeholderName: e.label,
            }));

            await MasterService.instance.miniFdService.editReviewerInApprovalWorkflow({
                miniFdId: this.iybrData.id,
                stakeholders,
            });

            this.$emit('update:approvers');
            this.$refs[REF_CONSTANTS.LIGHTBOX].close();
        } catch {
            this.hasError = true;
        } finally {
            this.isFormLoading = false;
        }
    }
}
