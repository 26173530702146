
























































import { Component, Prop, Watch } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { Debouncer } from '~/mixins/debouncer';
import { ManagedAbortControllers } from '~/mixins/managed-abort-controllers';

interface PuiSelectOption<T = string> {
    label: string;
    secondaryLabel?: string;
    value: T;
    disabled?: boolean;
    extraData?: any;
}

type SelectOption = PuiSelectOption<any>;

type RequestPromiseGenerator = (query: string) => Promise<SelectOption[]>;

@Component({})
export default class PmlTypeAhead extends mixins(Debouncer, ManagedAbortControllers) {
    @Prop({ default: () => '' })
    private placeholder!: string;

    @Prop()
    private requestPromiseGenerator?: RequestPromiseGenerator;

    @Prop({ default: () => 1000 })
    private debounceTimeout!: number;

    @Prop()
    private value?: SelectOption;

    @Prop(Boolean)
    private isDisabled!: boolean;

    private isFetchError = false;
    private isFirstOpen = true;
    private isOpen = false;
    private isLoading = false;
    private inputString = '';

    private options: SelectOption[] = [];

    private get shouldShowResults(): boolean {
        return this.isOpen && !this.isLoading && this.options.length !== 0 && !this.isFetchError;
    }

    private get shouldShowMessageBox(): boolean {
        return this.isOpen && (this.isLoading || this.options.length === 0 || this.isFetchError);
    }

    private get messageBoxText(): string {
        if (this.isLoading) {
            return 'Please wait! Loading results...'
        }

        if (this.isFetchError) {
            return 'Fetch error!'
        }

        if (this.options.length === 0) {
            return 'No results!'
        }

        return '';
    }

    @Watch('value')
    private onValueChanged(newValue: SelectOption | null): void {
        if (newValue) {
            this.inputString = newValue.label;
        }
    }

    public clearInput(): void {
        this.inputString = '';
        this.removeSelectedOption();
        this.isFirstOpen = true;
    }

    private onOptionClick(option: SelectOption): void {
        this.setSelectedOption(option);
        this.closeResults();
    }

    private onInputFocusIn(): void {
        this.openResults();
    }

    private onClickOutside(): void {
        this.closeResults();
    }

    private onInputChanged(): void {
        this.removeSelectedOption();
        this.isLoading = true;

        this.debounce(
            'fetchTypeahead',
            () => {
                this.fetchEntries();
            },
            this.debounceTimeout
        );
    }

    private setSelectedOption(option: SelectOption): void {
        this.$emit('input', option);
    }

    private removeSelectedOption(): void {
        this.$emit('input', null);
    }

    private async fetchEntries(): Promise<void> {
        if (!this.requestPromiseGenerator) {
            return;
        }

        this.isLoading = true;
        this.isFetchError = false;

        const signal = this.getSignal('fetchTypeahead', true);

        try {
            this.options = await this.requestPromiseGenerator(this.inputString);
        } catch (err) {
            if (!signal.aborted) {
                this.isFetchError = true;
            }
        } finally {
            this.isLoading = false;
            this.openResults();
        }
    }

    private openResults(): void {
        if (this.isFirstOpen) {
            this.fetchEntries().then();
            this.isFirstOpen = false;
        }

        this.isOpen = true;
    }

    private closeResults(): void {
        this.isOpen = false;
    }
}
