import Vue from 'vue';
import Component from 'vue-class-component';
import { Data, Helper, User } from '~/utils';
import { BudgetRequestsService } from '~/services';
import { Prop, Watch } from 'vue-property-decorator';
import Chart from 'chart.js';
import { ChartService } from '~/services/chart-service';
import moment from 'moment-timezone';
import { PlantsService } from '~/services/plants-service';
import { GetBudgetDistributionPayload } from '~/models/services/chart';
import { MasterService } from '~/services/master-service'

@Component({})

export default class ChartBudgetRequestsComponent extends Vue {
    public PlantsService = MasterService.instance.plantsService;
    private loading: boolean = false;
    private data: Data = Data.Instance;
    private userPermissions = <any>[];
    private optionsChart = {
        colors: ['#d6d6d6', '#607d8b', '#ffab00', '#19c142', '#0078dc'],
        labels: ['Draft', 'Submitted', 'Flagged', 'Reviewed', 'Approved'],
        states: {
            normal: {
                filter: {
                    type: 'none',
                    value: 0,
                }
            },
            hover: {
                filter: {
                    type: 'none',
                    value: 0.15,
                }
            },
            active: {
                allowMultipleDataPointsSelection: false,
                filter: {
                    type: 'none',
                    value: 0.35,
                }
            },
        },
        dataLabels:
            {
                enabled: false,
            },
        plotOptions: {
            pie: {
                expandOnClick: false,
                donut: {
                    labels: {
                        show: true,
                        name: {
                            show: true,
                        },
                        value: {
                            show: true
                        },
                        total: {
                            show: true
                        }
                    }
                }
            }
        },
        legend: {
            formatter: function (seriesName, opts) {
                return [opts.w.globals.series[opts.seriesIndex] + ' - ' + seriesName];
            },
            fontSize: '16px',
            offsetX: -10,
            offsetY: 10

        },
        stroke: {
            show: true,
            curve: 'smooth',
            width: 0,
        },
    };
    private selectedPlantCurrency: { id?: string, code?: string } = {};
    private series = [];
    private budgetRequestService: BudgetRequestsService = new BudgetRequestsService();
    private chartService: ChartService = new ChartService();
    private mtpStatusDistribution = <any>{};
    private budgetDistribution = <any>{};

    @Prop()
    private selectedMtpFilter: number;

    @Prop()
    private miniFdYear: number;

    private isActive: boolean = null;
    private capexPrevious = <any>[];
    private opexPrevious = <any>[];
    private capexNew = <any>[];
    private opexNew = <any>[];
    private labelName = <any>[];
    private totalPrevious = <any>[];
    private totalNew = <any>[];
    private chartBudgetDistribution = null;
    private symbolChart = '';
    private latestChangesChart = null;
    private mtpStatusDistributionRequest = {
        plantIds: [],
        mtpYear: 0,
        isActive: true,
        miniFdYear: 0
    };
    private budgetDistributionRequest: GetBudgetDistributionPayload = {
        plantIds: [],
        mtpYear: 0,
        miniFdYear: 0,
        currencyCode: 'mEUR'
    };
    private countries = [];
    private countryNames: string[] = [];
    private currencyCode = 'mEUR';
    private currencyToggle = true;

    // computed are declared with get before a function
    get showCurrencyToggle(): boolean {
        if (this.selectedPlantCurrency && this.selectedPlantCurrency.code === 'mEUR') {
            return false;
        } else {
            return true;
        }
    }

    async created() {
        this.userPermissions = await User.getPerm();
        await this.getDataMtpStatus(false);
    }

    @Watch('data.selectedPlant')
    async onSelectedPlantsChanged(val: string, oldVal: string) {
        if (this.$route.path === '/budget-requests') {
            await this.getDataMtpStatus(false);
        }
    }

    public async getDataMtpStatus(onlyMtpStatusDistribution: boolean) {
        if (!this.data.selectedPlant) {
            return;
        }
        // Set selected plant ids for api calls
        this.countries = [];
        this.countryNames = [];
        const selectedPlantIds = this.data.selectedPlant.map(plant => plant.plantSid);

        // Get countries for selected plants
        this.selectedPlantCurrency = this.data.selectedPlant.length ? await this.PlantsService.getCurrency({ countryName: this.data.selectedPlant[0].countryName }) : {};
        this.countries = this.data.selectedPlant;
        this.countryNames = this.filterUniqueCountries(this.countries);
        this.latestChangesChart = await this.budgetRequestService.getLatestChangeInPlant({ currentPlantIds: selectedPlantIds });
        if (this.latestChangesChart.dateTime && this.latestChangesChart.kId) {
            this.latestChangesChart.dateTime = moment(Helper.UtcToLocalTime(this.latestChangesChart.dateTime)).format('DD/MM/YYYY - HH:mm');
        } else {
            this.latestChangesChart = null;
        }

        this.mtpStatusDistributionRequest = {
            plantIds: selectedPlantIds,
            mtpYear: this.selectedMtpFilter,
            miniFdYear: this.miniFdYear,
            isActive: localStorage.selectedMtp !== 0 ? true : false
        };

        this.mtpStatusDistribution = await this.chartService.getMtpStatusDistribution(this.mtpStatusDistributionRequest);

        this.series = [0, 0, 0, 0, 0];
        for (let i = 0; i < this.mtpStatusDistribution.budgetRequests.length; i++) {
            if (this.mtpStatusDistribution.budgetRequests[i].mtpStatus === 1) {
                this.series[0] = this.mtpStatusDistribution.budgetRequests[i].counter;
            }
            if (this.mtpStatusDistribution.budgetRequests[i].mtpStatus === 2) {
                this.series[1] = this.mtpStatusDistribution.budgetRequests[i].counter;
            }
            if (this.mtpStatusDistribution.budgetRequests[i].mtpStatus === 3) {
                this.series[2] = this.mtpStatusDistribution.budgetRequests[i].counter;
            }
            if (this.mtpStatusDistribution.budgetRequests[i].mtpStatus === 4) {
                this.series[3] = this.mtpStatusDistribution.budgetRequests[i].counter;
            }
            if (this.mtpStatusDistribution.budgetRequests[i].mtpStatus === 5) {
                this.series[4] = this.mtpStatusDistribution.budgetRequests[i].counter;
            }
        }

        if (onlyMtpStatusDistribution === false && this.selectedMtpFilter !== 0) {
            this.budgetDistributionRequest = {
                plantIds: selectedPlantIds,
                mtpYear: this.selectedMtpFilter,
                miniFdYear: this.miniFdYear,
                currencyCode: 'mEUR'
            };
            await this.setChartMtpDistribution();

        }
    }

    private formatDataBudgetDistribution() {
        this.capexPrevious = [];
        this.opexPrevious = [];
        this.capexNew = [];
        this.opexNew = [];
        this.labelName = [];
        this.totalPrevious = [];
        this.totalNew = [];
        for (let i = 0; i < this.budgetDistribution.distributionCategories.length; i++) {
            this.labelName.push(this.budgetDistribution.distributionCategories[i].categoryName.split('MTP'));
            if (this.budgetDistribution.distributionCategories[i].previous) {
                this.capexPrevious.push(this.budgetDistribution.distributionCategories[i].previous.capex);
                this.opexPrevious.push(this.budgetDistribution.distributionCategories[i].previous.opex);
                this.totalPrevious.push(this.budgetDistribution.distributionCategories[i].previous.total);
            } else {
                this.capexPrevious.push(0);
                this.opexPrevious.push(0);
                this.totalPrevious.push(0);
            }
            if (this.budgetDistribution.distributionCategories[i].new) {
                this.capexNew.push(this.budgetDistribution.distributionCategories[i].new.capex);
                this.opexNew.push(this.budgetDistribution.distributionCategories[i].new.opex);
                this.totalNew.push(this.budgetDistribution.distributionCategories[i].new.total);
            } else {
                this.capexNew.push(0);
                this.opexNew.push(0);
                this.totalNew.push(0);
            }

        }
        for (let i = 0; i < this.labelName.length; i++) {
            this.labelName[i][0] = this.labelName[i][0] + 'MTP';
        }
    }

    private async setChartMtpDistribution() {
        this.budgetDistribution = await this.chartService.getBudgetDistribution(this.budgetDistributionRequest);
        this.formatDataBudgetDistribution();
        if (this.chartBudgetDistribution) {
            this.chartBudgetDistribution.destroy();
        }
        switch (this.budgetDistribution.currency) {
            case 'mSEK':
                this.symbolChart = 'kr';
                break;
            case 'mGBP':
                this.symbolChart = '£';
                break;
            case 'mEUR':
                this.symbolChart = '€';
                break;
            default:
                this.symbolChart = '€';
        }
        this.chartMtpDistribution();
    }

    private dataPointSelectionHandler(e, chartContext, config) {
        this.$emit('mtpstatuses', config.dataPointIndex + 1);
    }

    private chartMtpDistribution() {
        let chart = this.$refs.chart;
        if (this.selectedMtpFilter !== 0 && chart) {
            let ctx = (chart as any).getContext('2d');
            this.chartBudgetDistribution = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: this.labelName,
                    datasets: [
                        {
                            label: 'OPEX',
                            backgroundColor: '#99dfff',
                            data: this.opexPrevious,
                            stack: '1',
                        },
                        {
                            label: 'CAPEX',
                            backgroundColor: '#a9aed4',
                            data: this.capexPrevious,
                            stack: '1'
                        },
                        {
                            label: 'OPEX',
                            backgroundColor: '#00b0ff',
                            data: this.opexNew,
                            stack: '2'
                        },
                        {
                            label: 'CAPEX',
                            backgroundColor: '#283593',
                            data: this.capexNew,
                            stack: '2'
                        }
                    ]
                },
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                callback: (value, index, values) => {
                                    return this.symbolChart + parseFloat(value as string).toFixed(3) + 'm';
                                }
                            }

                        }]
                    },
                    legend: {
                        display: false,
                        align: 'start',
                        labels: {
                            padding: 50
                        }
                    },
                    tooltips: {
                        mode: 'x',
                        callbacks: {
                            title: function () {
                                return '';
                            },
                            label: (tooltipItem, data) => {
                                let corporation = data.datasets[tooltipItem.datasetIndex].label;
                                let valor = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
                                return corporation + ': ' + this.symbolChart + parseFloat(Number(valor).toFixed(3)) + 'm';
                            },
                            beforeTitle: (tooltipItem, data) => {
                                if (tooltipItem[0].datasetIndex === 0) {
                                    return 'Total: ' + this.symbolChart + parseFloat(this.totalPrevious[tooltipItem[0].index].toFixed(3)) + 'm';
                                } else {
                                    return 'Total: ' + this.symbolChart + parseFloat(this.totalNew[tooltipItem[0].index].toFixed(3)) + 'm';
                                }
                            }
                        },
                        backgroundColor: '#F3F5F7',
                        titleFontColor: 'black',
                        bodyFontColor: 'black',
                        titleFontSize: 17,
                        bodyFontSize: 17,
                        borderColor: 'red',
                    }
                }
            });
        }
    }

    private parseGroupedKey(key: string) {
        key = key.replace(')', '');
        key = key.split(',')[1];
        return key;
    }

    private goToHistory() {
        if (this.data.selectedPlant) {
            let ids = this.data.selectedPlant.map(p => p.plantSid).join(',');
            this.$router.push({ path: '/activity-tracking', query: { plantids: ids } });
        }
    }

    private async changedCurrencyCode() {
        this.budgetDistributionRequest.currencyCode = this.currencyCode;
        await this.setChartMtpDistribution();
    }

    private filterUniqueCountries(countries) {
        let countryNames: string[] = [];
        this.data.selectedPlant.forEach(plant => {
            countryNames.push(plant.countryName);
        });
        const uniqueCountryNamesSet = new Set(countryNames);
        const uniqueCountryNames = [...uniqueCountryNamesSet];

        return uniqueCountryNames;
    }
}
