import { render, staticRenderFns } from "./comments-collapsible.vue?vue&type=template&id=6498a44e&"
import script from "./comments-collapsible.ts?vue&type=script&lang=ts&"
export * from "./comments-collapsible.ts?vue&type=script&lang=ts&"
import style0 from "./comments-collapsible.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports