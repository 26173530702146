import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class BulkApproveModalComponent extends Vue {

    constructor() {
        super();
    }

    private approve() {
        this.$emit('bulkApproval');
    }
}
