var render = function () {
  var _vm$formData$projectG, _vm$existingChangeReq;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.uniqueIdInformationError ? _c('div', [_c('add-compensation-modal', {
    attrs: {
      "mtp-year": _vm.budgetRequestMtpYear,
      "show-modal": _vm.formMetadata.showAddBudgetCompensationModal
    },
    on: {
      "update:showModal": function ($event) {
        return _vm.$set(_vm.formMetadata, "showAddBudgetCompensationModal", $event);
      },
      "update:show-modal": function ($event) {
        return _vm.$set(_vm.formMetadata, "showAddBudgetCompensationModal", $event);
      },
      "change:modal-data": _vm.updateBudgetCompensationTableWithRows
    }
  }), _c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": {
        s: 12,
        m: 12,
        l: 8,
        xl: 8
      }
    }
  }, [_c('pui-collapsible', {
    staticClass: "collapsible-pb collapsible-bg",
    attrs: {
      "expandable": false,
      "headline": "DETAILS"
    }
  }, [_vm.formMetadata.isLoaded ? _c('div', [_c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_vm.formMetadata.hasErrors ? _c('pui-form-inline-notification', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.FULL_ROW,
      expression: "formLayout.FULL_ROW"
    }],
    staticClass: "error-inline-notification",
    attrs: {
      "title": _vm.errorTitleMessage,
      "text": "Please check the highlighted information you have provided and make updates where necessary."
    }
  }) : _vm._e()], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.HALF_ROW,
      expression: "formLayout.HALF_ROW"
    }],
    attrs: {
      "is-disabled": _vm.formData.projectGlobalId.meta.disabled,
      "is-valid": _vm.formData.projectGlobalId.validation.isValid,
      "label": "Project Global ID",
      "label-for": "projectGlobalId"
    }
  }, [_c('pui-form-input-field', {
    attrs: {
      "id": "projectGlobalId",
      "autofocus": true,
      "is-disabled": _vm.formData.projectGlobalId.meta.disabled,
      "is-valid": _vm.formData.projectGlobalId.validation.isValid,
      "value": (_vm$formData$projectG = _vm.formData.projectGlobalId.value) === null || _vm$formData$projectG === void 0 ? void 0 : _vm$formData$projectG.join(', '),
      "label": "Project Global ID",
      "placeholder-text": "Please enter Global ID",
      "type": "text"
    }
  }), _c('span', {
    attrs: {
      "slot": "error-message"
    },
    slot: "error-message"
  }, [_vm._v(" " + _vm._s(_vm.formData.projectGlobalId.validation.error) + " ")])], 1), _c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.HALF_ROW,
      expression: "formLayout.HALF_ROW"
    }],
    attrs: {
      "is-disabled": _vm.formData.projectName.meta.disabled,
      "is-valid": _vm.formData.projectName.validation.isValid,
      "label": "Project Name",
      "label-for": "projectName"
    }
  }, [_c('pui-form-input-field', {
    attrs: {
      "id": "projectName",
      "is-disabled": _vm.formData.projectName.meta.disabled,
      "is-valid": _vm.formData.projectName.validation.isValid,
      "label": "Project Name",
      "placeholder-text": "Please enter text",
      "type": "text"
    },
    model: {
      value: _vm.formData.projectName.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.projectName, "value", $$v);
      },
      expression: "formData.projectName.value"
    }
  }), _c('span', {
    attrs: {
      "slot": "error-message"
    },
    slot: "error-message"
  }, [_vm._v(" " + _vm._s(_vm.formData.projectName.validation.error) + " ")])], 1)], 1), _c('div', {
    staticClass: "form-spacer"
  }), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.HALF_ROW,
      expression: "formLayout.HALF_ROW"
    }],
    attrs: {
      "is-valid": _vm.formData.country.validation.isValid,
      "label": "Country"
    }
  }, [_c('pui-form-select', {
    attrs: {
      "is-disabled": _vm.formData.country.meta.disabled,
      "is-valid": _vm.formData.country.validation.isValid,
      "options": _vm.formData.country.meta.items,
      "label": "Country"
    },
    model: {
      value: _vm.formData.country.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.country, "value", $$v);
      },
      expression: "formData.country.value"
    }
  }), _c('span', {
    attrs: {
      "slot": "error-message"
    },
    slot: "error-message"
  }, [_vm._v(" " + _vm._s(_vm.formData.country.validation.error) + " ")])], 1), _c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.HALF_ROW,
      expression: "formLayout.HALF_ROW"
    }],
    attrs: {
      "is-valid": _vm.formData.technology.validation.isValid,
      "label": "Technology"
    }
  }, [_c('pui-form-select', {
    attrs: {
      "is-disabled": _vm.formData.technology.meta.disabled,
      "is-valid": _vm.formData.technology.validation.isValid,
      "options": _vm.formData.technology.meta.items,
      "label": "Technology"
    },
    model: {
      value: _vm.formData.technology.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.technology, "value", $$v);
      },
      expression: "formData.technology.value"
    }
  }), _c('span', {
    attrs: {
      "slot": "error-message"
    },
    slot: "error-message"
  }, [_vm._v(" " + _vm._s(_vm.formData.technology.validation.error) + " ")])], 1)], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.HALF_ROW,
      expression: "formLayout.HALF_ROW"
    }],
    attrs: {
      "is-valid": _vm.formData.plant.validation.isValid,
      "label": "Plant name / River group"
    }
  }, [_c('pui-form-select', {
    attrs: {
      "is-disabled": _vm.formData.plant.meta.disabled,
      "is-valid": _vm.formData.plant.validation.isValid,
      "options": _vm.formData.plant.meta.items,
      "label": "Plant name / River group"
    },
    model: {
      value: _vm.formData.plant.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.plant, "value", $$v);
      },
      expression: "formData.plant.value"
    }
  }), _c('span', {
    attrs: {
      "slot": "error-message"
    },
    slot: "error-message"
  }, [_vm._v(" " + _vm._s(_vm.formData.plant.validation.error) + " ")])], 1), _c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.HALF_ROW,
      expression: "formLayout.HALF_ROW"
    }],
    attrs: {
      "is-valid": _vm.formData.unit.validation.isValid,
      "label": "Unit"
    }
  }, [_c('pui-form-select', {
    attrs: {
      "is-disabled": _vm.formData.unit.meta.disabled,
      "is-valid": _vm.formData.unit.validation.isValid,
      "options": _vm.formData.unit.meta.items,
      "label": "Unit"
    },
    model: {
      value: _vm.formData.unit.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.unit, "value", $$v);
      },
      expression: "formData.unit.value"
    }
  }), _c('span', {
    attrs: {
      "slot": "error-message"
    },
    slot: "error-message"
  }, [_vm._v(" " + _vm._s(_vm.formData.unit.validation.error) + " ")])], 1), _vm.isFlaggedProject ? _c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.HALF_ROW,
      expression: "formLayout.HALF_ROW"
    }],
    attrs: {
      "is-valid": _vm.formData.mainFlag.validation.isValid,
      "label": "Main Flag"
    }
  }, [_c('pui-form-select', {
    attrs: {
      "is-disabled": _vm.formData.mainFlag.meta.disabled,
      "is-valid": _vm.formData.mainFlag.validation.isValid,
      "options": _vm.formData.mainFlag.meta.items,
      "label": "Main Flag"
    },
    model: {
      value: _vm.formData.mainFlag.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.mainFlag, "value", $$v);
      },
      expression: "formData.mainFlag.value"
    }
  }), _c('span', {
    attrs: {
      "slot": "error-message"
    },
    slot: "error-message"
  }, [_vm._v(" " + _vm._s(_vm.formData.mainFlag.validation.error) + " ")])], 1) : _vm._e()], 1), _c('div', {
    staticClass: "form-divider"
  }), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.HALF_ROW,
      expression: "formLayout.HALF_ROW"
    }],
    attrs: {
      "label": "MiniFD Type",
      "label-for": "miniFdType"
    }
  }, [_c('pui-form-select', {
    attrs: {
      "is-disabled": "",
      "options": _vm.formData.miniFdType.meta.items,
      "label": "MiniFD Type"
    },
    model: {
      value: _vm.formData.miniFdType.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.miniFdType, "value", $$v);
      },
      expression: "formData.miniFdType.value"
    }
  })], 1)], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.HALF_ROW,
      expression: "formLayout.HALF_ROW"
    }],
    attrs: {
      "is-valid": _vm.formData.wbsElement.validation.isValid,
      "has-info-layer": true,
      "pui-popover-config": _vm.DEFAULT_POPOVER_CONFIGURATION,
      "label": "WBS (PSP) Element",
      "label-for": "wbsElement"
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.wbsElement.validation.error) + " ")];
      },
      proxy: true
    }, {
      key: "helper-text",
      fn: function () {
        return [_vm._v(" e.g. \"0301.A01234\". If it's not yet available, please enter \"N/A\" ")];
      },
      proxy: true
    }, {
      key: "pui-popover-content",
      fn: function () {
        return [_c('div', {
          staticClass: "cr-field-popover"
        }, [_vm._v(" The WBS Element is sourced from SAP. If not yet available, please enter \"N/A\". ")])];
      },
      proxy: true
    }], null, false, 3318638893)
  }, [_c('pui-form-input-field', {
    attrs: {
      "id": "wbsElement",
      "is-valid": _vm.formData.wbsElement.validation.isValid,
      "label": "WBS (PSP) Element",
      "placeholder-text": "Please enter an ID",
      "type": "text"
    },
    model: {
      value: _vm.formData.wbsElement.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.wbsElement, "value", $$v);
      },
      expression: "formData.wbsElement.value"
    }
  })], 1), _c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.HALF_ROW,
      expression: "formLayout.HALF_ROW"
    }],
    attrs: {
      "is-valid": _vm.formData.aeroClassification.validation.isValid,
      "has-info-layer": true,
      "pui-popover-config": _vm.DEFAULT_POPOVER_CONFIGURATION,
      "label": "AERO Classification"
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.aeroClassification.validation.error) + " ")];
      },
      proxy: true
    }, {
      key: "pui-popover-content",
      fn: function () {
        return [_c('div', {
          staticClass: "cr-field-popover"
        }, [_c('div', {
          staticClass: "cr-field-popover__header"
        }, [_vm._v(" The asset management classification: ")]), _c('div', [_c('span', {
          staticClass: "cr-field-popover__field--title"
        }, [_vm._v(" Maintenance ")]), _vm._v(" – Baseline maintenance that is required according to engineering standards, incl. day-to-day maintenance. ")]), _c('div', [_c('span', {
          staticClass: "cr-field-popover__field--title"
        }, [_vm._v(" Risk ")]), _vm._v(" – A one-off project that aims to correct negative deviation from the normal declared performance parameters. ")]), _c('div', [_c('span', {
          staticClass: "cr-field-popover__field--title"
        }, [_vm._v(" Opportunity ")]), _vm._v(" – A project that would lead to an improvement over and above the declared performance parameters. ")]), _c('div', [_c('span', {
          staticClass: "cr-field-popover__field--title"
        }, [_vm._v(" Statutory ")]), _vm._v(" – Required by law to be addressed without any flexibility. ")])])];
      },
      proxy: true
    }], null, false, 323456074)
  }, [_c('pui-form-select', {
    attrs: {
      "is-disabled": _vm.formData.aeroClassification.meta.disabled,
      "is-valid": _vm.formData.aeroClassification.validation.isValid,
      "options": _vm.formData.aeroClassification.meta.items,
      "label": "Unit"
    },
    on: {
      "change": _vm.fillPrimaryDepartment
    },
    model: {
      value: _vm.formData.aeroClassification.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.aeroClassification, "value", $$v);
      },
      expression: "formData.aeroClassification.value"
    }
  })], 1)], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.HALF_ROW,
      expression: "formLayout.HALF_ROW"
    }],
    attrs: {
      "is-valid": _vm.formData.maintenanceType.validation.isValid,
      "has-info-layer": true,
      "pui-popover-config": _vm.DEFAULT_POPOVER_CONFIGURATION,
      "label": "Maintenance Type"
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.maintenanceType.validation.error) + " ")];
      },
      proxy: true
    }, {
      key: "pui-popover-content",
      fn: function () {
        return [_c('div', {
          staticClass: "cr-field-popover"
        }, [_c('div', {
          staticClass: "cr-field-popover__header"
        }, [_vm._v(" Indicates the maintenance type for the respective line item (not mandatory for hydro plants): ")]), _c('div', [_c('span', {
          staticClass: "cr-field-popover__field--title"
        }, [_vm._v(" Corrective ")]), _vm._v(" – Maintenance carried out after fault recognition and intended to put an item into a state in which it can perform a required function. ")]), _c('div', [_c('span', {
          staticClass: "cr-field-popover__field--title"
        }, [_vm._v(" Mandatory Preventive ")]), _vm._v(" – All preventive maintenance measures, which are required by law or which are of a legal type. They can also be part of an approval for the operation of an asset or based on existing legally binding contracts. ")]), _c('div', [_c('span', {
          staticClass: "cr-field-popover__field--title"
        }, [_vm._v(" Non-mandatory Preventive ")]), _vm._v(" – All preventive maintenance measures, which are not required by law or of a legal type. These can result from recommendations of the vendor/provider, from risk analyses/evaluations, from experience, from warranties, etc. ")]), _c('div', [_c('span', {
          staticClass: "cr-field-popover__field--title"
        }, [_vm._v(" Not available ")]), _vm._v(" – Non-maintenance expenditure. ")])])];
      },
      proxy: true
    }], null, false, 744905550)
  }, [_c('pui-form-select', {
    attrs: {
      "is-disabled": _vm.formData.maintenanceType.meta.disabled,
      "is-valid": _vm.formData.maintenanceType.validation.isValid,
      "options": _vm.formData.maintenanceType.meta.items,
      "label": "Maintenance Type"
    },
    model: {
      value: _vm.formData.maintenanceType.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.maintenanceType, "value", $$v);
      },
      expression: "formData.maintenanceType.value"
    }
  })], 1), _c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.HALF_ROW,
      expression: "formLayout.HALF_ROW"
    }],
    attrs: {
      "is-valid": _vm.formData.primaryDepartment.validation.isValid,
      "label": "Primary Department"
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.primaryDepartment.validation.error) + " ")];
      },
      proxy: true
    }, {
      key: "helper-text",
      fn: function () {
        return [_vm.isPrimaryDepartmentDefinedbyAero ? [_vm._v(" Defined by \"AERO Classification\". ")] : _vm._e()];
      },
      proxy: true
    }], null, false, 1440478988)
  }, [_c('pui-form-select', {
    attrs: {
      "is-disabled": _vm.formData.primaryDepartment.meta.disabled,
      "is-valid": _vm.formData.primaryDepartment.validation.isValid,
      "options": _vm.formData.primaryDepartment.meta.items,
      "label": "Primary Department"
    },
    model: {
      value: _vm.formData.primaryDepartment.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.primaryDepartment, "value", $$v);
      },
      expression: "formData.primaryDepartment.value"
    }
  })], 1)], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.QUARTER_ROW,
      expression: "formLayout.QUARTER_ROW"
    }],
    attrs: {
      "is-valid": _vm.formData.outageType.validation.isValid,
      "has-info-layer": true,
      "pui-popover-config": _vm.DEFAULT_POPOVER_CONFIGURATION,
      "label": "Outage Type",
      "label-for": "outageType"
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.outageType.validation.error) + " ")];
      },
      proxy: true
    }, {
      key: "pui-popover-content",
      fn: function () {
        return [_c('div', {
          staticClass: "cr-field-popover"
        }, [_c('div', [_vm._v("For Fossil plants, please indicate whether the works are part of an \"Outage\" or not.")]), _c('div', [_vm._v("For Hydro plants please choose \"Non-Outage\".")])])];
      },
      proxy: true
    }], null, false, 3615123447)
  }, [_c('pui-form-input-selector', {
    attrs: {
      "options": _vm.formData.outageType.meta.items,
      "selected-value": _vm.formData.outageType.value,
      "name": "outageType"
    },
    on: {
      "update:selectedValue": function ($event) {
        return _vm.$set(_vm.formData.outageType, "value", $event);
      },
      "update:selected-value": [function ($event) {
        return _vm.$set(_vm.formData.outageType, "value", $event);
      }, _vm.handleOutageTypeUpdate]
    }
  })], 1), _c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.QUARTER_ROW,
      expression: "formLayout.QUARTER_ROW"
    }],
    attrs: {
      "is-valid": _vm.formData.projectType.validation.isValid,
      "has-info-layer": true,
      "pui-popover-config": _vm.DEFAULT_POPOVER_CONFIGURATION,
      "label": "Project Type",
      "label-for": "projectType"
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.projectType.validation.error) + " ")];
      },
      proxy: true
    }, {
      key: "pui-popover-content",
      fn: function () {
        return [_c('div', {
          staticClass: "cr-field-popover"
        }, [_c('div', [_vm._v("A \"Project\" has a defined start and end date.")]), _c('div', [_vm._v("\"Non-Project\" items are recurring. (e.g. daily maintenance)")])])];
      },
      proxy: true
    }], null, false, 1322022411)
  }, [_c('pui-form-input-selector', {
    attrs: {
      "options": _vm.formData.projectType.meta.items,
      "selected-value": _vm.formData.projectType.value,
      "name": "projectType"
    },
    on: {
      "update:selectedValue": function ($event) {
        return _vm.$set(_vm.formData.projectType, "value", $event);
      },
      "update:selected-value": function ($event) {
        return _vm.$set(_vm.formData.projectType, "value", $event);
      }
    }
  })], 1), _c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.QUARTER_ROW,
      expression: "formLayout.QUARTER_ROW"
    }],
    attrs: {
      "is-valid": _vm.formData.projectStartDate.validation.isValid,
      "has-info-layer": true,
      "pui-popover-config": _vm.DEFAULT_POPOVER_CONFIGURATION,
      "label": "Project Start Date",
      "label-for": "projectStartDate"
    },
    scopedSlots: _vm._u([{
      key: "pui-popover-content",
      fn: function () {
        return [_c('div', {
          staticClass: "cr-field-popover"
        }, [_c('div', [_vm._v("For project, it is mandatory to provide a start date, which will be the expected date or the actual date by which the financial commitment of the project is or was legally processed, such as the award of a purchase order, but excluding \"planning cost\".")]), _c('div', [_vm._v("For non-project activities, a rough estimation is sufficient.")])])];
      },
      proxy: true
    }], null, false, 3578877515)
  }, [_c('pui-form-input-field', {
    attrs: {
      "id": "projectStartDate",
      "is-valid": _vm.formData.projectStartDate.validation.isValid,
      "label": "Project Start Date",
      "placeholder-text": "Please select a date",
      "trailing-icon-name": "calendar",
      "type": "date"
    },
    model: {
      value: _vm.formData.projectStartDate.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.projectStartDate, "value", $$v);
      },
      expression: "formData.projectStartDate.value"
    }
  }), _c('span', {
    attrs: {
      "slot": "error-message"
    },
    slot: "error-message"
  }, [_vm._v(" " + _vm._s(_vm.formData.projectStartDate.validation.error) + " ")])], 1), _c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.QUARTER_ROW,
      expression: "formLayout.QUARTER_ROW"
    }],
    attrs: {
      "is-valid": _vm.formData.projectEndDate.validation.isValid,
      "has-info-layer": true,
      "pui-popover-config": _vm.DEFAULT_POPOVER_CONFIGURATION,
      "label": "Project End Date",
      "label-for": "projectEndDate"
    },
    scopedSlots: _vm._u([{
      key: "pui-popover-content",
      fn: function () {
        return [_c('div', {
          staticClass: "cr-field-popover"
        }, [_c('div', [_vm._v("For project, please indicate the date when all financial commitments between Uniper and the contractor have been completed and further control measures have been implemented and successfully tested.")])])];
      },
      proxy: true
    }], null, false, 149279182)
  }, [_c('pui-form-input-field', {
    attrs: {
      "id": "projectEndDate",
      "is-valid": _vm.formData.projectEndDate.validation.isValid,
      "label": "Project End Date",
      "placeholder-text": "Please select a date",
      "trailing-icon-name": "calendar",
      "type": "date"
    },
    model: {
      value: _vm.formData.projectEndDate.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.projectEndDate, "value", $$v);
      },
      expression: "formData.projectEndDate.value"
    }
  }), _c('span', {
    attrs: {
      "slot": "error-message"
    },
    slot: "error-message"
  }, [_vm._v(" " + _vm._s(_vm.formData.projectEndDate.validation.error) + " ")])], 1)], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.HALF_ROW,
      expression: "formLayout.HALF_ROW"
    }],
    attrs: {
      "is-disabled": _vm.formData.outageIds.meta.disabled,
      "is-valid": _vm.formData.outageIds.validation.isValid,
      "label": "Outage ID(s)",
      "label-for": "outageIds"
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.outageIds.validation.error) + " ")];
      },
      proxy: true
    }, {
      key: "helper-text",
      fn: function () {
        return [_vm._v(" e.g. \"OUT/WHV/1/21.01\". For small outages without outage IDs, please enter the standard term \"N/A small(<2.5m€) outage.\" ")];
      },
      proxy: true
    }], null, false, 1290632823)
  }, [_c('pui-form-input-field', {
    attrs: {
      "id": "outageIds",
      "is-disabled": _vm.formData.outageIds.meta.disabled,
      "is-valid": _vm.formData.outageIds.validation.isValid,
      "label": "Outage ID(s)",
      "placeholder-text": "Please enter Outage ID(s)",
      "type": "text"
    },
    model: {
      value: _vm.formData.outageIds.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.outageIds, "value", $$v);
      },
      expression: "formData.outageIds.value"
    }
  })], 1), _c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.QUARTER_ROW,
      expression: "formLayout.QUARTER_ROW"
    }],
    attrs: {
      "is-valid": _vm.formData.dateForDecision.validation.isValid,
      "label": "Latest Date for Decision",
      "label-for": "dateForDecision"
    }
  }, [_c('pui-form-input-field', {
    attrs: {
      "id": "dateForDecision",
      "is-valid": _vm.formData.dateForDecision.validation.isValid,
      "label": "Latest Date for Decision",
      "placeholder-text": "Please select a date",
      "trailing-icon-name": "calendar",
      "type": "date"
    },
    model: {
      value: _vm.formData.dateForDecision.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.dateForDecision, "value", $$v);
      },
      expression: "formData.dateForDecision.value"
    }
  }), _c('span', {
    attrs: {
      "slot": "error-message"
    },
    slot: "error-message"
  }, [_vm._v(" " + _vm._s(_vm.formData.dateForDecision.validation.error) + " ")])], 1)], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.HALF_ROW,
      expression: "formLayout.HALF_ROW"
    }],
    attrs: {
      "is-disabled": _vm.formData.riskId.meta.disabled,
      "is-valid": _vm.formData.riskId.validation.isValid,
      "label": "Risk / Maintenance ID",
      "label-for": "riskId"
    }
  }, [_c('pui-form-input-field', {
    attrs: {
      "id": "riskId",
      "is-disabled": _vm.formData.riskId.meta.disabled,
      "is-valid": _vm.formData.riskId.validation.isValid,
      "label": "Risk / Maintenance ID",
      "placeholder-text": "Please enter an ID",
      "type": "text"
    },
    on: {
      "blur": _vm.confirmExternalIdsEntry
    },
    model: {
      value: _vm.formData.riskId.value[0],
      callback: function ($$v) {
        _vm.$set(_vm.formData.riskId.value, 0, $$v);
      },
      expression: "formData.riskId.value[0]"
    }
  }), _c('span', {
    attrs: {
      "slot": "helper-text"
    },
    slot: "helper-text"
  }, [_vm._v(" e.g. 'RAT01234-R' for PT Risk connection or 'RAT01234-M' for MSP connection ")]), _c('span', {
    attrs: {
      "slot": "error-message"
    },
    slot: "error-message"
  }, [_vm._v(" " + _vm._s(_vm.formData.riskId.validation.error) + " ")])], 1), _c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.HALF_ROW,
      expression: "formLayout.HALF_ROW"
    }],
    attrs: {
      "is-valid": _vm.formData.procurementCategory.validation.isValid,
      "has-info-layer": true,
      "pui-popover-config": _vm.DEFAULT_POPOVER_CONFIGURATION,
      "label": "Procurement Category"
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.procurementCategory.validation.error) + " ")];
      },
      proxy: true
    }, {
      key: "pui-popover-content",
      fn: function () {
        return [_c('div', {
          staticClass: "cr-field-popover"
        }, [_c('div', {
          staticClass: "cr-field-popover__header"
        }, [_vm._v(" Please choose the dominant procurement categories (Single selections possible). If it is a lump sum spend, please choose \"Others\": ")]), _c('div', [_c('span', {
          staticClass: "cr-field-popover__field--title"
        }, [_vm._v(" Boiler ")]), _vm._v(" – Ultra-critical boiler, auxiliary boiler, mills / grinding systems, burner, firing systems, high-pressure piping. ")]), _c('div', [_c('span', {
          staticClass: "cr-field-popover__field--title"
        }, [_vm._v(" Mechanical ")]), _vm._v(" – Small pipes, industrial cooling systems, conveying and handling services of primary fuel, pumps, compressors and systems, water treatment, valves, ventilator, etc. ")]), _c('div', [_c('span', {
          staticClass: "cr-field-popover__field--title"
        }, [_vm._v(" Turboset ")]), _vm._v(" – Steam / Gas turbine, electrical generator, CHP Turbine, turbine control & protection system. ")]), _c('div', [_c('span', {
          staticClass: "cr-field-popover__field--title"
        }, [_vm._v(" Pollution Control Equipment ")]), _vm._v(" – Scrubbers (wet or dry), heat exchanger, precipitators, NOx reduction systems, Catalysts, rubber lining works (acid protection), fly ash removal to ash bin, vacuum belt filter. ")]), _c('div', [_c('span', {
          staticClass: "cr-field-popover__field--title"
        }, [_vm._v(" Electrical ")]), _vm._v(" – Transformers, switchgears, power supply units, Diesel generators, electrical components and installations. ")]), _c('div', [_c('span', {
          staticClass: "cr-field-popover__field--title"
        }, [_vm._v(" Instrumentation & Controls (I&C) ")]), _vm._v(" – I&C components, Safety systems (e.g. fire alarm, access control, etc.), CHP specific controls. ")]), _c('div', [_c('span', {
          staticClass: "cr-field-popover__field--title"
        }, [_vm._v(" Hydro Technology (excluding Electrical I&C, and Civil) ")]), _vm._v(" – Hydro turbine, hydro generator, hydro gates and trash racks. ")]), _c('div', [_c('span', {
          staticClass: "cr-field-popover__field--title"
        }, [_vm._v(" Civil, infrastructure & general services ")]), _vm._v(" – Underground and civil works, Hydro dams / fish passes / canals, building construction works, dismantling and disposal services. ")]), _c('div', [_c('span', {
          staticClass: "cr-field-popover__field--title"
        }, [_vm._v(" Engineering services ")]), _vm._v(" – Engineering studies and tests, technical consultancy on plant operation and projects, handling of technical documents incl. quality issues. ")]), _c('div', [_c('span', {
          staticClass: "cr-field-popover__field--title"
        }, [_vm._v(" Others ")]), _vm._v(" – Maintenance consumables, commodities, professional services, facility management, etc. ")])])];
      },
      proxy: true
    }], null, false, 2424356470)
  }, [_c('pui-form-select', {
    attrs: {
      "is-disabled": _vm.formData.procurementCategory.meta.disabled,
      "is-valid": _vm.formData.procurementCategory.validation.isValid,
      "options": _vm.formData.procurementCategory.meta.items,
      "label": "Procurement Category"
    },
    model: {
      value: _vm.formData.procurementCategory.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.procurementCategory, "value", $$v);
      },
      expression: "formData.procurementCategory.value"
    }
  })], 1)], 1), _vm._l(_vm.formData.riskId.value.slice(1), function (_, riskIdIndex) {
    return _c('div', {
      directives: [{
        name: "pui-form-grid-row",
        rawName: "v-pui-form-grid-row"
      }],
      key: riskIdIndex
    }, [_c('div', {
      directives: [{
        name: "pui-form-grid-column",
        rawName: "v-pui-form-grid-column",
        value: _vm.formLayout.HALF_ROW,
        expression: "formLayout.HALF_ROW"
      }],
      staticClass: "extra-risk-id-field"
    }, [_c('pui-form-input-field', {
      attrs: {
        "is-disabled": _vm.formData.riskId.meta.disabled,
        "is-valid": _vm.formData.riskId.validation.isValid,
        "trailing-icon-is-clickable": true,
        "label": "Risk / Maintenance ID",
        "placeholder-text": "Please enter an ID",
        "trailing-icon-name": "close",
        "type": "text"
      },
      on: {
        "blur": _vm.confirmExternalIdsEntry,
        "trailing-icon:click": function ($event) {
          return _vm.removeRiskIdField(riskIdIndex + 1);
        }
      },
      model: {
        value: _vm.formData.riskId.value[riskIdIndex + 1],
        callback: function ($$v) {
          _vm.$set(_vm.formData.riskId.value, riskIdIndex + 1, $$v);
        },
        expression: "formData.riskId.value[riskIdIndex + 1]"
      }
    })], 1)]);
  }), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('div', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.HALF_ROW,
      expression: "formLayout.HALF_ROW"
    }],
    staticClass: "extra-risk-id-button"
  }, [_c('pui-button', {
    attrs: {
      "small": true,
      "icon": "add"
    },
    on: {
      "click": _vm.addEmptyRiskIdField
    }
  }, [_vm._v(" Add Another Risk/Maintenance Id ")])], 1)]), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.HALF_ROW,
      expression: "formLayout.HALF_ROW"
    }],
    attrs: {
      "is-valid": _vm.formData.pcClassification.validation.isValid,
      "has-info-layer": true,
      "pui-popover-config": _vm.DEFAULT_POPOVER_CONFIGURATION,
      "label": "Planning & Controlling Classification"
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.pcClassification.validation.error) + " ")];
      },
      proxy: true
    }, {
      key: "pui-popover-content",
      fn: function () {
        return [_c('div', {
          staticClass: "cr-field-popover"
        }, [_c('div', {
          staticClass: "cr-field-popover__header"
        }, [_vm._v(" Indication of controlling classification of the respective budget request: ")]), _c('div', [_c('span', {
          staticClass: "cr-field-popover__field--title"
        }, [_vm._v(" CAPEX: Replacement ")]), _vm._v(" – Investments that intend to change the technical functionality / character of an asset are to be classified as replacement investments. ")]), _c('div', [_c('span', {
          staticClass: "cr-field-popover__field--title"
        }, [_vm._v(" CAPEX: Maintenance ")]), _vm._v(" – Investments that intend to change the technical functionality / character of an asset are to be classified as replacement investments. ")]), _c('div', [_c('span', {
          staticClass: "cr-field-popover__field--title"
        }, [_vm._v(" CAPEX: Discretionary Growth ")]), _vm._v(" – Comprises development and expansion within existing and new regions and markets which refer to projects that have not been approved by certain approval body (e.g. new Asset Development projects). ")]), _c('div', [_c('span', {
          staticClass: "cr-field-popover__field--title"
        }, [_vm._v(" CAPEX - Green Growth Strategy ")]), _vm._v(" - All existing projects and new projects that are attributable to the “new strategy” e.g. Renewables, Green Molecules Assets, Clean Dispatchable Power etc. “New strategy” - all investments related to the strategic pillars Renewables, Clean Dispatchable Power, Power RES PPA and clean certificates, Green Molecules origination, Green Molecules assets and Green Storage. ")]), _c('div', [_c('span', {
          staticClass: "cr-field-popover__field--title"
        }, [_vm._v(" CAPEX - Green Growth Other ")]), _vm._v(" - Projects that are not attributable to the “New strategy” but can be classified as green e.g. projects for Green Heat BP and other Hydro Projects. “New strategy” - all investments related to the strategic pillars Renewables, Clean Dispatchable Power, Power RES PPA and clean certificates, Green Molecules origination, Green Molecules assets and Green Storage. ")]), _c('div', [_c('span', {
          staticClass: "cr-field-popover__field--title"
        }, [_vm._v(" CAPEX - Other Growth ")]), _vm._v(" - Projects related to all growth projects that are not related to the “New strategy” and are not classified as green. ")]), _c('div', [_c('span', {
          staticClass: "cr-field-popover__field--title"
        }, [_vm._v(" OPEX ")]), _vm._v(" – Operational spend. ")]), _c('div', [_c('span', {
          staticClass: "cr-field-popover__field--title"
        }, [_vm._v(" Provision ")]), _vm._v(" – A provision is an amount set aside for the probable, but uncertain, economic obligations. ")])])];
      },
      proxy: true
    }], null, false, 1962771423)
  }, [_c('pui-form-select', {
    attrs: {
      "is-disabled": _vm.formData.pcClassification.meta.disabled,
      "is-valid": _vm.formData.pcClassification.validation.isValid,
      "options": _vm.formData.pcClassification.meta.items,
      "label": "Planning & Controlling Classification"
    },
    model: {
      value: _vm.formData.pcClassification.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.pcClassification, "value", $$v);
      },
      expression: "formData.pcClassification.value"
    }
  })], 1), _c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.HALF_ROW,
      expression: "formLayout.HALF_ROW"
    }],
    attrs: {
      "is-disabled": _vm.formData.uniperShare.meta.disabled,
      "is-valid": _vm.formData.uniperShare.validation.isValid,
      "label": "Uniper Share of Budget",
      "label-for": "uniperShare"
    }
  }, [_c('pui-form-input-field', {
    attrs: {
      "id": "uniperShare",
      "is-disabled": _vm.formData.uniperShare.meta.disabled,
      "is-valid": _vm.formData.uniperShare.validation.isValid,
      "pattern": /^([0-9]+)$/,
      "label": "Uniper Share of Budget",
      "placeholder-text": "Enter value %",
      "type": "text"
    },
    model: {
      value: _vm.formData.uniperShare.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.uniperShare, "value", $$v);
      },
      expression: "formData.uniperShare.value"
    }
  }), _c('span', {
    attrs: {
      "slot": "error-message"
    },
    slot: "error-message"
  }, [_vm._v(" " + _vm._s(_vm.formData.uniperShare.validation.error) + " ")])], 1)], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.HALF_ROW,
      expression: "formLayout.HALF_ROW"
    }],
    attrs: {
      "is-valid": _vm.formData.compensation.validation.isValid,
      "label": "Budget Compensation"
    }
  }, [_c('pui-form-select', {
    attrs: {
      "is-disabled": _vm.formData.compensation.meta.disabled,
      "is-valid": _vm.formData.compensation.validation.isValid,
      "options": _vm.formData.compensation.meta.items,
      "label": "Budget Compensation"
    },
    on: {
      "change": _vm.updateAssetOperationsGroups
    },
    model: {
      value: _vm.formData.compensation.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.compensation, "value", $$v);
      },
      expression: "formData.compensation.value"
    }
  }), _c('span', {
    attrs: {
      "slot": "error-message"
    },
    slot: "error-message"
  }, [_vm._v(" " + _vm._s(_vm.formData.compensation.validation.error) + " ")])], 1), _c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.HALF_ROW,
      expression: "formLayout.HALF_ROW"
    }],
    attrs: {
      "is-valid": _vm.formData.requestType.validation.isValid,
      "label": "Request Type"
    }
  }, [_c('pui-form-select', {
    attrs: {
      "is-disabled": _vm.formData.requestType.meta.disabled,
      "is-valid": _vm.formData.requestType.validation.isValid,
      "options": _vm.formData.requestType.meta.items,
      "label": "Request Type"
    },
    model: {
      value: _vm.formData.requestType.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.requestType, "value", $$v);
      },
      expression: "formData.requestType.value"
    }
  }), _c('span', {
    attrs: {
      "slot": "error-message"
    },
    slot: "error-message"
  }, [_vm._v(" " + _vm._s(_vm.formData.requestType.validation.error) + " ")])], 1)], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.FULL_ROW,
      expression: "formLayout.FULL_ROW"
    }],
    attrs: {
      "is-valid": _vm.formData.summary.validation.isValid,
      "label": "Budget Request Summary"
    }
  }, [_c('pui-form-textarea', {
    attrs: {
      "grow": true,
      "is-disabled": _vm.formData.summary.meta.disabled,
      "is-valid": _vm.formData.summary.validation.isValid,
      "label": "Budget Request Summary"
    },
    model: {
      value: _vm.formData.summary.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.summary, "value", $$v);
      },
      expression: "formData.summary.value"
    }
  }), _c('span', {
    attrs: {
      "slot": "error-message"
    },
    slot: "error-message"
  }, [_vm._v(" " + _vm._s(_vm.formData.summary.validation.error) + " ")])], 1)], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }],
    staticClass: "files-section"
  }, [_c('div', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.FULL_ROW,
      expression: "formLayout.FULL_ROW"
    }]
  })])], 2) : _c('div', [_c('pui-loader-spinner', {
    attrs: {
      "message": "Currently pre-populating this form...",
      "min-height": "400px",
      "size": "80px",
      "title": "Please wait!"
    }
  })], 1)]), _c('files-change-request', {
    ref: _vm.REF_CONSTANTS.FILES_CHANGE_REQUEST,
    attrs: {
      "is-edit-page": _vm.editMode,
      "mini-fd-id": _vm.changeRequestId,
      "year": (_vm$existingChangeReq = _vm.existingChangeRequest) === null || _vm$existingChangeReq === void 0 ? void 0 : _vm$existingChangeReq.year
    },
    on: {
      "files-uploaded": _vm.emitSuccessfulFormSubmit
    }
  }), _vm.formData.requestType.value !== _vm.NEW_PROJECT ? _c('iybr-global-ids-collapsible', {
    ref: _vm.REF_CONSTANTS.GLOBAL_IDS_COLLAPSIBLE,
    attrs: {
      "currency-name": _vm.formMetadata.currencyCode,
      "global-ids": _vm.formData.projectGlobalId.value,
      "loaded-budget-requests": _vm.linkedBudgetRequests,
      "mini-fd-year": _vm.miniFdYear,
      "mtp-year": _vm.budgetRequestMtpYear,
      "project-type": _vm.formData.projectType.value
    },
    on: {
      "changed:table-data": _vm.handleGlobalIdTableDataChange
    }
  }) : _vm.formData.requestType.value === _vm.NEW_PROJECT ? _c('iybr-new-project-collapsible', {
    ref: _vm.REF_CONSTANTS.NEW_PROJECT_COLLAPSIBLE,
    attrs: {
      "currency-name": _vm.formMetadata.currencyCode,
      "mtp-year": _vm.budgetRequestMtpYear,
      "project-type": _vm.formData.projectType.value
    },
    on: {
      "changed:table-data": _vm.handleNewProjectFormDataChange
    }
  }) : _vm._e(), _c('div', {
    staticClass: "action-row-p"
  }), _c('pui-tabs', {
    on: {
      "changed": _vm.handleTabChange
    }
  }, [_c('pui-tab', {
    attrs: {
      "title": "Budget Request"
    }
  }), _c('pui-tab', {
    attrs: {
      "title": "Additional information"
    }
  })], 1), !_vm.formMetadata.areTablesValid ? _c('pui-form-inline-notification', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.FULL_ROW,
      expression: "formLayout.FULL_ROW"
    }],
    staticClass: "error-inline-notification",
    attrs: {
      "text": "Please check the highlighted information you have provided and make updates where necessary.",
      "title": "Your tables have errors."
    }
  }) : _vm._e(), !_vm.formMetadata.isAdditionalInformationValid ? _c('pui-form-inline-notification', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.FULL_ROW,
      expression: "formLayout.FULL_ROW"
    }],
    staticClass: "error-inline-notification",
    attrs: {
      "text": "Please fill out all additional information about this change request.",
      "title": "Total budget is over 2 million euros."
    }
  }) : _vm._e(), _vm.formMetadata.isCompensationAvailableExceedingRequired ? _c('pui-form-inline-notification', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.FULL_ROW,
      expression: "formLayout.FULL_ROW"
    }],
    staticClass: "error-inline-notification",
    attrs: {
      "text": "Please adjust your change request such that the compensation available does not exceed the required compensation.",
      "title": "Compensation Available exceeds Compensation Required"
    }
  }) : _vm._e(), !_vm.formMetadata.isCompensatedValueValid ? _c('pui-form-inline-notification', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.FULL_ROW,
      expression: "formLayout.FULL_ROW"
    }],
    staticClass: "error-inline-notification",
    attrs: {
      "text": "The compensation amount should be positive and less or equal to the original value.",
      "title": "Invalid compensation amount"
    }
  }) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedTab === 0,
      expression: "selectedTab === 0"
    }]
  }, [_c('pui-collapsible', {
    staticClass: "mt-4",
    attrs: {
      "expandable": false,
      "headline": "BUDGET REQUEST"
    }
  }, [_c('iybr-cost-table', {
    ref: _vm.REF_CONSTANTS.COST_TABLE,
    attrs: {
      "compensation-totals": _vm.compensationTotals,
      "currency-name": _vm.formMetadata.currencyCode,
      "global-ids-collapsible-table-data": _vm.globalIdsTableData,
      "new-project-form-data": _vm.newProjectFormData,
      "project-type": _vm.formData.projectType.value,
      "request-type": _vm.formData.requestType.value,
      "mtp-year": _vm.budgetRequestMtpYear
    },
    on: {
      "total-cost-updated": _vm.checkIfAssetStrategyIsRequired,
      "change:compensation-required": function ($event) {
        _vm.compensationRequired = $event;
      }
    }
  }), _c('pui-button', {
    staticClass: "add-budget-compensation-pb",
    attrs: {
      "small": true,
      "icon": "add"
    },
    on: {
      "click": _vm.showAddBudgetCompensationModal
    }
  }, [_vm._v(" Add Budget Compensation Plan ")]), _c('iybr-compensation-table', {
    ref: _vm.REF_CONSTANTS.COMPENSATION_TABLE,
    attrs: {
      "mtp-year": _vm.budgetRequestMtpYear,
      "currency-code": _vm.formMetadata.currencyCode,
      "mini-fd-id": _vm.budgetCompensationMiniFdId
    },
    on: {
      "update:compensation-totals": _vm.handleCompensationTotalsUpdate,
      "update:compensation-model": _vm.handleCompensationModelUpdate
    }
  })], 1)], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selectedTab === 1,
      expression: "selectedTab === 1"
    }]
  }, [_c('pui-collapsible', {
    staticClass: "mt-4 collapsible-pb collapsible-bg",
    attrs: {
      "expandable": false,
      "headline": "ADDITIONAL INFORMATION"
    }
  }, [_c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.FULL_ROW,
      expression: "formLayout.FULL_ROW"
    }],
    attrs: {
      "is-valid": _vm.formData.projectSetup.validation.isValid,
      "has-info-layer": true,
      "pui-popover-config": _vm.DEFAULT_POPOVER_CONFIGURATION,
      "label": "Project Setup"
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.projectSetup.validation.error) + " ")];
      },
      proxy: true
    }, {
      key: "pui-popover-content",
      fn: function () {
        return [_c('div', {
          staticClass: "cr-field-popover"
        }, [_c('div', [_vm._v("This could include, but not limited to:")]), _c('ul', {
          staticClass: "cr-field-popover__list"
        }, [_c('li', [_vm._v("Project governance")]), _c('li', [_vm._v("Project organization")]), _c('li', [_vm._v("Stakeholder management approach")]), _c('li', [_vm._v("HSSE Implications")])])])];
      },
      proxy: true
    }], null, false, 2495003502)
  }, [_c('pui-form-textarea', {
    attrs: {
      "grow": true,
      "is-valid": _vm.formData.projectSetup.validation.isValid,
      "label": "Project Setup"
    },
    model: {
      value: _vm.formData.projectSetup.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.projectSetup, "value", $$v);
      },
      expression: "formData.projectSetup.value"
    }
  })], 1)], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.FULL_ROW,
      expression: "formLayout.FULL_ROW"
    }],
    attrs: {
      "is-valid": _vm.formData.projectAnchoring.validation.isValid,
      "has-info-layer": true,
      "pui-popover-config": _vm.DEFAULT_POPOVER_CONFIGURATION,
      "label": "Project Anchoring"
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.projectAnchoring.validation.error) + " ")];
      },
      proxy: true
    }, {
      key: "pui-popover-content",
      fn: function () {
        return [_c('div', {
          staticClass: "cr-field-popover"
        }, [_c('div', [_vm._v("This could include, but not limited to:")]), _c('ul', {
          staticClass: "cr-field-popover__list"
        }, [_c('li', [_vm._v("Comparison of key assumptions to the last similar/similar approved projects")]), _c('li', [_vm._v("Lessons that have been learnt and incorporated")]), _c('li', [_vm._v("Alternative options (including do nothing)")])])])];
      },
      proxy: true
    }], null, false, 4088750370)
  }, [_c('pui-form-textarea', {
    attrs: {
      "grow": true,
      "is-valid": _vm.formData.projectAnchoring.validation.isValid,
      "label": "Project Anchoring"
    },
    model: {
      value: _vm.formData.projectAnchoring.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.projectAnchoring, "value", $$v);
      },
      expression: "formData.projectAnchoring.value"
    }
  })], 1)], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.FULL_ROW,
      expression: "formLayout.FULL_ROW"
    }],
    attrs: {
      "is-valid": _vm.formData.furtherFinancialParameters.validation.isValid,
      "has-info-layer": true,
      "pui-popover-config": _vm.DEFAULT_POPOVER_CONFIGURATION,
      "label": "Further Financial Parameters"
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.furtherFinancialParameters.validation.error) + " ")];
      },
      proxy: true
    }, {
      key: "pui-popover-content",
      fn: function () {
        return [_c('div', {
          staticClass: "cr-field-popover"
        }, [_c('div', [_vm._v("This could include, but not limited to:")]), _c('ul', {
          staticClass: "cr-field-popover__list"
        }, [_c('li', [_vm._v("Base case CAPEX, EBITDA and KPI projections")]), _c('li', [_vm._v("Reasons for deviation from MTP (if applicable)")]), _c('li', [_vm._v("Key financial drivers and assumptions")])])])];
      },
      proxy: true
    }], null, false, 2671348325)
  }, [_c('pui-form-textarea', {
    attrs: {
      "grow": true,
      "is-valid": _vm.formData.furtherFinancialParameters.validation.isValid,
      "label": "Further Financial Parameters"
    },
    model: {
      value: _vm.formData.furtherFinancialParameters.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.furtherFinancialParameters, "value", $$v);
      },
      expression: "formData.furtherFinancialParameters.value"
    }
  })], 1)], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.FULL_ROW,
      expression: "formLayout.FULL_ROW"
    }],
    attrs: {
      "is-valid": _vm.formData.procurementInvolvment.validation.isValid,
      "has-info-layer": true,
      "pui-popover-config": _vm.DEFAULT_POPOVER_CONFIGURATION,
      "label": "Procurement Involvement"
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.procurementInvolvment.validation.error) + " ")];
      },
      proxy: true
    }, {
      key: "pui-popover-content",
      fn: function () {
        return [_c('div', {
          staticClass: "cr-field-popover"
        }, [_c('div', [_vm._v("This could include, but not limited to:")]), _c('ul', {
          staticClass: "cr-field-popover__list"
        }, [_c('li', [_vm._v("Elaboration on Procurement Strategy and possible Supplier Panel")]), _c('li', [_vm._v("Overview of current tender process and received supplier offers")])])])];
      },
      proxy: true
    }], null, false, 1549204416)
  }, [_c('pui-form-textarea', {
    attrs: {
      "grow": true,
      "is-valid": _vm.formData.procurementInvolvment.validation.isValid,
      "label": "Procurement Involvement"
    },
    model: {
      value: _vm.formData.procurementInvolvment.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.procurementInvolvment, "value", $$v);
      },
      expression: "formData.procurementInvolvment.value"
    }
  })], 1)], 1), _c('div', {
    directives: [{
      name: "pui-form-grid-row",
      rawName: "v-pui-form-grid-row"
    }]
  }, [_c('pui-form-group', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.FULL_ROW,
      expression: "formLayout.FULL_ROW"
    }],
    attrs: {
      "is-valid": _vm.formData.riskAndOpportunities.validation.isValid,
      "has-info-layer": true,
      "pui-popover-config": _vm.DEFAULT_POPOVER_CONFIGURATION,
      "label": "Risk and Opportunities"
    },
    scopedSlots: _vm._u([{
      key: "error-message",
      fn: function () {
        return [_vm._v(" " + _vm._s(_vm.formData.riskAndOpportunities.validation.error) + " ")];
      },
      proxy: true
    }, {
      key: "pui-popover-content",
      fn: function () {
        return [_c('div', {
          staticClass: "cr-field-popover"
        }, [_c('div', [_vm._v("This could include, but not limited to:")]), _c('ul', {
          staticClass: "cr-field-popover__list"
        }, [_c('li', [_vm._v("Articulation of the key risks and opportunities, mitigation and residual risk")]), _c('li', [_vm._v("Scenario and sensitivity analysis")])])])];
      },
      proxy: true
    }], null, false, 1012554460)
  }, [_c('pui-form-textarea', {
    attrs: {
      "grow": true,
      "is-valid": _vm.formData.riskAndOpportunities.validation.isValid,
      "label": "Risk and Opportunities"
    },
    model: {
      value: _vm.formData.riskAndOpportunities.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.riskAndOpportunities, "value", $$v);
      },
      expression: "formData.riskAndOpportunities.value"
    }
  })], 1)], 1)])], 1)], 1), _c('pui-grid-column', {
    attrs: {
      "cols": _vm.formLayout.ONE_THIRD_ROW
    }
  }, [_c('pui-collapsible', {
    attrs: {
      "expandable": false,
      "headline": "Focus Topics"
    }
  }, [_vm.formMetadata.isLoaded ? _c('div', {
    staticClass: "collapsible-padding"
  }, [_c('tags', {
    attrs: {
      "view-only": _vm.isTagsViewOnly,
      "current-mode": _vm.editMode ? _vm.crTagsMode : _vm.emitTagsMode,
      "request-id": _vm.changeRequestId,
      "initial-tags": _vm.tagsList
    },
    on: {
      "tags-list-changed": _vm.handleTagsListChanged
    }
  })], 1) : _c('div', [_c('pui-loader-spinner', {
    attrs: {
      "min-height": "80px",
      "size": "60px"
    }
  })], 1)]), _vm.formMetadata.isLoaded ? _c('div', [!_vm.formMetadata.isApprovalWorkflowFormValid ? _c('pui-form-inline-notification', {
    directives: [{
      name: "pui-form-grid-column",
      rawName: "v-pui-form-grid-column",
      value: _vm.formLayout.FULL_ROW,
      expression: "formLayout.FULL_ROW"
    }],
    staticClass: "error-inline-notification-approval-form",
    attrs: {
      "text": "Please select an approver for each department.",
      "title": ""
    }
  }) : _vm._e()], 1) : _vm._e(), _c('form-approval-workflow', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isSdFd,
      expression: "!isSdFd"
    }],
    ref: "formApprovalWorkflow",
    attrs: {
      "is-asset-strategy-required": _vm.isAssetStrategyRequired,
      "is-hydro-technology": _vm.isHydroTechnology,
      "is-steam-technology": _vm.isSteamTechnology,
      "aero-classification": _vm.formData.aeroClassification.value,
      "is-sweden-country": _vm.isSwedenCountry,
      "selected-local-compensation": _vm.selectedLocalCompensation,
      "total-cost": _vm.costTableTotal,
      "primary-department-name": _vm.data.constants.primaryDepartment[_vm.formData.primaryDepartment.value]
    }
  }), _c('optional-notifiable-users', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isSdFd,
      expression: "!isSdFd"
    }],
    attrs: {
      "edit-page-list-of-notifiable-users": _vm.listOfNotifiableUsersFromEdit
    },
    on: {
      "notifiable-user-update": _vm.getListOfNotifiableUsers
    }
  }), _c('linked-external-ids-collapsible', {
    staticClass: "mt-5",
    attrs: {
      "external-ids": _vm.confirmedExternalIds
    },
    on: {
      "invalid-external-ids": _vm.handleExternalIdsInvalidEntries
    }
  })], 1)], 1), _c('pui-grid-row', {
    staticClass: "action-row-p"
  }, [_c('pui-grid-column', {
    staticClass: "flex-buttons",
    attrs: {
      "cols": 8
    }
  }, [_vm.isSdFd ? [_c('pui-button', {
    attrs: {
      "state": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.submitForm(_vm.MiniFdStatus.APPROVED);
      }
    }
  }, [_vm._v(" Save ")])] : [_c('pui-button', {
    attrs: {
      "state": "secondary"
    },
    on: {
      "click": function ($event) {
        return _vm.submitForm(_vm.MiniFdStatus.DRAFT);
      }
    }
  }, [_vm._v(" Save as Draft ")]), _c('pui-button', {
    on: {
      "click": function ($event) {
        return _vm.submitForm(_vm.MiniFdStatus.SUBMITTED);
      }
    }
  }, [_vm._v(" Submit ")])]], 2)], 1)], 1) : _c('div', [_c('pui-loader-error', {
    attrs: {
      "icon": "error-alert",
      "message": "Failed to load Change Request data for the given Unique ID. Please try again.",
      "title": "An error has occurred."
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }