var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('pui-lightbox', {
    ref: _vm.REF_CONSTANTS.LIGHTBOX,
    attrs: {
      "default-header-label": "Link Budget Requests",
      "default-footer-confirm-label": "Link",
      "default-footer-cancel-label": "Cancel",
      "default-footer-confirm-disabled": _vm.isFormDisabled,
      "on-confirm-callback": _vm.lightboxConfirm,
      "close-on-e-s-c": false,
      "fit-content": true
    },
    on: {
      "puilightbox:close": _vm.emitClose
    }
  }, [_c('div', {
    staticClass: "link-budget-requests-lightbox"
  }, [_vm.isFormSubmitting ? [_c('pui-loader', {
    attrs: {
      "promise": _vm.infinitePromise
    }
  })] : [_c('pui-grid-container', [_c('pui-grid-row', [_c('pui-grid-column', {
    attrs: {
      "cols": {
        s: 12,
        l: 12
      }
    }
  }, [_c('pui-form-group', {
    attrs: {
      "label": "Plant"
    }
  }, [_c('pui-form-select', {
    attrs: {
      "label": "",
      "search-input-placeholder": " ",
      "value": _vm.formData.plant.value,
      "options": _vm.plantOptions
    },
    on: {
      "change": _vm.handlePlantChange
    },
    model: {
      value: _vm.formData.plant.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.plant, "value", $$v);
      },
      expression: "formData.plant.value"
    }
  })], 1), _c('pui-form-group', {
    attrs: {
      "label": "Global IDs",
      "label-for": "globalIdInput"
    }
  }, [_c('pui-form-type-ahead', {
    attrs: {
      "label": "Global IDs",
      "search-input-id": "globalIdInput",
      "multiple": "",
      "is-loading": _vm.formData.globalIds.loading,
      "is-disabled": !_vm.formData.plant.value,
      "options": _vm.globalIdOptions,
      "use-local-cache": false
    },
    on: {
      "search": _vm.handleGlobalIdSearch
    },
    model: {
      value: _vm.formData.globalIds.value,
      callback: function ($$v) {
        _vm.$set(_vm.formData.globalIds, "value", $$v);
      },
      expression: "formData.globalIds.value"
    }
  })], 1)], 1)], 1)], 1)]], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }