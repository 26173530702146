






















import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { ICellRendererParams } from 'ag-grid-community';

interface GlobalIdCell extends ICellRendererParams {
    href: string;
    query: Record<string, string>;
}

@Component({})
export default class GlobalIdCellComponent extends Vue {
    private params?: GlobalIdCell;

    private get title(): string {
        return this.params.value;
    }

    private get href(): string | undefined {
        return this.params.href;
    }
    
    private get query(): Record<string, string> {
        return this.params.query;
    }
    
    private get showWarning(): boolean {
        return this.params.data.currentMtpPlanning.hasDifferences && this.params.data.currentMtpPlanning.mtpStatus !== 4;
    }
}
