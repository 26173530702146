import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import '~/components/home/home.scss';
import { User } from '~/utils';
import { Permission } from '~/utils/permission';
import AddNewFocusTopic from './add-new-focus-topic/add-new-focus-topic.vue';
import ViewFocusTopics from './view-focus-topics/view-focus-topics.vue';
import { FocusTopicsService } from '~/services/focus-topics-service';
import { FocusTopic } from '~/models/services/focus-topics';
import { ManagedAbortControllers } from '~/mixins/managed-abort-controllers';
import { mixins } from 'vue-class-component';

const REF_CONSTANTS = {
    ADD_NEW_FOCUS_TOPIC: 'addNewFT',
} as const;

@Component({
    components: {
        AddNewFocusTopic,
        ViewFocusTopics,
    }
})

export default class FocusTopicsComponent extends mixins(ManagedAbortControllers) {
    private readonly REF_CONSTANTS = REF_CONSTANTS;
    $refs!: {
        [REF_CONSTANTS.ADD_NEW_FOCUS_TOPIC]: Vue & {
            clearData: () => void;
        };
    };

    public FocusTopicService: FocusTopicsService = new FocusTopicsService();
    public focusTopics: FocusTopic[] = [];
    private userPermissions = <any>[];
    private isAccessDeniedModalActive: boolean = false;
    private loading: boolean = false;
    private error: string = '';
    private editFT = null;

    private get hasPermissionToEdit(): boolean {
        return this.userPermissions.includes(Permission.FOCUS_TOPICS_EDIT);
    }

    async created() {
        this.userPermissions = await User.getPerm();
        if (this.hasPermissionToEdit) {
            await this.load();
        } else {
            $('#accessDeniedModal').modal('show');
            this.isAccessDeniedModalActive = true;
        }
    }

    beforeRouteLeave(to, from, next) {
        if (this.isAccessDeniedModalActive) {
            next(false);
        } else {
            next(true);
        }
    }

    private onClickCancel(value) {
        this.isAccessDeniedModalActive = value;
    }

    private hideToast() {
        this.$bvToast.hide('toast-focus-topic-success');
    }

    private async load() {
        this.loading = true;

        const signal = this.getSignal('fetchTypeahead', true);

        try {
            this.focusTopics = await this.FocusTopicService.getFocusTopics({ signal });
        } finally {
            this.loading = false;
        }
    }

    private handleLoadingChanged(loading) {
        this.loading = loading;
    }

    private async handleFocusTopicSaved() {
        await this.load();
    }

    private handleErrorChanged(error: string) {
        this.error = error;
    }

    private handleIdChanged(editFT: FocusTopic) {
        this.editFT = editFT;
    }

    private clearEditFT() {
        this.editFT = null;
    }

    private handleReloadData() {
        this.$refs[REF_CONSTANTS.ADD_NEW_FOCUS_TOPIC].clearData();
        this.handleFocusTopicSaved();
    }
}
