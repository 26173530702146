import Vue from 'vue';
import Component from 'vue-class-component';
import { EventBus } from '~/utils/eventbus';

@Component({})
export default class DeleteBrModalComponent extends Vue {
    private selectedStateBr: string = '';

    constructor() {
        super();
    }

    private yesChanges() {
        EventBus.$emit(EventBus.REMOVE_BUDGET_REQUEST.REMOVE_MODAL, this.selectedStateBr);
    }
}
