export type Tag = {
  id?: number,
  mtpId?: string,
  miniFdId?: string,
  focusTopicId: string,
  createdAt?: string,
  createdBy?: string,
  tagName: string,
}

export enum TagsMode {
  BR = 1,
  CR = 2,
  EMIT = 3,
}

export type TagsConfig = {
  [key in TagsMode]: {
      getFn?: any;
      deleteFn?: any;
      importFn?: any;
  };
};
