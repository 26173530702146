import { Connection } from '~/utils/';
import { AssetGroupItem, GetAssetGroupsResponse } from '~/models/services/asset-groups';
import { ApiResponse } from '~/models/services/common';
import { CacheContainer } from '~/utils/cache-container'

export class AssetGroupsService {
    private readonly api = new Connection();

    private readonly assetGroupsCache = new CacheContainer<(string | number)[], AssetGroupItem[]>()

    public async getAssetGroups(fleetIds: (string | number)[]): Promise<AssetGroupItem[]> {
        if (this.assetGroupsCache.hasCachedValue(fleetIds)) {
            return this.assetGroupsCache.getCachedValue(fleetIds);
        }

        const querystring = require('querystring');
        const query = {
            params: {
                ...(fleetIds !== null && fleetIds !== undefined) && { fleetSId: fleetIds },
            },
            paramsSerializer: (params: Record<string, string>): string => {
                return querystring.stringify(params);
            },
        };

        const assetGroupItems = (await this.api.get<ApiResponse<GetAssetGroupsResponse>>('master-data/asset-group/list', query)).data.result.assetGroups;
        this.assetGroupsCache.storeCachedValue(fleetIds, assetGroupItems);

        return assetGroupItems;
    }
}
